const minutosAHoras = (minutos) => {
	if (minutos === 1) {
		return "1m";
	} else if (minutos < 60) {
		return `${minutos}m`;
	}

	const horas = Math.floor(minutos / 60);
	const minutosRestantes = minutos % 60;

	if (minutosRestantes === 0) {
		return `${horas}h`;
	}

	return `${horas}h ${minutosRestantes}m`;
};

export default minutosAHoras;
