import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getEstadisticas = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: estadisticas } = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.getEstadisticas}`,
				method: "GET",
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const getCertificadosPorMes = async ({ year }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: estadisticas } = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.getCertificadosPorMes}/${year}`,
				method: "POST",
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const getPromediosPorMes = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: estadisticas } = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.getPromediosPorMes}`,
				method: "GET",
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const registrarUso = async (info) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: estadisticas } = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.registrarUso}`,
				method: "POST",
				body: JSON.stringify(info),
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const getHorasJuego = async (añoBusqueda) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: estadisticas } = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.getHorasJuego}`,
				method: "POST",
				body: JSON.stringify(añoBusqueda),
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const getVisitasSala = async (añoBusqueda) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: estadisticas } = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.getVisitasSala}`,
				method: "POST",
				body: JSON.stringify(añoBusqueda),
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const getEstadisticasMos = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const estadisticas = await httpRequest({
				url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.obtenerEstadisticasMos}`,
				method: "GET",
			});

			resolve(estadisticas);
		} catch (error) {
			// console.log("Error al obtener las preguntas diagnóstico: ", error);

			reject(error);
		}
	});
};

export const getDetallesUsuariosSuspendidos = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const estadisticas = await httpRequest({
        url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.obtenerUsuariosSuspendidos}`,
        method: "GET",
      });

      resolve(estadisticas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};


export const getDetallesExamenesMos = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const estadisticas = await httpRequest({
        url: `${endpointsPaths.estadisticasPlataforma}/${httpRequestsPaths.estadisticasPlataforma.obtenerDetallesMos}`,
        method: "GET",
      });

      resolve(estadisticas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};