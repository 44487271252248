import "@assets/css/components/Perfil/DropZone/style.css";
import UserContext from "@context/User/UserContext";

import React, { useContext, useMemo } from "react";

import { useDropzone } from "react-dropzone";

export const DropZone = ({ getImageInBase64 }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { modoOscuro } = usuarioLogeado;

	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ accept: "image/*" });

	const baseStyle = {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "20px",
		borderWidth: 2,
		borderRadius: 2,
		borderColor: modoOscuro ? "rgb(73, 80, 87)" : "#eeeeee",
		borderStyle: "dashed",
		backgroundColor: modoOscuro ? "rgb(52, 58, 64)" : "#fafafa",
		color: "#ff9900",
		outline: "none",
		transition: "border .24s ease-in-out",
	};

	const focusedStyle = {
		borderColor: "#2196f3",
	};

	const acceptStyle = {
		borderColor: "#00e676",
	};

	const rejectStyle = {
		borderColor: "#ff1744",
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	return (
		<div className="container">
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} onChange={getImageInBase64} type={"file"} />
				<p >Haz clic aqu&iacute; para cargar una foto</p>
			</div>
		</div>
	);
};
