import {
  faArrowLeft,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
// Import Swiper React components
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { getCursosRutas } from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import { getCursoByCodigo } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getCategoriasById } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";

const RutasPredefinidas = ({
  nombres,
  rutasAprendizaje,
  rutaDeAprendizaje1,
  listarLecciones,
  rutasPorCurso,
}) => {
  // console.log(rutasAprendizaje);

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(1);

  const [showCategorias, setShowCategorias] = useState(true);

  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);

  const [cantidadCursos, setCantidadCursos] = useState(0);

  const [showCursos, setShowCursos] = useState(false);

  const [rutaSeleccionada, setRutaSeleccionada] = useState(0);

  const [showRutas, setShowRutas] = useState(false);

  const [showRegresar, setShowRegresar] = useState(false);

  const [cursos, setCursos] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [cursosRutas, setCursosRutas] = useState([]);

  //*FUNCION PARA SELECCIONAR LA CATEGORIA Y ASIGNARLA AL STATE
  const handleCategoria = (idCategoria) => {
    setShowCategorias(false);
    setCategoriaSeleccionada(idCategoria);
    setShowCursos(true);
    setShowRegresar(true);
  };

  //*FUNCION PARA SELECCIONAR EL CURSO Y ASIGNARLO AL STATE

  const handleCurso = (codigoCurso) => {
    // console.log(codigoCurso);
    setCursoSeleccionado(codigoCurso);

    const rutasCurso = rutasPorCurso.filter(
      (rutaPorCurso) => rutaPorCurso.codigoCurso === codigoCurso
    );
    // console.log(rutasCurso);

    if (rutasCurso.length > 0) {
      setCantidadCursos(rutasCurso.length);
    } else {
      setCantidadCursos(0);
    }

    setShowCursos(false);
    setShowRutas(true);
  };

  const handleRegresar = () => {
    if (showCursos) {
      setShowCursos(false);
      setShowCategorias(true);
      setShowRegresar(false);
    }

    if (showRutas) {
      setShowRutas(false);
      setShowCursos(true);
    }
  };

  useEffect(() => {
    const obtenerCursosPorRutas = async () => {
      try {
        const { data } = await getCursosRutas();
        if (Array.isArray(data)) {
          setCursosRutas(data);
        } else {
          setCursosRutas([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    obtenerCursosPorRutas();
  }, []);

  useEffect(() => {
    const obtenerCursosPorCodigo = async () => {
      try {
        let arreglo = [];

        for (const cursoRuta in cursosRutas) {
          if (Object.hasOwnProperty.call(cursosRutas, cursoRuta)) {
            const element = cursosRutas[cursoRuta];
            const { data } = await getCursoByCodigo(element.codigoCurso);
            arreglo.push(data);
          }
        }

        setCursos(arreglo);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursosPorCodigo();
  }, [cursosRutas]);

  useEffect(() => {
    const obtenerCategoriasPorId = async () => {
      try {
        let arreglo = [];

        for (const curso in cursos) {
          if (Object.hasOwnProperty.call(cursos, curso)) {
            const element = cursos[curso];
            const { data } = await getCategoriasById(element.idCategoria);
            arreglo.push(data);
          }
        }
        setCategorias(arreglo);
      } catch (error) {
        console.log(error);
      }
    };
    if (cursos.length > 0) {
      obtenerCategoriasPorId();
    }
  }, [cursos]);

  return (
    <>
      <Row>
        <Col>
          <Row className="mb-3 justify-content-between">
            <Col className="text-capitalize col-auto">
              <TitleDashboard title="Ruta predefinidas" />
            </Col>

            {showRegresar && (
              <Col className="col-auto">
                {/* <span onClick={handleRegresar} className="pointer">
                  <FontAwesomeIcon icon={faArrowLeft} /> Volver
                </span> */}
                <Button className="pointer" onClick={handleRegresar}>
                  <FontAwesomeIcon icon={faArrowLeft}/> Volver
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {/* Vista de las categorias */}
      {showCategorias && (
        <Row className="justify-content-center predefinidas ">
          {categorias.map((categoria, idx) => (
            <Col className="col-9 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <Card
                onClick={() => handleCategoria(categoria.idCategoria)}
                id={idx}
                className="card-predefinidas border-0"
              >
                <img
                  className="img-fluid"
                  src={`${require(`@assets/images/portadas_rutasAprendizaje/${categoria.portadaCategoria}`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {/* Vista de los cursos por categoria */}
      {showCursos && (
        <Row className="justify-content-center justify-content-lg-start ">
          {cursos.map(
            (curso, idx) =>
              curso.idCategoria === categoriaSeleccionada && (
                <Col
                  className=" pointer col-9 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                  onClick={() => {
                    handleCurso(curso.codigoCurso);
                  }}
                >
                  <Card id={idx} className=" mb-4 card-predefinidas border-0">
                    <img
                      className="img-fluid"
                      src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
                      alt="Imagen de fondo"
                    />
                  </Card>
                  {/* <div className="card-image">
														<img
															src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
															alt="Imagen de fondo"
														/>
														<div className="card-text">
															<h5>{curso.nombre}</h5>
															<small>{curso.descripcion}</small>
														</div>
													</div> */}
                </Col>

                /* <Col className="col-9 col-sm-6 col-md-4 col-lg-3 col-xl-2">
								<Card onClick={() => handleCurso(curso.codigoCurso)} id={idx} className=" mb-4 card-predefinidas ">
									<img className="img-fluid" src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} alt="imagen leccion" />
								</Card>
								</Col> */
              )
          )}
        </Row>
      )}

      {/* Vista de las rutas de cada curso */}

      {showRutas &&
        (cantidadCursos > 0 ? (
          <Row className="mb-5 mt-4">
            {rutasPorCurso.map(
              (rutaPorCurso, idRuta) =>
                rutaPorCurso.codigoCurso === cursoSeleccionado && (
                  <Col>
                    <Row className="justify-content-center">
                      {rutasAprendizaje.map(
                        (rutaAprendizaje, idRuta) =>
                          rutaAprendizaje.codigoCurso === cursoSeleccionado && (
                            <Col
                              key={idRuta}
                              className="col-9 col-sm-6 col-md-4 col-lg-3 mb-4 mb-lg-0 mt-5"
                            >
                              {/* <Card  className=" mb-4 card-ruta ">
											<img className="img-fluid" src={`${require(`@assets/images/portadas_rutasAprendizaje/${rutaAprendizaje.portada}`)}`} alt="imagen leccion" />
											<Card.Body style={{ padding: "10px", background: "grey", color: "#fff", borderRadius: "0px 0px 10px 10px" }} className="text-center">
												<p className="titulo-ruta">
													<b>{rutaAprendizaje.nombre} </b>
												</p>
												<small>{rutaAprendizaje.descripcion}</small>
											</Card.Body>
										</Card> */}

                              <div className="card-image">
                                <img
                                  src={
                                    rutaAprendizaje.portada !== null
                                      ? `${require(`@assets/images/portadas_rutasAprendizaje/${rutaAprendizaje.portada}`)}`
                                      : `${require(`@assets/images/portadas_rutasAprendizaje/miRutaAprendizaje.png`)}`
                                  } // Aquí va la URL de la imagen de fondo
                                  alt="Imagen de fondo"
                                />
                                <div className="card-text">
                                  <h5>{rutaAprendizaje.nombre}</h5>
                                  <small><p>{rutaAprendizaje.descripcion}</p></small>
                                  <div className="text-center">
                                  <Button
                                    block
                                    className="mt-3 btn-sm btn-light"
                                    name={rutaAprendizaje.lecciones}
                                    onClick={listarLecciones}
                                  >
                                    Tomar ruta
                                  </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )
                      )}
                    </Row>
                  </Col>
                )
            )}
          </Row>
        ) : (
          <Row>
            <Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="mb-3 icon"
                size="4x"
              />
              {/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
              <h4>Este curso no cuenta con ninguna ruta de aprendizaje</h4>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default RutasPredefinidas;
