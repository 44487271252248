import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columnas = [
  {
    name: "ID Licencia", //nombre de la columna
    selector: (row) => row.idLicencia, //identificador de la columna
    sortable: true,
    center: true,
    width: "150px",
  },
  {
    name: "Nombre",
    selector: (row) => row.nombre,
    sortable: true,
  },
  {
    name: "Estatus",
    selector: (row) => (row.estatus ? "Activo" : "Ináctivo"), //identificador de la columna
    sortable: true,
    center: true,
  },
  {
    name: "Usuario adicional",
    selector: (row) =>
      row.usuarioExtra ? (
        <FontAwesomeIcon icon={faCheck} color="#28a745" />
      ) : (
        <FontAwesomeIcon icon={faTimes} color="#dc3545" />
      ),
    sortable: true,
    center: true,
  },
  {
    name: "Licencia empresarial",
    selector: (row) =>
      row.licenciaEmpresarial ? (
        <FontAwesomeIcon icon={faCheck} color="#28a745" />
      ) : (
        <FontAwesomeIcon icon={faTimes} color="#dc3545" />
      ),
    sortable: true,
    center: true,
  },
];

export default columnas;
