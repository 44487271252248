import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaArrowCircleLeft, FaArrowCircleRight, FaLink } from "react-icons/fa";

const VerReel = ({ infoShort, shorts, handleLeccion }) => {
  const [shortsFiltrados, setShortsFiltrados] = useState([]);

  const [shortActual, setShortActual] = useState();

  const {
    nombre,
    descripcion,
    embebido,
    curso,
    codigoLeccion,
    nombreLeccion,
    portada,
  } = infoShort;

  const [info, setInfo] = useState({
    nombre,
    descripcion,
    embebido,
    curso,
    codigoLeccion,
    nombreLeccion,
    portada,
  });

  const previousReel = ({ currentTarget }) => {
    const { id } = currentTarget;

    let indiceAnterior = parseInt(id) - 1;

    setInfo(shortsFiltrados[indiceAnterior]);
    setShortActual(indiceAnterior);
  };

  const nextReel = ({ currentTarget }) => {
    const { id } = currentTarget;

    let indiceSiguiente = parseInt(id) + 1;

    setInfo(shortsFiltrados[indiceSiguiente]);
    setShortActual(indiceSiguiente);
  };

  useEffect(() => {
    const filtrarShorts = () => {
      const reelsFiltrados = shorts.filter((short) => short.curso === curso);

      setShortsFiltrados(reelsFiltrados);
    };

    filtrarShorts();
  }, []);

  useEffect(() => {
    const obtenerIndiceShort = () => {
      const indice = shortsFiltrados.findIndex(
        (shortFiltrado) => shortFiltrado.nombre === info.nombre
      );
      if (indice !== -1) {
        console.log(indice);
        setShortActual(indice);
        setInfo({
          ...info,
          indice,
        });
      }
    };
    obtenerIndiceShort();
  }, [shortsFiltrados]);

  return (
    <Container fluid>
      <Row style={{ backgroundColor: "#f2f2f2" }} className="p-3 mb-3">
        <Col style={{ textAlign: "start" }}>
          <h5>
            <b>{info.nombre}</b>
          </h5>

          <small>{info.descripcion}.</small>
          {/* <small><p><b>Este tema se ve en la lección: </b><a href='#' onClick={()=> handleLeccion(info.codigoLeccion, info.curso)}>{info.nombreLeccion} <FaLink /></a></p></small> */}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-auto d-flex flex-column justify-content-center p-0">
          <Button
            id={shortActual}
            onClick={previousReel}
            disabled={shortActual === 0 ? true : false}
            style={{ fontSize: "1.2rem" }}
            className="rounded-circle"
            type="primary"
          >
            <FaArrowCircleLeft />
          </Button>
        </Col>
        <Col className="col-auto">{info.embebido}</Col>
        <Col className="col-auto d-flex flex-column justify-content-center p-0">
          <Button
            disabled={shortActual === shortsFiltrados.length - 1}
            id={shortActual}
            onClick={nextReel}
            style={{ fontSize: "1.2rem" }}
            className="rounded-circle"
            type="primary"
          >
            <FaArrowCircleRight />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default VerReel;
