// const getDateFromUnix = (unixTimesamp) => {
//   const date = new Date(Math.floor(unixTimesamp * 1000));

//   const dateFormatter = new Intl.DateTimeFormat("es-ES", {
//     dateStyle: "full",
//   });

//   return dateFormatter.format(date);
// };


const getDateFromUnix = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);

  // Obtén la fecha formateada en el huso horario local
  const fechaFormateada = date.toLocaleString("es-ES", {
    dateStyle: "full",
    timeZone: "UTC", // Ajusta el huso horario según tu necesidad
  });

  return fechaFormateada;
};

export default getDateFromUnix;
