import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpFormDataRequest from "../httpFormDataRequest";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getPublicacionesByGrupo = async (idGrupo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: publicaciones } = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudPublicaciones.getPublicacionesByGrupo}/${idGrupo}`,
				method: "GET",
			});

			resolve(publicaciones);
		} catch (error) {
			// console.log("Error al obtener las publicaciones: ", error);

			reject(error);
		}
	});
};

export const addPublicacion = async (publication) => {
	return new Promise(async (resolve, reject) => {
		try {
			const publicacionAgregada = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudPublicaciones.addPublicacion}`,
				method: "POST",
				body: JSON.stringify(publication),
			});

			resolve(publicacionAgregada);
		} catch (error) {
			// console.log("Error al agregar la publicacion: ", error);

			reject(error);
		}
	});
};

export const updatePublicacion = async (publication) => {
	return new Promise(async (resolve, reject) => {
		try {
			const publicacionActualizada = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudPublicaciones.updatePublicacion}`,
				method: "POST",
				body: JSON.stringify(publication),
			});

			resolve(publicacionActualizada);
		} catch (error) {
			// console.log("Error al actualizar la publicacion: ", error);

			reject(error);
		}
	});
};

export const deletePublicacion = async (idPublicacion) => {
	return new Promise(async (resolve, reject) => {
		try {
			const publicacionEliminada = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudPublicaciones.deletePublicacion}/${idPublicacion}`,
				method: "POST",
			});

			resolve(publicacionEliminada);
		} catch (error) {
			// console.log("Error al eliminar la publicacion: ", error);

			reject(error);
		}
	});
};

export const reactivatePublicacion = async (idPublicacion) => {
	return new Promise(async (resolve, reject) => {
		try {
			const publicacionReactivada = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudPublicaciones.reactivatePublicacion}/${idPublicacion}`,
				method: "POST",
			});

			resolve(publicacionReactivada);
		} catch (error) {
			// console.log("Error al eliminar la publicacion: ", error);

			reject(error);
		}
	});
};

//FUNCION PARA DESCARGAR EL ARCHIVO DE UNA PUBLICACION

export const descargarArchivo = async (datosArchivo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const descargarArchivo = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.manejoArchivos.downloadArchivoPublicacion}`,
				method: "POST",
				body: JSON.stringify(datosArchivo),
			});

			resolve(descargarArchivo);
		} catch (error) {
			// console.log("Error al descargar el archivo: ", error);

			reject(error);
		}
	});
};

//FUNCIONES PARA EL TAB DE 'LECCIONES'

export const addSolicitud = async (datosSoliciitud) => {
	return new Promise(async (resolve, reject) => {
		try {
			const sendSolicitud = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudLecciones.addSolicitud}`,
				method: "POST",
				body: JSON.stringify(datosSoliciitud),
			});

			resolve(sendSolicitud);
		} catch (error) {
			// console.log("Error al descargar el archivo: ", error);

			reject(error);
		}
	});
};

// export const getLeccionesEmpresariales = async (idGrupo) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const {data: getLecciones} = await httpRequest({
// 				url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.getLeccionesByGrupo}/${idGrupo}`,
// 				method: "GET",
// 			});

// 			resolve(getLecciones);
// 		} catch (error) {
// console.log("Error al descargar el archivo: ", error);

// 			reject(error);
// 		}
// 	});
// };

export const sendMailNuevaLeccion = async (datosSoliciitud) => {
	return new Promise(async (resolve, reject) => {
		try {
			const sendMail = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.crudLecciones.sendMailNuevaLeccion}`,
				method: "POST",
				body: JSON.stringify(datosSoliciitud),
			});

			resolve(sendMail);
		} catch (error) {
			// console.log("Error al descargar el archivo: ", error);

			reject(error);
		}
	});
};

export const downloadArchivoLeccion = async (infoDescarga) => {
	return new Promise(async (resolve, reject) => {
		try {
			const sendMail = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.manejoArchivos.downloadArchivoLeccion}`,
				method: "POST",
				body: JSON.stringify(infoDescarga),
			});

			resolve(sendMail);
		} catch (error) {
			// console.log("Error al descargar el archivo: ", error);

			reject(error);
		}
	});
};

export const invitarEstudiante = async (infoUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const invite = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.grupoEmpresarial.invitarUsuario}`,
				method: "POST",
				body: JSON.stringify(infoUsuario),
			});

			resolve(invite);
		} catch (error) {
			// console.log("Error al descargar el archivo: ", error);

			reject(error);
		}
	});
};

export const deleteUsusarioDelGrupo = async (correo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const deleteUsuario = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.grupoEmpresarial.eliminarUsuario}`,
				method: "POST",
				body: JSON.stringify(correo),
			});

			resolve(deleteUsuario);
		} catch (error) {
			// console.log("Error al descargar el archivo: ", error);

			reject(error);
		}
	});
};

export const recuperarCertificacionesGrupo = ({ year, idGrupo }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const data = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.reportes.recuperarCertificacionesGrupo}/${year}/${idGrupo}`,
				method: "POST",
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const calcularPromedioPorCursoGrupo = ({ idGrupo }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const data = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.reportes.calcularPromedioPorCursoGrupo}/${idGrupo}`,
				method: "POST",
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCertificadosEmitidosPorCursoGrupo = ({ idGrupo }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const data = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.reportes.getCertificadosEmitidosPorCursoGrupo}/${idGrupo}`,
				method: "POST",
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCertificacionesMosAprobadasGrupo = ({ idGrupo }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const certificacionesAprobadas = await httpRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.reportes.getCertificacionesMosAprobadasGrupo}/${idGrupo}`,
				method: "POST",
			});

			resolve(certificacionesAprobadas);
		} catch (error) {
			reject(error);
		}
	});
};

export const generarReporteEmpresarial = (formData, folioContrato) => {
	return new Promise(async (resolve, reject) => {

		console.log(formData);
		console.log(folioContrato);
		try {
			const { data: reporte } = await httpFormDataRequest({
				url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.manejoArchivos.generarReporteEmpresarial}/${folioContrato}`,
				method: "POST",
				body: formData
			});

			resolve(reporte);
		} catch (error) {
			reject(error);
		}
	});
};