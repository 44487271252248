import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { addNotificacion } from "@helpers/httpRequests/httpRequestsNotificaciones/httpRequestsNotificaciones";

export const AgregarNotificacion = ({ setNotificacionAgregada }) => {
  const [formValues, setFormValues] = useState({
    titulo: "",
    descripcion: "",
  });

  const handleOnChange = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      });
    } catch (error) {
      console.log("Error en el onchange: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addNotificacion(formValues);

      Notificacion("success", "La notificación fue agregada correctamente");

      setNotificacionAgregada(true);
    } catch (error) {
      console.log("Error al agregar la notificacion: ", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid className="text-start">
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Título</Form.Label>

            <Form.Control
              value={formValues.titulo}
              onChange={handleOnChange}
              name="titulo"
              type="text"
              placeholder="Título de la notificación"
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col}>
            <Form.Label>Descripción</Form.Label>

            <Form.Control
              value={formValues.descripcion}
              onChange={handleOnChange}
              name="descripcion"
              type="text"
              placeholder="Descripci&oacute;n"
            />
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button variant="success" type="submit">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
