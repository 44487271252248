import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getImagenPerfil = (infoPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const imgPerfil = await httpRequest({
        url: `${endpointsPaths.perfiles}/${httpRequestsPaths.perfiles.getImagenPerfil}`,
        method: "POST",
        body: JSON.stringify(infoPerfil)
      });

      resolve(imgPerfil);
    } catch (error) {
      // console.log("Error el obtener la imagen de perfil: ", error);

      reject(error);
    }
  });
};
