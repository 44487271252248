import TitleDashboard from "@components/general/Titles/TitleDashboard";
import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ListaRetroalimentacion from "./ListaRetroalimentacion";

const Retroalimentacion = ({
  graficos,
  transformarDatos,
  relacionDatos,
  funcionesDax,
  funciones,
  analisisDatos,
  graficosExcel,
  macros,
  conocimientosGenerales,
  setLeccionesFunciones,
  setLeccionesAnalisisDatos,
  setLeccionesGraficosExcel,
  setLeccionesMacros,
  setLeccionesConocimientos,
  setLeccionesGraficos,
  setLeccionesTransformar,
  setLeccionesRelacion,
  setLeccionesFuncionesDax,
  codigoCursoSeleccionado,
}) => {
  //*States para los temas de la retroalimentacion

  useEffect(() => {
    const validacionTemas = () => {
      switch (funciones.length) {
        case 0:
        case 1:
          console.log("FUNCIONES EN 0");
          setLeccionesFunciones([
            {
              nombre: "¿Qué son las funciones?",
              codigoLeccion: "EX210301",
            },
            {
              nombre: "Insertar funciones",
              codigoLeccion: "EX210302",
            },
            {
              nombre: "Tipos de funciones",
              codigoLeccion: "EX210303",
            },
            {
              nombre: "Asignar nombre y rastreo",
              codigoLeccion: "EX210304",
            },
            {
              nombre: "Operadores y valores",
              codigoLeccion: "EX210305",
            },
          ]);
          break;

        case 2:
        case 3:
          setLeccionesFunciones([
            {
              nombre: "Paréntesis de celda",
              codigoLeccion: "EX210306",
            },
            {
              nombre: "Referencias de celda",
              codigoLeccion: "EX210307",
            },
            {
              nombre: "Funciones matemáticas",
              codigoLeccion: "EX210308",
            },
          ]);
          break;

        case 4:
          setLeccionesFunciones([
            {
              nombre: "Buscar V",
              codigoLeccion: "EA161301",
            },
            {
              nombre: "Condicional buscar V",
              codigoLeccion: "EA161302",
            },
            {
              nombre: "Registro duplicado buscar V",
              codigoLeccion: "EA161305",
            },
            {
              nombre: "SI y anidados",
              codigoLeccion: "EA161306",
            },
          ]);
          break;

        default:
          setLeccionesFunciones({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      //Validacion para analisis de datos

      switch (analisisDatos.length) {
        case 0:
          setLeccionesAnalisisDatos([
            {
              nombre: "Rango a tablas",
              codigoLeccion: "EX210601",
            },
            {
              nombre: "Opciones de estilos de tablas",
              codigoLeccion: "EX210605",
            },
            {
              nombre: "Nombre de la tabla",
              codigoLeccion: "EX210606",
            },
          ]);
          break;

        case 1:
        case 2:
          setLeccionesAnalisisDatos([
            {
              nombre: "Referencias estructuradas",
              codigoLeccion: "EX210607",
            },
            {
              nombre: "Botones de segmentación",
              codigoLeccion: "EX210610",
            },
            {
              nombre: "Selección multiple, borrar filtro, agregar columna",
              codigoLeccion: "EX210611",
            },
            {
              nombre: "Tamaño y posición",
              codigoLeccion: "EX210612",
            },
            {
              nombre: "Insertar gráficos",
              codigoLeccion: "EX210613",
            },
          ]);
          break;

        case 3:
        case 4:
          setLeccionesAnalisisDatos([
            {
              nombre: "Dashboards preparación de datos 1",
              codigoLeccion: "EI160601",
            },
            {
              nombre: "Dashboards preparación de datos 2",
              codigoLeccion: "EI160602",
            },
            {
              nombre: "Top 5 vendedores",
              codigoLeccion: "EI160603",
            },
            {
              nombre: "Gráficos en celda",
              codigoLeccion: "EI160604",
            },
            {
              nombre: "Top 5 productos",
              codigoLeccion: "EI160605",
            },
          ]);
          break;
        default:
          setLeccionesAnalisisDatos({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      //VALIDACIONES PARA GRAFICOS EN EXCEL

      switch (graficosExcel.length) {
        case 0:
        case 1:
          setLeccionesGraficosExcel([
            {
              nombre: "Insertar gráficos",
              codigoLeccion: "EX210613",
            },
            {
              nombre: "Gráficos recomendados",
              codigoLeccion: "EX210616",
            },
            {
              nombre: "Tamaño y posición",
              codigoLeccion: "EX210617",
            },
            {
              nombre: "Cambiar fila/columna",
              codigoLeccion: "EX210618",
            },
          ]);

          break;

        case 2:
        case 3:
          setLeccionesGraficosExcel([
            {
              nombre: "Cambiar tipo de gráfico",
              codigoLeccion: "EX210619",
            },
            {
              nombre: "Estilos y colores prediseñados",
              codigoLeccion: "EX210620",
            },
            {
              nombre: "Personalizar gráficos",
              codigoLeccion: "EX210621",
            },
            {
              nombre: "Colores de barra",
              codigoLeccion: "EX210622",
            },
            {
              nombre: "Efectos de barra",
              codigoLeccion: "EX210623",
            },
          ]);

          break;

        case 4:
          setLeccionesGraficosExcel([
            {
              nombre: "Primer gráfico dinámico",
              codigoLeccion: "EI160606",
            },
            {
              nombre: "Formato gráfico dinámico",
              codigoLeccion: "EI160607",
            },
            {
              nombre: "Segundo gráfico dinámico",
              codigoLeccion: "EI160608",
            },
            {
              nombre: "Insertar botones de segmentación",
              codigoLeccion: "EI160609",
            },
          ]);

          break;
        default:
          setLeccionesGraficosExcel({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      switch (macros.length) {
        case 0:
        case 1:
          setLeccionesMacros([
            {
              nombre: "Las macros en Excel",
              codigoLeccion: "EA160501",
            },
            {
              nombre: "La interfaz de Excel en BVA",
              codigoLeccion: "EA160601",
            },
            {
              nombre: "Cómo se crea el código BVA",
              codigoLeccion: "EA160602",
            },
          ]);
          break;

        case 2:
        case 3:
          setLeccionesMacros([
            {
              nombre: "Inserte botones y controles",
              codigoLeccion: "EA160701",
            },
            {
              nombre: "Aplicación de botones y controles 1",
              codigoLeccion: "EA160702",
            },
            {
              nombre: "Aplicación de botones y controles 2",
              codigoLeccion: "EA160703",
            },
            {
              nombre: "Aplicación de botones y controles 3",
              codigoLeccion: "EA160704",
            },
            {
              nombre: "Aplicación de una macro",
              codigoLeccion: "EA160705",
            },
            {
              nombre: "Diseñe una macro",
              codigoLeccion: "EA160706",
            },
          ]);
          break;

        case 4:
          setLeccionesMacros([
            {
              nombre: "Proyecto 2: Formato credenciales",
              codigoLeccion: "EA161310",
            },
            {
              nombre: "Proyecto 3: Macro para imprimir",
              codigoLeccion: "EA161311",
            },
            {
              nombre:
                "4. Señala la celda activa de forma automática: Fórmula y formato",
              codigoLeccion: "EA161411",
            },
            {
              nombre:
                "7. Crea hojas a partir de una lista: Programando la macro",
              codigoLeccion: "EA161422",
            },
            {
              nombre:
                "9. Rellena celdas en blanco como todo un experto: Iguaal a...",
              codigoLeccion: "EA161423",
            },
          ]);
          break;

        default:
          setLeccionesMacros({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      switch (conocimientosGenerales.length) {
        case 0:
        case 1:
          setLeccionesConocimientos([
            {
              nombre: "Primero contacto con Excel",
              codigoLeccion: "EX210101",
            },
            {
              nombre: 'Conociendo la "Cinta de opciones"',
              codigoLeccion: "EX210106",
            },
          ]);
          break;

        case 2:
        case 3:
          setLeccionesConocimientos([
            {
              nombre: "Plantillas",
              codigoLeccion: "EX210202",
            },
            {
              nombre: "Formato de fuente",
              codigoLeccion: "EX210203",
            },
            {
              nombre: "Estilos",
              codigoLeccion: "EX210204",
            },
            {
              nombre: "Borde, relleno y color de fuente",
              codigoLeccion: "EX210205",
            },
            {
              nombre: "Alimentación y orientación",
              codigoLeccion: "EX210206",
            },
            {
              nombre: "Combinar, centrar y ajustar texto",
              codigoLeccion: "EX210207",
            },
          ]);
          break;

        case 4:
          setLeccionesConocimientos([
            {
              nombre: "Click derecho",
              codigoLeccion: "EX210401",
            },
            {
              nombre: "Orden personalizado",
              codigoLeccion: "EX210402",
            },
            {
              nombre: "Orden por niveles",
              codigoLeccion: "EX210403",
            },
            {
              nombre: "Ordenar niveles y listas personalizadas",
              codigoLeccion: "EX210404",
            },
            {
              nombre: "Partes de una tabla dinámica",
              codigoLeccion: "EI160503",
            },
            {
              nombre: "Dar formato a una tabla dinámica",
              codigoLeccion: "EI160504",
            },
            {
              nombre: "Filtrar una tabla dinámica",
              codigoLeccion: "EI160505",
            },
          ]);
          break;

        default:
          setLeccionesConocimientos({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      switch (graficos.length) {
        case 0:
        case 1:
        case 2:
          setLeccionesGraficos([
            {
              nombre: "Creando gráficos",
              codigoLeccion: "PB210701",
            },
            {
              nombre: "Creando gráficos 2",
              codigoLeccion: "PB210702",
            },
          ]);
          break;

        case 3:
        case 4:
          setLeccionesGraficos([
            {
              nombre: "Controles de gráficos",
              codigoLeccion: "PB210703",
            },
            {
              nombre: "Segmentación de datos",
              codigoLeccion: "PB210704",
            },
          ]);
          break;

        case 5:
          setLeccionesGraficos([
            {
              nombre: "Formato de gráfico",
              codigoLeccion: "PB210902",
            },
            {
              nombre: "Formato de gráfico 2",
              codigoLeccion: "PB210903",
            },
            {
              nombre: "Formato de datos",
              codigoLeccion: "PB210904",
            },
            {
              nombre: "Efecto 3D",
              codigoLeccion: "PB210905",
            },
          ]);
          break;

        default:
          setLeccionesGraficos({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      switch (transformarDatos.length) {
        case 0:
        case 1:
        case 2:
          setLeccionesTransformar([
            {
              nombre: "Importar datos",
              codigoLeccion: "PB210401",
            },
            {
              nombre: "Editor de consultas",
              codigoLeccion: "PB210402",
            },
          ]);
          break;

        case 3:
        case 4:
          setLeccionesTransformar([
            {
              nombre: "Columnas calculadas",
              codigoLeccion: "PB210601",
            },
            {
              nombre: "Editar nombre de la columna",
              codigoLeccion: "PB210602",
            },
            {
              nombre: "Campos calculados",
              codigoLeccion: "PB210603",
            },
          ]);
          break;

        case 5:
          setLeccionesTransformar([
            {
              nombre: "Creando medidas con expresiones Dax",
              codigoLeccion: "PBIA0301",
            },
            {
              nombre: "Creando medidas con atajos del teclado",
              codigoLeccion: "PBIA0302",
            },
            {
              nombre: "Expresión simple diferencia",
              codigoLeccion: "PBIA0303",
            },
            {
              nombre: "Tablas medidas Dax",
              codigoLeccion: "PBIA0304",
            },
            {
              nombre: "Organizar por carpetas",
              codigoLeccion: "PBIA0305",
            },
          ]);
          break;

        default:
          setLeccionesTransformar({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      switch (relacionDatos.length) {
        case 0:
        case 1:
          setLeccionesRelacion([
            {
              nombre: "Bloques de creación básicos",
              codigoLeccion: "PB210301",
            },
            {
              nombre: "Importar datos",
              codigoLeccion: "PB210401",
            },
          ]);
          break;

        case 2:
        case 3:
          setLeccionesRelacion([
            {
              nombre: "Relación de datos",
              codigoLeccion: "PB210501",
            },
            {
              nombre: "Editar relación",
              codigoLeccion: "PB210502",
            },
            {
              nombre: "Administrar relaciones",
              codigoLeccion: "PB21030103",
            },
            {
              nombre: "Error al relacionar",
              codigoLeccion: "PB210504",
            },
          ]);
          break;

        case 4:
          setLeccionesRelacion([
            {
              nombre: "Relaciones en modelos de datos",
              codigoLeccion: "PBIA0101",
            },
            {
              nombre: "Modelos tipo embudo",
              codigoLeccion: "PBIA0102",
            },
            {
              nombre: "Ocultando conceptos repetidos",
              codigoLeccion: "PBIA0103",
            },
            {
              nombre: "Medidas implicitas",
              codigoLeccion: "PBIA0201",
            },
          ]);
          break;

        default:
          setLeccionesRelacion({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }

      switch (funcionesDax.length) {
        case 0:
        case 1:
        case 2:
          setLeccionesFuncionesDax([
            {
              nombre: "Creando medidas con expresiones Dax",
              codigoLeccion: "PBIA0301",
            },
            {
              nombre: "Creando medidas con atajos del teclado",
              codigoLeccion: "PBIA0302",
            },
            {
              nombre: "Expresión simple diferencia",
              codigoLeccion: "PBIA0303",
            },
          ]);
          break;

        case 3:
        case 4:
          setLeccionesFuncionesDax([
            {
              nombre: "Conociendo IF",
              codigoLeccion: "PBIA0501",
            },
            {
              nombre: "Condiciones múltiples",
              codigoLeccion: "PBIA0502",
            },
            {
              nombre: "Función OR",
              codigoLeccion: "PBIA0503",
            },
            {
              nombre: "Implementando IN",
              codigoLeccion: "PBIA0504",
            },
            {
              nombre: "Implementando NOT",
              codigoLeccion: "PBIA0505",
            },
          ]);
          break;

        case 5:
          setLeccionesFuncionesDax([
            {
              nombre: "Creando resúmenes con Dax",
              codigoLeccion: "PBIA0801",
            },
            {
              nombre: "Gráfico de líneas",
              codigoLeccion: "PBIA0802",
            },
            {
              nombre: "Porcentaje de devoluciones",
              codigoLeccion: "PBIA0803",
            },
            {
              nombre: "Promedio",
              codigoLeccion: "PBIA0804",
            },
          ]);
          break;

        default:
          setLeccionesFuncionesDax({
            nombre: "Sin lecciones asignadas",
            codigoLeccion: null,
          });
          break;
      }
    };

    validacionTemas();
  }, []);

  return (
    <Col>
      <TitleDashboard title={"Tu retroalimentación"} />

      <Row className="mt-4">
        <Col>
          <ListaRetroalimentacion
            graficos={graficos}
            transformarDatos={transformarDatos}
            relacionDatos={relacionDatos}
            funciones={funciones}
            funcionesDax={funcionesDax}
            analisisDatos={analisisDatos}
            graficosExcel={graficosExcel}
            macros={macros}
            conocimientosGenerales={conocimientosGenerales}
            codigoCursoSeleccionado={codigoCursoSeleccionado}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default Retroalimentacion;
