import { Notificacion } from "@components/general/Alertas/Alertas";
import {
  deactivateRutaAprendizaje,
  reactivateRutaAprendizaje,
} from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ReactivarRuta = ({ setRutaAprendizajeReactivada, ruta }) => {
  const { idRutaAprendizajeDefault: idRuta } = ruta;

  const cancelarReactivarRuta = () => {
    Notificacion("info", "No se reactivó la ruta");
    setRutaAprendizajeReactivada(false);
  };

  const desactivarRuta = async (e) => {
    e.preventDefault();
    try {
      const { message } = await reactivateRutaAprendizaje(idRuta);
      setRutaAprendizajeReactivada(true);
      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al reactivar la ruta");
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar la ruta seleccionada?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={desactivarRuta}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarReactivarRuta}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ReactivarRuta;
