import { getReportesComentariosByIdTema } from "@helpers/httpRequests/httpRequestsReportes/httpRequestsReportes";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

const VerInfoReporteComentario = ({ reporte }) => {
  console.log(reporte);

  const { textoComentario, alias, estatus, idRespuestaTema } = reporte;

  const [motivosReporte, setMotivosReporte] = useState([]);

  useEffect(() => {
    const obtenerMotivosReporte = async () => {
      try {
        const { data } = await getReportesComentariosByIdTema(idRespuestaTema);
        if (Array.isArray(data)) {
          setMotivosReporte(data);
        } else {
          setMotivosReporte([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerMotivosReporte();
  }, []);

  return (
    <Container className="text-start">
      <Row>
        <Col>
          <p>
            <b>Nuevo reporte de comentario</b>
          </p>
          <p>
            <b>Publicado por:</b> {alias}
          </p>
          <p>
            <b>Contenido del tema:</b> "{textoComentario}"
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Motivo del reporte</th>
                <th>Veces reportadas</th>
              </tr>
            </thead>
            <tbody>
              {motivosReporte.map((motivoReporte, key) => (
                <tr key={key}>
                  <td>{motivoReporte.motivo}</td>
                  <td>{motivoReporte.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p>
            <b>Estatus del reporte:</b>{" "}
            <span className={estatus === 1 ? "text-danger" : "text-success"}>
              {estatus === 1 ? "Por atender" : "Atendido"}
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default VerInfoReporteComentario;
