import { Notificacion } from "@components/general/Alertas/Alertas";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { addRutaAprendizajePersonalizada } from "@helpers/httpRequests/httpRequestsRutasAprendizajePersonalizadas/httpRequestsRutasPersonalizadas";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";

const AgregarRuta = ({ setRutaPersonalizadaAgregada, idUsuario }) => {
  const [sinRecursos, setSinRecursos] = useState(false);

  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const [selectedSeccion, setSelectedSeccion] = useState(null);

  const [selectedLeccion, setSelectedLeccion] = useState(null);

  const [selectSeccionesDeshabilitado, setSelectSeccionesDeshabilitado] =
    useState(true);

  const [selectLeccionesDeshabilitado, setSelectLeccionesDeshabilitado] =
    useState(true);

  const [leccionesRepetidas, setLeccionesRepetidas] = useState({
    status: false,
    razon: "",
  });

  const [formValues, setFormValues] = useState({
    nombreRuta: "",
    nombreCurso: "",
    codigoCurso: "",
    nombreSeccion: "",
    codigoSeccion: "",
    nombreLeccion: "",
    codigoLeccion: "",
    descripcionRuta: "",
  });

  const [botonAgregarDeshabilitado, setBotonAgregarDeshabilitado] =
    useState(true);

  const [listaLecciones, setListaLecciones] = useState([]);

  const [listarTodo, setListarTodo] = useState(false);

  const obtenerLecciones = async (codigoLeccion) => {
    try {
      setSinRecursos(false);
      const { data } = await getLeccionesByCodigoSeccion(codigoLeccion);
      // console.log(data);

      if (Array.isArray(data)) {
        setLecciones(data);
      } else {
        setLecciones([data]);
      }

      setSelectLeccionesDeshabilitado(false);
    } catch (error) {
      setSinRecursos(true);
      console.log(error);
    }
  };

  const obtenerSecciones = async (codigoCurso) => {
    try {
      setSinRecursos(false);
      const { data } = await getSeccionesByCodigoCurso(codigoCurso);
      if (Array.isArray(data)) {
        setSecciones(data);
      } else {
        setSecciones([data]);
      }
      setSelectSeccionesDeshabilitado(false);
    } catch (error) {
      setSinRecursos(true);
      console.log(error);
    }
  };

  const handleSelectOnChange = ({ target }) => {
    const { name, value } = target;

    const arregloValue = value.split("/"); //arregloValue[0] = valor del identificador / arregloValue[1] = valor del nombreCurso

    const arregloName = name.split("/"); //arregloName[0] = 'identificador' / arregloName[1] = 'nombreCurso'

    if (arregloName[0] === "codigoCurso") {
      obtenerSecciones(arregloValue[0]);
      setSelectedSeccion(0);
      setSelectedLeccion(0);
    }
    if (arregloName[0] === "codigoSeccion") {
      obtenerLecciones(arregloValue[0]);
      setSelectedSeccion(value);
      setSelectedLeccion(0);
    }
    if (arregloName[0] === "codigoLeccion") {
      setBotonAgregarDeshabilitado(false);
      setSelectedLeccion(value);
    }

    setFormValues({
      ...formValues,
      [arregloName[1]]: arregloValue[1],
      [arregloName[0]]: arregloValue[0],
    });
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const agregarLeccion = () => {
    // console.log(formValues.codigoLeccion);

    const duplicados = listaLecciones.find((object, index) => {
      return listaLecciones
        .slice(index)
        .some(
          (otherObject) => object.codigoLeccion === formValues.codigoLeccion
        );
    });

    if (duplicados === undefined) {
      setLeccionesRepetidas({
        status: false,
        razon: "",
      });
      return setListaLecciones([
        ...listaLecciones,
        ...lecciones.filter(
          (leccion) => leccion.codigoLeccion === formValues.codigoLeccion
        ),
      ]);
    }

    setLeccionesRepetidas({
      status: true,
      razon: "Las lecciones no se pueden repetir, elige otra.",
    });

    setFormValues({
      ...formValues,
      nombreCurso: "",
      codigoCurso: "",
      nombreSeccion: "",
      codigoSeccion: "",
      nombreLeccion: "",
      codigoLeccion: "",
    });
  };

  const handleListarTodo = () => {
    const arregloLeccionesAListar = [];

    const arregloLeccionesListadas = [];

    listaLecciones.map((leccionListada) => {
      arregloLeccionesListadas.push(leccionListada.codigoLeccion);
    });

    lecciones.map((leccionAListar) => {
      arregloLeccionesAListar.push(leccionAListar.codigoLeccion);
    });

    const validacionRepetidas = arregloLeccionesAListar.map(
      (leccionAListar) => {
        const repetidas = arregloLeccionesListadas.includes(leccionAListar);
        if (repetidas) {
          return true;
        }
      }
    );

    if (validacionRepetidas.includes(true)) {
      setLeccionesRepetidas({
        status: true,
        razon:
          "Parece que ya tienes listada una o más lecciones de la sección, asegurate de que no se repitan",
      });

      setFormValues({
        ...formValues,
        nombreCurso: "",
        codigoCurso: "",
        nombreSeccion: "",
        codigoSeccion: "",
        nombreLeccion: "",
        codigoLeccion: "",
      });
    } else {
      setListaLecciones([...listaLecciones, ...lecciones]);

      setLeccionesRepetidas({
        status: false,
        razon: "",
      });
    }
  };

  const eliminarLeccion = ({ target }) => {
    const { name, id } = target;

    const indice = parseInt(id);

    let nuevasLecciones = [...listaLecciones];

    nuevasLecciones.splice(indice, 1);

    setListaLecciones(nuevasLecciones);
  };

  const guardarNuevaRuta = async (e) => {
    e.preventDefault();
    try {
      let obj = listaLecciones.map((leccion) => ({
        nombre: leccion.nombre,
        codigoLeccion: leccion.codigoLeccion,
      }));

      //* Esta información será la que se esté mandando a la bd para su registro.
      const infoRuta = {
        idUsuario: idUsuario,
        lecciones: JSON.stringify(obj).replace(/\\"/g, ""),
        nombreRuta: formValues.nombreRuta,
        descripcionRuta: formValues.descripcionRuta,
      };

      const { message, data } = await addRutaAprendizajePersonalizada(infoRuta);

      // console.log(data);

      // console.log(infoRuta);

      setRutaPersonalizadaAgregada(true);

      Notificacion("success", message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();
        // console.log(data);
        const cursos = data.filter(
          (curso) =>
            curso.codigoCurso !== "EXUI" &&
            curso.codigoCurso !== "EAUI" &&
            curso.codigoCurso !== "EIUI" &&
            curso.codigoCurso !== "PBUI" &&
            curso.codigoCurso !== "PBUA"
        );

        setCursos(cursos);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursos();
  }, []);

  return (
    <Form onSubmit={guardarNuevaRuta}>
      <Container fluid className="py-5">
        <Row>
          <Col>
            <Form.Group>
              <Form.Control
                className="input-form"
                style={{ fontSize: "1rem" }}
                type="text"
                name="nombreRuta"
                value={formValues.nombreRuta}
                onChange={handleOnChange}
                placeholder="Nombre de la ruta"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Form.Group className="mb-4">
              <Form.Select
                style={{ fontSize: "1rem" }}
                name="codigoCurso/nombreCurso"
                onChange={handleSelectOnChange}
                defaultValue={0}
              >
                <option value={0}>Seleccione un curso</option>

                {cursos.map((curso, key) => (
                  <option
                    key={key}
                    value={`${curso.codigoCurso}/${curso.nombre}`}
                  >
                    {curso.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Select
                value={selectedSeccion}
                style={{ fontSize: "1rem" }}
                disabled={selectSeccionesDeshabilitado}
                name="codigoSeccion/nombreSeccion"
                onChange={handleSelectOnChange}
                defaultValue={0}
              >
                <option value={0}>Seleccione una sección</option>

                {secciones.map((seccion, key) => (
                  <option
                    key={key}
                    value={`${seccion.codigoSeccion}/${seccion.nombre}`}
                  >
                    {seccion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Select
                value={selectedLeccion}
                style={{ fontSize: "1rem" }}
                disabled={selectLeccionesDeshabilitado}
                name="codigoLeccion/nombreLeccion"
                onChange={handleSelectOnChange}
                defaultValue={0}
              >
                <option value={0}>Seleccione una lección</option>

                {lecciones.map((leccion, key) => (
                  <option
                    key={key}
                    value={`${leccion.codigoLeccion}/${leccion.nombre}`}
                  >
                    {leccion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <div className="d-grid gap-2">
              <Button
                className="btn-form-rutas mb-2"
                style={{ fontSize: "12px" }}
                disabled={botonAgregarDeshabilitado}
                size="lg"
                onClick={agregarLeccion}
                variant="success"
              >
                Agregar
              </Button>

              <Button
                className="btn-form-rutas"
                style={{ fontSize: "12px" }}
                onClick={handleListarTodo}
                size="lg"
                disabled={selectLeccionesDeshabilitado ? "disabled" : ""}
              >
                Agregar todas las lecciones
              </Button>
            </div>

            {leccionesRepetidas && (
              // <Col className="col-12">
              <small className="text-danger alert-rutas">
                {leccionesRepetidas.razon}
              </small>
              // </Col>
            )}

            {sinRecursos && (
              // <Col className="col-12">
              <small className="text-danger alert-rutas">
                El curso selecionado no contiene secciones o lecciones
                disponible, eliga otro.
              </small>
              // </Col>
            )}
          </Col>

          <Col className="mt-4">
            <div className="p-3 text-start contenedor-rutas ">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <h6>
                        <b>Lecciones agregadas</b>
                      </h6>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listaLecciones.length > 0 ? (
                    <>
                      {listaLecciones.map((leccion, key) => (
                        <tr key={leccion.codigoLeccion}>
                          <td className="btn-form-rutas">{leccion.nombre}</td>
                          <td className="text-center">
                            <Button
                              name={leccion.nombre}
                              id={key}
                              onClick={eliminarLeccion}
                              variant="danger"
                              className="btn-sm m-1 "
                            >
                              <FontAwesomeIcon
                                className="btn-form-rutas"
                                style={{ fontSize: "12px" }}
                                icon={faTrash}
                              />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>
                        <h6>Aún no tienes lecciones agregadas</h6>
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Form.Group>
              {/* <Form.Label>
								<h5>Descripción de la ruta</h5>
							</Form.Label> */}

              <Form.Control
                className="input-form"
                style={{ height: "100px", fontSize: "1rem" }}
                name="descripcionRuta"
                value={formValues.descripcionRuta}
                onChange={handleOnChange}
                as="textarea"
                rows={3}
                placeholder="Agrega una descripción a la ruta"
              />
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button className="btn-responsive" size="lg" type="submit">
                Guardar ruta
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AgregarRuta;
