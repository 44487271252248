import moment from "moment";


export const areSameDate = (unix1, unix2) => {
 // Convertir las fechas Unix a objetos moment
 const fechaMoment1 = moment.unix(unix1);
 const fechaMoment2 = moment.unix(unix2);

 // Obtener las fechas en formato 'yyyy-mm-dd'
 const fechaFormateada1 = fechaMoment1.format('YYYY-MM-DD');
 const fechaFormateada2 = fechaMoment2.format('YYYY-MM-DD');

 // Comparar si las fechas formateadas son iguales
 const sonIguales = fechaFormateada1 === fechaFormateada2;

 return sonIguales;
}