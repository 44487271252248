import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getUnidadesCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: unidades } = await httpRequest({
        url: `${endpointsPaths.cursoIngles}/${httpRequestsPaths.cursoIngles.unidades.getUnidadesCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(unidades);
    } catch (error) {
      // console.log("Error al obtener las unidades del curso: ", error);

      reject(error);
    }
  });
};

export const getLeccionesCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: lecciones } = await httpRequest({
        url: `${endpointsPaths.cursoIngles}/${httpRequestsPaths.cursoIngles.lecciones.getLeccionesCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(lecciones);
    } catch (error) {
      // console.log("Error al obtener las unidades del curso: ", error);

      reject(error);
    }
  });
};

export const getLeccionesByUnidad = async (unidad, info) => {
  return new Promise(async (resolve, reject) => {
    try {
      const lecciones = await httpRequest({
        url: `${endpointsPaths.cursoIngles}/${httpRequestsPaths.cursoIngles.lecciones.getLeccionesByUnidad}/${unidad}`,
        method: "POST",
        body: JSON.stringify(info),
      });

      resolve(lecciones);
    } catch (error) {
      // console.log("Error al obtener las unidades del curso: ", error);

      reject(error);
    }
  });
};

export const getLeccionByCodigoIngles = async (codigoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccion } = await httpRequest({
        url: `${endpointsPaths.cursoIngles}/${httpRequestsPaths.cursoIngles.lecciones.getLeccionByCodigo}/${codigoLeccion}`,
        method: "POST",
      });

      resolve(leccion);
    } catch (error) {
      // console.log("Error al obtener las unidades del curso: ", error);

      reject(error);
    }
  });
};

export const updatePerfilExterno = async (infoUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccion } = await httpRequest({
        url: `${endpointsPaths.cursoIngles}/${httpRequestsPaths.cursoIngles.updateInfo.updateEmail}`,
        method: "POST",
        body: JSON.stringify(infoUsuario),
      });

      resolve(leccion);
    } catch (error) {
      // console.log("Error al obtener las unidades del curso: ", error);

      reject(error);
    }
  });
};

export const updateNombreUsuarioExterno = async (infoUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccion } = await httpRequest({
        url: `${endpointsPaths.cursoIngles}/${httpRequestsPaths.cursoIngles.updateInfo.updateUsuario}`,
        method: "POST",
        body: JSON.stringify(infoUsuario),
      });

      resolve(leccion);
    } catch (error) {
      // console.log("Error al obtener las unidades del curso: ", error);

      reject(error);
    }
  });
};
