import {
  Notificacion,
  NotificacionExtendida,
} from "@components/general/Alertas/Alertas";
import { deleteNipPerfil } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarNipSeguridad = ({
  idPerfil,
  setNipActivo,
  contextValue,
  setContextValue,
}) => {
  const handleDeleteNip = async () => {
    try {
      await deleteNipPerfil(idPerfil);

      Notificacion("success", "El NIP se eliminó correctamente");

      setNipActivo(false);

      setContextValue({
        ...contextValue,
        usuarioLogeado: {
          ...contextValue.usuarioLogeado,
          nip: "",
        },
      });
    } catch (error) {
      console.log("Error al eliminar el nip: ", error);

      NotificacionExtendida("error", "No se pudo eliminar el NIP de seguridad");
    }
  };

  const cancelarDeleteNip = () => {
    Notificacion("info", "La eliminación de NIP ha sido cancelada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de eliminar tu NIP de seguridad?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleDeleteNip}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeleteNip}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarNipSeguridad;
