import React from 'react'

const muteToggle = ( { volume, setVolume }) => {
  const handleClickMute = (event) => {
    if (volume === 0) {
      setVolume(1)
    }
    else {
      setVolume(0)
    }
  }

  if (volume === 0) {
    return(
            <button className="display__mute"
                    id="muteButton"
                    title="mute button"
                    onClick={handleClickMute}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd"/><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"/></svg>
            </button>
          )
  }

  else {
    return(
      <button className="display__mute"
              id="muteButton"
              title="mute button"
              onClick={handleClickMute}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"/></svg>
      </button>
    )
  }
}

export default muteToggle