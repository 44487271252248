import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import columnasCodigosInvitado from "./columnasCodigosInvitado";

const TableCodigosInvitados = ({ codigosInvitados }) => {
  const [dataTableColumns, setDataTableColumns] = useState(
    columnasCodigosInvitado
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <DataTablesRecipient
            columns={dataTableColumns}
            data={codigosInvitados}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TableCodigosInvitados;
