import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { getLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import { Col, Container, Row } from "react-bootstrap";

const VerInvitado = ({ invitado }) => {
  const [datosLicencia, setDatosLicencia] = useState([]);

  const [cursosLicencia, setCursosLicencia] = useState(
    JSON.parse(invitado.cursos)
  );

  const [tiempoVigencia, setTiempoVigencia] = useState({
    diasVigencia: "",
    mesesVigencia: "",
  });

  const { diasVigencia, mesesVigencia } = tiempoVigencia;

  useEffect(() => {
    const consultarDatosLicencia = async () => {
      try {
        const { data: licencia } = await getLicencia(invitado.idLicencia);

        setDatosLicencia(licencia);

        // setCursosLicencia(JSON.parse(licencia.cursos));
      } catch (error) {
        console.log("Error al consultar los datos de la licencia: ", error);
      }
    };

    const calcularTiempoVigencia = () => {
      const registrationDateMoment = moment
        .unix(invitado.fechaRegistro)
        .utcOffset("-06:00");

      const expirationDateMoment = registrationDateMoment.add(
        datosLicencia.tiempoVigencia,
        "months"
      );

      const currentMoment = moment();

      const monthsLeft = expirationDateMoment.diff(currentMoment, "months");

      const daysLeft = expirationDateMoment.diff(currentMoment, "days");

      setTiempoVigencia({
        diasVigencia: daysLeft,
        mesesVigencia: monthsLeft,
      });
    };

    consultarDatosLicencia();

    calcularTiempoVigencia();
  }, []);

  return (
    <Container fluid className="text-start">
      <Row>
        <Col>
          <h3>Datos personales</h3>
          <hr />

          <Container fluid>
            <Row>
              <Col>
                <p>
                  <b>Nombre:</b> {invitado.nombreCompleto} {invitado.apellidos}
                </p>

                <p>
                  <b>Correo electrónico:</b> {invitado.correo}
                </p>
              </Col>

              <Col>
                <p>
                  <b>Nombre de usuario:</b> {invitado.nombreUsuario}
                </p>

                <p>
                  <b>Profesión:</b>{" "}
                  {invitado.profesion !== ""
                    ? invitado.profesion
                    : "No especificado"}
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Datos de la Licencia</h3>
          <hr />

          <Container fluid>
            <Row>
              <Col>
                <p>
                  <b>Fecha de registro: </b>{" "}
                  {getDateFromUnix(invitado.fechaRegistro)}
                </p>

                <p>
                  <b>Fecha último acceso: </b>{" "}
                  {invitado.fechaUltimoAcceso !== 0
                    ? getDateFromUnix(invitado.fechaUltimoAcceso)
                    : "Sin ingresos registrados"}
                </p>
              </Col>

              <Col>
                <p>
                  <b>Licencia: </b> {datosLicencia.nombre}
                </p>

                <p>
                  <b>Tiempo de vigencia: </b> Acceso permanente
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <b>Cursos de la licencia</b>

                <Container fluid className="p-0">
                  <Row>
                    {(() => {
                      const chunks = _.chunk(
                        cursosLicencia,
                        cursosLicencia.length / 2
                      );

                      return chunks.map((chunk, idxChunk) => (
                        <Col key={idxChunk} className="p-0">
                          <ul>
                            {chunk.map((curso, idxCurso) => (
                              <li key={idxCurso}>
                                <h6>{curso.nombre}</h6>
                              </li>
                            ))}
                          </ul>
                        </Col>
                      ));
                    })()}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default VerInvitado;
