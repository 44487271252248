import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

const columnas = [
  {
    name: "Código de curso", //nombre de la columna
    selector: (row) =>
      codigoCursosUsuarioInvitado.find(
        (invitado) => invitado.codigoInvitado === row.codigoCurso
      ) !== undefined
        ? `${row.codigoCurso} - Usuarios Invitados`
        : row.codigoCurso, //identificador de la columna
    sortable: true,
    center: true,
  },
  {
    name: "Código de sección", //nombre de la columna
    selector: (row) => row.codigoSeccion, //identificador de la columna
    sortable: true,
    center: true,
  },
  {
    name: "Nombre de sección", //nombre de la columna
    selector: (row) => row.nombre, //identificador de la columna
    sortable: true,
  },
  {
    name: "Nivel de sección",
    selector: (row) => row.nivel,
    sortable: true,
    center: true,
  },
];

export default columnas;
