const typesTiposUsuario = {
  asesor: "asesor",
  estudiante: "estudiante",
  empleado: "empleado",
  administrador: "administrador",
  vendedor: "vendedor",
  invitado: "invitado",
  cortesia: "cortesia",
  lecturaVeloz: "lectura_veloz",
  capacitador: "capacitador",
  todosUsuarios: [
    "asesor",
    "estudiante",
    "empleado",
    "administrador",
    "capacitador",
    "vendedor",
  ],
  usuariosEmpresariales: ["asesor", "empleado", "administrador", "capacitador"],
  usuariosPrueba: ["asesor", "administrador", "estudiante"],
};

export default typesTiposUsuario;
