import { Typography } from "@mui/material";
import React from "react";
import { Col } from "react-bootstrap";

const AvisoProductoDigital = () => {

	const ImagenesCertificacionMos = () => {
		return (
			<div className="mt-4 d-flex justify-content-center align-items-center">
				<picture className="pe-5">
					<source srcSet={require("@assets/images/compra/mos/mos-certification-logo.png")} type="image/png" />

					<img alt="Tarjetas aceptadas" className="d-block" loading="lazy" src={require("@assets/images/compra/mos/mos-certification-logo.webp")} width="150" />
				</picture>

				<picture>
					<source srcSet={require("@assets/images/compra/mos/logo-certiport.png")} type="image/png" />

					<img alt="Tarjetas aceptadas" className="d-block" loading="lazy" src={require("@assets/images/compra/mos/logo-certiport.webp")} width="250" />
				</picture>
			</div>
		);
	};

	return (
		<>
			<Col sm={12} lg className="d-none d-md-block">
				<Typography gutterBottom variant="caption">
					<span className="text-danger">*</span> Dejarás de recibir actualizaciones en todo nuestro contenido después de 18 meses
				</Typography>

				<br />

				<Typography gutterBottom variant="caption">
					<span className="text-danger">*</span> No podrás cambiar la información de ninguno de tus usuarios adquiridos.
				</Typography>

				{/* <h5></h5> */}

                {/* <ImagenesCertificacionMos /> */}
			</Col>

			<Col sm={12} lg className="d-md-none">
				<p></p>

				<p></p>
                
                {/* <ImagenesCertificacionMos /> */}
			</Col>
		</>
	);
};

export default AvisoProductoDigital;
