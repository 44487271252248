import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getCursos = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: cursos } = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.getCursos}`,
        method: "GET",
      });

      resolve(cursos);
    } catch (error) {
      // console.log("Error al obtener los cursos: ", error);

      reject(error);
    }
  });
};

export const getCursosPapelera = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: cursos } = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.getCursosPapelera}`,
        method: "GET",
      });

      resolve(cursos);
    } catch (error) {
      // console.log("Error al obtener los cursos: ", error);

      reject(error);
    }
  });
};

export const getCursosByIdCategoria = async (idCategoria) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: cursos } = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.getCursosByIdCategoria}/${idCategoria}`,
        method: "GET",
      });

      resolve(cursos);
    } catch (error) {
      // console.log("Error al obtener los cursos: ", error);

      reject(error);
    }
  });
};

export const getNombreCursos = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const nombreCursos = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.getNombreCursos}`,
        method: "GET",
      });
      resolve(nombreCursos);
    } catch (error) {
      reject(error);
    }
  });
};

export const addCurso = async (curso, licensesCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: cursoAgregado } = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.addCurso}`,
        method: "POST",
        body: JSON.stringify(curso),
      });

      resolve(cursoAgregado.affectedRows);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};

// export const handleNameCourse = async (nombre) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const validacionNombre = await httpRequest({
// 				url: `${}/${ endpointsPaths.validaciones}/validarNombreCurso`,
// 				method: "POST",
// 				body: JSON.stringify({ nombre }),
// 			});

// 			resolve(validacionNombre);
// 		} catch (error) {
// 			console.log("Error al validar el nombre del curso: ", error);

// 			reject(error);
// 		}
// 	});
// };

// export const handleCodeCourse = async (codigoCurso) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const validacionCodigoCurso = await httpRequest({
// 				url: `${ endpointsPaths.validaciones}/validarCodigoCurso`,
// 				method: "POST",
// 				body: JSON.stringify({ codigoCurso }),
// 			});

// 			resolve(validacionCodigoCurso);
// 		} catch (error) {
// 			console.log("Error al validar el código del curso: ", error);

// 			reject(error);
// 		}
// 	});
// };

export const getCursoByCodigo = (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: curso } = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.getCursoByCodigo}/${codigoCurso}`,
        method: "POST",
      });

      resolve(curso);
    } catch (error) {
      // console.log("Error al obtener el curso: ", error);

      reject(error);
    }
  });
};

export const updateCurso = async (curso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cursoActualizado = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.updateCurso}`,
        method: "POST",
        body: JSON.stringify(curso),
      });

      resolve(cursoActualizado);
    } catch (error) {
      // console.log("Error al actualizar el curso: ", error);

      reject(error);
    }
  });
};

export const deactivateCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cursoDesactivado = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.deactivateCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(cursoDesactivado);
    } catch (error) {
      // console.log("Error al actualizar el curso: ", error);

      reject(error);
    }
  });
};

export const deleteCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cursoDesactivado = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.deleteCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(cursoDesactivado);
    } catch (error) {
      // console.log("Error al actualizar el curso: ", error);

      reject(error);
    }
  });
};

export const reactivateCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cursoReactivado = await httpRequest({
        url: `${endpointsPaths.cursos}/${httpRequestsPaths.cursos.reactivateCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(cursoReactivado);
    } catch (error) {
      // console.log("Error al reactivar el curso: ", error);

      reject(error);
    }
  });
};
