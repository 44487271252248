import "@assets/css/main_style.css";

import PlataformaClickPlus from "PlataformaClickPlus";

//* Configuración para React 17
// import React from "react";
// import ReactDOM from "react-dom";

// ReactDOM.render(
// 	//<React.StrictMode>
// 		<PlataformaClickPlus />,
// 	//</React.StrictMode>,
// 	document.getElementById("root")
// );

//* Configuración para React 18
import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<PlataformaClickPlus />);
