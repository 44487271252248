import UserContext from "@context/User/UserContext";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../CompraLicencia/detalleCompra/header/Header";
import Footer from "../CompraLicencia/detalleCompra/footer/Footer";
import Body from "./body/Body";

const DetalleRenovacion = () => {
	

	return (
		<Row className="py-4">
			<Col className="compra-prices">
				<Container fluid className="detalle-compra">
					<Header />

					<Body />

					<Footer />
				</Container>
			</Col>
		</Row>
	);
};

export default DetalleRenovacion;
