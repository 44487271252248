import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "@assets/css/components/Misiones/style.css";

const Terminos = () => {
	return (
		<Container className="text-start">
			<Row>
				<Col>
					<p>¡Bienvenidos a la dinámica de Misiones! Antes de participar, es crucial comprender y aceptar los siguientes términos y condiciones que regirán este emocionante certamen:</p>

					<p>
						<span className="resaltado-terminos">Elegibilidad:</span>
						<br />
						El concurso está abierto a todas las personas que conforman la comunidad Click+. Cada participante debe presentar un proyecto original, que no haya sido previamente publicado ni premiado
						en otros concursos.
					</p>
					<p>
						<span className="resaltado-terminos">Inscripción:</span>
						<br />
						La inscripción es gratuita y se realiza a través de la misma plataforma, en la sección “Misiones”. Cada proyecto tendrá sus propias indicaciones de la actividad a realizar y la fecha de
						vigencia. Los usuarios que deseen entrar al concurso deberán registrar su proyecto a través de la opción “Participar” que se encuentra en cada actividad, siguiendo puntualmente los pasos
						que se describen dentro del mismo. Cada usuario podrá participar como máximo en dos proyectos por quincena.
					</p>
					<p>
						<span className="resaltado-terminos">Formato del Proyecto:</span>
						<br />
						Debido a que puede variar el programa/herramienta a utilizar, en cada proyecto se explicará el objetivo y se deberá dar una solución, eficiente y/o innovadora, en el formato específico que
						se requiera. Se aceptarán archivos que cumplan con los requisitos establecidos. Antes de seleccionar el archivo participante, deberá ser revisado por el usuario para asegurarse de subir la
						versión final, ya que no se podrán realizar modificaciones.
					</p>
					<p>
						<span className="resaltado-terminos">Plazos:</span>
						<br />
						Dentro de las especificaciones de cada proyecto se dará a conocer la fecha límite para participar. No habrá ningún tipo de prórroga o tiempo de tolerancia para subir el archivo
						participante.
					</p>
					<p>
						<span className="resaltado-terminos">Evaluación:</span>
						<br />
						El concurso se llevará a cabo independientemente del número de participantes que se haya inscrito. Nuestros asesores de soporte técnico serán quienes seleccionen el mejor proyecto, donde
						se tomará en cuenta innovación, viabilidad, utilidad y calidad de la presentación. Las decisiones del jurado son finales y no estarán sujetas a apelación.
					</p>
					<p>
						<span className="resaltado-terminos">Propiedad Intelectual:</span>
						<br />
						Los participantes conservan los derechos de propiedad intelectual de sus proyectos, pero otorgan a los organizadores una licencia no exclusiva para utilizar, reproducir y promocionar los
						proyectos con fines relacionados con el concurso.
					</p>
					<p>
						<span className="resaltado-terminos">Premios:</span>
						<br />
						Se elegirá un ganador para cada proyecto, el cual, acumulará 18 000 puntos, en caso contrario acumulará 6 000 por participar. Los puntos son acumulables pero no podrán ser transferidos a
						otra persona. El ganador será notificado a través de correo electrónico y además se dará a conocer en la pestaña “Salón de la fama”. Una vez que haya acumulado los créditos necesarios
						podrá canjear por premios disponibles en la sección “Recompensas”, a través del botón “Reclamar”. Cada premio tiene un costo de 30 000 créditos.
					</p>
					<p>
						<span className="resaltado-terminos">Publicidad y Divulgación:</span>
						<br />
						Los participantes aceptan que sus nombres y proyectos pueden ser utilizados con fines publicitarios y de divulgación sin compensación adicional.
					</p>
					<p>
						<span className="resaltado-terminos">Incumplimiento:</span>
						<br />
						El incumplimiento de cualquiera de los términos y condiciones puede resultar en la descalificación del participante o la revocación del premio.
					</p>
					<p>
						<span className="resaltado-terminos">Modificaciones:</span>
						<br />
						Los organizadores se reservan el derecho de realizar modificaciones a estos términos y condiciones en cualquier momento. Los participantes serán notificados de cualquier cambio.
					</p>
					<p className="text-center mt-5 mb-5">
						<b>Les animamos a desarrollar ideas innovadoras y a contribuir al aprendizaje. ¡Buena suerte a todos los participantes!</b>
					</p>

					<p>
						<small>Al participar en este concurso, los concursantes aceptan plenamente estos términos y condiciones.</small>
					</p>
				</Col>
			</Row>
		</Container>
	);
};

export default Terminos;
