import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AgregarLicencia } from "./Tabs/AgregarLicencia/AgregarLicencia";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import TableLicencias from "./DataTable/TableLicencias";

const DashboardLicencias = () => {
  const handleAgregarLicencia = async () => {
    try {
      await ModalSweetAlert({
        title: "Agregar nueva licencia",
        html: <AgregarLicencia setLicenciaAgregada={setLicenciaAgregada} />,
        width: 800,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar la licencia: ", error);
    }
  };

  const [licenciaAgregada, setLicenciaAgregada] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col align="right">
          <Button variant="primary" onClick={handleAgregarLicencia}>
            Agregar licencia
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TableLicencias
            licenciaAgregada={licenciaAgregada}
            setLicenciaAgregada={setLicenciaAgregada}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLicencias;
