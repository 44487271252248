import { faArrowRight, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { BsPlusCircleFill } from "react-icons/bs";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
const RutasPersonalizadas = ({
  handleAgregarRutaPersonalizada,
  rutas,
  rutaDeAprendizaje1,
  eliminarRutaPersonalizada,
  modificarRutaPersonalizada,
  listarLecciones,
}) => {
  return (
    <>
      <Row>
        <Col>
          <Row className="mb-3">
            <Col className="text-capitalize">
              <TitleDashboard title="Rutas personalizadas" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-between mt-4">
        <Col className="col-8 col-sm-5 col-md-5 personalizadas">
          <Button
            className="p-2 nueva-ruta"
            onClick={handleAgregarRutaPersonalizada}
          >
            <h6>
              Agregar nueva ruta{" "}
              <BsPlusCircleFill
                style={{
                  marginLeft: "5px",
                  textAlign: "center",
                  color: "white",
                }}
              />
            </h6>
          </Button>
        </Col>

        {rutas.length > 0 && (
          <Col className="col-4 justify-content-center d-flex align-items-center  ">
            <span className="m-0 text-center">
              Desliza para ver más{" "}
              <FontAwesomeIcon className="mx-2" icon={faArrowRight} />{" "}
            </span>
          </Col>
        )}
      </Row>
      <Row className="mb-5 pb-5 justify-content-center justify-content-sm-start ">
        <Col className=" mt-2">
          <Container fluid className="p-0">
            <Row>
              <Col className="col-rutas">
                {rutas.length > 0 ? (
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={5}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                      },
                      1440: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                      },
                      2000: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper swiper-rutas"
                  >
                    <>
                      {rutas.map((ruta, key) => (
                        <SwiperSlide key={key}>
                          {/* <button style={{ borderRadius: "0px" }} className="btn-sm"></button> */}

                          <Card id={key} className=" mb-4 card-rutas ">
                            <img
                              src={`${require("@assets/images/portadas_rutasAprendizaje/miRutaAprendizaje.png")}`}
                              alt="imagen leccion"
                            />
                            <Card.Body className="info">
                              <DropdownButton
                                style={{ fontSize: "10px"}}
                                className="text-end menu-ruta"
                                id={`dropdown-button-drop-left`}
                                drop={"left"}
                                variant="secondary"
                                title={
                                  <FontAwesomeIcon
                                    className="pointer ellipsis"
                                    icon={faEllipsisV}
                                  />
                                }
                              >
                                <Dropdown.Item
                                  onClick={eliminarRutaPersonalizada}
                                  id={key}
                                  eventKey="1"
                                >
                                  <h6>Eliminar</h6>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={modificarRutaPersonalizada}
                                  id={key}
                                  eventKey="1"
                                >
                                  <h6>Modificar</h6>
                                </Dropdown.Item>
                              </DropdownButton>
                              <h6 className="card-body-titulo">
                                {ruta.nombre}
                              </h6>
                              <p className="m-0 card-body-descripcion">
                                {ruta.descripcion}
                              </p>

                              <Button
                                type="button"
                                name={ruta.lecciones}
                                to="iframeElement"
                                onClick={listarLecciones}
                                className="btn-primary mt-1 btn-sm tomar-ruta"
                              >
                                Tomar ruta
                              </Button>
                            </Card.Body>
                          </Card>
                        </SwiperSlide>
                      ))}
                    </>
                  </Swiper>
                ) : (
                  <h6 className="mt-3 text-center sin-rutas">
                    Aún no tienes alguna ruta creada
                  </h6>
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default RutasPersonalizadas;
