const nombres = [
  {
    nombre: "Excel aplicado",
    codigo: "EA16",
  },
  {
    nombre: "Excel intermedio",
    codigo: "EI16",
  },
  {
    nombre: "Excel básico",
    codigo: "EX21",
  },
  {
    nombre: "Hangouts",
    codigo: "HANG",
  },
  {
    nombre: "Ingles beginning",
    codigo: "ING1",
  },
  {
    nombre: "Ingles intermediate",
    codigo: "ING2",
  },
  {
    nombre: "Excel hight intermedio",
    codigo: "ING3",
  },
  {
    nombre: "Internet",
    codigo: "INTE",
  },
  {
    nombre: "Microsoft teams",
    codigo: "MSTE",
  },
  {
    nombre: "Microsoft outlook",
    codigo: "OU20",
  },
  {
    nombre: "Power BI",
    codigo: "PB21",
  },
  {
    nombre: "Power BI avanzado",
    codigo: "PBIA",
  },
  {
    nombre: "Photoshop",
    codigo: "PH18",
  },
  {
    nombre: "Programación neurolingüística básica",
    codigo: "PNLB",
  },
  {
    nombre: "Microsoft powerpoint",
    codigo: "PP16",
  },
  // {
  //     nombre: "Microsoft project",
  //     codigo: "PR16",
  // },
  {
    nombre: "Programación por bloques con Scratch",
    codigo: "SCRA",
  },
  {
    nombre: "Microsoft Windows 10",
    codigo: "WI10",
  },
  {
    nombre: "Microsoft word",
    codigo: "WO16",
  },
  {
    nombre: "Zoom",
    codigo: "ZOOM",
  },
];

export { nombres };
