import "@assets/css/components/DashboardSecciones/style.css";

import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import React from "react";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { updateSeccion } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { useState } from "react";

export const EditarSeccion = ({ seccion }) => {
  const [nombreSeccion, setNombreSeccion] = useState(seccion.nombre);

  const [showAlert, setShowAlert] = useState(false);

  const [validacion, setValidacion] = useState({
    nombre: true,
    codigoCurso: true,
    codigoSeccion: true,
  });

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      if (nombreSeccion === "") {
        return setShowAlert(true);
      }

      setShowAlert(false);

      const seccionActualizada = await updateSeccion({
        nombre: nombreSeccion,
        codigoSeccion: seccion.codigoSeccion,
      });

      Notificacion(
        "success",
        "El nombre de la sección se actualizó correctamente"
      );
    } catch (error) {
      console.log("Error al actualizar el nombre de la sección: ", error);

      Notificacion(
        "error",
        "Ocurrió algo al actualizar el nombre de la lección, por favor vuelve a intentar."
      );
    }
  };

  const handleOnChange = ({ target }) => {
    try {
      const nombre = target.value;

      setNombreSeccion(nombre);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form onSubmit={handleOnSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Nombre de la seccion: </Form.Label>

                <Form.Control
                  type="text"
                  name="nombre"
                  value={nombreSeccion}
                  onChange={handleOnChange}
                />

                {showAlert && (
                  <Alert className="mt-2" variant="warning">
                    El nombre no puede ser vacío
                  </Alert>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <div className="d-grid gap-2 mt-4">
                  <Button variant="primary" type="submit" size="lg">
                    Guardar
                  </Button>
                </div>
              </Col>
            </Row>

            {/* <Row className="mb-3">
								<Col>
									<Droppable droppableId={`secciones`}>
										{(droppableProvided) => (
											<ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} className="list-unstyled">
												{
													secciones.map((seccion, index) => (
														<Draggable
															key={seccion.codigoSeccion}
															// draggableId={parseInt(seccion.codigoSeccion.substring(2,6))}
															draggableId={seccion.codigoSeccion}
															index={index}>
															{(draggableProvided) => (
																<li 
																	{...draggableProvided.draggableProps} 
																	{...draggableProvided.dragHandleProps} 
																	ref={draggableProvided.innerRef} 
																	className="editar-nivel-seccion-item"
																>
																	{seccion.codigoSeccion} <br /> {seccion.nombre}
																</li>
															)}
														</Draggable>
													))
												}

												{droppableProvided.placeholder}
											</ul>
										)}
									</Droppable>
								</Col>
							</Row> */}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
