import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { BiUser } from "react-icons/bi";
import CodeBlock from "./CodeBlock";

function formatListItems(text) {
  if (regExpStrings.regexCaracteresLista(text)) {
    return <p style={{ marginLeft: "40px" }}>{text}</p>;
  } else {
    return <p>{text}</p>;
  }
}

function splitTextWithCodeBlock(text) {
  // Expresión regular para encontrar bloques de código entre triple comilla invertida (```)
  const codeBlockRegex = /```([\s\S]*?)```/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  // Encuentra y divide el texto en partes que contienen texto normal y bloques de código
  while ((match = codeBlockRegex.exec(text)) !== null) {
    const inicio = text.substring(lastIndex, match.index);
    const codigo = match[0];

    // Agrega la parte de texto normal a la lista de partes
    if (inicio.trim() !== "") {
      parts.push({ type: "text", content: inicio });
    }

    // Agrega el bloque de código a la lista de partes
    parts.push({ type: "code", content: codigo });

    lastIndex = match.index + codigo.length;
  }

  // Agrega la parte final del texto (después del último bloque de código)
  const finalPart = text.substring(lastIndex);
  if (finalPart.trim() !== "") {
    parts.push({ type: "text", content: finalPart });
  }

  return parts;
}

const splitParagraphs = (text) => {
  return text
    .split("\n")
    .filter((item) => item !== "")
    .map((paragraph, index) => (
      <div className="parrafo" key={index}>
        {formatListItems(paragraph.trim())}
      </div>
    ));
};

const cleanCodeBlock = (codeBlock) => {
  // Buscar el nombre del lenguaje y el código
  const match = codeBlock.match(/```(\w+)\n([\s\S]*?)```/);

  if (!match) {
    return codeBlock;
  }

  const lenguaje = match[1];

  const codigoLimpio = match[2];

  return {
    lenguaje,
    codigo: codigoLimpio,
  };
};

const OpenAIMessages = ({ message, role }) => {
  const [formattedMessage, setFormattedMessage] = useState(<></>);

  useEffect(() => {
    let formattedMessage = "";

    if (regExpStrings.regexBloquesCodigo(message)) {
      const codeBlocks = splitTextWithCodeBlock(message);

      for (const codeBlock in codeBlocks) {
        if (Object.hasOwnProperty.call(codeBlocks, codeBlock)) {
          const { type, content } = codeBlocks[codeBlock];

          if (type === "text") {
            codeBlocks[codeBlock].content = splitParagraphs(content);
          } else if (type === "code") {
            const { lenguaje, codigo } = cleanCodeBlock(content);

            codeBlocks[codeBlock].content = {
              lenguaje,
              codigo,
            };
          }
        }
      }

      formattedMessage = codeBlocks;
    } else {
      formattedMessage = splitParagraphs(message);
    }
    setFormattedMessage(formattedMessage);
  }, []);

  return (
    <div className="message d-flex">
      <div className="align-items-end d-flex flex-column flex-shrink-0 position-relative">
        <div
          className="d-flex p-1 justify-content-center align-items-center position-relative"
          style={{
            borderRadius: "0.125rem",
            width: "36px",
            height: "36px",
            background: role === "user" ? "#f3f3f3" : "#272d34",
          }}
        >
          {role === "user" ? (
            <BiUser
              style={{ color: "#a7a7a7", width: "36px", height: "36px" }}
            />
          ) : (
            <Image
              src={require("@assets/images/avatar_gc.png")}
              width="36px"
              height="36px"
            />
          )}
        </div>
      </div>

      <div className="ms-5 d-flex flex-column position-relative">
        <div className="d-flex flex-column flex-grow-1">
          {Array.isArray(formattedMessage)
            ? formattedMessage.map((formattedMessage) => {
                const { type, content } = formattedMessage;

                if (type === "text") {
                  return content.map((item) => item);
                } else if (type === "code") {
                  return (
                    <CodeBlock
                      lenguaje={content.lenguaje}
                      code={content.codigo}
                    />
                  );
                } else {
                  return formattedMessage;
                }
              })
            : formattedMessage}
        </div>
      </div>
    </div>
  );
};

export default OpenAIMessages;
