import React, { useEffect, useState } from "react";

const useCapsLockWatcher = (inputRef = null) => {
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const checkCapsLock = (event) => {
    setIsCapsLockOn(event.getModifierState("CapsLock"));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (inputRef.current && inputRef.current === document.activeElement) {
        checkCapsLock(event);
      }
    };

    const handleFocus = () => {
      if (inputRef.current) {
        setTimeout(() => {
          // Create a new KeyboardEvent and check the state of CapsLock
          const e = new KeyboardEvent("keydown", { key: "CapsLock" });
          checkCapsLock(e);
        }, 0);

        window.addEventListener("keydown", handleKeyDown);
      }
    };

    const handleBlur = () => {
      window.removeEventListener("keydown", handleKeyDown);
      setIsCapsLockOn(false);
    };

    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      }
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputRef]);

  return isCapsLockOn;
};

export default useCapsLockWatcher;
