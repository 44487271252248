import UserContext from "@context/User/UserContext";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import React from "react";
import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const isLoggedIn = () => {
    return contextValue.usuarioLogeado !== undefined;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to={dashboardRoutesPaths.login} /> // Redirecciona al usuario a la página de inicio de sesión si no está logeado
        )
      }
    />
  );
};

export default PrivateRoute;
