import React from "react";
import { faCcAmex, faCcMastercard, faCcStripe, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";

export const Footer = () => {
	return (
		<Row className="detalle-compra-footer">
			<Col>
				<div id="footer-description">
					<Container>
						<Row>
							<Col className="text-center">
								<h2>
									<FontAwesomeIcon icon={faCcStripe} />
								</h2>
							</Col>

							<Col className="text-center">
								<h2>
									<FontAwesomeIcon icon={faCcVisa} />
								</h2>
							</Col>

							<Col className="text-center">
								<h2>
									<FontAwesomeIcon icon={faCcMastercard} />
								</h2>
							</Col>

							<Col className="text-center">
								<h2>
									<FontAwesomeIcon icon={faCcAmex} />
								</h2>
							</Col>
						</Row>
					</Container>
				</div>
			</Col>
		</Row>
	);
};

export default Footer;
