import { Notificacion } from "@components/general/Alertas/Alertas";
import { removerUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const RemoverUsuario = ({ usuario, setRefetchUsuarios }) => {
  const handleRemoverUsuario = async () => {
    try {
      const { idCredencial, idPerfil, idUsuario, idPersona } = usuario;

      await removerUsuario({
        idCredencial,
        idPerfil,
        idUsuario,
        idPersona,
      });

      setRefetchUsuarios(true);

      Notificacion("success", "Usuario removido permanentemente");
    } catch (error) {
      console.log("Error al eliminar al estudiante seleccionado: ", error);

      Notificacion("error", "No se pudo remover el usuario \n" + error.message);
    }
  };

  const cancelarRemocion = () => {
    Notificacion("info", "Acción cancelada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de remover a este usuario?</h3>

      <p>
        Si aceptas, sus datos y todos sus avances serán eliminados de forma
        permanente de forma irreversible.
      </p>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleRemoverUsuario}>
            Sí, remover
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarRemocion}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default RemoverUsuario;
