import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { ContenidoAvisoPrivacidad } from "./ContenidoAvisoPrivacidad";
import LogoFooter from "../../LogoFooter";

const AvisoPrivacidad = ({ modalShow, setModalShow }) => {

    const hideModal = () => { 
        setModalShow(!modalShow);
    };

	return (
        <Modal 
            aria-labelledby="contained-modal-title-vcenter" 
            centered
            dialogClassName="modal-90w"
            onHide={ hideModal }
            show={ modalShow } 
            size="lg"
        >

            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Aviso de Privacidad</Modal.Title>
                <Button variant="danger" onClick={ hideModal }>X</Button>

            </Modal.Header>

            <Modal.Body>
                <Container className="text-black">
                    <Row>
                        <Col className="text-center" md={ 12 }>
                            <LogoFooter />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col className="p-10" md={ 12 }>
                            <ContenidoAvisoPrivacidad />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer>
                
                <Button 
                    variant="secondary"
                    onClick={ hideModal }
                >
                    Cerrar
                </Button>

            </Modal.Footer>
        </Modal>
	);
};

export default AvisoPrivacidad;
