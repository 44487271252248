import { Button, Col, Container, Row } from "react-bootstrap";
import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import { faEdit, faEye, faPowerOff, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { AgregarPregunta } from "./Modales/AgregarPregunta/AgregarPregunta";
import { EditarPregunta } from "./Modales/EditarPregunta/EditarPregunta";
import { EliminarPregunta } from "./Modales/EliminarPregunta/EliminarPregunta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { ReactivarPregunta } from "./Modales/ReactivarPregunta/ReactivarPregunta";
import { VerPregunta } from "./Modales/VerPregunta/VerPregunta";
import columns from "./DataTables/columns";
import { getPreguntas } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import { useEffect } from "react";
import { useState } from "react";
import {MaterialReactTable} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";
import { FaEdit, FaEye, FaPowerOff, FaTrash } from "react-icons/fa";

const DashboardBancoPreguntas = () => {
	const [preguntas, setPreguntas] = useState([]);

	const [dataTableColumns, setDataTableColumns] = useState([]);

	const [preguntaAgregada, setPreguntaAgregada] = useState(false);

	const [preguntaModificada, setPreguntaModificada] = useState(false);

	const [preguntaEliminada, setPreguntaEliminada] = useState(false);

	const [preguntaReactivada, setpreguntaReactivada] = useState(false);

	const handleAgregarPregunta = () => {
		ModalSweetAlert({
			title: "Agregar nuevo pregunta",
			html: <AgregarPregunta setPreguntaAgregada={setPreguntaAgregada} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleModificarPregunta = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;
		
		console.log(preguntas[rowIndex]);

		ModalSweetAlert({
			title: "Modificar pregunta",
			html: <EditarPregunta setPreguntaModificada={setPreguntaModificada} infoPregunta={preguntas[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleDesactivarPregunta = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;
		console.log(rowIndex);

		ModalSweetAlert({
			title: "¿Desactivar pregunta?",
			icon: "question",
			html: <EliminarPregunta setPreguntaEliminada={setPreguntaEliminada} infoPregunta={preguntas[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleReactivarPregunta = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;
		ModalSweetAlert({
			title: "¿Reactivar pregunta?",
			icon: "question",
			html: <ReactivarPregunta setPreguntaReactivada={setpreguntaReactivada} infoPregunta={preguntas[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleVerPregunta = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;
		ModalSweetAlert({
			title: "Información de la pregunta",
			html: <VerPregunta infoPregunta={preguntas[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	useEffect(() => {
		const consultarPreguntas = async () => {
			try {
				const { data: preguntas } = await getPreguntas();

				setPreguntas(preguntas);
			} catch (error) {
				console.log("Error al consultar las preguntas: ", error);

				Notificacion("error", "No se pudieron cargar las preguntas, vuelve a intentar");
			}
		};

		consultarPreguntas();
	}, []);

	useEffect(() => {
		if (preguntas.length > 0) {
			//FUNCIONES PARA ELIMINAR, EDITAR O VER UNA PREGUNTA

			setDataTableColumns([
				...columns,
				{
					name: "Acciones",
					cell: (row) => (
						<>
							<FontAwesomeIcon icon={faEye} id={`ver-${row.idPregunta - 1}`} className="me-2 pointer text-primary" onClick={handleVerPregunta} />

							<FontAwesomeIcon icon={faEdit} id={`edit-${row.idPregunta - 1}`} className="me-2 pointer text-info" onClick={handleModificarPregunta} />

							{row.estatus === 1 ? (
								<FontAwesomeIcon icon={faTrashAlt} id={`delete-${row.idPregunta - 1}`} className="pointer text-danger" onClick={handleDesactivarPregunta} />
							) : (
								<FontAwesomeIcon icon={faPowerOff} id={`reactivate-${row.idPregunta - 1}`} className="pointer text-success" onClick={handleReactivarPregunta} />
							)}
						</>
					),
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			]);
		}
	}, [preguntas]);

	useEffect(() => {
		//UseEffect para refrescar la tabla al hacer alguna accion del crud.
		if (preguntaAgregada || preguntaEliminada || preguntaModificada || preguntaReactivada) {
			const refreshConsejos = async () => {
				try {
					const { data: preguntas } = await getPreguntas();
					console.log(preguntas);
					setPreguntas(preguntas);
				} catch (error) {
					console.log(error);
				}
			};
			refreshConsejos();

			setPreguntaAgregada(false);
			setPreguntaEliminada(false);
			setPreguntaModificada(false);
			setpreguntaReactivada(false);
		}
	}, [preguntaAgregada, preguntaEliminada, preguntaModificada, preguntaReactivada]);

	const columnas = useMemo(() => [
		{
			accessorKey: "pregunta",
			header: "Pregunta",
		},
		{
			accessorKey: "respuestaCorrecta",
			header: "Respuesta",
		},
		{
			accessorKey: "correo",
			header: "Correo",
		},
		{
			accessorKey: "codigoLeccion",
			header: "Nombre | Código de lección",
		},
	]);

	return (
		<Container>
			<Row className="mb-4">
				<Col align="left">
					<Button variant="primary" onClick={handleAgregarPregunta}>
						Agregar pregunta
					</Button>
				</Col>
			</Row>

			<Row className="mb-5">
				<Col>
					{/* <DataTablesRecipient columns={dataTableColumns} data={preguntas} /> */}
					<MaterialReactTable
						localization={MRT_Localization_ES}
						columns={columnas}
						data={preguntas}
						enableRowActions
						positionActionsColumn="last"
						renderRowActions={({ row, table }) => (
							<Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
								<IconButton color="primary" id={row.original.idPregunta - 1} onClick={handleVerPregunta}>
									<FaEye className="text-info" />
								</IconButton>

								<IconButton color="secondary" id={row.original.idPregunta - 1} onClick={handleModificarPregunta}>
									<FaEdit className="text-primary" />
								</IconButton>

								{row.original.estatus ? (
									<IconButton color="secondary" id={`${row.original.idPregunta - 1}`} onClick={handleDesactivarPregunta}>
										<FaTrash color="red" />
									</IconButton>
								) : (
									<IconButton color="secondary" id={`${row.original.idPregunta - 1}`} onClick={handleReactivarPregunta}>
										<FaPowerOff color="green" />
									</IconButton>
								)}
							</Box>
						)}
					/>
				</Col>
			</Row>
		</Container>
		//		</div>
	);
};

export default DashboardBancoPreguntas;
