import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import PaquetesIncluidos from "./PaquetesIncluidos";
import TotalAPagar from "./TotalAPagar";
import AvisoProductoDigital from "./AvisoProductoDigital";
import FormCodigoDescuento from "./FormCodigoDescuento";
import UserContext from "@context/User/UserContext";
import { getPricesRenovacion } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";

const Body = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const [precios, setPrecios] = useState({
		contado: {
			monto: 0,
		},
		msi: {
			monto: 0,
			planes: [],
		},
		credito: { precioTotal: 0, pagoInicial: 0, pagoQuincenal: 0, totalPagosQuincenales: 0 },
	});

	const [codigoAplicado, setCodigoAplicado] = useState(false);

	const [codigoDescuento, setCodigoDescuento] = useState("");

	const consultarPreciosRenovacion = async () => {
		try {
			const precios = await getPricesRenovacion();

			// setContextValue({
			// 	...contextValue,
			// 	renovacion: {
			// 		precios,
			// 	},
			// });

			setPrecios(precios);
		} catch (error) {
			console.log("Error al consultar los precios de la renovacion: ", error);
		}
	};

	useEffect(() => {
		if (precios.contado.monto === 0) {
			consultarPreciosRenovacion();
		}
	}, [precios]);

	return (
		<>
			<Row className="detalle-compra-pricing">
				<PaquetesIncluidos codigoAplicado={codigoAplicado} />

				<TotalAPagar codigoAplicado={codigoAplicado} codigoDescuento={codigoDescuento} precios={precios} />
			</Row>

			<Row className="detalle-compra-pricing pb-4 pb-lg-5">
				<AvisoProductoDigital />

				<FormCodigoDescuento
					precios={precios}
					states={{
						stateCodigoAplicado: {
							codigoAplicado,
							setCodigoAplicado,
						},
						stateCodigoDescuento: {
							codigoDescuento,
							setCodigoDescuento,
						},
						// statePreciosXCodigo	: {
						// 	preciosXCodigo,
						// 	setPreciosXCodigo,
						// }
					}}
				/>
			</Row>
		</>
	);
};

export default Body;
