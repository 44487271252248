import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getTemas = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temas } = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.getTemas}`,
        method: "GET",
      });

      resolve(temas);
    } catch (error) {
      // console.log("Error el obtener los temas: ", error);

      reject(error);
    }
  });
};

export const getTemasComentados = (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temasComentados } = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.getTemasComentados}/${idPerfil}`,
        method: "POST",
      });

      resolve(temasComentados);
    } catch (error) {
      reject(error);
    }
  });
};

export const addTema = (tema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const temaAgregado = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.addTema}`,
        method: "POST",
        body: JSON.stringify(tema),
      });

      resolve(temaAgregado);
    } catch (error) {
      // console.log("Error al agregar un nuevo tema: ", error);

      reject(error);
    }
  });
};

export const getTemasByUsuario = (alias) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temasUsuario } = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.getTemasByUsuario}/${alias}`,
        method: "POST",
      });

      resolve(temasUsuario);
    } catch (error) {
      // console.log("Error al obtener los temas del usuario: ", error);

      reject(error);
    }
  });
};

//*PENDIENTE
export const getTemasByUsuarioAndCategoria = (alias, idCategoria) => {
  return new Promise(async (resolve, reject) => {
    try {
      const temaUsuarioCategoria = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.getTemasByUsuarioAndCategoria}`,
        method: "POST",
        body: JSON.stringify({ alias, idCategoria }),
      });

      resolve(temaUsuarioCategoria);
    } catch (error) {
      // console.log("Error al obtener los temas del usuario a través de la categoría: ", error);

      reject(error);
    }
  });
};

export const updateTema = (tema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateTema = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.updateTema}`,
        method: "POST",
        body: JSON.stringify(tema),
      });

      resolve(updateTema);
    } catch (error) {
      // console.log("Error al actualizar el tema: ", error);

      reject(error);
    }
  });
};

export const getTemaByIdCategoria = (idCategoria) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temasCategoria } = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.getTemaByIdCategoria}/${idCategoria}`,
        method: "POST",
      });

      resolve(temasCategoria);
    } catch (error) {
      // console.log("Error al obtener los temas por categoría: ", error);

      reject(error);
    }
  });
};

export const getTemaById = (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: theme } = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.getTemaById}/${idTema}`,
        method: "POST",
      });

      resolve(theme);
    } catch (error) {
      // console.log("Error al obtener el tema: ", error);

      reject(error);
    }
  });
};

export const deactivateTema = (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deactivatedTheme = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.deactivateTema}/${idTema}`,
        method: "POST",
      });

      resolve(deactivatedTheme);
    } catch (error) {
      // console.log("Error al desactivar el tema: ", error);

      reject(error);
    }
  });
};

export const deleteTema = (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deleteTema = await httpRequest({
        url: `${endpointsPaths.temas}/${httpRequestsPaths.temas.deleteTema}/${idTema}`,
        method: "POST",
      });

      resolve(deleteTema);
    } catch (error) {
      // console.log("Error al eliminar el tema: ", error);

      reject(error);
    }
  });
};

export const reactivateTema = (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reactivatedTheme = await httpRequest({
        url: `${endpointsPaths.temas}${httpRequestsPaths.temas.reactivateTema}/${idTema}`,
        method: "POST",
      });

      resolve(reactivatedTheme);
    } catch (error) {
      // console.log("Error al reactivar el tema: ", error);

      reject(error);
    }
  });
};
