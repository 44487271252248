import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { addUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const ComponenteEmbebido = ({
  urlLeccion,
  iframeRef,
  leccionActual,
  idPerfil,
  tipoLeccion,
  onLoad,
}) => {
  const [urlActual, setUrlActual] = useState(urlLeccion);

  const [urlFuncional, setUrlFuncional] = useState(true);

  useEffect(() => {
    if (tipoLeccion === "captivate") {
      const verificarUrl = () => {
        fetch(urlActual)
          .then((response) => {
            if (response.status === 404) {
              setUrlFuncional(false);
            } else {
              setUrlFuncional(true);
            }
          })
          .catch((error) => {
            console.log(
              "Hubo un error al verificar la URL dentro del iframe:",
              error
            );
          });
      };
      verificarUrl();
    }

    //  const addUltimaLeccion = async() =>{
    // 	try {
    // 		const infoLeccion = {
    // 			idPerfil: idPerfil,
    // 			codigoLeccion: leccionActual,
    // 			fecha: getUnixTimestamp()

    // 		}
    // 		const { message } = await addUltimaLeccionTomada(infoLeccion);
    // 	} catch (error) {
    // 		console.log(error);
    // 	}
    // }

    // addUltimaLeccion()
  }, []);

  useEffect(() => {
    setUrlActual(urlLeccion);
  }, [urlLeccion]);

  useEffect(() => {
    if (tipoLeccion === "captivate") {
      const verificarUrl = () => {
        fetch(urlActual)
          .then((response) => {
            if (response.status === 404) {
              setUrlFuncional(false);
            } else {
              setUrlFuncional(true);
            }
          })
          .catch((error) => {
            console.log(
              "Hubo un error al verificar la URL dentro del iframe:",
              error
            );
          });
      };
      verificarUrl();
    }

    const addUltimaLeccion = async () => {
      try {
        const infoLeccion = {
          idPerfil: idPerfil,
          codigoLeccion: leccionActual,
          fecha: getUnixTimestamp(),
        };
        // console.log("Ultima leccion dedsde urlActual");
        const { message } = await addUltimaLeccionTomada(infoLeccion);
      } catch (error) {
        console.log(error);
      }
    };

    addUltimaLeccion();
  }, [urlActual]);

  //   const audioRef = useRef(null);

  // const handleIframeLoad = () => {
  //   audioRef.current.play();
  // };

  return (
    <>
      {urlFuncional ? (
        tipoLeccion === "captivate" && (
          <div className="ratio ratio-16x9 visor-curso">
            <iframe
              // onLoad={onLoad}
              allow="autoplay; encrypted-media"
              loading="lazy"
              ref={iframeRef}
              title="Lección actual"
              className="embed-responsive-item"
              src={urlActual}
            ></iframe>
          </div>
        )
      ) : (
        <Row>
          <Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
            <FontAwesomeIcon icon={faVideoSlash} className="mb-3" size="4x" />
            {/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
            <h4>Hubo un error al consultar la lección</h4>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ComponenteEmbebido;
