import React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import "@assets/css/components/Certificado/certificado.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const NavbarCertificado = () => {
  return (
    <>
      <Navbar variant="light" bg="dark">
        <Navbar.Brand href="#home">
          <Image
            fluid
            width="100"
            src={require("@assets/images/click.png")}
            alt="Logo Global Click"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav.Link
            style={{ color: "#FFF" }}
            href="http://cursos.globalclickmexico.com"
          >
            <FontAwesomeIcon className="me-2" icon={faUser} /> Iniciar sesión
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default NavbarCertificado;
