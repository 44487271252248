import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import useWindowResize from "@customHooks/useWindowResize";
import { CssBaseline, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { Card, Col, Container, Image, Navbar, Row } from "react-bootstrap";
import DirectorioTelefonico from "../CompraLicencia/modals/directorioTelefonico/DirectorioTelefonico";
import AvisoPrivacidad from "../CompraLicencia/modals/avisoPrivacidad/AvisoPrivacidad";
import DetalleRenovacion from "./DetalleRenovacion";

const FormularioPago = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { renovacion = null } = contextValue;

	const [showModalAvisoPrivacidad, setShowModalAvisoPrivacidad] = useState(false);

	const [showModalDirectorioTelefonico, setShowModalDirectorioTelefonico] = useState(false);

    const darkTheme = createTheme({
		palette: {
			mode: "dark",
		},
	});

    const {
		size: { width },
	} = useWindowResize();

	const handleClickAvisoPrivacidad = () => {
		setShowModalAvisoPrivacidad(!showModalAvisoPrivacidad);
	};

	const handleClickDirectorioTelefonico = () => {
		setShowModalDirectorioTelefonico(!showModalDirectorioTelefonico);
	};

	const handleClickPreguntasFrecuentes = () => {
		let link = document.createElement("a");

		link.href = "https://globalclickmexico.com/faq/index.html";

		link.target = "_blank";

		link.dispatchEvent(new MouseEvent("click"));
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />

			<div className="bg-main-container" style={{ overflow: "hidden", overflowY: "scroll" }}>
				<Navbar expand="lg" data-bs-theme="dark" bg="dark" className="bg-dark-custom">
					<Container fluid>
						<Navbar.Brand href="#">
							<div className="m-1">
								<img className="pointer" src={require(`@assets/images/logo-gc-horizontal.png`)} alt="Logo Global Click" style={{ width: `${width > 992 ? "10vw" : "20vw"}` }} />
								&nbsp;&nbsp;Compra de Licencia Plataforma Click+
							</div>
						</Navbar.Brand>

						<div className="text-white d-flex justify-content-end">
							<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickAvisoPrivacidad}>
								Aviso de privacidad
							</Typography>

							<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickPreguntasFrecuentes}>
								Preguntas frecuentes
							</Typography>

							<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickDirectorioTelefonico}>
								Directorio telefónico
							</Typography>
						</div>
					</Container>
				</Navbar>

				<Container fluid className="py-30">
					<Row>
						<Col>
							<Container fluid className="bg-light-dark card">
								<Row className="text-white">
									<Col>
										<Container fluid className="py-3">
											<TitleDashboard title="Detalle de Pago" />

											<DetalleRenovacion />
										</Container>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>

				<Container fluid className="p-0">
					<Row>
						<Col>
							<Card>
								<Card.Body className="p-0">
									<Image fluid src={require("@assets/images/compra/compra-footer.jpg")} alt="Footer de Global Click México" className="w-100 h-100" />
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>

				<DirectorioTelefonico modalShow={showModalDirectorioTelefonico} setModalShow={setShowModalDirectorioTelefonico} />

				<AvisoPrivacidad modalShow={showModalAvisoPrivacidad} setModalShow={setShowModalAvisoPrivacidad} />
			</div>
		</ThemeProvider>
	);
};

export default FormularioPago;
