import React from "react";
import { Overlay, Tooltip } from "react-bootstrap";
const CapsLockTooltip = ({ input, isCapsLockOn }) => {
  return (
    <Overlay target={input.current} show={isCapsLockOn} placement="top">
      {(props) => (
        <Tooltip className="caps-lock-on" {...props}>
          Las mayúsculas están activadas
        </Tooltip>
      )}
    </Overlay>
  );
};

export default CapsLockTooltip;
