const columnasAdministrador = [
  {
    name: "Nombre", //nombre de la columna
    selector: (row) => `${row.nombreCompleto} ${row.apellidos}`, //identificador de la columna
    sortable: true,
  },
  // {
  //     name: "Apellidos", //nombre de la columna
  //     selector: (row) => row.apellidos,
  //     sortable: true,
  // },
  {
    name: "Correo",
    selector: (row) => row.correo,
    sortable: true,
  },
  {
    name: "CURP",
    selector: (row) => row.curp,
    sortable: true,
  },
];

export default columnasAdministrador;
