import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Notificacion } from "../Alertas/Alertas";
import { cancelAgendaAsesoria } from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";

const CancelarAgenda = ({ idAgendaAsesoria, setRefetch }) => {
	const handleCancelarAgenda = async () => {
		try {
			await cancelAgendaAsesoria(idAgendaAsesoria);

			setRefetch(true);

			Notificacion("success", "Agenda cancelada con éxito");
		} catch (error) {
			console.log("Error al cancelar la agenda seleccionada: ", error);

			Notificacion("error", "No se pudo cancelar la agenda, contacta a Soporte Técnico en el Chat en Línea");
		}
	};

	const noCancelar = () => {
		Notificacion("info", "Acción cancelada");
	};

	return (
		<Container fluid className="text-center">
			<h3>¿Deseas cancelar esta agenda?</h3>

			<p>Si aceptas cancelarás tu agenda</p>

			<Row className="pt-3">
				<Col>
					<Button variant="success" onClick={handleCancelarAgenda}>
						Sí, cancelar
					</Button>
				</Col>

				<Col>
					<Button variant="danger" onClick={noCancelar}>
						No, cancelar
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default CancelarAgenda;
