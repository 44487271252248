const regExpStrings = {
	regexCaracterDigitoContrasenia: (string) => /\d/g.test(string),
	regexCaracteresEspeciales: (string) => /^(?=.*[@$!%*?&])[A-Za-z\d@$!%_"#*?&]{8,}$/.test(string),
	regexCaracteresEspecialesNoArroba: (string) =>
		// /^[a-zA-Z0-9@\-._]+$/.test(string),
		/[^\w\d]/.test(string),
	regexCaracterMayusculaContrasenia: (string) => /[A-Z]+/g.test(string),
	regexCurp: (string) =>
		/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(
			string
		),
	regexEmail: (string) => /^[\w!#\$%&'\*\+\/\=\?\^`\{\|\}~\-]+(:?\.[\w!#\$%&'\*\+\/\=\?\^`\{\|\}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i.test(string),
	regexPassword: (string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,}$/.test(string),
	regexNumeros: (string) => /^[0-9]+$/.test(string),
	regexLetras: (string) => /[^a-zA-Z]/g.test(string),
	regexCaracteresUTF8: (string) => /[\u0080-\uFFFF]/.test(string),
	regexCaracteresLista: (string) => /^(•|-|\d+\.)/gm.test(string),
	regexBloquesCodigo: (string) => /```([\s\S]*?)```/g.test(string),
};

export default regExpStrings;
