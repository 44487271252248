import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Metricas = () => {
  return (
    <Container className='text-start'>
        <Row>
            <Col>
                <p>
                    Los trabajos entregados para cada una de las misiones serán evaluados dependiendo de los requerimientos de estos, tomando en cuenta: 
                    <ul className='mt-3'>
                        <li><b>Diseño visual:</b> Se busca que los proyectos tengan un diseño limpio, usable y que sea intuitivo a la hora de utilizar por alguien más.</li>
                        <li><b>Funcionalidad:</b> Se espera que todas las funciones solicitadas sean presentadas como se solicitó.</li>
                        <li>
                           <b>Usabilidad:</b> Se espera que el trabajo entregado sea fácil de utilizar para los usuarios que interactuen con el.
                        </li>
                    </ul>
                </p>
                <p>Otros puntos importantes que se evaluan son: <b>escalabilidad del proyecto</b>, <b>creatividad y originalidad</b>, <b>interactividad</b>, <b>contenido claro y conciso</b>, entre varios puntos más que se indican junto con cada una de las misiones.</p>
            </Col>
        </Row>
    </Container>
  )
}

export default Metricas