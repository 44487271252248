import { Col, Container, Row } from "react-bootstrap";

import React from "react";
import TableBuscadorUsuarios from "../../DataTables/BuscadorUsuarios/TableBuscadorUsuarios";

const DashboardBuscadorUsuarios = ({ isTabActive, handleChangeTab, handleChangeComponent }) => {
	return (
		isTabActive && (
			<Container fluid>
				<Row className="mt-3">
					<Col>
						<TableBuscadorUsuarios handleChangeTab={handleChangeTab} handleChangeComponent={handleChangeComponent} />
					</Col>
				</Row>
			</Container>
		)
	);
};

export default DashboardBuscadorUsuarios;
