import { Row, Col, Container, Card } from "react-bootstrap";

import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import { getCursoByCodigo } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { useState } from "react";
import { useEffect } from "react";
import { getSeccionByCodigo } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { getLeccionByCodigo } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import "@assets/css/components/DashboardPreguntas/Modales/VerPregunta/style.css";
import { Accordion, AccordionTab } from "primereact/accordion";

export const VerPregunta = ({ infoPregunta }) => {
  console.log(infoPregunta);

  const [nombre, setNombre] = useState({
    nombreCurso: "",
    nombreSeccion: "",
    nombreLeccion: "",
  });
  const {
    pregunta,
    estatus,
    respuestaCorrecta,
    puntuacion,
    fechaRegistro,
    codigoCurso,
    codigoLeccion,
    imagenPregunta,
  } = infoPregunta;

  const fechaRegistroFormateada = getFechaSinHora(fechaRegistro);

  useEffect(() => {
    const getNombreCurso = async () => {
      try {
        const { nombre } = await getCursoByCodigo(codigoCurso);
        console.log(nombre);

        const seccion = codigoLeccion.substring(4, 6);
        const codigoSeccion = codigoCurso + seccion;

        const { data } = await getSeccionByCodigo(codigoSeccion);

        const { data: nombreLeccion } = await getLeccionByCodigo(codigoLeccion);
        setNombre({
          ...nombre,
          nombreCurso: nombre,
          nombreSeccion: data.nombre,
          nombreLeccion: nombreLeccion.nombre,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getNombreCurso();
  }, []);

  return (
    <>
      <Card className="text-start border-0" style={{ width: "100%" }}>
        {imagenPregunta !== "" ? (
          <div className="text-center">
            <Card.Img
              className="imagen-pregunta"
              variant="top"
              src={imagenPregunta}
            />
          </div>
        ) : (
          <div className="text-center text-danger">
            <FontAwesomeIcon icon={faCamera} />
            <h6>Imagen no disponible</h6>
          </div>
        )}
        <Card.Body>
          <Card.Title className="text-center pregunta m-3">
            <b>{pregunta}</b>
          </Card.Title>

          <Container>
            <Row>
              <Col>
                <Accordion activeIndex={null}>
                  <AccordionTab
                    header={
                      <span className=" titulo-accordion">Más detalles </span>
                    }
                  >
                    <Container>
                      <Row>
                        <Col md={6}>
                          <p>
                            Curso: <b>{nombre.nombreCurso}</b>
                          </p>
                          <p>
                            Sección: <b>{nombre.nombreSeccion}</b>
                          </p>
                          <p>
                            Lección: <b>{nombre.nombreLeccion}</b>
                          </p>
                          <p>
                            Estatus:{" "}
                            <span
                              className={
                                estatus ? "text-success" : "text-danger"
                              }
                            >
                              {estatus ? "Activo" : "Inactivo"}
                            </span>
                          </p>
                        </Col>
                        <Col md={6}>
                          <p>
                            Respuesta correcta: <b>'{respuestaCorrecta}'</b>
                          </p>
                          <p>
                            Puntuación: <b>{puntuacion}</b>
                          </p>
                          <p>
                            Fecha de registro: <b>{fechaRegistroFormateada}</b>
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </AccordionTab>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};
