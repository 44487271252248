import React from 'react';

// import '@assets/css/footer/style.css';

const LogoFooter = () => {
    return (
        <picture>
            <source 
                srcSet={ require('@assets/images/compra/footer/logo-footer.webp') } type='image/webp' 
            />

            <source 
                srcSet={ require('@assets/images/compra/footer/logo-footer.png') } type='image/png' 
            />


            <img 
                alt="Logo Global Click alternativo" 
                className="img-fluid logo-footer" 
                loading="lazy"
                src={ require('@assets/images/compra/footer/logo-footer.webp') }
            />
        </picture>
    );
};

export default LogoFooter;