import React, { useEffect, useState } from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const GestionCertificacionMosTableRow = ({ intento, setExamenRealizadoCheckedParent }) => {

    const { idIntentoCertificacionMos, numeroIntento, fechaSolicitud, fechaAgenda, examenRealizado, aprobado } = intento;

    const [examenRealizadoChecked, setExamenRealizadoChecked] = useState(examenRealizado);

	const [aprobadoChecked, setAprobadoChecked] = useState(aprobado);

    useEffect(() => {
        setExamenRealizadoCheckedParent(examenRealizadoChecked);
    }, []);

	return (
		<TableRow>
			<TableCell className="text-center"># {numeroIntento}</TableCell>

			<TableCell className="text-center">{fechaSolicitud === null ? "No se ha solicitado la agenda" : getDateFromUnix(fechaSolicitud)}</TableCell>

			<TableCell className="text-center">{fechaAgenda === null ? "No se ha solicitado la agenda" : getDateFromUnix(fechaAgenda)}</TableCell>
			
			<TableCell className="text-center">
				<Checkbox id={idIntentoCertificacionMos.toString()} name={`examenRealizado`} checked={examenRealizadoChecked} onChange={(e) => setExamenRealizadoChecked(!examenRealizadoChecked)} />
			</TableCell>

			<TableCell className="text-center">
				<Checkbox name={`aprobado`} checked={aprobadoChecked} onChange={(e) => setAprobadoChecked(!aprobadoChecked)} />
			</TableCell>

			<TableCell className="text-center">
				<OverlayTrigger overlay={<Tooltip className="super-z-index">Guardar Intento #{numeroIntento}</Tooltip>} trigger={["focus", "hover"]} placement="bottom">
					<Button variant="success" type="submit">
						<FontAwesomeIcon icon={faSave} />
					</Button>
				</OverlayTrigger>
			</TableCell>
		</TableRow>
	);
};

export default GestionCertificacionMosTableRow;
