import React from "react";
import TotalAPagarListItem from "./items/TotalAPagarListItem";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import TotalAPagarList from "./TotalAPagarList";

const renderSwitchPreciosDescuento = ({ precios, codigoDescuento }) => {

	let listItems;
	let precioElegido;

	switch (codigoDescuento) {
		case precios.clickPlus2024.codigo:
			listItems = <TotalAPagarListItem content={<>De contado: $ {numberCommaSeparator(precios.clickPlus2024.contado)} MXN</>} />;

			break;

		case precios.clickPlusPremium.codigo:
			listItems = <TotalAPagarList contado={precios.clickPlusPremium.contado} credito={precios.clickPlusPremium.credito} msi={precios.clickPlusPremium.msi} />;

			break;

		// case precios.gcMosPlus.codigo:
		// 	listItems = <TotalAPagarList contado={precios.gcMosPlus.contado} credito={precios.gcMosPlus.credito} msi={precios.gcMosPlus.msi} />;

		// 	break;

		case precios.gcPlus.codigo:
			listItems = (
				<TotalAPagarListItem
					content={
						<>
							De&nbsp;
							<strong>{precios.gcPlus.msi.planes[0]}</strong>&nbsp;a&nbsp;
							<strong>{precios.gcPlus.msi.planes[precios.gcPlus.msi.planes.length - 1]}</strong>&nbsp; Meses sin intereses: $ {numberCommaSeparator(precios.gcPlus.msi.monto)} MXN
						</>
					}
				/>
			);

			break;

		// case precios.mosGcPlus.codigo:
		// 	listItems = <TotalAPagarListItem content={<>De contado: $ {numberCommaSeparator(precios.mosGcPlus.contado)} MXN</>} />;

		// 	break;

		default:
			console.log("No hay ningún código que coincida con el ingresado o no se ingresó ningún dato");
			break;
	}

	return (
		<>
			<ul id="listPreciosDescuento">{listItems}</ul>
		</>
	);
};


const TotalAPagarDescuentoList = (props) => {
    const { descuentoAplicado, codigoDescuento = "" } = props;
    
    return (
        renderSwitchPreciosDescuento({
            precios: descuentoAplicado, 
            codigoDescuento
        })
    );
};

export default TotalAPagarDescuentoList;