import "@assets/css/components/PanelAdministracion/style.css";

import { Col, Container, Row } from "react-bootstrap";

import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import MultiViewPanelAdministracion from "./MultiViewPanelAdministracion/MultiViewPanelAdministracion";
import MultiViewPanelAdministracionContext from "@context/MultiViewPanelAdministracion/MultiViewPanelAdministracionContext";
import PanelAdministracionDashboardTitle from "./PanelAdministracionDashboardTitle";
import PanelAdministracionItem from "./PanelAdministracionItem";
import React from "react";
import panelAdministracionItems from "./panelAdministracionItems/panelAdministracionItems";
import { useContext } from "react";
import { useState } from "react";
import UserContext from "@context/User/UserContext";

const PanelAdministracion = ({ handleChangeComponent, setTemaSeleccionado }) => {
	const { contextValue: multiViewPanelAdministracionContextValue, setContextValue: setMultiViewPanelAdministracionContextValue } = useContext(MultiViewPanelAdministracionContext);

	const { contextValue: userContextValue, setContextValue: setUserContextValue } = useContext(UserContext);

	const {
		usuarioLogeado: { rol },
	} = userContextValue;

	const [titleDashboard, setTitleDashboard] = useState("Panel de Administración");

	const resetPanel = () => {
		setItemSelected(false);

		setTitleDashboard(breadcrumbItemsInitialState.title);

		setBreadcrumbItems([breadcrumbItemsInitialState]);
	};

	const breadcrumbItemsInitialState = {
		title: "Panel de Administración",
		onClick: resetPanel,
	};

	const [breadcrumbItems, setBreadcrumbItems] = useState([breadcrumbItemsInitialState]);

	const [itemSelected, setItemSelected] = useState(false);

	const handlePanelItemSelection = ({ currentTarget }) => {
		const selectedItem = currentTarget.id;

		const titleSelectedItem = panelAdministracionItems.filter((item) => item.id === selectedItem)[0].title;

		updateBreadcrumbItems({ title: titleSelectedItem, onClick: () => {} });

		setTitleDashboard(titleSelectedItem);

		setItemSelected(true);

		setMultiViewPanelAdministracionContextValue({
			...multiViewPanelAdministracionContextValue,
			view: selectedItem,
		});
	};

	const updateBreadcrumbItems = (selectedItem) => {
		if (!breadcrumbItems.includes(selectedItem)) {
			setBreadcrumbItems(() => [...breadcrumbItems, selectedItem]);
		}
	};

	return (
		<Container fluid className="p-0 mb-3 mt-5">
			<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItems} />

			<Row className="mb-3">
				<Col>
					<PanelAdministracionDashboardTitle title={titleDashboard} resetPanel={resetPanel} showBackToPanel={breadcrumbItems.length > 1} />
				</Col>
			</Row>

			<Row>
				{!itemSelected &&
					panelAdministracionItems.map((item, idx) =>
						!item.permittedTo.includes(rol) ? null : <PanelAdministracionItem key={idx} id={item.id} title={item.title} icon={item.icon} onClick={handlePanelItemSelection} />
					)}

				{itemSelected && (
					<Col>
						<MultiViewPanelAdministracion setTemaSeleccionado={setTemaSeleccionado} handleChangeComponent={handleChangeComponent} />
					</Col>
				)}
			</Row>
		</Container>
	);
};

export default PanelAdministracion;
