import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

// LIMPIAR LO DEL ARCHIVO rutasYDirecciones para establecer los roles de los objetos (todo revuelto actualmente)

const addTransmission = (transmision) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addedTransmission = await httpRequest({
        url: `${endpointsPaths.aulaVirtual}/${httpRequestsPaths.aulaVirtual.addTransmission}`,
        method: "POST",
        body: JSON.stringify(transmision),
      });

      resolve(addedTransmission);
    } catch (error) {
      // console.log("Error al agregar la nueva transmision: ", error);

      reject(error);
    }
  });
};

const getTransmissions = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const transmissions = await httpRequest({
        url: `${endpointsPaths.aulaVirtual}/${httpRequestsPaths.aulaVirtual.getTransmissions} `,
        method: "GET",
      });

      resolve(transmissions);
    } catch (error) {
      // console.log("Error al obtener las transmisiones: ", error);

      reject(error);
    }
  });
};

const updateTransmission = (transmission) => {
  return new Promise(async (resolve, reject) => {
    try {
      const transmissionUpdated = await httpRequest({
        url: `${endpointsPaths.aulaVirtual}/${httpRequestsPaths.aulaVirtual.updateTransmission}`,
        method: "POST",
        body: JSON.stringify(transmission),
      });

      resolve(transmissionUpdated);
    } catch (error) {
      // console.log("Error al actualizar la transmisión: ", error);

      reject(error);
    }
  });
};

const deleteTransmission = (idTransmision) => {
  return new Promise(async (resolve, reject) => {
    try {
      const transmisionActualizada = await httpRequest({
        url: `${endpointsPaths.aulaVirtual}/${httpRequestsPaths.aulaVirtual.deleteTransmission}/${idTransmision}`,
        method: "POST",
      });

      resolve(transmisionActualizada);
    } catch (error) {
      // console.log("Error al actualizar la transmisión: ", error);

      reject(error);
    }
  });
};

const getCapacitadores = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const capacitadores = await httpRequest({
        url: `${endpointsPaths.aulaVirtual}/${httpRequestsPaths.aulaVirtual.getCapacitadores}`,
        method: "GET",
      });

      resolve(capacitadores);
    } catch (error) {
      // console.log('Error al obtener los capacitadores: ', error);

      reject(error);
    }
  });
};

export {
  addTransmission,
  getTransmissions,
  updateTransmission,
  deleteTransmission,
  getCapacitadores,
};
