import { Notificacion } from "@components/general/Alertas/Alertas";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCursoByCodigo,
  getCursos,
} from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { updateRutaAprendizaje } from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";

const ModificarRuta = ({ ruta, setRutaAprendizajeModificada }) => {
  const [sinRecursos, setSinRecursos] = useState(false);

  const [nombreCurso, setNombreCurso] = useState({
    nombre: "",
  });

  const [formValues, setFormValues] = useState({
    nombreNuevo: ruta.nombre,
    descripcionNueva: ruta.descripcion,
    nombreCurso: "",
    codigoCurso: "",
    nombreSeccion: "",
    codigoSeccion: "",
    nombreLeccion: "",
    codigoLeccion: "",
  });

  const [listaLecciones, setListaLecciones] = useState([]);

  //STATES PARA LOS SELECTS DE LAS SECCIONES Y LAS LECCIONES

  const [selectSeccionesDeshabilitado, setSelectSeccionesDeshabilitado] =
    useState(true);

  const [selectLeccionesDeshabilitado, setSelectLeccionesDeshabilitado] =
    useState(true);

  const [leccionesRepetidas, setLeccionesRepetidas] = useState({
    status: false,
    razon: "",
  });

  const [cursoDistinto, setCursoDistinto] = useState(false);

  const [botonAgregarDeshabilitado, setBotonAgregarDeshabilitado] =
    useState(true);

  //STATES PARA ALMACENAR LOS DATOS DE LOS CURSOS, SECCIONES Y LECCIONES DE LOS SELECTS

  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const handleSelectOnChange = ({ target }) => {
    const { name, value } = target;

    const arregloValue = value.split("/"); //arregloValue[0] = valor del codigoCurso / arregloValue[1] = valor del nombreCurso

    const arregloName = name.split("/"); //arregloName[0] = 'codigoCurso' / arregloName[1] = 'nombreCurso'

    if (arregloName[0] === "codigoCurso") {
      obtenerSecciones(arregloValue[0]);
    }
    if (arregloName[0] === "codigoSeccion") {
      obtenerLecciones(arregloValue[0]);
    }
    if (arregloName[0] === "codigoLeccion") {
      setBotonAgregarDeshabilitado(false);
    }

    setFormValues({
      ...formValues,
      [arregloName[1]]: arregloValue[1],
      [arregloName[0]]: arregloValue[0],
    });
  };

  const agregarLeccion = async () => {
    try {
      const { data } = await getCursoByCodigo(formValues.codigoCurso);

      if (data.idCategoria !== formValues.categoriaCurso) {
        setCursoDistinto(true);
      } else {
        setCursoDistinto(false);

        const duplicados = listaLecciones.find((object, index) => {
          return listaLecciones
            .slice(index)
            .some(
              (otherObject) => object.codigoLeccion === formValues.codigoLeccion
            );
        });

        if (duplicados === undefined) {
          setLeccionesRepetidas({
            status: false,
            razon: "",
          });
          return setListaLecciones([
            ...listaLecciones,
            ...lecciones.filter(
              (leccion) => leccion.codigoLeccion === formValues.codigoLeccion
            ),
          ]);
        }

        setLeccionesRepetidas({
          status: true,
          razon: "Las lecciones no se pueden repetir, elige otra.",
        });

        setFormValues({
          ...formValues,
          nombreCurso: "",
          codigoCurso: "",
          nombreSeccion: "",
          codigoSeccion: "",
          nombreLeccion: "",
          codigoLeccion: "",
          categoriaCurso: 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarLeccion = ({ target }) => {
    const { name, id } = target;

    const indice = parseInt(id);

    let nuevasLecciones = [...listaLecciones];

    nuevasLecciones.splice(indice, 1);

    setListaLecciones(nuevasLecciones);
  };

  const obtenerSecciones = async (codigoCurso) => {
    try {
      setSinRecursos(false);
      const { data } = await getSeccionesByCodigoCurso(codigoCurso);

      setSecciones(data);
      setSelectSeccionesDeshabilitado(false);
    } catch (error) {
      console.log(error);
      setSinRecursos(true);
    }
  };

  const obtenerLecciones = async (codigoLeccion) => {
    try {
      setSinRecursos(false);
      const { data } = await getLeccionesByCodigoSeccion(codigoLeccion);

      setLecciones(data);
      setSelectLeccionesDeshabilitado(false);
    } catch (error) {
      setSinRecursos(true);
      console.log(error);
    }
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      let obj = listaLecciones.map((leccion) => ({
        nombre: leccion.nombre,
        codigoLeccion: leccion.codigoLeccion,
      }));

      const infoRuta = {
        idRuta: ruta.idRutaAprendizajeDefault,
        nombreRuta: formValues.nombreNuevo,
        descripcionRuta: formValues.descripcionNueva,
        lecciones: JSON.stringify(obj),
      };

      const { message } = await updateRutaAprendizaje(infoRuta);
      setRutaAprendizajeModificada(true);

      Notificacion("success", message);
    } catch (error) {}
  };

  const handleListarTodo = () => {
    const arregloLeccionesAListar = [];

    const arregloLeccionesListadas = [];

    listaLecciones.map((leccionListada) => {
      arregloLeccionesListadas.push(leccionListada.codigoLeccion);
    });

    lecciones.map((leccionAListar) => {
      arregloLeccionesAListar.push(leccionAListar.codigoLeccion);
    });

    const validacionRepetidas = arregloLeccionesAListar.map(
      (leccionAListar) => {
        const repetidas = arregloLeccionesListadas.includes(leccionAListar);
        if (repetidas) {
          return true;
        }
      }
    );

    if (validacionRepetidas.includes(true)) {
      setLeccionesRepetidas({
        status: true,
        razon:
          "Parece que ya tienes listada o más leccion, asegurate de que no se repitan",
      });

      setFormValues({
        ...formValues,
        nombreCurso: "",
        codigoCurso: "",
        nombreSeccion: "",
        codigoSeccion: "",
        nombreLeccion: "",
        codigoLeccion: "",
      });
    } else {
      setListaLecciones([...listaLecciones, ...lecciones]);

      setLeccionesRepetidas({
        status: false,
        razon: "",
      });
    }
  };

  useEffect(() => {
    const getNombreCurso = async () => {
      try {
        const { data } = await getCursoByCodigo(ruta.codigoCurso);

        setNombreCurso({
          nombre: data.nombre,
        });
        setFormValues({
          ...formValues,
          categoriaCurso: data.idCategoria,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getNombreCurso();

    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();

        setCursos(data);

        data.map((dato) => {
          if (dato.codigoCurso === ruta.codigoCurso) {
            setNombreCurso({
              nombre: dato.nombre,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursos();

    const { lecciones } = ruta;

    const listaDeLecciones = JSON.parse(lecciones);

    setListaLecciones(listaDeLecciones);
  }, []);

  return (
    <Form onSubmit={guardarCambios}>
      <Container className="text-start">
        <Row>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label>Nombre de la ruta</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                type="text"
                name="nombreNuevo"
                value={formValues.nombreNuevo}
                placeholder="Nombre de la ruta"
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <Form.Group className="mb-4">
              <Form.Control
                name="codigoCurso/nombreCurso"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione un curso
                </option>
                {cursos.map((curso, key) => (
                  <option
                    key={key}
                    value={`${curso.codigoCurso}/${curso.nombre}`}
                  >
                    {curso.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Control
                disabled={selectSeccionesDeshabilitado}
                name="codigoSeccion/nombreSeccion"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione una sección
                </option>
                {secciones.map((seccion, key) => (
                  <option
                    key={key}
                    value={`${seccion.codigoSeccion}/${seccion.nombre}`}
                  >
                    {seccion.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Control
                disabled={selectLeccionesDeshabilitado}
                name="codigoLeccion/nombreLeccion"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione una lección
                </option>
                {lecciones.map((leccion, key) => (
                  <option
                    key={key}
                    value={`${leccion.codigoLeccion}/${leccion.nombre}`}
                  >
                    {leccion.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Row className="text-center justify-content-center">
              <Col className="col-11 p-0 mb-3">
                <div className="d-grid gap-2 mt-4">
                  <Button
                    className="btn-form-rutas"
                    style={{ fontSize: "12px" }}
                    disabled={botonAgregarDeshabilitado}
                    onClick={agregarLeccion}
                    variant="success"
                    size="lg"
                  >
                    Agregar
                  </Button>
                </div>
              </Col>

              <Col className="col-11 p-0 mb-3">
                <div className="d-grid gap-2 mt-4">
                  <Button
                    className="btn-form-rutas"
                    style={{ fontSize: "12px" }}
                    onClick={handleListarTodo}
                    disabled={selectLeccionesDeshabilitado ? "disabled" : ""}
                    size="lg"
                  >
                    Agregar todas las lecciones
                  </Button>
                </div>
              </Col>
              {leccionesRepetidas && (
                <Col className="col-12">
                  <small className="text-danger alert-rutas">
                    {leccionesRepetidas.razon}
                  </small>
                </Col>
              )}

              {sinRecursos && (
                <Col className="col-12">
                  <small className="text-danger alert-rutas">
                    El curso selecionado no contiene secciones o lecciones
                    disponible, eliga otro.
                  </small>
                </Col>
              )}

              {cursoDistinto && (
                <Col>
                  <small className="text-danger">
                    {`No se puede escoger una lección de una categoría distinto, por favor elige una sección o lección del curso `}
                    <b className="text-danger">{nombreCurso.nombre}</b>.
                  </small>
                </Col>
              )}
            </Row>
          </Col>

          <Col md={7} className="mt-4 mt-md-0  ">
            <div className="p-3 text-start contenedor-rutas ">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <b>Lecciones agregadas</b>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listaLecciones.length > 0 ? (
                    <>
                      {listaLecciones.map((leccion, key) => (
                        <tr key={leccion.codigoLeccion}>
                          <td>{leccion.nombre}</td>
                          <td className="text-center">
                            <Button
                              name={leccion.nombre}
                              id={key}
                              onClick={eliminarLeccion}
                              variant="danger"
                              className="btn-sm m-1"
                            >
                              <FontAwesomeIcon
                                style={{ fontSize: "12px" }}
                                icon={faTrash}
                              />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>Aún no tienes lecciones agregadas</td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Descripción de la ruta</Form.Label>
              <Form.Control
                as="textarea"
                name="descripcionNueva"
                onChange={handleOnChange}
                placeholder="Descripción de la ruta"
                style={{ height: "100px" }}
                value={formValues.descripcionNueva}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <div className="d-grid gap-2 mt-4">
            <Button type="submit" className="mt-4" size="lg">
              Guardar cambios
            </Button>
          </div>
        </Row>
      </Container>
    </Form>
  );
};

export default ModificarRuta;
