import TitleDashboard from "@components/general/Titles/TitleDashboard";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Animated } from "react-animated-css";
import { Container, Row, Col, Image } from "react-bootstrap";

const AcercaDelCurso = ({ rol, infoCurso, tab }) => {
  const [showIcon, setShowIcon] = useState(false);

  const handleIcon = () => {
    setTimeout(() => {
      setShowIcon(true);
    }, 1200);
  };

  useEffect(() => {
    setShowIcon(false);
  }, []);

  useEffect(() => {
    if (tab === "aboutCurso") {
      handleIcon();
    }
  }, [tab]);

  return (
    <Container fluid>
      <Row className="mb-5 pb-5">
        <Col md={6} xs={12} align="left" className="mt-5">
          <Row className="mb-3">
            <Col>
              <TitleDashboard title={infoCurso.nombre} />
            </Col>
          </Row>

          <p>Categoría: {infoCurso.nombreCategoria}</p>
          {rol !== typesTiposUsuario.lecturaVeloz && (
            <>
              <p>Cantidad de lecciones: {infoCurso.cantidadLecciones}</p>
            </>
          )}
        </Col>

        <Col className="text-center" md={6} xs={12}>
          {/* <Image fluid src={require(`@assets/images/portadas_cursos/${infoCurso.portadaCurso}`)} /> */}
          {showIcon && (
            <Animated
              animationIn="zoomIn"
              animationInDuration={500}
              animationOut="fadeOut"
              isVisible={true}
            >
              <lord-icon
                src="https://cdn.lordicon.com/dxoycpzg.json"
                trigger="loop"
                delay="10000"
                colors="primary:#f59e0b,secondary:#646e78,tertiary:#121331,quaternary:#ebe6ef,quinary:#f59e0b"
                style={{ width: "250px", height: "250px" }}
                loop="false"
              ></lord-icon>
            </Animated>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AcercaDelCurso;
