import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getCategoriasCursos = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const categorias = await httpRequest({
        url: `${endpointsPaths.categoriasCursos}/${httpRequestsPaths.categoriasCursos.getCategoriasCursos}`,
        method: "GET",
      });

      resolve(categorias);
    } catch (error) {
      // console.log("Error a obtener todas las categorias: ", error);

      reject(error);
    }
  });
};

export const getCategoriasById = (idCategoria) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: categorias } = await httpRequest({
        url: `${endpointsPaths.categoriasCursos}/${httpRequestsPaths.categoriasCursos.getCategoriasById}/${idCategoria}`,
        method: "POST",
      });

      resolve(categorias);
    } catch (error) {
      // console.log("Error a obtener todas las categorias: ", error);

      reject(error);
    }
  });
};
