const columns = [
  {
    name: "#",
    selector: (row) => row.idRutaAprendizajeDefault,
    sortable: true,
    center: true,
    compact: true,
    width: "50px",
  },
  {
    name: "Nombre",
    selector: (row) => row.nombre,
    sortable: true,
    center: true,
    compact: true,
    width: "380px",
  },
  {
    name: "Descripción",
    selector: (row) => row.descripcion,
    sortable: true,
    center: true,
    compact: true,
    width: "450px",
  },
  {
    name: "Curso",
    selector: (row) => row.codigoCurso,
    sortable: true,
    center: true,
    compact: true,
    width: "100px",
  },

  {
    name: "Estatus",
    selector: (row) => (row.estatus === 1 ? "Activo" : "Desactivado"),
    sortable: true,
    center: true,
    compact: true,
    width: "100px",
  },
];

export default columns;
