import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

import "@assets/css/components/ImageDropZone/style.css";

// componente que tiene como funcion el crear una zona de arrastre de archisvos o al hacer clcik agregarlos desde la biblioteca
// falta la configuracion con el arrastre ya que en la documentacion vienen varias opciones
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#ffcc00",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const ImageDropZone = ({ getImageInBase64 }) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: "image/*" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Container fluid className="p-0">
      <Row>
        <Col>
          <div {...getRootProps({ style })}>
            <input
              {...getInputProps()}
              onChange={getImageInBase64}
              type={"file"}
            />
            <p className="text-secondary">
              Haz clic aqu&iacute; para agregar una imagen
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
