import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { addUltimaLeccionTomada, getLecciones } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { getRutasActivas } from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import { getSecciones } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { getTemas } from "@helpers/httpRequests/httpRequestsTemas/httpRequestsTemas";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IoIosClose, IoIosMenu, IoIosSearch } from "react-icons/io";
import "@assets/css/components/BarraBusqueda/style.css";
import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { getConsejos } from "@helpers/httpRequests/httpRequestsConsejos/httpRequestsConsejos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FaBoxOpen } from "react-icons/fa";

const BarraBusqueda = ({ parent, width, handleChangeComponent, handleSidebarCollapse, isSidebarActive }) => {
	const { contextValue: userContextValue, setContextValue: setUserContextValue } = useContext(UserContext);

	const { usuarioLogeado } = userContextValue;

	const { idPerfil } = usuarioLogeado;

	//*HACER LOS STATES CORRESPONDIENTES A CADA AREA DE BUSQUEDA

	const [categorias, setCategorias] = useState([]);

	// const [categoriasEncontradas, setCategoriasEncontradas] = useState([]);

	const [cursos, setCursos] = useState([]);

	const [cursosEncontrados, setCursosEncontrados] = useState([]);

	const [secciones, setSecciones] = useState([]);

	const [seccionesEncontradas, setSeccionesEncontradas] = useState([]);

	const [lecciones, setLecciones] = useState([]);

	const [leccionesEncontradas, setLeccionesEncontradas] = useState([]);

	const [rutas, setRutas] = useState([]);

	const [rutasEncontradas, setRutasEncontradas] = useState([]);

	const [temas, setTemas] = useState([]);

	const [temasEncontrados, setTemasEncontrados] = useState([]);

	const [consejos, setConsejos] = useState([]);

	const [consejosEncontrados, setConsejosEncontrados] = useState([]);

	const [formValues, setFormValues] = useState({
		busqueda: "",
	});

	//Funciones para los resultados de las busquedas
	const handleCursos = (codigoCurso) => {
		const codigoSeccionSeleccionada = codigoCurso + "01";

		setUserContextValue({
			...userContextValue,
			codigoCursoSeleccionado: codigoCurso,
			codigoSeccionSeleccionada,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	const handleSecciones = async (codigoSeccion, codigoCurso) => {
		const infoLeccion = {
			idPerfil: idPerfil,
			codigoLeccion: codigoSeccion + "01",
			fecha: getUnixTimestamp(),
		};

		const updateLeccion = await addUltimaLeccionTomada(infoLeccion);

		setUserContextValue({
			...userContextValue,
			codigoCursoSeleccionado: codigoCurso,
			codigoSeccionSeleccionada: codigoSeccion,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	const handleLecciones = async (codigoLeccion) => {
		const infoLeccion = {
			idPerfil: idPerfil,
			codigoLeccion: codigoLeccion,
			fecha: getUnixTimestamp(),
		};

		const codigoCurso = codigoLeccion.substring(0, 4);

		const codigoSeccionSeleccionada = codigoLeccion.substring(6, 0);

		const updateLeccion = await addUltimaLeccionTomada(infoLeccion);

		setUserContextValue({
			...userContextValue,
			codigoCursoSeleccionado: codigoCurso,
			codigoSeccionSeleccionada,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	const handleRutas = () => {
		handleChangeComponent(multiViewComponentsPaths.rutasAprendizaje);
	};

	const handleTemas = () => {
		handleChangeComponent(multiViewComponentsPaths.foroAyuda);
	};

	const buscar = ({ target }) => {
		const { name, value } = target;

		//*Se hace desde aqui el filtrado de los datos
		// categoriasEncontradas.splice(0, categoriasEncontradas.length); // Vaciar arreglo anterior

		// categorias.forEach(categoria => {
		// 	if (categoria.nombre.includes(value)) {
		// 	categoriasEncontradas.push(categoria);
		// 	}
		// });

		cursosEncontrados.splice(0, cursosEncontrados.length); // Vaciar arreglo anterior

		cursos.forEach((curso) => {
			if (curso.nombre.toLowerCase().includes(value.toLowerCase())) {
				cursosEncontrados.push(curso);
			}
		});

		seccionesEncontradas.splice(0, seccionesEncontradas.length); // Vaciar arreglo anterior

		secciones.forEach((seccion) => {
			if (seccion.nombre.toLowerCase().includes(value.toLowerCase())) {
				seccionesEncontradas.push(seccion);
			}
		});

		leccionesEncontradas.splice(0, leccionesEncontradas.length); // Vaciar arreglo anterior

		lecciones.forEach((leccion) => {
			if (leccion.nombre.toLowerCase().includes(value.toLowerCase())) {
				leccionesEncontradas.push(leccion);
			}
		});

		rutasEncontradas.splice(0, rutasEncontradas.length); // Vaciar arreglo anterior

		rutas.forEach((ruta) => {
			if (ruta.nombre.toLowerCase().includes(value.toLowerCase())) {
				rutasEncontradas.push(ruta);
			}
		});

		temasEncontrados.splice(0, temasEncontrados.length); // Vaciar arreglo anterior

		temas.forEach((tema) => {
			if (tema.titulo.toLowerCase().includes(value.toLowerCase())) {
				temasEncontrados.push(tema);
			}
		});

		consejosEncontrados.splice(0, consejosEncontrados.length); // Vacias arreglo anterior

		consejos.forEach((consejo) => {
			if (consejo.titulo.toLowerCase().includes(value.toLowerCase())) {
				consejosEncontrados.push(consejo);
			}
		});

		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const infoCurso = (idCategoria) => {
		const categoria = categorias.filter((categoria) => categoria.idCategoria === idCategoria);

		return categoria[0].nombre;
	};

	const infoSeccion = (codigoCurso) => {
		const nombreCurso = cursos.filter((curso) => curso.codigoCurso === codigoCurso);

		return nombreCurso[0].nombre;
	};

	const infoLeccion = (codigoSeccion) => {
		const codigoCurso = codigoSeccion.substring(4, 0);

		const nombreCurso = cursos.filter((curso) => curso.codigoCurso === codigoCurso);
		
		const nombreSeccion = secciones.filter((seccion) => seccion.codigoSeccion === codigoSeccion);

		return `${nombreCurso[0].nombre} - ${nombreSeccion[0].nombre}`;
	};

	const infoRutas = (codigoCurso) => {
		const curso = cursos.filter((curso) => curso.codigoCurso === codigoCurso);

		const categoria = categorias.filter((categoria) => categoria.idCategoria === curso[0].idCategoria);

		return `${categoria[0].nombre} - ${curso[0].nombre}`;
	};

	const infoConsejo = (codigoCurso) => {
		const curso = cursos.filter((curso) => curso.codigoCurso === codigoCurso);

		return curso[0].nombre;
	};

	useEffect(() => {
		const obtenerDatosBusqueda = async () => {
			try {
				const { data: dataCategorias } = await getCategoriasCursos();
				if (Array.isArray(dataCategorias)) {
					setCategorias(dataCategorias);
				} else {
					setCategorias([dataCategorias]);
				}

				const { data: dataCursos } = await getCursos();

				if (Array.isArray(dataCursos)) {
					setCursos(dataCursos);
				} else {
					setCursos([dataCursos]);
				}

				const { data: dataSecciones } = await getSecciones();

				if (Array.isArray(dataSecciones)) {
					setSecciones(dataSecciones);
				} else {
					setSecciones([dataSecciones]);
				}

				const { data: dataLecciones } = await getLecciones();
				if (Array.isArray(dataLecciones)) {
					setLecciones(dataLecciones);
				} else {
					setLecciones([dataLecciones]);
				}

				const { data: dataRutasAprendizaje } = await getRutasActivas();
				if (Array.isArray(dataRutasAprendizaje)) {
					setRutas(dataRutasAprendizaje);
				} else {
					setRutas([dataRutasAprendizaje]);
				}

				const { data: dataTemas } = await getTemas();
				if (Array.isArray(dataTemas)) {
					setTemas(dataTemas);
				} else {
					setTemas([dataTemas]);
				}

				const { data: dataConsejos } = await getConsejos();
				if (Array.isArray(dataConsejos)) {
					setConsejos(dataConsejos);
				} else {
					setConsejos([dataConsejos]);
				}
			} catch (error) {
				// console.log(error);
			}
		};

		obtenerDatosBusqueda();
	}, []);

	return (
		<>
			<InputGroup>
				{/* <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-top`}>Desplegar menú</Tooltip>}>
					<Button variant="outline-warning" onClick={handleSidebarCollapse} className="navbar-buttons">
						{isSidebarActive ? <IoIosClose /> : <IoIosMenu />}
					</Button>
				</OverlayTrigger> */}

				<Form.Control
					autoComplete="false"
					type="search"
					placeholder="Busca por categorías, cursos, secciones, lecciones, rutas de aprendizaje, consejos rápidos o temas de foro de ayuda"
					className="input-form"
					aria-label="Buscar"
					name="busqueda"
					value={formValues.busqueda}
					onChange={buscar}
				/>

				<Button variant={`${parent === "desktop-navbar" ? "outline-warning" : "secondary"}`} className="navbar-buttons">
					<IoIosSearch />
				</Button>
			</InputGroup>

			{formValues.busqueda !== "" && (
				<div style={{ position: "relative", zIndex: "30000" }}>
					<div
						className="contenedor-busqueda"
						style={{
							position: "absolute",
							height: "300px",
							width: "100%",
							// backgroundColor: "#fff",
							zIndex: 3000,
						}}>
						{/* //*Dentro de cada una de las columnas se estara validando el match y se muestra la fila con la columna, de lo contrario se omite y se muestra unicamente lo que tenga contenido que hace match */}

						<Container fluid style={{ display: "block" }}>
							{/* 							
							{categoriasEncontradas.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Categorías</b>
										<hr />
									</Col>
									<Col className="col-12">
									<Row className="justify-content-between mb-4">
											{categoriasEncontradas.map((categoria, idCategoria) => (
												<Col className="col-12">
													<Row className='justify-content-between'> 
														<Col className="col-auto">
																<span key={idCategoria}>{`${categoria.nombre}`}</span>
														</Col>
													</Row>
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							)} */}

							{cursosEncontrados.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Cursos</b>
										<hr />
									</Col>

									<Col className="col-12">
										<Row className="justify-content-between mb-4">
											{cursosEncontrados.map(
												(curso, idCurso) =>
													!curso.codigoCurso.startsWith("EAUI") &&
													!curso.codigoCurso.startsWith("EIUI") &&
													!curso.codigoCurso.startsWith("EXUI") &&
													!curso.codigoCurso.startsWith("PBUI") &&
													!curso.codigoCurso.startsWith("PBUA") && (
														<Col key={idCurso} className="col-12">
															<Row onClick={() => handleCursos(curso.codigoCurso)} className="justify-content-between mb-3 fila-resultados pointer">
																<Col className="col-9">
																	<span key={idCurso}>
																		{`${curso.nombre}`} | <small>{infoCurso(curso.idCategoria)}</small>
																	</span>
																</Col>
																<Col className="col-auto">
																	<small>Ir al curso</small>
																</Col>
															</Row>
														</Col>
													)
											)}
										</Row>
									</Col>
								</Row>
							)}

							{seccionesEncontradas.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Secciones</b>
										<hr />
									</Col>

									<Col className="col-12">
										<Row className="justify-content-between mb-4">
											{seccionesEncontradas.map(
												(seccion, idSeccion) =>
													!seccion.codigoSeccion.startsWith("EAUI") &&
													!seccion.codigoSeccion.startsWith("EIUI") &&
													!seccion.codigoSeccion.startsWith("EXUI") &&
													!seccion.codigoSeccion.startsWith("PBUI") &&
													!seccion.codigoSeccion.startsWith("PBUA") && (
														<Col key={idSeccion} className="col-12">
															<Row onClick={() => handleSecciones(seccion.codigoSeccion, seccion.codigoCurso)} className="justify-content-between mb-3 fila-resultados pointer">
																<Col className="col-9">
																	<span key={idSeccion}>
																		{`${seccion.nombre}`} | <small>{infoSeccion(seccion.codigoCurso)}</small>
																	</span>
																</Col>
																<Col className="col-auto">
																	<small>Ir a la sección de la sección</small>
																</Col>
															</Row>
														</Col>
													)
											)}
										</Row>
									</Col>
								</Row>
							)}

							{leccionesEncontradas.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Lecciones</b>
										<hr />
									</Col>
									<Col className="col-12">
										<Row className="justify-content-between mb-4">
											{leccionesEncontradas.map(
												(leccion, idleccion) =>
													!leccion.codigoLeccion.startsWith("EAUI") &&
													!leccion.codigoLeccion.startsWith("EIUI") &&
													!leccion.codigoLeccion.startsWith("EXUI") &&
													!leccion.codigoLeccion.startsWith("PBUI") &&
													!leccion.codigoLeccion.startsWith("PBUA") && (
														<Col key={idleccion} className="col-12">
															<Row onClick={() => handleLecciones(leccion.codigoLeccion)} className="justify-content-between mb-3 fila-resultados pointer">
																<Col className="col-9">
																	<span key={idleccion}>
																		{`${leccion.nombre}`} | <small>{infoLeccion(leccion.codigoSeccion)}</small>
																	</span>
																</Col>
																<Col className="col-auto">
																	<small>Ver lección</small>
																</Col>
															</Row>
														</Col>
													)
											)}
										</Row>
									</Col>
								</Row>
							)}

							{rutasEncontradas.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Rutas de aprendizaje</b>
										<hr />
									</Col>
									<Col className="col-12">
										<Row className="justify-content-between mb-4">
											{rutasEncontradas.map((ruta, idruta) => (
												<Col key={idruta} className="col-12">
													<Row onClick={handleRutas} className="justify-content-between mb-3 fila-resultados pointer">
														<Col className="col-9">
															<span key={idruta}>
																{`${ruta.nombre}`} | <small>{infoRutas(ruta.codigoCurso)}</small>
															</span>
														</Col>
														<Col className="col-auto">
															<small>Ver rutas</small>
														</Col>
													</Row>
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							)}

							{temasEncontrados.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Temas de foro de ayuda</b>
										<hr />
									</Col>
									<Col className="col-12">
										<Row className="justify-content-between mb-4">
											{temasEncontrados.map((tema, idtema) => (
												<Col key={idtema} className="col-12">
													<Row onClick={handleTemas} className="justify-content-between mb-3 fila-resultados pointer">
														<Col className="col-auto">
															<span key={idtema}>{`${tema.titulo}`}</span>
														</Col>
													</Row>
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							)}

							{consejosEncontrados.length > 0 && (
								<Row className="mt-3">
									<Col className="col-12">
										<b>Atajos</b>
										<hr />
									</Col>
									<Col className="col-12">
										<Row className="justify-content-between mb-4">
											{consejosEncontrados.map((consejo, idconsejo) => (
												<Col key={idconsejo} className="col-12">
													<Row className="justify-content-between mb-3 fila-resultados pointer">
														<Col className="col-auto">
															<span key={idconsejo}>
																{`${consejo.titulo}`} <FontAwesomeIcon icon={faArrowRight} size="sm" /> {consejo.descripcion} | <small>{infoConsejo(consejo.codigoCurso)}</small>
															</span>
														</Col>
													</Row>
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							)}

							{consejosEncontrados.length === 0 &&
								temasEncontrados.length === 0 &&
								rutasEncontradas.length === 0 &&
								leccionesEncontradas.length === 0 &&
								seccionesEncontradas.length === 0 &&
								cursosEncontrados.length === 0 && (
									<div className="text-center mt-5">
										<FaBoxOpen size={45} />
										<h2>No se encontraron resultados</h2>
									</div>
								)}
						</Container>
					</div>
				</div>
			)}
		</>
	);
};

export default BarraBusqueda;
