import React from "react";
import { faCcStripe } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Col, Row } from "react-bootstrap";
import EtiquetaHeader from "./EtiquetaHeader";

const Header = () => {
	return (
		<>
			<Row className="detalle-compra-header">
				<Col>
					<h4>
						Funciona con{" "}
						<FontAwesomeIcon icon={faCcStripe} className="powered-by" />
					</h4>
				</Col>


			</Row>

			<EtiquetaHeader />
		</>
	);
};

export default Header;
