import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { consultarIntentos, nuevasConsultas, sinConsultas, textToVoice, updateConsultas } from '@helpers/httpRequests/httpRequestsOpenAI/httpRequestsOpenAI'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Image, OverlayTrigger, Tooltip, Form, FloatingLabel, Button} from 'react-bootstrap'
import { MdOutlineAudioFile } from 'react-icons/md'
import { Notificacion } from '../Alertas/Alertas'
import AudioPlayer from './AudioPlayer'
import Joyride from 'react-joyride'
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp'
import { areSameDate } from '@helpers/dates/areSameDate/AreSameDate'
import UserContext from '@context/User/UserContext'
 

const lordIconProps = {
  loader: {
    src: "https://cdn.lordicon.com/avytqtql.json",
    trigger: "loop",
    colors: "primary:#ffcc00,secondary:#ebe6ef",
    style: { width: "100px", height: "100px" },
  },
};

const TextToVoice = () => {

  const { contextValue, setContextValue } = useContext(UserContext);
  const {usuarioLogeado} = contextValue ;

  const {idPerfil} = usuarioLogeado;
  const [consultasRestantes, setConsultasRestantes] = useState(0);


  //*Tour del componente
  const joyrideRef2 = useRef(null);

  const [tourSteps2, setTourSteps] = useState([
    {
      target: ".ttv-question",
      content: "Bienvenido, te presentamos una inteligencia artificial (IA) que nos permmite convertir palabras escritas en audio, ofreciendo un sonido natural y casi realista.",
      disableBeacon: true,
    },
    {
      target: ".ttv-question",
      content: "A continuación te mostramos como usarla:",
      disableBeacon: true,
    },

    {
      target: ".ttv-voice",
      content: "Primero selecciona de la lista de voces disponibles la que más te agrade.",
      disableBeacon: true,
    },
   
    {
      target: ".ttv-example",
      content: "Puedes escuchar un ejemplo de cada voz al seleccionarla.",
      disableBeacon: true,
    },

    {
      target: ".ttv-text",
      content: "Seguido coloca el texto que quieras convertir.",
      disableBeacon: true,
    },
   
    {
      target: ".ttv-audio",
      content: "Una vez se genere tu audio se mostrará aquí, podrás descargarlo o generar uno nuevo con una voz distinta.",
      disableBeacon: true,
    },

    {
      target: ".ttv-credits",
      content: "Usa los 5 créditos diarios para sacar el mayor provecho a la herramienta.",
      disableBeacon: true,
    },
     
    {
      target: ".ttv-question",
      content: "Eso es todo, ahora estás listo para generar audios que se ajusten a tus necesidades.",
      disableBeacon: true,
    },
   


  ]);

  const [runTour, setRunTour] = useState(false);

  const [formValues, setFormValues] = useState({
    input: "",
    voice: "alloy"
  })

  const [audio, setAudio] = useState(null);

  const [audioReady, setAudioReady]= useState(false)

  const [audioPrueba, setAudioPrueba] = useState("alloy");

  const [showLoader, setShowLoader] = useState(false);

  const handleOnChange = ({currentTarget}) => {
    const {name, value} = currentTarget;

    setFormValues({
      ...formValues,
      [name] : value,
    })


    if(name === "voice"){
      setAudioPrueba(value)

    }



  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {

      if(consultasRestantes !== 0){
            setShowLoader(true)

            const response = await textToVoice({
              texto: formValues.input,
              voz: formValues.voice,
            })
            setAudio(response.data.data)
            setAudioReady(true)
            setShowLoader(false)


            const actualizarContador = await updateConsultas({
              idPerfil,
              codigoHerramienta: "TTV",
              fechaUso: getUnixTimestamp(),
             })
        
        
             const {consultasRestantes} = actualizarContador.data.data;

            if(consultasRestantes ===0){
              //Se alcanzo el limite
              const sinConsulta = await sinConsultas({
               idPerfil,
               fechaActual: getUnixTimestamp(),
               codigoHerramienta: "TTV",
             })
     
             setConsultasRestantes(0)
 
             Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")
          }

            //Aun no se alcanza el limite
            setConsultasRestantes(consultasRestantes);
         

      }else{
        Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")

      }

   
    } catch (error) {
      console.log(error);

      //En caso de que se arroje un error por limite de consultas entra en el if
      if(error.info) {
        Notificacion("error", error.info)
        setShowLoader(false)
      }else{
        Notificacion("error", "Ocurrio un error insesperado")
      }


    }
  }

  const handleStartTour =() => {
    setRunTour(true)
  }



  useEffect(() => {
  
    const consultarIntentosSuite = async() => {
      try {
         //CONSULTA A LA API PARA VALIDAR CONSULTAS RESTANTES
  
         const intentosSuite = await consultarIntentos({
          idPerfil,
          codigoHerramienta: "TTV",
          fechaActual: getUnixTimestamp()
        }); 
  
  
        if(intentosSuite.isRegistered){
          //En caso de estar registrado con x cantidad de intentos
  
          const {consultasRestantes, fechaDesbloqueo, fechaActual, estatus} = intentosSuite.data.data;
  
          const sameDate = areSameDate(fechaActual, getUnixTimestamp()); //valida si la fecha registrada por primera vez es igual a la fecha que se tiene que desbloquear
  
          setConsultasRestantes(consultasRestantes)
  
          // if(sameDate === false && estatus === 0) {
           
          //   setConsultasRestantes(5);
          //   const conConsultas = await nuevasConsultas({
          //     idPerfil,
          //     codigoHerramienta: "TTV"
          //   });
            
          // }
  
         
        }else{
          //En caso de no estar registrado, se le agregan en automatico los 5 intentos
          setConsultasRestantes(5);
  
        }
  
      } catch (error) {
        console.log(error);
      }
    }
  
    consultarIntentosSuite()
  }, [])


  return (
    <Container fluid className='h-100 fondo-generador ttv-question'>
     
      <Joyride
        locale={spanishOptionsJoyRide}
        ref={joyrideRef2}
        steps={tourSteps2}
        run={runTour}
        hideCloseButton
        showSkipButton
        continuous={true}
        disableOverlayClose={false}
        disableCloseOnEsc={false}
        disableScrolling={false}
        scrollToFirstStep={false}
        scrollOffset={100}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRunTour(false);
          }

        }}
        styles={{
          options: {
            primaryColor: "#ff9900",
            textColor: "#00152A",
            width: 900,
            zIndex: 1000,
          },
        }}
      />
      
      <Row className='justify-content-between pt-5'>
          <Col className="col-auto text-center text-white ttv-credits">
            <h3>Créditos restantes: {consultasRestantes}/5</h3>
          </Col>
          <Col className="col-3 text-center ">
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>Ayuda</Tooltip>}
            >
              <span>
                <FontAwesomeIcon
                  className=" pointer "
                  onClick={handleStartTour}
                  style={{ fontSize: "2rem", color: "white" }}
                  icon={faQuestion}
                />
              </span>
            </OverlayTrigger>
          </Col>
      </Row>
      <Row className='justify-content-center pt-5'>
        <Col className='text-center col-6'>
        <picture>
              <source srcset={require(`@assets/images/textoAudio.webp`)} type="image/webp"/>

              <Image src={require(`@assets/images/textoAudio.png`)} fluid></Image>

            </picture>
        </Col>
      </Row>

      <Row className='justify-content-center '>
        <Col className='col-6'>
          <Form onSubmit={handleSubmit}>
            <Row className='mt-5 d-flex align-items-center'>
              <Col className='col-12'>
                <Form.Group>
                  <Form.Label className='text-white'>Selecciona una voz para tu audio</Form.Label>
                  <Form.Select onChange={handleOnChange} className="ttv-voice"  name='voice' aria-label="Tamaño de imagen">
                      <option>Selecciona una voz para tu audio</option>
                      <option value="alloy">Alloy</option>
                      <option value="echo">Echo</option>
                      <option value="fable">Fable</option>
                      <option value="onyx">Onyx</option>
                      <option value="nova">Nova</option>
                      <option value="shimmer">Shimmer</option>
                  </Form.Select> 
                </Form.Group>

               
                <Col className="ttv-example">
                  <Col className={`${audioPrueba === "alloy" ? 'd-block' : 'd-none'}`}>
                  <audio  controls className='mt-4 mb-4'>
                    <source
                      src={require(`@components/general/TextToVoice/audiosPrueba/alloy.mp3`)}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  </Col>


                  <Col className={`${audioPrueba === "echo" ? 'd-block' : 'd-none'}`}>
                  <audio  controls className='mt-4 mb-4'>
                    <source
                      src={require(`@components/general/TextToVoice/audiosPrueba/echo.mp3`)}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  </Col>

                  <Col className={`${audioPrueba === "fable" ? 'd-block' : 'd-none'}`}>
                  <audio  controls className='mt-4 mb-4'>
                    <source
                      src={require(`@components/general/TextToVoice/audiosPrueba/fable.mp3`)}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  </Col>

                  <Col className={`${audioPrueba === "onyx" ? 'd-block' : 'd-none'}`}>
                  <audio  controls className='mt-4 mb-4'>
                    <source
                      src={require(`@components/general/TextToVoice/audiosPrueba/onyx.mp3`)}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  </Col>

                  <Col className={`${audioPrueba === "nova" ? 'd-block' : 'd-none'}`}>
                  <audio  controls className='mt-4 mb-4'>
                    <source
                      src={require(`@components/general/TextToVoice/audiosPrueba/nova.mp3`)}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  </Col>

                  <Col className={`${audioPrueba === "shimmer" ? 'd-block' : 'd-none'}`}>
                  <audio  controls className='mt-4 mb-4'>
                    <source
                      src={require(`@components/general/TextToVoice/audiosPrueba/shimmer.mp3`)}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  </Col>
                </Col>

                <Col  className="ttv-text">

                <FloatingLabel controlId="floatingTextarea2" label="Ingresa el texto para tu audio">
                  <Form.Control
                   
                    onChange={handleOnChange}
                    name="input"
                    as="textarea"
                    style={{ height: '100px' }}
                  />
                </FloatingLabel>
                
                </Col>


                <div className='mt-4 d-grid gap-2'>
                  <Button disabled={showLoader} variant='warning' type='submit'>Generar audio</Button>
                </div>


              </Col>

            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="justify-content-center mt-5">
        {audioReady !== true ? (
            <Col className="text-center col-6  ttv-audio">
            <h2 className='text-white'>Tu audio se generará aquí</h2>
            {showLoader === true? (
                <Row className='justify-content-center'>
                  <Col className='col-2 text-center    '>
                    <lord-icon {...lordIconProps.loader}></lord-icon>
                  </Col>
                </Row>
            ): (
              <MdOutlineAudioFile className="img-picture"/>
            )}
          </Col>
        
        ):(
            
          <Col className="col-auto text-center text-white">
            {showLoader === true? (
                <Row className='justify-content-center'>
                  <Col className='col-auto text-center    '>
                    <lord-icon {...lordIconProps.loader}></lord-icon>
                  </Col>
                </Row>
            ): (
              <>
               <h2>Tu texto se ha convertido con éxito</h2>
            <AudioPlayer audioBuffer={audio} />
              </>
            )}
           
          </Col>
        )} 
      </Row>


    </Container>
  )
}

export default TextToVoice