import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import {
  faEdit,
  faEye,
  faPlus,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGrupos } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { getLeccionesPersonalizadas } from "@helpers/httpRequests/httpRequestsLeccionesPersonalizadas/httpRequestsLeccionesPersonalizadas";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import columns from "./DataTables/columns";
import DesactivarLeccion from "./Modales/DesactivarLeccion";
import EditarLeccion from "./Modales/EditarLeccion";
import NuevaLeccion from "./Modales/NuevaLeccion";
import ReactivarLeccion from "./Modales/ReactivarLeccion";
import VerInfoLeccion from "./Modales/VerInfoLeccion";

const DashboardLeccionesEmpresariales = () => {
  const [grupos, setGrupos] = useState([]);

  const [leccionesPersonalizadas, setLeccionesPersonalizadas] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [leccionAgregada, setLeccionAgregada] = useState(false);

  const [leccionEditada, setLeccionEditada] = useState(false);

  const [leccionDesactivada, setLeccionDesactivada] = useState(false);

  const [leccionActivada, setLeccionActivada] = useState(false);

  //Agregar leccion
  const handleNuevaLeccion = () => {
    ModalSweetAlert({
      title: "Nueva lección personalizada",
      html: <NuevaLeccion setLeccionAgregada={setLeccionAgregada} />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const obtenerNombre = (idGrupo) => {
    for (let i = 0; i < grupos.length; i++) {
      if (grupos[i].idGrupo === idGrupo) {
        return grupos[i].nombreEmpresa;
      }
    }
  };

  useEffect(() => {
    const obtenerLeccionesEmpresariales = async () => {
      try {
        const { data } = await getLeccionesPersonalizadas();
        console.log(data);
        if (Array.isArray(data)) {
          setLeccionesPersonalizadas(data);
        } else {
          setLeccionesPersonalizadas([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerLeccionesEmpresariales();

    const obtenerDatosGrupo = async () => {
      try {
        const { data } = await getGrupos();
        if (Array.isArray(data)) {
          setGrupos(data);
        } else {
          setGrupos([data]);
        }
      } catch (error) {
        console.log(error);
        setGrupos([]);
      }
    };
    obtenerDatosGrupo();
  }, []);

  useEffect(() => {
    if (grupos.length > 0) {
      //MODALES PARA EL DASHBOARD

      const handleEditarleccion = ({ currentTarget }) => {
        const rowIndex = currentTarget.id;
        console.log(rowIndex);
        ModalSweetAlert({
          title: "Editar lección",
          html: (
            <EditarLeccion
              setLeccionEditada={setLeccionEditada}
              infoLeccion={leccionesPersonalizadas[rowIndex]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleDesactivarLeccion = ({ currentTarget }) => {
        const rowIndex = currentTarget.id;
        ModalSweetAlert({
          title: "Desactivar Lección",
          html: (
            <DesactivarLeccion
              setLeccionDesactivada={setLeccionDesactivada}
              infoLeccion={leccionesPersonalizadas[rowIndex]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
          width: "700px",
        });
      };

      const handleReactivarLeccion = ({ currentTarget }) => {
        const rowIndex = currentTarget.id;
        ModalSweetAlert({
          title: "Reactivar Lección",
          html: (
            <ReactivarLeccion
              setLeccionActivada={setLeccionActivada}
              infoLeccion={leccionesPersonalizadas[rowIndex]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
          width: "700px",
        });
      };

      const handleVerInfoLeccion = (idGrupo, idLeccion) => {
        // const rowIndex = currentTarget.id;

        const nombreGrupo = obtenerNombre(idGrupo);

        console.log(nombreGrupo);

        ModalSweetAlert({
          title: "Ver información de la lección",
          html: (
            <VerInfoLeccion
              nombreGrupo={nombreGrupo}
              infoLeccion={leccionesPersonalizadas[idLeccion - 1]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
          width: "700px",
        });
      };

      //TODO recuperar el nombre del grupo para mostrarlo en la tabla de dashboard
      // const obtenerNombreGrupo = (idGrupo) => {
      //   console.log(idGrupo);
      //   const grupo = grupos.find(grupo => grupo.idGrupo === idGrupo);
      //   return grupo.nombre
      // }

      setDataTableColumns([
        ...columns,
        {
          name: "Grupo",
          selector: (row) => obtenerNombre(row.idGrupo),
          sortable: true,
          width: "200px",
        },
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={row.idLeccion - 1}
                onClick={() => handleVerInfoLeccion(row.idGrupo, row.idLeccion)}
                className="me-1 pointer"
              >
                <FontAwesomeIcon icon={faEye} color="grey" className="mx-1" />
              </span>

              <span
                id={row.idLeccion - 1}
                onClick={handleEditarleccion}
                className="me-1 pointer"
              >
                <FontAwesomeIcon icon={faEdit} className="text-primary" />
              </span>

              {row.estatus ? (
                <span
                  id={row.idLeccion - 1}
                  onClick={handleDesactivarLeccion}
                  className="ms-1 pointer"
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              ) : (
                <span
                  id={row.idLeccion - 1}
                  onClick={handleReactivarLeccion}
                  className="ms-1 pointer"
                >
                  <FontAwesomeIcon icon={faPowerOff} className="text-success" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          center: true,
          width: "10%",
        },
      ]);
    }
  }, [grupos, leccionesPersonalizadas]);

  useEffect(() => {
    if (
      leccionAgregada ||
      leccionEditada ||
      leccionActivada ||
      leccionDesactivada
    ) {
      const obtenerLeccionesEmpresariales = async () => {
        try {
          const { data } = await getLeccionesPersonalizadas();
          console.log(data);
          if (Array.isArray(data)) {
            setLeccionesPersonalizadas(data);
          } else {
            setLeccionesPersonalizadas([data]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      obtenerLeccionesEmpresariales();
      setLeccionAgregada(false);
      setLeccionEditada(false);
      setLeccionDesactivada(false);
      setLeccionActivada(false);
    }
  }, [leccionAgregada, leccionEditada, leccionActivada, leccionDesactivada]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col>
          <Button type="button" onClick={handleNuevaLeccion}>
            Nueva lección <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>

      <Row className="my-3 mb-5">
        <Col>
          <DataTablesRecipient
            columns={dataTableColumns}
            data={leccionesPersonalizadas}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLeccionesEmpresariales;
