import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

import httpRequest from "../httpRequest";

export const addMision = (infoMision) => {
	return new Promise(async (resolve, reject) => {
		try {
			const agregarMision = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.addMision}`,
				method: "POST",
				body: JSON.stringify(infoMision),
			});
			resolve(agregarMision);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateMision = (infoMision) => {
	return new Promise(async (resolve, reject) => {
		try {
			const updateMision = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.updateMision}`,
				method: "POST",
				body: JSON.stringify(infoMision),
			});
			resolve(updateMision);
		} catch (error) {
			reject(error);
		}
	});
};

export const eliminarMision = (idMision) => {
	return new Promise(async (resolve, reject) => {
		try {
			const borrarMision = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.deleteMision}/${idMision}`,
				method: "POST",
			});
			resolve(borrarMision);
		} catch (error) {
			reject(error);
		}
	});
};

export const getMisiones = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: misiones } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getMisiones}`,
				method: "GET",
			});
			resolve(misiones);
		} catch (error) {
			reject(error);
		}
	});
};

export const addParticipante = (infoParticipante) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { code: nuevoParticipante } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.addParticipante}`,
				method: "POST",
				body: JSON.stringify(infoParticipante),
			});
			resolve(nuevoParticipante);
		} catch (error) {
			reject(error);
		}
	});
};

export const getParticipaciones = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: participaciones } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getParticipaciones}/${idPerfil}`,
				method: "POST",
			});
			resolve(participaciones);
		} catch (error) {
			reject(error);
		}
	});
};

export const abandonarProyecto = (infoMision) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: abandonarProyecto } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.deleteParticipante}`,
				method: "POST",
				body: JSON.stringify(infoMision),
			});
			resolve(abandonarProyecto);
		} catch (error) {
			reject(error);
		}
	});
};

export const guardarArchivoProyecto = (infoProyecto) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: saveArchivo } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.saveProyecto}`,
				method: "POST",
				body: JSON.stringify(infoProyecto),
			});
			resolve(saveArchivo);
		} catch (error) {
			reject(error);
		}
	});
};

export const descargarArchivo = (nombreArchivo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: descargar } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.downloadTrabajoMision}`,
				method: "POST",
				body: JSON.stringify(nombreArchivo),
			});
			resolve(descargar);
		} catch (error) {
			reject(error);
		}
	});
};

export const getProyectosByPerfil = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: saveArchivo } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getProyectosByPerfil}/${idPerfil}`,
				method: "POST",
			});
			resolve(saveArchivo);
		} catch (error) {
			reject(error);
		}
	});
};

export const getTrabajosPorMision = (idMision) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: obtenerTrabajos } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getTrabajosPorMision}/${idMision}`,
				method: "POST",
			});
			resolve(obtenerTrabajos);
		} catch (error) {
			reject(error);
		}
	});
};

export const setGanador = (infoGanador) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: seleccionarGanador } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.setGanador}`,
				method: "POST",
				body: JSON.stringify(infoGanador),
			});
			resolve(seleccionarGanador);
		} catch (error) {
			reject(error);
		}
	});
};

export const getGanadores = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: obtenerGanadores } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getGanadores}`,
				method: "GET",
			});
			resolve(obtenerGanadores);
		} catch (error) {
			reject(error);
		}
	});
};

export const getGanadoresMes = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: obtenerGanadores } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getGanadoresMes}`,
				method: "GET",
			});
			resolve(obtenerGanadores);
		} catch (error) {
			reject(error);
		}
	});
};

export const getGanadorMision = (idMision) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: seleccionarGanador } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getGanadorMision}/${idMision}`,
				method: "POST",
			});
			resolve(seleccionarGanador);
		} catch (error) {
			reject(error);
		}
	});
};

export const otorgarParticipaciones = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: darParticipaciones } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.otorgarParticipaciones}/${idPerfil}`,
				method: "POST",
			});
			resolve(darParticipaciones);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCreditos = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: creditos } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getCreditos}/${idPerfil}`,
				method: "POST",
			});
			resolve(creditos);
		} catch (error) {
			reject(error);
		}
	});
};

export const getRecompensas = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getRecompensas}`,
				method: "GET",
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};

export const addRecompensa = (infoRecompensa) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { message: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.addRecompensa}`,
				method: "POST",
				body: JSON.stringify(infoRecompensa),
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateRecompensa = (infoRecompensa) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { message: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.updateRecompensa}`,
				method: "POST",
				body: JSON.stringify(infoRecompensa),
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteRecompensa = (idRecompensa) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { message: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.deleteRecompensa}/${idRecompensa}`,
				method: "POST",
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};

export const obtenerVictoriasParticipaciones = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getVictoriasParticipaciones}/${idPerfil}`,
				method: "POST",
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};

export const getParticipacionesUsuario = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.getParticipacionesDelUsuario}/${idPerfil}`,
				method: "POST",
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};

export const canjearRecompensa = (infoSolicitud) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: recompensas } = await httpRequest({
				url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.canjearRecompensa}`,
				method: "POST",
				body: JSON.stringify(infoSolicitud),
			});
			resolve(recompensas);
		} catch (error) {
			reject(error);
		}
	});
};
