import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getPreguntas = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const preguntas = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.getPreguntas}`,
        method: "GET",
      });

      resolve(preguntas);
    } catch (error) {
      // console.log("Error al obtener todas las preguntas: ", error);

      reject(error);
    }
  });
};

export const getPreguntasByCodigoLeccion = (codigoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preguntasLeccion = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.getPreguntasByCodigoLeccion}/${codigoLeccion}`,
        method: "POST",
      });

      resolve(preguntasLeccion);
    } catch (error) {
      // console.log("Error al obtener las preguntas de la lección", error);

      reject(error);
    }
  });
};

export const getPreguntaById = (idPregunta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pregunta = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.getPreguntaById}/${idPregunta}`,
        method: "POST",
      });

      resolve(pregunta);
    } catch (error) {
      // console.log("Error al obtener la pregunta: ", error);

      reject(error);
    }
  });
};

export const getPreguntasByCodigoCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: preguntasCurso } = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.getPreguntasByCodigoCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(preguntasCurso);
    } catch (error) {
      // console.log("Error al obtener las preguntas del curso: ", error);

      reject(error);
    }
  });
};

export const addPregunta = async (informacionPregunta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preguntaAgregada = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.addPregunta}`,
        method: "POST",
        body: JSON.stringify(informacionPregunta),
      });

      resolve(preguntaAgregada);
    } catch (error) {
      // console.log("Error agregando pregunta: ", error);

      reject(error);
    }
  });
};

export const updatePregunta = async (informacionPregunta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preguntaActualizada = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.updatePregunta}`,
        method: "POST",
        body: JSON.stringify(informacionPregunta),
      });

      resolve(preguntaActualizada);
    } catch (error) {
      // console.log("Error al actualizar la pregunta: ", error);

      reject(error);
    }
  });
};

export const deactivatePregunta = async (idPregunta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deactivatedQuestion = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.deactivatePregunta}/${idPregunta}`,
        method: "POST",
      });

      resolve(deactivatedQuestion);
    } catch (error) {
      // console.log("Error al desactivar la pregunta: ", error);

      reject(error);
    }
  });
};

export const reactivatePregunta = async (idPregunta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preguntaReactivada = await httpRequest({
        url: `${endpointsPaths.bancoPreguntas}/${httpRequestsPaths.bancoPreguntas.reactivatePregunta}/${idPregunta}`,
        method: "POST",
      });

      resolve(preguntaReactivada);
    } catch (error) {
      // console.log("Error al reactivar la pregunta: ", error);

      reject(error);
    }
  });
};
