import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpFormDataRequest from "../httpFormDataRequest";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const validateCorreoFolio = (folioContrato, correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validacionCorreoFolio = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.validateCorreoFolio}/${folioContrato}`,
        method: "POST",
        body: JSON.stringify({ correo }),
      });

      resolve(validacionCorreoFolio);
    } catch (error) {
      // console.log("Error validando el correo del folio proporcionado: ", error);

      reject(error);
    }
  });
};

export const validateFolio = async (folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validacionFolio = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.validateFolio}/${folioContrato}`,
        method: "POST",
      });

      resolve(validacionFolio);
    } catch (error) {
      // console.log('Error validando el folio: ', error);

      reject(error);
    }
  });
};

export const validarDatosPago = async (folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validarPago = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.validarDatosPago}/${folioContrato}`,
        method: "POST",
      });

      resolve(validarPago);
    } catch (error) {
      // console.log('Error validando datos del pago: ', error);

      reject(error);
    }
  });
};

export const registrarNuevoPago = async (datosPago, folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pagoRegistrado = httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.registrarNuevoPago}/${folioContrato}`,
        method: "POST",
        body: JSON.stringify(datosPago),
      });

      resolve(pagoRegistrado);
    } catch (error) {
      // console.log("Error al registrar el nuevo pago del cliente: ", error);

      reject(error);
    }
  });
};

export const registrarNuevoPagoCertificacionMos = async (datosPago, folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pagoRegistrado = httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.registrarNuevoPago}/${folioContrato}`,
        method: "POST",
        body: JSON.stringify(datosPago),
      });

      resolve(pagoRegistrado);
    } catch (error) {
      // console.log("Error al registrar el nuevo pago del cliente: ", error);

      reject(error);
    }
  });
};

export const getDatosFolio = async (folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: datosFolio } = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.getDatosFolio}/${folioContrato}`,
        method: "POST",
      });

      // console.log(datosFolio);

      resolve(datosFolio);
    } catch (error) {
      // console.log('Error consultando los datos del folio ' + folioContrato, error);

      reject(error);
    }
  });
};

export const getAsistentesCapacitacion = async (folioCapacitacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      let { data: asistentesCapacitacion } = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.getAsistentesCapacitacion}/${folioCapacitacion}`,
        method: "POST",
      });

      if (!Array.isArray(asistentesCapacitacion)) {
        asistentesCapacitacion = [asistentesCapacitacion];
      }

      resolve(asistentesCapacitacion);
    } catch (error) {
      // console.log("Error al obtener a los asistentes de la capacitación: ", error);

      reject(error);
    }
  });
};

export const validateTokenFolio = async (folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: token } = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.validateTokenFolio}/${folioContrato}`,
        method: "POST",
      });

      resolve(token);
    } catch (error) {
      // console.log("algo sucedió al consultar el token del folio: ", error);

      reject(error);
    }
  });
};

export const confirmarRegistroInicial = async (datosConfirmacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: confirmacionRegistroInicial } = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.confirmarRegistroInicial}`,
        method: "POST",
        body: JSON.stringify(datosConfirmacion),
      });

      resolve(confirmacionRegistroInicial);
    } catch (error) {
      // console.log("Error al confirmar el registro en el SAI: ", error);

      reject(error);
    }
  });
};

export const registrarSegundoUsuarioSai = async (datosSegundoUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: confirmacionRegistroSegundoUsuario } = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.registrarSegundoUsuario}`,
        method: "POST",
        body: JSON.stringify(datosSegundoUsuario),
      });

      resolve(confirmacionRegistroSegundoUsuario);
    } catch (error) {
      // console.log("Error al registar al segundo usuario en SAI: ", error);

      reject(error);
    }
  });
};

export const actualizarSegundoUsuarioSai = async (datosSegundoUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: segundoUsuarioActualizado } = await httpRequest({
        url: `${endpointsPaths.sai}/${httpRequestsPaths.sai.actualizarSegundoUsuario}`,
        method: "POST",
        body: JSON.stringify(datosSegundoUsuario),
      });

      resolve(segundoUsuarioActualizado);
    } catch (error) {
      // console.log("Error al actualizar al segundo usuario en SAI: ", error);

      reject(error);
    }
  });
};

export const validarDatosPreRegistro = (datosPreRegistro) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: validacionPreRegistro } = await httpRequest({
        url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.validarDatosPreRegistro }`,
        method: 'POST',
        body: JSON.stringify(datosPreRegistro)
      });

      resolve(validacionPreRegistro);
    } catch (error) {
      reject(error);
    }
  });
};

export const procesarDatosPreRegistro = (jsonPreRegistro) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validacionPreRegistro = await httpRequest({
        url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.procesarDatosPreRegistro }`,
        method: 'POST',
        body: JSON.stringify(jsonPreRegistro)
      });

      resolve(validacionPreRegistro);
    } catch (error) {
      reject(error);
    }
  });
};

export const getLinkContrato = ({folioContrato, correo}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: linkContrato } = await httpRequest({
        url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.getLinkContratoByFolio }/${ folioContrato }`,
        method: 'POST',
        body: JSON.stringify({correo})
      });

      resolve(linkContrato);
    } catch (error) {
      reject(error);
    }
  });
};

export const actualizarDatosFirma = (datosContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const datosFirma = await httpRequest({
        url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.actualizarDatosFirma }`,
        method: 'POST',
        body: JSON.stringify(datosContrato)
      });

      resolve(datosFirma);
    } catch (error) {
      reject(error);
    }
  });
};

export const generarDomiciliacion = (formData, folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: pathPDF } = await httpFormDataRequest({
				url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.generarDomiciliacion }/${ folioContrato }`,
				body: formData
			});

      resolve(pathPDF);
    } catch (error) {
      reject(error);
    }
  });
};

export const enviarDomiciliacionSai = (pdfData, folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const domiciliacionEnviada = await httpRequest({
        url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.enviarDomiciliacionSai }/${ folioContrato }`,
        method: 'POST',
        body: JSON.stringify(pdfData)
      });

      console.log(domiciliacionEnviada);

      resolve(domiciliacionEnviada);
    } catch (error) {
      console.log(error);

      reject(error);
    }
  });
};

export const subirFotosSai = (datosFotos) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fotoCargada = await httpRequest({
        url: `${ endpointsPaths.sai }/${ httpRequestsPaths.sai.subirFotosSai }`,
        method: 'POST',
        body: JSON.stringify(datosFotos)
      });

      resolve(fotoCargada);
    } catch (error) {
      reject(error);
    }
  });
};