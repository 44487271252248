import { Notificacion } from "@components/general/Alertas/Alertas";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { updateTema } from "@helpers/httpRequests/httpRequestsTemas/httpRequestsTemas";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const EditarTema = ({
  setTemaModificado,
  tema,
  categoria,
  setCategoriaModificada,
}) => {
  const { titulo, textoPublicacion, alias, idCategoria, idTema } = tema;

  console.log(idCategoria, parseInt(categoria));
  const [categorias, setCategorias] = useState([]);

  const [formValues, setFormValues] = useState({
    textoPublicacion: textoPublicacion,
    tituloPublicacion: titulo,
    idCategoria,
    idForo: 1,
    alias,
    fechaCreacion: getUnixTimestamp(),
    idTema,
  });

  const [validacionForm, setValidacionForm] = useState({
    idCategoria: false,
    tituloPublicacion: false,
    textoPublicacion: false,
  });

  const [caracteresRestantes, setCaracteresRestantes] = useState(500-textoPublicacion.length);

  const validarKeyDown = (event) => {
    const {key}= event;

    
		if(key === 'Backspace'){
			if(caracteresRestantes < 500){
				setCaracteresRestantes(caracteresRestantes + 1);
			}
		}else {
      if (caracteresRestantes > 0) {
        setCaracteresRestantes(caracteresRestantes - 1);
      }
		}
  }

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    console.log(name, value);
   
    if(caracteresRestantes > 0) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

    if (value === "" || value === "0") {
      setValidacionForm({
        ...validacionForm,
        [name]: !validacionForm.name,
      });
    } else {
      setValidacionForm({
        ...validacionForm,
        [name]: false,
      });
    }
  };

  const publicar = async (e) => {
    e.preventDefault();

    try {
      const { tituloPublicacion, textoPublicacion, idCategoria, idTema } =
        formValues;

      console.log(idCategoria);

      if (tituloPublicacion === "") {
        setValidacionForm({
          ...validacionForm,
          tituloPublicacion: true,
        });
      } else if (textoPublicacion === "") {
        setValidacionForm({
          ...validacionForm,
          textoPublicacion: true,
        });
      } else if (idCategoria === 0) {
        console.log();
        setValidacionForm({
          ...validacionForm,
          idCategoria: true,
        });
      }

      if (
        tituloPublicacion !== "" &&
        textoPublicacion !== "" &&
        idCategoria !== 0
      ) {
        const nuevaPublicacion = {
          titulo: tituloPublicacion,
          textoPublicacion: textoPublicacion
            .replace(/'/g, "\\'")
            .replace(/"/g, '\\"'),
          idCategoria,
          idTema,
        };

        //UPDATE PARA EL TEMA
        const { message } = await updateTema(nuevaPublicacion);
        console.log("sE ACTUALIZO LA PUBLICACION");
        setTemaModificado(true);
        setCategoriaModificada(parseInt(categoria));

        Notificacion("success", message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const categorias = async () => {
      try {
        const { data } = await getCategoriasCursos();
        console.log(data);
        if (Array.isArray(data)) {
          setCategorias(data);
        } else {
          setCategorias([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    categorias();
  }, []);

  useEffect(() => {
    if(formValues.textoPublicacion === ''){
      setCaracteresRestantes(500)
    }
  }, [formValues.textoPublicacion])
  

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={publicar} className="text-start">
            <Form.Group>
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                className={`mb-3 ${
                  validacionForm.idCategoria ? "is-invalid" : ""
                }`}
                name="idCategoria"
                onChange={handleOnChange}
                as="select"
                placeholder=""
              >
                {categorias.map(
                  (categoria, key) =>
                    categoria.idCategoria === formValues.idCategoria && (
                      <option
                        disabled
                        selected
                        key={key}
                        value={categoria.idCategoria}
                      >
                        {categoria.nombre} - Categoría actual
                      </option>
                    )
                )}
                {categorias.map((categoria, key) => (
                  <option key={key} value={categoria.idCategoria}>
                    {categoria.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Selecciona una categoría
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                className={validacionForm.tituloPublicacion ? "is-invalid" : ""}
                type="text"
                name="tituloPublicacion"
                value={formValues.tituloPublicacion}
                onChange={handleOnChange}
                placeholder="Título del tema"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Coloca un título para tu tema
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group >
              <Form.Label>Publicación</Form.Label>
              <Form.Control
                className={validacionForm.textoPublicacion ? "is-invalid" : ""}
                name="textoPublicacion"
                as="textarea"
                value={formValues.textoPublicacion}
                onChange={handleOnChange}
                onKeyDown={validarKeyDown}
                rows={5}
                placeholder="Escribe un comentario"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Coloca una descripción a tu tema
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="mb-3 mt-2">
              <Col className="col-auto">
                <small style={{color: 'gray', fontWeight: 'bold'}}>{caracteresRestantes}/500</small>
              </Col>
            </Row>

            <div className="d-grid gap-2">
              <Button type="submit" size="lg">
                Publicar
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditarTema;
