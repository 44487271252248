import { Col, Container, Row } from "react-bootstrap";
import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import React, { useMemo, useState } from "react";
import { faEdit, faEye, faPowerOff, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getGrupos, reactivateGrupo } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";

import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { EditarGrupo } from "@components/administrador/DashboardUsuarios/Modales/Grupo/EditarGrupo/EditarGrupo";
import { EliminarGrupo } from "@components/administrador/DashboardUsuarios/Modales/Grupo/EliminarGrupo/EliminarGrupo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VerGrupo } from "@components/administrador/DashboardUsuarios/Modales/Grupo/VerGrupo/VerGrupo";
import columnasGrupo from "./columnasGrupo";
import { useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";
import { FaEdit, FaEye, FaPowerOff, FaTrash } from "react-icons/fa";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";

export const TableGrupos = () => {
	const [grupos, setGrupos] = useState([]);

	const [dataTableColumns, setDataTableColumns] = useState([]);

	const [grupoEditado, setGrupoEditado] = useState(false);

	const [grupoReactivado, setGrupoReactivado] = useState(false);

	const [grupoEliminado, setGrupoEliminado] = useState(false);

	const handleVerDatosGrupo = async ({ currentTarget }) => {
		try {
			const idGrupo = parseInt(currentTarget.id);

			const dataGrupo = grupos.filter((grupo) => grupo.idGrupo === idGrupo)[0];

			await ModalSweetAlert({
				title: "Datos del grupo",
				html: <VerGrupo grupo={dataGrupo} setGrupoEditado={setGrupoEditado} />,
				showConfirmButton: false,
				showCloseButton: true,
			});
		} catch (error) {
			console.log("Error al ver los datos del estudiante seleccionado: ", error);
		}
	};

	const handleEditDatosGrupo = async ({ currentTarget }) => {
		try {
			const idGrupo = parseInt(currentTarget.id);

			const dataGrupo = grupos.filter((grupo) => grupo.idGrupo === idGrupo)[0];

			await ModalSweetAlert({
				title: "Modificar datos del grupo",
				html: <EditarGrupo grupo={dataGrupo} setGrupoEditado={setGrupoEditado} />,
				showConfirmButton: false,
				showCloseButton: true,
			});
		} catch (error) {
			console.log("Error al editar los datos del estudiante: ", error);
		}
	};

	const handleDeactivateGrupo = async ({ currentTarget }) => {
		try {
			const idGrupo = parseInt(currentTarget.id);

			const dataGrupo = grupos.filter((grupo) => grupo.idGrupo === idGrupo)[0];

			await ModalSweetAlert({
				title: "Eliminar grupo",
				icon: "warning",
				html: <EliminarGrupo grupo={dataGrupo} setGrupoEliminado={setGrupoEliminado} />,
				showConfirmButton: false,
				showCloseButton: true,
			});
		} catch (error) {
			console.log("Error al desactivar al estudiante: ", error);
		}
	};

	const handleReactivateGrupo = async ({ currentTarget }) => {
		try {
			const idGrupo = parseInt(currentTarget.id);

			const dataGrupo = grupos.filter((grupo) => grupo.idGrupo === idGrupo)[0];

			await reactivateGrupo(dataGrupo.idGrupo);

			setGrupoReactivado(true);

			Notificacion("success", "El empleado se reactivó correctamente");
		} catch (error) {
			console.log("Error al reactivar el empleado: ", error);
		}
	};

	useEffect(() => {
		const consultarGrupos = async () => {
			try {
				let { data: grupos } = await getGrupos();
				console.log(grupos);
				if (!Array.isArray(grupos)) {
					grupos = [grupos];
				}

				setGrupos(grupos.sort((a, b) => parseInt(a.folioGrupo) - parseInt(b.folioGrupo)));
			} catch (error) {
				console.log("Error al consultar los estudiantes registrados: ", error);
			}
		};

		consultarGrupos();
	}, []);

	// useEffect(() => {
	//     if (grupos.length > 0) {

	//         setDataTableColumns([
	//             ...columnasGrupo,
	//             {
	//                 name: 'Acciones',
	//                 cell: (row) => (
	// 					<>
	// 						<span id={`select-${row.idGrupo}`} className="me-1 pointer" onClick={handleVerDatosGrupo}>
	// 							<FontAwesomeIcon icon={faEye} className='text-primary' />
	// 						</span>

	// 						<span id={`edit-${row.idGrupo}`} className="me-1 pointer" onClick={handleEditDatosGrupo}>
	// 							<FontAwesomeIcon icon={faEdit} className='text-info' />
	// 						</span>

	// 						{row.estatus ? (
	// 							<span id={`delete-${row.idGrupo}`} className="me-1 pointer" onClick={handleDeactivateGrupo}>
	// 								<FontAwesomeIcon icon={faTrashAlt} className='text-danger' />
	// 							</span>
	// 						) : (
	// 							<span id={`reactivate-${row.idGrupo}`} className="me-1 pointer" onClick={handleReactivateGrupo}>
	// 								<FontAwesomeIcon icon={faPowerOff} className='text-success' />
	// 							</span>
	// 						)}
	// 					</>
	// 				),
	// 				ignoreRowClick: true,
	// 				allowOverflow: true,
	// 				button: true,
	//             }
	//         ]);
	//     }
	// }, [grupos]);

	useEffect(() => {
		if (grupoEditado || grupoEliminado || grupoReactivado) {
			const consultarGrupo = async () => {
				try {
					console.log("refetching estudiantes");

					let { data: grupos } = await getGrupos();

					if (!Array.isArray(grupos)) {
						grupos = [grupos];
					}

					setGrupos(grupos);

					setGrupoEditado(false);

					setGrupoEliminado(false);

					setGrupoReactivado(false);
				} catch (error) {
					console.log("Error al consultar los empleados registrados: ", error);
				}
			};

			consultarGrupo();
		}
	}, [grupoEditado, grupoEliminado, grupoReactivado]);

	const columnas = useMemo(() => [
		{
			accessorKey: "folioContrato",
			header: "Folio",
			size: 50,
			Cell: ({ cell }) => (
				<div className="text-center">
					<span>{cell.row.original.folioContrato}</span>
				</div>
			),
		},
		{
			accessorKey: "nombreEmpresa",
			header: "Nombre",
		},
		{
			accessorKey: "nombreAsesor",
			header: "Asesor",
		},
		{
			accessorKey: "fechaRegistro",
			header: "Fecha de registro",
			Cell: ({ cell }) => (
				<div className="text-center">
					<span>{getFechaSinHora(cell.row.original.fechaRegistro)}</span>
				</div>
			),
		},
	]);

	return (
		<Container fluid>
			<Row>
				<Col>
					{/* <DataTablesRecipient columns={dataTableColumns} data={grupos} /> */}

					<MaterialReactTable
						localization={MRT_Localization_ES}
						columns={columnas}
						data={grupos}
						enableRowActions
						positionActionsColumn="last"
						renderRowActions={({ row, table }) => (
							<Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
								<IconButton color="primary" id={row.original.idGrupo} onClick={handleVerDatosGrupo}>
									<FaEye className="text-info" />
								</IconButton>

								<IconButton color="secondary" id={row.original.idGrupo} onClick={handleEditDatosGrupo}>
									<FaEdit className="text-primary" />
								</IconButton>

								{row.original.estatusGrupo ? (
									<IconButton color="secondary" id={`${row.original.idGrupo}`} onClick={handleDeactivateGrupo}>
										<FaTrash color="red" />
									</IconButton>
								) : (
									<IconButton color="secondary" id={`${row.original.idGrupo}`} onClick={handleReactivateGrupo}>
										<FaPowerOff color="green" />
									</IconButton>
								)}
							</Box>
						)}
					/>
				</Col>
			</Row>
		</Container>
	);
};
