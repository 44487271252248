import { Notificacion } from "@components/general/Alertas/Alertas";
import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpFormDataRequest from "@helpers/httpRequests/httpFormDataRequest";
import { updatePublicacion } from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import React, { useRef } from "react";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const EditarPublicacion = ({
  setPublicacionModificada,
  publicacion,
  folioGrupo,
  numeroPublicacion,
}) => {
  // console.log(folioGrupo);
  // console.log(publicacion);

  const refInputFile = useRef(null);

  const [archivoValido, setArchivoValido] = useState(false);

  const { titulo, descripcion, archivoActividad, idPublicacion, pesoArchivo } =
    publicacion;

  const [formValues, setFormValues] = useState({
    titulo,
    descripcion,
    archivoAdjunto: archivoActividad,
  });

  const [validacionForm, setValidacionForm] = useState({
    titulo: false,
    descripcion: false,
    archivoAdjunto: false,
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (value === "") {
      setValidacionForm({
        ...validacionForm,
        [name]: !validacionForm.name,
      });
    } else {
      setValidacionForm({
        ...validacionForm,
        [name]: false,
      });
    }
  };

  const handleOnChangeFile = (event) => {
    const { name, value } = event.target;

    const file = event.target.files[0];
    // console.log(file);

    if (regExpStrings.regexCaracteresUTF8(file.name)) {
      console.log("tiene utf-8");

      refInputFile.current.value = "";

      refInputFile.current.previousElementSibling.innerHTML =
        "Seleccionar archivo:";

      setArchivoValido(false);

      return NotificationManager.error("Nombre de archivo inválido");
    }

    setFormValues({
      ...formValues,
      [name]: file,
    });

    setValidacionForm({
      ...validacionForm,
      [name]: true,
    });
  };

  const actualizarPublicacion = async (e) => {
    e.preventDefault();
    try {
      const { titulo, descripcion, archivoAdjunto } = formValues;

      if (titulo === "") {
        // console.log("TITULO");
        setValidacionForm({
          ...validacionForm,
          titulo: true,
        });
      } else if (descripcion === "") {
        // console.log("DESCRIPCION");
        setValidacionForm({
          ...validacionForm,
          descripcion: true,
        });
      }

      // console.log("VALIDACION DEL ARCHIVO: ", validacionForm.archivoAdjunto);

      if (validacionForm.archivoAdjunto) {
        //FETCH PARA MANDAR LOS DATOS DEL ARCHIVO AL SERVIDOR
        const formData = new FormData();

        formData.append("archivoAdjunto", archivoAdjunto);

        const { code, message: fetchMessage } = await httpFormDataRequest({
          url: `${endpointsPaths.foroEmpresarial}/${httpRequestsPaths.foroEmpresarial.manejoArchivos.addArchivoNuevaPublicacion}?folioGrupo=${folioGrupo}&numeroPublicacion=${idPublicacion}&archivoOriginal=${archivoActividad}`,
          body: formData,
        });

        if (code !== 200) {
          Notificacion("error", fetchMessage);
        } else {
          if (titulo !== "" && descripcion !== "") {
            const publicacion = {
              titulo,
              descripcion,
              archivoAdjunto:
                archivoAdjunto !== archivoActividad
                  ? archivoAdjunto.name
                  : archivoActividad,
              pesoArchivo,
              idPublicacion,
            };

            // console.log(publicacion);

            const { message } = await updatePublicacion(publicacion);
            setPublicacionModificada(true);

            Notificacion("success", message);
          }
        }
      } else {
        if (titulo !== "" && descripcion !== "") {
          const publicacion = {
            titulo,
            descripcion,
            archivoAdjunto: archivoActividad,
            pesoArchivo,
            idPublicacion,
          };

          // console.log(publicacion);

          const { message } = await updatePublicacion(publicacion);
          setPublicacionModificada(true);

          Notificacion("success", message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <Row>
        <Col className="text-start">
          <Form onSubmit={actualizarPublicacion}>
            <Form.Group>
              <Form.Label>Titulo de la publicación</Form.Label>
              <Form.Control
                className={validacionForm.titulo ? "is-invalid" : ""}
                onChange={handleOnChange}
                name="titulo"
                value={formValues.titulo}
                type="text"
                placeholder="Titulo"
              ></Form.Control>

              <Form.Control.Feedback type="invalid">
                Este campo no puede ir vacío
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Control
                className={validacionForm.descripcion ? "is-invalid" : ""}
                onChange={handleOnChange}
                name="descripcion"
                value={formValues.descripcion}
                as={"textarea"}
                placeholder={"Describe tu publicación"}
              ></Form.Control>

              <Form.Control.Feedback type="invalid">
                Este campo no puede ir vacío
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Adjuntar archivo</Form.Label>
              {/* //* VALIDAR DEL LADO DEL SERVIDOR QUE LOS ARCHIVOS SEAN DE PAQUETERIA OFFICE O PDF */}

              {formValues.archivoAdjunto === "" ? (
                <Form.Control
                  onChange={handleOnChangeFile}
                  name="archivoAdjunto"
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                  type="file"
                ></Form.Control>
              ) : (
                <>
                  <p>{archivoActividad} - Archivo actual </p>
                  <Form.Control
                    onChange={handleOnChangeFile}
                    name="archivoAdjunto"
                    accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                    type="file"
                  ></Form.Control>
                </>
              )}

              <Alert
                variant="danger"
                className={`mt-3 ${!archivoValido ? "" : "d-none"}`}
              >
                Asegúrate que el nombre de tu plantilla no contenga tildes ni
                caracteres especiales
              </Alert>
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button type="submit" size="lg">
                Guardar publicación
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditarPublicacion;
