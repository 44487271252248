import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getLeccionesPersonalizadas = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccionesPersonalizadas } = await httpRequest({
        url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.getLecciones}`,
        method: "GET",
      });

      resolve(leccionesPersonalizadas);
    } catch (error) {
      // console.log("Error al obtener las lecciones: ", error);

      reject(error);
    }
  });
};

export const getLeccionesByidGrupo = async (idGrupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getLecciones } = await httpRequest({
        url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.getLeccionesByGrupo}/${idGrupo}`,
        method: "POST",
      });

      resolve(getLecciones);
    } catch (error) {
      // console.log("Error al descargar el archivo: ", error);

      reject(error);
    }
  });
};

export const addLeccionPersonalizada = async (infoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addLeccion = await httpRequest({
        url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.addLeccionPersonalizada}`,
        method: "POST",
        body: JSON.stringify(infoLeccion),
      });

      resolve(addLeccion);
    } catch (error) {
      // console.log("Error al agregar la lección");

      reject(error);
    }
  });
};

export const updateLeccionPersonalizada = async (infoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateLeccion = await httpRequest({
        url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.updateLeccionPersonalizada}`,
        method: "POST",
        body: JSON.stringify(infoLeccion),
      });

      resolve(updateLeccion);
    } catch (error) {
      // console.log("Error al modificar la lección");

      reject(error);
    }
  });
};

export const deactivateLeccionPersonalizada = async (idLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deactivateLeccion = await httpRequest({
        url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.deactivateLeccionPersonalizada}/${idLeccion}`,
        method: "POST",
      });

      resolve(deactivateLeccion);
    } catch (error) {
      // console.log("Error al modificar la lección");

      reject(error);
    }
  });
};

export const reactivateLeccionPersonalizada = async (idLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reactivateLeccion = await httpRequest({
        url: `${endpointsPaths.leccionesPersonalizadas}/${httpRequestsPaths.leccionesPersonalizadas.reactivateLeccionPersonalizada}/${idLeccion}`,
        method: "POST",
      });

      resolve(reactivateLeccion);
    } catch (error) {
      // console.log("Error al modificar la lección");

      reject(error);
    }
  });
};
