import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const VerInfoLeccion = ({ infoLeccion, nombreGrupo }) => {
  const { estatus, nombre, nombrePlantilla } = infoLeccion;

  useEffect(() => {}, []);

  return (
    <Container className="text-start">
      <Row>
        <Col>
          <h5>
            <b>Nombre de la lección:</b> {nombre}
          </h5>
          {nombrePlantilla !== null && (
            <p>
              <b>Plantilla de la lección:</b> {nombrePlantilla}{" "}
            </p>
          )}
          <p>
            <b>Grupo al que pertenece: {nombreGrupo}</b>
          </p>
          <p>
            <b>Estatus:</b>{" "}
            <span className={estatus === 0 ? "text-danger" : "text-success"}>
              {estatus === 0 ? "Inactivo" : "Activo"}
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default VerInfoLeccion;
