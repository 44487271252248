import React, { useState, useEffect } from "react";
import { getSeccionByCodigo, getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { addUltimaLeccionTomada, getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";

import { Accordion, Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
// import { AccordionTab, Accordion } from "primereact/accordion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import { Checkbox } from "primereact/checkbox";
import { addNuevaLeccionVista, getLeccionesVistas, removeLeccionVista } from "@helpers/httpRequests/httpRequestLeccionesVistas/httpRequestsLeccionesVistas";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

const DespliegueSeccionesLecciones = ({
	setNombreLeccionSeleccionada,
	codigoCurso,
	setUrlLeccion,
	verificarEvaluacionLeccion,
	setLeccionActual,
	setShowEvaluacionLeccion,
	leccionActual,
	handleScrollToTop,
	idPerfil,
	setShowBtnEvaluacion,
	mostrarDocumento,
	tipoLeccion,
	setNombreSeccion,
	setListadoLecciones,
	refSiguienteLeccion,
	refLeccionAnterior,
	refSiguienteSeccion,
	refSeccionAnterior,
}) => {
	const { contextValue, setContextValue } = useContext(UserContext);


	const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);

	const { codigoSeccionSeleccionada, ultimoCursoTomado } = contextValue;

	const [leccionesVistas, setLeccionesVistas] = useState([]);

	const [leccionEsSeleccionada, setLeccionEsSeleccionada] = useState(false);

	//*STATES PARA ALMACENAR LAS LECCIONES Y SECCIONES
	const [secciones, setSecciones] = useState([]);

	const [lecciones, setLecciones] = useState([]);

	//*LECCION SELECCIONADA
	const [activeIndex, setActiveIndex] = useState(null);

	const handleTabChange = (index, nuevaSeccion = false) => {
		if (nuevaSeccion) {
			setActiveIndex(index);
		} else {
			// Si el índice actual es igual al nuevo índice, cerrar la pestaña
			setActiveIndex(activeIndex === index ? null : index);
		}
	};

	const handleSeleccionSeccion = async (codigoSeccion, siguienteSeccion = false, seccionAnterior = false) => {
		try {
			const { data, msg: message } = await getLeccionesByCodigoSeccion(codigoSeccion);

			if (data.length === 0) {
				return Notificacion("info", message);
			} else {
				setLecciones(Array.isArray(data) ? data : [data]);
				setListadoLecciones(Array.isArray(data) ? data : [data]);

				if (siguienteSeccion) {
					handleSeleccionLeccion({
						nombre: Array.isArray(data) ? data[0].nombre : data.nombre,
						codigoSeccion: Array.isArray(data) ? data[0].codigoSeccion : data.codigoSeccion,
						codigoLeccion: Array.isArray(data) ? data[0].codigoLeccion : data.codigoLeccion,
					});

					const nivel = parseInt(codigoSeccion.slice(4, 6));
					handleTabChange(nivel - 1, true);
				} else if (seccionAnterior) {
					handleSeleccionLeccion({
						nombre: Array.isArray(data) ? data[data.length - 1].nombre : data.nombre,
						codigoSeccion: Array.isArray(data) ? data[data.length - 1].codigoSeccion : data.codigoSeccion,
						codigoLeccion: Array.isArray(data) ? data[data.length - 1].codigoLeccion : data.codigoLeccion,
					});
					const nivel = parseInt(codigoSeccion.slice(4, 6));
					handleTabChange(nivel - 1, true);
				}
			}
		} catch (error) {
			console.log("Error al consultar las lecciones: ", error);

			NotificacionExtendida("info", "No hay lecciones por mostrar");
		}
	};

	const handleSeleccionLeccion = async (leccion) => {
		try {
			const { nombre, codigoLeccion, codigoSeccion } = leccion;

			handleSeleccionSeccion(codigoSeccion);

			setNombreLeccionSeleccionada(nombre);

			// const codigoSeccionSeleccionada = codigoLeccion.substring(6, 0);

			setContextValue({
				...contextValue,
				ultimaLeccionTomada: codigoLeccion,
				codigoSeccionSeleccionada: codigoSeccion,
			});

			// console.log("Se Registra la ultima leccion desde handleSeleccionLeccion");
			//*Update del campo 'idUltimaLeccionTomada'
			const infoLeccion = {
				idPerfil: idPerfil,
				codigoLeccion: codigoLeccion,
				fecha: getUnixTimestamp(),
			};
			const { message } = await addUltimaLeccionTomada(infoLeccion);

			//*VALIDACION TEMPORAL PARA EL CURSO DE EXCEL APLICADO (TEMPORAL)
			let urlLeccionSeleccionada = ``;

			if (codigoLeccion.startsWith("EA16")) {
				urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${codigoCurso}/EA16/${codigoLeccion}`;
			} else {
				// validacion cursos de invitado
				// si es diferente de undefined, sí es un curso de invitado
				const cursoInvitado = codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === codigoCurso);

				if (cursoInvitado !== undefined) {
					// console.log("Cambiando leccion de curso de invitado");

					urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/${codigoLeccion}`;
				} else {
					urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${codigoCurso}/${codigoLeccion}`;
				}
			}

			//Se recupera el nombre de la seccion para mandarla a viewcurso y mostrarla en el breadcrumb
			const { data: seccion } = await getSeccionByCodigo(codigoSeccion);

			setNombreSeccion(seccion.nombre);

			// console.log("Solicitando recurso: ", urlLeccionSeleccionada);

			setShowBtnEvaluacion(true);

			setUrlLeccion(urlLeccionSeleccionada);

			setLeccionActual(codigoLeccion);

			setShowEvaluacionLeccion(false);

			// handleScrollToTop();

			verificarEvaluacionLeccion(codigoLeccion);

			//Se valida si el tipo de leccion es diferente al tipo de captivate y se ejecuta el modal de mostrarDocumento pasandole el codigo de la leccion para mostrar
			if (tipoLeccion !== "captivate") {
				mostrarDocumento(codigoLeccion);
			}

			setLeccionEsSeleccionada(true);
		} catch (error) {
			console.log(error);
		}
	};

	const obtenerLeccionesVistas = async () => {
		try {
			// console.log("Se obtienen las lecciones por segunda vez");

			const { data } = await getLeccionesVistas(idPerfil);
			// console.log(data);
			if (Array.isArray(data)) {
				setLeccionesVistas(data);
			} else {
				setLeccionesVistas([data]);
			}
		} catch (error) {
			console.log(error);
			setLeccionesVistas([]);
		}
	};

	const handleLeccionVista = async ({ target }) => {
		// console.log(leccionesVistas);

		const { name, value } = target;

		// console.log(value);

		const resultado = leccionesVistas.some((leccionVista) => leccionVista.codigoLeccion === value);

		// console.log(resultado);

		if (!resultado) {
			// console.log("Se agrega nueva leccion vista");
			try {
				const infoLeccion = {
					idPerfil: idPerfil,
					codigoCurso: codigoCurso,
					codigoLeccion: value,
					fecha: getUnixTimestamp(),
				};

				await addNuevaLeccionVista(infoLeccion);

				obtenerLeccionesVistas();
			} catch (error) {
				console.log(error);
				Notificacion("error", "Error al guardar tu avance");
			}
		} else {
			// console.log("ya existe el valor en el objeto")
			//Aqui el valor si ya existe se eliminara del objeto

			try {
				const infoLeccion = {
					idPerfil: idPerfil,
					codigoLeccion: value,
				};
				const remove = await removeLeccionVista(infoLeccion);

				obtenerLeccionesVistas();

				// console.log(remove);
				for (var i = 0; i < leccionesVistas.length; i++) {
					if (leccionesVistas[i].codigoLeccion === value) {
						leccionesVistas.splice(i, 1);
						i--; // actualiza el índice después de eliminar un elemento
					}
				}

				// console.log(leccionesVistas);
			} catch (error) {
				console.log(error);
				Notificacion("error", "Error al modificar tu avance");
			}
		}
	};

	const handleSiguienteSeccion = (codigoSeccion) => {
		handleSeleccionSeccion(codigoSeccion, true);
	};

	React.useImperativeHandle(refSiguienteSeccion, () => ({
		handleSiguienteSeccion,
	}));

	const handleSeccionAnterior = (codigoSeccion) => {
		handleSeleccionSeccion(codigoSeccion, false, true);
	};

	React.useImperativeHandle(refSeccionAnterior, () => ({
		handleSeccionAnterior,
	}));

	const handleSiguienteLeccion = (indiceLeccion) => {
		handleSeleccionLeccion({
			nombre: Array.isArray(lecciones) ? lecciones[indiceLeccion].nombre : lecciones.nombre,
			codigoLeccion: Array.isArray(lecciones) ? lecciones[indiceLeccion].codigoLeccion : lecciones.codigoLeccion,
			codigoSeccion: Array.isArray(lecciones) ? lecciones[indiceLeccion].codigoSeccion : lecciones.codigoSeccion,
		});
	};

	React.useImperativeHandle(refSiguienteLeccion, () => ({
		handleSiguienteLeccion,
	}));

	const handleLeccionAnterior = (indiceLeccion) => {
		handleSeleccionLeccion({
			nombre: Array.isArray(lecciones) ? lecciones[indiceLeccion].nombre : lecciones.nombre,
			codigoLeccion: Array.isArray(lecciones) ? lecciones[indiceLeccion].codigoLeccion : lecciones.codigoLeccion,
			codigoSeccion: Array.isArray(lecciones) ? lecciones[indiceLeccion].codigoSeccion : lecciones.codigoSeccion,
		});
	};

	React.useImperativeHandle(refLeccionAnterior, () => ({
		handleLeccionAnterior,
	}));

	useEffect(() => {
		const consultarSecciones = async () => {
			try {
				const { data, message } = await getSeccionesByCodigoCurso(codigoCurso);

				if (data.length === 0) {
					return Notificacion("info", message);
				} else {
					setSecciones(Array.isArray(data) ? data : [data]);
				}
			} catch (error) {
				console.log("Error al consultar las secciones: ", error);

				NotificacionExtendida("error", "Algo sucedió al consultar las secciones del curso, recarga la página");
			}
		};

		consultarSecciones();

		// const addUltimaLeccion = async() =>{
		// 	console.log("se actualiza la leccion tomada,  ",leccionActual);
		// 	try {
		// 		const infoLeccion = {
		// 			idPerfil: idPerfil,
		// 			codigoLeccion: leccionActual,
		// 			fecha: getUnixTimestamp()

		// 		}
		// 		const { message } = await addUltimaLeccionTomada(infoLeccion);
		// 		console.log(message);
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// }

		// addUltimaLeccion()

		obtenerLeccionesVistas();

		const listadosDeLecciones = async () => {
			try {
				const seccionActual = leccionActual.slice(0, 6);

				const { data } = await getLeccionesByCodigoSeccion(seccionActual);

				setLecciones(data);
				setListadoLecciones(data);
			} catch (error) {
				console.log(error);
			}
		};

		listadosDeLecciones();

		setLeccionEsSeleccionada(true);

	}, []);

	const marcarLeccionVista = async() => {
		if (leccionEsSeleccionada) {
			
			try {
				const infoLeccion = {
					idPerfil: idPerfil,
					codigoCurso: codigoCurso,
					codigoLeccion: leccionActual,
					fecha: getUnixTimestamp(),
				};

				await addNuevaLeccionVista(infoLeccion);

				obtenerLeccionesVistas();
			} catch (error) {
				console.log(error);
				Notificacion("error", "Error al guardar tu avance");
			}

			setLeccionEsSeleccionada(false);

			

		}

	}

	useEffect(() => {
		marcarLeccionVista();
	}, [leccionEsSeleccionada || leccionActual])


	useEffect(() => {
		const consultarSecciones = async () => {
			try {
				const { data, message } = await getSeccionesByCodigoCurso(codigoCurso);

				if (data.length === 0) {
					return Notificacion("info", message);
				} else {
					setSecciones(Array.isArray(data) ? data : [data]);
				}
			} catch (error) {
				console.log("Error al consultar las secciones: ", error);

				NotificacionExtendida("error", "Algo sucedió al consultar las secciones del curso, recarga la página");
			}
		};

		consultarSecciones();

		// const addUltimaLeccion = async() =>{
		// 	console.log("se reactualiza la leccion tomada");
		// 	try {
		// 		const infoLeccion = {
		// 			idPerfil: idPerfil,
		// 			codigoLeccion: leccionActual,
		// 			fecha: getUnixTimestamp()

		// 		}
		// 		const { message } = await addUltimaLeccionTomada(infoLeccion);
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// }

		// addUltimaLeccion()

		setActiveIndex(null);
	}, [codigoCurso]);

	useEffect(() => {
		setSeccionSeleccionada(codigoSeccionSeleccionada);
	}, [codigoSeccionSeleccionada]);

	return (
		<div className="pt-1">
			{secciones.length > 0 ? (
				<Accordion>
					{secciones.map((seccion, idx) => (
						<Accordion.Item eventKey={idx} onClick={() => handleSeleccionSeccion(seccion.codigoSeccion)}>
							<Accordion.Header>
								{seccionSeleccionada === seccion.codigoSeccion ? (
									<b>
										{idx + 1}. {seccion.nombre} - Sección Actual
									</b>
								) : (
									<span>
										{idx + 1}. {seccion.nombre}
									</span>
								)}
							</Accordion.Header>

							<Accordion.Body>
								<Card body style={{ padding: "0" }}>
									{lecciones?.length > 0 ? (
										<>
											<Container>
												{lecciones.map((leccion, idx) => (
													<div key={idx}>
														<Row>
															<Col xs={10} className="">
																{/* <Col> */}
																<div className="d-grid gap-2">
																	<Button
																		style={{
																			background: leccion.codigoLeccion === leccionActual ? "orange" : "none",
																		}}
																		key={idx}
																		name={leccion.nombre}
																		id={leccion.codigoLeccion}
																		onClick={() => handleSeleccionLeccion(leccion)}
																		className="text-start"
																		size="lg"
																		variant="transparent"
																	>
																		<span>
																			<b>{idx + 1}</b>. {leccion.nombre}
																		</span>
																	</Button>
																</div>
																{/* <Checkbox id={idx+1} name={leccion.codigoLeccion } value={leccion.codigoLeccion} className="mx-3" onChange={handleLeccionVista} checked={(leccion.codigoLeccion in checkedCheckbox || leccionesVistas.some(leccionVista => leccionVista.codigoLeccion === leccion.codigoLeccion)) ? true : false}></Checkbox> */}
															</Col>

															<Col xs={2} className="d-flex align-items-center">
																<OverlayTrigger
																	className="overlay-trigger"
																	placement={"top"}
																	overlay={
																		<Tooltip id={`tooltip-bottom}`}>
																			{leccionesVistas.some((leccionVista) => leccionVista.codigoLeccion === leccion.codigoLeccion) ? "Marcar como no completado" : "Marcar como completado"}
																		</Tooltip>
																	}>
																	<Checkbox
																		id={idx + 1}
																		name={leccion.codigoLeccion}
																		value={leccion.codigoLeccion}
																		className="mx-3"
																		onChange={handleLeccionVista}
																		checked={leccionesVistas.some((leccionVista) => leccionVista.codigoLeccion === leccion.codigoLeccion)}></Checkbox>
																</OverlayTrigger>
															</Col>
														</Row>
													</div>
												))}
											</Container>

											{/* //*AUDIOS DEL CURSO DE PNL======================================================== */}
											{idx === 0 && codigoCurso === "PNLB" && (
												<Container className="mt-3">
													<Row>
														<Col>
															<audio controls>
																<source src="https://virtual.globalclickmexico.com/lecciones/comunicacion/PNLB/practica/1.Introductorio.mp3" type="audio/mp3" />
																Your browser does not support the audio element.
															</audio>
														</Col>
													</Row>

													<Row>
														<Col>
															<audio controls>
																<source src="https://virtual.globalclickmexico.com/lecciones/comunicacion/PNLB/practica/2.Calibraje, tecnica del espejo.mp3" type="audio/mp3" />
																Your browser does not support the audio element.
															</audio>
														</Col>
													</Row>

													<Row>
														<Col>
															<audio controls>
																<source src="https://virtual.globalclickmexico.com/lecciones/comunicacion/PNLB/practica/3.Tecnica del reencuadre.mp3" type="audio/mp3" />
																Your browser does not support the audio element.
															</audio>
														</Col>
													</Row>
												</Container>
											)}
										</>
									) : (
										<div className="text-center">
											<h6>
												Esta sección aun no tiene contenido <FontAwesomeIcon color="grey" icon={faVideoSlash} />
											</h6>
										</div>
									)}
								</Card>
							</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
			) : (
				<div className="text-center">
					<h6>Este curso no cuenta con secciones</h6>
				</div>
			)}
		</div>
	);
};

export default DespliegueSeccionesLecciones;
