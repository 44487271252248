const numberCommaSeparator = (number) => {
  while (/(\d+)(\d{3})/.test(number.toString())) {
    number = number
      .toFixed(2)
      .toString()
      .replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
  }

  return number;
};

export default numberCommaSeparator;
