import {
  faArrowUp,
  faFile,
  faFileDownload,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-scroll";

import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import TitleDashboard from "../Titles/TitleDashboard";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import { SiScratch } from "react-icons/si";
import "@assets/css/components/PlantillasTrabajo/style.css";
import { getPlantillasTrabajo } from "@helpers/httpRequests/httpRequestPlantillasTrabajo/httpRequestPlantillasTrabajo";

const PlantillasTrabajo = ({
  showScrollButton,
  handleChangeComponent,
  handleScrollToTop,
}) => {
  const [plantillasTrabajo, setPlantillasTrabajo] = useState([]);

  const [cursos, setCursos] = useState([]);

  const [cursosPlantillas, setCursosPlantillas] = useState([]);

  const breadcrumbItemsInitialState = [
    { title: "Plantillas de Trabajo", onClick: () => {} },
  ];

  const getIconPlantillaTrabajo = (extension) => {
    let icon = null;

    switch (extension) {
      case "xlsx":
        icon = <FontAwesomeIcon icon={faFileExcel} color="#185c37" />;
        break;

      case "sb3":
        icon = <SiScratch style={{ color: "#ffbe00" }} />;
        break;

      default:
        icon = <FontAwesomeIcon icon={faFile} color="" />;
        break;
    }

    return icon;
  };

  const handleDescargaPlantilla = async ({
    extensionPlantilla,
    urlPlantilla,
    nombrePlantilla,
  }) => {
    try {
      const request = await fetch(urlPlantilla);

      const response = await request.blob();

      const url = window.URL.createObjectURL(new Blob([response]));

      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", `${nombrePlantilla}.${extensionPlantilla}`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {}
  };

  useEffect(() => {
    const consultarPlantillasTrabajo = async () => {
      try {
        let plantillas = await getPlantillasTrabajo();

        if (!Array.isArray(plantillas)) {
          plantillas = [plantillas];
        }

        setPlantillasTrabajo(plantillas);
      } catch (error) {
        console.log("error al obtener las plantillas de trabajo: ", error);
      }
    };

    const consultarCursos = async () => {
      try {
        let { data: cursos } = await getCursos();

        if (!Array.isArray(cursos)) {
          cursos = [cursos];
        }

        setCursos(cursos);
      } catch (error) {
        console.log("error al obtener las plantillas de trabajo: ", error);
      }
    };

    consultarPlantillasTrabajo();

    consultarCursos();
  }, []);

  useEffect(() => {
    if (plantillasTrabajo.length > 0 && cursos.length > 0) {
      let aux = [];

      plantillasTrabajo.forEach((plantilla) => {
        const codigoCurso = plantilla.codigoLeccion.slice(
          0,
          plantilla.codigoLeccion.length / 2
        );

        const [extensionPlantilla] = plantilla.urlPlantilla
          .split(".")
          .reverse();

        aux.push({
          ...plantilla,
          nombreCurso: cursos.find((curso) => curso.codigoCurso === codigoCurso)
            .nombre,
          codigoCurso,
          icon: getIconPlantillaTrabajo(extensionPlantilla),
          extensionPlantilla,
        });
      });

      setCursosPlantillas(
        aux.filter((obj, index, self) => {
          return (
            index === self.findIndex((t) => t.codigoCurso === obj.codigoCurso)
          );
        })
      );
    }
  }, [plantillasTrabajo, cursos]);

  return (
    <>
      {showScrollButton && (
        <Animated
          animationIn="fadeIn"
          isVisible={true}
          style={{ zIndex: "9999", position: "absolute" }}
        >
          <div className="scroll-top">
            <span
              className={`rounded-circle scrollTop text-center d-flex`}
              style={{
                height: "50px",
                width: "50px",
                fontSize: "2rem",
                backgroundColor: "#F59E0B",
                position: "fixed",
                bottom: "70px",
                right: "40px",
              }}
            >
              <Link
                className="pointer"
                activeClass="active"
                to="scrollTopElement"
                spy={true}
                smooth={true}
                duration={800}
                containerId="scrollTop"
                style={{ color: "black", width: "100%" }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Link>
            </span>
          </div>
        </Animated>
      )}

      <Animated
        animationIn="fadeIn"
        animationInDuration={250}
        animationOut="fadeOut"
        isVisible={true}
      >
        <Container fluid className="p-0 mb-5 mt-5">
          <Row>
            <Col>
              <DynamicBreadcrumb
                arrayBreadcrumbs={breadcrumbItemsInitialState}
              />
            </Col>
          </Row>

          <Row>
            <Col className="ps-3">
              {cursosPlantillas.map((plantilla, idx) => (
                <div className="mb-4" key={idx}>
                  <TitleDashboard
                    title={
                      <>
                        {plantilla.icon} {plantilla.nombreCurso}
                      </>
                    }
                  />

                  <Container fluid className="mt-2">
                    <Row>
                      {plantillasTrabajo
                        .filter((item) =>
                          item.codigoLeccion.includes(plantilla.codigoCurso)
                        )
                        .map((plantillaTrabajo, idx) => (
                          <Col xs={3} className="my-3 mx-auto" key={idx}>
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip>Da clic para descargar</Tooltip>
                              }
                            >
                              <Card
                                body
                                className="mx-auto card-download-plantilla d-flex align-items-center justify-content-center"
                                onClick={() =>
                                  handleDescargaPlantilla({
                                    urlPlantilla: plantillaTrabajo.urlPlantilla,
                                    nombrePlantilla: plantillaTrabajo.nombre,
                                    extensionPlantilla:
                                      plantilla.extensionPlantilla,
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faFileDownload}
                                  size="5x"
                                  color="grey"
                                />
                                <br />
                                <span className="mt-2">
                                  {plantillaTrabajo.nombre}
                                </span>
                              </Card>
                              {/* 
															<Button
																// style={{wid}}
																block
																variant="secondary"
																className="h-100 ">
																
															</Button> */}
                            </OverlayTrigger>
                          </Col>
                        ))}
                    </Row>
                  </Container>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </Animated>
    </>
  );
};

export default PlantillasTrabajo;
