import regExpStrings from "@helpers/regExpStrings/regExpStrings";

const validarRestriccionesContrasenia = ({ contrasenia, isNuevaContrasenia = false }) => {
	return new Promise((resolve, reject) => {
		const CARACTERES_MINIMOS = 8;

		let validacionesContrasenia = {
			caracteresContrasenia: false,
			mayusculaContrasenia: false,
			numeroContrasenia: false,
			contrasenia: false,
			contraseniaNueva: false,
		};

		try {
			if (contrasenia.length >= CARACTERES_MINIMOS) {
				validacionesContrasenia.caracteresContrasenia = true;
			}

			if (regExpStrings.regexCaracterMayusculaContrasenia(contrasenia)) {
				validacionesContrasenia.mayusculaContrasenia = true;
			}

			if (regExpStrings.regexCaracterDigitoContrasenia(contrasenia)) {
				validacionesContrasenia.numeroContrasenia = true;
			}

			if (regExpStrings.regexPassword(contrasenia)) {
				if (isNuevaContrasenia) {
					validacionesContrasenia.contraseniaNueva = true;
				} else {
					validacionesContrasenia.contrasenia = true;
				}
			}

			resolve(validacionesContrasenia);
		} catch (error) {
			console.log("error validando restricciones contrasenia: ", error);

			reject(error);
		}
	});
};

export default validarRestriccionesContrasenia;
