import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import codigoPostalChecker from "@helpers/codigoPostal/codigoPostalChecker";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, FloatingLabel, Form, Row, useAccordionButton } from "react-bootstrap";

import UseAnimations from "react-useanimations";
import searchToX from "react-useanimations/lib/searchToX";

const AccordionDatosDomicilio = ({ isCreditoDirecto, formValues, setFormValues }) => {

	const nextEventKey = isCreditoDirecto ? "3" : "4";

	const decoratedOnClick = useAccordionButton(nextEventKey);

	const { codigoPostal, calle, ciudad, estado, numeroExterior, numeroInterior, entreCalles, tiempoResidencia } = formValues;

	const [colonias, setColonias] = useState([]);

	const [searchingPostalCode, setSearchingPostalCode] = useState(false);

	const consultarDatosCodigoPostal = async () => {
		try {

			setSearchingPostalCode(true);

			const { colonias, ciudad: ciudadEncontrada, estado: estadoEncontrado } = await codigoPostalChecker(codigoPostal);

			setFormValues({
				...formValues,
				ciudad: ciudadEncontrada,
				estado: estadoEncontrado,
			});

			setColonias(colonias);

			setTimeout(() => setSearchingPostalCode(false), 1000);
		} catch (error) {
			console.log("Error al obtener los datos del código postal: ", error);
		}
	};

	const handleOnChange = ({ target }) => {
		let auxValues = {};

		switch (target.name) {
			case "codigoPostal":
			case "numeroExterior":
			case "numeroInterior":
				auxValues = {
					[target.name]: target.value.replace(/\D/g, ""),
				};
				break;
				
				case "calle":
				case "entreCalles":
				case "tiempoResidencia":
					auxValues = {
						[target.name]: capitalizeString(target.value),
					};
				break;

			default:
				auxValues = {
					[target.name]: target.value,
				};
				break;
		}

		setFormValues({
			...formValues,
			...auxValues,
		});
	};

	useEffect(() => {
		if (codigoPostal.length === 5) {
			consultarDatosCodigoPostal();
		}
	}, [codigoPostal]);

	return (
		<Accordion.Item eventKey="2">
			<Accordion.Header>
				<Typography variant="button">Datos de Domicilio</Typography>
			</Accordion.Header>

			<Accordion.Body>
				<Container fluid>
					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label="Código Postal" className="mb-3">
								<Form.Control type="text" placeholder="Código Postal" name="codigoPostal" value={codigoPostal} onChange={handleOnChange} />

								{searchingPostalCode ? (
									<Form.Text className="text-muted">
										<div className="d-flex justify-content-end">
											<UseAnimations animation={searchToX} size={56} strokeColor="#ffcc00" loop autoplay speed={0.5} />
										</div>
									</Form.Text>
								) : null}
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label="Calle" className="mb-3">
								<Form.Control type="text" placeholder="Calle" name="calle" value={calle} onChange={handleOnChange} />
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6} className="mb-3">
							<FloatingLabel label="Colonia">
								<Form.Select aria-label="Elige tu colonia" name="colonia" onChange={handleOnChange}>
									<option value="">{colonias.length > 0 ? "Elige una colonia" : "Ingresa tu código postal"}</option>

									{colonias.map((colonia, idx) => (
										<option value={colonia} key={idx}>
											{colonia}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<Row>
								<Col xs={12} md={6}>
									<FloatingLabel label="Número exterior" className="mb-3">
										<Form.Control type="text" placeholder="Número exterior" name="numeroExterior" value={numeroExterior} onChange={handleOnChange} />
									</FloatingLabel>
								</Col>

								<Col xs={12} md={6}>
									<FloatingLabel label="Número interior" className="mb-3">
										<Form.Control type="text" placeholder="Número interior" name="numeroInterior" value={numeroInterior} onChange={handleOnChange} />

										<Form.Text className="text-muted">Opcional</Form.Text>
									</FloatingLabel>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6}>
							<Row>
								<Col xs={12} md={6}>
									<FloatingLabel label="Ciudad" className="mb-3">
										<Form.Control disabled type="text" placeholder="Ciudad" name="ciudad" value={ciudad === "" ? "Ingresa tu código postal" : ciudad} onChange={handleOnChange} />
									</FloatingLabel>
								</Col>

								<Col xs={12} md={6}>
									<FloatingLabel label="Estado" className="mb-3">
										<Form.Control disabled type="text" placeholder="Estado" name="estado" value={estado === "" ? "Ingresa tu código postal" : estado} onChange={handleOnChange} />
									</FloatingLabel>
								</Col>
							</Row>
						</Col>

						{isCreditoDirecto ? (
							<Col xs={12} md={6}>
								<FloatingLabel label="Entre calles" className="mb-3">
									<Form.Control type="text" placeholder="Entre calles" name="entreCalles" value={entreCalles} onChange={handleOnChange} />
								</FloatingLabel>
							</Col>
						) : (
							<Col xs={12} md={6}>
								<div className="d-grid gap-2 mb-3">
									<Button className="btn-next-accordion" variant="success" type="button" size="lg" onClick={decoratedOnClick}>
										Siguiente <FontAwesomeIcon icon={faArrowRight} />
									</Button>
								</div>
							</Col>
						)}
					</Row>

					<Row>
						{isCreditoDirecto ? (
							<Col xs={12} md={6}>
								<FloatingLabel label="Tiempo de residencia" className="mb-3">
									<Form.Control type="text" placeholder="Tiempo de residencia" name="tiempoResidencia" value={tiempoResidencia} onChange={handleOnChange} />
								</FloatingLabel>
							</Col>
						) : null}

						{isCreditoDirecto ? (
							<Col xs={12} md={6}>
								<div className="d-grid gap-2 mb-3">
									<Button className="btn-next-accordion" variant="success" type="button" size="lg" onClick={decoratedOnClick}>
										Siguiente <FontAwesomeIcon icon={faArrowRight} />
									</Button>
								</div>
							</Col>
						) : null}
					</Row>
				</Container>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default AccordionDatosDomicilio;
