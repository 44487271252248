import React from 'react'
import { Card, Col } from 'react-bootstrap';

const GridPaquetesItem = ({ title, imageSrc, imageAlt }) => {

    return (
        <Col className="pb-4 pb-lg" sm={12} lg>
            <Card>
                <Card.Body className='p-0'>
                    <img 
                        className="paquete-card-img w-100 h-100 rounded-2" 
                        src={ require(`@assets/images/compra/${imageSrc}`) } 
                        alt={ imageAlt } 
                    />
                </Card.Body>
            </Card>
        </Col>
    );
};

export default GridPaquetesItem;