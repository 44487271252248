import "@assets/css/components/DashboardCursos/style.css";

import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import React, { useState } from "react";

import DashboardCursos from "../DashboardCursos/DashboardCursos";
import { DashboardLecciones } from "../DashboardLecciones/DashboardLecciones";
import DashboardSecciones from "../DashboardSecciones/DashboardSecciones";
import TableGestionContenido from "./DataTables/TableGestionContenido";
import DashboardPapeleraReciclajeContenidoCurso from "../DashboardPapeleraReciclajeContenidoCurso/DashboardPapeleraReciclajeContenidoCurso";

export const DashboardGestionContenidoCursos = () => {
  const [tabState, setTabState] = useState("cursos");

  const handleChangeTab = (tab) => {
    try {
      setTabState(tab);
    } catch (e) {
      console.log(e);
    }
  };

  // const handleRefetch = () => {
  // 	try {
  // 		refetch();
  // 	} catch (e) {
  // 		console.log(e);
  // 	}
  // };

  return (
    <Container fluid className="">
      <Row className="mb-5">
        <Col>
          <Tabs
            id="tabsUsers"
            className="mb-3"
            onSelect={handleChangeTab}
            activeKey={tabState.select}
          >
            <Tab eventKey="cursos" title="Cursos">
              <DashboardCursos />
            </Tab>
            <Tab eventKey="secciones" title="Secciones">
              <DashboardSecciones />
            </Tab>
            <Tab eventKey="lecciones" title="Lecciones">
              <DashboardLecciones />
            </Tab>
            <Tab eventKey="gestionContenido" title="Gestion de contenido">
              <TableGestionContenido />
            </Tab>
            <Tab eventKey="papelera" title="Papelera de reciclaje">
              <DashboardPapeleraReciclajeContenidoCurso />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
