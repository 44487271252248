import React from "react";

const EtiquetaHeader = () => {
	return (
		<div className="compra-label text-uppercase">
			<span>
				<svg width="22" height="26">
					<path
						d="M21.284 5.3s3.65 16.194-10.176 20.243C-2.718 21.494.93 5.3.93 5.3L11.108.644 21.284 5.3zM10.605 18.67l6.42-6.378-1.764-1.751-4.656 4.626-3.124-3.104-1.763 1.751 4.887 4.856z"
						fill="#fff"
						fillRule="evenodd"
						className="secure-purchase-badge__shield-path"></path>
				</svg>

				<span className='text-white'>&nbsp;Compra 100% segura</span>
			</span>
		</div>
	);
};

export default EtiquetaHeader;
