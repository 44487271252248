import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TablePlantillasTrabajo from "./DataTables/TablePlantillasTrabajo";
import { useState } from "react";
import AgregarPlantilla from "./Modales/AgregarPlantilla/AgregarPlantilla";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";

const DashboardPlantillasTrabajo = () => {
  const [plantillaAgregada, setPlantillaAgregada] = useState(false);

  const handleAgregarPlantilla = async () => {
    await ModalSweetAlert({
      title: "Editar plantilla",
      html: <AgregarPlantilla setPlantillaAgregada={setPlantillaAgregada} />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col align="right">
          <Button variant="primary" onClick={handleAgregarPlantilla}>
            Agregar plantilla
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TablePlantillasTrabajo
            plantillaAgregada={plantillaAgregada}
            setPlantillaAgregada={setPlantillaAgregada}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardPlantillasTrabajo;
