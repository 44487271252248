import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const VerInfoInvitados = ({ infoUsuario }) => {
  const { nombreCompleto, avatarPerfil, apellidos, correo, curp, profesion } =
    infoUsuario;

  return (
    <Container>
      <Row>
        <Col>
          <Image
            width="90"
            height="90"
            className="rounded-circle"
            src={
              avatarPerfil !== null && avatarPerfil !== ""
                ? avatarPerfil
                : require("@assets/images/avatar.png")
            }
            alt="img-perfil"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-start">
          <p>
            <b>Nombre completo:</b> {`${nombreCompleto} ${apellidos}`}
          </p>
          <p>
            <b>Correo electrónico:</b> {`${correo}`}
          </p>
          <p>
            <b>Curp:</b> {`${curp}`}
          </p>
          <p>
            <b>Profesión:</b>{" "}
            {`${profesion === null ? "Sin especificar" : profesion}`}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default VerInfoInvitados;
