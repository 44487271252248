import { Col, Container, Row } from "react-bootstrap";
import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import { faEdit, faEye, faPowerOff, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getLicencias, reactivateLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";

import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { EditarLicencia } from "../Tabs/EditarLicencia/EditarLicencia";
import { EliminarLicencia } from "../Tabs/EliminarLicencia/EliminarLicencia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { VerLicencia } from "../Tabs/VerLicencia/VerLicencia";
import columnas from "./columnas";
import { useEffect } from "react";
import { useState } from "react";

const TableLicencias = ({ licenciaAgregada, setLicenciaAgregada }) => {
	const [licencias, setLicencias] = useState([]);

	const [dataTableColumns, setDataTableColumns] = useState([]);

	const [licenciaEditada, setLicenciaEditada] = useState(false);

	const [licenciaEliminada, setLicenciaEliminada] = useState(false);

	const [licenciaReactivada, setLicenciaReactivada] = useState(false);

	useEffect(() => {
		const consultarLicencias = async () => {
			try {
				const { data: licencias } = await getLicencias();

				setLicencias(licencias);
			} catch (error) {
				console.log("Error al consultar las licencias: ", error);

				Notificacion("error", error.message);
			}
		};

		consultarLicencias();
	}, []);

	useEffect(() => {
		if (licencias.length > 0) {
			const handleVerLicencia = ({ currentTarget }) => {
				const rowIndex = parseInt(currentTarget.id.split("-")[1]);

				const licencia = licencias.filter((licencia) => licencia.idLicencia === rowIndex)[0];

				ModalSweetAlert({
					title: `Licencia ${licencia.nombre}`,
					html: <VerLicencia licencia={licencia} />,
					width: 700,
					showConfirmButton: false,
					showCloseButton: true,
				});
			};

			const handleEditLicencia = ({ currentTarget }) => {
				const rowIndex = parseInt(currentTarget.id.split("-")[1]);

				const licencia = licencias.filter((licencia) => licencia.idLicencia === rowIndex)[0];

				ModalSweetAlert({
					title: "Actualizar licencia",
					icon: "info",
					width: 800,
					html: <EditarLicencia licencia={licencia} setLicenciaEditada={setLicenciaEditada} />,
					showConfirmButton: false,
					showCloseButton: true,
				});
			};

			const handleDeleteLicencia = ({ currentTarget }) => {
				const rowIndex = parseInt(currentTarget.id.split("-")[1]);

				const licencia = licencias.filter((licencia) => licencia.idLicencia === rowIndex)[0];

				ModalSweetAlert({
					title: "Eliminar licencia",
					icon: "warning",
					html: <EliminarLicencia licencia={licencia} setLicenciaEliminada={setLicenciaEliminada} />,
					showConfirmButton: false,
					showCloseButton: true,
				});
			};

			const handleReactivateLicencia = async ({ currentTarget }) => {
				try {
					const rowIndex = parseInt(currentTarget.id.split("-")[1]);

					const licencia = licencias.filter((licencia) => licencia.idLicencia === rowIndex)[0];

					await reactivateLicencia(licencia.idLicencia);

					Notificacion("success", "La licencia se reactivó correctamente");

					setLicenciaReactivada(true);
				} catch (error) {
					console.log("Error al reactivar la lección: ", error);

					Notificacion("error", "Algo ocurrió al reactivar la licencia, por favor vuelve a intentar");
				}
			};

			setDataTableColumns([
				...columnas,
				{
					name: "Acciones",
					cell: (row) => (
						<>
							<span id={`ver-${row.idLicencia}`} className="me-1 pointer" onClick={handleVerLicencia}>
								<FontAwesomeIcon icon={faEye} className="text-primary" />
							</span>

							<span id={`edit-${row.idLicencia}`} className="me-1 pointer" onClick={handleEditLicencia}>
								<FontAwesomeIcon icon={faEdit} className="text-info" />
							</span>

							{row.estatus ? (
								<span id={`delete-${row.idLicencia}`} className="ms-1 pointer" onClick={handleDeleteLicencia}>
									<FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
								</span>
							) : (
								<span id={`reactivate-${row.idLicencia}`} className="ms-1 pointer" onClick={handleReactivateLicencia}>
									<FontAwesomeIcon icon={faPowerOff} className="text-success" />
								</span>
							)}
						</>
					),
					ignoreRowClick: true,
					allowOverflow: true,
					button: true,
				},
			]);
		}
	}, [licencias]);

	useEffect(() => {
		if (licenciaAgregada || licenciaEditada || licenciaEliminada || licenciaReactivada) {
			const consultarLicencias = async () => {
				try {
					const { data: licencias } = await getLicencias();

					setLicencias(licencias);

					setLicenciaAgregada(false);

					setLicenciaEditada(false);

					setLicenciaEliminada(false);

					setLicenciaReactivada(false);
				} catch (error) {
					console.log("Error al consultar las lecciones:", error);

					Notificacion("error", "Ocurrió algo al consultar las lecciones, vuelve a intentar");
				}
			};

			consultarLicencias();
		}
	}, [licenciaAgregada, licenciaEditada, licenciaEliminada, licenciaReactivada]);

	return (
		<Container fluid>
			<Row>
				<Col>
					<DataTablesRecipient columns={dataTableColumns} data={licencias} />
				</Col>
			</Row>
		</Container>
	);
};

export default TableLicencias;
