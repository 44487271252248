import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "../Titles/TitleDashboard";
import {
	Alert,
	Avatar,
	Box,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarXmark } from "@fortawesome/free-regular-svg-icons";
import { faBookOpen, faBoxOpen, faBusinessTime, faCalendarDay, faCalendarPlus, faComments, faEdit, faPhone, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

import "@assets/css/components/AgendaAsesorias/style.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { NotificationManager } from "react-notifications";
import {
	addAgendaAsesoria,
	getAgendasAsesorias,
	getDisponibilidadHorario,
	getHistorialAsesorias,
	getUltimaAgenda,
	updateAgendaAsesoria,
	updateAgendaAsesoriaFeedback,
} from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";
import UserContext from "@context/User/UserContext";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { addDays, closestTo, eachDayOfInterval, fromUnixTime, isBefore, isSameDay, isSaturday, isSunday, isWeekend, isWithinInterval, parse, subMonths } from "date-fns";
import getUnixFromDate from "@helpers/dates/getUnixFromDate/getUnixFromDate";
import ReactStars from "react-rating-stars-component";
import { ModalSweetAlert } from "../Alertas/Alertas";
import CancelarAgenda from "./CancelarAgenda";
import { formatPhoneNumber } from "@helpers/strings/formatPhoneNumber/formatPhoneNumber";

const AgendasAsesorias = () => {
	addLocale("es", {
		firstDayOfWeek: 1,
		dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
		monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
		monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
		today: "Hoy",
		clear: "Limpiar",
		//...
	});

	const breadcrumbItemsInitialState = [{ title: "Control de Agendas de Asesorías", onClick: () => {} }];

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idPerfil, folioContrato, correo, nombreCompleto, apellidos, numeroTelefono } = usuarioLogeado;

	const [proximaAsesoria, setProximaAsesoria] = useState(null);

	const [ultimaAsesoria, setUltimaAsesoria] = useState(null);

	const [historialAgendas, setHistorialAgendas] = useState([]);

	const [agendasAsesorias, setAgendasAsesorias] = useState([]);

	const [ultimaAgenda, setUltimaAgenda] = useState({});

	const [invalidDates, setInvalidDates] = useState([]);

	const [horariosAtencion, setHorariosAtencion] = useState({
		entreSemana: ["09:00 a.m. a 11:00 a.m.", "11:00 a.m. a 01:00 p.m.", "01:00 p.m. a 02:45 p.m.", "03:45 p.m. a 05:45 p.m."],
		// finSemana: ["08:30 a.m. a 10:30 a.m.", "10:30 a.m. a 12:30 p.m.", "12:30 p.m. a 02:30 p.m."],
		finSemana: [],
	});

	const [formValues, setFormValues] = useState({
		fechaAgenda: 0,
		horarioAgenda: "",
		temaElegido: "Elige un tema",
		problematica: "",
		feedback: "",
		calificacion: 0,
		idPerfil,
		folioContrato,
		correo,
		nombreCompleto,
		apellidos,
		numeroTelefono,
		idAgendaAsesoria: 0,
	});

	const [refetch, setRefetch] = useState(false);

	const [showDialogRegistro, setShowDialogRegistro] = useState(false);

	const [showDialogFeedback, setShowDialogFeedback] = useState(false);

	const [showLoader, setShowLoader] = useState(false);

	const [isFechaEntreSemana, setIsFechaEntreSemana] = useState(false);

	const [disableNumeroTelefono, setDisableNumeroTelefono] = useState(false);

	const [fechaCalendario, setFechaCalendario] = useState(null);

	const formButtonRefRegistro = useRef(null);

	const formButtonRefFeedback = useRef(null);

	const horariosEntreSemanaRef = useRef(null);

	const horariosSabadoRef = useRef(null);

	const handleOpenDialogRegistro = () => {
		setShowDialogRegistro(true);
	};

	const handleCloseDialogRegistro = () => {
		setShowDialogRegistro(false);

		setFormValues({
			...formValues,
			idAgendaAsesoria: 0,
			temaElegido: "Elige un tema",
			problematica: "",
			fechaAgenda: 0,
			horarioAgenda: "",
		});

		setFechaCalendario(null);
	};

	const handleOpenDialogFeedback = () => {
		setShowDialogFeedback(true);
	};

	const handleCloseDialogFeedback = (event, reason) => {
		if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
			setShowDialogFeedback(false);

			setFormValues({
				...formValues,
				feedback: "",
				calificacion: 0,
			});
		}
	};

	const columnas = useMemo(() => [
		{
			id: "idAgendaAsesoria",
			header: "# ID",
			accessorFn: (row) => row.idAgendaAsesoria,
		},
		{
			id: "fechaSolicitud",
			header: "Fecha de Solicitud",
			accessorFn: (row) => getDateFromUnix(row.fechaSolicitud),
		},
		{
			id: "fechaAgenda",
			header: "Fecha de la Asesoría",
			accessorFn: (row) => getDateFromUnix(row.fechaAgenda),
		},
		{
			id: "horarioAgenda",
			header: "Horario elegido",
			accessorFn: (row) => row.horarioAgenda,
		},
		{
			id: "temaElegido",
			header: "Tema de la Asesoría",
			accessorFn: (row) => row.temaElegido,
		},
		{
			id: "estatus",
			header: "Estatus",
			accessorFn: (row) => row.estatus,
		},
	]);

	const table = useMaterialReactTable({
		localization: MRT_Localization_ES,
		columns: columnas,
		data: historialAgendas,
		renderDetailPanel: ({ row }) => {
			return (
				<Container fluid>
					<Row>
						<Col>
							<Typography gutterBottom variant="subtitle1">
								Problemática presentada
							</Typography>
							<hr />
							<br />

							<Typography gutterBottom variant="body1">
								{row.original.problematica}
							</Typography>
						</Col>
					</Row>
				</Container>
			);
		},
		enableRowActions: true,
		positionActionsColumn: "last",
		renderRowActions: ({ row, table }) => (
			<Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
				<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Cancelar Agenda</Tooltip>}>
					<IconButton color="error" onClick={() => handleCancelarAgenda(row.original.idAgendaAsesoria)} id={row.original.idAgendaAsesoria}>
						<FontAwesomeIcon icon={faTrash} />
					</IconButton>
				</OverlayTrigger>
				<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Modificar Agenda</Tooltip>}>
					<IconButton color="primary" onClick={() => handleModificarAgenda(row.original.idAgendaAsesoria)} id={row.original.idAgendaAsesoria}>
						<FontAwesomeIcon icon={faEdit} />
					</IconButton>
				</OverlayTrigger>
			</Box>
		),
	});

	const consultarHistorialAgendas = async () => {
		try {
			const historialAgendas = await getHistorialAsesorias(idPerfil);

			setHistorialAgendas(historialAgendas);

			if (refetch) {
				setRefetch(false);
			}

			// Obtener la fecha actual en Unix Timestamp de 10 dígitos
			const now = Math.floor(Date.now() / 1000);

			const auxAgendas = historialAgendas.filter((agenda) => agenda.estatus !== "Resuelta");

			// Convertir los horarios de las agendas en rangos de tiempo en formato de objeto Date
			const horariosDisponibles = auxAgendas.map((agenda) => {
				const fechaAgenda = fromUnixTime(agenda.fechaAgenda);

				const [horaInicio, horaFin] = agenda.horarioAgenda.split(" a ").map((hora) => parse(hora, "hh:mm a", new Date()));

				return { fecha: fechaAgenda, horaInicio, horaFin };
			});

			// Encontrar la fecha más cercana a la fecha actual
			const fechaMasCercana = closestTo(
				fromUnixTime(now),
				horariosDisponibles.map((agenda) => agenda.fecha)
			);

			// Encontrar el horario disponible más cercano en la fecha más cercana
			const horarioMasCercano = horariosDisponibles.find((agenda) => isSameDay(agenda.fecha, fechaMasCercana));

			// Encontrar la agenda más cercana que coincide con la fecha más cercana y el horario más cercano
			const agendaMasCercana = auxAgendas.find((agenda) => {
				const fechaAgenda = fromUnixTime(agenda.fechaAgenda);

				const horaInicioAgenda = parse(agenda.horarioAgenda.split(" a ")[0], "hh:mm a", new Date());

				const horaFinAgenda = parse(agenda.horarioAgenda.split(" a ")[1], "hh:mm a", new Date());

				return isSameDay(fechaAgenda, fechaMasCercana) && isWithinInterval(horarioMasCercano.horaInicio, { start: horaInicioAgenda, end: horaFinAgenda });
			});

			if (agendaMasCercana.estatus !== "Resuelta") {
				setProximaAsesoria(agendaMasCercana);
			}
		} catch (error) {
			console.log("error al consultar el historial: ", error);

			setHistorialAgendas([])

			// NotificationManager.error("Algo sucedió al consultar el historial tus agendas");
		}
	};

	const consultarUltimaAgenda = async () => {
		try {
			const ultimaAgenda = await getUltimaAgenda(idPerfil);

			setUltimaAgenda(ultimaAgenda);

			setUltimaAsesoria(ultimaAgenda);
		} catch (error) {
			console.log("Error al consultar la ultima agenda: ", error);

			// NotificationManager.error("No se pudo consultar la última agenda resuelta");
		}
	};

	const consultarAgendas = async () => {
		try {
			const agendasAsesorias = await getAgendasAsesorias();

			setAgendasAsesorias(agendasAsesorias);

			if (refetch) {
				setRefetch(false);
			}
		} catch (error) {
			console.log("error al obtener las agendas de asesorías: ", error);

			// NotificationManager.error("No se pudieron consultar las fechas no disponibles, recarga la página.");
		}
	};

	const handleAgendarAsesoria = async (e) => {
		e.preventDefault();

		try {
			if (formValues.temaElegido === "Elige un tema") {
				return NotificationManager.error("Elige un tema antes de agendar");
			}

			if (formValues.problematica === "") {
				return NotificationManager.error("Escribe tu problemática antes de agendar");
			}

			if (formValues.fechaAgenda === 0) {
				return NotificationManager.error("Elige una fecha para tu agenda");
			}

			if (formValues.horarioAgenda === "") {
				return NotificationManager.error("Elige un horario para tu agenda");
			}

			if (formValues.numeroTelefono === "" || formValues.numeroTelefono.replace(/[\s()-]/g, "").length < 10) {
				return NotificationManager.error("Ingresa un número de teléfono de 10 dígitos");
			}

			setShowLoader(true);

			await addAgendaAsesoria({
				...formValues,
				numeroTelefono: formValues.numeroTelefono.replace(/[\s()-]/g, ""),
			});

			setShowLoader(false);

			NotificationManager.success("Asesoría agendada con éxito");

			handleCloseDialogRegistro();

			setFormValues({
				...formValues,
				fechaAgenda: 0,
				horarioAgenda: "",
				temaElegido: "Elige un tema",
				problematica: "",
			});

			setRefetch(true);

			setFechaCalendario(null);
		} catch (error) {
			console.log("Algo sucedió al agendar la asesoría: ", error);

			NotificationManager.error("No se pudo agendar tu asesoría, vuelve a intentar");
		}
	};

	const handleSeleccionTemaAsesoria = async ({ target }) => {
		const { value } = target;

		if (value === "") return;

		setFormValues({
			...formValues,
			temaElegido: value,
		});
	};

	const handleSeleccionHorario = async ({ target }) => {
		if (formValues.fechaAgenda === 0) {
			return NotificationManager.info("Elige una fecha antes de continuar");
		}

		const { value } = target;

		if (value === "") return;

		try {
			await getDisponibilidadHorario({
				fechaElegida: formValues.fechaAgenda,
				horarioElegido: value,
			});

			setFormValues({
				...formValues,
				horarioAgenda: value,
			});
		} catch (error) {
			console.log("catch: ", error);

			NotificationManager.error("El horario elegido no está disponible en esta fecha");

			if (isFechaEntreSemana) {
				horariosEntreSemanaRef.current.value = "";
			} else {
				horariosSabadoRef.current.value = "";
			}
		}
	};

	const handleOnChange = ({ target }) => {
		const { name, value } = target;

		setFormValues({
			...formValues,
			[name]:
				name === "feedback"
					? value.replace(
							/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F600}-\u{1F64F}\u{1F910}-\u{1F91F}\u{1F920}-\u{1F927}\u{1F930}-\u{1F930}\u{1F933}-\u{1F93E}\u{1F9D0}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}]/gu,
							""
					  )
					: value,
		});
	};

	const handleOnChangeCalendario = (e) => {
		setIsFechaEntreSemana(!isWeekend(e.value));

		setFechaCalendario(e.value);

		setFormValues({
			...formValues,
			fechaAgenda: getUnixFromDate(e.value),
		});
	};

	const isDisabledDate = (date, invalidDates) => {
		// Si la fecha es anterior a la fecha actual, también la deshabilita
		if (isBefore(date, new Date()) || isSunday(date)) {
			return true;
		}

		// Obtener los horarios disponibles para el día de la semana correspondiente
		const availableSlots = isSaturday(date) ? horariosAtencion.finSemana : horariosAtencion.entreSemana;

		// Obtener la cantidad de horarios cubiertos para esta fecha
		const coveredSlots = invalidDates.filter((invalidDate) => isSameDay(date, invalidDate)).length;

		// Verificar si todos los horarios están cubiertos
		if (coveredSlots === availableSlots.length) {
			return true;
		}

		// Si no se cumple ninguna de las condiciones anteriores, la fecha no está deshabilitada
		return false;
	};

	const ratingChanged = (newRating) => {
		setFormValues({
			...formValues,
			calificacion: newRating,
		});
	};

	const handleFeedback = async (e) => {
		e.preventDefault();

		try {
			setShowLoader(true);

			await updateAgendaAsesoriaFeedback({
				idAgendaAsesoria: ultimaAsesoria.idAgendaAsesoria,
				feedback: formValues.feedback,
				calificacion: formValues.calificacion,
			});

			NotificationManager.success("Retroalimentación enviada satisfactoriamente");

			setShowLoader(false);

			setRefetch(true);

			handleCloseDialogFeedback();
		} catch (error) {
			console.log("error al actualizar el feedback: ", error);
		}
	};

	const handleCancelarAgenda = async (idAgendaAsesoria) => {
		try {
			await ModalSweetAlert({
				title: "Cancelar Agenda",
				html: <CancelarAgenda idAgendaAsesoria={idAgendaAsesoria} setRefetch={setRefetch} />,
				showConfirmButton: false,
				showCloseButton: true,
			});
		} catch (error) {
			console.log("algo sucedió al cancelar la agenda", error);
		}
	};

	const handleModificarAgenda = async (idAgendaAsesoria) => {
		try {
			const { temaElegido, problematica, fechaAgenda, horarioAgenda } = historialAgendas.find((agenda) => agenda.idAgendaAsesoria === idAgendaAsesoria);

			setFormValues({
				...formValues,
				idAgendaAsesoria,
				temaElegido,
				problematica,
				fechaAgenda,
				horarioAgenda,
			});

			setFechaCalendario(new Date(fechaAgenda * 1000));

			handleOpenDialogRegistro();
		} catch (error) {
			console.log("error al modificar la agenda: ", error);
		}
	};

	const handleModificarAsesoria = async (e) => {
		e.preventDefault();

		try {
			setShowLoader(true);

			await updateAgendaAsesoria(formValues);

			setFormValues({
				...formValues,
				idAgendaAsesoria: 0,
				temaElegido: "Elige un tema",
				problematica: "",
				fechaAgenda: 0,
				horarioAgenda: "",
			});

			setFechaCalendario(null);

			setShowLoader(false);

			NotificationManager.success("La asesoría fue modificada con éxito");

			handleCloseDialogRegistro();

			setRefetch(true);
		} catch (error) {
			console.log("error al modificar la información de la asesoría: ", error);
		}
	};

	useEffect(() => {
		if (historialAgendas.length === 0) {
			consultarHistorialAgendas();
		}

		if (Object.keys(ultimaAgenda).length === 0) {
			consultarUltimaAgenda();
		}

		if (agendasAsesorias.length === 0) {
			consultarAgendas();
		}

		if (numeroTelefono !== "") {
			setDisableNumeroTelefono(true);
		}
	}, []);

	useEffect(() => {
		if (refetch) {
			consultarHistorialAgendas();

			consultarAgendas();

			consultarUltimaAgenda();
		}
	}, [refetch]);

	useEffect(() => {
		const nextYear = eachDayOfInterval({
			// start: new Date(),
			start: subMonths(new Date(), 1),
			end: addDays(new Date(), 364),
		});

		let invalidDatesFromAPI = [];

		if (agendasAsesorias.length > 0) {
			invalidDatesFromAPI = [...agendasAsesorias.map((agenda) => new Date(agenda.fechaAgenda * 1000))];
		}

		const invalidDates = nextYear.filter((date) => isDisabledDate(date, invalidDatesFromAPI));

		setInvalidDates(invalidDates);
	}, [agendasAsesorias]);

	useEffect(() => {
		if (ultimaAsesoria !== null) {
			if (ultimaAsesoria.feedback === "" || ultimaAsesoria.feedback === null) {
				handleOpenDialogFeedback();
			}
		}
	}, [ultimaAsesoria]);

	return (
		<Container fluid className="pb-2 mb-5">
			<Row>
				<Col>
					<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
				</Col>
			</Row>

			<Row className="fondo-mos agenda-asesoria">
				<Col className="fondo-propedeutico">
					<Row>
						<Col className="p-4">
							<TitleDashboard title="Agenda de Asesorías Personalizadas" />
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="my-3">
				<Col>
					<Typography variant="h5" gutterBottom>
						Control de Agendas de Asesorías
					</Typography>
				</Col>

				<Col align="right">
					<Button size="lg" variant="success" className="mt-1" onClick={handleOpenDialogRegistro}>
						Agendar asesoría <FontAwesomeIcon icon={faCalendarPlus} />
					</Button>
				</Col>
			</Row>

			<Row className="mb-3">
				<Container fluid className="p-0 mt-3">
					<Row>
						<Col>
							<div className="d-flex justify-content-around flex-column flex-md-row">
								<Card body className="control-asesorias-card shadow mb-3">
									<Typography variant="h5" gutterBottom className="text-center">
										Tu próxima asesoría
									</Typography>

									<div className="d-flex justify-content-center mt-3">
										<div className="d-flex align-items-center progreso-certificaciones-mos">
											<div className="d-flex flex-column ms-3">
												{proximaAsesoria === null ? (
													<div className="text-center">
														<FontAwesomeIcon icon={faCalendarXmark} size="3x" color="#dc3545" className="mt-3" />

														<Typography variant="h6" gutterBottom>
															No hay ninguna asesoría próxima
														</Typography>
													</div>
												) : (
													<List>
														<ListItem>
															<ListItemAvatar>
																<Avatar>
																	<FontAwesomeIcon icon={faBookOpen} />
																</Avatar>
															</ListItemAvatar>
															<ListItemText primary="Tema elegido" secondary={proximaAsesoria.temaElegido} />
														</ListItem>

														<ListItem>
															<ListItemAvatar>
																<Avatar>
																	<FontAwesomeIcon icon={faCalendarDay} />
																</Avatar>
															</ListItemAvatar>
															<ListItemText primary="Fecha agendada" secondary={getDateFromUnix(proximaAsesoria.fechaAgenda)} />
														</ListItem>
														<ListItem>
															<ListItemAvatar>
																<Avatar>
																	<FontAwesomeIcon icon={faBusinessTime} />
																</Avatar>
															</ListItemAvatar>
															<ListItemText primary="Hora establecida" secondary={proximaAsesoria.horarioAgenda} />
														</ListItem>
													</List>
												)}
											</div>
										</div>
									</div>
								</Card>

								<Card body className="control-asesorias-card shadow text-center mb-3">
									<Typography variant="h5" gutterBottom className="text-center">
										Última Agenda resuelta
									</Typography>

									<div className="d-flex justify-content-center mt-3">
										<div className="d-flex align-items-center progreso-certificaciones-mos">
											<div className="d-flex flex-column">
												{ultimaAsesoria === null ? (
													<div className="text-center">
														<FontAwesomeIcon icon={faBoxOpen} size="3x" color="#dc3545" className="mt-3" />

														<Typography variant="h6" gutterBottom>
															No hay ninguna asesoría resuelta
														</Typography>
													</div>
												) : (
													<div>
														<List>
															<ListItem>
																<ListItemAvatar>
																	<Avatar>
																		<FontAwesomeIcon icon={faBookOpen} />
																	</Avatar>
																</ListItemAvatar>
																<ListItemText primary="Tema elegido" secondary={ultimaAsesoria.temaElegido} />
															</ListItem>

															<ListItem>
																<ListItemAvatar>
																	<Avatar>
																		<FontAwesomeIcon icon={faComments} />
																	</Avatar>
																</ListItemAvatar>
																<ListItemText primary="Problema abordado" secondary={ultimaAsesoria.problematica} />
															</ListItem>
														</List>
													</div>
												)}
											</div>
										</div>
									</div>
								</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</Row>

			<Row className="mt-5">
				<Typography variant="h5" gutterBottom>
					Historial de Asesorías
				</Typography>

				<Col className="mt-3">
					<Row>
						<Col>
							<MaterialReactTable table={table} />
						</Col>
					</Row>
				</Col>
			</Row>

			<Dialog open={showDialogRegistro} onClose={handleCloseDialogRegistro} fullWidth maxWidth="xl">
				<DialogTitle>{formValues.idAgendaAsesoria === 0 ? "Agenda" : "Modificación"} de Asesoría Personalizada</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{formValues.idAgendaAsesoria === 0
							? "Agenda una asesoría personalizada para que uno de nuestros Asesores Expertos puedan ayudarte a resolver dudas en temas de Office."
							: "Modifica los datos que sean necesarios"}
					</DialogContentText>

					<Alert variant="outlined" severity="info" className="mt-3">
						Si necesitas orientación en otro tema que no sea de Office, puedes enviar tu solicitud a nuestro Chat en Línea.
					</Alert>

					<Form onSubmit={formValues.idAgendaAsesoria === 0 ? handleAgendarAsesoria : handleModificarAsesoria} className="mt-3">
						<Container fluid className="p-0 mb-3">
							<Row>
								<Col xs={12} md>
									<Form.Group className="mb-3">
										<FloatingLabel label="Elige un tema para tu asesoría">
											<Form.Select value={formValues.temaElegido} onChange={handleSeleccionTemaAsesoria}>
												<option value="">Elige una opción</option>
												<option value="Excel">Excel</option>
												<option value="Power BI">Power BI</option>
												<option value="PowerPoint">PowerPoint</option>
												<option value="Word">Word</option>
												<option value="Outlook">Outlook</option>
											</Form.Select>
										</FloatingLabel>
									</Form.Group>
								</Col>

								<Col xs={12} md>
									<Form.Group className="mb-3">
										<FloatingLabel label="Ingresa tu número de teléfono a 10 dígitos">
											<Form.Control
												placeholder="Ingresa tu número de teléfono a 10 dígitos"
												name="numeroTelefono"
												value={formatPhoneNumber(formValues.numeroTelefono)}
												onChange={handleOnChange}
												disabled={disableNumeroTelefono}
												maxLength="15"
											/>
										</FloatingLabel>
									</Form.Group>

									{formValues.numeroTelefono !== "" ? (
										<FormControlLabel
											control={<Checkbox onChange={() => setDisableNumeroTelefono(!disableNumeroTelefono)} defaultChecked={!disableNumeroTelefono} />}
											label="Cambiar número de teléfono"
										/>
									) : null}
								</Col>
							</Row>
						</Container>

						<Form.Group className="mb-3">
							<FloatingLabel label="Escribe aquí tu duda o problemática a resolver">
								<Form.Control
									as="textarea"
									placeholder="Deja tu duda o problemática a resolver"
									name="problematica"
									value={formValues.problematica}
									onChange={handleOnChange}
									style={{ height: "100px" }}
								/>
							</FloatingLabel>
						</Form.Group>

						<Container fluid className="p-0">
							<Row>
								<Col xs={12} md>
									<Typography className="ps-3" gutterBottom variant="subtitle1">
										Elige una fecha{" "}
										<Typography gutterBottom variant="caption">
											(Sujeta a disponibilidad)
										</Typography>{" "}
									</Typography>

									<Calendar locale="es" value={fechaCalendario} onChange={handleOnChangeCalendario} inline className="calendario-agenda w-100" disabledDates={invalidDates} />

									<Alert variant="outlined" severity="warning" className="mt-3">
										Para agendar una asesoría deberás hacerlo al menos un día antes de la fecha que elijas.
									</Alert>
								</Col>

								<Col xs={12} md>
									<Typography gutterBottom variant="subtitle1">
										Elige un horario de atención{" "}
										<Typography gutterBottom variant="caption">
											(Sujeto a disponibilidad)
										</Typography>{" "}
									</Typography>

									{formValues.fechaAgenda === 0 ? (
										<Typography gutterBottom variant="subtitle2">
											Elige una fecha para continuar
										</Typography>
									) : isFechaEntreSemana ? (
										<Form.Group className="mb-3">
											<FloatingLabel label="Elige un horario de atención de Lunes a Viernes">
												<Form.Select value={formValues.horarioAgenda} onChange={handleSeleccionHorario} ref={horariosEntreSemanaRef}>
													<option value="">Elige un horario</option>

													{horariosAtencion.entreSemana.map((idx, horarios) => (
														<option key={idx} value={horariosAtencion.entreSemana[horarios]}>
															{horariosAtencion.entreSemana[horarios]}
														</option>
													))}
												</Form.Select>
											</FloatingLabel>
										</Form.Group>
									) : (
										<Form.Group className="mb-3">
											<FloatingLabel label="Elige un horario de atención en Sábado">
												<Form.Select value={formValues.horarioAgenda} onChange={handleSeleccionHorario} ref={horariosSabadoRef}>
													<option value="">Elige un horario</option>

													{horariosAtencion.finSemana.map((idx, horarios) => (
														<option key={idx} value={horariosAtencion.finSemana[horarios]}>
															{horariosAtencion.finSemana[horarios]}
														</option>
													))}
												</Form.Select>
											</FloatingLabel>
										</Form.Group>
									)}

									<TitleDashboard title="Tu Agenda" />

									<List>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<FontAwesomeIcon icon={faBookOpen} />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Tema elegido" secondary={formValues.temaElegido} />
										</ListItem>

										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<FontAwesomeIcon icon={faCalendarDay} />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Fecha agendada" secondary={formValues.fechaAgenda === 0 ? "Elige una fecha" : getDateFromUnix(formValues.fechaAgenda)} />
										</ListItem>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<FontAwesomeIcon icon={faBusinessTime} />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Hora establecida" secondary={formValues.horarioAgenda === "" ? "Elige un horario" : formValues.horarioAgenda} />
										</ListItem>
										<ListItem>
											<ListItemAvatar>
												<Avatar>
													<FontAwesomeIcon icon={faPhone} />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary="Número de contacto" secondary={formValues.numeroTelefono === "" ? "Ingresa tu número de teléfono" : formatPhoneNumber(formValues.numeroTelefono)} />
										</ListItem>
									</List>
								</Col>
							</Row>
						</Container>

						<Button hidden type="submit" ref={formButtonRefRegistro} />
					</Form>
				</DialogContent>

				<DialogActions className="mb-4">
					<Button variant="success" onClick={() => formButtonRefRegistro?.current.click()}>
						{formValues.idAgendaAsesoria === 0 ? "Agendar asesoría" : "Modificar asesoría"}
						{showLoader ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
					</Button>

					<Button variant="secondary" onClick={handleCloseDialogRegistro}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog disableEscapeKeyDown open={showDialogFeedback} onClose={handleCloseDialogFeedback} fullWidth maxWidth="sm">
				<DialogTitle>Tus opiniones nos ayudan a crecer: ¿Qué te pareció la asesoría?</DialogTitle>

				<DialogContent>
					<Typography gutterBottom variant="overline">
						¡Tu opinión es clave para nosotros!
					</Typography>

					{ultimaAsesoria !== null && (
						<List>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<FontAwesomeIcon icon={faBookOpen} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Tema elegido" secondary={ultimaAsesoria.temaElegido} />
							</ListItem>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<FontAwesomeIcon icon={faCalendarDay} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Fecha agendada" secondary={getDateFromUnix(ultimaAsesoria.fechaAgenda)} />
							</ListItem>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<FontAwesomeIcon icon={faBusinessTime} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Hora establecida" secondary={ultimaAsesoria.horarioAgenda} />
							</ListItem>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<FontAwesomeIcon icon={faComments} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Problema abordado" secondary={ultimaAsesoria.problematica} />
							</ListItem>
						</List>
					)}

					<Form onSubmit={handleFeedback} className="mt-3">
						<Form.Group className="mb-3">
							<FloatingLabel label="Escribe tu experiencia con esta asesoría">
								<Form.Control name="feedback" value={formValues.feedback} as="textarea" placeholder="Escribe tu experiencia con esta asesoría" style={{ height: "150px" }} onChange={handleOnChange} />
							</FloatingLabel>
						</Form.Group>

						<Button hidden type="submit" ref={formButtonRefFeedback} />
					</Form>

					<Typography gutterBottom variant="overline">
						Califica tu experiencia
					</Typography>

					<ReactStars count={5} onChange={ratingChanged} size={50} activeColor="#ffd700" />
				</DialogContent>

				<DialogActions>
					<Button variant="success" onClick={() => formButtonRefFeedback?.current.click()}>
						Enviar retroalimentación {showLoader ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
};

export default AgendasAsesorias;
