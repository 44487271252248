import "@assets/css/components/DashboardCursos/DataTables/style.css";

import { Col, Container, Row } from "react-bootstrap";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import React, { useEffect, useState } from "react";
import {
  faEdit,
  faImage,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCursos,
  reactivateCurso,
} from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";

import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { EditarCurso } from "../Modales/EditarCurso/EditarCurso";
import { EliminarCurso } from "../Modales/EliminarCurso/EliminarCurso";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VerPortadaCurso from "../Modales/VerCurso/VerCurso";
import columns from "./columns";

export const TableCursos = ({ cursoAgregado, setCursoAgregado }) => {
  const [cursos, setCursos] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [cursoEditado, setCursoEditado] = useState(false);

  const [cursoEliminado, setCursoEliminado] = useState(false);

  const [cursoReactivado, setCursoReactivado] = useState(false);

  useEffect(() => {
    const consultarCursos = async () => {
      try {
        const { data: cursos } = await getCursos();

        setCursos(cursos);
      } catch (error) {
        console.log("Error al obtener los cursos disponibles: ", error);

        Notificacion("error", "No se pudieron obtener los cursos");
      }
    };

    consultarCursos();
  }, []);

  useEffect(() => {
    if (cursos.length > 0) {
      const handleVerCurso = async ({ currentTarget }) => {
        try {
          let codigoCurso = currentTarget.id.split("-")[1];

          await ModalSweetAlert({
            title: cursos.filter(
              (curso) => curso.codigoCurso === codigoCurso
            )[0].nombre,
            html: (
              <VerPortadaCurso
                portadaCurso={
                  cursos.filter((curso) => curso.codigoCurso === codigoCurso)[0]
                    .portadaCurso
                }
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log("Error al obtener la portada del curso: ", error);

          Notificacion("error", "Error al obtener la portada del curso");
        }
      };

      const handleEditCurso = async ({ currentTarget }) => {
        try {
          const codigoCurso = currentTarget.id.split("-")[1];

          await ModalSweetAlert({
            width: 650,
            title: "Editar curso",
            html: (
              <EditarCurso
                setCursoEditado={setCursoEditado}
                curso={
                  cursos.filter((curso) => curso.codigoCurso === codigoCurso)[0]
                }
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log("Error al editar el curso: ", error);

          Notificacion("error", "Error al editar el curso");
        }
      };

      const handleDeleteCurso = async ({ currentTarget }) => {
        try {
          const codigoCurso = currentTarget.id.split("-")[1];

          await ModalSweetAlert({
            title: "Eliminar curso",
            html: (
              <EliminarCurso
                setCursoEliminado={setCursoEliminado}
                curso={
                  cursos.filter((curso) => curso.codigoCurso === codigoCurso)[0]
                }
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (e) {
          console.log(e);
        }
      };

      const handleReactivacionCurso = async ({ currentTarget }) => {
        try {
          const codigoCurso = currentTarget.id.split("-")[1];

          await reactivateCurso(codigoCurso);

          Notificacion("success", "Curso reactivado");

          setCursoReactivado(true);
        } catch (e) {
          console.log(e);
        }
      };

      setDataTableColumns([
        ...columns,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`select-${row.codigoCurso}`}
                className="me-1 pointer"
                onClick={handleVerCurso}
              >
                <FontAwesomeIcon icon={faImage} className="text-primary" />
              </span>

              <span
                id={`edit-${row.codigoCurso}`}
                className="me-1 pointer"
                onClick={handleEditCurso}
              >
                <FontAwesomeIcon icon={faEdit} className="text-info" />
              </span>

              {row.estatus ? (
                <span
                  id={`delete-${row.codigoCurso}`}
                  className="me-1 pointer"
                  onClick={handleDeleteCurso}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              ) : (
                <span
                  id={`reactivate-${row.codigoCurso}`}
                  className="me-1 pointer"
                  onClick={handleReactivacionCurso}
                >
                  <FontAwesomeIcon icon={faPowerOff} className="text-success" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]);
    }
  }, [cursos]);

  useEffect(() => {
    if (cursoAgregado || cursoEditado || cursoEliminado || cursoReactivado) {
      const consultarCursos = async () => {
        try {
          const { data: cursos } = await getCursos();

          console.log(cursos);

          setCursos(cursos);
        } catch (error) {
          console.log("Error al obtener los cursos disponibles: ", error);

          Notificacion("error", "No se pudieron obtener los cursos");
        }
      };

      consultarCursos();

      setCursoAgregado(false);

      setCursoEditado(false);

      setCursoEliminado(false);

      setCursoReactivado(false);
    }
  }, [cursoAgregado, cursoEditado, cursoEliminado, cursoReactivado]);

  /**
   * ENCARGADO DE FILTRAR CADA QUE SE DETECTE CAMBIO EN EL BUSCADOR
   */
  // const filtrarElementos = (e) => {
  // 	try {
  // 		let busqueda = e.target.value.toLowerCase();
  // 		let search = data.filter((registro) => {
  // 			if (busqueda.length > 0) {
  // 				if (
  // 					registro.nombre.toLowerCase().includes(busqueda) ||
  // 					registro.codigoCurso.toLowerCase().includes(busqueda) ||
  // 					registro.estatus.toString().toLowerCase().includes(busqueda) ||
  // 					registro.cantidadLecciones.toString().toLowerCase().includes(busqueda)
  // 				) {
  // 					return registro;
  // 				}
  // 			}
  // 			return false;
  // 		});

  // 		setState({ ...state, cursosFiltrados: search });
  // 	} catch (e) {
  // 		console.log(e);
  // 	}
  // };

  return (
    <Container fluid>
      {/* puede servir */}
      {/* <Row>
				<Col>
					<Form.Label>Buscar:</Form.Label>
					<Form.Control name="busqueda" type="text" placeholder="Bucar..." autoComplete="off" onChange={filtrarElementos} />
				</Col>
			</Row> */}

      <Row>
        <Col>
          <DataTablesRecipient columns={dataTableColumns} data={cursos} />
        </Col>
      </Row>
    </Container>
  );
};
