import TitleDashboard from "@components/general/Titles/TitleDashboard";
import useWindowResize from "@customHooks/useWindowResize";
import {
  faChalkboardTeacher,
  faExpand,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const VisorDeLecciones = ({
  videos,
  currentLesson,
  requestFullscreen,
  urlRutas,
  iframeRef,
  handleLeccionActual,
  finalizarRuta,
  handleFinalizarRuta,
  handleStartTour,
  urlPlantilla,
}) => {
  const {
    size: { width },
  } = useWindowResize();

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <TitleDashboard title={"Mis rutas de aprendizaje"} />
            </Col>

            {width > 768 && (
              <Col className="col-auto question-rutas end-rutas">
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip>Conoce tu entorno</Tooltip>}
                >
                  <FontAwesomeIcon
                    className="mx-5 pointer "
                    onClick={handleStartTour}
                    style={{ fontSize: "2rem" }}
                    icon={faQuestion}
                  />
                </OverlayTrigger>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="mb-4 mt-4 justify-content-center visor" ref={videos}>
        <Col xl={8} className="mb-4 mb-md-0">
          {urlRutas.urlVideo !== "" ? (
            <div className="encabezado-video">
              <Row>
                <Col>
                  <Row className="mb-3">
                    <Col className="text-capitalize">
                      <h4>{currentLesson.nombreLeccion}</h4>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div align={"end"} className="mb-3 overlay">
                <OverlayTrigger
                  className="overlay-trigger"
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-bottom}`}>Pantalla completa.</Tooltip>
                  }
                >
                  <Button
                    className="btn-fullscreen"
                    onClick={requestFullscreen}
                    variant="secondary"
                  >
                    <FontAwesomeIcon icon={faExpand} />
                  </Button>
                </OverlayTrigger>
              </div>

              {/*//* IFRAME PARA EL VIDEO DE LA LECCION, CARDS DE LAS RUTAS DE APRENDIZAJE */}
              {currentLesson.leccion.startsWith("PNLB") ||
              currentLesson.leccion.startsWith("CL23") ? (
                <div className="embed-responsive embed-responsive-16by9 visor-curso">
                  <iframe
                    style={{ border: "none" }}
                    allow="autoplay; encrypted-media;fullscreen"
                    loading="lazy"
                    title="Lección actual"
                    ref={iframeRef}
                    className="embed-responsive-item"
                    src={urlPlantilla}
                  ></iframe>
                </div>
              ) : (
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    allow="autoplay; encrypted-media"
                    loading="lazy"
                    ref={iframeRef}
                    title="Lección"
                    src={urlRutas.urlVideo}
                    width={"100%"}
                    height="500px"
                  ></iframe>
                </div>
              )}
            </div>
          ) : (
            <div className="text-capitalize">
              {/* <Row>
                    <Col>
                        <Row className="mb-3">
                            <Col>
                                <TitleDashboard title="Elige una ruta" />
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
              {/* //*IFRAME PARA EL VIDEO DE LA LECCION, CARDS DE LAS RUTAS DE APRENDIZAJE */}
              {/* <Image className="img-fluid " style={{ height: "100%", borderRadius: "40px" }} src={require("@assets/images/estudiando.jpg")} alt={"Foto de perfil"} /> */}
              <Row>
                <Col
                  className="text-center mt-5 mb-5"
                  style={{ color: "grey" }}
                >
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    className="mb-3 icon"
                    size="4x"
                  />
                  {/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
                  <h4>Selecciona una ruta para comenzar</h4>
                </Col>
              </Row>
            </div>
          )}
        </Col>

        {urlRutas.lecciones.length > 0 && (
          <Col xl={4} className="lista-lecciones mt-5 mt-xl-0">
            {/* //*LISTA DE LECCIONES DE LA RUTA  */}

            <aside>
              <Col>
                <div className="outer ">
                  <h5 className="mt-4">Lecciones de la ruta</h5>
                  <div
                    className={`progress mt-2 ${
                      urlRutas.lecciones.length > 0 &&
                      "rutas-aprendizaje contenedor-rutas"
                    }`}
                  >
                    <div className="right">
                      {urlRutas.lecciones.length > 0 && (
                        <>
                          {urlRutas.lecciones.map((leccion, key) => (
                            <div
                              key={key}
                              className={` ${
                                currentLesson.leccion === leccion.codigoLeccion
                                  ? `current ${
                                      leccion.codigoLeccion ===
                                        urlRutas.lecciones[
                                          urlRutas.lecciones.length - 1
                                        ].codigoLeccion && "done"
                                    }`
                                  : ""
                              }`}
                            >
                              <b
                                key={key}
                                id={leccion.codigoLeccion}
                                className="pointer leccion-lista"
                                onClick={handleLeccionActual}
                              >
                                {leccion.nombre}
                              </b>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  <Button
                    disabled={!finalizarRuta}
                    variant={"success"}
                    onClick={handleFinalizarRuta}
                    className="finalizar-ruta mt-2 mb-2"
                    block
                  >
                    Finalizar la ruta
                  </Button>
                </div>
              </Col>
            </aside>
          </Col>
        )}
      </Row>
    </>
  );
};

export default VisorDeLecciones;
