import React from "react";
import { Col } from "react-bootstrap";
import PaquetesIncluidosList from "./lists/PaquetesIncluidosList";

const PaquetesIncluidos = ({ codigoAplicado }) => {
	return (
		<Col>
			<h3>Incluye:</h3>

			<PaquetesIncluidosList 
				codigoAplicado={ true }
			/>
		</Col>
	);
};

export default PaquetesIncluidos;
