import React from "react";

import SliderPrincipal from "../Home/components/SeccionSliderHome";
import UsuarioLogeadoHomeBienvenida from "./components/HomeDefaultTemplate";
import HomeDefaultSlider from "./components/HomeDefaultSlider";

const UsuarioLogueadoHome = () => {
  return (
    <>
      <SliderPrincipal />
      <UsuarioLogeadoHomeBienvenida />
      <HomeDefaultSlider />
    </>
  );
};

export default UsuarioLogueadoHome;
