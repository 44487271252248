import { Notificacion } from "@components/general/Alertas/Alertas";
import { addPlantillaTrabajo } from "@helpers/httpRequests/httpRequestPlantillasTrabajo/httpRequestPlantillasTrabajo";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const AgregarPlantilla = ({ setPlantillaAgregada }) => {
  const [formValues, setFormValues] = useState({
    nombre: "",
    urlPlantilla: "",
    codigoLeccion: "",
  });

  const { nombre, urlPlantilla, codigoLeccion } = formValues;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addPlantillaTrabajo(formValues);

      setPlantillaAgregada(true);

      Notificacion("success", "La plantilla ha sido agregada correctamente");
    } catch (error) {
      console.log("Error al agregar la plantilla: ", error);

      Notificacion(
        "error",
        "Algo sucedió al agregar la plantilla, vuelve a intentar"
      );
    }
  };

  const handleOnChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit} className="text-start">
            <Form.Group className="mb-4">
              <Form.Label>Nombre de la plantilla</Form.Label>

              <Form.Control
                type="text"
                name="nombre"
                value={nombre}
                onChange={handleOnChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Enlace de descarga de plantilla</Form.Label>

              <Form.Control
                type="text"
                name="urlPlantilla"
                value={urlPlantilla}
                onChange={handleOnChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Código de Lección</Form.Label>

              <Form.Control
                type="text"
                name="codigoLeccion"
                value={codigoLeccion}
                onChange={handleOnChange}
              />
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" type="submit" size="lg">
                Agregar plantilla
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AgregarPlantilla;
