import React, { useState } from 'react'
import MuteToggle from '../MuteToggle/mutetoggle'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import useSound from 'use-sound'
import startSfx from '../../sounds/startTimer.mp3'
import pauseSfx from '../../sounds/pauseTimer.mp3'

const TimerDisplay = ({ timerMode,
  percentage,
  timeLeft,
  isActive,
  setIsActive,
  buttonText,
  setButtonText,
  volume,
  setVolume,

}) => {

  let colorValue = "default";

  const pomodoroValues = JSON.parse(localStorage.getItem("pomodoroValues"));

  if (pomodoroValues !== null) {
    const { accentColor } = pomodoroValues;
    colorValue = accentColor;
  }

  const [play] = useSound(startSfx, {
    interrupt: true,
    volume: volume,
  })
  const [pause] = useSound(pauseSfx, {
    interupt: true,
    volume: volume,
  })


  const handleClick = (event) => {
    if (event.target.id === 'muteButton') {
      return null
    }

    if (timeLeft === '0:00') {
      return null
    }

    if (isActive) {
      pause()
    }
    else {
      play()
    }
    setIsActive(!isActive)

    setButtonText(buttonText === 'INICIAR'
      || buttonText === 'REANUDAR'
      ? 'PAUSAR'
      : 'REANUDAR'
    )
  }

  let timesUpMsg = timerMode === 'pomo'
    ? 'Descansa un momento'
    : 'Regresa a estudiar'

  let timeText = timeLeft === '0:00'
    ? timesUpMsg
    : timeLeft

  let textSize = timeLeft === '0:00'
    ? '8px '
    : '28px'

  return (
    <div className="timer">
      <div className="timer__display">
        <CircularProgressbarWithChildren
          value={percentage}
          text={timeText}
          strokeWidth={4}
          styles={buildStyles({
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
            // Colors & Fonts
            pathColor: `${colorValue !== "default" ? 'var(--' + colorValue + '-accent)' : 'var(--accent-color)'}`,
            textColor: 'var(--text)',
            textSize: textSize,
            fontFamily: 'var(--font-current)',
            trailColor: 'none',
          })}>

          <MuteToggle volume={volume}
            setVolume={setVolume} />

          <button className="display__start-pause" onClick={handleClick}>{buttonText}</button>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  )
}

export default TimerDisplay