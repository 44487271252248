import useSignaturePad from "@customHooks/useSignaturePad";
import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Container, FloatingLabel, Form, OverlayTrigger, Popover, Row, useAccordionButton } from "react-bootstrap";
import { FileUpload } from "primereact/fileupload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileSignature, faSpinner, faUndo } from "@fortawesome/free-solid-svg-icons";
import ReactSignatureCanvas from "react-signature-canvas";
import bancosEmisores from "./bancosEmisores";
import { usePaymentInputs } from "react-payment-inputs";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";

const errorMessages = {
	emptyCardNumber: "Número de tarjeta vacío",
	invalidCardNumber: "Número de tarjeta inválido",
};

const AccordionAutorizacionContrato = ({ isCreditoDirecto, formValues, setFormValues, buttonRef, eventKey, loading }) => {
	const { caraFrontalIne, caraPosteriorIne, caraFrontalTarjeta } = formValues;

	const signaturePad = useRef();

	const decoratedOnClick = useAccordionButton(eventKey);

	const { showSignaturePad, signed, handleInitSignaturePadButton, resetSignaturePad, getImageFromSignaturePad, isEmpty, blockSignaturePad, unlockSignaturePad } = useSignaturePad();

	const { meta, getCardNumberProps } = usePaymentInputs({ errorMessages });

	const { erroredInputs, touchedInputs } = meta;

	const handleResetClick = () => {
		resetSignaturePad(signaturePad);
	};

	const checkEmptySignature = () => {
		return isEmpty(signaturePad);
	};

	const [stateCargaImagenes, setStateCargaImagenes] = useState({
		caraFrontalIne: {
			loaded: caraFrontalIne === "" ? false : true,
			data: `${caraFrontalIne === "" ? "" : caraFrontalIne}`,
		},
		caraPosteriorIne: {
			loaded: caraPosteriorIne === "" ? false : true,
			data: `${caraPosteriorIne === "" ? "" : caraPosteriorIne}`,
		},
		caraFrontalTarjeta: {
			loaded: caraFrontalTarjeta === "" ? false : true,
			data: `${caraFrontalTarjeta === "" ? "" : caraFrontalTarjeta}`,
		},
	});

	const onFileUpload = async ({ files, options }) => {
		const { props } = options;

		const { name } = props;

		const fileReader = new FileReader();

		const selectedFile = files;

		if (selectedFile.length > 0) {
			let fileToLoad = selectedFile[0];

			fileReader.onload = function (fileLoaded) {
				const fileBase64 = fileLoaded.target.result;

				if (name !== "") {
					setStateCargaImagenes({
						...stateCargaImagenes,
						[name]: {
							loaded: true,
							data: fileBase64,
						},
					});
				}
			};

			fileReader.readAsDataURL(fileToLoad);
		}
	};

	const getPopover = ({ title, description }) => {
		return (
			<Popover data-bs-theme="dark">
				<Popover.Header as="h3" className="text-white">
					{title}
				</Popover.Header>

				<Popover.Body>{description}</Popover.Body>
			</Popover>
		);
	};
	
	const handleOnChange = ({ target }) => {
		let value = target.value;

		if (target.name === 'cardNumber') {
			target.name = 'numeroTarjetaDomiciliacion';
		}

		setFormValues({
			...formValues,
			[target.name]: value,
		});
	};

	useEffect(() => {
		if (signed) {
			let emptyCanvas = checkEmptySignature();

			if (!emptyCanvas) {
				setFormValues({
					...formValues,
					firma: signaturePad?.current?.getTrimmedCanvas().toDataURL("image/png"),
				});
			}

			blockSignaturePad(signaturePad);
		} else {
			setFormValues({
				...formValues,
				firma: "",
			});

			unlockSignaturePad(signaturePad);
		}
	}, [signed]);

	useEffect(() => {
		for (const imagen in stateCargaImagenes) {
			if (Object.hasOwnProperty.call(stateCargaImagenes, imagen)) {
				const element = stateCargaImagenes[imagen];

				if (element.loaded) {
					setFormValues({
						...formValues,
						[imagen]: element.data,
					});
				}
			}
		}
	}, [stateCargaImagenes]);

	return (
		<Accordion.Item eventKey="4">
			<Accordion.Header>
				<Typography variant="button">Autorización de Contrato</Typography>
			</Accordion.Header>

			<Accordion.Body>
				<Container fluid>
					{isCreditoDirecto ? (
						<Row className="mb-3">
							<Typography variant="overline">Carga de Imágenes</Typography>
							<br />
							<Typography variant="caption">Carga las siguientes imágenes necesarias para gestionar tu Plan de Crédito con Global Click México</Typography>
							<hr />
							<Col xs={12} md={4}>
								<OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={getPopover({ title: "Cara Frontal de tu INE.", description: "Carga una foto de la cara frontal de tu INE" })}>
									<span>
										<FileUpload
											chooseOptions={{ icon: "pi pi-upload", className: "w-100 mb-3 p-button-success" }}
											mode="basic"
											name="caraFrontalIne"
											chooseLabel="Cara Frontal de INE"
											accept="image/*"
											maxFileSize={1000000}
											auto
											customUpload
											uploadHandler={onFileUpload}
										/>

										<Typography className={stateCargaImagenes.caraFrontalIne.loaded ? "text-success" : "text-danger"} variant="caption">
											{stateCargaImagenes.caraFrontalIne.loaded ? "Cargada" : "Falta cargar"}
										</Typography>
									</span>
								</OverlayTrigger>
							</Col>

							<Col xs={12} md={4}>
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="bottom"
									overlay={getPopover({ title: "Cara Posterior de tu INE.", description: "Carga una foto de la cara posterior o trasera de tu INE" })}>
									<span>
										<FileUpload
											chooseOptions={{ icon: "pi pi-upload", className: "w-100 mb-3 p-button-success" }}
											mode="basic"
											name="caraPosteriorIne"
											chooseLabel="Cara Posterior de INE"
											accept="image/*"
											maxFileSize={1000000}
											auto
											customUpload
											uploadHandler={onFileUpload}
										/>

										<Typography className={stateCargaImagenes.caraPosteriorIne.loaded ? "text-success" : "text-danger"} variant="caption">
											{stateCargaImagenes.caraPosteriorIne.loaded ? "Cargada" : "Falta cargar"}
										</Typography>
									</span>
								</OverlayTrigger>
							</Col>

							<Col xs={12} md={4}>
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="bottom"
									overlay={getPopover({
										title: "Cara Posterior de Tarjeta de Débito",
										description: "Carga una foto de tu Tarjeta de Débito sólo por la parte frontal para efectuar tu domiciliación de pagos.",
									})}>
									<span>
										<FileUpload
											chooseOptions={{ icon: "pi pi-upload", className: "w-100 mb-3 p-button-success" }}
											mode="basic"
											name="caraFrontalTarjeta"
											chooseLabel="Cara Frontal de TDD"
											accept="image/*"
											maxFileSize={1000000}
											auto
											customUpload
											uploadHandler={onFileUpload}
										/>

										<Typography className={stateCargaImagenes.caraFrontalTarjeta.loaded ? "text-success" : "text-danger"} variant="caption">
											{stateCargaImagenes.caraFrontalTarjeta.loaded ? "Cargada" : "Falta cargar"}
										</Typography>
									</span>
								</OverlayTrigger>
							</Col>
						</Row>
					) : null}

					{isCreditoDirecto ? (
						<Row className="mb-3">
							<Typography variant="overline">Domiciliación</Typography>
							<br />
							<Typography variant="caption">Ingresa la siguiente información para efectuar la domiciliación a la tarjeta que ingreses</Typography>
							<hr />

							<Col xs={12} md={6}>
								<FloatingLabel label="Número de Tarjeta" className="mb-3">
									<Form.Control
										type="text"
										placeholder="Número de Tarjeta"
										value={formValues.numeroTarjetaDomiciliacion}
										{...getCardNumberProps({ onChange: handleOnChange })}
										isInvalid={touchedInputs.cardNumber && erroredInputs.cardNumber}
									/>

									<Form.Control.Feedback type="invalid">{erroredInputs.cardNumber}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>

							<Col xs={12} md={6}>
								<FloatingLabel label="Titular de Tarjeta" className="mb-3">
									<Form.Control type="text" placeholder="Titular de Tarjeta" name="titularTarjetaDomiciliacion" onChange={handleOnChange} value={capitalizeString(formValues.titularTarjetaDomiciliacion)} />
								</FloatingLabel>
							</Col>

							<Col xs={12}>
								<FloatingLabel label="Banco emisor">
									<Form.Select aria-label="Banco emisor" onChange={handleOnChange} name="bancoEmisor">
										{bancosEmisores.map(({ key, value }) => (
											<option key={key} value={value}>
												{value}
											</option>
										))}
									</Form.Select>
								</FloatingLabel>
							</Col>
						</Row>
					) : null}

					<Row className="mb-3">
						<Col id="signatureCanvasParent">
							<Typography variant="overline">Firma de autorización</Typography>
							<br />
							<Typography variant="caption">Firma como en tu INE</Typography>
							<hr />

							<div className={`my-2 signature-canvas ${!showSignaturePad ? "signature-canvas-flex" : ""}`}>
								{!showSignaturePad ? (
									<Button id="firmarContrato" variant="primary" onClick={handleInitSignaturePadButton}>
										<FontAwesomeIcon icon={faFileSignature} /> Firmar contrato
									</Button>
								) : (
									<ReactSignatureCanvas
										canvasProps={{
											className: `signature-pad ${signed ? "signed-pad" : "signing-pad"}`,
										}}
										clearOnResize={true}
										penColor="blue"
										ref={signaturePad}
									/>
								)}
							</div>
						</Col>
					</Row>

					<Row>
						<Col className="align-self-end">
							<Form.Group>
								<div className="d-grid gap-2 mb-3">
									<Button className="btn-block" variant="primary" disabled={!showSignaturePad} onClick={handleResetClick}>
										<FontAwesomeIcon icon={faUndo} /> Volver a firmar
									</Button>
								</div>
							</Form.Group>

							<Form.Group>
								<div className="d-grid gap-2 mb-3">
									<Button className="btn-block" variant="success" disabled={!showSignaturePad} onClick={getImageFromSignaturePad}>
										<FontAwesomeIcon icon={faCheck} /> Terminar de firmar
									</Button>
								</div>
							</Form.Group>
						</Col>

						<Col className="align-self-end">
							<Form.Group>
								<div className="d-grid gap-2 mb-3">
									<Button className="btn-block" disabled={!signed} id="btnFinalizarPreRegistro" type="submit" variant="success">
										{signed ? <>Finalizar {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}</> : "Firma tu contrato para finalizar"}
									</Button>
								</div>
							</Form.Group>
						</Col>
					</Row>
				</Container>

				<Button hidden onClick={decoratedOnClick} ref={buttonRef}></Button>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default AccordionAutorizacionContrato;
