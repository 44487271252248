import React, { useContext } from "react";
import { faArrowUp, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import "@assets/css/components/ForoAyuda/style.css";
import { getTemas } from "@helpers/httpRequests/httpRequestsTemas/httpRequestsTemas";
import UserContext from "@context/User/UserContext";
import {
  addRespuestaTema,
  getRespuestasTema,
} from "@helpers/httpRequests/httpRequestsRespuestasTema/httpRequestsRespuestasTema";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { ModalSweetAlert, Notificacion } from "../Alertas/Alertas";
import NuevoTema from "./Modales/NuevoTema";
import {
  addLike,
  deleteLike,
  getLikes,
  getLikesPorTema,
} from "@helpers/httpRequests/httpRequestLikes/httpRequestsLikes";
import { Animated } from "react-animated-css";
import TabContainerTemas from "./Components/TabContainerTemas";
import VistaInfoTema from "./Components/VistaInfoTema";
import TitleForoAyuda from "../Titles/TitleForoAyuda";
import EliminarComentario from "@components/administrador/DashboardForoAyuda/Modales/EliminarComentario/EliminarComentario";
import { Link } from "react-scroll";
import useWindowResize from "@customHooks/useWindowResize";
import { useRef } from "react";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import NuevoComentario from "./Modales/NuevoComentario";
import EditarComentario from "./Modales/EditarComentario";

const ForoAyuda = ({ showScrollButton }) => {
  const joyrideRef = useRef(null);

  const [tourActivo, setTourActivo] = useState(false);

  const [runTour, setRunTour] = useState(false);

  const [tourSteps, setTourSteps] = useState([
    // {
    // 	target: '.question-curso',
    // 	content: 'Esta es la sección de consejos rápidos, aquí puedes consultar los atajos más relevantes que tienen los cursos que se ofrecen en la plataforma.',
    // 	disableBeacon: true,
    // 	placement: 'left-start'
    //   },
    {
      target: ".question-foroAyuda",
      content:
        "Bienvenido al foro de ayuda, aquí puedes postear temas relacionadas a las categorias listadas a continuación.",
      disableBeacon: true,
      placement: "left-start",
    },
    {
      target: ".listado-foroAyuda",
      content:
        "En este listado encontrarás las categorías disponibles, da click en alguna de ellas para desplegar los temas relacionados que han sido posteados por usuarios de la plataforma.",
      disableBeacon: true,
      placement: "top-start",
    },
    {
      target: ".publicaciones-foroAyuda",
      content:
        "Esta es la sección de tus publicaciones, donde puedes publicar algún tema que sea de tu interés compartir con la comunidad, haz cuantas publicaciones quieras, editalas o eliminalas.",
      disableBeacon: true,
      placement: "top-start",
    },

    {
      target: ".end-foroAyuda",
      content:
        "Por ultimo no olvides que todas las publicaciones que hagas deben ser con algún tema relacionado a los cursos y/o a la plataforma, no se permitirá lenguaje ofensivo o de temas que no sean de la plataforma y de sus cursos.",
      disableBeacon: true,
      placement: "left-start",
    },
  ]);

  const {
    size: { width },
  } = useWindowResize();

  //States para obtener los datos del usuario logeado

  const [temaAgregado, setTemaAgregado] = useState(false);

  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const { idCredencial, nombreUsuario, alias, rol, idPerfil } = usuarioLogeado;

  //Fin de states para obtener los datos del usuario logeado

  //states para almacenar las categorias, temas y respuestas

  const [categorias, setCategorias] = useState([]);

  const [temas, setTemas] = useState([]);

  const [respuestas, setRespuestas] = useState([]);

  //Fin para almacenar las categorias, temas y respuestas.

  //States para likes

  const [likesUsuarios, setLikesUsuarios] = useState([]);

  const [like, setLike] = useState(false);

  const [likeDado, setLikeDado] = useState(false);

  //state para almacenar los likes por tema
  const [likesPorTema, setLikesPorTema] = useState([]);

  //Fin de states para los likes

  const [showTema, setShowTema] = useState({
    show: false,
    idTema: 0,
    alias: "",
    numeroComentarios: 0,
  });

  const [nuevoComentario, setNuevoComentario] = useState({
    show: false,
    alias: "",
    idTema: 0,
    comentarioInsertado: false,
  });

  const [formValues, setFormValues] = useState({
    respuesta: "",
  });

  const [comentarioEliminado, setComentarioEliminado] = useState(false);

  const [comentarioEditado, setComentarioEditado] = useState(false);


  const handleStartTour = () => {
    setRunTour(!runTour);
  };

  const hacerNuevoComentario = async (respuesta, aliasComentario, idTema, imagenComentario = null) => {
    try {
      // let texto = formValues.respuesta.replace(/(\r\n|\n|\r)/gm, '<br/>')

      //Dentro del objeto del comentario se envian:
      //alias = Pertenece al alias de a quien se va a contestar
      //respuestasUsuario = Es el alias del usuario logeado que está enviando la respuesta.
      
      const infoNuevoComentario = {
        alias: aliasComentario,
        respuestasUsuario: alias,
        respuesta,
        idTema: idTema,
        fecha: getUnixTimestamp(),
        idPerfil,
        imagenComentario
      };


      if (infoNuevoComentario.respuesta !== "") {
        const { message } = await addRespuestaTema(infoNuevoComentario);

        setNuevoComentario({
          ...nuevoComentario,
          comentarioInsertado: true,
        });

        Notificacion("success", message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({ ...formValues, respuesta: value });
  };

  const handleEditorChange = (event) => {
    setFormValues({ ...formValues, respuesta: event.textValue });
  };

  const handleNuevoComentario = ({ target }) => {
    const { name: alias, id: idTema } = target;

    ModalSweetAlert({
      html: (
        <NuevoComentario alias={alias} idTema={idTema} hacerNuevoComentario={hacerNuevoComentario} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };


  const handleNuevoTema = () => {
    ModalSweetAlert({
      title: "Nueva publicación",
      html: (
        <NuevoTema
          setTemaAgregado={setTemaAgregado}
          alias={alias}
          idPerfil={idPerfil}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleLike = async () => {
    try {
      const infoLike = {
        alias,
        idTema: showTema.idTema,
      };

      if (like) {
        await deleteLike(infoLike);

        setLikeDado(!likeDado);

        setLike(false);
      } else {
        await addLike(infoLike);

        setLikeDado(!likeDado);

        setLike(true);
      }

      // console.log(like);
    } catch (error) {
      console.log(error);
    }
  };

  const verificarLike = (idTema) => {
    const validacion = likesUsuarios.some((like) => {
      return like.idTema === idTema && like.alias === alias;
    });

    if (validacion) {
      setLike(true);
      setLikeDado(true);
    }
  };

  const comentarTema = async (idTema, alias, idPerfil) => {
    try {
      const { data: dataTemas } = await getTemas();
      // setTemas(data);
      if (Array.isArray(dataTemas)) {
        setTemas(dataTemas);
      } else {
        setTemas([dataTemas]);
      }

      const { data: respuestas } = await getRespuestasTema();
      // console.log(respuestas);

      if (Array.isArray(respuestas)) {
        setRespuestas(respuestas);
      } else {
        setRespuestas([respuestas]);
      }

      const { data } = await getLikes();
      // console.log(data);

      if (Array.isArray(data)) {
        setLikesUsuarios(data);
      } else {
        setLikesUsuarios([data]);
      }
    } catch (error) {
      console.log(error);
      setLike(false);
    }

    verificarLike(idTema);

    setShowTema({
      ...showTema,
      show: true,
      idTema,
      alias,
      idPerfil,
      nombreUsuario,
      numeroComentarios: respuestas.filter(
        (respuesta) => respuesta.idTema === idTema
      ).length,
    });

    setNuevoComentario({ ...nuevoComentario, show: false });
  };

  const numeroLikes = (idTema) => {
    const likes = likesPorTema.find((like) => like.idTema === idTema);

    if (likes !== undefined) {
      const { likesTema } = likes;

      return likesTema;
    } else {
      return 0;
    }
  };

  const reset = () => {
    // console.log("Resetting data");
    setLike(false);

    setShowTema({ ...showTema, show: false });

    setLikeDado(!likeDado);
  };

  const handleEliminarComentario = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    // console.log(rowIndex);
    ModalSweetAlert({
      title: "Eliminar comentario",
      html: (
        <EliminarComentario
          setComentarioEliminado={setComentarioEliminado}
          comentario={rowIndex}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleEditarComentario = (infoComentario) => {

    ModalSweetAlert({
      title: "Editar comentario",
      html: (
        <EditarComentario
          setComentarioEditado={setComentarioEditado}
          comentario={infoComentario}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  useEffect(() => {
    const obtenerCategorias = async () => {
      try {
        const { data } = await getCategoriasCursos();

        setCategorias(data);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCategorias();

    const obtenerTemas = async () => {
      try {
        const { data } = await getTemas();
        if (Array.isArray(data)) {
          setTemas(data);
        } else {
          setTemas([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerTemas();

    const obtenerLikesPorTema = async () => {
      try {
        const { data } = await getLikesPorTema();
        if (Array.isArray(data)) {
          setLikesPorTema(data);
        } else {
          setLikesPorTema([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerLikesPorTema();

    const consultarRespuesta = async () => {
      try {
        const { data } = await getRespuestasTema();
        if (Array.isArray(data)) {
          setRespuestas(data);
        } else {
          setRespuestas([data]);
        }
      } catch (error) {
        console.log("Error al consultar las respuestas de los temas: ", error);
      }
    };

    consultarRespuesta();
  }, []);

  useEffect(() => {
    if (nuevoComentario.comentarioInsertado || comentarioEliminado || comentarioEditado) {
      const obtenerRespuestasTemas = async () => {
        try {
          setNuevoComentario({
            ...nuevoComentario,
            comentarioInsertado: false,
            show: false,
          });

          setFormValues({
            ...formValues,
            respuesta: "",
          });

          const { data } = await getRespuestasTema();

          if (Array.isArray(data)) {
            setRespuestas(data);
          } else {
            setRespuestas([data]);
          }

          setComentarioEliminado(false);
          setComentarioEditado(false);
        } catch (error) {
          console.log(error);
          setRespuestas([]);
        }
      };

      obtenerRespuestasTemas();
    }
  }, [nuevoComentario.comentarioInsertado, comentarioEliminado || comentarioEditado]);

  useEffect(() => {
    if (temaAgregado) {
      const obtenerTemas = async () => {
        try {
          const { data } = await getTemas();
          // setTemas(data);
          if (Array.isArray(data)) {
            setTemas(data);
          } else {
            setTemas([data]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      obtenerTemas();

      setTemaAgregado(false);
    }
  }, [temaAgregado]);

  useEffect(() => {
    // console.log("LIKE DADO");

    const obtenerLikes = async () => {
      try {
        const { data } = await getLikes();

        if (Array.isArray(data)) {
          setLikesUsuarios(data);
        } else {
          setLikesUsuarios([data]);
        }
      } catch (error) {}
    };

    obtenerLikes();

    const obtenerLikesPorTema = async () => {
      try {
        const { data } = await getLikesPorTema();

        if (Array.isArray(data)) {
          setLikesPorTema(data);
        } else {
          setLikesPorTema([data]);
        }
      } catch (error) {
        console.log(error);
        setLikesPorTema([]);
      }
    };

    obtenerLikesPorTema();
  }, [likeDado]);

  const breadcrumbItemsInitialState = [
    { title: "Foro de ayuda", onClick: () => {} },
  ];

  return (
    //*Se usan dos vistas, una será para la vista de todas las categorias y de los temas, la otra para ver el contenido del tema y comentarlo.
    <>
      {showScrollButton && (
        <Animated
          animationIn="fadeIn"
          isVisible={true}
          style={{ zIndex: "9999", position: "absolute" }}
        >
          <span
            className={`rounded-circle scrollTop text-center d-flex`}
            style={{
              height: "50px",
              width: "50px",
              fontSize: "2rem",
              backgroundColor: "#F59E0B",
              position: "fixed",
              bottom: "70px",
              right: "40px",
            }}
          >
            <Link
              className="pointer"
              activeClass="active"
              to="scrollTopElement"
              spy={true}
              smooth={true}
              duration={800}
              containerId="scrollTop"
              style={{ color: "black", width: "100%" }}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </Link>
          </span>
        </Animated>
      )}
      <Animated
        animationIn="fadeIn"
        animationInDuration={2000}
        animationOut="fadeOut"
        isVisible={true}
      >
        <Container fluid>
          <Joyride
            locale={spanishOptionsJoyRide}
            ref={joyrideRef}
            steps={tourSteps}
            run={runTour}
            continuous={true}
            showSkipButton
            hideCloseButton
            disableOverlayClose={true}
            disableCloseOnEsc={false}
            disableScrolling={false}
            scrollToFirstStep={true}
            scrollOffset={100}
            callback={(data) => {
              if (data.status === "finished") {
                setRunTour(false);
              } else if (data.action === "skip") {
                setRunTour(false);
              }
            }}
            styles={{
              options: {
                primaryColor: "#ff9900",
                textColor: "#00152A",
                width: 900,
                zIndex: 1000,
              },
            }}
          />

          <Row className="justify-content-between d-flex align-items-center mt-3">
            <Col className="col-auto">
              <DynamicBreadcrumb
                arrayBreadcrumbs={breadcrumbItemsInitialState}
              />
            </Col>
            {width > 768 && (
              <Col className="col-auto question-foroAyuda end-foroAyuda">
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip>Conoce tu entorno</Tooltip>}
                >
                  <FontAwesomeIcon
                    className="mx-5 pointer "
                    onClick={handleStartTour}
                    style={{ fontSize: "2rem" }}
                    icon={faQuestion}
                  />
                </OverlayTrigger>
              </Col>
            )}
          </Row>

          <Row className="fondo-foro mt-2">
            <Col className="fondo-foro-titulo">
              <Row>
                <Col className="p-4">
                  <TitleForoAyuda title="Foro de ayuda" />
                </Col>
              </Row>
            </Col>
          </Row>

          {!showTema.show ? (
            <Row className="mb-5">
              <Col>
                {/* //*CONTIENE LOS TEMAS POR CATEGORIA, LAS PUBLICACIONES DEL USUARIO LOGEADO Y LOS REPORTES QUE SE MOSTRARAN UNICAMENTE A ADMINISTRADOR*/}
                <TabContainerTemas
                  idPerfil={idPerfil}
                  aliasLogeado={alias}
                  categorias={categorias}
                  temas={temas}
                  alias={alias}
                  respuestas={respuestas}
                  handleNuevoTema={handleNuevoTema}
                  numeroLikes={numeroLikes}
                  comentarTema={comentarTema}
                  setComentarioEliminado={setComentarioEliminado}
                  rol={rol}
                />
              </Col>
            </Row>
          ) : (
            //*VISTA DE LA INFORMACION DEL TEMA QUE SE SELECCIONO*/
            <>
              <VistaInfoTema
                aliasLogeado={alias}
                rol={rol}
                reset={reset}
                showTema={showTema}
                numeroLikes={numeroLikes}
                temas={temas}
                handleLike={handleLike}
                like={like}
                handleNuevoComentario={handleNuevoComentario}
                nuevoComentario={nuevoComentario}
                comentarioEliminado={comentarioEliminado}
                formValues={formValues}
                handleOnChange={handleOnChange}
                handleEditorChange={handleEditorChange}
                hacerNuevoComentario={hacerNuevoComentario}
                handleEliminarComentario={handleEliminarComentario}
                handleEditarComentario={handleEditarComentario}
                comentarioEditado={comentarioEditado}
              />
            </>
          )}
        </Container>
      </Animated>
    </>
  );
};

export default ForoAyuda;
