import { faExclamationTriangle, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getUnixFromDate from "@helpers/dates/getUnixFromDate/getUnixFromDate";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { addBusinessDays, isWeekend, isSameDay, eachDayOfInterval } from "date-fns";
import GestionCertificacionMosTableRow from "./GestionCertificacionMosTableRow";
import { Animated } from "react-animated-css";
import { actualizarIntentoCertificacionMos, agregarSegundoIntentoCertificacionMos } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

const isDisabledDate = (date) => {
	if (isWeekend(date)) {
		return true;
	}

	const currentDate = new Date();

	for (let i = 0; i < 15; i++) {
		if (isSameDay(date, addBusinessDays(currentDate, i))) {
			return true;
		}
	}

	return false;
};

addLocale("es", {
	firstDayOfWeek: 1,
	dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
	dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
	dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
	monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
	monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
	today: "Hoy",
	clear: "Limpiar",
	//...
});

const GestionCertificacionMosTable = ({ intentos, certificacion }) => {
	const [examenRealizadoChecked, setExamenRealizadoChecked] = useState(false);

	const [showButtonAgregarSegundoIntento, setShowButtonAgregarSegundoIntento] = useState(intentos.length < 2);

	const [showFormAgregarSegundoIntento, setShowFormAgregarSegundoIntento] = useState(false);

	const [invalidDates, setInvalidDates] = useState([]);

	const [formValues, setFormValues] = useState({
		date: 0,
		fecha: "",
	});

	const handleMostrarFormAgregarSegundoIntento = () => {
		if (!examenRealizadoChecked) {
			return NotificationManager.error("Primero se debe realizar el primer examen antes de continuar");
		}

		setShowButtonAgregarSegundoIntento(false);

		setShowFormAgregarSegundoIntento(true);
	};

	const handleGuardarSegundoIntento = async () => {
		try {
			const { message } = await agregarSegundoIntentoCertificacionMos({ 
				idCertificacionMos: certificacion.idCertificacionMos, 
				fechaAgenda: formValues.fecha 
			});

			NotificationManager.success(message);
			
			setShowFormAgregarSegundoIntento(false);
	
			setShowButtonAgregarSegundoIntento(intentos.length < 2);
		} catch (error) {
			console.log("Error al guardar el segundo intento del usuario: ", error);

			NotificationManager.error("Algo sucedió al guardar el segundo intento. Verifica más tarde.");
		}

	};

	const cancelAgregarSegundoIntento = () => {
		setShowFormAgregarSegundoIntento(false);

		setShowButtonAgregarSegundoIntento(true);
	};

	const handleSubmitActualizarIntento = async (e) => {
		e.preventDefault();

		try {
			const idIntentoCertificacionMos = parseInt(e.target.elements.examenRealizado.id);

			const examenRealizado = e.target.elements.examenRealizado.checked;

			const examenAprobado = e.target.elements.aprobado.checked;

			const { message } = await actualizarIntentoCertificacionMos({
				idIntentoCertificacionMos,
				examenRealizado,
				aprobado: examenAprobado,
			});

			NotificationManager.success(message);
		} catch (error) {
			console.log("Error al guardar los datos del intento existente: ", error);
		}
	};

	useEffect(() => {
		const updateInvalidDates = () => {
			const next15Days = eachDayOfInterval({
				start: new Date(),
				end: addBusinessDays(new Date(), 15 * 5), // 15 días hábiles son 3 semanas (5 días hábiles por semana)
			});

			const invalidDates = next15Days.filter(isDisabledDate);
			setInvalidDates(invalidDates);
		};

		updateInvalidDates();
	}, []);

	return (
		<>
			{showButtonAgregarSegundoIntento ? (
				<Col align="right">
					<Button variant="primary" onClick={handleMostrarFormAgregarSegundoIntento}>
						Agregar 2° intento <FontAwesomeIcon icon={faUserGraduate} />
					</Button>
				</Col>
			) : null}

			<Form onSubmit={handleSubmitActualizarIntento} id="form-datos-intento">
				<TableContainer component={Paper} className="mt-3 mb-3">
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell className="text-center fw-bold">Número de intento</TableCell>

								<TableCell className="text-center fw-bold">Fecha de solicitud</TableCell>

								<TableCell className="text-center fw-bold">Fecha de aplicación</TableCell>

								<TableCell className="text-center fw-bold">Examen realizado</TableCell>

								<TableCell className="text-center fw-bold">Examen aprobado</TableCell>

								<TableCell className="text-center fw-bold"></TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{intentos.map((intento, idx) => (
								<GestionCertificacionMosTableRow intento={intento} key={idx} setExamenRealizadoCheckedParent={setExamenRealizadoChecked} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Form>

			{showFormAgregarSegundoIntento ? (
				<Animated animationIn="fadeIn" isVisible={true}>
					<Row>
						<Col>
							<Typography variant="subtitle1" gutterBottom className="fw-bold">
								Elige una fecha para el próximo intento
							</Typography>

							<Form id="form-segundo-intento">
								<Form.Group>
									<Form.Label>Fecha para el examen</Form.Label>

									<div>
										<Calendar
											required
											locale="es"
											placeholder="Fecha del examen"
											onChange={(e) =>
												setFormValues({
													...formValues,
													date: e.value,
													fecha: getUnixFromDate(e.value),
												})
											}
											value={formValues.date}
											name="fecha"
											className="calendario"
											showIcon
											dateFormat="dd/MM/yy"
											disabledDates={invalidDates}
											style={{ width: "100%", height: "2.3rem" }}
										/>
									</div>

									<small>
										<FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> La fecha del examen deberá ser al menos 15 dias hábiles despues de la fecha actual.
									</small>
								</Form.Group>
							</Form>
						</Col>

						<Col className="d-flex align-items-end">
							<Button variant="success" onClick={handleGuardarSegundoIntento} className="mx-2">
								Guardar fecha de examen
							</Button>

							<Button variant="danger" onClick={cancelAgregarSegundoIntento} className="mx-2">
								Cancelar
							</Button>
						</Col>
					</Row>
				</Animated>
			) : null}
		</>
	);
};

export default GestionCertificacionMosTable;
