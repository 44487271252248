import { Notificacion } from '@components/general/Alertas/Alertas'
import { deleteRecompensa } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

const EliminarRecompensa = ({setTarjetaRegaloEliminada, idRecompensa}) => {


    console.log(idRecompensa)
    
    const eliminarRecompensa = async() => {
        try {
            await deleteRecompensa(idRecompensa);
            setTarjetaRegaloEliminada(true);
            Notificacion("success", "Recompensa eliminada con éxito");
        } catch (error) {
            console.log(error);
            Notificacion("error", "Error al eliminar la recompensa seleccionada");
        }
    }

    const cancelar = () =>{
        Notificacion("info", "No se ha elimindo la recompensa")
    }



  return (
    <Container>
        <Row>
            <Col>
                <h2>¿Estás seguro de eliminar la recompensa?</h2>
            </Col>
        </Row>

        <Row className='mt-4'>
            <Col className='d-flex gap-2 justify-content-center '>
                <Button variant='success' onClick={eliminarRecompensa}>Si, eliminar</Button>
                <Button variant='danger' onClick={cancelar}>Cancelar</Button>
            </Col>
        </Row>
    </Container>
  )
}

export default EliminarRecompensa