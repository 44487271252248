import { Notificacion } from "@components/general/Alertas/Alertas";
import { reactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ReactivarUsuario = ({ setEmpleadoReactivado, colaborador }) => {
  const { idCredencial, nombreCompleto } = colaborador;
  // console.log(idCredencial);

  const cancelarReactivarUsuario = () => {
    Notificacion("info", "No se rehabilitó a ", nombreCompleto);
  };

  const reactivarUsuario = async () => {
    try {
      const data = await reactivateUsuario(idCredencial);
      setEmpleadoReactivado(true);
      Notificacion("success", "Usuario reactivado con exito");
    } catch (error) {
      Notificacion("error", "Error al reactivar a ", nombreCompleto);
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de reactivar a {nombreCompleto}?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={reactivarUsuario}>
            Sí, reactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarReactivarUsuario}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ReactivarUsuario;
