import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

import httpRequest from "../httpRequest";

export const descargarManual = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const getManual = await httpRequest({
        url: `${endpointsPaths.certificacionMos}/${httpRequestsPaths.certificacionMos.descargables.manualSimulador}`,
        method: "GET",
      });
      resolve(getManual);
    } catch (error) {
      reject(error);
    }
  });
};

export const descargarProceso = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const getProceso = await httpRequest({
        url: `${endpointsPaths.certificacionMos}/${httpRequestsPaths.certificacionMos.descargables.procesoExamen}`,
        method: "GET",
      });
      resolve(getProceso);
    } catch (error) {
      reject(error);
    }
  });
};

export const descargarMaterialApoyo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const getProceso = await httpRequest({
        url: `${endpointsPaths.certificacionMos}/${httpRequestsPaths.certificacionMos.descargables.materialApoyo}`,
        method: "GET",
      });
      resolve(getProceso);
    } catch (error) {
      reject(error);
    }
  });
};

export const enviarSolicitudExamen = (infoSolicitud) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sendSolicitud = await httpRequest({
        url: `${endpointsPaths.certificacionMos}/${httpRequestsPaths.certificacionMos.solicitud.sendSolicitud}`,
        method: "POST",
        body: JSON.stringify(infoSolicitud),
      });
      resolve(sendSolicitud);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCertificadosMos = (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getCertificados } = await httpRequest({
        url: `${endpointsPaths.certificacionMos}/${httpRequestsPaths.certificacionMos.requisitos.verificarCertificados}/${idPerfil}`,
        method: "POST",
      });
      resolve(getCertificados);
    } catch (error) {
      reject(error);
    }
  });
};
