import ListGroup from "react-bootstrap/ListGroup";
// import '@assets/css/components/ExamenDiagnostico/style.css'

const ListaExamenesModal = () => {
  return (
    <ListGroup defaultActiveKey="#link1">
      <ListGroup.Item action href="/ExamenDiagnostico">
        Excel
      </ListGroup.Item>

      <ListGroup.Item action href="#link2">
        Ingles
      </ListGroup.Item>

      <ListGroup.Item action href="#link3">
        Power BI
      </ListGroup.Item>
    </ListGroup>
  );
};

export default ListaExamenesModal;
