import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, Col, Container, Image, Row, Table } from 'react-bootstrap'
import "@assets/css/components/Misiones/style.css"
import { getGanadores, getGanadoresMes } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones'
import { MaterialReactTable } from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { Box, IconButton } from '@mui/material'
import { FaEdit, FaEye } from 'react-icons/fa'
import getDateFromUnix from '@helpers/dates/getDateFromUnix/getDateFromUnix'
import { ModalSweetAlert } from '@components/general/Alertas/Alertas'
import VerInfoGanador from '../modales/VerInfoGanador';

const SalonFama = ({tab}) => {



  //Consulta de ganadores por mes (Se debe proporcionar el mes)

  const [ganadores, setGanadores] = useState([]);

  const [ganadoresMes, setGanadoresMes] = useState([]);


  useEffect(() => {
    const obtenerGanadores = async() =>{
      try {
          const {data} = await getGanadores();
                
          setGanadores(Array.isArray(data)? data: [data]);
      } catch (error) {
        console.log(error);
      }
    }
    obtenerGanadores(); 

    const obtenerGanadoresMes = async()=>{
      try {
        const {data} =await getGanadoresMes();
        setGanadoresMes(Array.isArray(data) ? data: [data]);
      } catch (error) {
        console.log(error);
      }
    }
    obtenerGanadoresMes()
  }, [])

  useEffect(() => {
    const obtenerGanadores = async() =>{
      try {
          const {data} = await getGanadores();

          setGanadores(Array.isArray(data)? data: [data]);
      } catch (error) {
        console.log(error);
      }
    }
    obtenerGanadores(); 

    const obtenerGanadoresMes = async()=>{
      try {
        const {data} =await getGanadoresMes();
        setGanadoresMes(Array.isArray(data) ? data: [data]);
      } catch (error) {
        console.log(error);
      }
    }
    obtenerGanadoresMes()
  }, [tab])
  

  const columnas =  [
    {
      header: "#",
      size: 50,
      Cell: ({ cell }) => (
        <div className="text-center">
          <span>{cell.row.index + 1}</span>
        </div>
      ),
    },
    {
      accessorKey: "nombreGanador",
      header: "Nombre",
    },
  
  ];


  const handleVerInfoMisionCompletada = (info) =>{
    ModalSweetAlert({
      title: "",
      html: (
          <VerInfoGanador infoGanador={info} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
  });
  }

  return (
    <Container className='mb-5 tab-salon' fluid>
       
      <Row>
        <Col className='col-12 contenedor-ganadores text-center titulo-ganadores'>
          {ganadores.length > 0 && (
            <h2>Felicitamos a los <span className='resaltado'>ganadores</span> del mes</h2>
          )}
          <Row className='justify-content-center mt-5'>
            {/* Contenido dinamico dependiendo de los ganadores del mes */}
            {ganadoresMes.length> 0 ? (
             ganadores.map((ganador, idx) => (
               <Col key={idx} className="col-12 col-lg-4 mb-4 mb-lg-0">
                 <Image width="150" height="150" src={ganador.avatarPerfil === "null" ? require(`@assets/images/avatar.png`) : ganador.avatarPerfil} className='rounded-circle mb-3 avatar-misiones' />
                 <h5>{ganador.tituloMision}</h5>
                 <small className="nombre-ganador">GANADOR: {ganador.nombreGanador}</small>
                 <hr style={{margin:"10px auto 10px auto"}} width="50%"/>
                  <b> <p>{getDateFromUnix(ganador.fechaExpiracion)}</p></b>
               </Col>
             ))
            ): (
              <h2>No hay ganadores por el momento</h2>
            )}
          </Row>
          
          <Row className='mt-5 pb-5'>
            <Col>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ganadores anteriores</Accordion.Header>
                  <Accordion.Body>
                    <MaterialReactTable
                      localization={MRT_Localization_ES}
                      columns={columnas}
                      data={ganadores}
                      enableRowActions
                      positionActionsColumn="last"
                      renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                          <IconButton
                            color="secondary"
                            
                            onClick={()=> handleVerInfoMisionCompletada(row.original)}
                          >
                            <FaEye className="text-primary" />
                          </IconButton>

                        </Box>
                      )}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default SalonFama