import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
  addLeccion,
  getLeccionesByCodigoSeccion,
} from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { useState } from "react";
import extensionesPermitidas from "@helpers/resources/extensionesPermitidas";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

export const AgregarLeccion = ({ setLeccionAgregada }) => {
  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const [incluirPlantilla, setIncluirPlantilla] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [formValues, setFormValues] = useState({
    nombre: "",
    codigoCurso: "",
    codigoSeccion: "",
    urlPlantilla: "",
  });

  const [validacion, setValidacion] = useState({
    nombre: false,
    urlPlantilla: false,
    pesoArchivo: false,
  });

  const handleOnChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        formValues.nombre === "" ||
        formValues.codigoCurso === "" ||
        formValues.codigoSeccion === ""
      ) {
        return setShowAlert(true);
      }

      if (incluirPlantilla && formValues.urlPlantilla === "") {
        return setShowAlert(true);
      }

      setShowAlert(false);

      let nuevaLeccion = {
        ...formValues,
        codigoLeccion: "",
      };

      if (lecciones.length > 0) {
        const nivelUltimaLeccion = lecciones[lecciones.length - 1].nivel;

        const nivelNuevaLeccion = parseInt(nivelUltimaLeccion) + 1;

        const nuevoNivelFormateado =
          nivelNuevaLeccion >= 10
            ? nivelNuevaLeccion.toString()
            : `0${nivelNuevaLeccion}`;

        nuevaLeccion.codigoLeccion = `${formValues.codigoSeccion}${nuevoNivelFormateado}`;
      } else {
        nuevaLeccion.codigoLeccion = `${formValues.codigoSeccion}01`;
      }

      const leccionAgregada = await addLeccion(nuevaLeccion);

      setLeccionAgregada(true);

      Notificacion("success", "Lección agregada con éxito");
    } catch (error) {
      console.log("Error al agregar la lección: ", error);
    }
  };

  const handleSeleccionCurso = async ({ target }) => {
    const codigoCurso = target.value;

    setFormValues({
      ...formValues,
      codigoCurso,
    });

    try {
      let { data: secciones } = await getSeccionesByCodigoCurso(codigoCurso);

      if (!Array.isArray(secciones)) {
        secciones = [secciones];
      }

      setSecciones(secciones);
    } catch (e) {
      console.log(e);
    }
  };

  const handleIncluirPlantilla = ({ currentTarget }) => {
    setIncluirPlantilla(currentTarget.checked);
  };

  const handleSeleccionSeccion = async ({ target }) => {
    const codigoSeccion = target.value;

    setFormValues({
      ...formValues,
      codigoSeccion,
    });

    try {
      let { data: lecciones } = await getLeccionesByCodigoSeccion(
        codigoSeccion
      );

      if (!Array.isArray(lecciones)) {
        lecciones = [lecciones];
      }

      setLecciones(lecciones);
    } catch (e) {
      console.log(e);
    }
  };

  //********************************************************************************************************************* */
  //*Metodo encargado de recibir los arhivos cargados por el usuario y validar que sean de las extensiones permitidas
  //*a que el como puede ser modificado y se pueden inyectar archivos ejecutables u otro tipo de archivos*/
  //********************************************************************************************************************* */
  const handleLoadFile = async (e) => {
    try {
      let centinela = true;
      let pesoArchivos = 0;
      let files = e.target.files;
      // if (files.length > 0) {
      // 	Array.from(files).forEach((f) => {
      // 		pesoArchivos += f.size;
      // 		//*en caso de no tener una extension permitida el centilnela nos avisara
      // 		if (!extensionesDocumentosPermitidas.test(f.name)) {
      // 			// console.log(f.name);
      // 			centinela = false;
      // 		}
      // 	});

      // 	//* si todos los archivos son compatibles
      // 	if (centinela) {
      // 		//obtenbemos el valor en megabytes de los archivos los cuales el peso esta en bytes
      // 		let pesoMegas = pesoArchivos / pesoMegabyte;
      // 		// console.log(pesoMegas);
      // 		setValidacion({ ...validacion, pesoArchivo: true });
      // 		let reader = new FileReader();
      // 		reader.onload = (e) => {
      // 			console.log(e.target.result);
      // 		};
      // 		//TODO
      // 		//*uan vez validados los archisvos  que se subiran , debemos subirlos al servidor y obtener la url del recurso para
      // 		//*gaurdarlo en la base de datos
      // 		let json = await getUrlTemplate(state.archivos);
      // 		if (json.code === 200) {
      // 			console.log("getUrl Plantilla");
      // 			console.log(json);
      // 			//*agregamos al state el peso de los archivos , la url de los mismos
      // 			setState({
      // 				...state,
      // 				pesoArchivos: pesoMegas,
      // 				urlPlantilla: json.data.urlPlantilla,
      // 			});

      // 			//!una vez subidos los archivos y obtenida la url al dar click al boton guardar
      // 		} else {
      // 			Notificacion("info", "algo sucedio al cargar los archivos, intente de nuevo!!!");
      // 			console.log(json);
      // 		}
      // 	} else {
      // 		setValidacion({ ...validacion, pesoArchivo: false });
      // 		Notificacion("warning", "Algun archivo no es aceptable");
      // 	}
      // } else {
      // 	setValidacion({ ...validacion, pesoArchivo: false });
      // 	Notificacion("warning", "No se completo la operacion");
      // }
    } catch (e) {
      console.log("error en handleLoadFile");
      console.log(e);
    }
  };

  useEffect(() => {
    const consultarCursos = async () => {
      try {
        const { data: cursos } = await getCursos();

        setCursos(cursos);
      } catch (error) {
        console.log("Error al consultar los cursos: ", error);
      }
    };

    consultarCursos();
  }, []);

  return (
    <Form onSubmit={handleOnSubmit}>
      <Container fluid className="text-start">
        <Row>
          <Form.Group as={Col} controlId="text" className="mb-4">
            <Form.Label>Elige un curso</Form.Label>
            <Form.Control
              defaultValue={"Elige un curso"}
              as={"select"}
              onChange={handleSeleccionCurso}
            >
              <option value="">Elige un curso</option>

              {cursos.map((curso, idx) => (
                <option value={curso.codigoCurso} key={idx}>
                  {curso.nombre} - {curso.codigoCurso}{" "}
                  {codigoCursosUsuarioInvitado.find(
                    (invitado) => invitado.codigoInvitado === curso.codigoCurso
                  ) !== undefined && "- Usuarios Invitados"}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="text" className="mb-4">
            <Form.Label>Elige una seccion</Form.Label>
            <Form.Control as={"select"} onChange={handleSeleccionSeccion}>
              <option value="">Elige una sección</option>

              {secciones.map((seccion, idx) => (
                <option key={idx} value={seccion.codigoSeccion}>
                  {seccion.nombre} - {seccion.codigoSeccion}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridEmail" className="mb-4">
            <Form.Label>Nombre de la leccion</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              value={formValues.nombre}
              name="nombre"
              type="text"
              placeholder="Nombre de la leccion"
            />
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <Form.Check
              type="switch"
              id="plantillaDisponible"
              label="Incluir plantilla"
              onChange={handleIncluirPlantilla}
            />
          </Col>
        </Row>

        {incluirPlantilla && (
          <Row>
            <Col>
              <Form.Group controlId="" className="mb-4">
                <Form.Label>Cargar Documento</Form.Label>

                <Form.Control
                  type="file"
                  name="inputFile"
                  className="inputFile"
                  multiple
                  accept={`${extensionesPermitidas}`}
                  onChange={handleLoadFile}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        {showAlert && (
          <Row>
            <Col>
              <Alert variant="warning">Campos vacíos, revisa tus datos</Alert>
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" type="submit">
                Agregar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
