import { Notificacion } from "@components/general/Alertas/Alertas";
import { deleteCurso } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { deleteLeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { deleteSeccionByCodigo } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarElementoPapelera = ({
  elementoPapelera,
  seleccion,
  setElementoEliminado,
}) => {
  const [showLoader, setShowLoader] = useState(false);

  const handleEliminarElemento = async () => {
    try {
      setShowLoader(true);

      switch (seleccion) {
        case "cursos":
          await deleteCurso(elementoPapelera.codigoCurso);
          break;

        case "secciones":
          await deleteSeccionByCodigo(elementoPapelera.codigoSeccion);
          break;

        case "lecciones":
          await deleteLeccion(elementoPapelera.codigoLeccion);
          break;

        default:
          break;
      }

      setElementoEliminado(true);

      setShowLoader(false);

      Notificacion(
        "success",
        "El elemento se eliminó de la papelera con éxito"
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "El elemento no fue eliminado");
  };

  return (
    <Container fluid className="text-center">
      <h3>
        ¿Estás seguro que deseas eliminar este elemento de la papelera?
        <br />
        <br />
        {seleccion === "cursos" &&
          "Si eliminas el curso, también se eliminará todo su contenido. Este proceso podría tardar, por favor, sé paciente."}
        {seleccion === "secciones" &&
          "Si eliminas esta sección, también se eliminará todo su contenido."}
      </h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleEliminarElemento}>
            {showLoader ? "Eliminando..." : "Sí, eliminar"}
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarElementoPapelera;
