import { Notificacion } from "@components/general/Alertas/Alertas";
import { getLicenciasInvitado } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import { addUsuarioInvitado } from "@helpers/httpRequests/httpRequestsUsuarios/registro/httpRequestsRegistroUsuarios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const splitStringAtMiddle = (text) => {
  const middle = Math.floor(text.length / 2);

  let beforeMid = text.lastIndexOf(" ", middle);

  let afterMid = text.indexOf(" ", middle);

  if (beforeMid === -1 && afterMid === -1) {
    return [text]; // si el string no tiene espacios, devolvemos el string completo
  }

  if (beforeMid === -1) beforeMid = text.length;
  if (afterMid === -1) afterMid = text.length;

  // elegimos la posición más cercana al medio para dividir
  const splitAt = middle - beforeMid < afterMid - middle ? beforeMid : afterMid;

  return [text.substring(0, splitAt), text.substring(splitAt).trim()];
};

const generatePassword = () => {
  const length = 8;

  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=<>?";

  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);

    password += charset[randomIndex];
  }

  return password;
};

const AgregarInvitado = ({ datosAsistente, setInvitadoAgregado }) => {
  const [nombre, apellidos] = splitStringAtMiddle(
    datosAsistente.nombreCompleto
  );

  const [formValues, setFormValues] = useState({
    nombre,
    apellidos,
    correo: datosAsistente.correoEmpresa || datosAsistente.correoPersonal,
    contrasenia: generatePassword(),
    nombreUsuario:
      datosAsistente.correoEmpresa || datosAsistente.correoPersonal,
    idLicencia: 0,
  });

  const [licenciasInvitado, setLicenciasInvitado] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addUsuarioInvitado(formValues);

      setInvitadoAgregado(true);

      Notificacion("success", "Usuario invitado agregado correctamente");
    } catch (error) {
      console.log("Error al agregar al usuario invitado: ", error);

      NotificationManager.error(
        "No se pudo agregar al usuario invitado. \n" + error.message
      );
    }
  };

  const handleSeleccionLicencia = ({ target }) => {
    const idLicencia = parseInt(target.value);

    setFormValues({
      ...formValues,
      idLicencia,
    });
  };

  useEffect(() => {
    const consultarLicencias = async () => {
      try {
        let { data: licenciasInvitado } = await getLicenciasInvitado();

        if (!Array.isArray(licenciasInvitado)) {
          licenciasInvitado = [licenciasInvitado];
        }

        setLicenciasInvitado(licenciasInvitado);
      } catch (error) {
        console.log("Error al consultar las licencias de invitado: ", error);
      }
    };

    consultarLicencias();

    const auxNombreCompleto = datosAsistente.nombreCompleto.split("-");
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid className="text-start">
        <Row>
          <Col>
            <ul className="list-unstyled">
              <li className="mb-2">
                <strong>Nombre completo: </strong>
                {`${nombre} ${apellidos}`}
              </li>

              <li className="mb-2">
                <strong>Correo electrónico: </strong>
                {formValues.correo}
              </li>

              <li className="mb-2">
                <strong>Nombre de usuario asignado: </strong>
                {formValues.nombreUsuario}
              </li>

              <li className="mb-2">
                <strong>
                  Se generará una contraseña segura para el usuario y será
                  enviada en un correo electrónico.
                </strong>
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col}>
            <Form.Label>Elige una licencia de invitado</Form.Label>

            <Form.Control as="select" onChange={handleSeleccionLicencia}>
              <option value="">--Selecciona una licencia--</option>

              {licenciasInvitado.length > 0 &&
                licenciasInvitado.map((licencia, idx) => (
                  <option key={idx} value={licencia.idLicencia}>
                    {licencia.nombre}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <div className="d-grid gap-2">
              <Button variant="success" type="submit" size="lg">
                Agregar usuario invitado
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AgregarInvitado;
