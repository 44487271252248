import "@assets/css/components/Home/style.css";

import { Col, Container, Row } from "react-bootstrap";
import React, { useContext } from "react";

import AulaVirtual from "@components/general/AulaVirtual/AulaVirtual";
import ConsejosRapidos from "@components/general/ConsejosRapidos/ConsejosRapidos";
import { DashboardFAQ } from "@components/usuario/DashboardFAQ/DashboardFAQ";
import ForoEmpresarial from "@components/general/ForoEmpresarial/ForoEmpresarial";
import { Home } from "@components/general/Home/Home";
import ListadoCursos from "../Cursos/ListadoCursos";
import MultiViewPanelAdministracionProvider from "@context/MultiViewPanelAdministracion/MultiViewPanelAdministracionProvider";
import PanelAdministracion from "@components/administrador/PanelAdministracion/PanelAdministracion";
import { Perfil } from "@components/general/Perfil/Perfil";
import RegistroSegundoUsuario from "@components/usuario/RegistroSegundoUsuario/RegistroSegundoUsuario";
import RutasDeAprendizajes from "../RutasAprendizaje/RutasDeAprendizaje";
import UserContext from "@context/User/UserContext";
import UsuarioLogueadoHome from "../HomeDefault/HomeDefault";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import ForoAyuda from "../ForoAyuda/ForoAyuda";
import CursoPropedeutico from "../CursoPropedeutico/CursoPropedeutico";

import { Element, animateScroll as scroll } from "react-scroll";
import { useRef } from "react";
import { useState } from "react";
import ViewCurso from "@components/usuario/ViewCurso/ViewCurso";

import MisPagos from "@components/usuario/MisPagos/MisPagos";
import MisAvances from "../MisAvances/MisAvances";
import MaterialVistoCapacitacion from "../MaterialVistoCapacitacion/MaterialVistoCapacitacion";
import ExamenDiagnostico from "../ExmanDiagnostico/ExamenDiagnostico";
import SolicitudLlamadaVenta from "@components/invitado/SolicitudLlamadaVenta/SolicitudLlamadaVenta";
import HomeInvitado from "@components/invitado/HomeInvitado/HomeInvitado";
import HomeLecturaVeloz from "@components/lecturaVeloz/HomeLecturaVeloz/HomeLecturaVeloz";
import ChatOpenAI from "../ChatOpenAI/ChatOpenAI";
import PlantillasTrabajo from "../PlantillasTrabajo/PlantillasTrabajo";
import { Pomodoro } from "../Pomodoro/Pomodoro";
import SuiteIA from "@components/general/SuiteIA/SuiteIA";
import Misiones from "../Misiones/Misiones";
import SalaEntretenimiento from "../SalaEntretenimiento/SalaEntretenimiento";
import AgendasAsesorias from "../AgendasAsesorias/AgendasAsesorias";

const MultiView = ({
	view,
	handleChangeComponent,
	handleStartTour,
	setTemaSeleccionado,
	setModoConcentracion,
	modoConcentracion,
	setTimerMode,
	timerMode,
	setShortLength,
	shortLength,
	setLongLength,
	longLength,
	setSecondsLeft,
	secondsLeft,
	formatTimeLeft,
	calcPercentage,
	setIsActive,
	isActive,
	setButtonText,
	buttonText,
	timesUp,
	volume,
	setVolume,
	setPomoLength,
	pomoLength,
	setFichaUsada,
	fichaUsada,
	setFichaDisponible,
	fichaDisponible,
	time,
}) => {
	//USE REF Y USE STATE PARA MOSTRAR EL BOTON DE SCROLLTOTOP
	const containerRef = useRef(null);

	const [showScrollButton, setShowScrollButton] = useState(false);

	const { contextValue: userContextValue, setContextValue: setUserContextValue } = useContext(UserContext);

	const {
		usuarioLogeado: { rol, idGrupo },
	} = userContextValue;

	const validarRol = (rolesPermitidos) => {
		return rolesPermitidos.includes(rol);
	};

	//FUNCION PARA MANEJAR EL SCROLLTOTOP
	const handleScroll = () => {
		const { scrollTop } = containerRef.current.childBindings.domNode;
		if (scrollTop > 200) {
			setShowScrollButton(true);
		} else {
			setShowScrollButton(false);
		}
	};

	const handleScrollToTop = () => {
		// console.log("haciendo scroll ");
		// containerRef.current.childBindings.domNode.scrollTop = 200;
		scroll.scrollTo((containerRef.current.childBindings.domNode.scrollTop = 0), {
			containerId: "scrollTop",
			duration: 1000,
			smooth: "easeInOutQuint",
		});
	};

	return (
		<Element ref={containerRef} onScroll={handleScroll} name="elementTop" className={`element main-content ${modoConcentracion && "main-content__concentracion"}`} id="scrollTop">
			<Element name="scrollTopElement"></Element>

			<Container fluid className={`h-100 p-0 ${view === multiViewComponentsPaths.openAI ? "p-0" : ""}`}>
				<Row className="mx-auto end h-100">
					<Col className={`${view === multiViewComponentsPaths.openAI ? "p-0" : ""}`}>
						{/* Propedeutico MOS  */}
						{view === multiViewComponentsPaths.cursoPropedeuticoMos &&
							validarRol([typesTiposUsuario.asesor, typesTiposUsuario.estudiante, typesTiposUsuario.empleado, typesTiposUsuario.administrador, typesTiposUsuario.capacitador]) && (
								<CursoPropedeutico handleScrollToTop={handleScrollToTop} handleScroll={handleScroll} showScrollButton={showScrollButton} />
							)}

						{/* Inicio */}
						{view === multiViewComponentsPaths.home && (
							<Home
								handleStartTour={handleStartTour}
								handleScrollToTop={handleScrollToTop}
								handleChangeComponent={handleChangeComponent}
								handleScroll={handleScroll}
								showScrollButton={showScrollButton}
							/>
						)}

						{/* Pomodoro */}
						{view === multiViewComponentsPaths.pomodoro && (
							<Pomodoro
								formatTimeLeft={formatTimeLeft}
								calcPercentage={calcPercentage}
								setTimerMode={setTimerMode}
								timerMode={timerMode}
								setShortLength={setShortLength}
								shortLength={shortLength}
								setLongLength={setLongLength}
								longLength={longLength}
								setSecondsLeft={setSecondsLeft}
								secondsLeft={secondsLeft}
								setIsActive={setIsActive}
								isActive={isActive}
								setButtonText={setButtonText}
								buttonText={buttonText}
								timesUp={timesUp}
								volume={volume}
								setVolume={setVolume}
								setPomoLength={setPomoLength}
								pomoLength={pomoLength}
							/>
						)}

						{/* Home invitado */}
						{view === multiViewComponentsPaths.homeInvitado && validarRol([typesTiposUsuario.invitado, typesTiposUsuario.cortesia]) && (
							<HomeInvitado
								// handleStartTour={handleStartTour}
								handleScrollToTop={handleScrollToTop}
								handleChangeComponent={handleChangeComponent}
								handleScroll={handleScroll}
								showScrollButton={showScrollButton}
							/>
						)}

						{/* Home Lectura Veloz */}
						{view === multiViewComponentsPaths.homeLecturaVeloz && validarRol([typesTiposUsuario.lecturaVeloz]) && (
							<HomeLecturaVeloz
								// handleStartTour={handleStartTour}
								handleScrollToTop={handleScrollToTop}
								handleChangeComponent={handleChangeComponent}
								handleScroll={handleScroll}
								showScrollButton={showScrollButton}
							/>
						)}

						{/* Inicio usuario logeado */}
						{view === multiViewComponentsPaths.usuarioLogueadoHome && <UsuarioLogueadoHome />}

						{/* Perfil */}
						{view === multiViewComponentsPaths.miPerfil && <Perfil />}

						{/* Examen Diagnostico */}
						{view === multiViewComponentsPaths.examenDiagnostico && (
							<ExamenDiagnostico handleScroll={handleScroll} showScrollButton={showScrollButton} handleScrollToTop={handleScrollToTop} handleChangeComponent={handleChangeComponent} />
						)}

						{/* Registro 2° Usuario */}
						{view === multiViewComponentsPaths.registroSegundoUsuario && validarRol([typesTiposUsuario.estudiante]) && <RegistroSegundoUsuario />}

						{/* Mis cursos */}
						{/* { view === multiViewComponentsPaths.misCursos && <MisCursos /> } */}
						{view === multiViewComponentsPaths.misCursos && <ListadoCursos handleScrollToTop={handleScrollToTop} showScrollButton={showScrollButton} handleChangeComponent={handleChangeComponent} />}

						{/* Plantillas de Trabajo */}
						{view === multiViewComponentsPaths.plantillasTrabajo && (
							<PlantillasTrabajo handleScrollToTop={handleScrollToTop} showScrollButton={showScrollButton} handleChangeComponent={handleChangeComponent} />
						)}

						{/* ChatGPT */}
						{view === multiViewComponentsPaths.openAI && <ChatOpenAI handleScrollToTop={handleScrollToTop} showScrollButton={showScrollButton} handleChangeComponent={handleChangeComponent} />}

						{/* SuiteIA */}
						{view === multiViewComponentsPaths.suiteAI && <SuiteIA handleScrollToTop={handleScrollToTop} showScrollButton={showScrollButton} handleChangeComponent={handleChangeComponent} />}

						{/* Curso */}
						{view === multiViewComponentsPaths.curso && (
							<ViewCurso
								modoConcentracion={modoConcentracion}
								setModoConcentracion={setModoConcentracion}
								handleChangeComponent={handleChangeComponent}
								handleScroll={handleScroll}
								handleScrollToTop={handleScrollToTop}
								showScrollButton={showScrollButton}
							/>
						)}

						{/* Agenda de asesorías */}
						{view === multiViewComponentsPaths.agendasAsesorias && <AgendasAsesorias />}

						{/* Consejos Rápidos */}
						{view === multiViewComponentsPaths.consejosRapidos && <ConsejosRapidos handleChangeComponent={handleChangeComponent} />}

						{/* Foro de Ayuda */}
						{view === multiViewComponentsPaths.foroAyuda && <ForoAyuda handleScroll={handleScroll} showScrollButton={showScrollButton} handleScrollToTop={handleScrollToTop} />}

						{/* Rutas de Aprendizaje */}
						{view === multiViewComponentsPaths.rutasAprendizaje && <RutasDeAprendizajes handleScroll={handleScroll} showScrollButton={showScrollButton} handleScrollToTop={handleScrollToTop} />}

						{/* Mis Pagos */}
						{view === multiViewComponentsPaths.misPagos && validarRol([typesTiposUsuario.administrador, typesTiposUsuario.estudiante, typesTiposUsuario.asesor, typesTiposUsuario.lecturaVeloz]) && (
							<MisPagos />
						)}

						{/* Aula Virtual */}
						{view === multiViewComponentsPaths.aulaVirtual && <AulaVirtual handleScroll={handleScroll} showScrollButton={showScrollButton} />}

						{/* Mis Avances */}
						{view === multiViewComponentsPaths.misAvances && (
							<MisAvances handleScroll={handleScroll} showScrollButton={showScrollButton} handleScrollToTop={handleScrollToTop} handleChangeComponent={handleChangeComponent} />
						)}

						{/* Centro de Ayuda - FAQ */}
						{view === multiViewComponentsPaths.centroAyuda && <DashboardFAQ showScrollButton={showScrollButton} />}

						{/* Material visto en capacitacion */}
						{view === multiViewComponentsPaths.materialCapacitacion && <MaterialVistoCapacitacion handleChangeComponent={handleChangeComponent} />}

						{/* Misiones */}
						{view === multiViewComponentsPaths.misiones && <Misiones handleChangeComponent={handleChangeComponent} />}

						{/* Sala de entretenimiento */}
						{view === multiViewComponentsPaths.salaEntretenimiento && (
							<SalaEntretenimiento
								handleChangeComponent={handleChangeComponent}
								setFichaUsada={setFichaUsada}
								fichaUsada={fichaUsada}
								setFichaDisponible={setFichaDisponible}
								fichaDisponible={fichaDisponible}
								time={time}
							/>
						)}

						{/* Reportes */}
						{/* { view === multiViewComponentsPaths.reportes validarRol([ typesTiposUsuario.asesor, typesTiposUsuario.administrador ]) && <Reportes /> } */}

						{/* Foro Empresarial */}
						{view === multiViewComponentsPaths.foroEmpresarial &&
							(validarRol([typesTiposUsuario.asesor, typesTiposUsuario.administrador, typesTiposUsuario.empleado, typesTiposUsuario.capacitador]) || idGrupo !== null) && <ForoEmpresarial />}

						{/* Panel de Administración */}
						{view === multiViewComponentsPaths.panelAdministracion && validarRol([typesTiposUsuario.administrador, typesTiposUsuario.capacitador, typesTiposUsuario.vendedor]) && (
							<MultiViewPanelAdministracionProvider>
								<PanelAdministracion setTemaSeleccionado={setTemaSeleccionado} handleChangeComponent={handleChangeComponent} />
							</MultiViewPanelAdministracionProvider>
						)}

						{view === multiViewComponentsPaths.solicitudLlamadaVenta && validarRol([typesTiposUsuario.invitado, typesTiposUsuario.lecturaVeloz]) && <SolicitudLlamadaVenta />}
					</Col>
				</Row>
			</Container>
		</Element>
	);
};

export default MultiView;
