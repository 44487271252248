import { Notificacion } from "@components/general/Alertas/Alertas";
import { deactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const DesactivarUsuario = ({ setEmpleadoDesactivado, colaborador }) => {
  const { idCredencial, nombreCompleto } = colaborador;
  // console.log(nombreCompleto);

  const cancelarDeshabilitarUsuario = () => {
    Notificacion("info", "No se deshabilitó a " + nombreCompleto);
  };

  const deshabilitarUsuario = async () => {
    try {
      const data = await deactivateUsuario(idCredencial);
      setEmpleadoDesactivado(true);
      Notificacion("success", "Usuario desactivado con exito");
    } catch (error) {
      Notificacion("error", "Error al deshabilitar a " + nombreCompleto);
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de deshabilitar a {nombreCompleto}?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={deshabilitarUsuario}>
            Sí, deshabilitar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeshabilitarUsuario}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DesactivarUsuario;
