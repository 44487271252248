import React from "react";
import { Card, Modal } from "react-bootstrap";
import ListaExamenesModal from "./listaExamenesModal";

const HomeDefaultModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Examenes de diagnóstico disponibles
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListaExamenesModal />
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "flex-start" }}>
        <Card.Img
          variant="top"
          style={{ borderRadius: "10%", width: "150px" }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default HomeDefaultModal;
