import { Notificacion } from "@components/general/Alertas/Alertas";
import { deletePlantillaTrabajo } from "@helpers/httpRequests/httpRequestPlantillasTrabajo/httpRequestPlantillasTrabajo";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarPlantilla = ({ idPlantillaTrabajo, setPlantillaEliminada }) => {
  const handleEliminarPlantilla = async () => {
    try {
      await deletePlantillaTrabajo(idPlantillaTrabajo);

      setPlantillaEliminada(true);

      Notificacion("success", "Plantilla eliminada de la base de datos");
    } catch (error) {
      console.log("Error al dar de baja la plantilla de trabajo: ", error);
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "La plantilla no fue eliminada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro que deseas eliminar esta plantilla?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleEliminarPlantilla}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarPlantilla;
