import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";

import { useEffect } from "react";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Compressor from "compressorjs";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { DropZone } from "@helpers/DropZone/DropZone";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { updatePregunta } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";

export const EditarPregunta = ({ setPreguntaModificada, infoPregunta }) => {
  const {
    pregunta,
    tipoPregunta,
    codigoCurso,
    codigoLeccion,
    idPregunta,
    imagenPregunta,
    opcionesRespuesta,
    puntuacion,
    respuestaCorrecta,
  } = infoPregunta;

  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const [showImagenPregunta, setShowImagenPregunta] = useState(true);

  const [tipoDePregunta, setTipoDePregunta] = useState({
    opcionMultiple: false,
    verdaderoFalso: false,
  });

  const [mostrarAlert, setMostrarAlert] = useState({
    error: false,
    nuevaSeccionYLeccion: false,
  });

  const [formValues, setFormValues] = useState({
    codigoCurso,
    nombreCurso: "",
    nombreSeccion: "",
    codigoLeccion,
    nombreLeccion: "",
    pregunta,
    respuestaCorrecta,
    radioFalso: "FALSO",
    radioVerdadero: "VERDADERO",
    puntuacion,
    imagenPregunta,
    tipoPregunta,
  });

  const handleTipoPregunta = ({ target }) => {
    const { value } = target;

    if (value === "Opcion multiple") {
      setFormValues({
        ...formValues,
        tipoPregunta: value,
      });

      setTipoDePregunta({
        ...tipoDePregunta,
        opcionMultiple: true,
        verdaderoFalso: false,
      });
    } else if (value === "Verdadero/Falso") {
      setFormValues({
        ...formValues,
        tipoPregunta: value,
      });
      setTipoDePregunta({
        ...tipoDePregunta,
        verdaderoFalso: true,
        opcionMultiple: false,
      });
    }
  };

  const cleanImagenPregunta = () => {
    setFormValues({
      ...formValues,
      imagenPregunta: "",
    });

    setShowImagenPregunta(false);
  };

  const getBase64Image = ({ target }) => {
    try {
      const { files } = target;

      const [file] = files;

      const fileReader = new FileReader();

      if (!file) {
        return Notificacion(
          "warning",
          "Ups! Algo salió mal, vuelve a cargar tu foto"
        );
      }

      //!codigo para comparacion de peso de imagen antes de ser comprimida
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.2,
        success(result) {
          fileReader.readAsDataURL(result);

          fileReader.onload = async function () {
            setFormValues({ ...formValues, imagenPregunta: fileReader.result });

            setShowImagenPregunta(true);
          };
        },
        error(e) {
          console.log(e);

          Notificacion(
            "warning",
            "Ups!, algo salió mal, vuelve a cargar tu foto"
          );
        },
      });
    } catch (e) {
      Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
    }
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === "codigoCurso" || name === "codigoSeccion") {
      handleCambiosEnSelect(name, value);
    }
  };

  const handleCambiosEnSelect = async (name, value) => {
    try {
      if (name === "codigoCurso") {
        //Lógica para consultar las secciones que correspondan al curso seleccionado

        const { data } = await getSeccionesByCodigoCurso(value);
        setSecciones(data);
        if (value !== formValues.codigoCurso) {
          setMostrarAlert({
            ...mostrarAlert,
            error: false,
            nuevaSeccionYLeccion: true,
          });
        }
      }
      if (name === "codigoSeccion") {
        //Lógica para consultar las lecciones correspondientes a la seccion seleccionada

        const { data: lecciones } = await getLeccionesByCodigoSeccion(value);
        setLecciones(lecciones);
      }
    } catch (error) {
      console.log(error);
      setSecciones([]);
      setLecciones([]);
      setMostrarAlert({
        ...mostrarAlert,
        error: true,
      });
    }
  };

  const modificarPregunta = async (e) => {
    e.preventDefault();
    try {
      const informacionPregunta = {
        idPregunta,
        pregunta: formValues.pregunta,
        tipoPregunta: formValues.tipoPregunta,
        opcionesRespuesta,
        imagenPregunta: formValues.imagenPregunta,
        respuestaCorrecta: formValues.respuestaCorrecta,
        puntuacion: formValues.puntuacion,
        codigoCurso: formValues.codigoCurso,
        codigoLeccion: formValues.codigoLeccion,
      };

      const { message } = await updatePregunta(informacionPregunta);

      Notificacion("success", message);
      setPreguntaModificada(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const obtenerCursosSeccionesLecciones = async () => {
      try {
        const seccion = codigoLeccion.substring(4, 6);
        const codigoSeccion = codigoCurso + seccion;

        const { data: cursos } = await getCursos();
        setCursos(cursos);
        const cursoActual = cursos.filter(
          (curso) => curso.codigoCurso === codigoCurso
        );
        const { nombre: nombreCurso } = cursoActual[0];

        const { data: seccionesPorCurso } = await getSeccionesByCodigoCurso(
          codigoCurso
        );
        setSecciones(seccionesPorCurso);

        const seccionActual = seccionesPorCurso.filter(
          (seccion) => seccion.codigoSeccion === codigoSeccion
        );
        const { nombre: nombreSeccion } = seccionActual[0];

        const { data: leccionesPorSeccion } = await getLeccionesByCodigoSeccion(
          codigoSeccion
        );
        setLecciones(leccionesPorSeccion);

        const leccionesPorSeccionFiltradas = leccionesPorSeccion.filter(
          (leccion) => leccion.codigoLeccion === codigoLeccion
        );
        const { nombre: nombreLeccion } = leccionesPorSeccionFiltradas[0];

        setFormValues({
          ...formValues,
          nombreCurso,
          nombreSeccion,
          nombreLeccion: nombreLeccion,
        });
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursosSeccionesLecciones();

    if (tipoPregunta === "Opcion multiple") {
      setTipoDePregunta({
        ...tipoDePregunta,
        opcionMultiple: true,
      });
      const opciones = JSON.parse(opcionesRespuesta);
      const {
        1: opcionRespuesta1,
        2: opcionRespuesta2,
        3: opcionRespuesta3,
      } = opciones;

      setFormValues({
        ...formValues,
        opcionRespuesta1,
        opcionRespuesta2,
        opcionRespuesta3,
      });
    }

    if (tipoPregunta === "Verdadero/Falso") {
      setTipoDePregunta({
        ...tipoDePregunta,
        verdaderoFalso: true,
      });
    }
  }, []);

  return (
    <>
      <Form onSubmit={modificarPregunta} className="texto-izquierda m-0 m-sm-4">
        <Container>
          <Row>
            {/* Alerta para en caso de no encontrar datos de un curso o lección */}
            {mostrarAlert.error && (
              <Alert variant="danger">
                No se encontrarón secciones o lecciones relacionadas, selecciona
                otro curso.
              </Alert>
            )}
            {mostrarAlert.nuevaSeccionYLeccion && (
              <Alert variant="warning">
                Se ha elegido un nuevo curso para la pregunta, elige una nueva
                sección y una nueva lección para poder modificarlo
                correctamente.
              </Alert>
            )}

            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Curso <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  as={"select"}
                  required
                  name="codigoCurso"
                >
                  <option defaultValue disabled value="0">
                    {formValues.nombreCurso} - Curso actual
                  </option>
                  {cursos.map((curso, idx) => (
                    <option key={idx} value={curso.codigoCurso}>
                      {curso.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6} className="align-self-center">
              <p>
                <span className="text-danger">(* Campo requerido )</span>
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Sección <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  as={"select"}
                  required
                  name="codigoSeccion"
                >
                  <option selected disabled value="0">
                    {formValues.nombreSeccion} - Sección actual
                  </option>
                  {secciones.map((seccion, idx) => (
                    <option key={idx} value={seccion.codigoSeccion}>
                      {seccion.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>
                  Lección <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  as={"select"}
                  required
                  name="codigoLeccion"
                >
                  <option selected disabled value="0">
                    {formValues.nombreLeccion} - Lección actual
                  </option>
                  {lecciones.map((leccion, idx) => (
                    <option key={idx} value={leccion.codigoLeccion}>
                      {leccion.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md={9}>
              <Form.Group>
                <Form.Label>
                  Pregunta <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  type="text"
                  required
                  name="pregunta"
                  value={formValues.pregunta}
                  placeholder="Haz tu pregunta"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>
                  Puntuación <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  type="number"
                  required
                  name="puntuacion"
                  value={formValues.puntuacion}
                  placeholder="Puntuación de la respuesta"
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label>
                Tipo de pregunta <span className="text-danger">*</span>
              </Form.Label>
              <Form.Group className="mb-4">
                <Form.Check
                  checked={
                    formValues.tipoPregunta === "Opcion multiple" ? true : false
                  }
                  required
                  onChange={handleTipoPregunta}
                  inline
                  label="Opción Multiple"
                  value="Opcion multiple"
                  name="group1"
                  type={"radio"}
                  id={`inline-${"radio"}-1`}
                />

                <Form.Check
                  checked={
                    formValues.tipoPregunta === "Verdadero/Falso" ? true : false
                  }
                  required
                  onChange={handleTipoPregunta}
                  inline
                  label="Verdadero/Falso"
                  value="Verdadero/Falso"
                  name="group1"
                  type={"radio"}
                  id={`inline-${"radio"}-2`}
                />
              </Form.Group>

              {tipoDePregunta.opcionMultiple && (
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Opción #1 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        onChange={handleOnChange}
                        value={formValues.opcionRespuesta1}
                        type="text"
                        required
                        name="opcionRespuesta1"
                        placeholder="Primera opción"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Opción #2 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        onChange={handleOnChange}
                        value={formValues.opcionRespuesta2}
                        type="text"
                        required
                        name="opcionRespuesta2"
                        placeholder="Segunda opción"
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Opción #3 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        onChange={handleOnChange}
                        value={formValues.opcionRespuesta3}
                        type="text"
                        required
                        name="opcionRespuesta3"
                        placeholder="Tercera opción"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Respuesta Correcta{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        required
                        onChange={handleOnChange}
                        value={formValues.respuestaCorrecta}
                        as={"select"}
                        name="respuestaCorrecta"
                        placeholder="Selecciona una respuesta"
                      >
                        <option selected disabled value="0">
                          {formValues.respuestaCorrecta} - Respuesta actual
                        </option>
                        {(formValues.opcionRespuesta1 !== "",
                        formValues.opcionRespuesta2 !== "",
                        formValues.opcionRespuesta3 !== "") && (
                          <>
                            <option value={formValues.opcionRespuesta1}>
                              {formValues.opcionRespuesta1}
                            </option>
                            <option value={formValues.opcionRespuesta2}>
                              {formValues.opcionRespuesta2}
                            </option>
                            <option value={formValues.opcionRespuesta3}>
                              {formValues.opcionRespuesta3}
                            </option>
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {tipoDePregunta.verdaderoFalso && (
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Opción #1 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        disabled
                        type="text"
                        required
                        name="radioFalso"
                        placeholder="FALSO"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Opción #2 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        disabled
                        type="text"
                        required
                        name="radioVerdadero"
                        placeholder="VERDADERO"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label>
                      Respuesta correcta <span className="text-danger">*</span>
                    </Form.Label>

                    <Form.Group className="mb-4">
                      <Form.Check
                        checked={
                          formValues.respuestaCorrecta === "VERDADERO"
                            ? true
                            : false
                        }
                        required
                        onChange={handleOnChange}
                        value="VERDADERO"
                        label="VERDADERO"
                        name="respuestaCorrecta"
                        type={"radio"}
                        id={`inline-${"radio"}-3`}
                      />
                      <Form.Check
                        checked={
                          formValues.respuestaCorrecta === "FALSO"
                            ? true
                            : false
                        }
                        required
                        onChange={handleOnChange}
                        value="FALSO"
                        label="FALSO"
                        name="respuestaCorrecta"
                        type={"radio"}
                        id={`inline-${"radio"}-4`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {(showImagenPregunta, formValues.imagenPregunta !== "") ? (
                <Container className="mt-4 mb-4">
                  <Row>
                    <Col className="text-center">
                      <img
                        src={formValues.imagenPregunta}
                        alt="Imagen de pregunta"
                        width={180}
                        className="img-fluid"
                      ></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="col-6 text-center mt-2">
                      <Button variant="danger" onClick={cleanImagenPregunta}>
                        <FontAwesomeIcon className="pointer" icon={faTrash} />
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Form.Group className="mb-4">
                  <Form.Label>
                    Imagen de pregunta <span className="text-danger">*</span>
                  </Form.Label>
                  <DropZone getImageInBase64={getBase64Image} />
                </Form.Group>
              )}

              <div className="d-grid gap-2 mt-4">
                <Button type="submit" className="mt-4" size="lg">
                  Modificar pregunta
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};
