import React from "react";
import ForoAyuda from "@components/general/ForoAyuda/ForoAyuda";

export const DashboardForoAyuda = () => {
  return (
    <>
      <ForoAyuda />
    </>
  );
};
