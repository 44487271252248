const multiViewComponentsPaths = {
  aulaVirtual: "aulaVirtual",
  centroAyuda: "faq",
  consejosRapidos: "consejosRapidos",
  foroAyuda: "foroAyuda",
  foroEmpresarial: "foroEmpresarial",
  home: "home",
  homeInvitado: "homeInvitado",
  homeLecturaVeloz: "homeLecturaVeloz",
  curso: "curso",
  // listadoCursos: "listadoCursos",
  // miPerfil: "miPerfil", DEBE DE QUEDAR ASI
  miPerfil: "perfil",
  misAvances: "misAvances",
  misCursos: "misCursos",
  plantillasTrabajo: "plantillasTrabajo",
  misPagos: "misPagos",
  panelAdministracion: "panelAdministracion",
  registroSegundoUsuario: "registroSegundoUsuario",
  reportes: "reportes",
  rutasAprendizaje: "rutasAprendizaje",
  generarqr: "generarqr",
  // routeLearningView: "routeLearningView",
  usuarioLogueadoHome: "usuarioLogueadoHome",
  cursoPropedeuticoMos: "cursoPropedeuticoMos",
  materialCapacitacion: "materialCapacitacion",
  examenDiagnostico: "examenDiagnostico",
  solicitudLlamadaVenta: "solicitudLlamadaVenta",
  openAI: "chat-ia",
  suiteAI: "suite-ia",
  pomodoro: "pomodoro",
  misiones: "misiones",
  salaEntretenimiento: "sala-entretenimiento",
  agendasAsesorias: 'agendas-asesorias'
};

export default multiViewComponentsPaths;
