import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import React, { useContext, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerRing from "@components/general/Spinners/SpinnerRing/SpinnerRing";
import UserContext from "@context/User/UserContext";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { getGrupoByFolioContrato } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { handleCurpAPI } from "@helpers/validaciones/validaciones";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import useWindowResize from "@customHooks/useWindowResize";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import { validateDisponibilidadNombreUsuario } from "@helpers/httpRequests/httpRequestsValidaciones/httpRequestsValidaciones";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import { addUsuarioEmpresarial } from "@helpers/httpRequests/httpRequestsUsuarios/registro/httpRequestsRegistroUsuarios";

// import '@assets/css/components/RegistroUsuario/style.css';

const FormularioRegistroEmpleado = ({ history, location }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const [spinnerCurp, setSpinnerCurp] = useState(false);

	const [datosEmpresa, setDatosEmpresa] = useState({
		nombreEmpresa: "",
		licenciasRestantes: "",
	});

	const [formValues, setFormValues] = useState({
		folioContrato: contextValue.folioContrato,
		nombre: "",
		apellidos: "",
		correo: "",
		nombreUsuario: "",
		contrasenia: "",
		confirmacionContrasenia: "",
		puestoTrabajo: "",
		curp: "",
		avatarPerfil: "avatar.png",
	});

	const [validacion, setValidacion] = useState({
		nombre: false,
		apellidos: false,
		correo: false,
		nombreUsuario: false,
		contrasenia: false,
		confirmacionContrasenia: false,
		puestoTrabajo: false,
		curp: false,
		// avatarPerfil: true,
		caracteresContrasenia: false,
		mayusculaContrasenia: false,
		numeroContrasenia: false,
	});

	const [showLoadingIcon, setShowLoadingIcon] = useState(false);

	const [showPasswords, setShowPasswords] = useState(false);

	const refs = {
		correo: useRef(null),
		curp: useRef(null),
		inputContrasenia: useRef(null),
	};

	const isCapsLockOn = useCapsLockWatcher(refs.inputContrasenia);

	const handleOnChange = async ({ target }) => {
		try {
			const { name, value } = target;

			setFormValues({
				...formValues,
				[name]: value,
			});

			setValidacion({
				...formValues,
				[name]: value === "" ? false : true,
			});

			switch (true) {
				case target.name === "correo":
					setValidacion({
						...validacion,
						correo: regExpStrings.regexEmail(formValues.correo),
					});
					break;

				case target.name === "nombreUsuario":
					if (target.value === "") {
						return setValidacion({
							...validacion,
							nombreUsuario: false,
						});
					}

					// if (target.value === administrador.nombreUsuario) {
					// 	return setValidacion({
					// 		...validacion,
					// 		nombreUsuario: true,
					// 	});
					// }

					if (target.value.length < 8) {
						return setValidacion({
							...validacion,
							nombreUsuario: false,
						});
					}

					try {
						const { data: validacionNombreUsuario } = await validateDisponibilidadNombreUsuario(target.value);

						setValidacion({
							...validacion,
							nombreUsuario: validacionNombreUsuario,
						});
					} catch (error) {
						const { data: usuarioNoDisponible } = error;

						setValidacion({
							...validacion,
							nombreUsuario: usuarioNoDisponible,
						});
					}
					break;

				default:
					break;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnChangeCorreo = async ({ target }) => {
		try {
			const { value: correo } = target;

			setFormValues({
				...formValues,
				correo,
			});

			if (regExpStrings.regexEmail(correo)) {
				// setFormValues({
				// 	...formValues,
				// 	correo,
				// 	nombreUsuario: correo,
				// });

				setValidacion({
					...validacion,
					correo: true,
					// nombreUsuario: true,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnChangePasswords = async ({ target }) => {
		try {
			const { name, value } = target;

			if (name === "contrasenia") {
				setFormValues({
					...formValues,
					contrasenia: value,
				});

				const validacionesContrasenia = await validarRestriccionesContrasenia({
					contrasenia: value,
				});

				setValidacion({
					...validacion,
					...validacionesContrasenia,
				});
			} else if (name === "confirmacionContrasenia") {
				setFormValues({
					...formValues,
					confirmacionContrasenia: value,
				});

				let contraseniasCoincidentes = false;

				if (value === formValues.contrasenia) {
					contraseniasCoincidentes = true;
				}

				setValidacion({
					...validacion,
					confirmacionContrasenia: contraseniasCoincidentes,
				});
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const handleOnChangeCurp = async ({ target }) => {
		try {
			const { name, value } = target;

			const curp = value.toUpperCase();

			setFormValues({ ...formValues, [name]: curp });

			if (curp.length === 18) {
				setSpinnerCurp(true);

				const datosCurp = await handleCurpAPI(curp);

				if (!datosCurp.validacion) {
					setSpinnerCurp(false);

					setValidacion({ ...validacion, curp: false });

					return Notificacion("error", "No se encontró información de tu CURP, ingresa los datos manualmente.");
				}

				setSpinnerCurp(false);

				setFormValues({
					...formValues,
					// nombre: datosCurp.data.Nombres,
					// apellidos: `${ datosCurp.data.ApellidoPaterno } ${ datosCurp.data.ApellidoMaterno }`,
					curp,
				});

				setValidacion({
					...validacion,
					// nombre: true,
					// apellidos: true,
					curp: true,
				});
			}
		} catch (e) {
			// Notificacion('error', e.message);
			console.log(e);
		}
	};

	const validarCamposVacios = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const aux = formValues;

				for (const formValue in aux) {
					if (Object.hasOwnProperty.call(aux, formValue)) {
						const element = aux[formValue];

						if (element === "") {
							return setValidacion({ ...validacion, [formValue]: false });
						}
					}
				}

				// console.log('no hay campos vacios');

				resolve(true);
			} catch (error) {
				console.log("error validando campos vacios: ", error);

				reject(false);
			}
		});
	};

	const agregarNuevoUsuarioEmpresarial = async () => {
		// console.log('agregando usuario empresarial');
		// console.log(formValues)
		try {
			const respuestaRegistro = await addUsuarioEmpresarial(formValues);

			const [datosUsuario] = respuestaRegistro[0];

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuario,
				registroEmpresarial: false,
			});

			Notificacion("success", "¡Tu registro se completó con éxito!");

			history.push(dashboardRoutesPaths.intereses);
		} catch (error) {
			console.log("Error al agregar un nuevo usuario empresarial: ", error);

			NotificacionExtendida("error", error.message);

			setShowLoadingIcon(false);

			const { data } = error;

			if (data.category && data.category === "duplicated") {
				let propName = "";

				if (data.code === "used_curp") {
					propName = "curp";
				}

				if (data.code === "used_username") {
					propName = "correo";
				}

				if (propName !== "") {
					refs[propName].current.focus();

					setValidacion({
						...validacion,
						[propName]: false,
					});
				}
			}
		}

		// console.log(jsonUserEnterprise);

		// if (jsonUserEnterprise.code === 200) {
		// 	let idCredencial = jsonUserEnterprise.data.idCredencial;

		// 	let tipoUsuario = Usuarios.empleado;

		// 	let json = await getDataUserLogged(idCredencial, tipoUsuario);

		// 	let auxState = contextValue;

		// 	let dataUser = [json.data[0]];

		// 	auxState.loggedUserData = {
		// 		dataUser: dataUser,
		// 	};

		// 	setContextValue(auxState);

		// 	NotificacionExtendida('success', `¡Felicidades ${formValues.nombre}!, ahora formas parte de Click+`);

		// 	// history.replace('/dashboard');
		// 	history.push('/intereses');
		// } else if (jsonUserEnterprise.code === 500) {
		// 	Notificacion('error', `Algo salió mal, contacta a soporte técnico`);
		// } else if (jsonUserEnterprise.code === 409) {
		// 	Notificacion('info', `Tu asesor aun no ah creado un grupo para ti`);
		// } else {
		// 	Notificacion('info', 'Revisa tu formulario e intenta de nuevo');
		// }
	};

	const handleShowPasswords = () => {
		setShowPasswords(!showPasswords);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setShowLoadingIcon(true);

		try {
			await validarCamposVacios();
		} catch (error) {
			console.log(error);

			Notificacion("info", "Por favor, llena todos los campos para continuar");

			setShowLoadingIcon(false);
		}

		try {
			await agregarNuevoUsuarioEmpresarial();
		} catch (error) {
			Notificacion("error", "Algo sucedió al intentar registrarte. Vuelve a intentar o comunícate con Soporte Técnico");

			setShowLoadingIcon(false);
		}
	};

	useEffect(() => {
		if (formValues.folioContrato === undefined) {
			return history.replace(dashboardRoutesPaths.login);
		}

		const handleDatosEmpresa = async () => {
			try {
				const { data: datosEmpresa } = await getGrupoByFolioContrato(formValues.folioContrato);

				setDatosEmpresa({
					...datosEmpresa,
					nombreEmpresa: datosEmpresa.nombreEmpresa,
					licenciasRestantes: datosEmpresa.cantidadEstudiantes,
					logotipo: datosEmpresa.logotipo,
					estudiantesRestantes: datosEmpresa.estudiantesRestantes,
					cantidadEstudiantes: datosEmpresa.cantidadEstudiantes,
				});
			} catch (error) {
				Notificacion("error", "Algo sucedió al consultar los datos de tu empresa, comunícate con Soporte Técnico");
			}
		};

		handleDatosEmpresa();
	}, []);

	const {
		size: { width },
	} = useWindowResize();

	return (
		<Container fluid className="formRegistro" style={{ overflowY: "scroll" }}>
			<Row className={`justify-content-center align-items-center py-4 ${width >= 768 && "vh-100"}`}>
				<Col sm={12} md={8}>
					<Card style={{ border: "none" }} className="h-100">
						<Card.Header className="bg-dark text-white">
							<h2>Registro Empresarial</h2>
							<p>Llena los siguientes datos para finalizar con tu registro</p>

							<Row>
								{datosEmpresa.logotipo !== "" && (
									<Col xs="auto" className="align">
										<img src={datosEmpresa.logotipo} alt="Logo de la empresa" className="img-fluid" />
									</Col>
								)}

								<Col className="align">
									{datosEmpresa.nombreEmpresa && <h4>{datosEmpresa.nombreEmpresa}</h4>}

									{datosEmpresa.nombreAsesor && <h6>Asesor: {datosEmpresa.nombreAsesor}</h6>}

									{formValues.folioContrato && <h6>Folio asignado: {formValues.folioContrato}</h6>}

									<h6>
										{datosEmpresa.estudiantesRestantes}/{datosEmpresa.cantidadEstudiantes} Licencias restantes
									</h6>
								</Col>
							</Row>
						</Card.Header>

						<Card.Body>
							<Form onSubmit={handleSubmit}>
								<Row>
									<Form.Group as={Col} md={6}>
										<Form.Label>CURP</Form.Label>

										<Form.Control
											className={`${spinnerCurp ? "searching-curp" : ""} ${!validacion.curp ? "is-invalid" : ""}`}
											ref={refs.curp}
											name="curp"
											type="text"
											value={formValues.curp}
											placeholder="Ingresa tu CURP"
											maxLength="18"
											onChange={handleOnChangeCurp}
										/>

										<Form.Control.Feedback type="invalid">Ingresa tu CURP</Form.Control.Feedback>
									</Form.Group>

									<Form.Group as={Col} md={6}>
										<Form.Label>Nombre(s)</Form.Label>

										<Form.Control
											className={!validacion.nombre ? "is-invalid" : ""}
											name="nombre"
											type="text"
											placeholder="Ingresa tu nombre(s)"
											onChange={handleOnChange}
											autoComplete="off"
											value={capitalizeString(formValues.nombre)}
										/>

										<Form.Control.Feedback type="invalid">Ingresa tu nombre</Form.Control.Feedback>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group as={Col} md={6}>
										<Form.Label>Apellido (s)</Form.Label>

										<Form.Control
											className={!validacion.apellidos ? "is-invalid" : ""}
											name="apellidos"
											type="text"
											placeholder="Ingresa tu apellido(s)"
											onChange={handleOnChange}
											autoComplete="off"
											value={capitalizeString(formValues.apellidos)}
										/>

										<Form.Control.Feedback type="invalid">Ingresa tu apellido(s)</Form.Control.Feedback>
									</Form.Group>

									<Form.Group as={Col} md={6}>
										<Form.Label>Correo electrónico</Form.Label>

										<Form.Control
											className={validacion.correo ? "is-valid" : "is-invalid"}
											ref={refs.correo}
											name="correo"
											type="email"
											placeholder="Ingresa un correo electr&oacute;nico"
											onChange={handleOnChangeCorreo}
											autoComplete="off"
											value={formValues.correo}
										/>

										<Form.Control.Feedback type="invalid">Ingresa un correo válido</Form.Control.Feedback>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group as={Col} md={6}>
										<Form.Label>Puesto de trabajo</Form.Label>

										<Form.Control
											className={!validacion.puestoTrabajo ? "is-invalid" : ""}
											name="puestoTrabajo"
											type="text"
											placeholder="Ingresa tu puesto actual"
											onChange={handleOnChange}
											autoComplete="off"
											value={capitalizeString(formValues.puestoTrabajo)}
										/>

										<Form.Control.Feedback type="invalid">Ingresa tu puesto actual</Form.Control.Feedback>
									</Form.Group>

									<Form.Group as={Col} md={6}>
										<Form.Label>Nombre de usuario</Form.Label>

										<Form.Control
											className={!validacion.nombreUsuario ? "is-invalid" : "is-valid"}
											name="nombreUsuario"
											type="text"
											onChange={handleOnChange}
											autoComplete="off"
											value={formValues.nombreUsuario}
										/>

										<Form.Control.Feedback type="invalid">Ingresa un correo electrónico para establecer tu nombre de usuario</Form.Control.Feedback>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group as={Col} md={6}>
										<Form.Label>Contraseña</Form.Label>

										<InputGroup hasValidation>
											<InputGroup.Text onClick={handleShowPasswords} className="pointer">
												<FontAwesomeIcon icon={faEye} />
											</InputGroup.Text>

											<Form.Control
												className={validacion.contrasenia ? "is-valid" : "is-invalid"}
												name="contrasenia"
												value={formValues.contrasenia}
												type={showPasswords ? "text" : "password"}
												placeholder="Ingresa una contraseña"
												onChange={handleOnChangePasswords}
												autoComplete="off"
												ref={refs.inputContrasenia}
											/>

											<Form.Control.Feedback type="invalid">Genera una contraseña v&aacute;lida</Form.Control.Feedback>

											<CapsLockTooltip input={refs.inputContrasenia} isCapsLockOn={isCapsLockOn} />
										</InputGroup>

										<Form.Text id="passwordHelpBlock" muted>
											La contraseña debe contener:
											<ul>
												<li className={validacion.caracteresContrasenia ? "text-success" : "text-danger"}>8 caracteres</li>
												<li className={validacion.mayusculaContrasenia ? "text-success" : "text-danger"}>1 mayúscula</li>
												<li className={validacion.numeroContrasenia ? "text-success" : "text-danger"}>1 número</li>
											</ul>
											{/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
										</Form.Text>
									</Form.Group>

									<Form.Group as={Col} md={6}>
										<Form.Label>Confirma la contraseña</Form.Label>

										<InputGroup hasValidation>
											<InputGroup.Text onClick={handleShowPasswords} className="pointer">
												<FontAwesomeIcon icon={faEye} />
											</InputGroup.Text>

											<Form.Control
												className={validacion.confirmacionContrasenia ? "is-valid" : "is-invalid"}
												name="confirmacionContrasenia"
												value={formValues.confirmacionContrasenia}
												type={showPasswords ? "text" : "password"}
												placeholder="Confirma tu contraseña"
												onChange={handleOnChangePasswords}
												autoComplete="off"
											/>

											<Form.Control.Feedback type="invalid">Las contraseñas no coinciden</Form.Control.Feedback>
										</InputGroup>

										<Form.Text id="passwordHelpBlock2" muted>
											La contraseña debe ser igual a la anterior
										</Form.Text>
									</Form.Group>
								</Row>

								{/* <Row>
									<Form.Group as={Col} md={6}>
										<Form.Label>Elige una foto de perfil</Form.Label>

										{spinnerImagen ? <SpinnerImagen /> : <DropZoneReact getImageInBase64={getImageInBase64} />}
									</Form.Group>

									{formValues.avatarPerfil && (
										<Form.Group as={Col} md={6}>
											<Form.Control.Feedback>Esta sera tu foto de perfil</Form.Control.Feedback>

											<img className='imagenPrevia' src={`data:image/jpg;base64,${formValues.avatarPerfil}`} alt='Imagen dañada' />
										</Form.Group>
									)}
								</Row> */}

								<Button variant="warning" block onClick={handleSubmit}>
									Continuar {showLoadingIcon && <SpinnerRing />}
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default FormularioRegistroEmpleado;
