import React from "react";
import { Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { FaSearch } from "react-icons/fa";

const GraficasEstadisticas = ({
	certificadosData,
	graficaCertificadosEmitidosPorCurso,
	handleOnChangeCertificaciones,
	formValuesCertificados,
	certificadosEmitidosPorMes,
	buscarCertificaciones,
	handleOnChangeHorasJuego,
	tiempoJuego,
	buscarHorasJuego,
	formatoTiempoJuego,
	handleFormatoDeTiempo,
	configHorasJuego,
	handleOnChangeVisitasSala,
	visitasSala,
	buscarVisitasSala,
	configPromediosCurso,
	configVisitasSala,
}) => {
	return (
		<>
			<Row>
				<Col className="col-6">
					<Col>
						<Row>
							<Col>
								<small>
									<b>Certificados emitidos por curso</b>
								</small>
							</Col>
						</Row>
					</Col>
					<div className="app">
						<div className="row">
							<div className="mixed-chart">
								{certificadosData.length > 0 && <ReactApexChart options={graficaCertificadosEmitidosPorCurso.options} series={graficaCertificadosEmitidosPorCurso.series} type="bar" height={350} />}
							</div>
						</div>
					</div>
				</Col>
				<Col className="col-6">
					<Col>
						<Row>
							<Col>
								<small>
									<b>Certificados emitidos en:</b>
								</small>
							</Col>
							<Col xs="auto">
								<Form>
									<Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
										Año de emisión
									</Form.Label>
									<InputGroup className="mb-2">
										<Form.Control
											onChange={handleOnChangeCertificaciones}
											name="periodoCertificacion"
											id="inlineFormInputGroup"
											value={formValuesCertificados.periodoCertificacion}
											placeholder="Año de emisión"
										/>
										<InputGroup.Text>
											<Button type="button" onClick={() => buscarCertificaciones(formValuesCertificados.periodoCertificacion)}>
												<FaSearch />
											</Button>
										</InputGroup.Text>
									</InputGroup>
								</Form>
							</Col>
						</Row>
					</Col>
					<div className="app">
						<div className="row">
							<div className="mixed-chart">
								<ReactApexChart options={certificadosEmitidosPorMes.options} series={certificadosEmitidosPorMes.series} type="donut" height="350" />
							</div>
						</div>
					</div>
				</Col>

				<Col className="col-6">
					<Col>
						<Row>
							<Col>
								<small>
									<b>Tiempo de juego por año:</b>
								</small>
							</Col>
							<Col xs="auto">
								<Form>
									<Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
										Año de emisión
									</Form.Label>
									<InputGroup className="mb-2">
										<Form.Control onChange={handleOnChangeHorasJuego} id="inlineFormInputGroup" value={tiempoJuego.year} placeholder="Año de emisión" />
										<InputGroup.Text>
											<Button type="button" onClick={() => buscarHorasJuego(tiempoJuego.year)}>
												<FaSearch />
											</Button>
										</InputGroup.Text>
									</InputGroup>

									<div key={`inline-check`} className="mb-3" style={{ backgroundColor: "#E7E6E7", padding: "1rem" }}>
										<Form.Check className="pointer" inline checked={formatoTiempoJuego === "horas"} label="Horas" name="horas" type="radio" id={`inline-1`} onChange={handleFormatoDeTiempo} />
										<Form.Check inline checked={formatoTiempoJuego === "minutos"} className="pointer" label="Minutos" name="minutos" type="radio" id={`inline-2`} onChange={handleFormatoDeTiempo} />
									</div>
								</Form>
							</Col>
						</Row>
					</Col>

					<div id="chart">
						<ReactApexChart options={configHorasJuego.options} series={configHorasJuego.series} type="line" height={350} />
					</div>
				</Col>

				<Col className="col-6">
					<Col>
						<Row>
							<Col>
								<small>
									<b>Visitas a la sala de entretenimientos por año:</b>
								</small>
							</Col>
							<Col xs="auto">
								<Form>
									<Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
										Año de emisión
									</Form.Label>
									<InputGroup className="mb-2">
										<Form.Control onChange={handleOnChangeVisitasSala} name="periodoCertificacion" id="inlineFormInputGroup" value={visitasSala.year} placeholder="Año de emisión" />
										<InputGroup.Text>
											<Button type="button" onClick={() => buscarVisitasSala(visitasSala.year)}>
												<FaSearch />
											</Button>
										</InputGroup.Text>
									</InputGroup>
								</Form>
							</Col>
						</Row>
					</Col>
					<div className="app">
						<div className="row">
							<div id="chart">
								<ReactApexChart options={configVisitasSala.options} series={configVisitasSala.series} type="bar" height={380} />
							</div>
						</div>
					</div>
				</Col>
			</Row>

			<Row className="mt-3">
				<Col className="mb-4">
					<small>
						<b>Promedio general por curso</b>
					</small>
				</Col>
				<Col className="col-12">
					<div id="chart">
						<ReactApexChart options={configPromediosCurso.options} series={configPromediosCurso.series} type="bar" height={300} />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default GraficasEstadisticas;
