import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDatosUsuario,
  updateNipPerfil,
  validateNipPerfil,
} from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React, { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import {
  Notificacion,
  NotificacionExtendida,
} from "@components/general/Alertas/Alertas";

const CambiarNipSeguridad = ({
  idPerfil,
  setNipActivo,
  contextValue,
  setContextValue,
}) => {
  const [nipValido, setNipValido] = useState(false);

  const [showNip, setShowNip] = useState(false);

  const [formValues, setFormValues] = useState({
    nip: "",
    nipNuevo: "",
    confirmacionNuevoNip: "",
  });

  const handleValidarNip = async (e) => {
    e.preventDefault();

    try {
      const nipValido = await validateNipPerfil({
        idPerfil,
        nip: formValues.nip,
      });

      if (!nipValido) {
        return NotificationManager.error("El NIP es inválido");
      }

      NotificationManager.success("NIP válido");

      setNipValido(nipValido);
    } catch (error) {
      console.log("error al validar el nip: ", error);
    }
  };

  const handleOnChangeValidacionNip = ({ target }) => {
    setFormValues({
      ...formValues,
      nip: target.value,
    });
  };

  const showNipSeguridad = () => {
    setShowNip(!showNip);
  };

  const { usuarioLogeado } = contextValue;

  const [showNips, setShowNips] = useState({
    nip: false,
    confirmacionNip: false,
  });

  const [validacionNip, setValidacionNip] = useState({
    nipNuevo: false,
    confirmacionNuevoNip: false,
  });

  const handleAgregarNip = async (e) => {
    e.preventDefault();

    try {
      if (formValues.nipNuevo === "") {
        return NotificacionExtendida("info", "Ingresa un NIP para continuar");
      }

      await updateNipPerfil({
        idPerfil,
        nip: formValues.nipNuevo,
      });

      const { data: datosUsuario } = await getDatosUsuario({
        tipoUsuario: usuarioLogeado.rol,
        idCredencial: usuarioLogeado.idCredencial,
        usuarioTitular: usuarioLogeado.usuarioTitular,
      });

      const [datosUsuarioActualizados] = datosUsuario;

      setContextValue({
        ...contextValue,
        usuarioLogeado: datosUsuarioActualizados,
      });

      Notificacion("success", "NIP actualizado correctamente");

      setNipActivo(true);
    } catch (error) {
      console.log("Error al agregar un nuevo nip al perfil: ", error);

      NotificacionExtendida(
        "error",
        "No se pudo actualizar el NIP, por favor intenta más tarde"
      );
    }
  };

  const handleOnChangeAgregarNip = ({ target }) => {
    const { name, value } = target;

    const cleanedValue = value.replace(/[^0-9]/g, "");

    setFormValues({
      ...formValues,
      [name]: cleanedValue,
    });

    setValidacionNip({
      ...validacionNip,
      nipNuevo: true,
    });

    if (name === "confirmacionNuevoNip") {
      let nipsCoincidentes = false;

      if (value === formValues.nipNuevo) {
        nipsCoincidentes = true;
      }

      setValidacionNip({
        ...validacionNip,
        confirmacionNuevoNip: nipsCoincidentes,
      });
    }
  };

  const showNipNuevo = () => {
    setShowNips({
      ...showNips,
      nip: !showNips.nip,
    });
  };

  const showConfirmacionNipNuevo = () => {
    setShowNips({
      ...showNips,
      confirmacionNip: !showNips.confirmacionNip,
    });
  };

  return (
    <Container fluid>
      {!nipValido ? (
        <>
          <h4>Valida tu NIP</h4>

          <Row className="justify-content-center">
            <Col xs={12} md={4}>
              <Form onSubmit={handleValidarNip}>
                <Form.Group>
                  <InputGroup hasValidation>
                    <InputGroup.Prepend
                      onClick={showNipSeguridad}
                      className="pointer"
                    >
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      type={showNip ? "text" : "password"}
                      autoComplete="off"
                      value={formValues.nip}
                      name="nip"
                      placeholder="Ingresa tu NIP"
                      onChange={handleOnChangeValidacionNip}
                      maxLength={4}
                    />
                  </InputGroup>
                </Form.Group>

                <Button block variant="primary" type="submit">
                  Validar
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <h4>Cambia tu NIP</h4>

          <Row className="mx-5 justify-content-center">
            <Col xs={12} md={4}>
              <Form onSubmit={handleAgregarNip}>
                <Form.Group>
                  <Form.Label>NIP de seguridad</Form.Label>

                  <InputGroup hasValidation>
                    <InputGroup.Prepend
                      onClick={showNipNuevo}
                      className="pointer"
                    >
                      <InputGroup.Text id="inputGroupPrepend2">
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      type={showNips.nip ? "text" : "password"}
                      autoComplete="off"
                      value={formValues.nipNuevo}
                      className={validacionNip.nipNuevo ? "is-valid " : ""}
                      name="nipNuevo"
                      placeholder="Ingresa tu NIP"
                      onChange={handleOnChangeAgregarNip}
                      maxLength={4}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Confirmación</Form.Label>

                  <InputGroup hasValidation>
                    <InputGroup.Prepend
                      onClick={showConfirmacionNipNuevo}
                      className="pointer"
                    >
                      <InputGroup.Text id="inputGroupPrepend2">
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      onChange={handleOnChangeAgregarNip}
                      maxLength={4}
                      value={formValues.confirmacionNuevoNip}
                      type={showNips.confirmacionNip ? "text" : "password"}
                      autoComplete="off"
                      name="confirmacionNuevoNip"
                      placeholder="Confirma tu NIP"
                      className={
                        validacionNip.confirmacionNuevoNip
                          ? "is-valid"
                          : "is-invalid"
                      }
                    />
                  </InputGroup>
                </Form.Group>

                <Button block variant="warning" type="submit">
                  Cambiar NIP
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default CambiarNipSeguridad;
