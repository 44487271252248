import React from "react";

const ThumbUpSvg = () => {
  return (
    <svg
      // height='512.00026pt'
      height="200"
      viewBox="0 0 512.00026 512.00026"
      // width='512.00026pt'
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m500.757812 261.621094c0 138.28125-112.097656 250.378906-250.378906 250.378906-86.417968 0-162.609375-43.78125-207.605468-110.371094-13.089844-19.367187-30.488282-58.511718-37.382813-95.039062-7.328125-38.839844-3.714844-74.71875-2.855469-80.742188 17.359375-121.335937 121.707032-214.605468 247.84375-214.605468 138.28125 0 250.378906 112.097656 250.378906 250.378906zm0 0"
        fill="#fff"
      />

      <path
        d="m476.082031 35.917969c47.890625 47.894531 47.890625 125.542969 0 173.433593-47.894531 47.890626-125.539062 47.890626-173.433593 0-47.890626-47.890624-47.890626-125.539062 0-173.433593 47.894531-47.890625 125.539062-47.890625 173.433593 0zm0 0"
        fill="#00b96f"
      />
      <path
        d="m476.082031 35.917969c43.761719 43.761719 47.527344 112.367187 11.308594 160.414062-48.046875 36.21875-116.648437 32.453125-160.414063-11.308593-43.761718-43.765626-47.527343-112.367188-11.308593-160.414063 48.046875-36.21875 116.648437-32.453125 160.414062 11.308594zm0 0"
        fill="#54d680"
      />
      <path
        d="m506.71875 68.890625-99.707031 99.707031c-3.519531 3.519532-8.136719 5.28125-12.746094 5.28125-4.613281 0-9.226563-1.757812-12.746094-5.28125l-49.640625-49.636718c-7.039062-7.042969-7.039062-18.457032 0-25.496094 7.039063-7.039063 18.453125-7.039063 25.492188 0l36.894531 36.894531 86.964844-86.960937c7.039062-7.039063 18.449219-7.039063 25.492187 0 7.039063 7.039062 7.039063 18.453124-.003906 25.492187zm0 0"
        fill="#fff5f5"
      />
      <path
        d="m506.71875 43.398438c7.042969 7.039062 7.042969 18.449218.003906 25.488281l-85.792968 85.789062c-3.519532 3.519531-8.132813 5.28125-12.746094 5.28125-4.613282 0-9.226563-1.757812-12.746094-5.28125l-49.640625-49.640625c-4.46875-4.46875-6.089844-10.699218-4.886719-16.457031 5.757813-1.203125 11.988282.417969 16.457032 4.886719l36.894531 36.894531 86.964843-86.964844c7.039063-7.039062 18.453126-7.039062 25.492188.003907zm0 0"
        fill="#fff5f5"
      />
      <path
        d="m407.15625 253.078125c-.890625.476563-1.441406 1.402344-1.441406 2.410156 0 1.007813.550781 1.9375 1.441406 2.410157 6.4375 3.441406 10.824219 10.21875 10.824219 18.03125v10.21875c0 7.808593-4.386719 14.589843-10.824219 18.027343-.890625.476563-1.441406 1.402344-1.441406 2.410157 0 1.007812.550781 1.9375 1.441406 2.410156 6.4375 3.441406 10.824219 10.21875 10.824219 18.03125v10.21875c0 7.808594-4.386719 14.589844-10.824219 18.027344-.890625.476562-1.441406 1.402343-1.441406 2.410156 0 1.007812.550781 1.9375 1.441406 2.410156 6.4375 3.441406 10.824219 10.21875 10.824219 18.03125v10.21875c0 11.289062-9.152344 20.4375-20.441407 20.4375h-109.144531c-15.382812 0-30.347656-5.023438-42.613281-14.304688-12.269531-9.285156-27.230469-14.308593-42.617188-14.308593h-83.59375v-132.855469h72.515626c14.605468 0 28.097656-7.789062 35.402343-20.4375l34.574219-59.886719c2.691406-4.664062 4.105469-9.949219 4.105469-15.332031v-34.132812c0-8.464844 6.863281-15.328126 15.332031-15.328126 19.753906 0 35.765625 16.015626 35.765625 35.769532v19.261718c0 10.8125-2.859375 21.433594-8.285156 30.78125l-6.839844 11.777344c-1.183594 2.046875.289063 4.605469 2.652344 4.605469h92.746093c11.289063 0 20.441407 9.152344 20.441407 20.441406v10.21875c0 7.808594-4.386719 14.589844-10.824219 18.027344zm0 0"
        fill="#ffdfcf"
      />
      <path
        d="m285.125 106.285156v20.4375c0 5.644532-4.574219 10.21875-10.21875 10.21875h-8.734375v-19.417968c0-8.464844 6.863281-15.328126 15.328125-15.328126.953125 0 1.894531.046876 2.828125.121094.511719 1.222656.796875 2.5625.796875 3.96875zm0 0"
        fill="#ffeee6"
      />
      <path
        d="m336.222656 245.269531c0-5.644531 4.578125-10.21875 10.21875-10.21875h71.539063c0 7.808594-4.386719 14.589844-10.824219 18.027344-.890625.476563-1.441406 1.402344-1.441406 2.410156h-59.273438c-5.640625 0-10.21875-4.574219-10.21875-10.21875zm61.316406 130.8125h-109.144531c-15.382812 0-30.347656-5.027343-42.613281-14.308593-12.269531-9.285157-27.230469-14.308594-42.617188-14.308594h-83.59375v32.703125h83.59375c15.386719 0 30.347657 5.023437 42.617188 14.308593 12.265625 9.28125 27.230469 14.304688 42.613281 14.304688h109.144531c11.289063 0 20.441407-9.148438 20.441407-20.4375v-10.21875c0-4.152344-1.261719-8-3.390625-11.21875-3.660156 5.523438-9.925782 9.175781-17.050782 9.175781zm-61.316406-28.617187c0 5.644531 4.578125 10.21875 10.21875 10.21875h59.273438c0-1.007813.550781-1.933594 1.441406-2.410156 6.4375-3.4375 10.824219-10.21875 10.824219-18.027344h-71.539063c-5.640625 0-10.21875 4.574218-10.21875 10.21875zm0-51.097656c0 5.644531 4.578125 10.21875 10.21875 10.21875h59.273438c0-1.007813.550781-1.933594 1.441406-2.410157 6.4375-3.4375 10.824219-10.21875 10.824219-18.027343h-71.539063c-5.640625 0-10.21875 4.574218-10.21875 10.21875zm0 0"
        fill="#ffc9c0"
      />
      <path
        d="m160.445312 229.941406v167.601563c0 8.464843-6.863281 15.328125-15.328124 15.328125h-51.097657c-7.046875 0-12.96875-4.765625-14.761719-11.242188h-36.484374c-27.003907-39.964844-42.773438-88.144531-42.773438-140.007812 0-12.144532.882812-24.085938 2.554688-35.769532h76.703124c1.792969-6.476562 7.714844-11.242187 14.761719-11.242187h51.097657c8.464843 0 15.328124 6.863281 15.328124 15.332031zm0 0"
        fill="#00b96f"
      />
      <path
        d="m0 261.621094c0-12.144532.863281-24.085938 2.535156-35.769532h45.496094v125.386719c0 8.464844-6.863281 15.328125-15.328125 15.328125h-9.714844c-14.757812-31.921875-22.988281-67.46875-22.988281-104.945312zm78.691406-31.679688v132.851563c0 8.46875 6.863282 15.332031 15.328125 15.332031h51.097657c8.464843 0 15.328124-6.863281 15.328124-15.332031v-132.851563c0-8.46875-6.863281-15.332031-15.328124-15.332031h-51.097657c-8.464843 0-15.328125 6.863281-15.328125 15.332031zm0 0"
        fill="#54d680"
      />
    </svg>
  );
};

export default ThumbUpSvg;
