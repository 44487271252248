import React, { Component } from 'react';
import Header from './Header';
import Tablero from './Tablero';
import './App.css';
import { construirBaraja } from './utils/construirBaraja';
import { AlertSimple } from '@components/general/Alertas/Alertas';
import { Col, Container, Row } from 'react-bootstrap';

const getEstadoInicial = () => {
  const baraja = construirBaraja();
  return {
    baraja,
    parejaSeleccionada: [],
    estaComparando: false,
    numeroDeIntentos: 0    
  };
}

class App extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.state = getEstadoInicial();
  }

  render() {
    return (
      <Container className="App">
        <Row>
          <Col className='col-12 mb-4'>
            <Header 
              numeroDeIntentos={this.state.numeroDeIntentos}
              resetearPartida={() => this.resetearPartida()}
            />
          </Col>
          <Col className='col-12'>
            <Tablero 
              baraja={this.state.baraja}
              parejaSeleccionada={this.state.parejaSeleccionada}
              seleccionarCarta={(carta) => this.seleccionarCarta(carta)}
            />
          </Col>
        </Row>
        
      
      </Container>
    );
  }

  seleccionarCarta(carta) {
    
    if (
      this.state.estaComparando ||
      this.state.parejaSeleccionada.indexOf(carta) > -1 ||
      carta.fueAdivinida
    ) {
      return;
    }

    const parejaSeleccionada = [...this.state.parejaSeleccionada, carta];
    this.setState({
      parejaSeleccionada
    });

    if (parejaSeleccionada.length === 2) {
      this.compararPareja(parejaSeleccionada);
    }
  }

  compararPareja(parejaSeleccionada) {
    this.setState({estaComparando: true});

    setTimeout(() => {
      const [primeraCarta, segundaCarta] = parejaSeleccionada;
      let baraja = this.state.baraja;

      if (primeraCarta.icono === segundaCarta.icono) {
        baraja = baraja.map((carta) => {
          if (carta.icono !== primeraCarta.icono) {
            return carta;
          }

          return {...carta, fueAdivinada: true};
        });
      }

      this.verificarSiHayGanador(baraja);
      this.setState({
        parejaSeleccionada: [],
        baraja,
        estaComparando: false,
        numeroDeIntentos: this.state.numeroDeIntentos + 1
      })
    }, 1000)
  }

  verificarSiHayGanador(baraja) {
    if (
      baraja.filter((carta) => !carta.fueAdivinada).length === 0
    ) {
      {AlertSimple("success",`Ganaste en ${this.state.numeroDeIntentos} intentos.`)}
      this.setState(
        getEstadoInicial()
      )
    }
  }

  resetearPartida() {
    this.setState(
      getEstadoInicial()
    );
  }
}

export default App;