import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap'

export const ShowImagenGenerada = ({verImagenGenerada, setVerImagenGenerada, imagenesGeneradas}) => {

  return (
      <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={verImagenGenerada}
          onHide={() => setVerImagenGenerada(false)}
      >
          {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}

          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
              <>
                  <Image width='100%' height='100%' fluid src={imagenesGeneradas[0].output_url} />
              </>
          </Modal.Body>
      </Modal>
  )
}
