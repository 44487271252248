import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { faArrowLeft, faChartBar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLeccionesVistas } from "@helpers/httpRequests/httpRequestLeccionesVistas/httpRequestsLeccionesVistas";
import { getLeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import columns from "../datatables/columns";
import { Checkbox } from "primereact/checkbox";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import UserContext from "@context/User/UserContext";

const ListarAvanceEmpleado = ({
	idPerfilEmpleado,
	setMostrarGraficaEmpleado,
	nombreEmpleadoSeleccionado,
	indexAvance,
	tiempoEnPlataformaEmpleado,
	mostrarGraficaEmpleado,
	diasPlataformaEmpleado,
	minutosPlataformaEmpleado,
	mesesPlataformaEmpleado,
	yearsPlataformaEmpleado,
	horasPlataformaEmpleado,
	setMostrarGraficaInvitado,
}) => {

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { modoOscuro } = usuarioLogeado;

	const [mostrarLeccionesEmpleado, setMostrarLeccionesEmpleado] = useState(false);

	const [leccionesTomadas, setLeccionesTomadas] = useState(0);

	const [leccionesTotales, setLeccionesTotales] = useState(0);

	const [leccionesVistasEmpleado, setLeccionesVistasEmpleado] = useState([]);

	const [leccionesSeleccionadasEmpleado, setLeccionesSeleccionadasEmpleado] = useState([]);

	const [dataTableColumns, setDataTableColumns] = useState([]);

	const [leccionesListadasEmpleado, setLeccionesListadasEmpleado] = useState(false);

	const [cursos, setCursos] = useState([]);

	const [avancePorCursoEmpleado, setAvancePorCursoEmpleado] = useState([]);

	const [codigoCurso, setCodigoCurso] = useState(null);

	const [tiempoPlataforma, setTiempoPlataforma] = useState(null);

	const handleClickEmpleado = async (data) => {
		try {
			//*Esta funcion se encargara de listar las lecciones del curso que se seleccione.
			const { codigoCurso, name, cursos, lecciones } = data;
			setCodigoCurso(codigoCurso);

			// console.log(lecciones);
			// console.log(codigoCurso);

			setLeccionesTomadas(lecciones);

			const { data: datosLeccion } = await getLeccionesByCodigoCurso(codigoCurso);

			setLeccionesTotales(datosLeccion.length);

			if (datosLeccion.length > 0) {
				setLeccionesSeleccionadasEmpleado(datosLeccion);
				setMostrarLeccionesEmpleado(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const obtenerFecha = (boolean, codigoLeccion) => {
		if (boolean) {
			let data = leccionesVistasEmpleado.find((leccionVista) => leccionVista.codigoLeccion === codigoLeccion);
			return getFechaSinHora(data.fecha);
		} else {
			return " - ";
		}
	};

	const handleOcultarGraficas = () => {
		setMostrarGraficaEmpleado(false);
		setMostrarGraficaInvitado(false);
	};

	useEffect(() => {
		const obtenerCursos = async () => {
			try {
				const { data: cursos } = await getCursos();
				// console.log(cursos);
				setCursos(cursos);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerCursos();

		const obtenerHistorial = async () => {
			try {
				const { data } = await getLeccionesVistas(idPerfilEmpleado);
				if (Array.isArray(data)) {
					setLeccionesVistasEmpleado(data);
				} else {
					setLeccionesVistasEmpleado([data]);
				}
				setLeccionesListadasEmpleado(true);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerHistorial();

		if (codigoCurso !== null) {
			//*Se asigna la tabla para ver los avances de cada empleado (Grafica)
			setDataTableColumns([
				...columns,
				{
					name: "Fecha en que se completó",
					cell: (row) => (
						<>
							<div className="text-center">
								<p>
									{" "}
									{obtenerFecha(
										leccionesVistasEmpleado.some((leccionVista) => leccionVista.codigoLeccion === (leccionVista.codigoCurso.startsWith("ING") ? row.claseLeccion : row.codigoLeccion)) ? true : false,
										codigoCurso.startsWith("ING") ? row.claseLeccion : row.codigoLeccion
									)}{" "}
								</p>
							</div>
						</>
					),
					center: true,
					ignoreRowClick: true,
					allowOverflow: true,
					width: "25%",
				},
				{
					name: "Completado",
					cell: (row) => (
						<>
							<Checkbox className="mx-3" checked={leccionesVistasEmpleado.some((leccionVista) => leccionVista.codigoLeccion === row.codigoLeccion)}></Checkbox>
						</>
					),
					center: true,
					ignoreRowClick: true,
					allowOverflow: true,
					width: "25%",
				},
			]);
		}
	}, []);

	useEffect(() => {
		const listarAvancePorCursoEmpleado = async () => {
			try {
				// console.log("Longitud de lecciones vistasEmpleado");
				// console.log(leccionesVistasEmpleado);

				const { data } = await getLeccionesVistas(idPerfilEmpleado);

				if (Array.isArray(data)) {
					setLeccionesVistasEmpleado(data);
				} else {
					setLeccionesVistasEmpleado([data]);
				}
			} catch (error) {
				console.log(error);
				setLeccionesVistasEmpleado([]);
			}
		};

		listarAvancePorCursoEmpleado();
		if (codigoCurso !== null) {
			//*Se asigna la tabla para ver los avances de cada empleado (Grafica)
			setDataTableColumns([
				...columns,
				{
					name: "Fecha en que se completó",
					cell: (row) => (
						<>
							<div className="text-center">
								<p>
									{" "}
									{obtenerFecha(
										leccionesVistasEmpleado.some((leccionVista) => leccionVista.codigoLeccion === (leccionVista.codigoCurso.startsWith("ING") ? row.claseLeccion : row.codigoLeccion)) ? true : false,
										codigoCurso.startsWith("ING") ? row.claseLeccion : row.codigoLeccion
									)}{" "}
								</p>
							</div>
						</>
					),
					center: true,
					ignoreRowClick: true,
					allowOverflow: true,
					width: "25%",
				},
				{
					name: "Completado",
					cell: (row) => (
						<>
							<Checkbox
								className="mx-3"
								checked={leccionesVistasEmpleado.some(
									(leccionVista) => leccionVista.codigoLeccion === (leccionVista.codigoLeccion.startsWith("ING") ? row.claseLeccion : row.codigoLeccion)
								)}></Checkbox>
						</>
					),
					center: true,
					ignoreRowClick: true,
					allowOverflow: true,
					width: "25%",
				},
			]);
		}
	}, [leccionesSeleccionadasEmpleado || idPerfilEmpleado || codigoCurso]);

	useEffect(() => {
		const listarLeccionesVistas = async () => {
			try {
				let arregloCursos = [];

				if (leccionesVistasEmpleado.length > 0) {
					// console.log("La longitud es mas de 0");
					const { data: cursos } = await getCursos();
					cursos.map((curso, idx) => {
						let cantidadLecciones = leccionesVistasEmpleado.filter((dato) => dato.codigoCurso.startsWith(curso.codigoCurso));
						arregloCursos.push({
							name: curso.nombre,
							codigoCurso: curso.codigoCurso,
							lecciones: cantidadLecciones.length,
						});
					});

					// console.log("arregloCursos");
					// console.log(arregloCursos);

					setAvancePorCursoEmpleado(arregloCursos);
				} else {
					// console.log("La longitud es 0");

					arregloCursos.push({
						name: "",
						codigoCurso: "",
						lecciones: 0,
					});
					setAvancePorCursoEmpleado(arregloCursos);
				}
			} catch (error) {
				console.log(error);
			}
		};

		listarLeccionesVistas();
	}, [leccionesVistasEmpleado]);

	return (
		<Row>
			{mostrarLeccionesEmpleado ? (
				<>
					<Col className="col-12 mt-4">
						<Row className="justify-content-end">
							<Col>
								<h6>
									<FontAwesomeIcon icon={faChartBar} /> Avance por lecciones del usuario empresarial
								</h6>
							</Col>
							<Col className="col-auto pointer" onClick={handleOcultarGraficas}>
								<FontAwesomeIcon icon={faArrowLeft} /> Regresar
							</Col>
						</Row>
					</Col>
					<Col className="mt-5">
						<>
							<p>
								<b>Lecciones del curso: {leccionesTotales} </b>
							</p>
							<p>
								<b>Lecciones tomadas: {leccionesTomadas}</b>
							</p>
						</>
					</Col>
					<Col className="col-12">
						{/* //*Aqui se listaran las lecciones del curso que se haya seleccionado */}
						<DataTablesRecipient columns={dataTableColumns} data={leccionesSeleccionadasEmpleado} />
					</Col>
				</>
			) : (
				<>
					<Col className="col-12 mt-4">
						<Row className="justify-content-end">
							<Col>
								<h6>
									<FontAwesomeIcon icon={faChartBar} /> Avance por cursos
								</h6>
							</Col>
							<Col className="col-auto pointer" onClick={handleOcultarGraficas}>
								<FontAwesomeIcon icon={faArrowLeft} /> Regresar
							</Col>
						</Row>
					</Col>
					<Col className="mt-5">
						<Row>
							<Col>
								<p>
									<b>Estudiante: </b>
									{nombreEmpleadoSeleccionado}
								</p>
								<p>
									Tiempo en plataforma: {yearsPlataformaEmpleado > 0 && `${yearsPlataformaEmpleado} ${yearsPlataformaEmpleado === 1 ? "Año" : "Años"}`}{" "}
									{mesesPlataformaEmpleado > 0 && `${mesesPlataformaEmpleado} ${mesesPlataformaEmpleado === 1 ? "Mes" : "Meses"}`}{" "}
									{diasPlataformaEmpleado > 0 && `${diasPlataformaEmpleado} ${diasPlataformaEmpleado === 1 ? "Día" : "Días"}`}{" "}
									{horasPlataformaEmpleado > 0 && `${horasPlataformaEmpleado} ${horasPlataformaEmpleado === 1 ? "Hora" : "Horas"}`}{" "}
									{minutosPlataformaEmpleado > 0 && `${minutosPlataformaEmpleado} ${minutosPlataformaEmpleado === 1 ? "Minuto" : "Minutos"}`}
								</p>
							</Col>
						</Row>
						<ResponsiveContainer width="100%" height={400}>
							<BarChart data={avancePorCursoEmpleado}>
								<XAxis dataKey="name" angle={-45} tick={false} interval={0} />
								<YAxis />
								<CartesianGrid strokeDasharray="3 3" />
								<Tooltip
									itemStyle={{
										color: modoOscuro ? "#000" : "#fff",
									}}
									labelStyle={{
										color: modoOscuro ? "#000" : "#fff",
									}}
								/>

								<Legend
									wrapperStyle={{
										color: modoOscuro ? "#000" : "#fff",
									}}
								/>
								<Bar
									onClick={handleClickEmpleado}
									dataKey="lecciones"
									fill="#F59E0B"
								/>
							</BarChart>
						</ResponsiveContainer>
					</Col>
				</>
			)}
		</Row>
	);
};

export default ListarAvanceEmpleado;
