import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Retroalimentacion from "./Retroalimentacion";
import { useState } from "react";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import { addRecomendaciones } from "@helpers/httpRequests/httpRequestsRecomendaciones/httpRequestsRecomendaciones";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const TestDiagnostico = ({
  codigoCursoSeleccionado,
  generarRetroalimentacion,
  preguntasDiagnostico,
  examenContestado,
  objetoRespuestas,
  handleRespuestas,
  respuestas,
  respuestasExamen,
  handleRevisarTest,
  handleRetroalimentacion,
  graficos,
  transformarDatos,
  relacionDatos,
  funcionesDax,
  funciones,
  analisisDatos,
  graficosExcel,
  macros,
  conocimientosGenerales,
  handleChangeComponent,
  handleScrollToTop,
}) => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const { idPerfil } = usuarioLogeado;

  const [leccionesFunciones, setLeccionesFunciones] = useState([]);

  const [leccionesAnalisisDatos, setLeccionesAnalisisDatos] = useState([]);

  const [leccionesGraficosExcel, setLeccionesGraficosExcel] = useState([]);

  const [leccionesMacros, setLeccionesMacros] = useState([]);

  const [leccionesConocimientos, setLeccionesConocimientos] = useState([]);

  const [leccionesGraficos, setLeccionesGraficos] = useState([]);

  const [leccionesTransformar, setLeccionesTransformar] = useState([]);

  const [leccionesRelacion, setLeccionesRelacion] = useState([]);

  const [leccionesFuncionesDax, setLeccionesFuncionesDax] = useState([]);

  const handleTerminarEvaluacion = async () => {
    try {
      //Guardar las recomendaciones ligadas a este usuario

      let lecciones = [];

      switch (codigoCursoSeleccionado) {
        case "EX21":
          lecciones = [
            leccionesFunciones,
            leccionesAnalisisDatos,
            leccionesGraficosExcel,
            leccionesMacros,
            leccionesConocimientos,
          ];
          break;

        case "PB21":
          lecciones = [
            leccionesGraficos,
            leccionesTransformar,
            leccionesRelacion,
            leccionesFuncionesDax,
          ];
          break;

        default:
          break;
      }

      let temas = [];

      switch (codigoCursoSeleccionado) {
        case "EX21":
          temas = [
            "Funciones",
            "Analisis de datos",
            "Gráficos en Excel",
            "Macros",
            "Conocimientos generales",
          ];
          break;

        case "PB21":
          temas = [
            "Gráficos",
            "Transformar datos",
            "Relación de datos",
            "Funciones DAX",
          ];
          break;

        default:
          break;
      }

      for (let index = 0; index < lecciones.length; index++) {
        let infoRecomendacion = {
          leccionesRecomendadas: JSON.stringify(lecciones[index]).replace(
            /\\"/g,
            ""
          ),
          tema: temas[index],
          idPerfil,
          codigoCursoSeleccionado,
        };

        const { data } = await addRecomendaciones(infoRecomendacion);
      }

      Notificacion("success", "Retroalimentación guardada exitosamente");
      handleChangeComponent("examenDiagnostico");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="mt-3 mb-5">
      {!generarRetroalimentacion ? (
        <>
          <Col className="col-12">
            <Alert variant="warning">
              Asegurate de contestar todas las preguntas para obtener una
              retroalimentación acertada a tus conocimientos
            </Alert>
          </Col>
          <Col>
            <Form>
                {preguntasDiagnostico.length > 0 ? (
                  preguntasDiagnostico.map(
                    (preguntaDiagnostico, idPregunta) => (
                      <div key={idPregunta} className="mt-3">
                        <Form.Label>
                          <small>
                            Tema:{" "}
                            <span className="text-capitalize">
                              {preguntaDiagnostico.tema}
                            </span>
                          </small>
                          <p key={idPregunta}>{`${idPregunta + 1}. ${
                            preguntaDiagnostico.pregunta
                          }`}</p>
                        </Form.Label>

                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >

                        {!examenContestado ? (
                          objetoRespuestas.map(
                            (objetoRespuesta, idObjetoRespuesta) =>
                              objetoRespuesta[4] === idPregunta + 1 &&
                              objetoRespuestas[idObjetoRespuesta].map(
                                (respuesta, idRespuesta) =>
                                  idRespuesta !== 4 && (
                                    
                                    <FormControlLabel key={idRespuesta}
                                    value={respuesta} control={<Radio />} label={respuesta}
                                    name={`${idObjetoRespuesta}`}
                                    onChange={handleRespuestas} />
                                  )
                              )
                          )
                        ) : (
                          <>
                            <Alert
                              variant={
                                respuestasExamen[idPregunta] ===
                                respuestas[idPregunta]
                                  ? "success"
                                  : "danger"
                              }
                            >
                              <small>
                                Tu respuesta: {respuestasExamen[idPregunta]}
                              </small>
                            </Alert>
                            <Alert variant="success">
                              <small>
                                Respuesta correcta: {respuestas[idPregunta]}
                              </small>
                            </Alert>
                          </>
                        )}
                        </RadioGroup>
                      </div>
                    )
                  )
                ) : (
                  <h2>
                    Hubo un problema al recuperar las preguntas del examen
                  </h2>
                )}
            </Form>
          </Col>
        </>
      ) : (
        <Retroalimentacion
          codigoCursoSeleccionado={codigoCursoSeleccionado}
          setLeccionesFunciones={setLeccionesFunciones}
          setLeccionesAnalisisDatos={setLeccionesAnalisisDatos}
          setLeccionesGraficosExcel={setLeccionesGraficosExcel}
          setLeccionesMacros={setLeccionesMacros}
          setLeccionesConocimientos={setLeccionesConocimientos}
          setLeccionesGraficos={setLeccionesGraficos}
          setLeccionesTransformar={setLeccionesTransformar}
          setLeccionesRelacion={setLeccionesRelacion}
          setLeccionesFuncionesDax={setLeccionesFuncionesDax}
          funciones={funciones}
          analisisDatos={analisisDatos}
          graficosExcel={graficosExcel}
          macros={macros}
          conocimientosGenerales={conocimientosGenerales}
          graficos={graficos}
          transformarDatos={transformarDatos}
          relacionDatos={relacionDatos}
          funcionesDax={funcionesDax}
        />
      )}

      {preguntasDiagnostico.length > 0 && (
        <Col className="col-12">
          {generarRetroalimentacion ? (
            <Button onClick={handleTerminarEvaluacion} block className="mt-4">
              Terminar evaluación
            </Button>
          ) : !examenContestado ? (
            <Button onClick={handleRevisarTest} block className="mt-4">
              Revisar evaluación
            </Button>
          ) : (
            <Button onClick={handleRetroalimentacion} block className="mt-4">
              Ver retroalimentación
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
};

export default TestDiagnostico;
