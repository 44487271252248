import { Notificacion } from "@components/general/Alertas/Alertas";
import { deactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarInvitado = ({ invitado, setInvitadoEliminado }) => {
  const handleDeleteInvitado = async () => {
    try {
      await deactivateUsuario(invitado.idCredencial);

      setInvitadoEliminado(true);

      Notificacion("success", "El usuario invitado se desactivó correctamente");
    } catch (error) {
      console.log("Error al eliminar al estudiante seleccionado: ", error);

      Notificacion(
        "error",
        "No se pudo eliminar el usuario invitado seleccionado. \n" +
          error.message
      );
    }
  };

  const cancelarDeleteInvitado = () => {
    Notificacion("info", "No se desactivó el usuario invitado seleccionado");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar a este usuario invitado?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleDeleteInvitado}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeleteInvitado}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarInvitado;
