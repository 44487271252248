import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";

export const validateDisponibilidadCorreo = (correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (regExpStrings.regexEmail(correo)) {
        const validacionCorreo = await httpRequest({
          url: `${endpointsPaths.validaciones}/${httpRequestsPaths.validaciones.validateDisponibilidadCorreo}/${correo}`,
          method: "POST",
        });

        resolve(validacionCorreo);
      } else {
        reject({ valid: false });
      }
    } catch (error) {
      // console.log("Error validando el correo: ", error);

      reject(error);
    }
  });
};

export const validateCurp = (curp) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validacionCurp = await httpRequest({
        url: `${endpointsPaths.validaciones}/${httpRequestsPaths.validaciones.validateCurp}/${curp}`,
        method: "POST",
      });

      resolve(validacionCurp);
    } catch (error) {
      // console.log("Error validando CURP: ", error);

      reject(error);
    }
  });
};

export const validateDisponibilidadNombreUsuario = (nombreUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validacionNombreUsuario = await httpRequest({
        url: `${endpointsPaths.validaciones}/${httpRequestsPaths.validaciones.validateDisponibilidadNombreUsuario}`,
        method: "POST",
        body: JSON.stringify({ nombreUsuario }),
      });

      console.log("Validacion de nombre de usuario: ", validacionNombreUsuario);

      resolve(validacionNombreUsuario);
    } catch (error) {
      // console.log("Error al consultar la disponibilidad del nombre de usuario: ", error);

      reject(error);
    }
  });
};