import { Notificacion } from "@components/general/Alertas/Alertas";
import DatosFacturacion from "@components/usuario/MisPagos/components/facturacion/DatosFacturacion";
import UserContext from "@context/User/UserContext";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import { enviarComprobanteAbono } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { enviarCorreoFacturacion, retrievePaymentIntent } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import { registrarNuevoPago, validarDatosPago } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { getUsuarioByFolio } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import Loader from "@helpers/loader/loader";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Col, Container, FloatingLabel, Form, Image, Navbar, Row } from "react-bootstrap";

const AbonoRealizado = ({ history }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { folioContrato, isMobile } = contextValue;

	const [paymentIntent, setPaymentIntent] = useState({});

	const [paymentMethod, setPaymentMethod] = useState({});

	const [facturacionEnviada, setFacturacionEnviada] = useState(false);

	const [datosUsuario, setDatosUsuario] = useState({
		nombreCompleto: "",
		apellidos: "",
		correo: "",
		licenciaRenovacion: false,
		folioRenovacion: "",
	});

	const { correo, nombreCompleto, apellidos, licenciaRenovacion, folioRenovacion } = datosUsuario;

	const [formValuesFacturacion, setFormValuesFacturacion] = useState({
		usoCfdi: "",
		claveCfdi: "",
		constanciaSituacionFiscalData: "",
	});

	const [pagoRealizado, setPagoRealizado] = useState(false);

	const [showSpinner, setShowSpinner] = useState(false);

	const [showLoader, setShowLoader] = useState(true);

	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);

	const [datosCompletados, setDatosCompletados] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
			setOpen(false);
		}
	};

	const handleButtonClick = async () => {
		let montoCobro = "",
			tipoTarjeta = "";

		const { amount } = paymentIntent;

		const {
			card: { funding },
		} = paymentMethod;

		montoCobro = amount / 100;

		tipoTarjeta = funding === "credit" ? "Tarjeta de crédito" : "Tarjeta de débito";

		await enviarCorreoFacturacion({
			pdfData: formValuesFacturacion.constanciaSituacionFiscalData,
			folioContrato: contextValue.folioContrato,
			cfdi: {
				clave: formValuesFacturacion.claveCfdi,
				descripcion: formValuesFacturacion.usoCfdi,
			},
			metodoPago: tipoTarjeta,
			nombreCliente: `${nombreCompleto} ${apellidos}`,
			correoCliente: correo,
			montoCobro: numberCommaSeparator(montoCobro),
		});

		Notificacion("success", "Solicitud de facturación enviada con éxito");

		setFacturacionEnviada(true);
	};

	const handleExit = () => {
		history.replace(dashboardRoutesPaths.pagos.abonoLicencia);
	};

	const validarExistenciaPago = async () => {
		try {
			const {
				stripeSession: { payment_intent },
			} = contextValue;

			const { paymentIntent, paymentMethod } = await retrievePaymentIntent(payment_intent);

			setPaymentIntent(paymentIntent);

			setPaymentMethod(paymentMethod);

			// const { data } = await validarDatosPago(folioContrato);

			// const { pagosContrato } = data;

			// const pago = pagosContrato.find((pago) => pago.referenciaPago === paymentIntent.id);

			// if (pago !== undefined) {
			// 	// return history.replace(dashboardRoutesPaths.dashboard);
			// }

			setPagoRealizado(true);

			if (loading) {
				setLoading(false);

				setOpen(false);
			}
		} catch (error) {
			console.log("Error al obtener el historial de pagos: ", error);
		}
	};

	const obtenerDatosUsuario = async () => {
		try {
			const usuario = await getUsuarioByFolio(folioContrato);

			setDatosUsuario(usuario);

			setDatosCompletados(true);
		} catch (error) {
			console.log("error al obtener los datos de usuario: ", error);

			setShowLoader(false);

			handleOpen();
		}
	};

	const handleSubmitRecoleccionDatos = (e) => {
		e.preventDefault();

		setLoading(true);

		setDatosCompletados(true);
	};

	const handleOnChange = ({ target }) => {
		setDatosUsuario({
			...datosUsuario,
			[target.name]: target.value
		});
	};

	useEffect(() => {
		obtenerDatosUsuario();
	}, []);

	useEffect(() => {
		if (datosUsuario.correo !== "") {
			if (datosCompletados) {
				validarExistenciaPago();
			}
		}
	}, [datosUsuario, datosCompletados]);

	useEffect(() => {
		if (pagoRealizado) {
			const registrarPagoSai = async () => {
				try {
					const { id, amount } = paymentIntent;

					const {
						card: { last4, funding },
					} = paymentMethod;

					const idMedioPago = funding === "credit" ? 7 : 9;

					const fechaActual = moment().format("yyyy-MM-DD|HH:mm");

					const [fechaPago, horaPago] = fechaActual.split("|");

					let folioContratoAux = licenciaRenovacion ? `${folioContrato}-${folioRenovacion}` : folioContrato;

					try {
						await registrarNuevoPago(
							{
								fechaPago,
								horaPago,
								montoPago: amount / 100,
								referenciaPago: id,
								comentarioPago: "Abono desde Plataforma",
								idMedioPago,
							},
							folioContratoAux
						);
					} catch (error) {
						console.log("error al registrar el pago en el SAI: ", error);
					}

					try {
						await enviarComprobanteAbono({
							titular: `${nombreCompleto} ${apellidos}`,
							paymentIntentId: id,
							folioContrato,
							montoCobro: amount / 100,
							numeroTarjeta: `Tarjeta con terminación ${last4}`,
							description: `Abono a tu adeudo Click+ - Folio ${folioContrato}`,
							correo,
						});
					} catch (error) {
						console.log("error al enviar los comprobantes de pago: ", error);
					}

					setPagoRealizado(false);

					setShowLoader(false);
				} catch (error) {
					console.log("error al validar el pago: ", error);
				}
			};

			registrarPagoSai();
		}
	}, [pagoRealizado]);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
					<Navbar variant="light" bg="dark">
						<Navbar.Brand href="#home">
							<Image fluid width="100" src={require("@assets/images/click.png")} alt="Logo Global Click" />
						</Navbar.Brand>
					</Navbar>

					<Container fluid className="py-30 bg-main-container">
						<Row>
							<Col>
								<Container fluid className="bg-light-dark">
									<Row className="text-white">
										<Col>
											<Container fluid className="p-0 p-lg my-3">
												<Row>
													<Col className="text-center">
														<ThumbUpSvg />
													</Col>
												</Row>

												<Row>
													<Col className="text-center">
														<Container>
															<Row>
																<Col>
																	<h3 className="text-success text-uppercase">¡Gracias por tu pago!</h3>

																	<p className="text-center">Tu pago ha sido recibido satisfactoriamente.</p>

																	<p className="text-center">
																		Recibirás un correo electrónico de <b>notificaciones@globalclickmexico.com</b> con tu comprobante de pago.
																	</p>
																</Col>
															</Row>

															{!facturacionEnviada && (
																<>
																	<DatosFacturacion formValues={formValuesFacturacion} setFormValues={setFormValuesFacturacion} />

																	{formValuesFacturacion.facturacionSolicitada && (
																		<Button variant="success" onClick={handleButtonClick} disabled={facturacionEnviada}>
																			Solicitar facturación {showSpinner && <FontAwesomeIcon icon={faSpinner} spin />}
																		</Button>
																	)}
																</>
															)}

															{
																!isMobile ? 
																<Row>
																	<Col>
																		<Button variant="primary" onClick={handleExit}>
																			Regresar a la plataforma
																		</Button>
																	</Col>
																</Row>
																: 
																<Row>
																	<Col>
																		<Button variant="primary" onClick={() => window?.ReactNativeWebView.postMessage('back-to-app')}>
																			Regresar a la aplicación
																		</Button>
																	</Col>
																</Row>
															}
														</Container>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

					<Dialog open={open} onClose={handleClose} disableEscapeKeyDown >
						<DialogTitle>Falta información</DialogTitle>

						<DialogContent>
							<DialogContentText>No has completado tu registro, para enviar tu comprobante completa los siguientes datos.</DialogContentText>

							<Form onSubmit={handleSubmitRecoleccionDatos} >
								<FloatingLabel label="Nombre" className='mb-3'>
									<Form.Control type="text" placeholder="Nombre" value={nombreCompleto} onChange={handleOnChange} name='nombreCompleto' />
								</FloatingLabel>

								<FloatingLabel label="Apellidos" className='mb-3'>
									<Form.Control type="text" placeholder="Apellidos" value={apellidos} onChange={handleOnChange} name='apellidos' />
								</FloatingLabel>

								<FloatingLabel label="Correo electrónico" className='mb-3'>
									<Form.Control type="text" placeholder="Correo electrónico" value={correo} onChange={handleOnChange} name='correo' />
								</FloatingLabel>

								<Button type='submit' variant="success">
									{
										loading ?
										<>
											<FontAwesomeIcon icon={faSpinner} spin /> Cargando...
										</>
										:
										<>
											Continuar <FontAwesomeIcon icon={faArrowRight} />
										</>
									}
								</Button>
							</Form>
						</DialogContent>
					</Dialog>
				</Animated>
			)}
		</>
	);
};

export default AbonoRealizado;
