const getApiUrl = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.location.origin.includes("globalclickmexico") || window.location.origin.includes('ngrok')) {
        const BASE_API_URL = `${window.location.origin}/api`;

        localStorage.setItem("BASE_API_URL", BASE_API_URL);

        // console.log("Se obtuvo correctamente la VPS BASE_API_URL: ", BASE_API_URL);

        return resolve(BASE_API_URL);
      }

      let url = "";

      let aux = window.location.origin.split(":");

      aux[2] = "3002/api";

      url = aux.join(":");

      fetch(url)
        .then((result) => {
          // console.log("Se obtuvo correctamente la LOCAL DEV API URL: ", url);

          localStorage.setItem("BASE_API_URL", url);

          resolve(url);
        })
        .catch((error) => {
          let aux = window.location.origin.split(":");

          aux[2] = "3000/api";

          url = aux.join(":");

          fetch(url)
            .then((result) => {
              console.log(
                "Se obtuvo correctamente la LOCAL PROD API URL: ",
                url
              );

              localStorage.setItem("BASE_API_URL", url);

              resolve(url);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    } catch (error) {
      console.log(error);
    }
  });
};

// const API_URL = getApiUrl();

const AUTH_TOKEN =
  "QmVhcmVyIGV5SmhiR2NpT2lKSVV6STFOaUo5LlVqSjNkMWxxVW5OWk1uaHdXVEowUVUxcVFYbE5kejA5LjB1T183cmhmUllQa3Y5amJMeEh6UmdRM1B4VmNMb25VbUJZS0c2MktRVW8=";

export { getApiUrl, AUTH_TOKEN };
