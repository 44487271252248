import TitleDashboard from "@components/general/Titles/TitleDashboard";
import {
  faEllipsisV,
  faFile,
  faListAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import {
  descargarArchivo,
  getPublicacionesByGrupo,
} from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import "@assets/css/components/ForoEmpresarial/style.css";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import NuevaPublicacion from "../Modales/publicaciones/NuevaPublicacion";
import EliminarPublicacion from "../Modales/publicaciones/EliminarPublicacion";
import ReactivarPublicacion from "../Modales/publicaciones/ReactivarPublicacion";
import EditarPublicacion from "../Modales/publicaciones/EditarPublicacion";

const Publicaciones = ({ isTabActive, rol, key, tab, idGrupo, infoGrupo }) => {
  const [publicaciones, setPublicaciones] = useState([]);

  const [publicacionesEmpleado, setPublicacionesEmpleado] = useState([]);

  const [publicacionSeleccionada, setPublicacionSeleccionada] = useState(0);

  //States para los modales
  const [publicacionAgregada, setPublicacionAgregada] = useState(false);

  const [publicacionEliminada, setPublicacionEliminada] = useState(false);

  const [publicacionReactivada, setPublicacionReactivada] = useState(false);

  const [publicacionModificada, setPublicacionModificada] = useState(false);

  //*Modales para agregar, actualizar y eliminar una publicacion hecha.

  const handleAgregarPublicacion = () => {
    ModalSweetAlert({
      title: "Nueva publicación",
      html: (
        <NuevaPublicacion
          idGrupo={idGrupo}
          folioGrupo={infoGrupo.folioGrupo}
          numeroPublicaciones={publicaciones.length}
          setPublicacionAgregada={setPublicacionAgregada}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleEliminarPublicacion = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "Deshabitar publicación",
      html: (
        <EliminarPublicacion
          setPublicacionEliminada={setPublicacionEliminada}
          publicacion={publicaciones[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleReactivarPublicacion = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "Rehabilitar publicación",
      html: (
        <ReactivarPublicacion
          setPublicacionReactivada={setPublicacionReactivada}
          publicacion={publicaciones[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleEditarPublicacion = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "Editar publicación",
      html: (
        <EditarPublicacion
          folioGrupo={infoGrupo.folioGrupo}
          setPublicacionModificada={setPublicacionModificada}
          publicacion={publicaciones[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  //*FUNCIONES PARA LAS PUBLICACIONES

  const handleDescargarArchivo = async ({ target }) => {
    try {
      const { name, value } = target;

      const datosArchivo = {
        folioGrupo: infoGrupo.folioGrupo,
        idPublicacion: value,
        nombreArchivo: name,
      };

      const resp = await descargarArchivo(datosArchivo);

      // const { data } = await descargarArchivo(datosArchivo);

      const { data } = resp;

      const dataBinaria = new Uint8Array(data.data);

      const datos = new Blob([dataBinaria]);

      var link = document.createElement("a");

      link.href = window.URL.createObjectURL(datos);

      var fileName = datosArchivo.nombreArchivo;

      link.download = fileName;

      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const obtenerPublicaciones = async () => {
      try {
        const { data } = await getPublicacionesByGrupo(idGrupo);
        // console.log(data);

        if (Array.isArray(data)) {
          setPublicaciones(data);
          const dataFiltrada = data.filter((dato) => dato.estatus === 1);

          setPublicacionesEmpleado(dataFiltrada);
        } else {
          const arregloPublicaciones = [data];
          const dataFiltrada = arregloPublicaciones.filter(
            (dato) => dato.estatus === 1
          );

          // console.log(dataFiltrada);

          setPublicaciones([data]);
          setPublicacionesEmpleado(dataFiltrada);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    obtenerPublicaciones();
  }, []);

  useEffect(() => {
    if (tab === "publicaciones") {
      const obtenerPublicaciones = async () => {
        try {
          const { data } = await getPublicacionesByGrupo(idGrupo);
          // console.log(data);

          if (Array.isArray(data)) {
            setPublicaciones(data);
            const dataFiltrada = data.filter((dato) => dato.estatus === 1);

            setPublicacionesEmpleado(dataFiltrada);
          } else {
            const arregloPublicaciones = [data];
            const dataFiltrada = arregloPublicaciones.filter(
              (dato) => dato.estatus === 1
            );

            // console.log(dataFiltrada);

            setPublicaciones([data]);
            setPublicacionesEmpleado(dataFiltrada);
          }
        } catch (error) {
          console.log(error);
        }
      };
      obtenerPublicaciones();
    }
  }, [tab]);

  useEffect(() => {
    // console.log(publicacionesEmpleado);
  }, [publicacionesEmpleado]);

  useEffect(() => {
    if (
      publicacionAgregada ||
      publicacionEliminada ||
      publicacionReactivada ||
      publicacionModificada
    ) {
      // console.log("Se refrescan los datos");
      const obtenerPublicaciones = async () => {
        try {
          const { data } = await getPublicacionesByGrupo(idGrupo);
          // console.log(data);

          if (Array.isArray(data)) {
            setPublicaciones(data);
          } else {
            setPublicaciones([data]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      obtenerPublicaciones();
      setPublicacionAgregada(false);
      setPublicacionEliminada(false);
      setPublicacionModificada(false);
    }
  }, [
    publicacionAgregada,
    publicacionEliminada,
    publicacionReactivada,
    publicacionModificada,
  ]);

  return (
    isTabActive &&
    <Row className="mt-4">
      <Col>
        <Col className="d-flex justify-content-between mb-3 p-0">
          <h3>Publicaciones</h3>
          {rol === "asesor" && (
            <Button
              onClick={handleAgregarPublicacion}
              className="btn-sm"
              variant="success"
            >
              <FontAwesomeIcon icon={faPlus} /> Nueva publicación
            </Button>
          )}
        </Col>

        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={publicacionSeleccionada}
        >
          <Row className="mb-5" style={{ gap: "1rem" }}>
            <Col className="contenedor-publicaciones" sm={3}>
              <Nav
                variant="pills"
                className="flex-column nav-publicaciones text-center"
              >
                {/* //*AQUI SE LISTARAN LAS PUBLICACIONES */}
                {rol === "asesor" ? (
                  publicaciones.length > 0 ? (
                    publicaciones.map((publicacion, key) => (
                      <Nav.Item className="mb-3" key={key}>
                        <Nav.Link
                          onClick={() => setPublicacionSeleccionada(key)}
                          className="text-capitalize"
                          eventKey={key}
                        >
                          {publicacion.titulo}
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  ) : (
                    <p>Sin publicaciones</p>
                  )
                ) : publicacionesEmpleado.length > 0 ? (
                  publicacionesEmpleado.map((publicacion, key) => (
                    <Nav.Item className="mb-3" key={key}>
                      <Nav.Link
                        onClick={() => setPublicacionSeleccionada(key)}
                        className="text-capitalize"
                        eventKey={key}
                      >
                        {publicacion.titulo}
                      </Nav.Link>
                    </Nav.Item>
                  ))
                ) : (
                  <p>Sin publicaciones</p>
                )}
              </Nav>
            </Col>

            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey={publicacionSeleccionada}>
                  {rol === "asesor" ? (
                    publicaciones.length > 0 ? (
                      publicaciones.map(
                        (publicacion, key) =>
                          publicacionSeleccionada === key && (
                            <Row
                              key={key}
                              style={{
                                backgroundColor: "whitesmoke",
                                borderRadius: "10px",
                                marginBottom: "1rem",
                              }}
                            >
                              <Col style={{ height: "auto" }} className="mt-3">
                                <Row>
                                  <Col
                                    className={`col-12 ${
                                      publicacion.estatus === 1
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {publicacion.estatus === 1
                                      ? "Activa"
                                      : "Inactiva"}
                                  </Col>

                                  <Col>
                                    <TitleDashboard
                                      title={publicacion.titulo}
                                    />
                                  </Col>

                                  <Col className="col-2 text-center">
                                    {rol === "asesor" && (
                                      <DropdownButton
                                        style={{ fontSize: "10px" }}
                                        className="text-end menu-ruta"
                                        id={`dropdown-button-drop-left`}
                                        drop={"left"}
                                        variant="transparent"
                                        title={
                                          <FontAwesomeIcon
                                            className="pointer"
                                            icon={faEllipsisV}
                                          />
                                        }
                                      >
                                        {publicacion.estatus === 1 ? (
                                          <Dropdown.Item
                                            onClick={handleEliminarPublicacion}
                                            id={key}
                                            eventKey="1"
                                          >
                                            Desactivar publicación
                                          </Dropdown.Item>
                                        ) : (
                                          <Dropdown.Item
                                            onClick={handleReactivarPublicacion}
                                            id={key}
                                            eventKey="1"
                                          >
                                            Restaurar publicación
                                          </Dropdown.Item>
                                        )}

                                        <Dropdown.Item
                                          onClick={handleEditarPublicacion}
                                          id={key}
                                          eventKey="1"
                                        >
                                          Modificar
                                        </Dropdown.Item>
                                      </DropdownButton>
                                    )}
                                  </Col>
                                </Row>

                                <small>
                                  Creado el {getFechaSinHora(publicacion.fecha)}
                                </small>

                                <Row className="mt-3">
                                  <Col>
                                    <p>{publicacion.descripcion}</p>
                                  </Col>
                                </Row>

                                {publicacion.archivoActividad === null ||
                                publicacion.archivoActividad === "" ? (
                                  <p>Sin archivo adjunto</p>
                                ) : (
                                  <>
                                    <Row className="mt-3 mb-3">
                                      <Col>
                                        <Button
                                          onClick={handleDescargarArchivo}
                                          value={publicacion.idPublicacion}
                                          name={publicacion.archivoActividad}
                                          className="btn-sm"
                                        >
                                          <FontAwesomeIcon
                                            style={{ marginRight: ".5rem" }}
                                            icon={faFile}
                                          />{" "}
                                          Descargar material de apoyo
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                            </Row>
                          )
                      )
                    ) : (
                      <Col
                        className="text-center mt-5"
                        style={{ color: "grey" }}
                      >
                        <FontAwesomeIcon
                          icon={faListAlt}
                          className="mb-3"
                          size="4x"
                        />
                        <h4>Sin publicaciones por el momento </h4>
                      </Col>
                    )
                  ) : publicacionesEmpleado.length > 0 ? (
                    publicacionesEmpleado.map(
                      (publicacion, key) =>
                        publicacionSeleccionada === key && (
                          <Row
                            key={key}
                            style={{
                              backgroundColor: "whitesmoke",
                              borderRadius: "10px",
                              marginBottom: "1rem",
                            }}
                          >
                            <Col style={{ height: "auto" }} className="mt-3">
                              <Row>
                                {rol === "asesor" && (
                                  <Col
                                    className={`col-12 ${
                                      publicacion.estatus === 1
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {publicacion.estatus === 1
                                      ? "Activo"
                                      : "Inactivo"}
                                  </Col>
                                )}
                                <Col>
                                  <TitleDashboard title={publicacion.titulo} />
                                </Col>
                                <Col className="col-2 text-center">
                                  {rol === "asesor" && (
                                    <DropdownButton
                                      style={{ fontSize: "10px" }}
                                      className="text-end menu-ruta"
                                      id={`dropdown-button-drop-left`}
                                      drop={"left"}
                                      variant="transparent"
                                      title={
                                        <FontAwesomeIcon
                                          className="pointer"
                                          icon={faEllipsisV}
                                        />
                                      }
                                    >
                                      {publicacion.estatus === 1 ? (
                                        <Dropdown.Item
                                          onClick={handleEliminarPublicacion}
                                          id={key}
                                          eventKey="1"
                                        >
                                          Desactivar publicación
                                        </Dropdown.Item>
                                      ) : (
                                        <Dropdown.Item
                                          onClick={handleReactivarPublicacion}
                                          id={key}
                                          eventKey="1"
                                        >
                                          Restaurar publicación
                                        </Dropdown.Item>
                                      )}

                                      <Dropdown.Item
                                        onClick={handleEditarPublicacion}
                                        id={key}
                                        eventKey="1"
                                      >
                                        Modificar
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  )}
                                </Col>
                              </Row>

                              <small>
                                Creado el {getFechaSinHora(publicacion.fecha)}
                              </small>

                              <Row className="mt-3">
                                <Col>
                                  <p>{publicacion.descripcion}</p>
                                </Col>
                              </Row>

                              <Row className="mt-3 mb-3">
                                <Col>
                                  <Button
                                    onClick={handleDescargarArchivo}
                                    value={publicacion.idPublicacion}
                                    name={publicacion.archivoActividad}
                                    className="btn-sm"
                                  >
                                    <FontAwesomeIcon
                                      style={{ marginRight: ".5rem" }}
                                      icon={faFile}
                                    />{" "}
                                    Descargar material de apoyo
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                    )
                  ) : (
                    <Col className="text-center mt-5" style={{ color: "grey" }}>
                      <FontAwesomeIcon
                        icon={faListAlt}
                        className="mb-3 icon"
                        size="4x"
                      />
                      <h4>Sin publicaciones por el momento</h4>
                    </Col>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default Publicaciones;
