import { Col, Container, Row } from "react-bootstrap";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import React, { useState } from "react";
import {
  faEdit,
  faEye,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  getNotificaciones,
  reactivateNotificacion,
} from "@helpers/httpRequests/httpRequestsNotificaciones/httpRequestsNotificaciones";

import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { EditarNotificacion } from "../Modales/EditarNotificacion/EditarNotificacion";
import { EliminarNotificacion } from "../Modales/EliminarNotificacion/EliminarNotificacion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VerNotificacion } from "../Modales/VerNotificacion/VerNotificacion";
import columnas from "./columnas";
import { useEffect } from "react";

export const TablaNotificaciones = ({
  notificacionAgregada,
  setNotificacionAgregada,
}) => {
  const [notificaciones, setNotificaciones] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [notificacionEditada, setNotificacionEditada] = useState(false);

  const [notificacionEliminada, setNotificacionEliminada] = useState(false);

  const [notificacionReactivada, setNotificacionReactivada] = useState(false);

  useEffect(() => {
    const consultarNotificaciones = async () => {
      try {
        const { data: notificaciones } = await getNotificaciones();

        setNotificaciones(notificaciones);
      } catch (error) {
        console.log("Error al consultar las  notificaciones: ", error);
      }
    };

    consultarNotificaciones();
  }, []);

  useEffect(() => {
    if (
      notificacionAgregada ||
      notificacionEditada ||
      notificacionEliminada ||
      notificacionReactivada
    ) {
      const consultarNotificaciones = async () => {
        try {
          const { data: notificaciones } = await getNotificaciones();

          setNotificaciones(notificaciones);
        } catch (error) {
          console.log("Error al consultar las  notificaciones: ", error);
        }
      };

      consultarNotificaciones();

      setNotificacionAgregada(false);

      setNotificacionEditada(false);

      setNotificacionEliminada(false);

      setNotificacionReactivada(false);
    }
  }, [
    notificacionAgregada,
    notificacionEditada,
    notificacionEliminada,
    notificacionReactivada,
  ]);

  useEffect(() => {
    if (notificaciones.length > 0) {
      const handleVerNotificacion = ({ currentTarget }) => {
        const rowIndex = parseInt(currentTarget.id.split("-")[1]);

        const notificacion = notificaciones.filter(
          (notificacion) => notificacion.idNotificacion === rowIndex
        )[0];

        ModalSweetAlert({
          title: "Ver detalle de notificación",
          html: <VerNotificacion notificacion={notificacion} />,
          width: 700,
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleEditNotificacion = ({ currentTarget }) => {
        const rowIndex = parseInt(currentTarget.id.split("-")[1]);

        const notificacion = notificaciones.filter(
          (notificacion) => notificacion.idNotificacion === rowIndex
        )[0];

        ModalSweetAlert({
          title: "Actualizar notificación",
          icon: "info",
          width: 800,
          html: (
            <EditarNotificacion
              notificacion={notificacion}
              setNotificacionEditada={setNotificacionEditada}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleDeleteNotificacion = ({ currentTarget }) => {
        const rowIndex = parseInt(currentTarget.id.split("-")[1]);

        const notificacion = notificaciones.filter(
          (notificacion) => notificacion.idNotificacion === rowIndex
        )[0];

        ModalSweetAlert({
          title: "Eliminar notificación",
          icon: "warning",
          html: (
            <EliminarNotificacion
              notificacion={notificacion}
              setNotificacionEliminada={setNotificacionEliminada}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleReactivateNotificacion = async ({ currentTarget }) => {
        try {
          const rowIndex = parseInt(currentTarget.id.split("-")[1]);

          const { idNotificacion } = notificaciones.filter(
            (notificacion) => notificacion.idNotificacion === rowIndex
          )[0];

          await reactivateNotificacion(idNotificacion);

          Notificacion("success", "La notificación se reactivó correctamente");

          setNotificacionReactivada(true);
        } catch (error) {
          console.log("Error al reactivar la lección: ", error);

          Notificacion(
            "error",
            "Algo ocurrió al reactivar la licencia, por favor vuelve a intentar"
          );
        }
      };

      setDataTableColumns([
        ...columnas,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`ver-${row.idNotificacion}`}
                className="me-1 pointer"
                onClick={handleVerNotificacion}
              >
                <FontAwesomeIcon icon={faEye} className="text-primary" />
              </span>

              <span
                id={`edit-${row.idNotificacion}`}
                className="me-1 pointer"
                onClick={handleEditNotificacion}
              >
                <FontAwesomeIcon icon={faEdit} className="text-info" />
              </span>

              {row.estatus ? (
                <span
                  id={`delete-${row.idNotificacion}`}
                  className="ms-1 pointer"
                  onClick={handleDeleteNotificacion}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              ) : (
                <span
                  id={`reactivate-${row.idNotificacion}`}
                  className="ms-1 pointer"
                  onClick={handleReactivateNotificacion}
                >
                  <FontAwesomeIcon icon={faPowerOff} className="text-success" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          center: true,
        },
      ]);
    }
  }, [notificaciones]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <DataTablesRecipient
            columns={dataTableColumns}
            data={notificaciones}
          />
        </Col>
      </Row>
    </Container>
  );
};
