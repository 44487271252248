import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getCantidadRutasPorCurso = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: rutas } = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.getRutasPorCurso}`,
        method: "GET",
      });
      resolve(rutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const getRutasActivas = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: rutas } = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.getRutasActivas}`,
        method: "GET",
      });
      resolve(rutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const getRutasAprendizaje = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: rutas } = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.getRutasAprendizaje}`,
        method: "GET",
      });
      resolve(rutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const addRutaAprendizaje = (inforRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const nuevaRuta = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.addRutaAprendizaje}`,
        method: "POST",
        body: JSON.stringify(inforRuta),
      });
      resolve(nuevaRuta);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const updateRutaAprendizaje = (inforRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const modificarRuta = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.updateRutaAprendizaje}`,
        method: "POST",
        body: JSON.stringify(inforRuta),
      });
      resolve(modificarRuta);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const deactivateRutaAprendizaje = (idRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const desactivarRuta = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.deactivateRutaAprendizaje}/${idRuta}`,
        method: "POST",
      });
      resolve(desactivarRuta);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const reactivateRutaAprendizaje = (idRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reactivarRuta = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.reactivateRutaAprendizaje}/${idRuta}`,
        method: "POST",
      });
      resolve(reactivarRuta);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const getCursosRutas = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: cursosRutas } = await httpRequest({
        url: `${endpointsPaths.rutasAprendizaje}/${httpRequestsPaths.rutasAprendizaje.getCursosRutas}`,
        method: "GET",
      });
      resolve(cursosRutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};
