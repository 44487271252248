export const shorts = [
	{
		embebido: (
			<iframe
				src="https://player.vimeo.com/video/871535767?h=22a6cfa50e"
				width="auto"
				height="564"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
				title="Cambia facilmente a formato de porcentaje"></iframe>
		),
		nombre: "Cambia facilmente a formato de porcentaje",
		descripcion: "Aplica un formato de porcentaje a tus datos de forma facil y rápida.",
		codigoLeccion: "EX210215",
		nombreLeccion: "Porcentaje y moneda",
		curso: "EX21",
		portada: "microsoft excel.png",
	},
	{
		embebido: <iframe src="https://player.vimeo.com/video/871535740?h=8c2a5359ce" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="AutoRelleno"></iframe>,
		nombre: "AutoRelleno",
		descripcion: "Selecciona un rango de celdas e inserta el mismo dato en ellas al mismo tiempo.",
		codigoLeccion: "EI160407",
		nombreLeccion: "Relleno rápido",
		curso: "EI16",
		portada: "autorelleno.png",
	},
	{
		embebido: (
			<iframe
				src="https://player.vimeo.com/video/871535711?h=3bd19e6a0c"
				width="auto"
				height="564"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
				title="Aumenta tu productividad"></iframe>
		),
		nombre: "Aumenta tu productividad",
		descripcion: "Facilita tu trabajo con la ayuda de ChatGPT y Excel.",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "productividad.png",
	},
	{
		embebido: <iframe src="https://player.vimeo.com/video/871535604?h=0eda7bf492" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Whatsapp"></iframe>,
		nombre: "Whatsapp",
		descripcion: "Crea botones en excel para enviar un whatsapp a tus clientes.",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "whatsapp.png",
	},
	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871535646?h=268cf6f215" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="ChatGPT 4 y Excel"></iframe>
		),
		nombre: "ChatGPT 4 y Excel",
		descripcion: "¿Quieres usar ChatGPT 4 en excel?",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "chatgpt.png",
	},
	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871535681?h=c6c7b61ec5" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Acomodar celdas"></iframe>
		),
		nombre: "Acomodar celdas",
		descripcion: "Acomoda celdas como un profesional en Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "acomodar.png",
	},
	{
		embebido: <iframe src="https://player.vimeo.com/video/871535784?h=18863d3b03" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="BuscarV"></iframe>,
		nombre: "Condicionar BuscarV",
		descripcion: "Crea una condición para que el resultado de una busqueda cambie de color",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "buscarv.png",
	},
	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536258?h=97aa8ca71e" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Separar datos"></iframe>
		),
		nombre: "Separar datos",
		descripcion: "Separa datos con la inteligencia de Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "separar.png",
	},
	{
		embebido: (
			<iframe
				src="https://player.vimeo.com/video/871536236?h=bc5417ace9"
				width="auto"
				height="564"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
				title="Presentaciones instantaneas"></iframe>
		),
		nombre: "Presentaciones en segundos",
		descripcion: "Crea maravillosas presentaciones en segundos con Tome.app",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "PP16",
		portada: "presentaciones.png",
	},
	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536210?h=806db583e0" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Tablas dinámicas"></iframe>
		),
		nombre: "¿Para qué sirven las tablas dinámicas?",
		descripcion: "Aprende qué son y para qué sirven las tablas dinámicas en Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "tablas.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536180?h=2c53c6ca97" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Pantalla completa"></iframe>
		),
		nombre: "Pantalla completa",
		descripcion: "¿Quieres mostrar en pantalla completa tus presentaciones en Excel?",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "pantalla.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536158?h=39d5055737" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Menús desplegables"></iframe>
		),
		nombre: "Menús desplegables",
		descripcion: "¿Quieres agregar menús desplegables a tus informes en Power BI?",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "PB21",
		portada: "desplegables.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536110?h=2a70d3a7f4" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Menú rotatorio"></iframe>
		),
		nombre: "Menú rotatorio",
		descripcion: "Te enseñamos como hacer un menú rotatorio en Power Point",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "PP16",
		portada: "menus.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536088?h=846db7113e" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Inmovilizar celdas"></iframe>
		),
		nombre: "Inmovilizar celdas",
		descripcion: "Aprende a usar el método secuencial para inmovilizar celdas en Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "inmovilizar.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536065?h=c3501167f2" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Grabador de Windows"></iframe>
		),
		nombre: "Grabador de windows",
		descripcion: "Aprende a grabar tu pantalla sin tener que instalar aplicaciones",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "WI10",
		portada: "grabador.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536047?h=6b4c7788af" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Función CONSECUTIVOS"></iframe>
		),
		nombre: "Función CONSECUTIVOS",
		descripcion: "Cómo crear consecutivos como todo un profesional en Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "consecutivos.png",
	},

	{
		embebido: (
			<iframe src="https://player.vimeo.com/video/871536018?h=a7cfa9697a" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Formato personalizado"></iframe>
		),
		nombre: "Formato personalizado",
		descripcion: "Aprende a dar un formato personalizado a tus fechas en Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "formato.png",
	},

	{
		embebido: <iframe src="https://player.vimeo.com/video/871535996?h=15bf9e8f05" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Filtos"></iframe>,
		nombre: "Filtros",
		descripcion: "Aprende los mejores atajos para filtros en Excel",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "EX21",
		portada: "filtros.png",
	},

	{
		embebido: <iframe src="https://player.vimeo.com/video/871535969?h=9e7710cb5e" width="auto" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Engranes"></iframe>,
		nombre: "Engranes",
		descripcion: "Aprende a realizar engranes rotatorios para tus presentaciones en Power Point",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "PP16",
		portada: "engranes.png",
	},

	{
		embebido: (
			<iframe
				src="https://player.vimeo.com/video/871535936?h=27eec11e7c"
				width="auto"
				height="564"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
				title="Efecto Zoom In en diapositiva"></iframe>
		),
		nombre: "Efecto Zoom In en diapositiva",
		descripcion: "Aprende a crear un efecto de zoom para darle mucho más estilo a tus presentaciones de Power Point",
		codigoLeccion: "EX210101",
		nombreLeccion: "Formatos en Excel",
		curso: "PP16",
		portada: "zoom.png",
	},
];