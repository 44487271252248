const columnasRegistroInvitado = [
  // {
  //     name: 'Folio',
  //     selector: (row) => row.folioContrato,
  //     sortable: true,
  //     width: '120px'
  // },
  {
    name: "Nombre",
    selector: (row) => row.nombreCompleto,
    sortable: true,
  },
  {
    name: "Correo",
    selector: (row) => row.correoEmpresa || row.correoPersonal,
    sortable: true,
  },
  {
    name: "Registrado",
    selector: (row) => (
      <span className={row.registrado ? "text-success" : "text-danger"}>
        <b>{row.registrado ? "✓" : "X"}</b>
      </span>
    ),
    sortable: true,
    center: true,
  },
];

export default columnasRegistroInvitado;
