import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AgregarNotificacion } from "./Modales/AgregarNotificacion/AgregarNotificacion";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { TablaNotificaciones } from "./DataTables/TablaNotificaciones";

export const DashboardNotificaciones = () => {
  const handleAgregarNotificacion = async () => {
    try {
      await ModalSweetAlert({
        title: "Agregar nueva notificación",
        html: (
          <AgregarNotificacion
            setNotificacionAgregada={setNotificacionAgregada}
          />
        ),
        width: 800,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar la licencia: ", error);
    }
  };

  const [notificacionAgregada, setNotificacionAgregada] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col align="left">
          <Button variant="primary" onClick={handleAgregarNotificacion}>
            Agregar notificación
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TablaNotificaciones
            notificacionAgregada={notificacionAgregada}
            setNotificacionAgregada={setNotificacionAgregada}
          />
        </Col>
      </Row>
    </Container>
  );
};
