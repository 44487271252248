import { Col, Container, Image, Row } from "react-bootstrap";

import React from "react";

const VerPortadaCurso = ({ portadaCurso }) => {
  return (
    <Container fluid className="text-center">
      <Row>
        <Col>
          <Image
            fluid
            src={
              portadaCurso.includes("base64")
                ? portadaCurso
                : require(`@assets/images/portadas/${portadaCurso}`)
            }
            alt="Portada del curso"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default VerPortadaCurso;
