import { Notificacion } from "@components/general/Alertas/Alertas";
import {
  deactivatePublicacion,
  reactivatePublicacion,
} from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ReactivarPublicacion = ({ setPublicacionReactivada, publicacion }) => {
  const { idPublicacion } = publicacion;
  // console.log(idPublicacion);

  const cancelarRehabilitarPublicacion = () => {
    Notificacion("info", "No se rehabilitó la publicación");
  };

  const RehabilitarPublicacion = async () => {
    try {
      const { message, data } = await reactivatePublicacion(idPublicacion);
      // console.log(data);
      setPublicacionReactivada(true);
      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al rehabilitar la publicación");
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de rehabilitar esta publicación?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={RehabilitarPublicacion}>
            Sí, rehabilitar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarRehabilitarPublicacion}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ReactivarPublicacion;
