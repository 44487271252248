import shuffle from 'lodash.shuffle';
import { FaArchive, FaPlus, FaChartBar, FaFacebook ,FaComputer , FaRobot, FaLightbulb, FaMousePointer, FaMeta, FaWordpress   } from 'react-icons/fa';
import { SiMicrosoftexcel, SiMicrosoftpowerpoint, SiMicrosoftword,  SiPowerbi, SiCanva } from "react-icons/si";
import { TbWorld } from "react-icons/tb";
import { GiPieChart, GiProcessor } from "react-icons/gi";
import { PiChartLineUpBold, PiPresentationChartFill  } from "react-icons/pi";
import { SiScratch, SiMicrosoftoutlook, SiAdobephotoshop,SiGooglehangouts, SiZoom, SiMeta  } from "react-icons/si";
import { BsMicrosoftTeams } from "react-icons/bs";



const NUMERO_DE_CARTAS = 20;


export const construirBaraja = () =>  {


  const reactIconsArray = [
    <SiMicrosoftexcel />,
    <SiMicrosoftword />,
    <FaArchive />,
    <SiMicrosoftpowerpoint />,
    <TbWorld />,
    <FaPlus />,
    <FaChartBar />,
    <GiPieChart />,
    <SiPowerbi />,
    <FaFacebook />,
    <SiCanva />,
    <GiProcessor />,
    <FaRobot />,
    <PiChartLineUpBold />,
    <PiPresentationChartFill />,
    <FaLightbulb />,
    <FaMousePointer />,
    <SiMeta />,
    <SiScratch />,
    <SiMicrosoftoutlook />,
    <SiAdobephotoshop />,
    <BsMicrosoftTeams />,
    <SiGooglehangouts />,
    <SiZoom />,
    <FaWordpress />
  ]

  let fontAwesomeClasses = reactIconsArray;
  let cartas = [];

  while (cartas.length < NUMERO_DE_CARTAS) {
    const index = Math.floor(Math.random() * reactIconsArray.length);
    const carta = {
      icono: fontAwesomeClasses.splice(index, 1)[0],
      fueAdivinada: false
    };

    cartas.push(carta);
    cartas.push({...carta});
  }

  return shuffle(cartas);
};