import { Pagination } from "react-bootstrap";
import React from "react";

const PaginationSections = ({ numberPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(numberPages + 1).keys()].slice(1);

  const handlePreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage !== numberPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Pagination size="lg" className="justify-content-center">
      <Pagination.Prev onClick={handlePreviousPage} />

      {pageNumbers.map((pageNumber) => (
        <Pagination.Item
          key={pageNumber}
          active={currentPage === pageNumber}
          onClick={() => setCurrentPage(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      ))}

      <Pagination.Next onClick={handleNextPage} />
    </Pagination>
  );
};

export default PaginationSections;
