import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import React, { useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { Typography, Alert as MUIAlert } from "@mui/material";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import { getUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { enviarRecuperacionNombreUsuario } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";

const RecuperacionNombreUsuario = () => {
	const [correo, setCorreo] = useState("");

	const [correoIngresado, setCorreoIngresado] = useState(false);

	const [correoValido, setCorreoValido] = useState(false);

	const [showLoader, setShowLoader] = useState(false);

	const [correoErrorMessage, setCorreoErrorMessage] = useState("Ingresa tu correo electrónico");

	const handleOnChange = ({ target }) => {
		setCorreo(target.value);

		if (regExpStrings.regexEmail(target.value)) {
			setCorreoValido(true);
		}
	};

	const handleRecuperarNombreUsuario = async () => {
		try {
			setShowLoader(true);

			const datosUsuario = await getUsuario(correo);

			console.log("datos usuario: ", datosUsuario);

			if (datosUsuario.message && datosUsuario.message === "No existe ningún usuario con este correo") {
				setCorreoValido(false);

				setShowLoader(false);

				return setCorreoErrorMessage("El correo ingresado no existe, verifica tus datos");
			}

			if (!datosUsuario.usuarioTitular) {
                setCorreoValido(false);

				setShowLoader(false);

				return setCorreoErrorMessage("El correo ingresado corresponde al usuario secundario, para recuperar tu contraseña contacta a tu titular.");
			}

            await enviarRecuperacionNombreUsuario({
                correo,
                nombre: datosUsuario.nombreCompleto, 
                idCredencial: datosUsuario.idCredencial
            });

            setCorreoIngresado(true);

			setShowLoader(false);

			ModalSweetAlert({
				icon: "success",
				title: "Enviamos un enlace para restablecer tu contraseña",
				html: (
					<>
						El enlace fue enviado a la dirección{" "}
						<Typography variant="overline">
							<b className="text-lowercase">{correo}</b>
						</Typography>
						<br />
						Ingresa a tu correo para recuperar tu nombre de usuario.
					</>
				),
			});
		} catch (error) {
			console.log("error al recuperar el nombre de usuario: ", error);
		}
	};

	return (
		<Container fluid className="formRegistro vh-100" style={{ overflowY: "hidden" }}>
			<Row className="justify-content-center align-items-center vh-100">
				<Col md={8} lg={7}>
					<Card
						body
						className="p-2"
						style={{
							borderRadius: "1.25rem",
						}}>
						<a style={{ textDecoration: "none" }} href={dashboardRoutesPaths.login} className="text-secondary pointer">
							<FontAwesomeIcon icon={faArrowLeft} /> Iniciar sesión
						</a>

						<Typography gutterBottom variant="h5" className="mt-3 text-center">
							Recupera tu Nombre de usuario
						</Typography>

						<Row className="justify-content-center">
							<div className="imgKey m-4"></div>
						</Row>

						<Container fluid>
							{!correoIngresado ? (
								<>
									<h5 className="text-center mt-3">
										Para recuperar tu nombre de usuario es necesario ingresar el correo del titular de la licencia.
										<br />
										<br />
										Da clic en "Recuperar nombre de usuario" para que te enviemos tu nombre de usuario. Te enviaremos un enlace por correo electrónico.
									</h5>

									<Row className="my-4 mb-3 pb-5 justify-content-center">
										<Col>
											<FloatingLabel label="Correo electrónico" className="mb-3">
												<Form.Control type="text" placeholder="Ingresa el correo registrado en tu cuenta" onChange={handleOnChange} value={correo} name="correo" autoComplete="off" />
											</FloatingLabel>

											<MUIAlert severity="error">{correoErrorMessage}</MUIAlert>

											<div className="d-grid gap-2 mt-3">
												<Button onClick={handleRecuperarNombreUsuario} variant="primary" size="lg">
													{showLoader ? (
														<>
															Enviando correo... <FontAwesomeIcon icon={faSpinner} spin />
														</>
													) : (
														<>Recuperar nombre de usuario</>
													)}
												</Button>
											</div>
										</Col>
									</Row>
								</>
							) : (
								<>
									<h5 className="text-center mt-3">
										Hemos enviado un correo con tu nombre de usuario a la dirección de correo que registraste en tu Plataforma Click+.
										<br />
										<br />
										Accede a tu correo para recuperar tu nombre de usuario.
									</h5>

									<Image className="d-flex align" src={require("@assets/images/check.png")} width={150} />
								</>
							)}
						</Container>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default RecuperacionNombreUsuario;
