import { Alert, ModalSweetAlert, Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import { faEdit, faEye, faSpinner, faTrashAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addNipPerfil, getDatosUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React, { useContext, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import AddNipSeguridad from "./AddNipSeguridad";
import EliminarNipSeguridad from "./EliminarNipSeguridad";
import CambiarNipSeguridad from "./CambiarNipSeguridad";

const TabNipSeguridad = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { nip } = usuarioLogeado;

	const [nipActivo, setNipActivo] = useState(nip === "" ? false : true);

	const handleCambiarNip = async () => {
		await ModalSweetAlert({
			title: "Cambiar NIP de seguridad",
			icon: "info",
			html: <CambiarNipSeguridad setNipActivo={setNipActivo} idPerfil={usuarioLogeado.idPerfil} contextValue={contextValue} setContextValue={setContextValue} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const handleEliminarNip = async () => {
		await ModalSweetAlert({
			title: "Eliminar NIP de seguridad",
			icon: "warning",
			html: <EliminarNipSeguridad setNipActivo={setNipActivo} idPerfil={usuarioLogeado.idPerfil} contextValue={contextValue} setContextValue={setContextValue} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	return (
		<Container fluid>
			<TitleDashboard title={<>Estado del NIP: {nipActivo ? "Activo" : "Inactivo"}</>} />

			{!nipActivo ? (
				<>
					<AddNipSeguridad setNipActivo={setNipActivo} />
				</>
			) : (
				<>
					<Row className="d-flex justify-content-center align-items-center text-center mb-3">
						<Col>
							<FontAwesomeIcon icon={faUserShield} className="mb-3" size="6x" />

							<h3>¿Qué deseas hacer?</h3>
						</Col>
					</Row>

					<Row>
						<Col>
							<Card className="card-option-nip" body onClick={handleCambiarNip}>
								<FontAwesomeIcon icon={faEdit} className="mb-3" size="2x" color="#007bff" />

								<h3>Cambiar NIP</h3>
							</Card>
						</Col>

						<Col>
							<Card className="card-option-nip" body onClick={handleEliminarNip}>
								<FontAwesomeIcon icon={faTrashAlt} className="mb-3" size="2x" color="#dc3545" />
								<h3>Eliminar NIP</h3>
							</Card>
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};

export default TabNipSeguridad;
