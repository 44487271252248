import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deleteTransmission } from "@helpers/httpRequests/httpRequestsAulaVirtual/httpRequestsAulaVirtual";

const EliminarTransmision = ({ setTransmisionEliminada, transmision }) => {
  const { idTransmision } = transmision;

  const handleCancelar = () => {
    Notificacion("info", "La transmisión no fue eliminada");

    setTransmisionEliminada(true);
  };

  const handleEliminarTransmision = async () => {
    try {
      const transmisionEliminada = await deleteTransmission(idTransmision);

      console.log("transmission eliminada", transmisionEliminada);

      Notificacion("success", "Transmisión eliminada correctamente");

      setTransmisionEliminada(true);
    } catch (error) {
      console.log("Error al eliminar la transmisión: ", error);

      Notificacion("error", error.message);
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>
        ¿Estás seguro que deseas eliminar la transmisión con ID {idTransmision}?
      </h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleEliminarTransmision}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarTransmision;
