import { useEffect, useLayoutEffect, useState } from "react";

const useWindowResize = () => {
  const [size, setSize] = useState([0, 0]);

  const [sizeChanged, setSizeChanged] = useState(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setSizeChanged(true);
  }, [sizeChanged]);

  return {
    size,
    sizeChanged,
  };
};

export default useWindowResize;
