const localStorageItemNames = {
  userContext: "userContext",
  multiViewContext: "multiViewContext",
  multiViewPanelAdministracion: "multiViewPanelAdministracion",
  datosUsuario: "datosUsuario",
  datosUsuarioSecundario: "datosUsuarioSecundario",
  token: "token",
  busquedaUsuarioActiva: "busquedaUsuarioActiva",
  busquedaUsuarioData: "busquedaUsuarioData",
};

export default localStorageItemNames;
