import { Notificacion } from "@components/general/Alertas/Alertas";
import {
  updateNombreUsuario,
  updatePerfil,
} from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { validateDisponibilidadNombreUsuario } from "@helpers/httpRequests/httpRequestsValidaciones/httpRequestsValidaciones";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import React from "react";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const EditarInvitado = ({ invitado, setInvitadoEditado }) => {
  const [formValues, setFormValues] = useState(invitado);

  const [validacionCorreo, setValidacionCorreo] = useState(true);

  const [validacionNombreUsuario, setValidacionNombreUsuario] = useState(true);

  const handleOnChange = async ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      });

      switch (true) {
        case target.name === "correo":
          setValidacionCorreo(regExpStrings.regexEmail(formValues.correo));
          break;

        case target.name === "nombreUsuario":
          if (target.value === "") {
            return setValidacionNombreUsuario(false);
          }

          if (target.value === invitado.nombreUsuario) {
            return setValidacionNombreUsuario(true);
          }

          if (target.value.length < 8) {
            return setValidacionNombreUsuario(false);
          }

          try {
            const { data: validacionNombreUsuario } =
              await validateDisponibilidadNombreUsuario(target.value);

            setValidacionNombreUsuario(validacionNombreUsuario);
          } catch (error) {
            const { data: usuarioNoDisponible } = error;

            setValidacionNombreUsuario(usuarioNoDisponible);
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("Error al establecer los valores del formulario: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!regExpStrings.regexEmail(formValues.correo)) {
        setValidacionCorreo(false);

        return NotificationManager.error("Ingresa un correo válido");
      }

      if (formValues.nombreUsuario !== invitado.nombreUsuario) {
        await updateNombreUsuario({
          nombreUsuario: formValues.nombreUsuario,
          idCredencial: invitado.idCredencial,
        });
      }

      await updatePerfil(formValues);

      Notificacion(
        "success",
        "Los datos del estudiante se actualizaron correctamente."
      );

      setInvitadoEditado(true);
    } catch (error) {
      console.log("Error al actualizar los datos del asesor: ", error);

      if (error.code === 500) {
        const { data } = error;

        if (data.code === "ER_DUP_ENTRY") {
          setValidacionCorreo(false);

          return NotificationManager.error(
            "El correo ingresado ya está siendo utilizado, ingresa un correo distinto"
          );
        }
      }

      NotificationManager.error(
        "No se pudo actualizar al asesor. \n" + error.message
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid className="text-start">
        <Row>
          <Form.Group as={Col} controlId="nombres">
            <Form.Label>Nombre(s)</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              name="nombreCompleto"
              type="text"
              placeholder="Escribe tu nombre(s)"
              value={formValues.nombreCompleto}
              autoComplete="off"
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="apellidos">
            <Form.Label>Apellidos</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              name="apellidos"
              type="text"
              placeholder="Escribe tus apellidos"
              value={formValues.apellidos}
              autoComplete="off"
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="correo">
            <Form.Label>Nombre de usuario</Form.Label>

            <Form.Control
              className={validacionNombreUsuario ? "is-valid" : "is-invalid"}
              onChange={handleOnChange}
              name="nombreUsuario"
              type="text"
              placeholder="Escribe tu nombre de usuario"
              value={formValues.nombreUsuario}
              autoComplete="off"
            />

            {!validacionNombreUsuario && (
              <Alert variant="danger" className="mt-3">
                El nombre de usuario debe tener al menos 8 caracteres
              </Alert>
            )}
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="correo">
            <Form.Label>Correo</Form.Label>

            <Form.Control
              className={validacionCorreo ? "is-valid" : "is-invalid"}
              onChange={handleOnChange}
              name="correo"
              type="text"
              placeholder="Escribe tu correo"
              value={formValues.correo}
              autoComplete="off"
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="curp">
            <Form.Label>CURP</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              name="curp"
              type="text"
              placeholder="Escribe tu curp"
              value={formValues.curp.toUpperCase()}
              autoComplete="off"
              maxLength={18}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="curp">
            <Form.Label>Profesión</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              name="profesion"
              type="text"
              placeholder="Ingresa tu profesion"
              value={formValues.profesion}
              autoComplete="off"
            />
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <div className="d-grid gap-2">
              <Button type="submit" variant="success" size="lg">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default EditarInvitado;
