const columns = [
  // {
  //     name: "#",
  //     selector: row => row.idLeccion,
  //     sortable: true,
  //     center: true,
  //     compact: true,
  //     width: '40px'
  // },
  {
    name: "Nombre de la lección",
    selector: (row) => row.nombre,
    sortable: true,
    center: true,
    compact: true,
    width: "560px",
  },

  {
    name: "Estatus",
    selector: (row) => (row.estatus === 1 ? "Activa" : "Inactiva"),
    sortable: true,
    center: true,
    compact: true,
    width: "150px",
  },
];

export default columns;
