import { Notificacion } from "@components/general/Alertas/Alertas";
import { deactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const DesactivarUsuario = ({ usuario }) => {
	const handleDeactivateUsuario = async () => {
		try {
			await deactivateUsuario(usuario.idCredencial);

			Notificacion("success", "El usuario se desactivó correctamente");
		} catch (error) {
			console.log("Error al eliminar al usuario seleccionado: ", error);

			Notificacion("error", "No se pudo eliminar el usuario seleccionado.");
		}
	};

	const cancelarDeleteUsuario = () => {
		Notificacion("info", "No se desactivó el usuario seleccionado");
	};

	return (
		<Container fluid className="text-center">
			<h3>¿Estás seguro de desactivar a este estudiante?</h3>

			<Row className="pt-3">
				<Col>
					<Button variant="success" onClick={handleDeactivateUsuario}>
						Sí, desactivar
					</Button>
				</Col>

				<Col>
					<Button variant="danger" onClick={cancelarDeleteUsuario}>
						No, cancelar
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default DesactivarUsuario;
