import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { getInvitados } from "@helpers/httpRequests/httpRequestsUsuarios/invitados/httpRequestsInvitados";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import columnasInvitadoExistente from "./columnasInvitadoExistente";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import { reactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import VerInvitado from "../../Modales/VerInvitado/VerInvitado";
import EditarInvitado from "../../Modales/EditarInvitado/EditarInvitado";
import EliminarInvitado from "../../Modales/EliminarInvitado/EliminarInvitado";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";
import { FaEdit, FaEye, FaPowerOff, FaTrash } from "react-icons/fa";

const TableInvitadosExistentes = () => {
  const [invitados, setInvitados] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [invitadoEditado, setInvitadoEditado] = useState(false);

  const [invitadoEliminado, setInvitadoEliminado] = useState(false);

  const [invitadoReactivado, setInvitadoReactivado] = useState(false);

  const handleVerDatosInvitado = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id.split("-")[1]);

      const dataInvitado = invitados.filter(
        (invitado) => invitado.idPersona === idPersona
      )[0];

      await ModalSweetAlert({
        title: "Datos del usuario invitado",
        html: <VerInvitado invitado={dataInvitado} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log(
        "Error al ver los datos del estudiante seleccionado: ",
        error
      );
    }
  };

  const handleEditDatosInvitado = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataInvitado = invitados.filter(
        (invitado) => invitado.idPersona === idPersona
      )[0];

      await ModalSweetAlert({
        title: "Modificar datos del usuario invitado",
        html: (
          <EditarInvitado
            invitado={dataInvitado}
            setInvitadoEditado={setInvitadoEditado}
          />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al editar los datos del estudiante: ", error);
    }
  };

  const handleDeactivateInvitado = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataInvitado = invitados.filter(
        (invitado) => invitado.idPersona === idPersona
      )[0];

      await ModalSweetAlert({
        title: "Eliminar usuario invitado",
        icon: "warning",
        html: (
          <EliminarInvitado
            invitado={dataInvitado}
            setInvitadoEliminado={setInvitadoEliminado}
          />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al desactivar al estudiante: ", error);
    }
  };

  const handleReactivateInvitado = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataInvitado = invitados.filter(
        (invitado) => invitado.idPersona === idPersona
      )[0];

      await reactivateUsuario(dataInvitado.idCredencial);

      setInvitadoReactivado(true);

      Notificacion("success", "El usuario invitado se reactivó correctamente");
    } catch (error) {
      console.log("Error al reactivar al usuario invitado: ", error);
    }
  };

  useEffect(() => {
    const consultarInvitados = async () => {
      try {
        let { data: invitados } = await getInvitados();

        if (!Array.isArray(invitados)) {
          invitados = [invitados];
        }

        setInvitados(invitados);
      } catch (error) {
        console.log("Error al consultar a los usuarios invitados: ", error);
      }
    };

    consultarInvitados();
  }, []);

  // useEffect(() => {
  //     if (invitados.length > 0) {

  //         setDataTableColumns([
  //             ...columnasInvitadoExistente,
  //             {
  //                 name: 'Acciones',
  //                 cell: (row) => (
  // 					<>
  // 						<span id={`select-${row.idPersona}`} className="me-1 pointer" onClick={handleVerDatosInvitado}>
  // 							<FontAwesomeIcon icon={faEye} className='text-primary' />
  // 						</span>

  // 						<span id={`edit-${row.idPersona}`} className="me-1 pointer" onClick={handleEditDatosInvitado}>
  // 							<FontAwesomeIcon icon={faEdit} className='text-info' />
  // 						</span>

  // 						{row.estatusCredencial ? (
  // 							<span id={`delete-${row.idPersona}`} className="me-1 pointer" onClick={handleDeactivateInvitado}>
  // 								<FontAwesomeIcon icon={faTrashAlt} className='text-danger' />
  // 							</span>
  // 						) : (
  // 							<span id={`reactivate-${row.idPersona}`} className="me-1 pointer" onClick={handleReactivateInvitado}>
  // 								<FontAwesomeIcon icon={faPowerOff} className='text-success' />
  // 							</span>
  // 						)}
  // 					</>
  // 				),
  // 				ignoreRowClick: true,
  // 				allowOverflow: true,
  // 				button: true,
  //             }
  //         ]);
  //     }
  // }, [invitados]);

  useEffect(() => {
    if (invitadoEditado || invitadoEliminado || invitadoReactivado) {
      const consultarInvitados = async () => {
        try {
          let { data: invitados } = await getInvitados();

          if (!Array.isArray(invitados)) {
            invitados = [invitados];
          }

          setInvitados(invitados);

          setInvitadoEditado(false);

          setInvitadoEliminado(false);

          setInvitadoReactivado(false);
        } catch (error) {
          console.log("Error al consultar a los usuarios invitados: ", error);
        }
      };

      consultarInvitados();
    }
  }, [invitadoEditado, invitadoEliminado, invitadoReactivado]);

  const columnas = useMemo(() => [
    {
      accessorKey: "nombreCompleto",
      header: "Nombre",
    },
    {
      accessorKey: "apellidos",
      header: "Apellidos",
    },
    {
      accessorKey: "correo",
      header: "Correo",
    },
  ]);

  return (
    <Container fluid>
      <Row>
        <Col>
          {/* <DataTablesRecipient columns={dataTableColumns} data={invitados} /> */}

          <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={columnas}
            data={invitados}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                <IconButton
                  color="primary"
                  id={row.original.idPersona}
                  onClick={handleVerDatosInvitado}
                >
                  <FaEye className="text-info" />
                </IconButton>

                <IconButton
                  color="secondary"
                  id={row.original.idPersona}
                  onClick={handleEditDatosInvitado}
                >
                  <FaEdit className="text-primary" />
                </IconButton>

                {row.original.estatusCredencial ? (
                  <IconButton
                    color="secondary"
                    id={`${row.original.idPersona}`}
                    onClick={handleDeactivateInvitado}
                  >
                    <FaTrash color="red" />
                  </IconButton>
                ) : (
                  <IconButton
                    color="secondary"
                    id={`${row.original.idPersona}`}
                    onClick={handleReactivateInvitado}
                  >
                    <FaPowerOff color="green" />
                  </IconButton>
                )}
              </Box>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TableInvitadosExistentes;
