import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import columnas from "./columnas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import EliminarElementoPapelera from "../Modales/EliminarElementoPapelera/EliminarElementoPapelera";

const TablePapeleraContenidoCurso = ({ elementos, setElementoEliminado }) => {
  const [dataTableColumns, setDataTableColumns] = useState([]);

  const { seleccion, data: elementosPapelera } = elementos;

  const seleccionCodigoMap = {
    cursos: "codigoCurso",
    secciones: "codigoSeccion",
    lecciones: "codigoLeccion",
  };

  useEffect(() => {
    if (elementos.data.length > 0) {
      const handleDeleteElement = async ({ currentTarget }) => {
        const codigo = currentTarget.id.split("-")[2];

        const propiedad = seleccionCodigoMap[seleccion];

        const elementoPapelera = elementosPapelera.find(
          (elemento) => elemento[propiedad] === codigo
        );

        await ModalSweetAlert({
          title: "Eliminar elemento de la papelera",
          html: (
            <EliminarElementoPapelera
              elementoPapelera={elementoPapelera}
              seleccion={seleccion}
              setElementoEliminado={setElementoEliminado}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      setDataTableColumns([
        ...columnas,
        {
          name: "Eliminar permanentemente",
          cell: (row) => {
            let id = "";

            if (row.codigoCurso && !row.codigoSeccion && !row.codigoLeccion) {
              id = `delete-${seleccion}-${row.codigoCurso}`;
            } else if (row.codigoSeccion && !row.codigoLeccion) {
              id = `delete-${seleccion}-${row.codigoSeccion}`;
            } else if (row.codigoLeccion && !row.codigoCurso) {
              id = `delete-${seleccion}-${row.codigoLeccion}`;
            }

            return (
              <span
                id={id}
                className="me-1 pointer"
                onClick={handleDeleteElement}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
              </span>
            );
          },
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          // center: true,
          width: "400px",
        },
      ]);
    }
  }, [elementos]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <DataTablesRecipient
            columns={dataTableColumns}
            data={elementosPapelera}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TablePapeleraContenidoCurso;
