const LeccionesDeApoyo = [
  {
    nombreLeccion: "Accesibilidad",
    codigoLeccion: "MOSSRC01",
  },
  {
    nombreLeccion: "Agregar una serie de datos",
    codigoLeccion: "MOSSRC02",
  },
  {
    nombreLeccion: "Aplicar formato de número",
    codigoLeccion: "MOSSRC03",
  },
  {
    nombreLeccion: "Contar.si",
    codigoLeccion: "MOSSRC04",
  },
  {
    nombreLeccion: "Configurar las opciones de estilo de tabla",  
    codigoLeccion: "MOSSRC05",
  },
  {
    nombreLeccion: "Insertar fila de totales",
    codigoLeccion: "MOSSRC06",
  },
  {
    nombreLeccion: "Inspeccionar un libro para comprobar si hay problemas de compatibilidad",
    codigoLeccion: "MOSSRC07",
  },
  {
    nombreLeccion: "Fórmula IZQUIERDA, DERECHA Y MEDIANA",
    codigoLeccion: "MOSSRC08",
  },
  {
    nombreLeccion: "Tablas dinámicas",
    codigoLeccion: "MOSSRC09",
  },
  {
    nombreLeccion: "Modificar propiedades de formas",
    codigoLeccion: "MOSSRC10",
  },
  {
    nombreLeccion: "Ocultar y mostrar columnas",
    codigoLeccion: "MOSSRC11",
  },
  {
    nombreLeccion: "Concatenar",
    codigoLeccion: "MOSSRC12",
  },
  {
    nombreLeccion: "Ordenar columnas múltiples",
    codigoLeccion: "MOSSRC13",
  },
  {
    nombreLeccion: "Sumar.si",
    codigoLeccion: "MOSSRC14",
  },
  {
    nombreLeccion: "Insertar referencias",
    codigoLeccion: "MOSSRC15",
  },
  {
    nombreLeccion: "Inspeccionar propiedades",
    codigoLeccion: "MOSSRC16",
  },
  {
    nombreLeccion: "Insertar minigráficos",
    codigoLeccion: "MOSSRC17",
  },
  {
    nombreLeccion: "Modificar propiedades de un documento",
    codigoLeccion: "MOSSRC18",
  },
  {
    nombreLeccion: "Organizar datos por columnas múltiples",
    codigoLeccion: "MOSSRC19",
  },
  {
    nombreLeccion: "Texto altenativo",
    codigoLeccion: "MOSSRC20",
  },
  {
    nombreLeccion: "Insertar cuadro de texto y formas",
    codigoLeccion: "MOSSRC21",
  },
  {
    nombreLeccion: "ZOOM",
    codigoLeccion: "MOSSRC22",
  },
];

export { LeccionesDeApoyo };
