import Loader from "@helpers/loader/loader";
import React from "react";
import { useState } from "react";
import { Animated } from "react-animated-css";

const TransmisionEnVivo = ({ transmision }) => {
  const [mainIframeLoading, setMainIframeLoading] = useState(true);

  const handleIframeLoad = (e) => {
    setMainIframeLoading(false);
  };

  // useEffect(() => {
  //     console.log(transmision);
  // }, []);

  return (
    <>
      {mainIframeLoading && (
        <Loader height={"20vh"} setShowLoader={setMainIframeLoading} />
      )}

      {transmision ? (
        <>
          <Animated
            animationIn="fadeIn"
            animationInDuration="2000"
            animationOut="fadeOut"
            isVisible={true}
          >
            <div className={mainIframeLoading ? "d-none" : ""}>
              <h5>{transmision.titulo}</h5>

              <div className="ratio ratio-21x9">
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="embed-responsive-item"
                  // frameBorder="0"
                  src={transmision.urlTransmision}
                  title={transmision.titulo}
                  onLoad={handleIframeLoad}
                />
              </div>
            </div>
          </Animated>
        </>
      ) : (
        <h2>No hay transmisiones para mostrar</h2>
      )}
    </>
  );
};

export default TransmisionEnVivo;
