import { Notificacion } from "@components/general/Alertas/Alertas";
import { deleteRespuestaTema } from "@helpers/httpRequests/httpRequestsRespuestasTema/httpRequestsRespuestasTema";
import { deleteComentario } from "@helpers/httpRequests/httpRequestsTemas/httpRequestsComentariosTemas/httpRequestsComentariosTemas";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarComentario = ({
  comentario,
  setComentarioEliminado,
  setReporteComentarioAtendido,
}) => {

  const cancelarEliminarComentario = () => {
    Notificacion("info", "No se eliminó el tema");
  };

  const eliminarComentario = async () => {
    try {
      const { message } = await deleteRespuestaTema(parseInt(comentario));

      setComentarioEliminado(true);

      console.log(message);

      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al desactivar el consejo");
      console.log(error);
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de eliminar el tema seleccionado?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={eliminarComentario}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarEliminarComentario}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarComentario;
