import { getLicencia, getLicencias } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { updateLicenciaUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { Notificacion } from "@components/general/Alertas/Alertas";

const spinnerStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

const CambioLicencia = ({ usuario, setRefetchUsuarios }) => {
	const [cursosUsuario, setCursosUsuario] = useState([]);

	const [cursosLicenciaElegida, setCursosLicenciaElegida] = useState([]);

	const [licencias, setLicencias] = useState([]);

	const [licenciaElegida, setLicenciaElegida] = useState({});

	const [showSpinner, setShowSpinner] = useState(false);

	const [updating, setUpdating] = useState(false);

	const [rolNuevo, setRolNuevo] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (cursosLicenciaElegida.length === 0) {
			return NotificationManager.error("Elige una licencia antes de continuar");
		}

		setUpdating(true);

		try {
			let auxCursos = JSON.parse(licenciaElegida.cursos);

			if (usuario.rol === typesTiposUsuario.lecturaVeloz) {
				const auxCursosUsuario = JSON.parse(usuario.cursos);

				auxCursos = [...auxCursos, ...auxCursosUsuario];
			}

			await updateLicenciaUsuario({
				idLicencia: licenciaElegida.idLicencia,
				idPersona: usuario.idPersona,
				idCredencial: usuario.idCredencial,
				cursos: JSON.stringify(auxCursos),
				rol: typesTiposUsuario.estudiante,
			});

			Notificacion("success", "Licencia del usuario cambiada con éxito");

			setUpdating(false);
		} catch (error) {
			console.log("Error al cambiar la licencia");
		}
	};

	const handleSeleccionLicencia = async ({ target }) => {
		if (target.value === "") {
			setLicenciaElegida({});

			setCursosLicenciaElegida([]);

			return NotificationManager.error("Elige una licencia antes de continuar");
		}

		try {
			setShowSpinner(true);

			const { data: licencia } = await getLicencia(target.value);

			setLicenciaElegida(licencia);

			setCursosLicenciaElegida(JSON.parse(licencia.cursos));

			setShowSpinner(false);
		} catch (error) {
			console.log("Error al consultar los cursos de la licencia seleccionada: ", error);
		}
	};

	useEffect(() => {
		const consultarLicencias = async () => {
			try {
				let { data: licencias } = await getLicencias();

				if (!Array.isArray(licencias)) {
					licencias = [licencias];
				}

				setLicencias(
					licencias.filter(
						(licencia) =>
							!licencia.licenciaEmpresarial &&
							// !licencia.licenciaInvitado &&
							licencia.nombre !== usuario.nombreLicencia
					)
				);
			} catch (error) {
				console.log("Error al consultar las licencias: ", error);
			}
		};

		consultarLicencias();

		try {
			setCursosUsuario(JSON.parse(usuario.cursos));
		} catch (error) {
			console.log("Error al obtener los cursos del usuario: ", error);
		}
	}, []);

	return (
		<Container fluid>
			<Row>
				<Col>
					<h4 className="text-start">Para cambiar el rol y los cursos del usuario elegido, selecciona una licencia </h4>

					<Form className="text-start" onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Label>Elige una Licencia</Form.Label>

							<Form.Control as="select" onChange={handleSeleccionLicencia}>
								<option value="">--Selecciona una Licencia--</option>

								{licencias.map((licencia, idx) => (
									<option key={idx} value={licencia.idLicencia}>
										{licencia.nombre}
									</option>
								))}
							</Form.Control>
						</Form.Group>

						<Container fluid className="px-2 my-3">
							<Row>
								<Col className="contenedor-cursos cambio-licencia mx-2">
									<h5 className="subtitulos px-4 pt-4 pb-0">Licencia Actual: {usuario.nombreLicencia}</h5>

									<h5 className="subtitulos px-4 pt-4 pb-0">Rol asignado: {usuario.rol}</h5>

									<h5 className="subtitulos px-4 pt-4 pb-0">Cursos asignados</h5>

									<Container>
										<Row>
											{(() => {
												const chunkSize = cursosUsuario.length > 1 ? Math.ceil(cursosUsuario.length / 2) : cursosUsuario.length;

												const chunks = _.chunk(cursosUsuario, chunkSize);

												return chunks.map((chunk, idxChunk) => (
													<Col key={idxChunk}>
														<ul>
															{chunk.map((curso, idxCurso) => (
																<li key={idxCurso}>
																	<h6>{curso.nombre}</h6>
																</li>
															))}
														</ul>
													</Col>
												));
											})()}
										</Row>
									</Container>
								</Col>

								<Col className="contenedor-cursos cambio-licencia mx-2">
									<h5 className="subtitulos px-4 pt-4 pb-0">Licencia elegida: {cursosLicenciaElegida.length > 0 ? licenciaElegida.nombre : "Elige una licencia"}</h5>

									{showSpinner ? (
										<div style={spinnerStyle}>
											<Spinner animation="border" variant="primary" />
										</div>
									) : (
										<>
											<h5 className="subtitulos px-4 pt-4 pb-0">Rol a asignar: {rolNuevo}</h5>

											<h5 className="subtitulos px-4 pt-4 pb-0">Cursos a asignar</h5>

											<Container>
												<Row>
													{(() => {
														const chunkSize = cursosLicenciaElegida.length > 1 ? Math.ceil(cursosLicenciaElegida.length / 2) : cursosLicenciaElegida.length;

														const chunks = _.chunk(cursosLicenciaElegida, chunkSize);

														return chunks.map((chunk, idxChunk) => (
															<Col key={idxChunk}>
																<ul>
																	{chunk.map((curso, idxCurso) => (
																		<li key={idxCurso}>
																			<h6>{curso.nombre}</h6>
																		</li>
																	))}
																</ul>
															</Col>
														));
													})()}
												</Row>
											</Container>
										</>
									)}
								</Col>
							</Row>
						</Container>

						<div className="d-grid gap-2 mt-4">
							<Button variant="primary" type="submit" size="lg">
								{updating ? "Cambiando licencia..." : "Cambiar Licencia"}
							</Button>
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default CambioLicencia;
