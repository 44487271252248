import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getConsejos = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejos = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.getConsejos}`,
        method: "GET",
      });

      resolve(consejos);
    } catch (error) {
      // console.log("Error a obtener los consejos: ", error);

      reject(error);
    }
  });
};

export const getConsejoById = (idConsejoRapido) => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejo = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.getConsejoById}/${idConsejoRapido}`,
        method: "POST",
      });

      resolve(consejo);
    } catch (error) {
      // console.log("Error a obtener el consejo con ID: " + idConsejoRapido, error);

      reject(error);
    }
  });
};

export const getConsejosByCodigo = (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejosByCodigo = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.getConsejosByCodigo}/${codigoCurso}`,
        method: "POST",
      });

      resolve(consejosByCodigo);
    } catch (error) {
      reject(error);
    }
  });
};

export const addConsejo = (nuevoConsejo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejoAgregado = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.addConsejo}`,
        method: "POST",
        body: JSON.stringify(nuevoConsejo),
      });

      resolve(consejoAgregado);
    } catch (error) {
      // console.log("Error al agregar un consejo: ", error);

      reject(error);
    }
  });
};

export const updateConsejo = (idConsejo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejoActualizado = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.updateConsejo}`,
        method: "POST",
        body: JSON.stringify(idConsejo),
      });

      resolve(consejoActualizado);
    } catch (error) {
      // console.log("Error al actualizar el consejo: ", error);

      reject(error);
    }
  });
};

export const reactivateConsejo = (idConsejoRapido) => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejoReactivado = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.reactivateConsejo}/${idConsejoRapido}`,
        method: "POST",
      });

      resolve(consejoReactivado);
    } catch (error) {
      // console.log("Error al reactivar un consejo: ", error);

      reject(error);
    }
  });
};

export const deactivateConsejo = (idConsejoRapido) => {
  return new Promise(async (resolve, reject) => {
    try {
      const consejoDesactivado = await httpRequest({
        url: `${endpointsPaths.consejosRapidos}/${httpRequestsPaths.consejosRapidos.deactivateConsejo}/${idConsejoRapido}`,
        method: "POST",
      });

      resolve(consejoDesactivado);
    } catch (error) {
      // console.log("Error desactivando el consejo: ", error);

      reject(error);
    }
  });
};
