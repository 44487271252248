import { validateCodigoInvitado } from "@helpers/httpRequests/httpRequestsUsuarios/invitados/httpRequestsInvitados";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const ValidacionCodigoInvitado = ({ setCodigoValidado }) => {
  const [codigo, setCodigo] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validateCodigoInvitado(codigo);

      setCodigoValidado(true);

      NotificationManager.success("El código es válido");
    } catch (error) {
      console.log("Error al validar el código: ", error);

      NotificationManager.error("El código es inválido");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="text-start">
              <Form.Label>Ingresa el código a validar</Form.Label>

              <Form.Control
                onChange={({ target }) => setCodigo(target.value)}
                type="text"
                placeholder="Código de invitado"
                value={codigo}
                name="codigo"
              />
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" type="submit" size="lg">
                Validar código
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ValidacionCodigoInvitado;
