import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getFechaRegistro = (correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("Correo desde httpRequest");
      const getFecha = await httpRequest({
        url: `${endpointsPaths.usuariosMoodle}/${httpRequestsPaths.usuariosMoodle.getFechaRegistro}`,
        method: "POST",
        body: JSON.stringify({ correo }),
      });

      resolve(getFecha);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAvancesPerfil = (correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getAvances } = await httpRequest({
        url: `${endpointsPaths.usuariosMoodle}/${httpRequestsPaths.usuariosMoodle.getAvancesPerfil}`,
        method: "POST",
        body: JSON.stringify({ correo }),
      });

      resolve(getAvances);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCertificacionesPerfil = (correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getCertificaciones } = await httpRequest({
        url: `${endpointsPaths.usuariosMoodle}/${httpRequestsPaths.usuariosMoodle.getCertificacionesPerfil}`,
        method: "POST",
        body: JSON.stringify({ correo }),
      });

      resolve(getCertificaciones);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPuntajesPerfil = (correo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getPuntajes } = await httpRequest({
        url: `${endpointsPaths.usuariosMoodle}/${httpRequestsPaths.usuariosMoodle.getPuntajesPerfil}`,
        method: "POST",
        body: JSON.stringify({ correo }),
      });

      resolve(getPuntajes);
    } catch (error) {
      reject(error);
    }
  });
};
