const Proyectos = [
  {
    nombreProyecto:
      "FERRETERIAMEX: Organizar, actualizar y dar formato al libro",
    codigoProyecto: "MOS16P01",
  },
  {
    nombreProyecto: "GASTOS FAMILIARES: Estudio de mercado",
    codigoProyecto: "MOS16P02",
  },
  {
    nombreProyecto:
      "TORNILLOS DURAMAX: Aplica fórmulas y formatos a sus listados",
    codigoProyecto: "MOS16P03",
  },
  {
    nombreProyecto:
      "BANCO DEL NORTE: Analiza y crea fórmulas. Agrega información para aclaraciones a clientes",
    codigoProyecto: "MOS16P04",
  },
  {
    nombreProyecto:
      "ROPA DEPORTIVA: Realiza análisis de ventas y representación gráfica",
    codigoProyecto: "MOS16P05",
  },
  {
    nombreProyecto:
      "EMPRESA MULTIPLAZA: Informe de incidentes en las diferentes áreas (Agregando formato)",
    codigoProyecto: "MOS16P06",
  },
  {
    nombreProyecto: "NOMINA DE EMPLEADOS: Corrige errores y aplica formato",
    codigoProyecto: "MOS16P07",
  },
];

export { Proyectos };
