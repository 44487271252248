import { getCodigosInvitado } from "@helpers/httpRequests/httpRequestsUsuarios/invitados/httpRequestsInvitados";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Row,
} from "react-bootstrap";
import TableCodigosInvitados from "../DataTables/CodigosInvitados/TableCodigosInvitados";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import GeneracionCodigosInvitados from "../Modales/GeneracionCodigosInvitados/GeneracionCodigosInvitados";
import ValidacionCodigoInvitado from "../Modales/ValidacionCodigoInvitado/ValidacionCodigoInvitado";

const TabCodigosInvitados = () => {
  const [codigosInvitados, setCodigosInvitados] = useState([]);

  const [codigoValidado, setCodigoValidado] = useState(false);

  const [codigosGenerados, setCodigosGenerados] = useState(false);

  useEffect(() => {
    const consultarCodigosInvitados = async () => {
      try {
        let codigosInvitados = await getCodigosInvitado();

        if (!Array.isArray(codigosInvitados)) {
          codigosInvitados = [codigosInvitados];
        }

        setCodigosInvitados(codigosInvitados);
      } catch (error) {
        console.log("Error al consultar a los usuarios invitados: ", error);
      }
    };

    consultarCodigosInvitados();
  }, []);

  useEffect(() => {
    if (codigoValidado || codigosGenerados) {
      const consultarCodigosInvitados = async () => {
        try {
          let codigosInvitados = await getCodigosInvitado();

          if (!Array.isArray(codigosInvitados)) {
            codigosInvitados = [codigosInvitados];
          }

          setCodigosInvitados(codigosInvitados);

          setCodigoValidado(false);

          setCodigosGenerados(false);
        } catch (error) {
          console.log("Error al consultar a los usuarios invitados: ", error);
        }
      };

      consultarCodigosInvitados();
    }
  }, [codigoValidado, codigosGenerados]);

  const handleValidarCodigo = async () => {
    await ModalSweetAlert(
      {
        title: "Validar código de invitado",
        html: (
          <ValidacionCodigoInvitado setCodigoValidado={setCodigoValidado} />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      },
      true
    );
  };

  const handleGenerarCodigos = async () => {
    await ModalSweetAlert({
      title: "Generar nuevos códigos",
      html: (
        <GeneracionCodigosInvitados setCodigosGenerados={setCodigosGenerados} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col align="right" className="mb-4">
          <Dropdown className="pe-4" as={ButtonGroup}>
            <Button onClick={handleGenerarCodigos} variant="primary">
              Generar códigos
            </Button>

            <Dropdown.Toggle
              split
              variant="primary"
              id="dropdown-split-basic"
            />

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleValidarCodigo}>
                Validar código
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      <Row>
        <Col>
          <TableCodigosInvitados codigosInvitados={codigosInvitados} />
        </Col>
      </Row>
    </Container>
  );
};

export default TabCodigosInvitados;
