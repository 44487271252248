import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { FaPhone, FaWhatsapp } from "react-icons/fa";

const ErrorValidacionDatosRegistroInicial = ({ reason }) => {
  return (
    <>
      <Container fluid className="bg-error-page vh-100">
        <Row className="text-center">
          <Col>
            <Container>
              <Row>
                <Col>
                  <Image
                    src={require("@assets/images/logoGCvertical.png")}
                    height="250px"
                  />

                  <h1 className="error-main-title my-5">
                    Oops! Algo sucedió al validar tu inicio de registro
                  </h1>

                  <Card body>
                    <h4>
                      Lamentamos que hayas tenido esta experiencia, contacta a
                      Soporte Técnico y especifica la siguiente leyenda:{" "}
                    </h4>

                    <h5 className="text-danger mb-5">{reason}</h5>

                    <h5>
                      Comunícate a cualquier a de los siguientes canales de
                      atención
                    </h5>

                    <Container fluid>
                      <Row>
                        <Col>
                          <ul className="list-unstyled">
                            {/* <li>
                              <FaPhone className="text-primary" /> (477)
                              390-83-01 Ext. 101
                            </li>

                            <li>
                              <FaPhone className="text-primary" /> (477)
                              390-83-01 Ext. 102
                            </li> */}

                            <li>
                              <FontAwesomeIcon
                                icon={faComments}
                                className="text-warning"
                              />{" "}
                              Chat en Línea, ubicado en la esquina inferior derecha
                            </li>

                            <li>
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="text-warning"
                              />{" "}
                              soporte@globalclickmexico.com
                            </li>
                          </ul>
                        </Col>

                        <Col>
                          <ul className="list-unstyled">
                            {/* <li>
                              <FaWhatsapp className="text-success" /> (477)
                              314-59-75
                            </li>

                            <li>
                              <FaWhatsapp className="text-success" /> (477)
                              663-03-93
                            </li> */}

                            <li>
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="text-warning"
                              />{" "}
                              desarrollador@globalclickmexico.com
                            </li>
                          </ul>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Image
                            fluid
                            src={require("@assets/images/click.png")}
                            width="150px"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ErrorValidacionDatosRegistroInicial;
