import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "@assets/css/components/PillCheckbox/style.css";

const PillCheckbox = ({ defaultChecked, name, value, title, onChange }) => {
  return (
    <label className="pill-checkbox text-center w-100">
      <input type="checkbox" name={name} value={value} onChange={onChange} defaultChecked={defaultChecked} />

      <span className="pill-checkbox-label">
        {title}
        <span className="pill-checkbox-icon pill-checkbox-icon-checkLight pill-checkbox-icon-smallest">
          <FontAwesomeIcon className="check" icon={faCheck} />
        </span>
      </span>
    </label>
  );
};

export default PillCheckbox;
