import { Notificacion } from "@components/general/Alertas/Alertas";
import exportToExcel from "@helpers/exportToExcel/exportToExcel";
import { generarCodigosInvitado } from "@helpers/httpRequests/httpRequestsUsuarios/invitados/httpRequestsInvitados";
import { InputNumber } from "primereact/inputnumber";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";

const GeneracionCodigosInvitados = ({ setCodigosGenerados }) => {
  const [cantidadCodigos, setCantidadCodigos] = useState(0);

  const [codigosInvitados, setCodigosInvitado] = useState([]);

  const [exportandoExcel, setExportandoExcel] = useState(false);

  const handleGenerarCodigos = async () => {
    if (cantidadCodigos === 0) {
      return NotificationManager.error(
        "Ingresa la cantidad de códigos a generar"
      );
    }

    try {
      const { data: codigosInvitado } = await generarCodigosInvitado(
        cantidadCodigos
      );

      setCodigosInvitado(codigosInvitado);

      setCodigosGenerados(true);
    } catch (error) {
      console.log("Error al generar los códigos de invitado: ", error);

      Notificacion(
        "error",
        "No se pudieron generar los códigos de invitado, vuelve a intentar."
      );
    }
  };

  const handleExportar = async () => {
    const { value: nombreArchivo } = await Swal.fire({
      title: "Ingresa un nombre para el archivo",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Exportar con este nombre",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#0d6efd",
      allowOutsideClick: false,
    });

    setExportandoExcel(true);

    exportToExcel(codigosInvitados, nombreArchivo);

    setExportandoExcel(false);

    NotificationManager.success("Exportación completa");
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="card flex flex-wrap gap-3 p-fluid">
            <div className="flex-auto">
              <label htmlFor="cantidadCodigos" className="font-bold block mb-2">
                Ingresa la cantidad de códigos a generar
              </label>

              <InputNumber
                inputId="cantidadCodigos"
                value={cantidadCodigos}
                onValueChange={(e) => setCantidadCodigos(e.value)}
                useGrouping={false}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <div className="d-grid gap-2">
            <Button onClick={handleGenerarCodigos} variant="primary" size="lg">
              Generar códigos
            </Button>
          </div>
        </Col>

        <Col>
          <div className="d-grid gap-2">
            <Button
              onClick={handleExportar}
              disabled={codigosInvitados.length < 1}
              variant="success"
              size="lg"
            >
              {exportandoExcel
                ? "Exportando..."
                : "Exportar a Excel los recién generados"}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneracionCodigosInvitados;
