import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";
import { updateLeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";

import { Notificacion } from "@components/general/Alertas/Alertas";
import extensionesPermitidas from "@helpers/resources/extensionesPermitidas";

export const EditarLeccion = ({ leccion, setLeccionEditada }) => {
  const [validacion, setValidacion] = useState({
    nombre: true,
    urlPlantilla: true,
  });

  const [formValues, setFormValues] = useState(leccion);

  const [modificarPlantilla, setModificarPlantilla] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      const leccionActualizada = await updateLeccion(formValues);

      setLeccionEditada(true);

      Notificacion("success", "La lección se ha actualizado correctamente");
    } catch (error) {
      console.log("Error al actualizar la lección: ", error);

      Notificacion(
        "error",
        "Error al actualizar la lección, vuelve a intentar"
      );
    }
  };

  const handleOnChange = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleIncluirPlantilla = ({ currentTarget }) => {
    setModificarPlantilla(currentTarget.checked);
  };

  const handleLoadFile = async (e) => {
    try {
      let centinela = true;
      let pesoArchivos = 0;
      let files = e.target.files;
      // if (files.length > 0) {
      // 	Array.from(files).forEach((f) => {
      // 		pesoArchivos += f.size;
      // 		//*en caso de no tener una extension permitida el centilnela nos avisara
      // 		if (!extensionesDocumentosPermitidas.test(f.name)) {
      // 			// console.log(f.name);
      // 			centinela = false;
      // 		}
      // 	});

      // 	//* si todos los archivos son compatibles
      // 	if (centinela) {
      // 		//obtenbemos el valor en megabytes de los archivos los cuales el peso esta en bytes
      // 		let pesoMegas = pesoArchivos / pesoMegabyte;
      // 		// console.log(pesoMegas);
      // 		setValidacion({ ...validacion, pesoArchivo: true });
      // 		let reader = new FileReader();
      // 		reader.onload = (e) => {
      // 			console.log(e.target.result);
      // 		};
      // 		//TODO
      // 		//*uan vez validados los archisvos  que se subiran , debemos subirlos al servidor y obtener la url del recurso para
      // 		//*gaurdarlo en la base de datos
      // 		let json = await getUrlTemplate(state.archivos);
      // 		if (json.code === 200) {
      // 			console.log("getUrl Plantilla");
      // 			console.log(json);
      // 			//*agregamos al state el peso de los archivos , la url de los mismos
      // 			setState({
      // 				...state,
      // 				pesoArchivos: pesoMegas,
      // 				urlPlantilla: json.data.urlPlantilla,
      // 			});

      // 			//!una vez subidos los archivos y obtenida la url al dar click al boton guardar
      // 		} else {
      // 			Notificacion("info", "algo sucedio al cargar los archivos, intente de nuevo!!!");
      // 			console.log(json);
      // 		}
      // 	} else {
      // 		setValidacion({ ...validacion, pesoArchivo: false });
      // 		Notificacion("warning", "Algun archivo no es aceptable");
      // 	}
      // } else {
      // 	setValidacion({ ...validacion, pesoArchivo: false });
      // 	Notificacion("warning", "No se completo la operacion");
      // }
    } catch (e) {
      console.log("error en handleLoadFile");
      console.log(e);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <Container fluid className="text-start">
        <Row>
          <Form.Group as={Col} className="mb-4">
            <Form.Label>Nombre de la leccion</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              value={formValues.nombre}
              name="nombre"
              type="text"
              placeholder="Nombre de la leccion"
            />
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <Form.Check
              type="switch"
              id="modificarPlantilla"
              label="Modificar plantilla"
              onChange={handleIncluirPlantilla}
            />
          </Col>
        </Row>

        {modificarPlantilla && (
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="" className="mb-4">
                <Form.Label>Cargar Documento</Form.Label>

                <Form.Control
                  type="file"
                  name="inputFile"
                  className="inputFile"
                  multiple
                  accept={`${extensionesPermitidas}`}
                  onChange={handleLoadFile}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" type="submit" size="lg">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
