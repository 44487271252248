import SubtitleForoAyuda from "@components/general/Titles/SubtitleForoAyuda";
import {
  faCommentAlt,
  faEllipsisV,
  faHeart,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import React from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Tab,
} from "react-bootstrap";

const TabPaneTemasPorCategoria = ({
  categorias,
  currentPaginationSections,
  handleEliminarTema,
  alias,
  reportarTema,
  respuestas,
  numeroLikes,
  comentarTema,
  rol,
  aliasLogeado,
}) => {
  return categorias.map((categoria, key) => (
    <Tab.Pane key={key} eventKey={categoria.nombre}>
      {currentPaginationSections.some(
        (tema) => tema.idCategoria === categoria.idCategoria
      ) ? (
        currentPaginationSections.map(
          (tema, key) =>
            categoria.idCategoria === tema.idCategoria && (
              <Row className="row-foro" key={key}>
                <Col
                  style={{ height: "150px" }}
                  className="mt-3 text-capitalize"
                >
                  <Row>
                    <Col
                      className="pointer"
                      onClick={() => {
                        comentarTema(tema.idTema, tema.alias, tema.idPerfil);
                      }}
                    >
                      <SubtitleForoAyuda title={tema.titulo} />
                    </Col>
                    <Col className="col-2 text-center">
                      <DropdownButton
                        style={{ fontSize: "10px" }}
                        className="text-end menu-ruta"
                        id={`dropdown-button-drop-left`}
                        drop={"left"}
                        variant="transparent"
                        title={
                          <FontAwesomeIcon
                            className="pointer ellipsis"
                            icon={faEllipsisV}
                          />
                        }
                      >
                        {(rol === "administrador" ||
                          tema.alias === aliasLogeado) && (
                          <Dropdown.Item
                            onClick={handleEliminarTema}
                            name={categoria.idCategoria}
                            id={key}
                            eventKey="1"
                          >
                            <h5>Eliminar</h5>
                          </Dropdown.Item>
                        )}

                        {tema.alias !== aliasLogeado && (
                          <Dropdown.Item
                            id={key}
                            onClick={reportarTema}
                            eventKey="1"
                          >
                            <h5>Reportar tema</h5>
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </Col>
                  </Row>

                  <small>
                    Creado por <b>{tema.alias}</b>
                  </small>

                  <Row>
                    <Col className="col-12">
                      <small>{getFechaSinHora(tema.fechaCreacion)}</small>
                    </Col>
                  </Row>

                  <Row className="justify-content-end pt-3">
                    <Col className="d-flex align-items-center">
                      <span className="mx-2">
                        {/* <FontAwesomeIcon icon={faCommentAlt} /> 0 */}
                        <h6>
                          <FontAwesomeIcon
                            className="svg-foro"
                            icon={faCommentAlt}
                          />{" "}
                          {
                            respuestas.filter(
                              (respuesta) => respuesta.idTema === tema.idTema
                            ).length
                          }
                        </h6>
                      </span>
                      <span className="mx-2">
                        <h6>
                          <FontAwesomeIcon
                            className="svg-foro"
                            icon={faHeart}
                          />{" "}
                          {numeroLikes(tema.idTema)}
                        </h6>
                      </span>
                    </Col>

                    <Col className="col-3 text-center">
                      <Button
                        name={tema.idTema}
                        value={tema.alias}
                        onClick={() => {
                          comentarTema(tema.idTema, tema.alias, tema.idPerfil);
                        }}
                        className="btn-sm btn-responsive"
                      >
                        Comentar <FontAwesomeIcon icon={faPen} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
        )
      ) : (
        <Row>
          <Col className="text-center mt-5">
            <h4>No hay temas en esta categoría</h4>
          </Col>
        </Row>
      )}
    </Tab.Pane>
  ));
};

export default TabPaneTemasPorCategoria;
