const columnasCodigosInvitado = [
  {
    name: "#",
    selector: (row) => row.idCodigoInvitado,
    center: true,
  },
  {
    name: "Código",
    selector: (row) => row.codigo,
    center: true,
  },
];

export default columnasCodigosInvitado;
