import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const updateGrupo = (grupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const grupoActualizado = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.updateGrupo}`,
        method: "POST",
        body: JSON.stringify(grupo),
      });

      resolve(grupoActualizado);
    } catch (error) {
      // console.log("Error al actualizar el grupo: ", error);

      reject(error);
    }
  });
};

export const getUsuariosInvitados = (grupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usuariosInvitados = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.getUsuariosInvitados}/${grupo}`,
        method: "POST",
      });

      resolve(usuariosInvitados);
    } catch (error) {
      // console.log("Error al actualizar el grupo: ", error);

      reject(error);
    }
  });
};

export const getGrupoById = (idGrupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: grupo } = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.getGrupoById}/${idGrupo}`,
        method: "POST",
      });

      resolve(grupo);
    } catch (error) {
      // console.log("Error al obtener el grupo: ", error);

      reject(error);
    }
  });
};

export const getUsuariosByIdGrupo = async (idGrupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: usuariosGrupo } = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.getUsuariosByIdGrupo}/${idGrupo}`,
        method: "POST",
      });

      resolve(usuariosGrupo);
    } catch (error) {
      // console.log("Error al consultar los usuarios del grupo seleccionado: ", error);

      reject(error);
    }
  });
};

export const deactivateGrupo = (idGrupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const grupoDesactivado = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.deactivateGrupo}/${idGrupo}`,
        method: "POST",
      });

      resolve(grupoDesactivado);
    } catch (error) {
      // console.log("Error al desactivar el grupo: ", error);

      reject(error);
    }
  });
};

export const reactivateGrupo = async (idGrupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const grupoReactivado = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.reactivateGrupo}/${idGrupo}`,
        method: "POST",
      });

      resolve(grupoReactivado);
    } catch (error) {
      // console.log("Error al reactivar el grupo: ", error);

      reject(error);
    }
  });
};

export const getGrupos = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: grupos } = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.getGrupos}`,
        method: "GET",
      });

      resolve(grupos);
    } catch (error) {
      // console.log("Error al obtener los grupos: ", error);

      reject(error);
    }
  });
};

export const getGrupoByFolioContrato = async (folioContrato) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: grupo } = await httpRequest({
        url: `${endpointsPaths.grupos}/${httpRequestsPaths.grupos.getGrupoByFolioContrato}/${folioContrato}`,
        method: "GET",
      });

      console.log(grupo);

      resolve(grupo);
    } catch (error) {
      // console.log("Error al obtener el grupo por folio de contrato: ", error);

      reject(error);
    }
  });
};
