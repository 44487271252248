import { Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { updateConsejo } from "@helpers/httpRequests/httpRequestsConsejos/httpRequestsConsejos";
import {
  getCursoByCodigo,
  getCursos,
} from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";

const EditarConsejo = ({ setConsejoModificado, consejo }) => {
  const { idConsejoRapido, codigoCurso, titulo, descripcion } = consejo;

  const [cursos, setCursos] = useState([]);

  const [versiones, setVersiones] = useState([]);

  const [validacionForm, setValidacionForm] = useState({
    tituloConsejo: false,
    descripcionConsejo: false,
    cursoConsejo: false,
  });

  const [formValues, setFormValues] = useState({
    idConsejo: "",
    tituloConsejo: titulo,
    descripcionConsejo: descripcion,
    cursoConsejo: codigoCurso,
    nombreCurso: "",
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    let categoria = parseInt(value.substring(5, 6));
    setFormValues({
      ...formValues,
      [name]: value,
      showVersiones: categoria === 3 ? true : false,
    });
    if (value === "" || value === "0") {
      setValidacionForm({
        ...validacionForm,
        [name]: !validacionForm.name,
      });
    } else {
      setValidacionForm({
        ...validacionForm,
        [name]: false,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { tituloConsejo, descripcionConsejo, cursoConsejo } = formValues;

      let codigoCurso = cursoConsejo.substring(4, 0);

      if (tituloConsejo === "") {
        setValidacionForm({
          ...validacionForm,
          tituloConsejo: true,
        });
      } else if (descripcionConsejo === "") {
        setValidacionForm({
          ...validacionForm,
          descripcionConsejo: true,
        });
      } else if (cursoConsejo === "0") {
        setValidacionForm({
          ...validacionForm,
          cursoConsejo: true,
        });
      }

      if (
        tituloConsejo !== "" &&
        descripcionConsejo !== "" &&
        cursoConsejo !== "0"
      ) {
        const nuevoConsejo = {
          idConsejoRapido,
          tituloConsejo,
          descripcionConsejo,
          codigoCurso,
          versiones: JSON.stringify(versiones),
        };

        const { message } = await updateConsejo(nuevoConsejo);

        Notificacion("success", message);
      }

      setConsejoModificado(true);
    } catch (error) {
      console.log(error);
      Notificacion("error", "Error al actualizar el consejo ");
    }
  };

  const handleVersion = ({ target }) => {
    const { id } = target;

    const versionYaSeleccionada = versiones.indexOf(id);

    if (versionYaSeleccionada === -1) {
      setVersiones([...versiones, id]);
    } else {
      versiones.splice(versionYaSeleccionada, 1);
    }
  };

  useEffect(() => {
    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();
        const { data: datosCurso } = await getCursoByCodigo(codigoCurso);
        setCursos(data);
        setFormValues({
          ...formValues,
          nombreCurso: datosCurso.nombre,
        });
      } catch (error) {}
    };
    obtenerCursos();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit} className="text-start">
        <Form.Group>
          <Form.Label>Titulo del consejo</Form.Label>
          <Form.Control
            value={formValues.tituloConsejo}
            className={validacionForm.tituloConsejo ? "is-invalid" : ""}
            name="tituloConsejo"
            onChange={handleOnChange}
            type="text"
            placeholder="Título del consejo"
          ></Form.Control>

          <Form.Control.Feedback type="invalid">
            Este campo no puede ir vacío
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Descripción del consejo</Form.Label>
          <Form.Control
            value={formValues.descripcionConsejo}
            className={validacionForm.descripcionConsejo ? "is-invalid" : ""}
            name="descripcionConsejo"
            onChange={handleOnChange}
            type="text"
            placeholder="Descripción del consejo"
          ></Form.Control>

          <Form.Control.Feedback type="invalid">
            Este campo no puede ir vacío
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Curso del consejo</Form.Label>
          <Form.Control
            className={validacionForm.cursoConsejo ? "is-invalid" : ""}
            name="cursoConsejo"
            onChange={handleOnChange}
            as={"select"}
          >
            <option value="0">{formValues.nombreCurso}</option>
            {cursos.map((curso, idx) => (
              <option
                key={idx}
                value={`${curso.codigoCurso}/${curso.idCategoria}`}
              >
                {curso.nombre}
              </option>
            ))}
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            Este campo no puede ir vacío
          </Form.Control.Feedback>
        </Form.Group>

        {formValues.showVersiones && (
          <Form.Group>
            <Form.Label>
              Selecciona las versiones en las que se puede usar el consejo
            </Form.Label>
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 365"
              label={`Microsoft 365`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2021"
              label={`Microsoft Office 2021`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2019"
              label={`Microsoft Office 2019`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2016"
              label={`Microsoft Office 2016`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2013"
              label={`Microsoft Office 2013`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2010"
              label={`Microsoft Office 2010`}
            />
          </Form.Group>
        )}

        <div className="d-grid gap-2 mt-4">
          <Button type="submit" variant="primary" size="lg">
            Actualizar consejo
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditarConsejo;
