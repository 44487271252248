const injectCSS = (href) => {
	let oppositeTheme = "";

	if (href.includes('dark')) {
		oppositeTheme = href.replace('dark', 'light');
	} else {
		oppositeTheme = href.replace('light', 'dark');
	}

	const linksToDelete = document.head.querySelectorAll(`link[href="${oppositeTheme}"]`);
  	
	linksToDelete.forEach(link => link.parentNode.removeChild(link));

	const ss = document.createElement("link");

	ss.rel = "stylesheet";

    ss.href = href;

	window.document.head.appendChild(ss);
}

export default injectCSS;