import { AUTH_TOKEN, getApiUrl } from "@config/config";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import validateLocalStorageItemExistence from "@helpers/localStorage/validateLocalStorageItemExistence";

const httpRequest = ({ url, method, body = undefined }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let BASE_API_URL = "";

      if (validateLocalStorageItemExistence("BASE_API_URL")) {
        BASE_API_URL = localStorage.getItem("BASE_API_URL");
      } else {
        BASE_API_URL = await getApiUrl();
      }

      const options = {
        method,
        headers: {
          Authorization: window.atob(AUTH_TOKEN),
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      if (method === "POST") {
        if (body !== undefined) {
          options.body = body;
        }
      }

      // console.log("HTTP Requesting: ", `${ BASE_API_URL }/${ url }`);

      const request = await fetch(`${BASE_API_URL}/${url}`, options);

      if (
        request.status === 429 &&
        request.url.includes(httpRequestsPaths.openAI.sendGPTRequest)
      ) {
        return reject({
          code: 500,
          message:
            "Haz alcanzado el límite de consultas diarias, vuelve mañana para más consultas.",
          data: "gpt-daily-limit-reached",
        });
      }

      const response = await request.json();

      // console.log("HTTP Response: ", response);

      response.code === 200 ? resolve(response) : reject(response);
    } catch (error) {
      // console.log("Error en la petición: ", error);

      reject(error);
    }
  });
};

export default httpRequest;
