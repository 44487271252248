import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addRutaAprendizajePersonalizada = (infoRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const rutasPersonalizadas = await httpRequest({
        url: `${endpointsPaths.rutasAprendizajePersonalizadas}/${httpRequestsPaths.rutasAprendizajePersonalizadas.addRutaAprendizaje}`,
        method: "POST",
        body: JSON.stringify(infoRuta),
      });
      resolve(rutasPersonalizadas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const getRutasAprendizajePersonalizadas = (idUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getRutas } = await httpRequest({
        url: `${endpointsPaths.rutasAprendizajePersonalizadas}/${httpRequestsPaths.rutasAprendizajePersonalizadas.getRutasAprendizaje}/${idUsuario}`,
        method: "GET",
      });
      resolve(getRutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const deleteRutaPersonalizada = (idRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getRutas = await httpRequest({
        url: `${endpointsPaths.rutasAprendizajePersonalizadas}/${httpRequestsPaths.rutasAprendizajePersonalizadas.deleteRutaPersonalizada}/${idRuta}`,
        method: "POST",
      });
      resolve(getRutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};

export const updateRutaPersonalizada = (infoRuta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateRutas = await httpRequest({
        url: `${endpointsPaths.rutasAprendizajePersonalizadas}/${httpRequestsPaths.rutasAprendizajePersonalizadas.updateRutaPersonalizada}`,
        method: "POST",
        body: JSON.stringify(infoRuta),
      });
      resolve(updateRutas);
    } catch (error) {
      // console.log(error);
      reject(error);
    }
  });
};
