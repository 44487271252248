import React, { useState, useRef, useEffect, useContext } from 'react'
import { Badge, Button, Card, Col, Container, FloatingLabel, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import DynamicBreadcrumb from '../DynamicBreadcrumb/DynamicBreadcrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faTrash } from '@fortawesome/free-solid-svg-icons';
import { textToImageRequest, consultarIntentos, updateConsultas, sinConsultas, nuevasConsultas } from '@helpers/httpRequests/httpRequestsOpenAI/httpRequestsOpenAI';
import FileSaver from 'file-saver';
import { FaArrowRight, FaDownload, FaEye } from 'react-icons/fa';
import { ImFilePicture } from "react-icons/im";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import "@assets/css/components/SuiteAI/style.css"
import { Notificacion, NotificacionExtendida } from '../Alertas/Alertas';
import UserContext from '@context/User/UserContext';
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';
import { areSameDate } from '@helpers/dates/areSameDate/AreSameDate';
import { Alert } from '@mui/material';
import { MdLocalGasStation } from 'react-icons/md';
import { InfoOutlined } from '@mui/icons-material';
import OpcionesImagenes from './resources/OpcionesImagenes';
import { ShowImagenGenerada } from './resources/ShowImagenGenerada';

const lordIconProps = {
  img: {
    src: "https://cdn.lordicon.com/bzqvamqv.json",
    trigger: "loop",
    colors: "primary:#d3d0d0,secondary:#ffcc00,tertiary:#ebe6ef",
    style: { width: "200px", height: "200px" },
  },
  loader: {
    src: "https://cdn.lordicon.com/avytqtql.json",
    trigger: "loop",
    colors: "primary:#ffcc00,secondary:#ebe6ef",
    style: { width: "100px", height: "100px" },
  },
};

const TextToImage = ({ setEsperarMinuto }) => {

  const { contextValue, setContextValue } = useContext(UserContext);
  const { usuarioLogeado } = contextValue;

  const { idPerfil } = usuarioLogeado;


  //*Tour del componente
  const joyrideRef2 = useRef(null);

  const [tourSteps2, setTourSteps] = useState([
    {
      target: ".tti-question",
      content: "Ahora con la inteligencia artificial (IA) podemos generar imagenes a partir de una descripción que tu proporciones.",
      disableBeacon: true,
    },
    {
      target: ".tti-question",
      content: "A continuación te enseñaremos a como utilizar la herramienta:",
      disableBeacon: true,
    },
    {
      target: ".tti-description",
      content: "Como primer paso, describe la imagen a generar para que la IA pueda crearla. EJEMPLO: 'Un bosque al atardecer con una cabaña en el fondo'.",
      disableBeacon: true,
      placement: "top-end"
    },
    {
      target: ".tti-description",
      content: "Detalla tu descripción tanto como puedas obtener un mejor resultado.",
      disableBeacon: true,
      placement: "top-end"
    },
    {
      target: ".tti-styles",
      content: "Puedes elegir un estilo para tu imagen, cada estilo te generará un contenido acorde al estilo seleccionado.",
      disableBeacon: true,
    },
    {
      target: ".tti-images",
      content: "Una vez se genere la imagen, se mostrará aquí.",
      disableBeacon: true,
    },

    {
      target: ".tti-credits",
      content: "Usa los 5 créditos diarios para sacar el mayor provecho a la herramienta.",
      disableBeacon: true,
    },

    {
      target: ".tti-question",
      content: "Estás listo para disfrutar de la herramienta y usarla cuando la necesites.",
      disableBeacon: true,
    },


  ]);
  const [runTour, setRunTour] = useState(false);



  const imagenesLocalStorage = localStorage.getItem('imagenesGeneradas');

  const [imagenGenerada, setImagenGenerada] = useState(imagenesLocalStorage !== null ? true : false);


  const [imagenURL, setImagenURL] = useState(imagenesLocalStorage !== null ? JSON.parse(imagenesLocalStorage) : [' '])

  const [imagenesGeneradas, setImagenesGeneradas] = useState(imagenesLocalStorage !== null ? JSON.parse(imagenesLocalStorage) : []);

  const [showLoader, setShowLoader] = useState(false);

  const [formValues, setFormValues] = useState({
    description: "",
    size: "1024x1024",
  })

  const [consultasRestantes, setConsultasRestantes] = useState(0);

  const [tiempoEspera, setTiempoEspera] = useState(false);

  const [estiloSeleccionado, setEstiloSeleccionado] = useState({
    nombreEstilo: "default",
    pathEstilo: "/text2img"
  });

  const [lgShow, setLgShow] = useState(false)

  const [verImagenGenerada, setVerImagenGenerada] = useState(false);

  const [descargandoImagen, setDescargandoImagen] = useState(false);

  const seleccionarEstilo = (nombreEstilo, pathEstilo) => {
    setEstiloSeleccionado({
      ...estiloSeleccionado,
      nombreEstilo,
      pathEstilo,
    })
  }


  const mostrarOpcionesEstilos = () => {
    setLgShow(true);
  }


  const handleOnChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;

    setFormValues({
      ...formValues,
      [name]: value
    })
  }


  // const descargarImagenGenerada = (indiceImagen) => {
  //   console.log("data:image/png;base64," + imagenesGeneradas[indiceImagen]);
  //   let base = imagenesGeneradas[indiceImagen];
  //   let textImg = "data:image/jpeg;base64," + base;

  //   console.log(base)

  //   const photo = "data:image/png;base64," + base.b64_json;

  //   let id = Math.random().toString();

  //   id = id.slice(2, 8);

  //   FileSaver.saveAs(photo, `download_${id}`);
  // }

  const handleDownloadClick = async () => {
    // Crea un elemento 'a' para descargar la imagen
    const request = await fetch(imagenesGeneradas[0].output_url);
    setDescargandoImagen(true);
    const downloadLink = document.createElement('a');
    const blobResponse = await request.blob();

    const url = window.URL.createObjectURL(new Blob([blobResponse]));

    downloadLink.href = url;
    downloadLink.setAttribute("download", `${imagenesGeneradas[0].id}.jpg`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setDescargandoImagen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      /**
       * PASOS PARA GENERAR Y ACTUALIZAR REGISTROS DE INTENTOS
       * -validar intentos restantes
       * -si son mas de 0 hacer la generacion de la imagen
       * -al finalizar actualizar los registros de los intentos y actualizarlos
       */

      if (consultasRestantes !== 0) {

        setImagenGenerada(false);

        setShowLoader(true)

        const textToImage = await textToImageRequest({
          consulta: formValues.description,
          size: formValues.size,
          userId: idPerfil,
          estilo: estiloSeleccionado.pathEstilo
        });
      
        setImagenesGeneradas([textToImage.data]);

        setFormValues({
          ...formValues,
          description: '',
        })
        setEstiloSeleccionado({
          ...estiloSeleccionado,
          nombreEstilo: 'default',
          pathEstilo: '/text2img',
        })

        // console.log(textToImage);

        /**
         * Respuesta de textToImage: 
         * code: 200,
         * data: {
         *  id: ...,
         *  output_url: "...",
         * },
         * message: "..."
         */

        setImagenGenerada(true);

        setShowLoader(false);


        // // localStorage.setItem('imagenesGeneradas', JSON.stringify(textToImage.data))

        // // const photo = "data:image/png;base64," + textToImage.data[0].b64_json;
        // // FileSaver.saveAs(photo, `download1png`);

        // // const photo1 = "data:image/jpeg;base64," + textToImage.data[1].b64_json;
        // // FileSaver.saveAs(photo1, `download1_1.png`);

        // // const photo2 = "data:image/jpeg;base64," + textToImage.data[2].b64_json;
        // // FileSaver.saveAs(photo2, `download1_2.png`);

        // // const photo3 = "data:image/jpeg;base64," + textToImage.data[3].b64_json;
        // // FileSaver.saveAs(photo3, `download1_3.png`);

        // //Termina de generar la imagen 
        const actualizarContador = await updateConsultas({
          idPerfil,
          codigoHerramienta: "TTI",
          fechaUso: getUnixTimestamp(),
        })

        const { consultasRestantes } = actualizarContador.data.data;

        if (consultasRestantes === 0) {
          //Se alcanzo el limite
          const sinConsulta = await sinConsultas({
            idPerfil,
            fechaActual: getUnixTimestamp(),
            codigoHerramienta: "TTI",
          })
          setConsultasRestantes(0)

          Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")
        }

        setConsultasRestantes(consultasRestantes);

        setTiempoEspera(true)

        localStorage.setItem("tiempoEspera", true)

        setTimeout(() => {
          setTiempoEspera(false)
          localStorage.setItem("tiempoEspera", false)
        }, 60000);


      } else {
        Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")
      }





    } catch (error) {

      console.log(error)
      //En caso de que se arroje un error por limite de consultas entra en el if
      if (error.info) {
        Notificacion("error", error.info)
        setShowLoader(false)
      } else {
        console.log(error.code)
        console.log(error.data.type)
        setShowLoader(false)

        let errorMessage = "Ocurrió un error al generar las imagenes";



        switch (error.code) {
          case 500:
            if (error.data.error.code === "content_policy_violation") {
              errorMessage = "La descripción que ingresaste no permite generar imagenes, intenta con otra diferente."
            } else if (error.data.error.code === "rate_limit_exceeded") {
              errorMessage = "Espera 1 minuto para generar imagenes de nuevo."
            } else {
              errorMessage = "Por favor llena todos los campos del formulário"
            }

            break;


          default:
            break;
        }

        NotificacionExtendida("error", errorMessage)
      }


    }
  }

  const handleStartTour = () => {
    setRunTour(true);
  }


  useEffect(() => {

    // setDescargandoImagen(false);


    const tiempoDeEspera = localStorage.getItem("tiempoEspera");
    if (tiempoDeEspera !== null) {
      setTiempoEspera(tiempoDeEspera === "false" ? false : true)
    }

    if (tiempoDeEspera === "true") {
      setEsperarMinuto(true)
    }


    const consultarIntentosSuite = async () => {
      try {
        //CONSULTA A LA API PARA VALIDAR CONSULTAS RESTANTES

        const intentosSuite = await consultarIntentos({
          idPerfil,
          codigoHerramienta: "TTI",
          fechaActual: getUnixTimestamp()
        });


        if (intentosSuite.isRegistered) {
          //En caso de estar registrado con x cantidad de intentos

          const { consultasRestantes, fechaDesbloqueo, fechaActual, estatus } = intentosSuite.data.data;
          const sameDate = areSameDate(fechaActual, getUnixTimestamp()); //valida si la fecha registrada por primera vez es igual a la fecha que se tiene que desbloquear

          setConsultasRestantes(consultasRestantes)


          // if(sameDate === false && estatus === 0) {

          //   setConsultasRestantes(5);
          //   const conConsultas = await nuevasConsultas({
          //     idPerfil,
          //     codigoHerramienta: "TTI"
          //   });

          // }


        } else {

          //En caso de no estar registrado, se le agregan en automatico los 5 intentos
          setConsultasRestantes(5);

        }

      } catch (error) {
        console.log(error);
      }
    }

    consultarIntentosSuite()
  }, [])




  return (
    <Container fluid className='h-100 fondo-generador tti-question'>

      <Joyride
        locale={spanishOptionsJoyRide}
        ref={joyrideRef2}
        steps={tourSteps2}
        run={runTour}
        hideCloseButton
        showSkipButton
        continuous={true}
        disableOverlayClose={false}
        disableCloseOnEsc={false}
        disableScrolling={false}
        scrollToFirstStep={false}
        scrollOffset={100}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRunTour(false);
          }

        }}
        styles={{
          options: {
            primaryColor: "#ff9900",
            textColor: "#00152A",
            width: 900,
            zIndex: 1000,
          },
        }}
      />



      <Row className='justify-content-between pt-5'>
        <Col className="col-auto text-center text-white tti-credits">
          <h3>Créditos restantes: {consultasRestantes}/5</h3>
        </Col>
        <Col className="col-3 text-center ">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>Ayuda</Tooltip>}
          >
            <span>
              <FontAwesomeIcon
                className=" pointer "
                onClick={handleStartTour}
                style={{ fontSize: "2rem", color: "white" }}
                icon={faQuestion}
              />
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      {/* <Row className='justify-content-center'>
            <Col className='col-2 text-center    '>
            <lord-icon {...lordIconProps.img}></lord-icon>
            </Col>
        </Row> */}
      <Row className='justify-content-center '>
        <Col className='col-8 p-4 rounded-5'>
          <Row className='justify-content-center'>
            <Col className='text-center text-white col-8'>
              <picture>
                <source srcset={require(`@assets/images/textoImagenTitulo.webp`)} type="image/webp" />

                <Image src={require(`@assets/images/textoImagenTitulo.png`)} fluid alt='Generador de imagenes'></Image>

              </picture>
            </Col>
          </Row>
          <Row className='justify-content-center mt-4'>

            <Col className='col-12'>
              <Form onSubmit={handleSubmit}>

                <FloatingLabel
                  controlId="floatingInput"
                  label="Inserta una descripción para tu imagen"
                  className="mb-3 tti-description"
                >
                  <Form.Control type="text" name='description' value={formValues.description} onChange={handleOnChange} />
                </FloatingLabel>

                <small className='text-white'>Ejemplo: Un grupo de personas tomando una capacitación en una oficina</small>
                {/* Modal para mas opciones de estilos */}
                <Row>
                  <Col>
                    <OpcionesImagenes estiloSeleccioado={estiloSeleccionado} setEstiloSeleccionado={setEstiloSeleccionado} lgShow={lgShow} setLgShow={setLgShow} />
                  </Col>
                </Row>
  
                <Row className='tti-styles'>
                  <Row className='d-flex justify-content-end'>
                    <Col className='col-auto'><span className='text-white pointer' onClick={mostrarOpcionesEstilos}>Ver más estilos <FaArrowRight color={'#fff'}  /> </span></Col>
                  </Row>
                  <Col className='col-10 col-md-6 col-lg-4 col-xl-3 pointer' onClick={() => seleccionarEstilo('default', '/text2img')}>
                    <Card className=" mb-4 card-images border-0" style={{ backgroundColor: '#212529' }}>
                      <picture width="100%" height="100%">
                        <source
                          srcSet={require('@assets/images/plus.webp')}
                        />
                        <img
                          loading="lazy"
                          className="img-fluid"
                          src={require('@assets/images/plus.png')}
                          alt="imagen leccion"
                        />
                      </picture>
                      <Card.Body className="info-images">
                        <h3>Sin estilo</h3>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col className='col-10 col-md-6 col-lg-4 col-xl-3 pointer' onClick={() => seleccionarEstilo('Abstract Expressionism', '/abstract-expressionism-generator')}>
                    <Card className=" mb-4 card-images border-0">
                      <picture width="100%" height="100%">
                        <source
                          srcSet={require('./resources/img/abstract.webp')}
                        />
                        <img
                          loading="lazy"
                          className="img-fluid"
                          src={require('./resources/img/abstract.jpeg')}
                          alt="imagen leccion"
                        />
                      </picture>
                      <Card.Body className="info-images">
                        <h3>Abstract Expressionism</h3>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col className='col-10 col-md-6 col-lg-4 col-xl-3 pointer' onClick={() => seleccionarEstilo('Terrarium World', '/terrarium-world-generator')}>
                    <Card className=" mb-4 card-images border-0">
                      <picture width="100%" height="100%">
                        <source
                          srcSet={require('./resources/img/terrarium.webp')}
                        />
                        <img
                          loading="lazy"
                          className="img-fluid"
                          src={require('./resources/img/terrarium.jpeg')}
                          alt="imagen leccion"
                        />
                      </picture>
                      <Card.Body className="info-images">
                        <h3>Terrarium World</h3> </Card.Body>
                    </Card>
                  </Col>

                  <Col className='col-10 col-md-6 col-lg-4 col-xl-3 pointer' onClick={() => seleccionarEstilo('Anime Portrait', '/anime-portrait-generator')}>
                    <Card className=" mb-4 card-images border-0">
                      <picture width="100%" height="100%">
                        <source
                          srcSet={require('./resources/img/anime_portrait.webp')}
                        />
                        <img
                          loading="lazy"
                          className="img-fluid"
                          src={require('./resources/img/anime_portrait.jpeg')}
                          alt="imagen leccion"
                        />
                      </picture>
                      <Card.Body className="info-images">
                        <h3>Anime Portrait</h3>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Alert icon={<InfoOutlined fontSize="inherit" />} severity="warning">
                      <b>NOTA:</b> Las imagenes generadas pueden no ser realistas del todo.
                    </Alert>
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <h4 className='text-white'>Verifíca tu solicitud</h4>
                  <Col className='col-12'>
                    <span className='text-white'><b>Descripción: </b>{formValues.description !== "" ? formValues.description : <Badge pill bg='warning' className='text-dark'>Sin descripción</Badge>}</span>
                  </Col>
                  <Col className='col-12 mt-2'>
                    <Col className='text-white'>
                      <b>Estilo seleccionado: </b><Badge pill bg='warning' className='text-dark'>{estiloSeleccionado.nombreEstilo !== 'default' ? estiloSeleccionado.nombreEstilo : "Sin estilo"}</Badge>
                    </Col>
                  </Col>
                </Row>

                {tiempoEspera === false && (
                  <div className='d-grid gap-2 mt-4'>
                    <Button className='btn btn-warning text-dark' disabled={(showLoader || formValues.description === "")} type='submit' block><b>Generar imagen</b></Button>
                  </div>

                )}


                {tiempoEspera === true && (
                  <Alert className='mt-4' severity="info">Espera un minuto para generar de nuevo más imagenes</Alert>
                )}

              </Form>

              {/* <Image src={require(`@assets/images/textoImagen.png`)} fluid ></Image> */}
            </Col>
            <Col className="col-12 ">
              <Row className='mt-5 tti-images'>
                <Col className='text-center text-white'>
                  {!imagenGenerada ? (
                    <>
                      <h2>Tu imagen se generará aquí:</h2>
                      {showLoader === false && (
                        <ImFilePicture className="img-picture" />
                      )}
                    </>
                  ) : (
                    <h2>Tu imagen está lista:</h2>
                  )}
                </Col>
              </Row>

              {showLoader === true ? (
                <Row className='justify-content-center'>
                  <Col className='col-2 text-center    '>
                    <lord-icon {...lordIconProps.loader}></lord-icon>
                  </Col>
                </Row>
              ) : (
                imagenGenerada && (
                    <>
                      {descargandoImagen ? (
                        <Row className='justify-content-center'>
                          <Col className='col-2 text-center    '>
                            <lord-icon {...lordIconProps.loader}></lord-icon>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row>
                            <Col>
                              <ShowImagenGenerada imagenesGeneradas={imagenesGeneradas} verImagenGenerada={verImagenGenerada} setVerImagenGenerada={setVerImagenGenerada} />
                            </Col>
                          </Row>
                          <Row className='mt-3 mb-5 fondo-imagenes-generadas justify-content-center'>
                            {imagenesGeneradas.map((archivo, idx) => (

                              <Col className='col-10 col-md-6 col-lg-4 col-xl-3 text-center'>
                                <Card className=" mb-4 card-images border-0">
                                  <img
                                    src={archivo.output_url}
                                    alt="imagen leccion"
                                  />
                                  <Card.Body className="info-images">

                                    <Row className='d-flex justify-content-center'>
                                      <Col className='col-auto'>
                                        <button className='btn bg-transparent' id={idx} onClick={handleDownloadClick}><FaDownload size={24} color={"white"} /></button>
                                      </Col>

                                      <Col className='col-auto'>
                                        <button className='btn bg-transparent' id={idx} onClick={() => setVerImagenGenerada(true)}><FaEye size={24} color={"white"} /></button>
                                      </Col>

                                    </Row>

                                  </Card.Body>
                                </Card>
                                <FontAwesomeIcon className='pointer' onClick={() => {
                                  setImagenesGeneradas([])
                                  setImagenGenerada(false)
                                }} icon={faTrash} color={'gray'} style={{ fontSize: 24 }} />

                              </Col>
                            ))}


                          </Row>

                        </>
                      )}
                    </>
                )
              )}
            </Col>
          </Row>



        </Col>
      </Row>
    </Container>
  )
}

export default TextToImage