import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import React, { useEffect } from "react";
import {
  getSeccionesByCodigoCurso,
  updateReorderSecciones,
} from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import SeccionCurso from "./SeccionCurso";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { useState } from "react";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

const getDragnDropListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e3e3e3" : "#FFF",
  padding: 15,
  // width: 250
});

const getDragnDropItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 10,
  // margin: `0 0 ${8}px 0`,
  background: isDragging ? "#FFF" : "transparent",
  ...draggableStyle,
});

const TableGestionContenido = () => {
  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [recoverySecciones, setRecoverySecciones] = useState([]);

  const [editarSecciones, setEditarSecciones] = useState(false);

  const handleSeleccionCurso = async ({ target }) => {
    try {
      if (target.value === "") {
        return Notificacion("info", "Elige un curso");
      }

      let { data: secciones } = await getSeccionesByCodigoCurso(target.value);

      if (!Array.isArray(secciones)) {
        secciones = [secciones];
      }

      setSecciones(secciones);

      setRecoverySecciones(secciones);
    } catch (error) {
      console.log("Error al consultar las secciones del curso ", error);

      setSecciones([]);
    }
  };

  useEffect(() => {
    const consultarCursos = async () => {
      try {
        const { data: cursos } = await getCursos();

        setCursos(cursos);
      } catch (error) {
        console.log("Error al obtener los cursos disponibles: ", error);

        Notificacion("error", "No se pudieron obtener los cursos");
      }
    };

    consultarCursos();
  }, []);

  const handleEditSecciones = () => {
    setEditarSecciones(true);
  };

  const handleCancelEditSecciones = () => {
    setSecciones(recoverySecciones);

    setEditarSecciones(false);
  };

  const reorderSecciones = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragDropEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const auxSecciones = Array.from(secciones);

    const { source, destination } = result;

    const { index: sourceIndex } = source;

    const { index: destinationIndex } = destination;

    const reorderedSecciones = reorderSecciones(
      auxSecciones,
      sourceIndex,
      destinationIndex
    );

    reorderedSecciones.forEach((seccion, idx) => {
      const nuevoNivel = idx + 1;

      const nuevoNivelFormateado =
        nuevoNivel < 10 ? `0${nuevoNivel}` : nuevoNivel;

      reorderedSecciones[idx] = {
        ...seccion,
        nivel: nuevoNivelFormateado,
      };
    });

    setSecciones(reorderedSecciones);
  };

  const handleGuardarReorden = async () => {
    try {
      const isConfirmed = await ModalSweetAlert({
        title: "¿Estás seguro que deseas reordenar las secciones?",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Sí, reordenar",
        cancelButtonText: "No, cancelar",
      });

      if (!isConfirmed) {
        return;
      }

      await updateReorderSecciones(secciones);

      setEditarSecciones(false);

      Notificacion("success", "Las secciones se reordenaron con éxito");
    } catch (error) {
      console.log("Error al guardar el reorden: ", error);

      Notificacion("error", "No se pudieron reordenar las secciones");
    }
  };

  return (
    <Container fluid className="p-0 mb-3">
      <Row>
        <Col>
          <Form.Group as={Col} controlId="text" className="mb-4">
            <Form.Label>Elige un curso</Form.Label>

            <Form.Control as={"select"} onChange={handleSeleccionCurso}>
              <option value="">Elige un curso</option>

              {cursos.map((curso, idx) => (
                <option value={curso.codigoCurso} key={idx}>
                  {curso.nombre} - {curso.codigoCurso}{" "}
                  {codigoCursosUsuarioInvitado.find(
                    (invitado) => invitado.codigoInvitado === curso.codigoCurso
                  ) !== undefined && "- Usuarios Invitados"}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      {secciones.length > 0 ? (
        <>
          <Row>
            <Col align="left">
              <TitleDashboard title="Secciones" />
            </Col>

            <Col align="right">
              {!editarSecciones && (
                <Button variant="primary" onClick={handleEditSecciones}>
                  Cambiar orden de las secciones
                </Button>
              )}

              {editarSecciones && (
                <>
                  <Button
                    className="me-2"
                    variant="success"
                    onClick={handleGuardarReorden}
                  >
                    Guardar
                  </Button>

                  <Button
                    className="me-2"
                    variant="danger"
                    onClick={handleCancelEditSecciones}
                  >
                    Cancelar
                  </Button>
                </>
              )}
            </Col>
          </Row>

          {editarSecciones && (
            <h5>
              Da clic y arrastra la sección al lugar donde deseas reordenarla
            </h5>
          )}

          <Row className="mt-3">
            <Col>
              {editarSecciones ? (
                <DragDropContext onDragEnd={handleDragDropEnd}>
                  <Droppable droppableId="droppable-secciones">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getDragnDropListStyle(snapshot.isDraggingOver)}
                      >
                        {secciones.map((seccion, idx) => (
                          <Draggable
                            key={idx + 1}
                            draggableId={idx.toString()}
                            index={idx}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getDragnDropItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <SeccionCurso
                                  editarSecciones={editarSecciones}
                                  codigoSeccion={seccion.codigoSeccion}
                                  nombre={seccion.nombre}
                                  idx={idx}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <>
                  {secciones.map((seccion, idx) => (
                    <SeccionCurso
                      key={idx + 1}
                      codigoSeccion={seccion.codigoSeccion}
                      nombre={seccion.nombre}
                      idx={idx}
                    />
                  ))}
                </>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <h5>No hay secciones en este curso</h5>
      )}
    </Container>
  );
};

export default TableGestionContenido;
