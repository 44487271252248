import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import SubtitleForoAyuda from "@components/general/Titles/SubtitleForoAyuda";
import { faEllipsisV, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getReportes,
  getReportesComentarios,
  updateEstadoReporte,
  updateEstadoReporteComentario,
} from "@helpers/httpRequests/httpRequestsReportes/httpRequestsReportes";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import VerInfoReporte from "../../Modales/VerInfoReporte";
import VerInfoReporteComentario from "../../Modales/VerInfoReporteComentario";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";

const TabPaneReportes = ({
  currentPaginationSections,
  rol,
  handleEliminarTema,
  comentarTema,
  nombreUsuarioLogeado,
  setTemasCategoria,
  setCurrentPage,
  setReporteAtendido,
  handleEliminarComentario,
  setReporteComentarioAtendido,
  reporteComentarioAtendido,
}) => {
  const [key, setKey] = useState("temas");

  const [checked1, setChecked1] = useState(false);

  const [checked2, setChecked2] = useState(false);

  //States para almacenar los reportes de los comentarios y los temas para filtrarlos en el tercer tabpane

  const [reportesTemas, setReportesTemas] = useState([]);

  const [reportesComentarios, setReportesComentarios] = useState([]);

  const verInfoReporte = (id) => {
    const rowIndex = id;

    ModalSweetAlert({
      title: "Información del reporte",
      html: <VerInfoReporte reporte={currentPaginationSections[rowIndex]} />,
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const verInfoReporteComentario = (id) => {
    const rowIndex = id;

    ModalSweetAlert({
      title: "Información del reporte",
      html: (
        <VerInfoReporteComentario
          reporte={currentPaginationSections[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const marcarReporteTemaAtendido = async ({ target }) => {
    try {
      console.log(target);
      const { id } = target;
      // console.log(id);
      const { message } = await updateEstadoReporte(parseInt(id));
      setReporteAtendido(true);
      Notificacion("success", message);
    } catch (error) {}
  };

  const marcarReporteComentarioAtendido = async ({ target }) => {
    try {
      const { id } = target;
      console.log(target);
      // console.log(id);
      const { message } = await updateEstadoReporteComentario(parseInt(id));
      console.log(message);
      setReporteComentarioAtendido(true);
      Notificacion("success", message);
    } catch (error) {}
  };

  const handleSelect = async (key) => {
    try {
      if (key === "temas") {
        // console.log("OBTENIENDO TEMAS");
        const { data } = await getReportes();
        // console.log(data);

        if (Array.isArray(data)) {
          setTemasCategoria(data);
        } else {
          setTemasCategoria([data]);
        }
      }

      if (key === "comentarios") {
        // console.log("OBTENIENDO COMENTARIOS");
        const { data } = await getReportesComentarios();
        // console.log(data);

        if (Array.isArray(data)) {
          setTemasCategoria(data);
        } else {
          setTemasCategoria([data]);
        }
      }

      if (key === "todos") {
        const { data: reportesTemas } = await getReportes();

        if (Array.isArray(reportesTemas)) {
          setTemasCategoria(reportesTemas);
        } else {
          setTemasCategoria([reportesTemas]);
        }

        const { data: reportesComentarios } = await getReportesComentarios();

        if (Array.isArray(reportesComentarios)) {
          setReportesComentarios(reportesComentarios);
        } else {
          setReportesComentarios([reportesComentarios]);
        }
      }
    } catch (error) {
      console.log(error);
      setTemasCategoria([]);
    }
  };

  useEffect(() => {
    if (reporteComentarioAtendido) {
      console.log("refrescando comentarios");
      const obtenerReportesComentario = async () => {
        try {
          const { data } = await getReportesComentarios();
          console.log(data);

          if (Array.isArray(data)) {
            setReportesComentarios(data);
          } else {
            setReportesComentarios([data]);
          }
        } catch (error) {
          console.log(error);
          setTemasCategoria([]);
        }
      };

      setReporteComentarioAtendido(false);
      obtenerReportesComentario();
    }
  }, [reporteComentarioAtendido]);

  return (
    <>
      <Tab.Pane eventKey="reportesTemas">
        <Tabs
          className="test"
          defaultActiveKey="temas"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
            handleSelect(k);
          }}
          id="tab-reportes"
        >
          <Tab eventKey="temas" title={<h5>Temas reportados</h5>}>
            {/* //* LISTADO DE TEMAS REPORTADOS */}
            {currentPaginationSections.length > 0 ? (
              currentPaginationSections.map(
                (reporte, key) =>
                  rol === "administrador" && (
                    <Row className="row-foro" key={key}>
                      <Col style={{ height: "150px" }} className="mt-3">
                        <Row>
                          <Col className="text-capitalize">
                            <SubtitleForoAyuda title={reporte.titulo} />
                          </Col>
                          <Col className="col-2 text-center">
                            {reporte.estatus === 1 ? (
                              <DropdownButton
                                style={{ fontSize: "10px" }}
                                className="text-end menu-ruta"
                                id={`dropdown-button-drop-left`}
                                drop={"left"}
                                variant="transparent"
                                title={
                                  <FontAwesomeIcon
                                    className="pointer ellipsis"
                                    icon={faEllipsisV}
                                  />
                                }
                              >
                                <Dropdown.Item
                                  name={reporte.idReporte}
                                  onClick={handleEliminarTema}
                                  id={key}
                                  eventKey="1"
                                >
                                  <h5>Eliminar publicación</h5>
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={marcarReporteTemaAtendido}
                                  eventKey="1"
                                >
                                  <h5 id={reporte.idTema}>
                                    Marcar como atendido
                                  </h5>
                                </Dropdown.Item>
                              </DropdownButton>
                            ) : (
                              <h6 className="text-success">Atendido</h6>
                            )}
                          </Col>
                        </Row>
                        <small>
                          Creado por {reporte.nombreUsuario} el{" "}
                          {getFechaSinHora(reporte.fechaReporte)}
                        </small>

                        <Row className="justify-content-end pt-4">
                          <Col className="col-3 text-center">
                            <Button
                              name={reporte.idTema}
                              value={reporte.nombreUsuario}
                              onClick={() => {
                                verInfoReporte(key);
                              }}
                              className="btn-sm btn-responsive"
                            >
                              Ver reporte <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
              )
            ) : (
              <div className="text-center mt-3">
                <h4>No hay reportes por el momento</h4>
              </div>
            )}
          </Tab>

          {/* //* LISTADO DE COMENTARIOS REPORTADOS */}
          <Tab eventKey="comentarios" title={<h5>Comentarios reportados</h5>}>
            {currentPaginationSections.length > 0 ? (
              currentPaginationSections.map(
                (reporte, key) =>
                  rol === "administrador" && (
                    <Row className="row-foro" key={key}>
                      <Col style={{ height: "150px" }} className="mt-3">
                        <Row>
                          <Col className="text-capitalize">
                            <SubtitleForoAyuda
                              title={"Nuevo reporte de comentario"}
                            />
                          </Col>
                          <Col className="col-2 text-center">
                            {reporte.estatus === 1 ? (
                              <DropdownButton
                                style={{ fontSize: "10px" }}
                                className="text-end menu-ruta"
                                id={`dropdown-button-drop-left`}
                                drop={"left"}
                                variant="transparent"
                                title={
                                  <FontAwesomeIcon
                                    className="pointer ellipsis"
                                    icon={faEllipsisV}
                                  />
                                }
                              >
                                <Dropdown.Item
                                  onClick={handleEliminarComentario}
                                  id={reporte.idRespuestaTema}
                                  eventKey="1"
                                >
                                  Eliminar publicación
                                </Dropdown.Item>

                                <Dropdown.Item
                                  id={reporte.idReporte}
                                  onClick={marcarReporteComentarioAtendido}
                                  eventKey="1"
                                >
                                  Marcar como atendido
                                </Dropdown.Item>
                              </DropdownButton>
                            ) : (
                              <h6 className="text-success">Atendido</h6>
                            )}
                          </Col>
                        </Row>

                        <small>
                          Comentario escrito por {reporte.nombreUsuario} el{" "}
                          {getFechaSinHora(reporte.fechaReporte)}
                        </small>

                        <Row className="justify-content-end pt-4">
                          <Col className="col-3 text-center">
                            <Button
                              name={reporte.idTema}
                              value={reporte.nombreUsuario}
                              onClick={() => {
                                verInfoReporteComentario(key);
                              }}
                              className="btn-sm btn-responsive"
                            >
                              Ver reporte <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
              )
            ) : (
              <div className="text-center mt-3">
                <h4>No hay reportes por el momento</h4>
              </div>
            )}
          </Tab>

          {/* <Tab  eventKey="reportes" title="Comentarios reportados">

						<Row>
							<Col className='m-4'>

								<ToggleButton onClick={() =>{
									handleSelect("todos")
								}} className='btn-atendidos' style={{marginRight: "1rem"}} onLabel='Temas' offLabel='Temas' checked={checked1} onChange={(e) => setChecked1(e.value)} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />
						
								<ToggleButton className='btn-atendidos' style={{marginRight: "1rem"}} onLabel='Comentarios' offLabel='Comentarios' checked={checked2} onChange={(e) => setChecked2(e.value)} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />


				
							</Col>
						</Row>
 
					</Tab> */}
        </Tabs>
      </Tab.Pane>
    </>
  );
};

export default TabPaneReportes;
