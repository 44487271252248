import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getLeccionesVistas = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccionesVistas } = await httpRequest({
        url: `${endpointsPaths.leccionesVistas}/${httpRequestsPaths.leccionesVistas.getLecciones}/${idPerfil}`,
        method: "POST",
      });

      resolve(leccionesVistas);
    } catch (error) {
      // console.log("Error al obtener las lecciones: ", error);

      reject(error);
    }
  });
};

export const getLeccionesVistasBI = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccionesVistas } = await httpRequest({
        url: `${endpointsPaths.leccionesVistas}/${httpRequestsPaths.leccionesVistas.getLeccionesBI}/${idPerfil}`,
        method: "POST",
      });

      resolve(leccionesVistas);
    } catch (error) {
      // console.log("Error al obtener las lecciones: ", error);

      reject(error);
    }
  });
};

export const addNuevaLeccionVista = async (infoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const nuevaLeccion = await httpRequest({
        url: `${endpointsPaths.leccionesVistas}/${httpRequestsPaths.leccionesVistas.addNuevaLeccion}`,
        method: "POST",
        body: JSON.stringify(infoLeccion),
      });

      resolve(nuevaLeccion);
    } catch (error) {
      // console.log("Error al agregar la leccion vista: ", error);

      reject(error);
    }
  });
};

export const removeLeccionVista = async (infoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const nuevaLeccion = await httpRequest({
        url: `${endpointsPaths.leccionesVistas}/${httpRequestsPaths.leccionesVistas.removeLeccion}`,
        method: "POST",
        body: JSON.stringify(infoLeccion),
      });

      resolve(nuevaLeccion);
    } catch (error) {
      // console.log("Error al remover la leccion vista: ", error);

      reject(error);
    }
  });
};
