export const laCasaDeChocolate = `Había una vez una pobre familia que vivía en su perdido bosque lejos de todos sitios. Tenían dos hijos, el chico se llamaba Hansel y la chica, Gretel. Todos los días Hansel y Gretel iban con su padre a buscar leña para su casa.

Un día, salieron con su padre en busca de ramitas. Su papá les advirtió que no se distrajeran porque se podrían perder, pero Hansel y Gretel no le hicieron mucho caso porque estaban jugando. Al llegar a la mitad del camino, su papá les dijo: "Vamos a separarnos, vosotros dos ir por allí, y yo iré por aquí, pero antes del anochecer tenéis que estar aquí para volver juntos a casa, ¿vale?". -"Sí, papá, no te preocupes"- -"Bueno, hijos, tened cuidado, dadme un beso."

Los dos hermanos besaron a su padre y alegremente se fueron cantando y saltando mientras cogían ramas. Tan bien se lo estaban pasando que no se fijaron en el camino que estaban recorriendo y de repente se dieron cuenta de que estaban perdidos. Hansel se asustó mucho, pero su hermana que era un poco más valiente que él le dijo: "No te preocupes hermanito, todavía no ha anochecido, seguro que encontramos el camino de vuelta."

Hansel y Gretel empezaron a andar sin saber muy bien hacia donde iban y con miedo porque pronto anochecería. De pronto, ¡qué sorpresa!, ¡no se lo podían creer! ¡Era una casa de chocolate allí, en medio del bosque! Al principio, los dos hermanos no se atrevían a acercarse, pero decidieron cogerse de la mano e ir juntos. Miraron por la ventana y vieron que no había nadie dentro. Por fuera de la casa tenía ladrillos de chocolate, tejado de mazapán, cristales de caramelo. Tenían mucha hambre y pensaron que si le daban un bocado a un ladrillo no pasaría nada y así lo hicieron.

Mientras comían se dieron cuenta que la puerta de la casa estaba abierta. Decidieron entrar. ¡Qué susto cuando vieron lo que allí había! Un gran fuego con un enorme caldero y jaulas que colgaban del techo, sapos y culebras en botes ¡Qué asco! Estaban ensimismados mirando y, de pronto... ¡Ja, Ja, Ja, Ja! Era la risa de una fea bruja que entró en la casa montada en su escoba y tras de sí cerró la puerta con llave y Hansel y Gretel quedaron allí atrapados.

La bruja los cogió y metió a cada niño en una jaula, cerró y colgó la llave en la pared, diciendo: "¡Creíais que os podías comer mi casa! Ja, Ja. Pues ahora quién os comerá seré yo, pero antes tenéis que engordar porque estáis muy flacos. Y así cada día la bruja les daba mucho de comer y les pedía que sacaran el brazo entre los barrotes, pero Hansel que muy inteligente, se dio cuenta que la bruja apenas veía y cuando ella le decía que sacara el brazo, él y su hermana sacaban un hueso de pollo y así la bruja decidía no comérselos aún, hasta que se cansó y dijo: "¡Ya está bien! Me da igual lo flaco que estés, te comeré a ti primero." La bruja cogió la llave y sacó a Hansel de la jaula. Se enfadó mucho al notar que el niño estaba más gordito y que la había engañado. Se enfadó tanto que se olvidó que la llave la había dejado puesta en la jaula.

Mientras la bruja gritaba y metía a Hansel en el caldero, Gretel cogió la llave, salió de su jaula, agarró la escoba en que la bruja volaba y le atizó en la cabeza, entonces su hermano y ella subieron a la escoba y salieron volando de allí. La bruja quería perseguirlos pero no podía hacer nada sin su escoba, así que no pudo agarrarlos.

Los dos hermanos se dirigieron alegremente a su casa, y ¡cuál fue la sorpresa de sus padres cuando los vieron llegar sanos y salvos en la escoba! Se besaron y abrazaron felizmente, utilizaron la escoba para ir de pueblo en pueblo vendiendo leña y así nunca les faltó para comer, y además los dos hermanos aprendieron una gran lección: "Nunca hay que fiarse de las apariencias". Por eso si veis a un desconocido que os llama, aunque parezca bueno.... No os fieis.`;