import { Notificacion } from "@components/general/Alertas/Alertas";
import { reactivateLeccionPersonalizada } from "@helpers/httpRequests/httpRequestsLeccionesPersonalizadas/httpRequestsLeccionesPersonalizadas";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const ReactivarLeccion = ({ setLeccionActivada, infoLeccion }) => {
  // console.log(infoLeccion);
  const { idLeccion } = infoLeccion;

  // console.log(idLeccion);

  const handleActivarLecccion = async () => {
    try {
      await reactivateLeccionPersonalizada(idLeccion);

      setLeccionActivada(true);

      Notificacion("success", "La lección fue activada con éxito");
    } catch (e) {
      console.log(e);

      Notificacion(
        "error",
        "Algo sucedió al activar la lección, vuelve a intentar"
      );
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "La lección no fue activada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro que deseas activar esta lección?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleActivarLecccion}>
            Sí, activar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ReactivarLeccion;
