import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const CorsFrame = () => {
  const iframeRef = useRef();

  const [isDark, setIsDark] = useState(true);

  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined,
    (isSystemDark) => setIsDark(isSystemDark)
  );

  const aplicarEstilosAlIframe = () => {
    if (isDark) {
      console.log(iframeRef.current.contentWindow.document);

      if (iframeRef && iframeRef.current.contentWindow) {
        const iframeDocument = iframeRef.current.contentWindow;

        console.log(iframeDocument.document.querySelector(".cpMainContainer"));

        iframeDocument.document.querySelector(
          ".cpMainContainer"
        ).style.backgroundColor = "#1d1b14!important;";

        const styleElement = iframeDocument.createElement("style");

        styleElement.innerHTML = `.cpMainContainer {background-color: #1d1b14!important;}`;

        iframeDocument.head.appendChild(styleElement);
      }
    }
  };

  return (
    <Container fluid className="vh-100">
      <Row>
        <Col>
          <div className="embed-responsive embed-responsive-16by9 visor-curso">
            <iframe
              style={{ border: "none" }}
              allow="autoplay; encrypted-media; fullscreen;"
              crossorigin="use-credentials"
              loading="lazy"
              title="Lección actual"
              ref={iframeRef}
              className="embed-responsive-item iframe-curso"
              src="https://virtual.globalclickmexico.com/lecciones/sistemas/PH18/PH180101/index.html"
              onLoad={aplicarEstilosAlIframe}
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CorsFrame;
