import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaCheck, FaClipboard } from "react-icons/fa";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeBlock = ({ lenguaje, code }) => {
  const [codigoCopiado, setCodigoCopiado] = useState(false);

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(code)
        .then(() => setCodigoCopiado(true))
        .catch(() => setCodigoCopiado(false));
    } else {
      // Fallback para navegadores que no admiten la API Clipboard
      const elementoTemporal = document.createElement("textarea");

      elementoTemporal.value = code;

      document.body.appendChild(elementoTemporal);

      elementoTemporal.select();

      document.execCommand("copy");

      setCodigoCopiado(true);

      document.body.removeChild(elementoTemporal);
    }
  };

  useEffect(() => {
    if (codigoCopiado) {
      setTimeout(() => setCodigoCopiado(false), 1500);
    }
  }, [codigoCopiado]);

  return (
    <pre>
      <div className="bg-black mb-4">
        <div className="d-flex justify-content-between align-items-center position-relative code-block-header py-2 px-4">
          <span>{lenguaje}</span>

          <Button
            className="d-flex ms-auto align-items-center gap-2 copy-code-button"
            onClick={() => copyToClipboard()}
          >
            {codigoCopiado ? (
              <>
                <FaCheck /> ¡Copiado!
              </>
            ) : (
              <>
                <FaClipboard /> Copiar código
              </>
            )}
          </Button>
        </div>

        <div className="p-3 overflow-y-auto">
          <SyntaxHighlighter language={lenguaje} style={tomorrowNightBright}>
            {code}
          </SyntaxHighlighter>
        </div>
      </div>
    </pre>
  );
};

export default CodeBlock;
