import { Notificacion } from "@components/general/Alertas/Alertas";
import { deactivateRutaAprendizaje } from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const DesactivarRuta = ({ setRutaAprendizajeDesactivada, ruta }) => {
  const { idRutaAprendizajeDefault: idRuta } = ruta;

  const cancelarDesactivarRuta = () => {
    Notificacion("info", "No se desactivó la ruta");
    setRutaAprendizajeDesactivada(false);
  };

  const desactivarRuta = async (e) => {
    e.preventDefault();
    try {
      const { message } = await deactivateRutaAprendizaje(idRuta);
      setRutaAprendizajeDesactivada(true);
      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al desactivar la ruta");
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar la ruta seleccionada?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={desactivarRuta}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDesactivarRuta}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DesactivarRuta;
