export const laRosaMasBellaDelMundo = `Érase una reina muy poderosa, en cuyo jardín lucían las flores más hermosas de cada estación del año. Ella prefería las rosas por encima de todas; por eso las tenía de todas las variedades, desde el escaramujo de hojas verdes y olor de manzana hasta la más magnífica rosa de Provenza. Crecían pegadas al muro del palacio, se enroscaban en las columnas y los marcos de las ventanas y, penetrando en las galerías, se extendían por los techos de los salones, con gran variedad de colores, formas y perfumes.

Pero en el palacio moraban la tristeza y la aflicción. La Reina yacía enferma en su lecho, y los médicos decían que iba a morir.

-Hay un medio de salvarla, sin embargo -afirmó el más sabio de ellos-. Tráiganle la rosa más espléndida del mundo, la que sea expresión del amor puro y más sublime. Si puede verla antes de que sus ojos se cierren, no morirá.

Y ya tienen a viejos y jóvenes acudiendo, de cerca y de lejos, con rosas, las más bellas que crecían en todos los jardines; pero ninguna era la requerida. La flor milagrosa tenía que proceder del jardín del amor; pero incluso en él, ¿qué rosa era expresión del amor más puro y sublime?

Los poetas cantaron las rosas más hermosas del mundo, y cada uno celebraba la suya. Y el mensaje corrió por todo el país, a cada corazón en que el amor palpitaba; corrió el mensaje y llegó a gentes de todas las edades y clases sociales.

-Nadie ha mencionado aún la flor -afirmaba el sabio. Nadie ha designado el lugar donde florece en toda su magnificencia. No son las rosas de la tumba de Romeo y Julieta o de la Walburg, a pesar de que su aroma se exhalará siempre en leyendas y canciones; ni son las rosas que brotaron de las lanzas ensangrentadas de Winkelried, de la sangre sagrada que mana del pecho del héroe que muere por la patria, aunque no hay muerte más dulce ni rosa más roja que aquella sangre. Ni es tampoco aquella flor maravillosa para cuidar la cual el hombre sacrifica su vida velando de día y de noche en la sencilla habitación: la rosa mágica de la Ciencia.

-Yo sé dónde florece -dijo una madre feliz, que se presentó con su hijito a la cabecera de la Reina-. Sé dónde se encuentra la rosa más preciosa del mundo, la que es expresión del amor más puro y sublime. Florece en las rojas mejillas de mi dulce hijito cuando, restaurado por el sueño, abre los ojos y me sonríe con todo su amor.

Bella es esa rosa -contestó el sabio- pero hay otra más bella todavía.

-¡Sí, otra mucho más bella! -dijo una de las mujeres-. La he visto; no existe ninguna que sea más noble y más santa. Pero era pálida como los pétalos de la rosa de té. En las mejillas de la Reina la vi. La Reina se había quitado la real corona, y en las largas y dolorosas noches sostenía a su hijo enfermo, llorando, besándolo y rogando a Dios por él, como sólo una madre ruega a la hora de la angustia.

-Santa y maravillosa es la rosa blanca de la tristeza en su poder, pero tampoco es la requerida.

-No; la rosa más incomparable la vi ante el altar del Señor -afirmó el anciano y piadoso obispo-. La vi brillar como si reflejara el rostro de un ángel. Las doncellas se acercaban a la sagrada mesa, renovaban el pacto de alianza de su bautismo, y en sus rostros lozanos se encendían unas rosas y palidecían otras. Había entre ellas una muchachita que, henchida de amor y pureza, elevaba su alma a Dios: era la expresión del amor más puro y más sublime.

-¡Bendita sea! -exclamó el sabio-, mas ninguno ha nombrado aún la rosa más bella del mundo.

En esto entró en la habitación un niño, el hijito de la Reina; había lágrimas en sus ojos y en sus mejillas, y traía un gran libro abierto, encuadernado en terciopelo, con grandes broches de plata.
-¡Madre! -dijo el niño-. ¡Oye lo que acabo de leer!-. Y, sentándose junto a la cama, se puso a leer acerca de Aquél que se había sacrificado en la cruz para salvar a los hombres y a las generaciones que no habían nacido.

-¡Amor más sublime no existe!
Se encendió un brillo rosado en las mejillas de la Reina, sus ojos se agrandaron y resplandecieron, pues vio que de las hojas de aquel libro salía la rosa más espléndida del mundo, la imagen de la rosa que, de la sangre de Cristo, brotó del árbol de la Cruz.

-¡Ya la veo! -exclamó-. Jamás morirá quien contemple esta rosa, la más bella del mundo.`;
