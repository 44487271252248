import React, { useEffect, useState } from "react";

import getLocalStorage from "@helpers/localStorage/getLocalStorage";
import setLocalStorage from "@helpers/localStorage/setLocalStorage";

import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import MultiViewContext from "./MultiViewContext";

const MultiViewProvider = ({ children }) => {
  const initialState = {};

  const [contextValue, setContextValue] = useState(
    getLocalStorage(localStorageItemNames.multiViewContext, initialState)
  );

  useEffect(() => {
    setLocalStorage(localStorageItemNames.multiViewContext, contextValue);
  }, [contextValue]);

  const clearContext = () => {
    setContextValue(initialState);
  };

  return (
    <MultiViewContext.Provider
      value={{ contextValue, setContextValue, clearContext }}
    >
      {children}
    </MultiViewContext.Provider>
  );
};

export default MultiViewProvider;
