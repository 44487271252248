import { Notificacion } from "@components/general/Alertas/Alertas";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateNipPerfil } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const CambioNIP = ({ idPerfil, setRefetchUsuarios }) => {
  const [showNip, setShowNip] = useState(false);

  const [nip, setNip] = useState("");

  const showNipSeguridad = () => {
    setShowNip(!showNip);
  };

  const handleOnChange = ({ target }) => {
    setNip(target.value.replace(/[^0-9]/g, ""));
  };

  const handleCambiarNip = async () => {
    if (nip === "") {
      return NotificationManager.error("info", "Ingresa un NIP para continuar");
    }

    await updateNipPerfil({
      idPerfil,
      nip,
    });

    Notificacion("success", "NIP actualizado correctamente");

    setRefetchUsuarios(true);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form onSubmit={handleCambiarNip}>
            <Form.Group>
              <InputGroup hasValidation>
                <InputGroup.Text onClick={showNipSeguridad} className="pointer">
                  <FontAwesomeIcon icon={faEye} />
                </InputGroup.Text>

                <Form.Control
                  type={showNip ? "text" : "password"}
                  autoComplete="off"
                  value={nip}
                  name="nip"
                  placeholder="Ingresa un NIP"
                  onChange={handleOnChange}
                  maxLength={4}
                />
              </InputGroup>
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" type="submit" size="lg">
                Cambiar NIP
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CambioNIP;
