import { Notificacion } from "@components/general/Alertas/Alertas";
import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpFormDataRequest from "@helpers/httpRequests/httpFormDataRequest";
import { getGrupos } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { addLeccionPersonalizada } from "@helpers/httpRequests/httpRequestsLeccionesPersonalizadas/httpRequestsLeccionesPersonalizadas";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";

const NuevaLeccion = ({ setLeccionAgregada }) => {
  const [gruposEmpresariales, setGruposEmpresariales] = useState([]);

  //VALIDACION PAR EL FORMULARIO
  const [validated, setValidated] = useState(false);

  //STATE PARA LOADER
  const [showLoader, setShowLoader] = useState(false);

  const [formValues, setFormValues] = useState({
    nombre: "",
    leccion: "",
    archivoLeccion: "",
    nombreLeccion: "",
    tipoArchivo: "",
    grupo: 0,
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    // console.log(value);

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleOnChangeFile = (event) => {
    const { name } = event.target;

    const { name: nombreArchivo, type } = event.target.files[0];

    if (name === "plantilla") {
      setFormValues({
        ...formValues,
        tipoArchivo: type,
        nombrePlantilla: nombreArchivo,
        archivoLeccion: nombreArchivo,
      });
    } else {
      setFormValues({
        ...formValues,
        archivoComprimido: event.target.files[0],
        [name]: event.target.files[0].name,
      });
    }
  };

  const agregarLeccion = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    try {
      setShowLoader(true);

      const {
        nombre,
        leccion,
        tipoArchivo,
        grupo,
        archivoLeccion,
        nombrePlantilla,
        archivoComprimido,
      } = formValues;

      if (nombre !== "" && leccion !== "" && grupo !== 0) {
        const infoLeccion = {
          nombre,
          archivoLeccion: leccion.split(".")[0],
          nombrePlantilla,
          tipoArchivo: tipoArchivo,
          idGrupo: grupo,
        };

        const { message } = await addLeccionPersonalizada(infoLeccion);

        //* FETCH PARA MANDAR LOS DATOS DEL ARCHIVO AL SERVIDOR
        const formData = new FormData();

        formData.append("leccion", archivoComprimido);

        await httpFormDataRequest({
          url: `${endpointsPaths.foroEmpresarial}/${
            httpRequestsPaths.foroEmpresarial.manejoArchivos
              .addArchivoLeccionEmpresarial
          }?idGrupo=${grupo}&nombreCarpeta=${leccion.split(".")[0]}`,
          body: formData,
        });

        setLeccionAgregada(true);

        Notificacion("success", message);
      }
    } catch (error) {
      console.log(error);

      Notificacion(
        "error",
        "Algo ocurrió al subir la lección, por favor vuelve a intentar"
      );
    }
  };

  useEffect(() => {
    const obtenerGruposEmpresariales = async () => {
      try {
        const { data } = await getGrupos();

        // console.log(data);

        if (Array.isArray(data)) {
          setGruposEmpresariales(data);
        } else {
          setGruposEmpresariales([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerGruposEmpresariales();
  }, []);

  return (
    <Container>
      <Row>
        <Col className="text-start">
          <Form onSubmit={agregarLeccion}>
            <Form.Group className="mb-4">
              <Form.Label>Nombre de la lección</Form.Label>
              <Form.Control
                required
                value={formValues.nombre}
                onChange={handleOnChange}
                name="nombre"
                type="text"
                placeholder="Nombre de la lección"
              ></Form.Control>

              <Form.Control.Feedback type="invalid">
                <p>Este campo es obligatorio</p>
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Plantilla de la lección</Form.Label>
              <Form.Control
                onChange={handleOnChangeFile}
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                name="plantilla"
                type="file"
              ></Form.Control>
              <small className="text-info">
                El nombre de la plantilla y del archivo comprimido deben ser los
                mismos
              </small>
              <Form.Control.Feedback type="invalid">
                <p>Este campo es obligatorio</p>
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Archivo comprimido de la lección</Form.Label>
              <Form.Control
                onChange={handleOnChangeFile}
                accept="application/x-zip-compressed"
                name="leccion"
                type="file"
              ></Form.Control>
              <small className="text-info">
                El tipo de archivo comprimido debe ser .ZIP
              </small>
              <Form.Text>
                <p>El tipo de archivo comprimido debe ser ZIP</p>
              </Form.Text>

              <Form.Control.Feedback type="invalid">
                <p>Este campo es obligatorio</p>
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Selecciona el grupo empresarial</Form.Label>
              <Form.Control
                required
                onChange={handleOnChange}
                name="grupo"
                as="select"
              >
                <option value="">- SELECCIONE -</option>
                {gruposEmpresariales.length > 0 ? (
                  gruposEmpresariales.map((grupo, key) => (
                    <option key={key} value={grupo.idGrupo}>
                      {grupo.nombre}
                    </option>
                  ))
                ) : (
                  <option>No hay grupos empresariales</option>
                )}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                <p>Este campo es obligatorio</p>
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button
                disabled={showLoader ? true : false}
                type="submit"
                size="lg"
              >
                Agregar lección{" "}
                {showLoader && (
                  <Spinner
                    animation="border"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      verticalAlign: "middle",
                    }}
                    className={`mx-3${!setShowLoader ? "d-none" : ""}`}
                  />
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NuevaLeccion;
