import { Notificacion } from "@components/general/Alertas/Alertas";
import { deleteRutaPersonalizada } from "@helpers/httpRequests/httpRequestsRutasAprendizajePersonalizadas/httpRequestsRutasPersonalizadas";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarRuta = ({ setRutaPersonalizadaEliminada, idUsuario, ruta }) => {
  const { idRutaAprendizaje } = ruta;
  // console.log(idRutaAprendizaje);

  const cancelarEliminarRuta = () => {
    Notificacion("info", "No se eliminó la ruta");
    setRutaPersonalizadaEliminada(false);
  };

  const eliminarRuta = async (e) => {
    e.preventDefault();
    try {
      const { message } = await deleteRutaPersonalizada(idRutaAprendizaje);
      setRutaPersonalizadaEliminada(true);
      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al eliminar la ruta");
    }
  };

  return (
    <Container fluid className="text-center">
      <h5>¿Estás seguro de eliminar la ruta seleccionada?</h5>

      <Row className="pt-3">
        <Col>
          <Button
            className="btn-responsive"
            variant="success"
            onClick={eliminarRuta}
          >
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button
            className="btn-responsive"
            variant="danger"
            onClick={cancelarEliminarRuta}
          >
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarRuta;
