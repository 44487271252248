import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import TableRegistroInvitados from "../DataTables/Registro/TableRegistroInvitados";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { getAsistentesCapacitacion } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { validateExistenciaCorreo } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

const TabRegistro = () => {
  const [folioCapacitacion, setFolioCapacitacion] = useState("");

  const [asistentesCapacitacion, setAsistentesCapacitacion] = useState([]);

  const [invitadoAgregado, setInvitadoAgregado] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (folioCapacitacion === "") {
      return Notificacion(
        "error",
        "Ingresa un folio de capacitación antes de continuar"
      );
    }

    try {
      let asistentesCapacitacion = await getAsistentesCapacitacion(
        folioCapacitacion
      );

      for (const asistente in asistentesCapacitacion) {
        if (Object.hasOwnProperty.call(asistentesCapacitacion, asistente)) {
          const element = asistentesCapacitacion[asistente];

          let registrado;

          try {
            await validateExistenciaCorreo(
              element.correoEmpresa || element.correoPersonal
            );

            registrado = false;
          } catch (error) {
            registrado = true;
          }

          asistentesCapacitacion[asistente] = {
            ...asistentesCapacitacion[asistente],
            registrado,
          };
        }
      }

      setAsistentesCapacitacion(asistentesCapacitacion);
    } catch (error) {
      console.log(
        "Error al consultar los asistentes de la capacitacion: ",
        error
      );
    }
  };

  useEffect(() => {
    if (invitadoAgregado) {
      const consultarAsistentesCapacitacion = async () => {
        try {
          let asistentesCapacitacion = await getAsistentesCapacitacion(
            folioCapacitacion
          );

          for (const asistente in asistentesCapacitacion) {
            if (Object.hasOwnProperty.call(asistentesCapacitacion, asistente)) {
              const element = asistentesCapacitacion[asistente];

              let registrado;

              try {
                await validateExistenciaCorreo(
                  element.correoEmpresa || element.correoPersonal
                );

                registrado = false;
              } catch (error) {
                registrado = true;
              }

              asistentesCapacitacion[asistente] = {
                ...asistentesCapacitacion[asistente],
                registrado,
              };
            }
          }

          setAsistentesCapacitacion(asistentesCapacitacion);

          setInvitadoAgregado(false);
        } catch (error) {
          console.log("Error al consultar a los usuarios invitados: ", error);
        }
      };

      consultarAsistentesCapacitacion();
    }
  }, [invitadoAgregado]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Folio de capacitación</Form.Label>

              <Form.Control
                name="folioCapacitacion"
                type="text"
                placeholder="Ingresa el folio de capacitación"
                onChange={({ target }) => setFolioCapacitacion(target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Buscar asistentes
            </Button>
          </Form>

          {asistentesCapacitacion.length > 0 && (
            <TableRegistroInvitados
              setInvitadoAgregado={setInvitadoAgregado}
              asistentesCapacitacion={asistentesCapacitacion}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TabRegistro;
