import { MaterialReactTable } from "material-react-table";
import React from "react";

const DataTableRecipient = ({ columns, data, maxHeight = "50vh" }) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      muiTableContainerProps={{ sx: { maxHeight } }}
    />
  );
};

export default DataTableRecipient;
