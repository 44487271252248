const typesPdfDomiciliacion = {
    NOMBRE_SERVICIO: 'servicio',
    FECHA_SOLICITUD: {
        DIA: 'diaSolicitud',
        MES: 'mesSolicitud',
        ANIO: 'anioSolicitud'
    },
    DIAS_PAGO: 'diasPago',
    NOMBRE_BANCO: 'nombreBanco',
    NUMERO_TARJETA: 'numeroTarjeta',
    MONTO_MAXIMO: 'montoMaximo',
    RADIO_BUTTON_GROUP: 'opcionCargo',
    RADIO_BUTTON_MONTO_FIJO: 'radioBtnMontoFijo',
    MONTO_FIJO: 'montoFijo',
    FECHA_VENCIMIENTO: 'fechaVencimiento',
    NOMBRE_TITULAR: 'nombreTitular',
    FIRMA_TITULAR: 'firmaTitular'
};

export default typesPdfDomiciliacion;