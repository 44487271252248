import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateGrupo } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";

export const EliminarGrupo = ({ grupo, setGrupoEliminado }) => {
  const handleDeleteGrupo = async () => {
    try {
      await deactivateGrupo(grupo.idGrupo);

      setGrupoEliminado(true);

      Notificacion("success", "El grupo se desactivó correctamente");
    } catch (error) {
      console.log("Error al eliminar al grupo seleccionado: ", error);

      Notificacion(
        "error",
        "No se pudo eliminar el grupo seleccionado. \n" + error.message
      );
    }
  };

  const cancelarDeleteGrupo = () => {
    Notificacion("info", "No se desactivó el estudiante seleccionado");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar este grupo?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleDeleteGrupo}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeleteGrupo}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
