import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import "@assets/css/components/ForoEmpresarial/style.css";

import { ColorPicker } from "primereact/colorpicker";
import { DropZone } from "@helpers/DropZone/DropZone";
import { Notificacion } from "@components/general/Alertas/Alertas";
import Compressor from "compressorjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { updateGrupo } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";

const EditarGrupo = ({ idGrupo, infoGrupo, setInfoGrupo, setInfoGrupoModificada, handleClose, solicitarDatosEmpresa }) => {
	const { logotipo, nombre, color: colorEmpresa } = infoGrupo;

	const [formValues, setFormValues] = useState({
		logo: logotipo !== "" ? logotipo : "",
		nombre,
		colorEmpresa,
	});

	const [color, setColor] = useState("1976D2");

	const [showLogo, setShowLogo] = useState(false);

	const getBase64Image = ({ target }) => {
		try {
			const { files } = target;

			const [file] = files;

			const fileReader = new FileReader();

			if (!file) {
				return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
			}

			//!codigo para comparacion de peso de imagen antes de ser comprimida
			fileReader.readAsDataURL(file);

			new Compressor(file, {
				quality: 0.2,
				success(result) {
					fileReader.readAsDataURL(result);

					fileReader.onload = async function () {
						setFormValues({ ...formValues, logo: fileReader.result });
						setShowLogo(true);
					};
				},
				error(e) {
					console.log(e);

					Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
				},
			});
		} catch (e) {
			Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
		}
	};

	const quitarLogo = () => {
		setFormValues({ ...formValues, logo: "" });
		setShowLogo(false);
	};

	const handleOnChange = ({ target }) => {
		const { name, value } = target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const actualizarDatos = async (e) => {
		e.preventDefault();
		try {
			const grupo = {
				nombre: formValues.nombre,
				color,
				logotipo: formValues.logo,
				idGrupo,
			};

			const { message } = await updateGrupo(grupo);
			setInfoGrupoModificada(true);
			Notificacion("success", message);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (colorEmpresa === "") {
			setColor("f2f2f2");
		} else {
			setColor(colorEmpresa);
		}

		if (logotipo !== "") {
			setShowLogo(true);
		}
	}, []);

	return (
		<Container>
			<Row className='justify-content-end'>
				<Col className="col-auto">
					<FontAwesomeIcon onClick={handleClose} style={{fontSize: 24}} className="pointer" color={'red'} icon={faWindowClose}/>
				</Col>
			</Row>
			<Row>
				<Col>
					<h2>{solicitarDatosEmpresa ? "Completa la información de tu grupo" : "Editar Grupo"}</h2>
				</Col>
			</Row>
			<Row>
				<Col className="text-start mt-3">
					<Form onSubmit={actualizarDatos}>
						<Form.Group>
							<Form.Label>Nombre de la empresa</Form.Label>
							<Form.Control onChange={handleOnChange} name="nombre" type="text" value={formValues.nombre} placeholder="Nombre de la empresa"></Form.Control>
						</Form.Group>

						<Form.Group className="mt-3">
							<Form.Label>Color empresarial</Form.Label>
							<div>
								<ColorPicker style={{ marginLeft: "1rem" }} value={color} onChange={(e) => setColor(e.value)} />
							</div>
						</Form.Group>

						{showLogo ? (
							<>
								<div className="text-center">
									<p>Vista previa</p>
									<Image fluid width={"200"} src={formValues.logo}></Image>
									<div className="mt-3 mb-3">
										<FontAwesomeIcon onClick={quitarLogo} className="pointer" icon={faTrash} />
									</div>
								</div>
							</>
						) : (
							<>
								<Form.Group className="mt-3">
									<Form.Label>Logo de la empresa</Form.Label>
									<DropZone getImageInBase64={getBase64Image} />
								</Form.Group>
							</>
						)}

						<div className="d-grid gap-2 mt-4">
						<Button block type="submit">
							Guardar
						</Button>
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default EditarGrupo;
