import sepomexApiConfig from "./sepomexApiConfig";

const { BASE_URL, PATH, HTTP_PARAMS } = sepomexApiConfig;

const getDatosLocalidadByCodigoPostal = (codigoPostal) => {
    return new Promise(async (resolve, reject) => {
        try {
            const request = await fetch(`${BASE_URL}${PATH}/${codigoPostal}?${HTTP_PARAMS}`);
            
            if (request.status >= 200 && request.status < 300) {
                const { response } = await request.json();
                
                resolve(response);
            } else if (request.status === 400) {
                reject();
            }
        } catch (error) {
            console.log(error);

            reject(error);
        }
    });
};

const codigoPostalChecker = async (codigoPostal) => {
    if (/\d/.test(codigoPostal)) {
        if (codigoPostal.length === 5) {

            try {
                const datosLocalidad = await getDatosLocalidadByCodigoPostal(codigoPostal);

                if (!window.location.origin.includes('globalclickmexico.com')) {
                    datosLocalidad.asentamiento = ['Valle Delta'];
                    datosLocalidad.municipio = 'León';
                    datosLocalidad.estado = 'Guanajuato';
                }

                return {
                    colonias: datosLocalidad.asentamiento,
                    ciudad: datosLocalidad.municipio,
                    estado: datosLocalidad.estado
                };
            } catch (error) {
                console.log(error);
            }
        }
    }
};

export default codigoPostalChecker;