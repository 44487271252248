import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import React, { useEffect, useState } from "react";

const CustomMultiStateCheckbox = ({ idAgendaAsesoria, publica, handleChangePrivacy }) => {
	const [stateCheckbox, setStateCheckbox] = useState("private");

	const optionsMultiStateCheckbox = [
		{ value: "public", icon: "pi pi-globe" },
		{ value: "private", icon: "pi pi-lock" },
	];

	const handleOnChangeCheckboxPrivacidad = async (e) => {
		try {
			
			let aux = e.value;
			
			if (e.value === null) {
				aux = stateCheckbox === "public" ? "private" : "public";
			}

			await handleChangePrivacy({ idAgendaAsesoria, privacidad: aux });

			setStateCheckbox(aux);
		} catch (error) {
			console.log(error);
		}
	};

    useEffect(() => {
        setStateCheckbox(publica ? 'public' : 'private');
    }, []);

	return (
		<div className="flex flex-column align-items-center text-center gap-3">
			<MultiStateCheckbox
				value={stateCheckbox}
				onChange={handleOnChangeCheckboxPrivacidad}
				options={optionsMultiStateCheckbox}
				optionValue="value"
			/>
		</div>
	);
};

export default CustomMultiStateCheckbox;
