import { Button, Form } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { updateTransmission } from "@helpers/httpRequests/httpRequestsAulaVirtual/httpRequestsAulaVirtual";
import { useState } from "react";

const EditarTransmision = ({ setTransmisionModificada, transmision }) => {
  const [formValues, setFormValues] = useState(transmision);

  const { titulo, descripcion, urlTransmision } = formValues;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateTransmission(formValues);

      Notificacion("success", "Transmisión actualizada correctamente");

      setTransmisionModificada(true);
    } catch (error) {
      console.log("Error al actualizar la transmision: ", error);

      Notificacion("error", error.message);
    }
  };

  const handleOnChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="text-start">
      <Form.Group className="mb-2">
        <Form.Label>Titulo de la transmisión</Form.Label>

        <Form.Control
          type="text"
          name="titulo"
          onChange={handleOnChange}
          value={titulo}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Descripción</Form.Label>

        <Form.Control
          type="text"
          name="descripcion"
          onChange={handleOnChange}
          value={descripcion}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Enlace de la transmisión</Form.Label>

        <Form.Control
          type="text"
          name="urlTransmision"
          onChange={handleOnChange}
          value={urlTransmision}
        />
      </Form.Group>

      <div className="d-grid gap-2 mt-4">
        <Button variant="success" type="submit" size="lg">
          Guardar
        </Button>
      </div>
    </Form>
  );
};

export default EditarTransmision;
