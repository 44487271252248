import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";

import { ColorPicker } from "primereact/colorpicker";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { updateGrupo } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { NotificationManager } from "react-notifications";

export const EditarGrupo = ({ grupo, setGrupoEditado }) => {
  const [formValues, setFormValues] = useState({ ...grupo });

  const handleOnChange = ({ target }) => {
    try {
      const { name, value } = target;

      setFormValues({
        ...formValues,
        [name]: value,
      });
    } catch (error) {
      console.log("Error al actualizar el formValues: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const grupoActualizado = await updateGrupo(formValues);

      setGrupoEditado(true);

      Notificacion("success", "Datos del grupo modificados correctamente");
    } catch (error) {
      console.error("Error al actualizar los datos del grupo: ", error);

      NotificationManager.error(
        "No se pudo actualizar el grupo.\n" + error.message
      );
    }
  };

  return (
    <Form className="text-start" onSubmit={handleSubmit}>
      <Container fluid>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Nombre del grupo</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              type="text"
              name="nombre"
              value={formValues.nombre}
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Nombre de la empresa</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              type="text"
              name="nombreEmpresa"
              value={formValues.nombreEmpresa}
              autoComplete="off"
            />
          </Form.Group>
        </Row>

        <Row className="mt-4">
          <Form.Group as={Col}>
            <Form.Label>Color actual &nbsp;</Form.Label>

            <ColorPicker
              className="ps-2 text-end"
              value={formValues.color}
              name="color"
              disabled
            />

            <br />

            <Form.Label>Elige un color &nbsp;</Form.Label>

            <ColorPicker
              className="ps-2 text-end"
              value={formValues.color}
              name="color"
              onChange={handleOnChange}
            />

            {/* <Form.Control type="color" id="exampleColorInput" onChange={handleChangeColor} value={formValues.color} /> */}
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button type="submit" variant="success" size="lg">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
