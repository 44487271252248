import "@assets/css/components/Login/RegistroEmpleado/Intereses/style.css";

import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import React, { useContext, useEffect, useState } from "react";

import PillCheckbox from "@components/general/PillCheckbox/PillCheckbox";
import UserContext from "@context/User/UserContext";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { getDatosUsuario, getTemasInteres, updateIntereses } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { Link } from "@mui/material";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Intereses = ({ history }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { rol, idCredencial, usuarioTitular, modoOscuro, intereses: interesesUsuario } = usuarioLogeado;

	const [listaIntereses, setListaIntereses] = useState([]);

	const [intereses, setIntereses] = useState([]);

	const [interesesExistentes, setInteresesExistentes] = useState([]);

	const [loading, setLoading] = useState(false);

	const handleCheckboxInteresesChange = ({ target }) => {
		const { value } = target;

		if (target.checked) {
			setIntereses((intereses) => [...intereses, value]);
		} else {
			setIntereses(intereses.filter((interes) => interes !== value));
		}
	};

	const handleButtonClick = async () => {
		try {
			if (intereses.length === 0) {
				return Notificacion("info", "Elige al menos un tema de tu interés");
			}

      setLoading(true);
      
			await updateIntereses({
				intereses: intereses.join(","),
				idPersona: usuarioLogeado.idPersona,
			});

      const { data } = await getDatosUsuario({
				tipoUsuario: rol,
				idCredencial,
				usuarioTitular,
			});

			const [datosUsuarioActualizados] = data;

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuarioActualizados,
			});

      setLoading(false);

			Notificacion("success", "Intereses actualizados correctamente");

			history.replace(dashboardRoutesPaths.dashboard);
		} catch (error) {
			console.log(error);

			NotificacionExtendida("error", "Algo sucedió al establecer tus intereses, por favor vuelve a  intentar. Si el problema persiste, llama a Soporte Técnico");
		}
	};

	const consultarIntereses = async () => {
		try {
			const intereses = await getTemasInteres();

			setListaIntereses(intereses);
		} catch (error) {
			console.log("error al consultar los intereses: ", error);
		}
	};

	useEffect(() => {
		if (listaIntereses.length === 0) {
			consultarIntereses();
		}

    if (interesesUsuario !== null) {
      const auxIntereses = interesesUsuario.split(',');

      setInteresesExistentes(auxIntereses);

      setIntereses([...intereses, ...auxIntereses]);
    }
	}, []);

	return (
		<Container fluid className="formRegistro" style={{ overflowY: "scroll" }}>
			<Row className="justify-content-center align-items-center py-4 vh-100">
				<Col sm={12} md={8}>
					<Card className="h-100 border-0">
						<Card.Header className="text-center border-bottom-none">
							<h3 className="text-primary">¡Bienvenido, {usuarioLogeado.nombreCompleto.split(" ")[0]}!</h3>

							<p>Para una mejor experiencia en tu plataforma, selecciona tus temas de interés</p>
						</Card.Header>

						<Card.Body>
							<Container>
								<Row className="justify-content-center">
									{listaIntereses.length === 0 ? (
										<Col xs={12} md={6}>
											<Spinner animation="border" role="status">
												<span className="visually-hidden">Cargando...</span>
											</Spinner>
										</Col>
									) : (
										listaIntereses.map(({ tema }, idx) => (
											<Col xs={12} md={6} key={idx}>
												<PillCheckbox defaultChecked={interesesExistentes.includes(tema)} title={tema} name="temaInteres" value={tema} onChange={handleCheckboxInteresesChange} />
											</Col>
										))
									)}
								</Row>

								<Row className="justify-content-center mb-3">
									<Col className="text-center">
										<Button variant="primary" className="w-50 mt-3" onClick={handleButtonClick}>
											Siguiente { loading ? <FontAwesomeIcon spin icon={faSpinner} /> : null }
										</Button>
									</Col>
								</Row>

								<Row>
									<Col className="text-center">
										<Link href="#" underline="always" color={modoOscuro ? "#f3f3f3" : "#3e3e3e"} onClick={() => history.replace(dashboardRoutesPaths.dashboard)}>
											Omitir
										</Link>
									</Col>
								</Row>
							</Container>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Intereses;
