import React, { useEffect, useState } from "react";

import MultiViewPanelAdministracionContext from "./MultiViewPanelAdministracionContext";
import getLocalStorage from "@helpers/localStorage/getLocalStorage";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import setLocalStorage from "@helpers/localStorage/setLocalStorage";

const MultiViewPanelAdministracionProvider = ({ children }) => {
  const initialState = {};

  const [contextValue, setContextValue] = useState(
    getLocalStorage(
      localStorageItemNames.multiViewPanelAdministracion,
      initialState
    )
  );

  useEffect(() => {
    setLocalStorage(
      localStorageItemNames.multiViewPanelAdministracion,
      contextValue
    );
  }, [contextValue]);

  const clearContext = () => {
    setContextValue(initialState);
  };

  return (
    <MultiViewPanelAdministracionContext.Provider
      value={{ contextValue, setContextValue, clearContext }}
    >
      {children}
    </MultiViewPanelAdministracionContext.Provider>
  );
};

export default MultiViewPanelAdministracionProvider;
