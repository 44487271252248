import getDateFromUnix from '@helpers/dates/getDateFromUnix/getDateFromUnix';
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';

const VerInfoGanador = ({infoGanador}) => {

    console.log(infoGanador);
    const {avatarPerfil, correo, descripcionMision, tituloMision, fechaExpiracion, iconoProyecto, nombreGanador}= infoGanador;




  return (
    <Container className='text-start mt-3 mb-3'>
        <Row className='d-flex align-items-center'>
           
            <Col>
                <h4>Mision: <b>{tituloMision}</b></h4>
                <p>Descripción de la misión:</p>
                <pre style={{ whiteSpace: "pre-line" }} >
                                         <b><p>{descripcionMision.replace(/^"|"$/g, '')}</p></b>
                                     </pre> 
                        
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col className='text-center'>
                <h4>Ganador de la misión</h4>
                <Image className='mb-4 rounded-circle' src={(avatarPerfil === "null" || avatarPerfil === "") ? require(`@assets/images/avatar.png`): avatarPerfil} width="200" height="200" />
                <p><b>{nombreGanador}</b></p>
                <span>{getDateFromUnix(fechaExpiracion)}</span> 
            </Col>
        </Row>
    </Container>
  )
}

export default VerInfoGanador