import { Notificacion } from "@components/general/Alertas/Alertas";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { addTema } from "@helpers/httpRequests/httpRequestsTemas/httpRequestsTemas";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const NuevoTema = ({ setTemaAgregado, alias, idPerfil }) => {
  const [categorias, setCategorias] = useState([]);

  const [formValues, setFormValues] = useState({
    textoPublicacion: "",
    tituloPublicacion: "",
    idCategoria: 0,
    idForo: 1,
    alias,
    fechaCreacion: getUnixTimestamp(),
  });

  const [validacionForm, setValidacionForm] = useState({
    idCategoria: false,
    tituloPublicacion: false,
    textoPublicacion: false,
  });

  const [caracteresRestantes, setCaracteresRestantes] = useState(500);

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    
    if(caracteresRestantes > 0){
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

    if (value === "" || value === "0") {
      setValidacionForm({
        ...validacionForm,
        [name]: !validacionForm.name,
      });
    } else {
      setValidacionForm({
        ...validacionForm,
        [name]: false,
      });
    }
  };

  const validanOnKeyDown = (event) => {
    const { key } = event;

    if (key === 'Backspace') {
      if (caracteresRestantes < 500) {
        setCaracteresRestantes(caracteresRestantes + 1);
      }
    } else {
      if (caracteresRestantes > 0) {
        setCaracteresRestantes(caracteresRestantes - 1);
      }
    }
  };

  const publicar = async (e) => {
    e.preventDefault();

    try {
      const {
        tituloPublicacion,
        textoPublicacion,
        idCategoria,
        idForo,
        alias,
        fechaCreacion,
      } = formValues;

      // console.log(idCategoria);

      if (tituloPublicacion === "") {
        setValidacionForm({
          ...validacionForm,
          tituloPublicacion: true,
        });
      } else if (textoPublicacion === "") {
        setValidacionForm({
          ...validacionForm,
          textoPublicacion: true,
        });
      } else if (idCategoria === 0) {
        // console.log();
        setValidacionForm({
          ...validacionForm,
          idCategoria: true,
        });
      }

      if (
        tituloPublicacion !== "" &&
        textoPublicacion !== "" &&
        idCategoria !== 0
      ) {
        const nuevaPublicacion = {
          titulo: tituloPublicacion,
          textoPublicacion: JSON.stringify(textoPublicacion.replace(/'/g, "\\'").replace(/"/g, '\\"')),
          idCategoria,
          idForoAyuda: idForo,
          alias,
          idPerfil,
          fechaCreacion,
        };

        const { message } = await addTema(nuevaPublicacion);

        setTemaAgregado(true);

        Notificacion("success", message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("Categorias");

    const categorias = async () => {
      try {
        const { data } = await getCategoriasCursos();
        // console.log(data);
        if (Array.isArray(data)) {
          setCategorias(data);
        } else {
          setCategorias([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    categorias();
  }, []);

  useEffect(() => {
    if(formValues.textoPublicacion === ''){
      setCaracteresRestantes(500);
    }
  }, [formValues.textoPublicacion])
  

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={publicar} className="text-start">
            <Form.Group>
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                className={`mb-3 ${validacionForm.idCategoria ? "is-invalid" : ""
                  }`}
                name="idCategoria"
                onChange={handleOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Selecciona una categoría
                </option>

                {categorias.map((categoria, key) => (
                  <option key={key} value={categoria.idCategoria}>
                    {categoria.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Selecciona una categoría
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                className={validacionForm.tituloPublicacion ? "is-invalid" : ""}
                type="text"
                name="tituloPublicacion"
                value={formValues.tituloPublicacion}
                onChange={handleOnChange}
                placeholder="Titulo del tema"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Escribe un título para tu publicación
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Publicación</Form.Label>
              <Form.Control
                className={validacionForm.textoPublicacion ? "is-invalid" : ""}
                name="textoPublicacion"
                as="textarea"
                value={formValues.textoPublicacion}
                onChange={handleOnChange}
                onKeyDown={validanOnKeyDown}
                rows={5}
                placeholder="Describe tu publicación"
              />
              <Form.Control.Feedback type="invalid">
                Describe tu publicación
              </Form.Control.Feedback>
            </Form.Group>

            <div className="mt-2 mb-3">
              <small style={{color: 'gray', fontWeight: 'bold'}}>{caracteresRestantes}/500</small>
            </div>

            <div className="d-grid gap-2">
              <Button type="submit" size="lg">
                Publicar
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NuevoTema;
