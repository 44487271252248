import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import GridBeneficios from "./GridBeneficios";

const CardBeneficios = () => {
	return (
		<Row className="pt-4 text-black">
			<Col className="pb-4 pb-lg">
                <Card body className="bg-blue-gc">
                    <Container fluid className="card-beneficios text-center">
                        <h2 className="text-white">Beneficios incluidos</h2>

                        <GridBeneficios />
                    </Container>
                </Card>
			</Col>
		</Row>
	);
};

export default CardBeneficios;
