import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
import {
  addSeccion,
  getSeccionesByCodigoCurso,
} from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { useState } from "react";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

export const AgregarSeccion = ({ setSeccionAgregada }) => {
  const [secciones, setSecciones] = useState([]);

  const [codigoCurso, setCodigoCurso] = useState("");

  const [cursos, setCursos] = useState([]);

  const nombreSeccionRef = useRef();

  const handleSeleccionCurso = async ({ target }) => {
    const codigoCurso = target.value;

    setCodigoCurso(codigoCurso);

    try {
      let { data: secciones } = await getSeccionesByCodigoCurso(codigoCurso);

      if (!Array.isArray(secciones)) {
        secciones = [secciones];
      }

      setSecciones(secciones);
    } catch (error) {
      console.log("Error al consultar las secciones: ", error);
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      let seccionNueva = {
        nombre: nombreSeccionRef.current.value,
        codigoCurso,
      };

      if (secciones.length > 0) {
        const nivelUltimaSeccion = secciones[secciones.length - 1].nivel;

        const nivelNuevaSeccion = parseInt(nivelUltimaSeccion) + 1;

        const nuevoNivelFormateado =
          nivelNuevaSeccion >= 10
            ? nivelNuevaSeccion.toString()
            : `0${nivelNuevaSeccion}`;

        seccionNueva.codigoSeccion = `${codigoCurso}${nuevoNivelFormateado}`;
      } else {
        seccionNueva.codigoSeccion = `${codigoCurso}01`;
      }

      const seccionAgregada = await addSeccion(seccionNueva);

      console.log(seccionAgregada);

      setSeccionAgregada(true);

      Notificacion(
        "success",
        "La sección fue agregada como última sección del curso"
      );
    } catch (error) {
      console.log("Error al agregar una nueva sección: ", error);
    }
  };

  useEffect(() => {
    const constultarCursos = async () => {
      try {
        const { data: cursos } = await getCursos();

        setCursos(cursos);
      } catch (error) {
        console.log("Error al consultar los cursos: ", error);
      }
    };

    constultarCursos();
  }, []);

  return (
    <Form onSubmit={handleOnSubmit}>
      <Container fluid className="text-start">
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Seleciona un curso</Form.Label>

            <Form.Control
              as="select"
              custom
              onChange={handleSeleccionCurso}
              className="form-control"
              name="codigoCurso"
            >
              <option value="">{"Selecciona un curso"}</option>

              {cursos.map((curso, idx) => (
                <option value={curso.codigoCurso} key={idx}>
                  {curso.nombre} - {curso.codigoCurso}{" "}
                  {codigoCursosUsuarioInvitado.find(
                    (invitado) => invitado.codigoInvitado === curso.codigoCurso
                  ) !== undefined && "- Usuarios Invitados"}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        {codigoCurso !== "" && (
          <>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="sectionName">
                <Form.Label>Nombre de la sección</Form.Label>

                <Form.Control
                  ref={nombreSeccionRef}
                  type="text"
                  placeholder="Nombre de la seccion"
                  name="nombre"
                />
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <div className="d-grid gap-2 mt-4">
                  <Button variant="primary" type="submit" size="lg">
                    Agregar
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Form>
  );
};
