import { Box, IconButton } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { FaDownload, FaEdit, FaTrash } from "react-icons/fa";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import EliminarPlantilla from "../Modales/EliminarPlantilla/EliminarPlantilla";
import EditarPlantilla from "../Modales/EditarPlantilla/EditarPlantilla";
import { getPlantillasTrabajo } from "@helpers/httpRequests/httpRequestPlantillasTrabajo/httpRequestPlantillasTrabajo";

const TablePlantillasTrabajo = ({
  plantillaAgregada,
  setPlantillaAgregada,
}) => {
  const [plantillasTrabajo, setPlantillasTrabajo] = useState([]);

  const [plantillaEditada, setPlantillaEditada] = useState(false);

  const [plantillaEliminada, setPlantillaEliminada] = useState(false);

  const columnas = useMemo(() => [
    {
      accessorKey: "nombre",
      header: "Nombre",
    },
    {
      accessorKey: "codigoLeccion",
      header: "Código de Lección",
    },
    {
      accessorKey: "urlPlantilla",
      header: "Enlace",
    },
  ]);

  const handleDescargarPlantilla = async ({ currentTarget }) => {
    try {
      const { urlPlantilla, nombre } = plantillasTrabajo.find(
        (item) => item.idPlantillaTrabajo === parseInt(currentTarget.id)
      );

      const [extensionPlantilla] = urlPlantilla.split(".").reverse();

      const request = await fetch(urlPlantilla);

      const response = await request.blob();

      const url = window.URL.createObjectURL(new Blob([response]));

      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", `${nombre}.${extensionPlantilla}`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("error al descargar la plantilla: ", error);
    }
  };

  const handleEditarPlantilla = async ({ currentTarget }) => {
    const plantilla = plantillasTrabajo.find(
      (item) => item.idPlantillaTrabajo === parseInt(currentTarget.id)
    );

    await ModalSweetAlert({
      title: "Editar plantilla",
      html: (
        <EditarPlantilla
          plantilla={plantilla}
          setPlantillaEditada={setPlantillaEditada}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleEliminarPlantilla = async ({ currentTarget }) => {
    await ModalSweetAlert({
      title: "Eliminar plantilla",
      icon: "error",
      html: (
        <EliminarPlantilla
          idPlantillaTrabajo={currentTarget.id}
          setPlantillaEliminada={setPlantillaEliminada}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  useEffect(() => {
    const consultarPlantillasTrabajo = async () => {
      try {
        let plantillas = await getPlantillasTrabajo();

        if (!Array.isArray(plantillas)) {
          plantillas = [plantillas];
        }

        setPlantillasTrabajo(plantillas);
      } catch (error) {
        console.log("Error al consultar las plantillas: ", error);
      }
    };

    consultarPlantillasTrabajo();
  }, []);

  useEffect(() => {
    if (plantillaAgregada || plantillaEditada || plantillaEliminada) {
      const consultarPlantillasTrabajo = async () => {
        try {
          let plantillas = await getPlantillasTrabajo();

          if (!Array.isArray(plantillas)) {
            plantillas = [plantillas];
          }

          setPlantillasTrabajo(plantillas);

          setPlantillaAgregada(false);

          setPlantillaEditada(false);

          setPlantillaEliminada(false);
        } catch (error) {
          console.log("Error al consultar las plantillas: ", error);
        }
      };

      consultarPlantillasTrabajo();
    }
  }, [plantillaAgregada, plantillaEditada, plantillaEliminada]);

  return (
    <MaterialReactTable
      localization={MRT_Localization_ES}
      columns={columnas}
      data={plantillasTrabajo}
      enableRowActions
      positionActionsColumn="last"
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
          <IconButton
            color="primary"
            id={row.original.idPlantillaTrabajo}
            onClick={handleDescargarPlantilla}
          >
            <FaDownload className="text-info" />
          </IconButton>

          <IconButton
            color="secondary"
            id={row.original.idPlantillaTrabajo}
            onClick={handleEditarPlantilla}
          >
            <FaEdit className="text-primary" />
          </IconButton>

          <IconButton
            color="secondary"
            id={`${row.original.idPlantillaTrabajo}`}
            onClick={handleEliminarPlantilla}
          >
            <FaTrash color="red" />
          </IconButton>
        </Box>
      )}
    />
  );
};

export default TablePlantillasTrabajo;
