import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { updateRutaPersonalizada } from "@helpers/httpRequests/httpRequestsRutasAprendizajePersonalizadas/httpRequestsRutasPersonalizadas";

const ModificarRuta = ({ ruta, setRutaPersonalizadaModificada }) => {
  const [sinRecursos, setSinRecursos] = useState(false);

  //STATES PARA LOS SELECTS DE LAS SECCIONES Y LAS LECCIONES

  const [selectSeccionesDeshabilitado, setSelectSeccionesDeshabilitado] =
    useState(true);

  const [selectLeccionesDeshabilitado, setSelectLeccionesDeshabilitado] =
    useState(true);

  const [leccionesRepetidas, setLeccionesRepetidas] = useState({
    status: false,
    razon: "",
  });

  const [cursoDistinto, setCursoDistinto] = useState(false);

  const [botonAgregarDeshabilitado, setBotonAgregarDeshabilitado] =
    useState(true);

  const [nombreCurso, setNombreCurso] = useState({
    nombre: "",
  });

  const [listaLecciones, setListaLecciones] = useState([]);

  const [formValues, setFormValues] = useState({
    nombreNuevo: ruta.nombre,
    descripcionNueva: ruta.descripcion,
    nombreCurso: "",
    codigoCurso: "",
    nombreSeccion: "",
    codigoSeccion: "",
    nombreLeccion: "",
    codigoLeccion: "",
  });

  //STATES PARA ALMACENAR LOS DATOS DE LOS CURSOS, SECCIONES Y LECCIONES DE LOS SELECTS

  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const [selectedSeccion, setSelectedSeccion] = useState(null);

  const [selectedLeccion, setSelectedLeccion] = useState(null);

  const handleSelectOnChange = ({ target }) => {
    const { name, value } = target;

    console.log(name);

    const arregloValue = value.split("/"); //arregloValue[0] = valor del codigoCurso / arregloValue[1] = valor del nombreCurso

    const arregloName = name.split("/"); //arregloName[0] = 'codigoCurso' / arregloName[1] = 'nombreCurso'

    if (arregloName[0] === "codigoCurso") {
      obtenerSecciones(arregloValue[0]);
      setSelectedSeccion(0);
      setSelectedLeccion(0);
    }
    if (arregloName[0] === "codigoSeccion") {
      obtenerLecciones(arregloValue[0]);
      setSelectedSeccion(value);
      setSelectedLeccion(0);
    }
    if (arregloName[0] === "codigoLeccion") {
      setBotonAgregarDeshabilitado(false);
      setSelectedLeccion(value);
    }

    setFormValues({
      ...formValues,
      [arregloName[1]]: arregloValue[1],
      [arregloName[0]]: arregloValue[0],
    });
  };

  const agregarLeccion = async () => {
    try {
      // const { data } = await getCursoByCodigo(formValues.codigoCurso);

      setCursoDistinto(false);
      // console.log(formValues.codigoLeccion);

      const duplicados = listaLecciones.find((object, index) => {
        return listaLecciones
          .slice(index)
          .some(
            (otherObject) => object.codigoLeccion === formValues.codigoLeccion
          );
      });

      if (duplicados === undefined) {
        setLeccionesRepetidas({
          status: false,
          razon: "",
        });

        return setListaLecciones([
          ...listaLecciones,
          ...lecciones.filter(
            (leccion) => leccion.codigoLeccion === formValues.codigoLeccion
          ),
        ]);
      }

      setLeccionesRepetidas({
        status: true,
        razon: "Las lecciones no se pueden repetir, elige otra.",
      });

      setFormValues({
        ...formValues,
        nombreCurso: "",
        codigoCurso: "",
        nombreSeccion: "",
        codigoSeccion: "",
        nombreLeccion: "",
        codigoLeccion: "",
        categoriaCurso: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarLeccion = ({ target }) => {
    const { name, id } = target;

    const indice = parseInt(id);

    let nuevasLecciones = [...listaLecciones];

    nuevasLecciones.splice(indice, 1);

    setListaLecciones(nuevasLecciones);
  };

  const obtenerSecciones = async (codigoCurso) => {
    try {
      setSinRecursos(false);

      const { data } = await getSeccionesByCodigoCurso(codigoCurso);
      if (Array.isArray(data)) {
        setSecciones(data);
      } else {
        setSecciones([data]);
      }
      setSelectSeccionesDeshabilitado(false);
    } catch (error) {
      console.log(error);

      setSinRecursos(true);
    }
  };

  const obtenerLecciones = async (codigoLeccion) => {
    try {
      setSinRecursos(false);

      const { data } = await getLeccionesByCodigoSeccion(codigoLeccion);
      // console.log(data);

      if (Array.isArray(data)) {
        setLecciones(data);
      } else {
        setLecciones([data]);
      }
      setSelectLeccionesDeshabilitado(false);
    } catch (error) {
      console.log(error);
      setSinRecursos(true);
    }
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    //   console.log(name, value);

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const guardarCambios = async (e) => {
    e.preventDefault();
    try {
      let obj = listaLecciones.map((leccion) => ({
        nombre: leccion.nombre,
        codigoLeccion: leccion.codigoLeccion,
      }));

      const infoRuta = {
        idRuta: ruta.idRutaAprendizaje,
        nombreRuta: formValues.nombreNuevo,
        descripcionRuta: formValues.descripcionNueva,
        lecciones: JSON.stringify(obj),
      };

      const { message } = await updateRutaPersonalizada(infoRuta);
      setRutaPersonalizadaModificada(true);

      Notificacion("success", message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleListarTodo = () => {
    const arregloLeccionesAListar = [];

    const arregloLeccionesListadas = [];

    listaLecciones.map((leccionListada) => {
      arregloLeccionesListadas.push(leccionListada.codigoLeccion);
    });

    lecciones.map((leccionAListar) => {
      arregloLeccionesAListar.push(leccionAListar.codigoLeccion);
    });

    const validacionRepetidas = arregloLeccionesAListar.map(
      (leccionAListar) => {
        const repetidas = arregloLeccionesListadas.includes(leccionAListar);
        if (repetidas) {
          return true;
        }
      }
    );

    if (validacionRepetidas.includes(true)) {
      setLeccionesRepetidas({
        status: true,
        razon:
          "Parece que ya tienes listada una o más lecciones, asegurate de que no se repitan",
      });

      setFormValues({
        ...formValues,
        nombreCurso: "",
        codigoCurso: "",
        nombreSeccion: "",
        codigoSeccion: "",
        nombreLeccion: "",
        codigoLeccion: "",
      });
    } else {
      setListaLecciones([...listaLecciones, ...lecciones]);

      setLeccionesRepetidas({
        status: false,
        razon: "",
      });
    }
  };

  useEffect(() => {
    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();
        // console.log(data);
        const cursos = data.filter(
          (curso) =>
            curso.codigoCurso !== "EXUI" &&
            curso.codigoCurso !== "EAUI" &&
            curso.codigoCurso !== "EIUI" &&
            curso.codigoCurso !== "PBUI" &&
            curso.codigoCurso !== "PBUA"
        );
        console.log(cursos);

        setCursos(cursos);

        data.map((dato) => {
          if (dato.codigoCurso === ruta.codigoCurso) {
            setNombreCurso({
              nombre: dato.nombre,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursos();

    const { lecciones } = ruta;

    const listaDeLecciones = JSON.parse(lecciones);

    setListaLecciones(listaDeLecciones);
  }, []);

  return (
    <Form onSubmit={guardarCambios}>
      <Container className="text-start">
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                <h5>Nombre de la ruta</h5>
              </Form.Label>
              <Form.Control
                className="input-form"
                style={{ fontSize: "1rem" }}
                onChange={handleOnChange}
                type="text"
                name="nombreNuevo"
                value={formValues.nombreNuevo}
                placeholder="Nombre de la ruta"
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <Form.Group>
              <Form.Control
                style={{ fontSize: "1rem" }}
                className="select-form"
                name="codigoCurso/nombreCurso"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione un curso
                </option>
                {cursos.map((curso, key) => (
                  <option
                    key={key}
                    value={`${curso.codigoCurso}/${curso.nombre}`}
                  >
                    {curso.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Control
                value={selectedSeccion}
                style={{ fontSize: "1rem" }}
                className="select-form"
                disabled={selectSeccionesDeshabilitado}
                name="codigoSeccion/nombreSeccion"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione una sección
                </option>
                {secciones.map((seccion, key) => (
                  <option
                    key={key}
                    value={`${seccion.codigoSeccion}/${seccion.nombre}`}
                  >
                    {seccion.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="text-start">
              <Form.Control
                value={selectedLeccion}
                style={{ fontSize: "1rem" }}
                className="select-form"
                disabled={selectLeccionesDeshabilitado}
                name="codigoLeccion/nombreLeccion"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione una lección
                </option>
                {lecciones.map((leccion, key) => (
                  <option
                    key={key}
                    value={`${leccion.codigoLeccion}/${leccion.nombre}`}
                  >
                    {leccion.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Row className="justify-content-center">
              <Col className="col-11 p-0 mb-3">
                <Button
                  className="btn-form-rutas"
                  style={{ fontSize: "12px" }}
                  disabled={botonAgregarDeshabilitado}
                  block
                  onClick={agregarLeccion}
                  variant="success"
                >
                  Agregar
                </Button>
              </Col>
              <Col className="col-11 p-0 mb-3">
                <Button
                  className="btn-form-rutas"
                  style={{ fontSize: "12px" }}
                  onClick={handleListarTodo}
                  block
                  disabled={selectLeccionesDeshabilitado ? "disabled" : ""}
                >
                  Agregar todas las lecciones
                </Button>
              </Col>
              {leccionesRepetidas && (
                <Col className="col-12 text-center">
                  <small className="text-danger alert-rutas">
                    {leccionesRepetidas.razon}
                  </small>
                </Col>
              )}

              {sinRecursos && (
                <Col className="col-12">
                  <small className="text-danger alert-rutas">
                    El curso selecionado no contiene secciones o lecciones
                    disponible, eliga otro.
                  </small>
                </Col>
              )}
            </Row>
          </Col>

          <Col md={7} className="mt-4 mt-md-0  ">
            <div className="p-3 text-start contenedor-rutas ">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <h6>
                        <b>Lecciones agregadas</b>
                      </h6>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listaLecciones.length > 0 ? (
                    <>
                      {listaLecciones.map((leccion, key) => (
                        <tr key={leccion.codigoLeccion}>
                          <td className="btn-form-rutas">{leccion.nombre}</td>
                          <td className="text-center">
                            <Button
                              name={leccion.nombre}
                              id={key}
                              onClick={eliminarLeccion}
                              variant="danger"
                              className="btn-sm m-1"
                            >
                              <FontAwesomeIcon
                                className="btn-form-rutas"
                                style={{ fontSize: "12px" }}
                                icon={faTrash}
                              />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>
                        <h6>Aún no tienes lecciones agregadas</h6>
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>
                <h5>Descripción de la ruta</h5>
              </Form.Label>
              <Form.Control
                className="input-form"
                as="textarea"
                maxLength={90}
                name="descripcionNueva"
                onChange={handleOnChange}
                placeholder="Descripción de la ruta"
                style={{ height: "100px", fontSize: "1rem" }}
                value={formValues.descripcionNueva}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Button type="submit" block className="mt-4 btn-responsive">
            Guardar cambios
          </Button>
        </Row>
      </Container>
    </Form>
  );
};

export default ModificarRuta;
