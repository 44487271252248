import React, { useContext } from "react";

import slider2 from "@assets/images/slider2.png";
import chatIa from "@assets/images/chat-ia.png";
import sliderMisiones from "@assets/images/slider-misiones.png";
import salaEntretenimiento from "@assets/images/slider-juegos.png";
import agendaAsesoria from "@assets/images/agenda-asesoria.png";
import chatEnLinea from "@assets/images/chat-online.png";
import nuevoContenido from "@assets/images/SliderNuevoContenido.png"
import UserContext from "@context/User/UserContext";
import { Carousel } from "react-bootstrap";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";

const SeccionSliderHome = ({ handleChangeComponent }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const handleCurso = (view, codigoCurso) => {
		setContextValue({
			...contextValue,
			codigoCursoSeleccionado: codigoCurso,
			codigoSeccionSeleccionada: codigoCurso + "01",
		});

		handleChangeComponent(view);
	};

	const handleMos = (view) => {
		handleChangeComponent(view);
	};

	return (
		<Carousel className="main-slider mt-4 mb-4">

			<Carousel.Item>
				<img src={nuevoContenido} alt="slider-misiones" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>
			<Carousel.Item onClick={() => handleChangeComponent(multiViewComponentsPaths.agendasAsesorias)} className="pointer">
				<img src={agendaAsesoria} alt="slider-meta" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>

			<Carousel.Item>
				<img src={chatEnLinea} alt="slider-meta" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>

			<Carousel.Item onClick={() => handleChangeComponent(multiViewComponentsPaths.suiteAI)} className="pointer">
				<img src={chatIa} alt="slider-meta" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>

			<Carousel.Item onClick={() => handleMos("cursoPropedeuticoMos")} className="pointer">
				<img src={slider2} alt="slider-mos" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>

			<Carousel.Item className="pointer" onClick={() => handleChangeComponent(multiViewComponentsPaths.misiones)}>
				<img src={sliderMisiones} alt="slider-misiones" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>

			<Carousel.Item className="pointer" onClick={() => handleChangeComponent(multiViewComponentsPaths.salaEntretenimiento)}>
				<img src={salaEntretenimiento} alt="slider-misiones" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item>

		</Carousel>
	);
};

export default SeccionSliderHome;
