import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import Compressor from "compressorjs";
import { ImageDropZone } from "@components/general/ImageDropZone/ImageDropZone";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { addCurso } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getLicencias } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";

export const AgregarCurso = ({ setCursoAgregado }) => {
  const refImageDefaultPortadaCurso = useRef();

  const [formValues, setFormValues] = useState({
    nombreCurso: "",
    codigoCurso: "",
    portadaCurso: "",
    tiempoCurso: "",
    idCategoria: 0,
    isMsOffice: false,
  });

  const [validacion, setValidacion] = useState({
    nombreCurso: false,
    codigoCurso: false,
    tiempoCurso: false,
    idCategoria: false,
  });

  const [licencias, setLicencias] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [licenciasCurso, setLicenciasCurso] = useState([]);

  const [showPreviewPortadaCurso, setShowPreviewPortadaCurso] = useState(false);

  const [usePortadaDefault, setUsePortadaDefault] = useState(false);

  const handleAddCourse = async (e) => {
    e.preventDefault();

    try {
      // if (licenciasCurso.length < 1) {
      // 	return Notificacion('error', 'Elige al menos una licencia para este curso');
      // }

      const cursoAgregado = await addCurso({
        ...formValues,
        // licenciasCurso,
        portadaDefault: usePortadaDefault,
      });

      if (cursoAgregado) {
        setCursoAgregado(true);

        Notificacion("success", "Curso agregado exitosamente");
      }
    } catch (error) {
      console.log("Error al agregar el curso: ", error);

      Notificacion("error", "Algo sucedió al agregar el curso");
    }
  };

  const handleUsePortadaDefault = ({ currentTarget }) => {
    try {
      setUsePortadaDefault(currentTarget.checked);
    } catch (error) {
      console.log("error al convertir la portada default a base64", error);
    }
  };

  const handleIsMsOffice = ({ currentTarget }) => {
    try {
      setFormValues({
        ...formValues,
        isMsOffice: currentTarget.checked,
      });
    } catch (error) {
      console.log("error al convertir la portada default a base64", error);
    }
  };

  const handleCheckboxChange = ({ target }) => {
    try {
      const idLicencia = target.value;

      if (!licenciasCurso.includes(idLicencia)) {
        setLicenciasCurso((currentValue) => [...currentValue, idLicencia]);
      } else {
        setLicenciasCurso(() =>
          licenciasCurso.filter((licencia) => licencia !== idLicencia)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBase64Image = ({ target }) => {
    try {
      const { files } = target;

      const [file] = files;

      const fileReader = new FileReader();

      if (!file) {
        return Notificacion(
          "warning",
          "¡Ups! Algo salió mal, vuelve a cargar tu foto"
        );
      }

      //!codigo para comparacion de peso de imagen antes de ser comprimida
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.2,
        success(result) {
          fileReader.readAsDataURL(result);

          fileReader.onload = async function () {
            setFormValues({ ...formValues, portadaCurso: fileReader.result });

            setShowPreviewPortadaCurso(true);
          };
        },
        error(error) {
          console.log(error);

          Notificacion(
            "warning",
            "Ups!, algo salió mal, vuelve a cargar tu foto"
          );
        },
      });
    } catch (error) {
      console.log(error);

      Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
    }
  };

  const handleOnChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });

    if (target.name === "nombreCurso" && target.value !== "") {
      setValidacion({
        ...validacion,
        nombreCurso: true,
      });
    }

    if (target.name === "codigoCurso" && target.value.length >= 4) {
      setValidacion({
        ...validacion,
        codigoCurso: true,
      });
    }

    if (target.name === "tiempoCurso" && target.value > 0) {
      setValidacion({
        ...validacion,
        tiempoCurso: true,
      });
    }
  };

  useEffect(() => {
    const consultarLicencias = async () => {
      try {
        const { data: licencias } = await getLicencias();

        setLicencias(licencias);
      } catch (e) {
        console.log(e);
      }
    };

    const consultarCategorias = async () => {
      try {
        const { data: categorias } = await getCategoriasCursos();

        console.log(categorias);

        setCategorias(categorias);
      } catch (error) {
        console.log("Error al consultar las categorías de los cursos: ", error);
      }
    };

    consultarLicencias();

    consultarCategorias();
  }, []);

  useEffect(() => {
    if (usePortadaDefault) {
      fetch(refImageDefaultPortadaCurso.current.src)
        .then((request) => request.blob())
        .then((response) => {
          try {
            const fileReader = new FileReader();

            fileReader.onload = ({ target }) => {
              setFormValues({
                ...formValues,
                portadaCurso: target.result,
              });
            };

            fileReader.readAsDataURL(response);
          } catch (error) {
            console.log(
              "Error al convertir la imagen default a base64: ",
              error
            );
          }
        });
    } else {
      setFormValues({
        ...formValues,
        portadaCurso: "",
      });
    }
  }, [usePortadaDefault]);

  return (
    <Form onSubmit={handleAddCourse}>
      <Container fluid className="text-start">
        <Row>
          <Form.Group as={Col} controlId="nombreCurso" className="mb-4">
            <Form.Label>Nombre del curso</Form.Label>

            <Form.Control
              type="text"
              className={validacion.nombreCurso ? "" : "is-invalid"}
              name="nombreCurso"
              onChange={handleOnChange}
              value={formValues.nombreCurso}
            />

            <Form.Control.Feedback type="invalid">
              Ingresa un nombre para el curso
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="codigoCurso" className="mb-4">
            <Form.Label>Codigo del curso</Form.Label>

            <Form.Control
              type="text"
              className={validacion.codigoCurso ? "" : "is-invalid"}
              name="codigoCurso"
              onChange={handleOnChange}
              value={formValues.codigoCurso}
            />

            <Form.Control.Feedback type="invalid">
              El código debe contener al menos 4 caracteres
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="portada" className="mb-4">
            <Form.Label>Elige una portada para el curso</Form.Label>

            {usePortadaDefault ? (
              <Image
                ref={refImageDefaultPortadaCurso}
                className="default-portada-curso"
                src={require("@assets/images/portadas/default.png")}
                alt="Portada default curso"
                width="150"
              />
            ) : (
              <>
                {!showPreviewPortadaCurso ? (
                  <ImageDropZone getImageInBase64={getBase64Image} />
                ) : (
                  <Image
                    className="preview-portada-curso"
                    src={formValues.portadaCurso}
                    alt="Portada del curso"
                    width="150"
                  />
                )}
              </>
            )}
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-4">
            <Form.Check
              id="usePortadaDefault"
              label="Utilizar portada por defecto"
              type="switch"
              onChange={handleUsePortadaDefault}
              value="usePortadaDefault"
            />
          </Form.Group>
        </Row>

        {/* <Row>
					<Form.Group as={Col} controlId="formGridEmail">
						<Form.Label>Elige las licencias dónde estará disponible</Form.Label>

						{
							licencias.map((licencia, idx) => (
								<Form.Check
									key={idx}
									id={licencia.idLicencia}
									label={licencia.nombre}
									onChange={handleCheckboxChange}
									value={licencia.idLicencia}
								/>
							))
						}
					</Form.Group>
				</Row> */}

        <Row>
          <Form.Group as={Col} className="mb-4">
            <Form.Label>
              Ingresa cuánto tiempo dura el curso en horas
            </Form.Label>

            <Form.Control
              type="number"
              className={validacion.tiempoCurso ? "" : "is-invalid"}
              name="tiempoCurso"
              onChange={handleOnChange}
              value={formValues.tiempoCurso}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-4">
            <Form.Label>Elige la categoría para el curso</Form.Label>

            <Form.Control
              as="select"
              onChange={handleOnChange}
              name="idCategoria"
              type="text"
              value={formValues.idCategoria}
            >
              <option value="">--Selecciona una categoría--</option>
              {categorias.map((categoria) => (
                <option
                  key={categoria.idCategoria}
                  value={categoria.idCategoria}
                >
                  {categoria.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className="py-2">
          <Form.Group as={Col}>
            <Form.Check
              id="isMsOffice"
              label="Marcar como curso de Office"
              type="switch"
              onChange={handleIsMsOffice}
              value="isMsOffice"
            />
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button variant="success" type="submit" size="lg">
                Agregar curso
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
