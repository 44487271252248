import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import {
  faEdit,
  faEye,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAdministradores } from "@helpers/httpRequests/httpRequestsUsuarios/administradores/httpRequestsAdministradores";
import { reactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EditarAdministrador from "../../Modales/Administrador/EditarAdministrador/EditarAdministrador";
import { EliminarAdministrador } from "../../Modales/Administrador/EliminarAdministrador/EliminarAdministrador";
import { VerAdministrador } from "../../Modales/Administrador/VerAdministrador/VerAdministrador";
import columnasAdministrador from "./columnasAdministrador";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";
import { FaEdit, FaEye, FaPowerOff, FaTrash } from "react-icons/fa";

const TableAdministrador = ({ adminAgregado, setAdminAgregado }) => {
  const [administradores, setAdministradores] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [adminEditado, setAdminEditado] = useState(false);

  const [adminReactivado, setAdminReactivado] = useState(false);

  const [adminEliminado, setAdminEliminado] = useState(false);

  const handleVerDatosAdministrador = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataAdministrador = administradores.filter(
        (admin) => admin.idPersona === idPersona
      )[0];

      await ModalSweetAlert({
        html: <VerAdministrador administrador={dataAdministrador} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al consultar los datos del asesor", error);
    }
  };

  const handleEditDatosAdministrador = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataAdministrador = administradores.filter(
        (admin) => admin.idPersona === idPersona
      )[0];

      await ModalSweetAlert({
        title: "Actualizar datos de administrador",
        html: (
          <EditarAdministrador
            administrador={dataAdministrador}
            setAdminEditado={setAdminEditado}
          />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al actualizar los datos del asesor: ", error);
    }
  };

  const handleDeactivateAdministrador = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataAdministrador = administradores.filter(
        (admin) => admin.idPersona === idPersona
      )[0];

      await ModalSweetAlert({
        title: "Eliminar administrador",
        icon: "warning",
        html: (
          <EliminarAdministrador
            administrador={dataAdministrador}
            setAdminEliminado={setAdminEliminado}
          />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al desactivar al asesor: ", error);
    }
  };

  const handleReactivateAdministrador = async ({ currentTarget }) => {
    try {
      const idPersona = parseInt(currentTarget.id);

      const dataAdministrador = administradores.filter(
        (admin) => admin.idPersona === idPersona
      )[0];

      await reactivateUsuario(dataAdministrador.idCredencial);

      setAdminReactivado(true);

      Notificacion("success", "El administrador se reactivó correctamente");
    } catch (error) {
      console.log("Error al reactivar al adminstrador: ", error);
    }
  };

  useEffect(() => {
    const consultarAdministradores = async () => {
      try {
        const administradores = await getAdministradores();

        setAdministradores(administradores);
      } catch (error) {
        console.log("Error al consultar los asesores registrados: ", error);
      }
    };

    consultarAdministradores();
  }, []);

  useEffect(() => {
    if (administradores.length > 0) {
      setDataTableColumns([
        ...columnasAdministrador,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`select-${row.idPersona}`}
                className="me-1 pointer"
                onClick={handleVerDatosAdministrador}
              >
                <FontAwesomeIcon icon={faEye} className="text-primary" />
              </span>

              <span
                id={`edit-${row.idPersona}`}
                className="me-1 pointer"
                onClick={handleEditDatosAdministrador}
              >
                <FontAwesomeIcon icon={faEdit} className="text-info" />
              </span>

              {row.estatusCredencial ? (
                <span
                  id={`delete-${row.idPersona}`}
                  className="me-1 pointer"
                  onClick={handleDeactivateAdministrador}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              ) : (
                <span
                  id={`reactivate-${row.idPersona}`}
                  className="me-1 pointer"
                  onClick={handleReactivateAdministrador}
                >
                  <FontAwesomeIcon icon={faPowerOff} className="text-success" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]);
    }
  }, [administradores]);

  useEffect(() => {
    if (setAdminAgregado || adminEditado || adminEliminado || adminReactivado) {
      const consultarAdministradores = async () => {
        try {
          const administradores = await getAdministradores();

          setAdministradores(administradores);

          setAdminAgregado(false);

          setAdminEditado(false);

          setAdminEliminado(false);

          setAdminReactivado(false);
        } catch (error) {
          console.log(
            "Error al consultar los administradores registrados: ",
            error
          );
        }
      };

      consultarAdministradores();
    }
  }, [adminAgregado, adminEditado, adminEliminado, adminReactivado]);

  const columnas = useMemo(() => [
    {
      accessorKey: "nombreCompleto",
      header: "Nombre",
    },
    {
      accessorKey: "apellidos",
      header: "Apellidos",
    },
    {
      accessorKey: "correo",
      header: "Correo",
    },
    {
      accessorKey: "curp",
      header: "CURP",
    },
  ]);

  return (
    <Container fluid>
      <Row>
        <Col>
          {/* {
						asesores.length < 1 && <SpinnerImage />
					}
					{
                        asesores.length > 0 &&
                        <DataTablesRecipient columns={dataTableColumns} data={asesores} />
                        
                    } */}

          {/* <DataTablesRecipient columns={dataTableColumns} data={administradores} /> */}
          <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={columnas}
            data={administradores}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                <IconButton
                  color="primary"
                  id={row.original.idPersona}
                  onClick={handleVerDatosAdministrador}
                >
                  <FaEye className="text-info" />
                </IconButton>

                <IconButton
                  color="secondary"
                  id={row.original.idPersona}
                  onClick={handleEditDatosAdministrador}
                >
                  <FaEdit className="text-primary" />
                </IconButton>

                {row.original.estatusCredencial ? (
                  <IconButton
                    color="secondary"
                    id={`${row.original.idPersona}`}
                    onClick={handleDeactivateAdministrador}
                  >
                    <FaTrash color="red" />
                  </IconButton>
                ) : (
                  <IconButton
                    color="secondary"
                    id={`${row.original.idPersona}`}
                    onClick={handleReactivateAdministrador}
                  >
                    <FaPowerOff color="green" />
                  </IconButton>
                )}
              </Box>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TableAdministrador;
