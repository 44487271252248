
export const imagenesMuestra = [
    {
        nombre: "Watercolor Painting",
        imagen: require('../resources/img/atardecer.webp'),
        imagenAlt: require('../resources/img/atardecer.jpeg'),
        nombrePath: "/watercolor-painting-generator",
    },
    {
        nombre: "Vintage Film Poster",
        imagen: require('../resources/img/poster-iron-man.webp'),
        imagenAlt: require('../resources/img/poster-iron-man.jpeg'),
        nombrePath: "/film-poster-generator",
    },
    {
        nombre: "Neo Noir Poster",
        imagen: require('../resources/img/thomas.webp'),
        imagenAlt: require('../resources/img/thomas.jpeg'),
        nombrePath: "/neo-noir-generator",
    },
    {
        nombre: "Cyberpunk",
        imagen: require('../resources/img/cyberpunk.webp'),
        imagenAlt: require('../resources/img/cyberpunk.jpeg'),
        nombrePath: "/cyberpunk-generator",
    },
    {
        nombre: "Anime Portrait Generator",
        imagen: require('../resources/img/anime_portrait.webp'),
        imagenAlt: require('../resources/img/anime_portrait.jpeg'),
        nombrePath: "/anime-portrait-generator",
    },
    {
        nombre: "Impressionism Painting",
        imagen: require('../resources/img/impressionism.webp'),
        imagenAlt: require('../resources/img/impressionism.jpeg'),
        nombrePath: "/impressionism-painting-generator",
    },
    {
        nombre: "3D Objects",
        imagen: require('../resources/img/3dObjects.webp'),
        imagenAlt: require('../resources/img/3dObjects.jpeg'),
        nombrePath: "/3d-objects-generator",
    },
    {
        nombre: "Contemporary Architecture",
        imagen: require('../resources/img/contemporaryArchitecture.webp'),
        imagenAlt: require('../resources/img/contemporaryArchitecture.jpeg'),
        nombrePath: "/contemporary-architecture-generator",
    },
    {
        nombre: "AI Logo",
        imagen: require('../resources/img/logo.webp'),
        imagenAlt: require('../resources/img/logo.jpeg'),
        nombrePath: "/logo-generator",
    },
    {
        nombre: "Surreal Portrait",
        imagen: require('../resources/img/surrealism.webp'),
        imagenAlt: require('../resources/img/surrealism.jpeg'),
        nombrePath: "/surreal-portrait-generator",
    },
    {
        nombre: "Fantasy Portrait",
        imagen: require('../resources/img/fantasy.webp'),
        imagenAlt: require('../resources/img/fantasy.jpeg'),
        nombrePath: "/fantasy-portrait-generator",
    },
    {
        nombre: "3D Cartoon",
        imagen: require('../resources/img/3dCartoon.webp'),
        imagenAlt: require('../resources/img/3dCartoon.jpeg'),
        nombrePath: "/3d-cartoon-generator",
    },
    {
        nombre: "Comics Superhero",
        imagen: require('../resources/img/comicsSuperhero.webp'),
        imagenAlt: require('../resources/img/comicsSuperhero.jpeg'),
        nombrePath: "/comics-superhero-generator",
    },
    {
        nombre: "Abstract Expressionism",
        imagen: require('../resources/img/abstract.webp'),
        imagenAlt: require('../resources/img/abstract.jpeg'),
        nombrePath: "/abstract-expressionism-generator",
    },
    {
        nombre: "Supernatural Character",
        imagen: require('../resources/img/supernatural.webp'),
        imagenAlt: require('../resources/img/supernatural.jpeg'),
        nombrePath: "/supernatural-character-generator",
    },
    {
        nombre: "Solar System",
        imagen: require('../resources/img/solarSystem.webp'),
        imagenAlt: require('../resources/img/solarSystem.jpeg'),
        nombrePath: "/solar-system-generator",
    },
    {
        nombre: "Zombie Apocalypse Scene",
        imagen: require('../resources/img/zombieApocalypse.webp'),
        imagenAlt: require('../resources/img/zombieApocalypse.jpeg'),
        nombrePath: "/zombie-apocalypse-generator",
    },
    {
        nombre: "Psychedelic Poster",
        imagen: require('../resources/img/psychedelic.webp'),
        imagenAlt: require('../resources/img/psychedelic.jpeg'),
        nombrePath: "/psychedelic-poster-generator",
    },
    {
        nombre: "Terrarium World",
        imagen: require('../resources/img/terrarium.webp'),
        imagenAlt: require('../resources/img/terrarium.jpeg'),
        nombrePath: "/terrarium-world-generator",
    },
];