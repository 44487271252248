import "@assets/css/components/DashboardUsuarios/Modales/Asesor/style.css";

import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

export const EliminarAdministrador = ({ administrador, setAdminEliminado }) => {
  console.log(administrador);

  const handleDeleteAdministrador = async () => {
    try {
      await deactivateUsuario(administrador.idCredencial);

      setAdminEliminado(true);

      Notificacion("success", "El administrador se desactivó correctamente");
    } catch (error) {
      console.log("Error al eliminar al administrador seleccionado: ", error);

      Notificacion(
        "error",
        "No se pudo eliminar al administrador. \n" + error.message
      );
    }
  };

  const cancelarDeleteAdministrador = () => {
    Notificacion("info", "No se desactivó el administrador seleccionado");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar a este administrador?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleDeleteAdministrador}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeleteAdministrador}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
