import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "@assets/css/components/Certificado/certificado.css";
import getFilledPdfBytes from "./getFilledPdfBytes/getFilledPdfBytes";

// Import the main component 
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

const TemplateCertificado = () => {
  const [dataImage, setDataImage] = useState({
    urlImage: "",
  });

  const [datosCertificado, setDatosCertificados] = useState({
    nombreCompleto: "",
    nombreCurso: "",
    horasCurso: 0,
    fechaFinalizacion: "",
  });

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  useEffect(() => {
    //Funcion para descargar el pdf sin la libreria
    const saveByteArray = (byte) => {
      const blob = new Blob([byte], { type: "application/pdf" });

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);

      setDataImage({
        ...dataImage,
        urlImage: link.href,
      });
    };

    const createPdf = async (infoPdf) => {
      try {
        const { curso } = infoPdf;
        //TODO Aqui se va a evaluar en base a la info que llegue, si es certificado de un curso de microsoft para elegir la plantilla.

        let template = "";

        if (curso) {
          template = "template_certificado_microsoft.pdf";
        } else {
          template = "certificado_vista_publica.pdf";
        }

        const data = await fetch(
          `${window.location.origin}/assets/templates/${template}`
        );

        const pdfTemplateBytes = await data.arrayBuffer();

        const filledPdfBytes = await getFilledPdfBytes(
          pdfTemplateBytes,
          infoPdf
        );

        saveByteArray(filledPdfBytes);
      } catch (error) {
        console.log(error);
      }
    };

    const generarPDF = () => {
      try {
        //Se reciben los datos para manejarlos en la vista pública
        const qsParse = new URLSearchParams(window.location.search);

        const datosURL = decodeURIComponent(window.atob(qsParse.get("data")));
        //Transformamos el string del data en arreglo
        const array = JSON.parse(datosURL);

        //Desestructuramos los datos y los pasamos al certificado
        const {
          0: nombreCompleto,
          1: nombreCurso,
          2: horasCurso,
          3: fechaFinalizacion,
          4: idPerfil,
          5: codigoCurso,
          6: curso,
        } = array;

        //url de la vista pública a donde el usuario será redirigido desde el certificado
        const urlVistaPublica = `${window.location.origin}/certificado`;

        //Esta información esta por el momento estática para cuando la recuperemos del curso.
        //Se recuperara la informacion del curso y cuando se finalice el curso se habilitara el componente para generar el pdf.
        const informacion = {
          url: urlVistaPublica,
          nombre: nombreCompleto,
          nombreCurso,
          tiempoCurso: horasCurso,
          idPerfil,
          codigoCurso,
          fechaFinalizacion,
          curso,
        };

        createPdf(informacion);
      } catch (error) {
        console.log(error);
      }
    };

    generarPDF();
  }, []);

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
          >
            <Viewer
              fileUrl={
                dataImage.urlImage !== ""
                  ? dataImage.urlImage
                  : "./template_certificado_microsoft.pdf"
              }
            />
          </Worker>
        </Col>
      </Row>
    </Container>
  );
};
export default TemplateCertificado;
