import { Notificacion } from "@components/general/Alertas/Alertas";
import { reactivatePregunta } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export const ReactivarPregunta = ({ setPreguntaReactivada, infoPregunta }) => {
  const { idPregunta } = infoPregunta;

  console.log(idPregunta);

  const handleReactivarPregunta = async () => {
    try {
      const { message } = await reactivatePregunta(idPregunta);
      Notificacion("success", message);
      setPreguntaReactivada(true);
    } catch (error) {
      Notificacion("error", error);
    }
  };

  const cancelarReactivatePregunta = () => {
    Notificacion("info", "No se reactivó el consejo");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de reactivar la pregunta seleccionada?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleReactivarPregunta}>
            Sí, reactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarReactivatePregunta}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
