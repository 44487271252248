const columns = [
  {
    name: "Lección",
    selector: (row) => row.nombre,
    sortable: true,
    center: true,
    width: "50%",
  },
];

export default columns;
