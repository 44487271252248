import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { MdSend } from "react-icons/md";
import "@assets/css/components/ChatOpenAI/style.css";
import { consultarIntentos, nuevasConsultas, sendGPTRequest, sinConsultas, updateConsultas } from "@helpers/httpRequests/httpRequestsOpenAI/httpRequestsOpenAI";
import OpenAIMessages from "./components/OpenAIMessages";
import { ModalSweetAlert, Notificacion } from "../Alertas/Alertas";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { areSameDate } from "@helpers/dates/areSameDate/AreSameDate";
import UserContext from "@context/User/UserContext";

const busquedasRecomendadas = [
  "¿Cómo puedo declarar e inicializar una variable en Visual Basic?",
  "¿Cuál es la diferencia entre 'Sub' y 'Function' en Visual Basic?",
  "¿Cómo puedo realizar una estructura de control 'If...Then...Else' en Visual Basic?",
  "¿Qué método puedo usar para leer datos de un archivo en Visual Basic?",
];

const lordIconProps = {
  home: {
    src: "https://cdn.lordicon.com/flvisirw.json",
    trigger: "loop",
    colors: "primary:#d3d0d0,secondary:#ffcc00,tertiary:#ebe6ef",
    style: { width: "150px", height: "150px" },
  },
  loader: {
    src: "https://cdn.lordicon.com/avytqtql.json",
    trigger: "loop",
    colors: "primary:#ffcc00,secondary:#ebe6ef",
    style: { width: "100px", height: "100px" },
  },
};


const ChatVisualBasic = () => {



  const { contextValue, setContextValue } = useContext(UserContext);
  const { usuarioLogeado } = contextValue;

  const { idPerfil } = usuarioLogeado;

  //*Tour del componente
  const joyrideRef = useRef(null);

  const [tourSteps, setTourSteps] = useState([
    {
      target: ".vb-question",
      content: "Te damos la bienvenida al asistente virutla de Visual Basic, con el puedes resolver dudas, hacer preguntas y mejorar en tus proyectos de programación todo esto con ayuda de la inteligencia artificial (IA).",
      disableBeacon: true,
    },

    {
      target: ".vb-examples",
      content: "Puedes seleccionar alguna de las opciones predefinidas.",
      disableBeacon: true,
    },

    {
      target: ".vb-text",
      content: "O puedes escribir tu propia consulta personalizada.",
      disableBeacon: true,
    },

    {
      target: ".vb-credits",
      content: "Usa los 5 créditos diarios para sacar el mayor provecho a la herramienta.",
      disableBeacon: true,
    },

    {
      target: ".vb-question",
      content: "Ahora que conoces al asesor virtual no dudes en consultarlo para agilizar tu aprendizaje con nosotros.",
      disableBeacon: true,
    },


  ]);
  const [runTour, setRunTour] = useState(false);

  const [busquedaRealizada, setBusquedaRealizada] = useState(false);

  const [loading, setLoading] = useState(false);

  const [busqueda, setBusqueda] = useState("");

  const [historialMessages, setHistorialMessages] = useState([]);

  const [consultasRestantes, setConsultasRestantes] = useState(0);

  const handleStartTour = () => {
    setRunTour(true);
  }

  const sendRequest = async (busqueda) => {
    try {
      setBusquedaRealizada(true);

      setLoading(true);

      const historial = await sendGPTRequest({
        consulta: busqueda,
        messagesHistory: historialMessages,
      });

      setHistorialMessages(
        [...historialMessages, ...historial].filter((obj, index, self) => {
          return index === self.findIndex((t) => t.content === obj.content);
        })
      );

      setBusqueda("");

      setLoading(false);
    } catch (error) {
      console.log(error);

      if (error.data === "gpt-daily-limit-reached") {
        setBusquedaRealizada(false);

        setBusqueda("");

        setLoading(false);

        ModalSweetAlert({
          icon: "warning",
          title: error.message,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      if (consultasRestantes !== 0) {

        //Aun no se llega al limite
        sendRequest(busqueda);

        const actualizarContador = await updateConsultas({
          idPerfil,
          codigoHerramienta: "VB",
					fechaUso: getUnixTimestamp(),
        })

        const { consultasRestantes } = actualizarContador.data.data;

        if (consultasRestantes === 0) {
          //Se alcanzo el limite
          const sinConsulta = await sinConsultas({
            idPerfil,
            fechaActual: getUnixTimestamp(),
            codigoHerramienta: "VB",
          })
          setConsultasRestantes(0)

          Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")

        }

        setConsultasRestantes(consultasRestantes);


      } else {
        Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")

      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleDefaultRequest = async ({ currentTarget }) => {
    try {

      if (consultasRestantes !== 0) {



        sendRequest(busquedasRecomendadas[currentTarget.id.split("-")[1]]);

        const actualizarContador = await updateConsultas({
          idPerfil,
          codigoHerramienta: "VB",
					fechaUso: getUnixTimestamp(),
        })

        const { consultasRestantes } = actualizarContador.data.data;

        if (consultasRestantes === 0) {
          //Se alcanzo el limite
          const sinConsulta = await sinConsultas({
            idPerfil,
            fechaActual: getUnixTimestamp(),
            codigoHerramienta: "VB",
          })

          setConsultasRestantes(0)

          Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")


        }

        setConsultasRestantes(consultasRestantes);


      } else {
        Notificacion("error", "Has llegado al límite de consultas diarias, vuelve mañana para seguir usando la herramienta.")

      }




    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const consultarIntentosSuite = async () => {
      try {
        //CONSULTA A LA API PARA VALIDAR CONSULTAS RESTANTES

        const intentosSuite = await consultarIntentos({
          idPerfil,
          codigoHerramienta: "VB",
          fechaActual: getUnixTimestamp()
        });


        if (intentosSuite.isRegistered) {
          //En caso de estar registrado con x cantidad de intentos

          const { consultasRestantes, fechaDesbloqueo, fechaActual, estatus } = intentosSuite.data.data;

          const sameDate = areSameDate(fechaActual, getUnixTimestamp()); //valida si la fecha registrada por primera vez es igual a la fecha que se tiene que desbloquear

          setConsultasRestantes(consultasRestantes)

          // if (sameDate === false && estatus === 0) {

          //   setConsultasRestantes(5);
          //   const conConsultas = await nuevasConsultas({
          //     idPerfil,
          //     codigoHerramienta: "VB"
          //   });

          // }



        } else {
          //En caso de no estar registrado, se le agregan en automatico los 5 intentos
          setConsultasRestantes(5);

        }

      } catch (error) {
        console.log(error);
      }
    }

    consultarIntentosSuite()
  }, [])




  return (
    <Container
      fluid
      className="h-100 chat-main-content vb-question fondo-generador"
    >

      <Joyride
        locale={spanishOptionsJoyRide}
        ref={joyrideRef}
        steps={tourSteps}
        run={runTour}
        hideCloseButton
        showSkipButton
        continuous={true}
        disableOverlayClose={false}
        disableCloseOnEsc={false}
        disableScrolling={false}
        scrollToFirstStep={false}
        scrollOffset={100}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRunTour(false);
          }

        }}
        styles={{
          options: {
            primaryColor: "#ff9900",
            textColor: "#00152A",
            width: 900,
            zIndex: 1000,
          },
        }}
      />

      <Row className='justify-content-between pt-5'>
        <Col className="col-auto text-center text-white vb-credits">
          <h3>Créditos restantes: {consultasRestantes}/5</h3>
        </Col>
        <Col className="col-3 text-center ">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>Ayuda</Tooltip>}
          >
            <span>
              <FontAwesomeIcon
                className=" pointer "
                onClick={handleStartTour}
                style={{ fontSize: "2rem", color: "white" }}
                icon={faQuestion}
              />
            </span>
          </OverlayTrigger>
        </Col>
      </Row>

      {!busquedaRealizada && (
        <Row className="justify-content-center pt-5">
          <Col className="text-center text-white col-8">
            {/* <lord-icon {...lordIconProps.home}></lord-icon> */}
            <picture>
              <source srcset={require(`@assets/images/asistenteVisualB.webp`)} type="image/webp" />

              <Image src={require(`@assets/images/asistenteVisualB.png`)} fluid alt="Asistente de visual basic"></Image>

            </picture>
            {/* 
            <h3>Bienvenido(a) al Asesor Virtual</h3> */}

            {/* <h5>
              Aquí puedes realizar preguntas sobre temas en concreto como:
              fórmulas de Excel, código de Visual Basic y más.
            </h5> */}
          </Col>
        </Row>
      )}

      <Row
        className="container-historial-messages"
        style={{ height: busquedaRealizada ? "70vh" : "20vh" }}
      >
        <Col>
          <Container fluid className="pt-5">
            {historialMessages.map((message, idx) => (
              <Row
                key={idx}
                className={`respuesta-chat ${message.role === "user" ? "user" : "ia"
                  }`}
              >
                <Col>
                  <OpenAIMessages
                    role={message.role}
                    message={message.content}
                  />
                </Col>
              </Row>
            ))}

            {loading && (
              <Row className="h-100">
                <Col className="d-flex align-items-center justify-content-center">
                  <lord-icon {...lordIconProps.loader}></lord-icon>
                </Col>
              </Row>
            )}
          </Container>
        </Col>
      </Row>

      {!busquedaRealizada && (
        <Row className="vb-examples">
          {busquedasRecomendadas.map((busqueda, idx) => (
            <Col key={idx} xs={6} className="text-center ">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Da clic para buscar</Tooltip>}
              >
                <Button
                  id={`default-${idx}`}
                  className="my-1 text-start button-chat"
                  type="button"
                  variant="warning"
                  style={{
                    height: "125px",
                    width: "75%",
                    backgroundColor: '#ff9900',
                    fontWeight: "bold",
                  }}
                  onClick={handleDefaultRequest}
                >
                  {busqueda}
                </Button>
              </OverlayTrigger>
            </Col>
          ))}
        </Row>
      )}

      <Row className="mt-5 me-4">
        <Col className="vb-text">
          <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
              <Form.Control
                className="input-chat-ia"
                placeholder="Haz una pregunta"
                as="textarea"
                rows={4}
                value={busqueda}
                onChange={(e) => setBusqueda(e.target.value)}
              />
              <Button type="submit" variant="warning" style={{backgroundColor: "#ff9900"}}>
                <MdSend />
              </Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ChatVisualBasic