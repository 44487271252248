import { Accordion, Card, Form } from "react-bootstrap";

import React from "react";

export const VerNotificacion = ({ notificacion }) => {
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form className="text-left">
              <Form.Group>
                <Form.Label>Nombre de la notificación</Form.Label>

                <Form.Control value={notificacion.titulo} disabled />
              </Form.Group>

              <Form.Group>
                <Form.Label>Descripción</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={3}
                  value={notificacion.descripcion}
                  disabled
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
