import CustomCheckbox from "@components/general/CustomCheckbox/CustomCheckbox";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { emailValidator } from "@helpers/validaciones/validaciones";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FileUpload } from "primereact/fileupload";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Container, FloatingLabel, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";

const usosCFDI = {
	fisica: [
		{
			clave: "D01",
			value: "Honorarios médicos, dentales y gastos hospitalarios",
		},
		{
			clave: "D02",
			value: "Gastos médicos por incapacidad o discapacidad",
		},
		{
			clave: "D03",
			value: "Gastos funerales",
		},
		{
			clave: "D04",
			value: "Donativos",
		},
		{
			clave: "D05",
			value: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
		},
		{
			clave: "D06",
			value: "Aportaciones voluntarias al SAR",
		},
		{
			clave: "D07",
			value: "Primas por seguros de gastos médicos",
		},
		{
			clave: "D08",
			value: "Gasos de transportación escolar obligatoria",
		},
		{
			clave: "D09",
			value: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
		},
		{
			clave: "D10",
			value: "Pagos por servicios educativos (colegiaturas)",
		},
	],
	moral: [
		{
			clave: "P01",
			value: "Por definir",
		},
		{
			clave: "G01",
			value: "Adquisición de mercancías",
		},
		{
			clave: "G02",
			value: "Devoluciones, descuentos o bonificaciones",
		},
		{
			clave: "G03",
			value: "Gastos en general",
		},
		{
			clave: "I01",
			value: "Construcciones",
		},
		{
			clave: "I02",
			value: "Mobiliario y equipo de oficina por inversiones",
		},
		{
			clave: "I03",
			value: "Equipo de transporte",
		},
		{
			clave: "I04",
			value: "Equipo de cómputo y accesorios",
		},
		{
			clave: "I05",
			value: "Dados, troqueles, moldes, matrices y herramental",
		},
		{
			clave: "I06",
			value: "Comunicaciones telefónicas",
		},
		{
			clave: "I07",
			value: "Comunicaciones satelitales",
		},
		{
			clave: "I08",
			value: "Otra maquinaria y equipo",
		},
	],
};

const DatosFacturacion = ({ showExtraForm = false, formValues, setFormValues }) => {
	const usosCfdiInitialState = [{ clave: "", value: "Selecciona el tipo de persona -" }];

	const [facturacionSolicitada, setFacturacionSolicitada] = useState(false);

	const [constanciaCargada, setConstanciaCargada] = useState(false);

	const [usosCfdi, setUsosCfdi] = useState(usosCfdiInitialState);

	const [pdfCSF, setPdfCSF] = useState({
		loaded: false,
		data: "",
	});

	const [pdfData, setPdfData] = useState("");

	const [tipoPersona, setTipoPersona] = useState("");

	const [checked, setChecked] = useState(false);

	const [ emailValido, setEmailValido ] = useState(false);

	const handleCheckboxChange = (e) => {
		setChecked(!checked);

		setFacturacionSolicitada(!facturacionSolicitada);

		setUsosCfdi(usosCfdiInitialState);
	};

	const handleRadioButtonChange = ({ target }) => {
		setTipoPersona(target.value);
	};

	const handleSelectChange = (e) => {
		const selectedIndex = e.nativeEvent.target.selectedIndex;

		const usoCfdi = e.nativeEvent.target[selectedIndex].text.split("-")[1].trim();

		setFormValues({
			...formValues,
			usoCfdi,
			claveCfdi: e.target.value,
		});
	};

	const onFileUpload = async ({ files }) => {
		const fileReader = new FileReader();

		const selectedFile = files;

		if (selectedFile.length > 0) {
			let fileToLoad = selectedFile[0];

			fileReader.onload = function (fileLoaded) {
				const fileBase64 = fileLoaded.target.result;

				setPdfCSF({
					loaded: true,
					data: fileBase64,
				});
			};

			fileReader.readAsDataURL(fileToLoad);
		}
	};

	const handleOnChange = ({ target }) => {
		setFormValues({
			...formValues,
			[target.name]: target.value
		});
	};

	useEffect(() => {
		if (constanciaCargada) {
			setConstanciaCargada(false);
		}

		setFormValues({
			...formValues,
			facturacionSolicitada,
		});
	}, [facturacionSolicitada]);

	useEffect(() => {
		if (constanciaCargada) {
			setFormValues({
				...formValues,
				constanciaSituacionFiscalData: pdfData,
			});
		} else {
			setFormValues({
				...formValues,
				facturacionSolicitada,
				usoCfdi: "",
				claveCfdi: "",
				constanciaSituacionFiscalData: "",
			});

			setPdfData("");
		}
	}, [constanciaCargada]);

	useEffect(() => {
		setConstanciaCargada(!constanciaCargada);
	}, [pdfData]);

	useEffect(() => {
		if (tipoPersona !== "") {
			setUsosCfdi([
				{
					clave: "",
					value: "Elige un uso de CFDI",
				},
				...usosCFDI[tipoPersona],
			]);
		}
	}, [tipoPersona]);

	useEffect(() => {
		const valido = emailValidator(formValues.correo);

		setEmailValido(valido);
	}, [ formValues.correo ]);

	useEffect(() => {
		if (pdfCSF.loaded) {
			setPdfData(pdfCSF.data);
		}
	}, [pdfCSF]);

	return (
		<>
			<Row className="px-1 pt-2">
				<Col>
					<Form.Group>
						<CustomCheckbox id="cboDatosFacturacion" name="facturacionSolicitada" title="Deseo facturar mi compra" onChange={handleCheckboxChange} checked={checked} />
					</Form.Group>
				</Col>
			</Row>

			{facturacionSolicitada && showExtraForm ? (
				<Row className="mt-3">
					<Col>
						<FloatingLabel label="Nombre" className="mb-3">
							<Form.Control type="text" placeholder="Nombre"  value={ capitalizeString(formValues.nombreCompleto)  } onChange={handleOnChange} name='nombreCompleto' />
						</FloatingLabel>
					</Col>

					<Col>
						<FloatingLabel label="Apellidos" className="mb-3">
							<Form.Control type="text" placeholder="Apellidos"  value={ capitalizeString(formValues.apellidos)  } onChange={handleOnChange} name='apellidos' />
						</FloatingLabel>
					</Col>

					<Col>
						<FloatingLabel label="Correo electrónico" className="mb-3">
							<Form.Control className={emailValido ? 'text-success' : 'text-danger'} type="email" placeholder="Correo electrónico"  value={formValues.correo} onChange={handleOnChange} name='correo' />
						</FloatingLabel>
					</Col>
				</Row>
			) : null}

			{facturacionSolicitada ? (
				<Row className="mt-3">
					<Col>
						<Container fluid className="p-1">
							<Row>
								<Col className={showExtraForm ? 'd-flex' : ''}>
									<FormControl>
										<FormLabel className="text-white">Elige el tipo de persona</FormLabel>

										<RadioGroup row={!showExtraForm} onChange={handleRadioButtonChange} value={tipoPersona} className="text-white">
											<FormControlLabel value="fisica" control={<Radio />} label="Persona Física" />

											<FormControlLabel value="moral" control={<Radio />} label="Persona Moral" />
										</RadioGroup>
									</FormControl>
								</Col>
							</Row>

							<Row className="mt-3">
								<Col>
									<FloatingLabel label="Uso de CFDI (Comprobante Fiscal Digital por Internet)">
										<Form.Select aria-label="Uso de CFDI (Comprobante Fiscal Digital por Internet)" onChange={handleSelectChange} name="usoCfdi">
											{usosCfdi.map((usoCfdi, idx) => (
												<option key={idx} value={usoCfdi.clave}>
													{`${usoCfdi.clave} - ${usoCfdi.value}`}
												</option>
											))}
										</Form.Select>
									</FloatingLabel>
								</Col>

								<Col>
									<OverlayTrigger
										trigger={["hover", "focus"]}
										placement="bottom"
										overlay={
											<Popover>
												<Popover.Header as="h3">Constancia de Situación Fiscal</Popover.Header>

												<Popover.Body>Carga tu Constacia de Situación Fiscal en formato PDF.</Popover.Body>
											</Popover>
										}>
										<span>
											<FileUpload
												chooseOptions={{ icon: "pi pi-upload", className: "w-100 p-button-success btn-csf" }}
												mode="basic"
												name="constanciaSituacionFiscalData"
												chooseLabel="Constancia de Situación Fiscal"
												accept=".pdf"
												maxFileSize={1000000}
												auto
												customUpload
												uploadHandler={onFileUpload}
											/>

											<Typography className={pdfCSF.loaded ? "text-success" : "text-danger"} variant="overline">
												{pdfCSF.loaded ? "Cargada" : "Falta cargar"}
											</Typography>
										</span>
									</OverlayTrigger>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			) : null}
		</>
	);
};

export default DatosFacturacion;
