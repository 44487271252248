import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import TotalAPagarDescuentoList from "./lists/TotalAPagarDescuentoList";
import TotalAPagarList from "./lists/TotalAPagarList";
import UserContext from "@context/User/UserContext";

const TotalAPagar = (props) => {
	
	const { contextValue, setContextValue } = useContext(UserContext);

	// const { renovacion: { precios } } = contextValue;

	const { codigoAplicado, codigoDescuento, precios } = props;

	// const [precios, setPrecios] = useState({});

	// useEffect(() => {
	// 	console.log('precios: ', props.precios);
		
	// 	if (Object.keys(props.precios).length > 0) {
	// 		setPrecios(props.precios);
	// 	}
	// }, [props.precios]);

	return (
		<Col>
			<h3>Opciones de pago:</h3>

			<Row>
				<TotalAPagarList 
					// contado={precios.contado} 
					// credito={precios.credito} 
					// msi={precios.msi} 
					precios={precios}
					codigoAplicado={codigoAplicado} 
				/>
			</Row>

			{/* {codigoAplicado ? (
				<>
					<h3 className={"descuento-element animate__animated animate__fadeIn"}>Con descuento:</h3>

					<Row className={"descuento-element animate__animated animate__fadeIn"}>
						<TotalAPagarDescuentoList descuentoAplicado={descuentoAplicado} codigoDescuento={codigoDescuento} />
					</Row>
				</>
			) : null} */}
		</Col>
	);
};

export default TotalAPagar;
