import { Notificacion } from "@components/general/Alertas/Alertas";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpFormDataRequest from "@helpers/httpRequests/httpFormDataRequest";
import { addPublicacion } from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import React, { useRef } from "react";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const NuevaPublicacion = ({
  setPublicacionAgregada,
  folioGrupo,
  numeroPublicaciones,
  idGrupo,
}) => {
  const refInputFile = useRef(null);

  const [validated, setValidated] = useState(false);

  const [archivoValido, setArchivoValido] = useState(false);

  const [fileBase64, setFileBase64] = useState(null);

  const [formValues, setFormValues] = useState({
    titulo: "",
    descripcion: "",
    archivoAdjunto: "",
  });

  // const [validacionForm, setValidacionForm] = useState({
  // 	titulo: false,
  // 	descripcion: false,
  // 	archivoAdjunto: false,
  // });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    // if (value === "") {
    // 	setValidacionForm({
    // 		...validacionForm,
    // 		[name]: !validacionForm.name,
    // 	});
    // } else {
    // 	setValidacionForm({
    // 		...validacionForm,
    // 		[name]: false,
    // 	});
    // }
  };

  const handleOnChangeFile = (event) => {
    const { name, value } = event.target;
    // const reader = new FileReader();
    // reader.onload = function() {

    //     setBinaryData(reader.result);
    // };
    // reader.readAsArrayBuffer(event.target.files[0]);

    let file = event.target.files[0];

    if (regExpStrings.regexCaracteresUTF8(file.name)) {
      console.log("tiene utf-8");

      refInputFile.current.value = "";

      refInputFile.current.previousElementSibling.innerHTML =
        "Seleccionar archivo:";

      setArchivoValido(false);

      return NotificationManager.error("Nombre de archivo inválido");
    }

    setArchivoValido(true);

    setFormValues({
      ...formValues,
      [name]: file,
    });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileBase64(reader.result.split(",")[1]);
    };
  };

  const publicar = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);
    try {
      const { titulo, descripcion, archivoAdjunto } = formValues;

      // console.log(archivoAdjunto);

      // if (titulo === "") {
      // 	console.log("TITULO");
      // 	setValidacionForm({
      // 		...validacionForm,
      // 		titulo: true,
      // 	});
      // } else if (descripcion === "") {
      // 	console.log("DESCRIPCION");
      // 	setValidacionForm({
      // 		...validacionForm,
      // 		descripcion: true,
      // 	});
      // }

      if (archivoAdjunto !== "") {
        //FETCH PARA MANDAR LOS DATOS DEL ARCHIVO AL SERVIDOR
        const formData = new FormData();

        formData.append("archivoAdjunto", archivoAdjunto);

        const { code, message: fetchMessage } = await httpFormDataRequest({
          url: `${endpointsPaths.foroEmpresarial}/${
            httpRequestsPaths.foroEmpresarial.manejoArchivos
              .addArchivoNuevaPublicacion
          }?folioGrupo=${folioGrupo}&numeroPublicacion=${
            numeroPublicaciones + 1
          }`,
          body: formData,
        });

        // const response = await fetch(`http://localhost:3002/api/foro-empresarial/add-archivo-nueva-publicacion?folioGrupo=${folioGrupo}&numeroPublicacion=${numeroPublicaciones + 1}`, {
        // 	method: "POST",
        // 	body: formData,
        // });

        // const { data, code, message: fetchMessage } = await response.json();
        // console.log(data);

        if (code !== 200) {
          Notificacion("error", fetchMessage);
        } else {
          if (titulo !== "" && descripcion !== "") {
            const publicacion = {
              titulo,
              descripcion,
              archivoAdjunto: archivoAdjunto.name,
              fechaRegistro: getUnixTimestamp(),
              pesoArchivo: archivoAdjunto.size,
              idGrupo: idGrupo,
            };

            const { message } = await addPublicacion(publicacion);

            setPublicacionAgregada(true);

            Notificacion("success", message);
          }
        }
      }
    } catch (error) {
      console.log(error);
      console.log("Ocurrio un error");
    }
  };

  return (
    <Container>
      <Row>
        <Col className="text-start">
          <Form noValidate validated={validated} onSubmit={publicar}>
            <Form.Group>
              <Form.Label>Titulo de la publicación</Form.Label>
              <Form.Control
                required
                // className={validacionForm.titulo ? "is-invalid" : ""}
                onChange={handleOnChange}
                name="titulo"
                value={formValues.titulo}
                type="text"
                placeholder="Titulo"
              ></Form.Control>

              <Form.Control.Feedback type="invalid">
                Este campo no puede ir vacío
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Control
                required
                // className={validacionForm.descripcion ? "is-invalid" : ""}
                onChange={handleOnChange}
                name="descripcion"
                value={formValues.descripcion}
                as={"textarea"}
                placeholder={"Describe tu publicación"}
              ></Form.Control>

              <Form.Control.Feedback type="invalid">
                Este campo no puede ir vacío
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Adjuntar archivo</Form.Label>

              {/* //* VALIDAR DEL LADO DEL SERVIDOR QUE LOS ARCHIVOS SEAN DE PAQUETERIA OFFICE O PDF */}
              <Form.Control
                ref={refInputFile}
                required
                onChange={handleOnChangeFile}
                name="archivoAdjunto"
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                type="file"
              />

              {/* {validacionForm.archivoAdjunto && <p className="text-danger mt-3">Es necesario adjuntar un archivo a la publicación</p>} */}

              <Alert
                variant="danger"
                className={`mt-3 ${!archivoValido ? "" : "d-none"}`}
              >
                Asegúrate que el nombre de tu plantilla no contenga tildes ni
                caracteres especiales
              </Alert>

              <Form.Control.Feedback type="invalid">
                <p>Este campo es obligatorio</p>
              </Form.Control.Feedback>
            </Form.Group>

            <Button block type="submit">
              Agregar publicación
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NuevaPublicacion;
