import React from "react";

import cursoWord from "@assets/images/portadas/WO16.png";
import cursoExcel from "@assets/images/portadas/EX21.png";
import cursoPowerBI from "@assets/images/portadas/PB21.png";
// import cursoAccess from "@assets/images/portadas/cursoAccess.png";

import { Col, Container, Row, Card, Button } from "react-bootstrap";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "@assets/css/components/Home/style.css";

// import required modules
import { Pagination } from "swiper";
import TitleDashboard from "@components/general/Titles/TitleDashboard";

const HomeDefaultSlider = () => {
  return (
    <Container fluid>
      <Row className="my-5">
        <Col>
          <Col>
            <Col>
              <TitleDashboard title="Más visitados" />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row style={{ marginBottom: "25px" }}>
        <Col>
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={cursoExcel} />
                <Card.Body>
                  <Card.Title>Excel</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  <Button variant="warning">Agregar a mis rutas</Button>
                </Card.Body>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={cursoWord} />
                <Card.Body>
                  <Card.Title>Word</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  <Button variant="warning">Agregar a mis rutas</Button>
                </Card.Body>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={cursoWord} />
                <Card.Body>
                  <Card.Title>Programación</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  <Button variant="warning">Agregar a mis rutas</Button>
                </Card.Body>
              </Card>
            </SwiperSlide>

            <SwiperSlide>
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={cursoPowerBI} />
                <Card.Body>
                  <Card.Title>Gráficos</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  <Button variant="warning">Agregar a mis rutas</Button>
                </Card.Body>
              </Card>
            </SwiperSlide>

            {/* <SwiperSlide>
							<Card style={{ width: "18rem" }}>
								<Card.Img variant="top" src={cursoAccess} />
								<Card.Body>
									<Card.Title>Usando Teams</Card.Title>
									<Card.Text>
										Some quick example text to build on the card title and make
										up the bulk of the card's content.
									</Card.Text>
									<Button variant="warning">Agregar a mis rutas</Button>
								</Card.Body>
							</Card>
						</SwiperSlide> */}

            <SwiperSlide>
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={cursoExcel} />
                <Card.Body>
                  <Card.Title>Tablas dinámicas</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  <Button variant="warning">
                    {/* <BsPlusCircleFill /> */}
                    Agregar a mis rutas
                  </Button>
                </Card.Body>
              </Card>
            </SwiperSlide>
          </Swiper>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeDefaultSlider;
