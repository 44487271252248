import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getSecciones = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: secciones } = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.getSecciones}`,
        method: "GET",
      });

      resolve(secciones);
    } catch (error) {
      // console.log("Error al obtener las secciones: ", error);

      reject(error);
    }
  });
};

export const getSeccionesPapelera = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: secciones } = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.getSeccionesPapelera}`,
        method: "GET",
      });

      resolve(secciones);
    } catch (error) {
      // console.log("Error al obtener las secciones: ", error);

      reject(error);
    }
  });
};

export const getSeccionByCodigo = (codigoSeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const seccion = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.getSeccionByCodigo}/${codigoSeccion}`,
        method: "POST",
      });

      resolve(seccion);
    } catch (error) {
      // console.log("Error al obtener la sección: ", error);

      reject(error);
    }
  });
};

//Todo
export const getSeccionesByCodigoCurso = (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const secciones = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.getSeccionesByCodigoCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(secciones);
    } catch (error) {
      // console.log("Error al obtener las secciones del curso: ", error);

      reject(error);
    }
  });
};

export const addSeccion = (seccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const seccionAgregada = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.addSeccion}`,
        method: "POST",
        body: JSON.stringify(seccion),
      });

      resolve(seccionAgregada);
    } catch (error) {
      // console.log("Erro al agregar la sección: ", error);

      reject(error);
    }
  });
};

export const updateSeccion = ({ nombre, codigoSeccion }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const seccionActualizada = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.updateSeccion}/${codigoSeccion}`,
        method: "POST",
        body: JSON.stringify({ nombre }),
      });

      resolve(seccionActualizada);
    } catch (error) {
      // console.log("Error al actualizar la sección: ", error);

      reject(error);
    }
  });
};

export const updateReorderSecciones = (secciones) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedReorderSecciones = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.updateReorderSecciones}`,
        method: "POST",
        body: JSON.stringify({ secciones }),
      });

      resolve(updatedReorderSecciones);
    } catch (error) {
      // console.log("Error al actualizar el reorden de las secciones: ", error);

      reject(error);
    }
  });
};

export const reactivateSeccionByCodigo = (codigoSeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const seccionReactivada = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.reactivateSeccion}/${codigoSeccion}`,
        method: "POST",
      });

      resolve(seccionReactivada);
    } catch (error) {
      // console.log("Error al reactivar la sección: ", error);

      reject(error);
    }
  });
};

export const deactivateSeccionByCodigo = (codigoSeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const seccionDesactivada = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.deactivateSeccion}/${codigoSeccion}`,
        method: "POST",
      });

      resolve(seccionDesactivada);
    } catch (error) {
      // console.log("Error al desactivar la sección: ", error);

      reject(error);
    }
  });
};

export const deleteSeccionByCodigo = (codigoSeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const seccionDesactivada = await httpRequest({
        url: `${endpointsPaths.secciones}/${httpRequestsPaths.secciones.deleteSeccion}/${codigoSeccion}`,
        method: "POST",
      });

      resolve(seccionDesactivada);
    } catch (error) {
      // console.log("Error al desactivar la sección: ", error);

      reject(error);
    }
  });
};
