import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateSeccionByCodigo } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";

export const EliminarSeccion = ({ seccion, setSeccionEliminada }) => {
  const handleEliminarSeccion = async () => {
    try {
      await deactivateSeccionByCodigo(seccion.codigoSeccion);

      setSeccionEliminada(true);

      Notificacion("success", "Sección eliminada correctamente");
    } catch (e) {
      console.log("handleDesactivateSection", e);
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "La sección no fue eliminada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro que deseas eliminar esta sección?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleEliminarSeccion}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
