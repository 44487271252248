import timeDifference from "./getTimeDifference";

const getTimeInMinutes = (fecha1, fecha2) => {
	let { hoursDifference, minutesDifference, secondsDifference } = timeDifference(new Date(fecha2 * 1000), new Date(fecha1 * 1000));

	if (hoursDifference === 0) {
		hoursDifference = "00";
	} else if (hoursDifference > 0 && hoursDifference <= 9) {
		hoursDifference = `0${hoursDifference}`;
	}

	if (minutesDifference === 0) {
		minutesDifference = "00";
	} else if (minutesDifference > 0 && minutesDifference <= 9) {
		minutesDifference = `0${minutesDifference}`;
	}

	if (secondsDifference === 0) {
		secondsDifference = "00";
	} else if (secondsDifference > 0 && secondsDifference <= 9) {
		secondsDifference = `0${secondsDifference}`;
	}

	return `${hoursDifference}:${minutesDifference}:${secondsDifference}`;
};

export default getTimeInMinutes;