import { Col, Container, Form, Image, Row } from "react-bootstrap";

import React from "react";

export const VerGrupo = ({ grupo }) => {
	return (
		<Form className="text-start">
			<Container fluid>
				<Row>
					<Form.Group as={Col}>
						<Form.Label>Folio de contrato: {grupo.folioGrupo}</Form.Label>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Nombre del grupo: {grupo.nombreEmpresa === "" ? "Sin nombre asignado" : grupo.nombreEmpresa}</Form.Label>
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<Form.Label>Nombre del asesor asignado: {grupo.nombreAsesor}</Form.Label>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Nombre de la empresa: {grupo.nombreEmpresa === "" ? "Sin nombre asignado" : grupo.nombreEmpresa}</Form.Label>
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<Form.Label>Color empresarial: {grupo.colorGrupo === "" && <b>Sin color asignado</b>}</Form.Label>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Logotipo actual</Form.Label>
						<br />
						{grupo.logotipoGrupo !== "" ? (
							<Image src={grupo.logotipo} alt="Logotipo del grupo" />
						) : (
							<span>
								<b>Sin logotipo</b>
							</span>
						)}
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<Form.Label>Almacenamiento Ocupado: {`${grupo.almacenamientoOcupado}/250 mb usados`}</Form.Label>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Cantidad de empleados: {`${grupo.cantidadEstudiantesGrupo - grupo.estudiantesRestantes}/${grupo.cantidadEstudiantesGrupo} registrados`}</Form.Label>
					</Form.Group>
				</Row>
			</Container>
		</Form>
	);
};
