import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import SubtitleForoAyuda from "@components/general/Titles/SubtitleForoAyuda";
import {
  faArrowLeft,
  faCommentAlt,
  faEllipsisV,
  faHeart,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import { getImagenPerfil } from "@helpers/httpRequests/httpRequestsPerfiles/httpRequestsPerfiles";
import Loader from "@helpers/loader/loader";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import ReportarComentario from "../Modales/ReportarComentario";
import PaginationSections from "./PaginationSections";
import { getRespuestasTemaByIdTema } from "@helpers/httpRequests/httpRequestsRespuestasTema/httpRequestsRespuestasTema";

const VistaInfoTema = ({
  rol,
  reset,
  showTema,
  numeroLikes,
  temas,
  handleLike,
  like,
  handleNuevoComentario,
  nuevoComentario,
  comentarioEliminado,
  formValues,
  handleOnChange,
  hacerNuevoComentario,
  handleEliminarComentario,
  handleEditarComentario,
  handleEditorChange,
  aliasLogeado,
  comentarioEditado
}) => {
  const [imagenPerfil, setImagenPerfil] = useState({});

  const [showLoader, setShowLoader] = useState(true);

  const [respuestas, setRespuestas] = useState([]);

  //Paginacion comentarios por tema

  const [currentPage, setCurrentPage] = useState(1);

  const [sectionsPerPage, setSectionsPerPage] = useState(4);

  const indexOfLastSection = currentPage * sectionsPerPage;

  const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

  const currentPaginationAnswers = respuestas.slice(
    indexOfFirstSection,
    indexOfLastSection
  );

  const numberPages = Math.ceil(respuestas.length / sectionsPerPage);

  //End paginacion de temas por categoria

  const { nombreUsuario, alias, idPerfil } = showTema;

  // console.log("alias y nombre usuario: ", {alias, nombreUsuario});

  // console.log(currentPaginationAnswers, alias);

  const reportarComentario = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "¿Por qué quieres reportar este comentario?",
      icon: "error",
      html: (
        <ReportarComentario comentario={currentPaginationAnswers[rowIndex]} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  useEffect(() => {
    const consultarRespuesta = async () => {
      try {
        const { data } = await getRespuestasTemaByIdTema(showTema.idTema);

        if (Array.isArray(data)) {
          setRespuestas(data);
        } else {
          setRespuestas([data]);
        }
      } catch (error) {
        console.log("Error al consultar las respuestas de los temas: ", error);
      }
    };

    consultarRespuesta();
    const obtenerImagenPerfil = async () => {
      try {
        const { data } = await getImagenPerfil({
          alias,
          idPerfil
        });
        const { avatarPerfil } = data.data;
        setImagenPerfil({
          avatarPerfil
        });
        setShowLoader(false);
      } catch (error) {
        console.log(error);
        setImagenPerfil({ avatarPerfil: null });
        setShowLoader(false);
      }
    };

    obtenerImagenPerfil();
  }, []);

  useEffect(() => {
    const consultarRespuesta = async () => {
      try {
        const { data } = await getRespuestasTemaByIdTema(showTema.idTema);

        if (Array.isArray(data)) {
          setRespuestas(data);
        } else {
          setRespuestas([data]);
        }
      } catch (error) {
        setRespuestas([]);
        console.log("Error al consultar las respuestas de los temas: ", error);
      }
    };

    consultarRespuesta();
  }, [nuevoComentario.comentarioInsertado, comentarioEliminado, comentarioEditado]);

  return (
    <Row className="mb-5">
      <Col className="col-12 mb-4 mt-4">
        <button
          className="btn btn-primary btn-sm btn-responsive"
          onClick={reset}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Regresar
        </button>
      </Col>

      {/* //*COLUMNA PARA MOSTRAR LA INFORMACION DEL TEMA SELECCIONADO */}

      {temas.map(
        (tema, key) =>
          tema.idTema === showTema.idTema && (
            <Col key={key} className="col-12 mt-4 mt-lg-0 col-lg-8 p-3">
              <Row style={{ height: "220px" }}>
                <Col className="text-capitalize">
                  <SubtitleForoAyuda title={tema.titulo} />
                  <p className="mt-3">

                    <pre style={{ whiteSpace: "pre-line" }} >
                      <b><p style={{ height: 'auto', maxHeight: "100px", overflowY: 'scroll' }}>{tema.textoPublicacion.replace(/\\'/g, "'").replace(/\\"/g, '"')}</p></b>
                    </pre>

                  </p>
                  <small>Creado el {getFechaSinHora(tema.fechaCreacion)}</small>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-start">
                  <button
                    onClick={handleLike}
                    className="btn btn-sm mx-2 btn-like  btn-responsive"
                  >
                    <FontAwesomeIcon
                      color={like ? "red" : "white"}
                      icon={faHeart}
                    />{" "}
                    Me gusta
                  </button>
                  <button
                    name={tema.alias}
                    id={tema.idTema}
                    onClick={handleNuevoComentario}
                    className="btn btn-primary btn-sm btn-responsive"
                  >
                    Comentar <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                </Col>
              </Row>
            </Col>
          )
      )}

      {/* //* COLUMNA PARA MOSTRAR LOS DATOS DE QUIEN INICIO EL TEMA */}

      <Col className="col-12 col-lg-4 mt-3 ">
        <Card className="card-post-foro-ayuda">
          <h5 className="p-2 text-center">Iniciado por</h5>
          {showLoader ? (
            <Loader height={"10vh"} setShowLoader={setShowLoader} />
          ) : (
            <div className="text-center">
              <img
                src={
                  imagenPerfil.avatarPerfil !== null
                    ? imagenPerfil.avatarPerfil
                    : require("@assets/images/avatar.png")
                }
                alt="avatar.png"
                className=" rounded-circle"
                style={{ margin: "auto", width: "70px", height: "70px" }}
              />

              {/* <img
                src={require("@assets/images/avatar.png")}
                alt="avatar.png"
                className=" rounded-circle"
                style={{ margin: "auto", width: "70px", height: "70px" }}
              /> */}
            </div>
          )}

          <Card.Body>
            <Card.Title
              style={{ fontSize: "14px" }}
              className="mb-3 text-center"
            >
              <h6>{showTema.alias}</h6>
            </Card.Title>
            <div className="d-flex justify-content-around mt-4">
              <span>
                <h6>
                  <FontAwesomeIcon icon={faCommentAlt} />{" "}
                  {
                    respuestas.filter(
                      (respuesta) => respuesta.idTema === showTema.idTema
                    ).length
                  }
                </h6>
              </span>

              <span>
                <h6>
                  <FontAwesomeIcon color={"darkRed"} icon={faHeart} />{" "}
                  {numeroLikes(showTema.idTema)}
                </h6>
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>



      {/* //* MAPEO PARA MOSTRAR LOS COMENTARIOS HECHOS EN EL TEMA */}

      {currentPaginationAnswers.map(
        (respuesta, key) =>
          respuesta.idTema === showTema.idTema && (
            <>


              <Col key={key} className="col-12 col-lg-8 p-3 mt-4">
                <Row className="row-foro" style={{ height: "auto" }}>
                  <Col>
                    <h6>@{respuesta.respuestasUsuario}</h6>
                  </Col>
                  <Col className="col-2 text-center">
                    <DropdownButton
                      style={{ fontSize: "10px" }}
                      className="text-end menu-ruta"
                      id={`dropdown-button-drop-left`}
                      drop={"left"}
                      variant="transparent"
                      title={
                        <FontAwesomeIcon
                          className="pointer ellipsis"
                          icon={faEllipsisV}
                        />
                      }
                    >
                      {(rol === "administrador" ||
                        respuesta.respuestasUsuario === aliasLogeado) && (
                          <h5>
                            <Dropdown.Item
                              onClick={handleEliminarComentario}
                              id={respuesta.idRespuestaTema}
                              eventKey="1"
                            >
                              Eliminar
                            </Dropdown.Item>
                          </h5>
                        )}

                      {(rol === "administrador" ||
                        respuesta.respuestasUsuario === aliasLogeado) && (
                          <h5>
                            <Dropdown.Item
                              onClick={(e) => handleEditarComentario(respuesta)}
                              id={respuesta.idRespuestaTema}
                              eventKey="1"
                            >
                              Editar
                            </Dropdown.Item>
                          </h5>
                        )}


                      {respuesta.respuestasUsuario !== aliasLogeado && (
                        <h5>
                          <Dropdown.Item
                            onClick={reportarComentario}
                            id={key}
                            eventKey="1"
                          >
                            Reportar comentario
                          </Dropdown.Item>
                        </h5>
                      )}
                    </DropdownButton>
                  </Col>

                  <Col className="text-capitalize col-12">
                    <small>{getFechaSinHora(respuesta.fecha)}</small>


                    <p className="mt-3">
                      <p><b>@{respuesta.alias}</b></p>   <pre style={{ whiteSpace: "pre-line" }} >
                        <b><p>{respuesta.respuesta.replace(/^"|"$/g, '')}</p></b>
                      </pre>
                    </p>

                    <Image src={respuesta.imagenComentario} fluid />

                  </Col>
                </Row>

                <Row>
                  <Col>
                    <button
                      name={respuesta.respuestasUsuario}
                      id={respuesta.idTema}
                      onClick={handleNuevoComentario}
                      className="btn btn-primary btn-sm btn-responsive"
                    >
                      Comentar <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12 col-lg-4"></Col>
            </>
          )
      )}

      {/* {nuevoComentario.show && (
        <>
          <Col className="col-lg-4"></Col>
          <Col className="col-12 col-lg-8 mt-4">
            <h6>Añade un comentario</h6>
            <Form>
              <Form.Group>
                <Form.Control
                  className="input-form"
                  name="respuesta"
                  value={formValues.respuesta}
                  onChange={handleOnChange}
                  as="textarea"
                  rows={5}
                  placeholder="Escribe un comentario"
                ></Form.Control>
              </Form.Group>


              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="btn-responsive"
                  onClick={hacerNuevoComentario}
                >
                  Responder a {nuevoComentario.alias}
                </Button>
              </div>
            </Form>
          </Col>
        </>
      )} */}

      {/* //*PAGINACION UNICAMENTE PARA LOS COMENTARIOS DEL TEMA */}


      <Col className="col-8 mt-3">
        {currentPaginationAnswers.length > 0 && (
          <PaginationSections
            numberPages={numberPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </Col>

      <Col className="col-4"></Col>
    </Row>
  );
};

export default VistaInfoTema;
