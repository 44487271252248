import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import UserContext from "@context/User/UserContext";
import {
  faEdit,
  faEye,
  faPlus,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRutasAprendizaje } from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import columns from "./Datatables/columns";
import AgregarRuta from "./Modales/AgregarRuta";
import DesactivarRuta from "./Modales/DesactivarRuta";
import ModificarRuta from "./Modales/ModificarRuta";
import ReactivarRuta from "./Modales/ReactivarRuta";
import VerInfoRuta from "./Modales/VerInfoRuta";

import "@assets/css/components/DashboardRutasAprendizaje/style.css";

export const DashboardRutasAprendizaje = () => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const { idUsuario } = usuarioLogeado;

  const [rutas, setRutas] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [rutaAprendizajeAgregada, setRutaAprendizajeAgregada] = useState(false);

  const [rutaAprendizajeModificada, setRutaAprendizajeModificada] =
    useState(false);

  const [rutaAprendizajeDesactivada, setRutaAprendizajeDesactivada] =
    useState(false);

  const [rutaAprendizajeReactivada, setRutaAprendizajeReactivada] =
    useState(false);

  //Agregar ruta

  const handleAgregarRuta = () => {
    ModalSweetAlert({
      title: "Nueva ruta de aprendizaje",
      html: (
        <AgregarRuta
          setRutaAprendizajeAgregada={setRutaAprendizajeAgregada}
          idUsuario={idUsuario}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  useEffect(() => {
    const getRutas = async () => {
      try {
        const { data } = await getRutasAprendizaje();
        if (Array.isArray(data)) {
          setRutas(data);
        } else {
          setRutas([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getRutas();
  }, []);

  useEffect(() => {
    if (rutas.length > 0) {
      const handleVerRuta = ({ currentTarget }) => {
        const rowIndex = currentTarget.id.split("-")[1];
        ModalSweetAlert({
          title: "Información de la ruta",
          html: <VerInfoRuta ruta={rutas[rowIndex]} />,
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleModificarRuta = ({ currentTarget }) => {
        const rowIndex = currentTarget.id.split("-")[1];
        ModalSweetAlert({
          title: "Modificar Ruta",
          html: (
            <ModificarRuta
              setRutaAprendizajeModificada={setRutaAprendizajeModificada}
              ruta={rutas[rowIndex]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleDeactivateRuta = ({ currentTarget }) => {
        const rowIndex = currentTarget.id.split("-")[1];
        ModalSweetAlert({
          title: "¿Desactivar Ruta?",
          width: "500px",
          icon: "question",
          html: (
            <DesactivarRuta
              setRutaAprendizajeDesactivada={setRutaAprendizajeDesactivada}
              ruta={rutas[rowIndex]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      const handleReactivateRuta = ({ currentTarget }) => {
        const rowIndex = currentTarget.id.split("-")[1];
        ModalSweetAlert({
          title: "¿Reactivar ruta?",
          width: "500px",
          icon: "question",
          html: (
            <ReactivarRuta
              setRutaAprendizajeReactivada={setRutaAprendizajeReactivada}
              ruta={rutas[rowIndex]}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
        });
      };

      setDataTableColumns([
        ...columns,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`edit-${row.idRutaAprendizajeDefault - 1}`}
                onClick={handleVerRuta}
                className="me-2 pointer"
              >
                <FontAwesomeIcon icon={faEye} color="grey" />
              </span>

              <span
                id={`edit-${row.idRutaAprendizajeDefault - 1}`}
                className="me-1 pointer"
                onClick={handleModificarRuta}
              >
                <FontAwesomeIcon icon={faEdit} color="#007bff" />
              </span>

              {/* Validar con row.estatus para mostrar el trash o el off al cambiar el estatus */}

              {row.estatus === 1 ? (
                <span
                  id={`delete-${row.idRutaAprendizajeDefault - 1}`}
                  onClick={handleDeactivateRuta}
                  className="ms-1 pointer"
                >
                  <FontAwesomeIcon icon={faTrashAlt} color="red" />
                </span>
              ) : (
                <span
                  id={`delete-${row.idRutaAprendizajeDefault - 1}`}
                  onClick={handleReactivateRuta}
                  className="ms-1 pointer"
                >
                  <FontAwesomeIcon icon={faPowerOff} color="green" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]);
    }
  }, [rutas]);

  useEffect(() => {
    if (
      rutaAprendizajeAgregada ||
      rutaAprendizajeModificada ||
      rutaAprendizajeDesactivada ||
      rutaAprendizajeReactivada
    ) {
      const refreshRutas = async () => {
        try {
          const { data } = await getRutasAprendizaje();
          if (Array.isArray(data)) {
            setRutas(data);
          } else {
            setRutas([data]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      refreshRutas();

      setRutaAprendizajeAgregada(false);
      setRutaAprendizajeModificada(false);
      setRutaAprendizajeDesactivada(false);
      setRutaAprendizajeReactivada(false);
    }
  }, [
    rutaAprendizajeAgregada,
    rutaAprendizajeModificada,
    rutaAprendizajeDesactivada,
    rutaAprendizajeReactivada,
  ]);

  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <Button type="button" onClick={handleAgregarRuta}>
            Nueva ruta de aprendizaje <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>

      <Row className="my-3">
        <Col>
          <DataTablesRecipient columns={dataTableColumns} data={rutas} />
        </Col>
      </Row>
    </>
  );
};
