const columns = [
  {
    name: "#",
    selector: (row) => row.idConsejoRapido,
    sortable: true,
    center: true,
    compact: true,
    width: "40px",
  },
  {
    name: "Titulo",
    selector: (row) => row.titulo,
    sortable: true,
    center: true,
    compact: true,
    width: "360px",
  },
  {
    name: "Descripción",
    selector: (row) => row.descripcion,
    sortable: true,
    center: true,
    compact: true,
    width: "350px",
  },
  {
    name: "Estatus",
    selector: (row) => (row.estatus === 1 ? "Activo" : "Desactivado"),
    sortable: true,
    center: true,
    compact: true,
    width: "100px",
  },
  {
    name: "Codigo Curso",
    selector: (row) => row.codigoCurso,
    sortable: true,
    center: true,
    compact: true,
    width: "200px",
  },
];

export default columns;
