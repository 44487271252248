import styled from "styled-components";

// export const ButtonStyled = styled.button`
//   overflow: hidden;
//   outline: none;
//   position: relative;
//   cursor: pointer;
//   top: 200px;
//   background: linear-gradient(
//     90deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(0, 212, 255, 1) 100%
//   );
//   padding: 10px 30px;
//   color: #fff;
//   font-size: 20px;
//   border-radius: 5px;
//   border: 1px solid #fff;
//   text-align: center;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
//   width: 50%;
//   height: 5rem;
// `;

export const ButtonStyled = styled.button`
    border-radius: 100%;
    width: 250px;
    height: 250px;
    border-color: #fd5900;
    overflow: hidden;
    outline: none;
    position: relative;
`;
