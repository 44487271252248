import TitleDashboard from "@components/general/Titles/TitleDashboard";
import {
  getLeccionesByUnidad,
  getUnidadesCurso,
} from "@helpers/httpRequests/httpRequestCursoIngles/httpRequestCursoIngles";
import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

const VerAvancesIngles = ({ codigoCursoSeleccionado, correo }) => {
  const [unidadesIngles, setUnidadesIngles] = useState([]);

  const [leccionesIngles, setLeccionesIngles] = useState([]);

  const moduloSeleccionado = codigoCursoSeleccionado.substring(3);

  const [loader, setLoader] = useState(true);

  const [sinInfo, setSinInfo] = useState(false);

  const [unidadSeleccionada, setUnidadSeleccionada] = useState(1);

  const handleUnidad = async ({ currentTarget }) => {
    setLoader(true);
    //En base al id recuperado se consultaran las lecciones referentes a ese id de la unidad
    const { id } = currentTarget;

    setUnidadSeleccionada(id);

    try {
      const { data } = await getLeccionesByUnidad(id, {
        codigoCurso: codigoCursoSeleccionado,
        modulo: moduloSeleccionado,
        correo: correo,
      });
      console.log(data);
      if (Array.isArray(data)) {
        setLeccionesIngles(data);
        setLoader(false);
      } else {
        setLeccionesIngles([data]);
        setLoader(false);
      }
      setSinInfo(false);
    } catch (error) {
      console.log(error);
      setSinInfo(true);
    }
  };

  useEffect(() => {
    const obtenerUnidadesIngles = async () => {
      try {
        const { data } = await getUnidadesCurso(codigoCursoSeleccionado);
        console.log(data);
        if (Array.isArray(data)) {
          setUnidadesIngles(data);
        } else {
          setUnidadesIngles([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerUnidadesIngles();

    const obtenerLecciones = async () => {
      try {
        const { data } = await getLeccionesByUnidad(1, {
          codigoCurso: codigoCursoSeleccionado,
          modulo: moduloSeleccionado,
          correo: correo,
        });
        console.log(data);
        if (Array.isArray(data)) {
          setLeccionesIngles(data);
        } else {
          setLeccionesIngles([data]);
        }
        setSinInfo(false);
      } catch (error) {
        console.log(error);
        setSinInfo(true);
      }
    };

    obtenerLecciones();

    setLoader(false);
  }, []);

  return (
    <Container fluid style={{ textAlign: "start" }}>
      <Row>
        <Col>
          <TitleDashboard
            title={`Avance del curso | Unidad ${unidadSeleccionada}`}
          />
        </Col>
      </Row>
      <Row className="mt-4 text-center">
        <Col className="col-4">
          {unidadesIngles.map((unidadIngles, idx) => (
            <div className="gap-2 d-grid">
              <Button
              key={idx}
              id={idx + 1}
              block
              onClick={handleUnidad}
              className="mb-3 btn-sm text-start"
            >
              <small>{unidadIngles.nombre}</small>
            </Button>
            </div>
          ))}
        </Col>

        <Col className="contenedor-cursos" style={{ height: "70vh", position: 'relative' }}>
          {(leccionesIngles.length && !loader) > 0 ? (
            <Animated
              animationIn="fadeIn"
              isVisible={true}
              style={{ zIndex: "9999", position: "absolute", width: "100%" }}
            >
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Parte</th>
                    <th>Lección</th>
                    <th>Porcentaje %</th>
                  </tr>
                </thead>
                <tbody>
                  {leccionesIngles.map((leccionIngles, id) => (
                    <tr key={id}>
                      <td>{leccionIngles.parte === 1 ? 1 : 2}</td>
                      <td>
                        <small>{leccionIngles.leccion}</small>
                      </td>
                      <td>
                        {leccionIngles.porcentaje === null
                          ? 0
                          : leccionIngles.porcentaje}{" "}
                        %
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Animated>
          ) : sinInfo ? (
            <p>Sin informacion</p>
          ) : (
            <div style={{ width: "100%", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  zIndex: "200",
                  backgroundColor: "#fff",
                  width: "100%",
                  height: "70vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="spinner"></div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {/* 
      <Row>
        <Col>
          <iframe title='Avances ingles' width="100%" height="100%" src='https://virtual.globalclickmexico.com/campus/apps/progreso/avance_ingles.php?curso=ING1&email=nan.valtierra@gmail.com'></iframe>
        </Col>
      </Row> */}
    </Container>
  );
};

export default VerAvancesIngles;
