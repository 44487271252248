import React from "react";
import examenDiagnostico from "@assets/images/portadas/examenDiagnostico.png";
import rutasAprendizaje from "@assets/images/portadas/rutasAprendizaje.png";
import "@assets/css/components/Home/style.css";
import HomeDefaultModal from "./HomeDefaultModal";

import { Col, Container, Row, Card, Button } from "react-bootstrap";

const HomeDefaultTemplate = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Container fluid="md">
      <Row>
        <Col style={{ textAlign: "center", margin: "30px 0" }}>
          <h2 style={{ fontSize: "2.4rem" }}>¡Bienvenido Victor!</h2>
          <h3>¿Tienes algún objetivo en mente? Puedes empezar por aquí:</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Header style={{ fontSize: "1.2rem" }}>
              Diagnóstico de habilidades
            </Card.Header>
            <Card.Img
              className="card-img-hover-home"
              src={examenDiagnostico}
              style={{ width: "100%", height: "auto", borderRadius: "0" }}
            ></Card.Img>
            <Card.Body>
              <Card.Title>
                Mide tus conocimientos en Excel, Power BI, o Ingles
              </Card.Title>
              <Card.Text>
                Conoce tu nivel de conocimientos, aplicando un examen de
                diagnóstico en el tema de tu preferencia y consolida lo que
                sabes
              </Card.Text>
              <div className="text-center">
                <Button variant="warning" onClick={() => setModalShow(true)}>
                  Tomar examen
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Header style={{ fontSize: "1.2rem" }}>
              Rutas de aprendizaje
            </Card.Header>

            <Card.Img
              className="card-img-hover-home"
              src={rutasAprendizaje}
              style={{ width: "100%", height: "auto", borderRadius: "0" }}
            ></Card.Img>
            <Card.Body>
              <Card.Title>¡Maximiza tu potencial!</Card.Title>
              <Card.Text>
                ¿Tienes algún objetivo en mente? Reunimos para ti, una serie de
                lecciones para aprender un tema en especifico. Aprende: tablas
                dinámicas, macros, fotomontaje con Photoshop o almacenamiento en
                la nube, entre otros.
              </Card.Text>
              <div className="text-center">
                <Button variant="warning">Conocer rutas</Button>
              </div>

              <HomeDefaultModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

function HomeInicialModal() {
  const [modalShow, setModalShow] = React.useState(false);

  return <></>;
}

HomeDefaultModal(<HomeInicialModal />);

export default HomeDefaultTemplate;
