const columnas = [
  // {
  //   name: "#", //nombre de la columna
  //   selector: (row) => row.idNotificacion, //identificador de la columna
  //   sortable: true,
  // },
  {
    name: "Título", //nombre de la columna
    selector: (row) => row.titulo, //identificador de la columna
    sortable: true,
    // width: '350px'
  },
  {
    name: "Descripción",
    selector: (row) => row.descripcion, //identificador de la columna
    sortable: true,
    // width: '600px'
  },
  {
    name: "Estatus",
    selector: (row) => (row.estatus ? "Activo" : "Ináctivo"), //identificador de la columna
    sortable: true,
    center: true,
    width: "150px",
  },
];

export default columnas;
