import Header from './components/Header/header'
import Controls from './components/Controls/controls'
import TimerDisplay from './components/TimerDisplay/timerdisplay'
import Button from './components/Button/button'
import Settings from './components/Settings/settings'
import { useState, useEffect, useRef } from 'react';
import useSound from 'use-sound'
import timesUpSfx from './sounds/timesUp.mp3'

import "@assets/css/components/Pomodoro/style.css"
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TfiReload } from 'react-icons/tfi'
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'


export const Pomodoro = ({ setTimerMode, setPomoLength, setShortLength, setLongLength, setSecondsLeft, timerMode, pomoLength, shortLength, longLength, secondsLeft, formatTimeLeft, calcPercentage, setIsActive, isActive, setButtonText, buttonText, timesUp, volume, setVolume }) => {
  const [settingsVisible, setSettingsVisible] = useState(false)

  let fontSelectedValue = "kumbh";
  let colorValue = "default";

  const pomodoroValues = JSON.parse(localStorage.getItem("pomodoroValues"));

  if (pomodoroValues !== null) {
    const { fontSelected, accentColor } = pomodoroValues;
    fontSelectedValue = fontSelected;
    colorValue = accentColor;
  }

  const [tourActivo, setTourActivo] = useState(false);

  const joyrideRef = useRef(null);

  const [tourSteps, setTourSteps] = useState([
    {
      target: ".pomodoro-question",
      content: "Bienvenido, ¿Has oido hablar de la técnica Pomodoro?. La técnica Pomodoro es una técnica popular para gestión del tiempo que consiste en trabajar en sesiones de 25 minutos separadas por descansos breves para maximizar la concentración.",
      disableBeacon: true,
    },
    {
      target: ".pomodoro",
      content: "Usa 'Pomodoro' para dedicarle un tiempo de 5 a 90 minutos a la técnica.",
      disableBeacon: true,
    },
    {
      target: ".short",
      content: "Luego de un tiempo usa el 'Descanso corto' para relajarte y maximizar tu productividad",
      disableBeacon: true,
    },
    {
      target: ".long",
      content: "Si quieres hacer descansos un poco más largos te recomendamos usar 'Descanso largo'",
      disableBeacon: true,
    },
    {
      target: ".settings",
      content: "Desde la configuración puedes personalizar tus tiempos y los estilos de la herramienta.",
      disableBeacon: true,
    },

    {
      target: ".pomodoro-question",
      content: "Al iniciar la cuenta regresiva puedes continuar navegando por la plataforma y se te notificará cuando el tiempo haya concluido, esta notificación aparecerá en la esquina superior derecha de tu pantalla.",
      disableBeacon: true,
    },

    
    {
      target: ".pomodoro-question",
      content: "Con esta herramienta aumentarás tu productividad y te mantendrás relajado y concentrado en tus actividades, buena suerte.",
      disableBeacon: true,
    },

  ]);
  const [runTour, setRunTour] = useState(false);




  const [fontPref, setFontPref] = useState(fontSelectedValue)         // options: kumbh, roboto, space
  const [accentColor, setAccentColor] = useState(colorValue) // options: default, blue, purple

  const toggleSettingsVisibility = (event) => {
    setSettingsVisible(!settingsVisible)
  }

  const reinciarContador = () => {
    switch (timerMode) {
      case "pomo":
        setSecondsLeft(pomoLength * 60)
        setButtonText("INICIAR")
        break;

      case "short":
        setSecondsLeft(shortLength * 60)
        setButtonText("INICIAR")
        break;

      case "long":
        setSecondsLeft(longLength * 60)
        setButtonText("INICIAR")
        break;

      default:
        break;
    }
  }

  const handleStartTour = async () => {
    setRunTour(!runTour);
  };

  return (
    <Container fluid className='p-0'>

      <Joyride
        locale={spanishOptionsJoyRide}
        ref={joyrideRef}
        steps={tourSteps}
        run={runTour}
        hideCloseButton
        showSkipButton
        continuous={true}
        disableOverlayClose={false}
        disableCloseOnEsc={false}
        disableScrolling={false}
        scrollToFirstStep={true}
        scrollOffset={100}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRunTour(false);
          }

        }}
        styles={{
          options: {
            primaryColor: "#ff9900",
            textColor: "#00152A",
            width: 900,
            zIndex: 1000,
          },
        }}
      />

      <Row>

        <Col className="pomodoro-app">

          <Row className="mt-4">
            <Col className='text-center text-white'>
              <h2>Pulsa INICIAR para comenzar la cuenta regresiva</h2>
            </Col>
          </Row>

          <Row className='d-flex align-items-center justify-content-between'>
            <Col className='col-auto'>
              <Controls
                timerMode={timerMode}
                setTimerMode={setTimerMode}
                setSecondsLeft={setSecondsLeft}
                pomoLength={pomoLength}
                shortLength={shortLength}
                longLength={longLength}
                setIsActive={setIsActive}
                buttonText={buttonText}
                setButtonText={setButtonText}
                volume={volume}
              />
            </Col>
            <Col className='col-auto text-center settings'>
              <Button type="settings" toggleVisibility={toggleSettingsVisibility} />
            </Col>

            <Col className="col-auto text-center ">
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>Conoce la técnica Pomodoro</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon
                    className=" pointer pomodoro-question"
                    onClick={handleStartTour}
                    style={{ fontSize: "2rem", color: "white" }}
                    icon={faQuestion}
                  />
                </span>
              </OverlayTrigger>
            </Col>
          </Row>
          <TimerDisplay
            timerMode={timerMode}
            percentage={calcPercentage()}
            timeLeft={formatTimeLeft(secondsLeft)}
            isActive={isActive}
            setIsActive={setIsActive}
            buttonText={buttonText}
            setButtonText={setButtonText}
            volume={volume}
            setVolume={setVolume}
          />


          <Row className='mt-5'>
            <Col>
              <button onClick={reinciarContador} className='btn btn-warning'>Reiniciar tiempo <TfiReload /></button>
            </Col>
          </Row>


          <Settings visible={settingsVisible}
            toggleSettingsVisibility={toggleSettingsVisibility}
            pomoLength={pomoLength}
            setPomoLength={setPomoLength}
            shortLength={shortLength}
            setShortLength={setShortLength}
            longLength={longLength}
            setLongLength={setLongLength}
            fontPref={fontPref}
            setFontPref={setFontPref}
            accentColor={accentColor}
            setAccentColor={setAccentColor}
            closeSettings={toggleSettingsVisibility}
            setSecondsLeft={setSecondsLeft}
            timerMode={timerMode}
          />
        </Col>
      </Row>
    </Container>
  );
}
