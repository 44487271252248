import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPreguntasByCodigoCurso } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ProgressBar } from "primereact/progressbar";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import "@assets/css/components/ViewCurso/styles.css";
import { addCurso, updateCurso, verificarCursoUsuario } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import { HiOutlineDocumentText } from "react-icons/hi";
import { getUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { MdOutlineCancel } from "react-icons/md";
import { FaSpa } from "react-icons/fa";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const EvaluacionFinal = ({ setShowEvaluacionFinal, codigoCurso, infoCurso, setCertificadoDesbloqueado, idPerfil, rol, idUsuarioEmpresarial, setPuntajeCertificacion, setModoConcentracion, modoConcentracion, cancelFullScreenButton, FullScreenButton }) => {
	//*Arreglo original en donde se almacenan inicialmente las preguntas
	const [preguntasEvaluacion, setPreguntasEvaluacion] = useState([]);

	const [arrayPrueba, setArrayPrueba] = useState(new Array(preguntasEvaluacion.length).fill("undefined"));

	//*Arreglo de respuestas
	const [respuestas, setRespuestas] = useState([]);

	const [respuestasExamen, setRespuestasExamen] = useState(new Array(preguntasEvaluacion.length).fill("undefined"));

	//*State para guardara las respuestas del examen
	const [objetoRespuestas, setObjetoRespuestas] = useState(null);
	// const [arregloObjetos, setArregloObjetos] = useState([]);

	//*Pagnacion de preguntas
	const [currentPage, setCurrentPage] = useState(1);

	const [sectionsPerPage, setSectionsPerPage] = useState(3);

	const indexOfLastSection = currentPage * sectionsPerPage;

	const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

	const currentPaginationSections = preguntasEvaluacion.slice(indexOfFirstSection, indexOfLastSection);

	const numberPages = Math.ceil(preguntasEvaluacion.length / sectionsPerPage);

	//*State para el progressbar
	const [valueProgress, setValueProgress] = useState(0);

	//*State para cuando se finalice el examen
	const [examenContestado, setExamenContestado] = useState(false);

	const [puntuacionFinal, setPuntuacionFinal] = useState(0);

	const [guardarPuntuacion, setGuardarPuntuacion] = useState(false);

	const [camposVacios, setCamposVacios] = useState(false);

	//*State para verificar si el usuario ya hizo el examen y ya esta registrado el avance, de esta manera actualizarlo o insertarlo como nuevo avance del curso.
	const [cursoVerificado, setCursoVerificado] = useState(false);

	const [dataVerificada, setDataVerificada] = useState({});

	const [ultimaLeccionTomada, setUltimaLeccionTomada] = useState(0);

	const handlePreviousPage = () => {
		if (currentPage >= 1) {
			// console.log(valueProgress);
			setCurrentPage(currentPage - 1);
			let calculoProgress = 100 / numberPages;
			// console.log(calculoProgress);

			let porcentajeActual = valueProgress;
			// console.log(porcentajeActual);

			setValueProgress((porcentajeActual -= calculoProgress));
			// console.log(numberPages);
			// console.log(currentPage);
		}
	};

	const handleNextPage = () => {
		if (currentPage <= numberPages) {
			setCurrentPage(currentPage + 1);
			//*Calculo del porcentaje para el progressbar

			// let calculoProgress = preguntasEvaluacion.length / numberPages;
			// console.log(calculoProgress);
			// let porcentajeActual = valueProgress;
			// console.log(porcentajeActual);
			let calculoProgress = 100 / numberPages;
			// console.log(calculoProgress);

			let porcentajeActual = valueProgress;

			setValueProgress((porcentajeActual += calculoProgress));

			// console.log(numberPages);
			// console.log(currentPage);
		}
	};

	//*Funcion para renderizar las respuestas del cuestionario
	const handleRespuestas = () => {
		// console.log(respuestasExamen);

		let arregloAux = [];

		currentPaginationSections.map((pregunta, idx) => {
			arregloAux.push(pregunta.opcionesRespuesta);
		});

		for (let i = 0; i < arregloAux.length; i++) {
			const obj = JSON.parse(arregloAux[i]);
			obj.id = i + 1;
			arregloAux[i] = JSON.stringify(Object.assign({}, obj));
		}

		// console.log(arregloAux);

		const valores = arregloAux.map((objeto) => {
			const parsedObjeto = JSON.parse(objeto);
			return Object.values(parsedObjeto);
		});

		setObjetoRespuestas(valores);
	};

	const handleRespuestaExamen = ({ target }) => {
		const { name, value } = target;

		respuestasExamen[name] = value;

		handleRespuestas();
	};

	const handleFinalizarExamen = () => {
		//Se guardan en un arreglo vacio las diferencias entre el arerglo respuestas correctas y el de las respuestas del usuario
		let diferencias = [];

		for (let i = 0; i < preguntasEvaluacion.length; i++) {
			if (respuestas[i] !== respuestasExamen[i]) {
				diferencias.push(respuestasExamen[i]);
			}
		}

		if (!diferencias.includes(undefined)) {
			let numeroRespuestasCorrectas = preguntasEvaluacion.length - diferencias.length;

			// console.log(numeroRespuestasCorrectas);

			let puntuacion = (100 * numeroRespuestasCorrectas) / preguntasEvaluacion.length;

			// console.log(puntuacion);
			setPuntuacionFinal(puntuacion);

			setExamenContestado(true);

			setGuardarPuntuacion(true);

			setCamposVacios(false);
		} else {
			setCamposVacios(true);
		}
	};

	const handleGuardarPuntaje = async () => {
		try {
			const infoCurso = {
				rol: rol,
				codigoCurso: codigoCurso,
				idPerfil: idPerfil,
				idUltimaLeccionTomada: ultimaLeccionTomada,
				puntajeEvaluacion: puntuacionFinal,
				dataVerificada
			};

			//* AQUI SE GUARDA EL PUNTAJE EN LA BASE DE DATOS, SE CREA SI NO ESTA VERIFICADO, SE ACTUALIZA SI SI LO ESTA
			if (cursoVerificado) {
				await updateCurso(infoCurso);
			} else {
				await addCurso(infoCurso);
			}

			//*Si la puntuacion es igual o mayor a 85% actualizar el state para descargar el certificado
			// console.log(puntuacionFinal);
			if (puntuacionFinal >= 85) {
				// console.log("La puntuacion es mas o igual a 85");
				setCertificadoDesbloqueado(true);
			}

			setPuntajeCertificacion(puntuacionFinal);
			setShowEvaluacionFinal(false);
			
			
		} catch (error) {
			console.log(error);
		}
	};

	const handleRegresar = async () => {
		const Modal = await ModalSweetAlert({
			title: "¿Seguro que quieres salir?",
			text: "Perderás todo tu progreso",
			confirmButtonText: "Si, quiero salir",
			showCancelButton: true,
			cancelButtonText: "Quiero quedarme",
			confirmButtonColor: "green",
			cancelButtonColor: "red",
			width: "400px",
		});

		if (Modal) {
			setShowEvaluacionFinal(false);
		}
	};

	useEffect(() => {
		const obtenerPreguntas = async () => {
			try {
				const { data } = await getPreguntasByCodigoCurso(codigoCurso);
				if (Array.isArray(data)) {
					// console.log("LONGITUD DE LA DATA DE LAS PREGUNTAS: " + data.length);
					setPreguntasEvaluacion(data);
					//*Aqui se vaciaran del arreglo original las primeras preguntas que se mostrarán en pantalla una vez iniciado el examen.
					for (let i = 0; i < data.length; i++) {
						respuestas[i] = data[i].respuestaCorrecta;
					}
				} else {
					setPreguntasEvaluacion([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerPreguntas();

		const verificarCurso = async () => {
			try {
				const infoCurso = {
					codigoCurso: codigoCurso,
					idPerfil: idPerfil,
					rol: rol,
					idUsuarioEmpresarial: idUsuarioEmpresarial,
				};
				const { data } = await verificarCursoUsuario(infoCurso);
				setCursoVerificado(true);
				setDataVerificada(data.data);
			} catch (error) {
				console.log(error);
			}
		};

		verificarCurso();

		const ultimaLeccion = async () => {
			try {
				const { data } = await getUltimaLeccionTomada(idPerfil);
				const { idUltimaLeccionTomada } = data;
				setUltimaLeccionTomada(idUltimaLeccionTomada);
			} catch (error) {
				console.log(error);
			}
		};

		ultimaLeccion();
	}, []);

	useEffect(() => {
		handleRespuestas();
	}, [numberPages, currentPage]);

	return (
		<>
			<Container style={{ borderRadius: "10px", height: "auto" }} className="shadow-lg p-4 mt-4">
				<Row className="justify-content-end">
					<Col className="col-auto">
						{!modoConcentracion ? (
							<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Modo concentración.</Tooltip>}>
								<Button variant="outline-success" onClick={FullScreenButton}>
									<FaSpa />
								</Button>
							</OverlayTrigger>
						) : (
							<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Salir del modo concentración.</Tooltip>}>
								<Button variant="outline-danger" onClick={cancelFullScreenButton}>
									<MdOutlineCancel />
								</Button>
							</OverlayTrigger>
						)}
					</Col>
				</Row>

				<Row className="justify-content-between mt-5">
					<Col className="col-auto">
						<h3>Evaluación final: {infoCurso.nombre}</h3>
					</Col>
					<Col className="col-auto">
						{/* //*CUANDO SE PRESIONE PREGUNTE SI DESEA CANCELAR EL EXAMEN */}
						<Button className="btn-sm" onClick={handleRegresar}>
							<FontAwesomeIcon icon={faArrowLeft} /> Regresar
						</Button>
					</Col>
				</Row>

				{preguntasEvaluacion.length > 0 && !examenContestado && (
					<Row className="mt-4 mb-3 justify-content-center">
						<Col className="col-10 text-center">
							<ProgressBar showValue={false} className="progressbar-evaluacion" color="#FF9900" value={valueProgress}></ProgressBar>
						</Col>
					</Row>
				)}

				<Row className="justify-content-center p-3">
					<Col className="text-center">
						{puntuacionFinal >= 85 && <lord-icon src="https://cdn.lordicon.com/tqywkdcz.json" trigger="loop" style={{ width: "100px", height: "100px" }}></lord-icon>}
						{examenContestado && (
							<h3>
								Tu puntuación: <b>{Math.round(puntuacionFinal)}%</b>
							</h3>
						)}

						{guardarPuntuacion && (
							<Button variant="success" onClick={handleGuardarPuntaje} className="mx-1 mt-3">
								Guardar puntuación
							</Button>
						)}
					</Col>

					<Col className="col-12 mb-4">
						{/* //* En el listado de las preguntas se deben mostrar unicamente 5 de las 100 prguntas e irlas mostrando para contestarlas. */}
						{preguntasEvaluacion.length > 0 ? (
							!examenContestado ? (
								//*--------------------------Listado de preguntas con sus respuestas-----------------------------
								currentPaginationSections.map((preguntaEvaluacion, idPregunta) => (
									<div key={idPregunta}>
										<span>{`${preguntaEvaluacion.idTemporal + 1}. ${preguntaEvaluacion.pregunta} `}</span>

										{preguntaEvaluacion.imagenPregunta !== null && (
											<Row className="justify-content-center mt-3 mb-4">
												<Col className="col-auto">
													<img src={preguntaEvaluacion.imagenPregunta} alt="img pregunta" />
												</Col>
											</Row>
										)}
										<div className="mt-2 mb-3">
											<RadioGroup
												 aria-labelledby="demo-radio-buttons-group-label"
												 defaultValue="female"
												 name="radio-buttons-group"
											   >
											{!examenContestado &&
												
												objetoRespuestas.map((objeto, idObjeto) =>
													objeto.length === 4
														? objeto[3] === idPregunta + 1 &&
														  objetoRespuestas[idObjeto].map(
																(respuesta, idRespuesta) =>
																	idRespuesta !== 3 && (
																		
																		<FormControlLabel key={idRespuesta}
																		value={respuesta}  control={<Radio />} label={respuesta}
																		name={`${preguntaEvaluacion.idTemporal}`}
																		onChange={handleRespuestaExamen}  
																		checked={respuesta === respuestasExamen[preguntaEvaluacion.idTemporal] ? true : false}
																		/>
																	)
														  )
														: objeto[2] === idPregunta + 1 &&
														  objetoRespuestas[idObjeto].map(
																(respuesta, idRespuesta) =>
																	idRespuesta !== 2 && (
																		<FormControlLabel key={idRespuesta}
																		value={respuesta}  control={<Radio />} label={respuesta}
																		name={`${preguntaEvaluacion.idTemporal}`}
																		onChange={handleRespuestaExamen}
																		checked={respuesta === respuestasExamen[preguntaEvaluacion.idTemporal] ? true : false}
																		 />
																	)
														  )
												)
											}
											</RadioGroup>
										</div>
									</div>
								))
							) : (
								//*--------------------------Listado de preguntas con los resultados del usuario-----------------------------
								preguntasEvaluacion.map((preguntaEvaluacion, idPregunta) => (
									<div className="mt-5" key={idPregunta}>
										<span>{`${preguntaEvaluacion.idTemporal + 1}. ${preguntaEvaluacion.pregunta} `}</span>
										<div className="mt-2 mb-3">
											{examenContestado && (
												<>
													<Alert variant={respuestasExamen[idPregunta] === respuestas[idPregunta] ? "success" : "danger"}>
														<small>Tu respuesta: {respuestasExamen[idPregunta]}</small>
													</Alert>
													{/*  //TODO DEFINIR SI ES CORRECTO MOSTRARLE LAS RESPUESTAS COMO RETROALIMENTACION */}
													{/* <Alert variant="success">
														<small>Respuesta correcta: {respuestas[idPregunta]}</small>
													</Alert> */}
												</>
											)}
										</div>
									</div>
								))
							)
						) : (
							<Row className="mt-5">
								<Col className="text-center" style={{ color: "grey" }}>
									<HiOutlineDocumentText className="mb-3" size="80px" />
									<h4>Evaluación final no disponible por el momento</h4>
								</Col>
							</Row>
						)}
					</Col>

					{preguntasEvaluacion.length > 0 && (
						<Col className="text-center">
							{!examenContestado && (
								<Button disabled={currentPage === 1 ? true : false} onClick={handlePreviousPage} className="mx-1">
									Anterior
								</Button>
							)}
							{currentPage === numberPages ? (
								!guardarPuntuacion ? (
									<Button onClick={handleFinalizarExamen} className="mx-1">
										Finalizar evaluacion
									</Button>
								) : (
									<Button variant="success" onClick={handleGuardarPuntaje} className="mx-1">
										Guardar puntuación
									</Button>
								)
							) : (
								<Button onClick={handleNextPage} className="mx-1">
									Siguiente
								</Button>
							)}

							{camposVacios && (
								<Alert className="mt-3" variant="danger">
									Uno o más incisos se encuentran en blanco, contesta todos las preguntas.
								</Alert>
							)}
						</Col>
					)}
				</Row>
			</Container>
		</>
	);
};

export default EvaluacionFinal;
