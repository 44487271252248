import { Notificacion } from "@components/general/Alertas/Alertas";
import { deactivateLeccionPersonalizada } from "@helpers/httpRequests/httpRequestsLeccionesPersonalizadas/httpRequestsLeccionesPersonalizadas";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const DesactivarLeccion = ({ setLeccionDesactivada, infoLeccion }) => {
  // console.log(infoLeccion);
  const { idLeccion } = infoLeccion;

  // console.log(idLeccion);

  const handleDesactivarLeccion = async () => {
    try {
      await deactivateLeccionPersonalizada(idLeccion);

      setLeccionDesactivada(true);

      Notificacion("success", "La lección fue desactivada con éxito");
    } catch (e) {
      console.log(e);

      Notificacion(
        "error",
        "Algo sucedió al desactivar la lección, vuelve a intentar"
      );
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "La lección no fue desactivada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro que deseas desactivar esta lección?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleDesactivarLeccion}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DesactivarLeccion;
