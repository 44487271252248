import { Button, Col, Container, Row } from "react-bootstrap";
import {
  faEdit,
  faPlus,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import AgregarConsejo from "./Modales/AgregarConsejo/AgregarConsejo";
import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import EditarConsejo from "./Modales/EditarConsejo/EditarConsejo";
import EliminarConsejo from "./Modales/EliminarConsejo/EliminarConsejo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import React, { useMemo } from "react";
import ReactivarConsejo from "./Modales/ReactivarConsejo/reactivarConsejo";
import columns from "./DataTables/columns";
import { getConsejos } from "@helpers/httpRequests/httpRequestsConsejos/httpRequestsConsejos";
import { useEffect } from "react";
import { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";
import { FaEdit, FaPowerOff, FaTrash } from "react-icons/fa";

//COMPONENTES PARA LOS MODALES
export const DashboardConsejos = () => {
  const [consejos, setConsejos] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [consejoAgregado, setConsejoAgregado] = useState(false);

  const [consejoModificado, setConsejoModificado] = useState(false);

  const [consejoEliminado, setConsejoEliminado] = useState(false);

  const [consejoReactivado, setConsejoReactivado] = useState(false);

  //Agregar consejo
  const handleAgregarConsejo = () => {
    ModalSweetAlert({
      title: "Agregar nuevo consejo",
      icon: "info",
      html: <AgregarConsejo setConsejoAgregado={setConsejoAgregado} />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleEditarConsejo = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    //Current target
    ModalSweetAlert({
      title: "Editar consejo",
      icon: "info",
      html: (
        <EditarConsejo
          setConsejoModificado={setConsejoModificado}
          consejo={consejos[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  //Eliminar consejo

  const handleDesactivarConsejo = ({ currentTarget }) => {
    //Current target
    const rowIndex = currentTarget.id;

    ModalSweetAlert({
      title: "Desactivar consejo",
      icon: "question",
      html: (
        <EliminarConsejo
          setConsejoEliminado={setConsejoEliminado}
          consejo={consejos[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  //Reactivar el consejo
  const handleReactivarConsejo = ({ currentTarget }) => {
    //Current target
    const rowIndex = currentTarget.id;

    ModalSweetAlert({
      title: "Reactivar consejo",
      icon: "question",
      html: (
        <ReactivarConsejo
          setConsejoReactivado={setConsejoReactivado}
          consejo={consejos[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  useEffect(() => {
    //Use effect para traer la data de la tabla consejos.
    const obtenerConsejos = async () => {
      try {
        const { data } = await getConsejos();

        if (Array.isArray(data)) {
          setConsejos(data);
        } else {
          setConsejos([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerConsejos();
  }, []);

  useEffect(() => {
    if (consejos.length > 0) {
      //Editar consejo

      setDataTableColumns([
        ...columns,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`edit-${row.idConsejoRapido - 1}`}
                className="me-1 pointer"
                onClick={handleEditarConsejo}
              >
                <FontAwesomeIcon icon={faEdit} className="text-primary" />
              </span>

              {row.estatus ? (
                <span
                  id={`delete-${row.idConsejoRapido - 1}`}
                  className="ms-1 pointer"
                  onClick={handleDesactivarConsejo}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              ) : (
                <span
                  id={`delete-${row.idConsejoRapido - 1}`}
                  className="ms-1 pointer"
                  onClick={handleReactivarConsejo}
                >
                  <FontAwesomeIcon icon={faPowerOff} className="text-success" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
      ]);
    }
  }, [consejos]);

  useEffect(() => {
    //UseEffect para refrescar la tabla al hacer alguna accion del crud.
    if (
      consejoAgregado ||
      consejoEliminado ||
      consejoModificado ||
      consejoReactivado
    ) {
      const refreshConsejos = async () => {
        try {
          const { data: dataConsejos } = await getConsejos();
          setConsejos(dataConsejos);
        } catch (error) {
          console.log(error);
        }
      };
      refreshConsejos();

      setConsejoAgregado(false);
      setConsejoEliminado(false);
      setConsejoModificado(false);
      setConsejoReactivado(false);
    }
  }, [consejoAgregado, consejoEliminado, consejoModificado, consejoReactivado]);

  const columnas = useMemo(() => [
    {
      header: "#",
      size: 50,
      Cell: ({ cell }) => (
        <div className="text-center">
          <span>{cell.row.original.idConsejoRapido}</span>
        </div>
      ),
    },
    {
      accessorKey: "titulo",
      header: "Título",
    },
    {
      accessorKey: "descripcion",
      header: "Descripción",
    },
    {
      accessorKey: "estatus",
      header: "Correo",
      Cell: ({ cell }) => (
        <div className="text-center">
          <span>{cell.row.original.estatus}</span>
        </div>
      ),
    },
    {
      accessorKey: "codigoCurso",
      header: "Código Curso",
    },
  ]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col>
          <Button type="button" onClick={handleAgregarConsejo}>
            Nuevo consejo <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>

      <Row className="my-3 mb-5">
        <Col>
          {/* <DataTablesRecipient  columns={dataTableColumns} data={consejos} /> */}
          <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={columnas}
            data={consejos}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                <IconButton
                  color="secondary"
                  id={row.original.idConsejoRapido - 1}
                  onClick={handleEditarConsejo}
                >
                  <FaEdit className="text-primary" />
                </IconButton>

                {row.original.estatus ? (
                  <IconButton
                    color="secondary"
                    id={`${row.original.idConsejoRapido - 1}`}
                    onClick={handleDesactivarConsejo}
                  >
                    <FaTrash color="red" />
                  </IconButton>
                ) : (
                  <IconButton
                    color="secondary"
                    id={`${row.original.idConsejoRapido - 1}`}
                    onClick={handleReactivarConsejo}
                  >
                    <FaPowerOff color="green" />
                  </IconButton>
                )}
              </Box>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};
