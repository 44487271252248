import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateContrasenia } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

const CambioContraseña = ({ infoUsuario }) => {
  const inputContrasenia = useRef(null);

  const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

  const formValuesInitialState = {
    contrasenia: "",
    confirmacionContrasenia: "",
  };

  const validacionContraseniaInitialState = {
    caracteresContrasenia: false,
    mayusculaContrasenia: false,
    numeroContrasenia: false,
    contrasenia: false,
    confirmacionContrasenia: false,
  };

  const [formValues, setFormValues] = useState(formValuesInitialState);

  const [validacionContrasenia, setValidacionContrasenia] = useState(
    validacionContraseniaInitialState
  );

  const [showPasswords, setShowPasswords] = useState({
    contrasenia: false,
    confirmacionContrasenia: false,
  });

  const handleOnChangePasswords = async ({ target }) => {
    try {
      const { name, value } = target;

      if (name === "contrasenia") {
        setFormValues({
          ...formValues,
          [name]: value,
        });

        const validacionesContrasenia = await validarRestriccionesContrasenia({
          contrasenia: value,
          isNuevaContrasenia: true,
        });

        setValidacionContrasenia({
          ...validacionContrasenia,
          ...validacionesContrasenia,
        });
      } else if (name === "confirmacionContrasenia") {
        setFormValues({
          ...formValues,
          confirmacionContrasenia: value,
        });

        let contraseniasCoincidentes = false;

        if (value === formValues.contrasenia) {
          contraseniasCoincidentes = true;
        }

        setValidacionContrasenia({
          ...validacionContrasenia,
          confirmacionContrasenia: contraseniasCoincidentes,
          contrasenia: true,
        });
      }
    } catch (e) {
      console.log("catch", e);
    }
  };

  const showContrasenia = () => {
    setShowPasswords({
      ...showPasswords,
      contrasenia: !showPasswords.contrasenia,
    });
  };

  const showConfirmacionContrasenia = () => {
    setShowPasswords({
      ...showPasswords,
      confirmacionContrasenia: !showPasswords.confirmacionContrasenia,
    });
  };

  const handleNewPassword = (e) => {
    e.preventDefault();
    try {
      ModalSweetAlert({
        title: `Cambio de contraseña`,
        text: "¿Estás seguro de cambiar la contraseña?",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#dc3545",
        showCancelButton: true,
        confirmButtonText: "Cambiar contraseña",
        confirmButtonColor: "#28a745",
        reverseButtons: true,
      }).then(async (isConfirmed) => {
        if (isConfirmed) {
          console.log("Cambiando la contraseña");
          //Logica para cambiar la contraseña
          try {
            const updatePassword = await updateContrasenia({
              idCredencial: infoUsuario.idCredencial,
              nuevaContrasenia: formValues.confirmacionContrasenia,
            });

            Notificacion("success", "Contraseña modificada");
          } catch (error) {
            console.log(error);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="text-start">
      <Row>
        <Col className="col-12 mb-4 mt-3">
          <p>
            <h4>
              <b>Se modificará la contraseña de:</b>
            </h4>
            {infoUsuario.nombreCompleto + " " + infoUsuario.apellidos}
          </p>
        </Col>

        <Col>
          <Form onSubmit={handleNewPassword}>
            <Form.Group>
              <Form.Label>Contraseña nueva</Form.Label>

              <InputGroup hasValidation>
                <InputGroup.Text onClick={showContrasenia} className="pointer">
                  <FontAwesomeIcon icon={faEye} />
                </InputGroup.Text>

                <Form.Control
                  required
                  type={showPasswords.contrasenia ? "text" : "password"}
                  autoComplete="off"
                  value={formValues.contrasenia}
                  className={
                    validacionContrasenia.contrasenia ? "is-valid " : ""
                  }
                  name="contrasenia"
                  placeholder="Contraseña nueva"
                  onChange={handleOnChangePasswords}
                  ref={inputContrasenia}
                />

                <Form.Control.Feedback type="invalid">
                  Genera una contraseña v&aacute;lida
                </Form.Control.Feedback>

                <CapsLockTooltip
                  input={inputContrasenia}
                  isCapsLockOn={isCapsLockOn}
                />
              </InputGroup>

              <Form.Text id="passwordHelpBlock" muted>
                La contraseña debe contener:
                <ul>
                  <li
                    className={
                      validacionContrasenia.caracteresContrasenia
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    8 caracteres
                  </li>
                  <li
                    className={
                      validacionContrasenia.mayusculaContrasenia
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    1 mayúscula
                  </li>
                  <li
                    className={
                      validacionContrasenia.numeroContrasenia
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    1 número
                  </li>
                </ul>
                {/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Confirmar contraseña</Form.Label>

              <InputGroup hasValidation>
                <InputGroup.Text
                  onClick={showConfirmacionContrasenia}
                  className="pointer"
                >
                  <FontAwesomeIcon icon={faEye} />
                </InputGroup.Text>

                <Form.Control
                  required
                  onChange={handleOnChangePasswords}
                  value={formValues.confirmacionContrasenia}
                  type={
                    showPasswords.confirmacionContrasenia ? "text" : "password"
                  }
                  autoComplete="off"
                  name="confirmacionContrasenia"
                  placeholder="Confirmar nueva contraseña"
                  className={
                    validacionContrasenia.confirmacionContrasenia
                      ? "is-valid"
                      : "is-invalid"
                  }
                />

                <Form.Control.Feedback type="invalid">
                  Las contraseñas deben de ser iguales
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  Las contraseñas son iguales
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button type="submit" size="lg">
                Cambiar contraseña
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CambioContraseña;
