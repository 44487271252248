const typesMesesFecha = {
    enero: {
        digito: 1,
        letra: 'Enero'
    },
    febrero: {
        digito: 2,
        letra: 'Febrero'
    },
    marzo: {
        digito: 3,
        letra: 'Marzo'
    },
    abril: {
        digito: 4,
        letra: 'Abril'
    },
    mayo: {
        digito: 5,
        letra: 'Mayo'
    },
    junio: {
        digito: 6,
        letra: 'Junio'
    },
    julio: {
        digito: 7,
        letra: 'Julio'
    },
    agosto: {
        digito: 8,
        letra: 'Agosto'
    },
    septiembre: {
        digito: 9,
        letra: 'Septiembre'
    },
    octubre: {
        digito: 10,
        letra: 'Octubre'
    },
    noviembre: {
        digito: 11,
        letra: 'Noviembre'
    },
    diciembre: {
        digito: 12,
        letra: 'Diciembre'
    },
};

export default typesMesesFecha;