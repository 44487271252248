import React from "react";
import PaquetesIncluidosListItem from "./items/PaquetesIncluidosListItem";

const PaquetesIncluidosList = ({ codigoAplicado }) => {

	return (
		<ul id="listDetallePremium" className="fa-ul">
			
			<PaquetesIncluidosListItem 
				title="Asesorías personalizadas (durante 18 meses)"
			/>
			
			<PaquetesIncluidosListItem 
				title="Paquete Herramientas Ofimáticas"
			/>
			
			<PaquetesIncluidosListItem 
				title="Paquete Temas Complementarios"
			/>
			
			<PaquetesIncluidosListItem 
				title="Paquete Desarrollo Personal"
			/>

			<PaquetesIncluidosListItem 
				title="Paquete Beneficios Incluidos"
			/>
			
			<PaquetesIncluidosListItem
				classNames={ 'info-certificacion-mos d-block animate__animated animate__fadeIn' }
				codigoAplicado={ codigoAplicado } 
				title={ 
					<>
						<strong>4</strong> Intentos en Examen de Prueba Certificación MOS (durante 18 meses)
					</>
				}	
			/>
			
			<PaquetesIncluidosListItem
				classNames={ 'info-certificacion-mos d-block animate__animated animate__fadeIn' }
				codigoAplicado={ codigoAplicado } 
				title={
					<>
						<strong>2</strong> Intentos en Examen de Certificación MOS  (durante 18 meses)
					</>
				}
			/>

			<PaquetesIncluidosListItem 
				title="Acceso de por vida"
			/>
			
		</ul>
	);
};

export default PaquetesIncluidosList;
