import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import TablePapeleraContenidoCurso from "./DataTables/TablePapeleraContenidoCurso";
import { getCursosPapelera } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getLeccionesPapelera } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { getSeccionesPapelera } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";

const DashboardPapeleraReciclajeContenidoCurso = () => {
  const [elementosPapelera, setElementosPapelera] = useState({
    seleccion: "",
    data: [],
  });

  const [seleccion, setSeleccion] = useState("");

  const [elementoEliminado, setElementoEliminado] = useState(false);

  const opcionesPapelera = [
    {
      key: "cursos",
      label: "Papelera de Cursos",
    },
    {
      key: "secciones",
      label: "Papelera de Secciones",
    },
    {
      key: "lecciones",
      label: "Papelera de Lecciones",
    },
  ];

  const handleSeleccionPapelera = async ({ target }) => {
    try {
      let resultados;

      switch (target.value) {
        case "cursos":
          resultados = await getCursosPapelera();
          break;

        case "lecciones":
          resultados = await getLeccionesPapelera();
          break;

        case "secciones":
          resultados = await getSeccionesPapelera();
          break;

        default:
          setSeleccion("");

          setElementosPapelera({
            seleccion: "",
            data: [],
          });
          return;
      }

      if (!Array.isArray(resultados.data)) {
        resultados.data = [resultados.data];
      }

      setSeleccion(capitalizeString(target.value));

      setElementosPapelera({
        seleccion: target.value,
        data: resultados.data,
      });
    } catch (error) {
      console.log("Error al consultar los elementos de papelera: ", error);
    }
  };

  useEffect(() => {
    if (elementoEliminado) {
      const refetchData = async () => {
        try {
          let resultados;

          switch (elementosPapelera.seleccion) {
            case "cursos":
              resultados = await getCursosPapelera();
              break;

            case "lecciones":
              resultados = await getLeccionesPapelera();
              break;

            case "secciones":
              resultados = await getSeccionesPapelera();
              break;

            default:
              setSeleccion("");

              setElementosPapelera({
                seleccion: "",
                data: [],
              });
              return;
          }

          if (!Array.isArray(resultados.data)) {
            resultados.data = [resultados.data];
          }

          setElementosPapelera({
            ...elementosPapelera,
            data: resultados.data,
          });
        } catch (error) {
          console.log("Error al reconsultar los datos: ", error);

          setElementosPapelera({
            seleccion: "",
            data: [],
          });
        }

        setElementoEliminado(false);
      };

      refetchData();
    }
  }, [elementoEliminado]);

  return (
    <>
      <Container fluid className="p-0 mb-3">
        <Row>
          <Col>
            <Form.Group as={Col} controlId="text">
              <Form.Label>Elige una papelera para ver</Form.Label>

              <Form.Control as={"select"} onChange={handleSeleccionPapelera}>
                <option value="">Elige una opción</option>

                {opcionesPapelera.map((opcion, idx) => (
                  <option value={opcion.key} key={idx}>
                    {opcion.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {elementosPapelera.data.length > 0 ? (
          <Row>
            <Col>
              <h4>{seleccion}</h4>

              <hr />

              <TablePapeleraContenidoCurso
                elementos={elementosPapelera}
                setElementoEliminado={setElementoEliminado}
              />
            </Col>
          </Row>
        ) : (
          <h5>
            {seleccion === ""
              ? "Elige una opción"
              : "No hay elementos en esta papelera"}
          </h5>
        )}
      </Container>
    </>
  );
};

export default DashboardPapeleraReciclajeContenidoCurso;
