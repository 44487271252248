import { Checkbox } from "primereact/checkbox";
import React from "react";

const CustomCheckbox = ({ id, name, title, onChange, checked }) => {
	return (
		<div className="flex align-items-center">
			<Checkbox inputId={id} name={name} onChange={onChange} checked={checked} />
 
			<label htmlFor={id} className="ml-2 text-white">
				&nbsp;{title}                                                                                                                                                                                   
			</label>
		</div>
	);
};

export default CustomCheckbox;
