/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";

const DirectorioTelefonico = ({ modalShow, setModalShow }) => {
	const hideModal = () => {
		setModalShow(!modalShow);
	};

	return (
		<>
			<Modal aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-90w" onHide={hideModal} show={modalShow} size="lg">
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">Directorio Telef&oacute;nico</Modal.Title>
					<Button variant="danger" onClick={hideModal}>
						X
					</Button>
				</Modal.Header>

				<Modal.Body>
					<Container fluid>
						<Row>
							<Col>
								<h5>Nuestros Expertos</h5>
								<hr />

								<p>
									<span>
										David Vigueras -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:david@globalclickmexico.com">
											david@globalclickmexico.com
										</a>
									</span>
								</p>
								<p>
									<span>
										Iv&aacute;n Vigueras -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:ivanvigueras@globalclickmexico.com">
											ivanvigueras@globalclickmexico.com
										</a>
									</span>
								</p>
								<p>
									<span>
										Jos&eacute; Mar&iacute;a Carrillo -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:josemaria@globalclickmexico.com">
											josemaria@globalclickmexico.com
										</a>
									</span>
								</p>
								<p>
									<span>
										Asesor personalizado -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:asesoria@globalclickmexico.com">
											asesoria@globalclickmexico.com
										</a>
									</span>
								</p>
							</Col>

							<Col>
								<h5>Nuestros Departamentos</h5>
								<hr />

								<p>
									<span>
										Vinculaci&oacute;n -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:vinculacion@globalclickmexico.com">
											vinculacion@globalclickmexico.com
										</a>
									</span>
								</p>
								<p>
									<span>
										Soporte t&eacute;cnico -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:soporte@globalclickmexico.com">
											soporte@globalclickmexico.com
										</a>
									</span>
									<br />
									<span>
										<a style="color: #ff9900; text-decoration: none;" href="#">
											477 390 8301 Ext. 101
										</a>{" "}
										{/* &oacute;{" "}
										<a style="color: #ff9900; text-decoration: none;" href="#">
											477 314 5975
										</a> */}
									</span>
								</p>
								<p>
									<span>
										Recursos humanos -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:rh@globalclickmexico.com">
											rh@globalclickmexico.com
										</a>
									</span>
									<br />
									<span>
										<a style="color: #ff9900; text-decoration: none;" href="#">
											477 390 8301 Ext. 105
										</a>{" "}
										{/* &oacute;{" "}
										<a style="color: #ff9900; text-decoration: none;" href="#">
											477 912 0100
										</a> */}
									</span>
								</p>
								<p>
									<span>
										Cobranza -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:cobranza@globalclickmexico.com">
											cobranza@globalclickmexico.com
										</a>
									</span>
									<br />
									<span>
										<a style="color: #ff9900; text-decoration: none;" href="#">
											477 390 8301 Ext. 104
										</a>
									</span>
								</p>
								<p>
									<span>
										Log&iacute;stica -{" "}
										<a style="color: #ff9900; text-decoration: none;" href="mailto:logistica@globalclickmexico.com">
											logistica@globalclickmexico.com
										</a>
									</span>
									<br />
									{/* <span>
										<a style="color: #ff9900; text-decoration: none;" href="#">
											477 659 7690
										</a>
									</span> */}
								</p>
							</Col>
						</Row>
					</Container>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={hideModal}>
						Cerrar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DirectorioTelefonico;
