const capitalizeString = (uncapitalizedString) => {
  return uncapitalizedString.replace(/\w\S*/g, function (capitalizedString) {
    return (
      capitalizedString.charAt(0).toUpperCase() +
      capitalizedString.substr(1).toLowerCase()
    );
  });
};

export default capitalizeString;
