import { NotificacionExtendida } from "@components/general/Alertas/Alertas";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import { faArrowLeft, faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateTokenFolio } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { getUsuario, updateContrasenia } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import Loader from "@helpers/loader/loader";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import "@assets/css/views/Login/style.css";
import { Typography } from "@mui/material";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";

const FormularioRecuperacionContraseña = () => {
	const urlParams = new URLSearchParams(useLocation().search);

	const inputContrasenia = useRef(null);

	const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

	const [showPasswords, setShowPasswords] = useState({
		contrasenia: false,
		confirmacionContrasenia: false,
	});

	const formValuesInitialState = {
		contrasenia: "",
		confirmacionContrasenia: "",
	};

	const validacionContraseniaInitialState = {
		caracteresContrasenia: false,
		mayusculaContrasenia: false,
		numeroContrasenia: false,
		contrasenia: false,
		confirmacionContrasenia: false,
	};

	const [formValues, setFormValues] = useState(formValuesInitialState);

	const [validacionContrasenia, setValidacionContrasenia] = useState(validacionContraseniaInitialState);

	const [showLoader, setShowLoader] = useState(true);

	const [loading, setLoading] = useState(false);

	const [contraseniaActualizada, setContraseniaActualizada] = useState(false);

	const [idCredencial, setIdCredencial] = useState(0);

	const [usuario, setUsuario] = useState({});

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (formValues.contrasenia === "") {
			return NotificacionExtendida("error", "Primero ingresa tu contraseña");
			}
			
		if (formValues.contrasenia !== formValues.confirmacionContrasenia) {
			return NotificacionExtendida("error", "Las contraseñas no coinciden, revisa tu información");
		}

		try {
			await updateContrasenia({
				idCredencial,
				nuevaContrasenia: formValues.contrasenia,
			});

			NotificacionExtendida("success", "Se ha cambiado la contraseña");

			clearInputs();

			setFormValues(formValuesInitialState);

			setContraseniaActualizada(true);
		} catch (e) {
			console.log("catch", e);

			if (e.message && e.message === "wrong_password") {
				NotificacionExtendida("error", "La contraseña actual no coincide, revisa tu información");

				// setValidacionContraseniaAnterior(true);

				clearInputs();
			} else if (e.message && e.message === "empty_input") {
				NotificacionExtendida("error", "Es necesario llenar todos los campos");

				// setValidacionContraseniaAnterior(true);

				clearInputs();
			}
		}
	};

	const showContrasenia = () => {
		setShowPasswords({
			...showPasswords,
			contrasenia: !showPasswords.contrasenia,
		});
	};

	const showConfirmacionContrasenia = () => {
		setShowPasswords({
			...showPasswords,
			confirmacionContrasenia: !showPasswords.confirmacionContrasenia,
		});
	};

	const clearInputs = () => {
		setFormValues(formValuesInitialState);

		setValidacionContrasenia(validacionContraseniaInitialState);
	};

	const handleOnChangePasswords = async ({ target }) => {
		try {
			const { name, value } = target;

			if (name === "contrasenia") {
				setFormValues({
					...formValues,
					[name]: value,
				});

				const validacionesContrasenia = await validarRestriccionesContrasenia({
					contrasenia: value,
					isNuevaContrasenia: true,
				});

				setValidacionContrasenia({
					...validacionContrasenia,
					...validacionesContrasenia,
				});
			} else if (name === "confirmacionContrasenia") {
				setFormValues({
					...formValues,
					confirmacionContrasenia: value,
				});

				let contraseniasCoincidentes = false;

				if (value === formValues.contrasenia) {
					contraseniasCoincidentes = true;
				}

				setValidacionContrasenia({
					...validacionContrasenia,
					confirmacionContrasenia: contraseniasCoincidentes,
					contrasenia: true,
				});
			}
		} catch (e) {
			console.log("catch", e);
		}
	};

	const validarToken = async (token, folioContrato, correo) => {
		try {
			await validateTokenFolio(folioContrato, token);

			await consultarDatosUsuario(correo);

			setShowLoader(false);
		} catch (error) {
			console.log("algo sucedió al validar el token: ", error);
		}
	};

	const consultarDatosUsuario = async (correo) => {
		try {
			const datosUsuario = await getUsuario(correo);

			setUsuario(datosUsuario);

			setIdCredencial(datosUsuario.idCredencial);
		} catch (error) {
			console.log("error al consultar los datos del usuario: ", error);
		}
	};

	useEffect(() => {
		const correo = urlParams.get("correo");

		const token = urlParams.get("token");

		const folioContrato = urlParams.get("folioContrato");

		if (Object.keys(usuario).length === 0) {
			validarToken(token, folioContrato, correo);
		}
	}, []);

	return showLoader ? (
		<Loader />
	) : (
		<Container fluid className="formRegistro vh-100 d-flex flex-column align-items-center justify-content-center" style={{ overflowY: "hidden" }}>
			<Card body className="w-50">
				<a style={{ textDecoration: "none" }} href={dashboardRoutesPaths.login} className="text-secondary pointer">
					<FontAwesomeIcon icon={faArrowLeft} /> Iniciar sesión
				</a>
				{
					!contraseniaActualizada ?
					<>
						<h5 className="text-center mt-3">Ingresa tu nueva contraseña</h5>

						<Row className="my-4 mb-3 justify-content-center">
							<Col>
								<Form onSubmit={handleSubmit}>
									<Form.Group>
										<Form.Label>Contraseña nueva</Form.Label>

										<InputGroup hasValidation>
											<InputGroup.Text onClick={showContrasenia} className="pointer">
												<FontAwesomeIcon icon={faEye} />
											</InputGroup.Text>

											<Form.Control
												type={showPasswords.contrasenia ? "text" : "password"}
												autoComplete="off"
												value={formValues.contrasenia}
												className={validacionContrasenia.contrasenia ? "is-valid " : ""}
												name="contrasenia"
												placeholder="Contraseña nueva"
												onChange={handleOnChangePasswords}
												ref={inputContrasenia}
											/>

											<Form.Control.Feedback type="invalid">Genera una contraseña v&aacute;lida</Form.Control.Feedback>

											<CapsLockTooltip input={inputContrasenia} isCapsLockOn={isCapsLockOn} />
										</InputGroup>

										<Form.Text id="passwordHelpBlock" muted>
											La contraseña debe contener:
											<ul>
												<li className={validacionContrasenia.caracteresContrasenia ? "text-success" : "text-danger"}>8 caracteres</li>
												<li className={validacionContrasenia.mayusculaContrasenia ? "text-success" : "text-danger"}>1 mayúscula</li>
												<li className={validacionContrasenia.numeroContrasenia ? "text-success" : "text-danger"}>1 número</li>
												<li className={validacionContrasenia.confirmacionContrasenia ? "text-success" : "text-danger"}>Las contraseñas coinciden</li>
											</ul>
											{/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
										</Form.Text>
									</Form.Group>

									<Form.Group>
										<Form.Label>Confirmar contraseña</Form.Label>

										<InputGroup hasValidation>
											<InputGroup.Text id="inputGroupPrepend2" onClick={showConfirmacionContrasenia} className="pointer">
												<FontAwesomeIcon icon={faEye} />
											</InputGroup.Text>

											<Form.Control
												onChange={handleOnChangePasswords}
												value={formValues.confirmacionContrasenia}
												type={showPasswords.confirmacionContrasenia ? "text" : "password"}
												autoComplete="off"
												name="confirmacionContrasenia"
												placeholder="Confirmar nueva contraseña"
												className={validacionContrasenia.confirmacionContrasenia ? "is-valid" : "is-invalid"}
											/>

											{/* <Form.Control.Feedback type="invalid">Las contraseñas deben de ser iguales</Form.Control.Feedback>
											<Form.Control.Feedback type="valid">Las contraseñas son iguales</Form.Control.Feedback> */}
										</InputGroup>
									</Form.Group>

									<div className="d-grid gap-2 mt-3">
										<Button variant="success" type="submit" size="lg">
											Cambiar contraseña { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null }
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
					</>
					:
					<>
						<h5 className="text-center mt-3">Tu contraseña ha sido actualizada correctamente</h5>

						<Row className="my-4 mb-3 justify-content-center">
							<Col>
								<Typography gutterBottom variant='body2' className='text-center'>Ingresa con tu nombre de usuario y la contraseña que acabas de establecer.</Typography>
								
								<Image className='d-flex align' src={require('@assets/images/check.png')} width={150} />
							</Col>
						</Row>
					</>
				}
			</Card>
		</Container>
	);
};

export default FormularioRecuperacionContraseña;
