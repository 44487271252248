import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateNotificacion } from "@helpers/httpRequests/httpRequestsNotificaciones/httpRequestsNotificaciones";

export const EliminarNotificacion = ({
  setNotificacionEliminada,
  notificacion,
}) => {
  const handleDeleteNotificacion = async () => {
    try {
      const notificacionEliminada = await deactivateNotificacion(
        notificacion.idNotificacion
      );

      console.log(notificacionEliminada);

      Notificacion("success", "Notificación");

      setNotificacionEliminada(true);
    } catch (error) {
      console.log("Error al eliminar la notificación: ", error);
    }
  };

  const cancelarDeletePregunta = () => {
    Notificacion("info", "No se desactivó la notificación");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar la notificación seleccionada?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleDeleteNotificacion}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeletePregunta}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
