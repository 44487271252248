import getTimeInMinutes from "@helpers/timer/getTimeInMinutes";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

export const Cronometro = ({ inicio, obtenerTiempoTranscurrido }) => {
	const [tiempo, setTiempo] = useState('00:00:00');

	const [segundos, setSegundos] = useState(0);
	
	useEffect(() => {
		const clockerInterval = setInterval(function () {
			setTiempo(getTimeInMinutes(inicio.getTime() / 1000, Math.round(new Date().getTime() / 1000)));

			setSegundos((seg) => seg + 1);
		}, 1000);

		setTimeout(() => {
			clearInterval(clockerInterval);
		}, 600000);
	}, [inicio]);

	useEffect(() => {
		console.log('tiempo: ', tiempo);

		if (tiempo !== '00:00:00') {
			console.log('segundos: ', segundos);

			obtenerTiempoTranscurrido(segundos);
		}
	}, [tiempo]);

	return <Typography variant="h5" gutterBottom>{tiempo}</Typography>;
};
