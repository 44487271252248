import React from "react";
import { Col, Row } from "react-bootstrap";
import TotalAPagarDescuentoList from "./lists/TotalAPagarDescuentoList";
import TotalAPagarList from "./lists/TotalAPagarList";

const TotalAPagar = (props) => {
	const { codigoAplicado, codigoDescuento, descuentoAplicado, descuentoNoAplicado } = props;

	return (
		<Col>
			<h3>Opciones de pago:</h3>

			<Row>
				<TotalAPagarList contado={descuentoNoAplicado.contado} credito={descuentoNoAplicado.credito} msi={descuentoNoAplicado.msi} codigoAplicado={codigoAplicado} />
			</Row>

			{codigoAplicado ? (
				<>
					<h3 className={"descuento-element animate__animated animate__fadeIn"}>Con descuento:</h3>

					<Row className={"descuento-element animate__animated animate__fadeIn"}>
						<TotalAPagarDescuentoList descuentoAplicado={descuentoAplicado} codigoDescuento={codigoDescuento} />
					</Row>
				</>
			) : null}
		</Col>
	);
};

export default TotalAPagar;
