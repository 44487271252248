import "@assets/css/components/DashboardSecciones/style.css";

import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AgregarSeccion } from "./Modales/AgregarSeccion/AgregarSeccion";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import TableSecciones from "./DataTables/TableSecciones";

const DashboardSecciones = () => {
  const handleAgregarSeccion = async (e) => {
    try {
      await ModalSweetAlert({
        title: "Agregar nueva sección",
        width: 600,
        html: <AgregarSeccion setSeccionAgregada={setSeccionAgregada} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar una nueva sección: ", error);
    }
  };

  const [seccionAgregada, setSeccionAgregada] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col align="right">
          <Button variant="primary" onClick={handleAgregarSeccion}>
            Agregar sección
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TableSecciones
            seccionAgregada={seccionAgregada}
            setSeccionAgregada={setSeccionAgregada}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardSecciones;
