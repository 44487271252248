import React, { useEffect } from 'react'
import {liberarFicha, validarCreditoUsuario} from "@helpers/httpRequests/httpRequestsSalaEntretenimiento/httpRequestsSalaEntretenimiento";
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';

/**
 * Se consulta el registro del usuario en la base de datos, en caso de que esté se valida la diferencia de minutos entre la fecha registrada y la actual, si pasa de los 60 minutos retorna true en caso que no un false
 */
const validarCredito = async(idPerfil) => {
    try {
        const tieneCredito = await validarCreditoUsuario(idPerfil);
        // console.log(tieneCredito)

        const {fechaRegistro} = tieneCredito.data;

        const fecha1 = fechaRegistro * 1000;

        const fecha2 = getUnixTimestamp() * 1000;

        const diferenciaEnMilisegundos = Math.abs(fecha1 - fecha2);

        const diferenciaEnMinutos = Math.floor(diferenciaEnMilisegundos / 60000)

        if(diferenciaEnMinutos >= 60){
            await liberarFicha(idPerfil)

            return true;

        }else{
            return false;
        }

    } catch (error) {
        console.log(error.message)
        return true;
    }
}

export default validarCredito