 //Arreglo de opciones para herramientas
export const herramientas = [
    {
        nombre: "Generador de texto",
        descripcion: "Genera texto de apoyo para solucionar dudas o complementar tus proyectos y actividades.",
        fondo: "texto.png",
        id: "chat-ai"
    },
    {
        nombre: "Asistente de Visual Basic",
        descripcion: "Genera texto de apoyo para solucionar dudas relacionadas con Visual Basic.",
        fondo: "asistenteVisualBasic.png",
        id: "chat-ai-visual"
    },
    {
        nombre: "Generador de imagenes",
        descripcion: "Genera imagenes a partir de texto con la ayuda de la herramienta 'DeepAI'",
        fondo: "foto.png",
        id: "images-generator"
    },
    
    {
        nombre: "Texto a voz",
        descripcion: "Convierte texto en voz para tus proyectos de trabajo y/o personales.",
        fondo: "voz.png",
        id: "text-speech"
    },
    {
        nombre: "Próximamente",
        descripcion: "Estamos trabajando para brindarte más herramientas útiles que te ayuden durante tu aprendizaje.",
        fondo: "video.png",
        id: "video-generator"
    },
]