import { Col, Container, Row, Table } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import UserContext from "@context/User/UserContext";

const TabNotificaciones = () => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const [notificaciones, setNotificaciones] = useState([]);

  useEffect(() => {
    const consultarNotificaciones = async () => {
      try {
        const { notificacionesUsuarioDisponibles: notificaciones } =
          contextValue;

        setNotificaciones(notificaciones);
      } catch (error) {
        console.log(
          "Ocurrió un error al consultar las notificaciones: ",
          error
        );

        Notificacion(
          "error",
          "Algo sucedió al cargar tus notificaciones, por favor recarga la página."
        );
      }
    };

    consultarNotificaciones();
  }, []);

  return (
    <Container fluid>
      <h3>¿Qué hay de nuevo?</h3>

      <Row className="my-4">
        <Col>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <td>Título</td>
                <td>Descripción</td>
              </tr>
            </thead>

            <tbody>
              {notificaciones?.length > 0 ? (
                notificaciones.map((n, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <b>{n.titulo}</b>
                      </td>
                      <td>{n.descripcion}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No cuentas con notificaciones</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default TabNotificaciones;
