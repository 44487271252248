import ExcelJS from "exceljs";

const exportToExcel = (data, nombreArchivo) => {
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Resultados");

  // Agregar encabezados de columnas
  worksheet.columns = [{ header: "Código", key: "codigo" }];

  // Agregar filas de datos
  data.forEach((item) => worksheet.addRow({ codigo: item }));

  // Guardar el archivo Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    const excelData = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const excelUrl = URL.createObjectURL(excelData);

    const link = document.createElement("a");

    link.href = excelUrl;

    link.download = nombreArchivo + ".xlsx";

    link.click();
  });
};

export default exportToExcel;
