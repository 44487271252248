import useWindowResize from "@customHooks/useWindowResize";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { formatPhoneNumber } from "@helpers/strings/formatPhoneNumber/formatPhoneNumber";
import { emailValidator, phoneNumberValidator } from "@helpers/validaciones/validaciones";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, FloatingLabel, Form, Row, useAccordionButton } from "react-bootstrap";

const AccordionDatosPersonales = ({ formValues, setFormValues }) => {

	const nextEventKey = "2";

	const decoratedOnClick = useAccordionButton(nextEventKey);

	const { size: { width } } = useWindowResize();

	const [ numeroTelefonoValido, setNumeroTelefonoValido ] = useState(false);

	const [ emailValido, setEmailValido ] = useState(false);
 
	const { nombre, apellidoPaterno, apellidoMaterno, numeroTelefono, correo, edad } = formValues;

	const estadosCiviles = ["SOLTERO(A)", "COMPROMETIDO(A)", "CASADO(A)", "VIUDO(A)", "DIVORCIADO(A)", "UNION LIBRE"];

	const handleOnChange = ({ target }) => {
		let auxValue = target.value;

		if (target.name === 'edad') {
			auxValue = target.value.replace(/\D/g, "");
		} else if (target.name === 'numeroTelefono') {
			auxValue = formatPhoneNumber(target.value)
		} else if (target.name !== 'correo' && target.name !== 'estadoCivil' && target.name !== 'edad' && target.name !== 'numeroTelefono') {
			auxValue = capitalizeString(target.value);
		}

		setFormValues({
			...formValues,
			[target.name]: auxValue,
		});
	};

	useEffect(() => {
		const valido = emailValidator(correo);

		setEmailValido(valido);
	}, [ correo ]);


	useEffect(() => {
		const valido = phoneNumberValidator(numeroTelefono);

		setNumeroTelefonoValido(valido);
	}, [ numeroTelefono ]);

	return (
		<Accordion.Item eventKey="1">
			<Accordion.Header>
				<Typography variant="button">Datos Personales</Typography>
			</Accordion.Header>

			<Accordion.Body>
				<Container fluid>
					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label="Nombre(s)" className="mb-3">
								<Form.Control type="text" placeholder="Nombre(s)" name="nombre" onChange={handleOnChange} value={nombre} />
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label={ width > 992 && width < 1200 ? "Ap. Paterno" : "Apellido paterno" } className="mb-3">
								<Form.Control type="text" placeholder="Apellido paterno" name="apellidoPaterno" onChange={handleOnChange} value={apellidoPaterno} />
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label={ width > 992 && width < 1200 ? "Ap. Materno" : "Apellido materno" } className="mb-3">
								<Form.Control type="text" placeholder="Apellido materno" name="apellidoMaterno" onChange={handleOnChange} value={apellidoMaterno} />
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label={ width > 992 && width < 1200 ? "Teléfono" : "Número de teléfono" } className="mb-3">
								<Form.Control className={numeroTelefonoValido ? 'text-success' : 'text-danger'}  maxLength='10' type="text" placeholder="Número de teléfono" name="numeroTelefono" onChange={handleOnChange} value={numeroTelefono} />
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label={ width > 992 && width < 1200 ? "Correo" : "Correo electrónico" } className="mb-3">
								<Form.Control className={emailValido ? 'text-success' : 'text-danger'} type="text" placeholder="Correo electrónico" name="correo" onChange={handleOnChange} value={correo} />
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label="Edad" className="mb-3">
								<Form.Control type="text" placeholder="Edad" name="edad" onChange={handleOnChange} value={ edad } />
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label="Estado civil">
								<Form.Select aria-label="Estado civil" onChange={handleOnChange} name="estadoCivil">
									<option value="">Elige un estado civil</option>

									{estadosCiviles.map((estadoCivil, idx) => (
										<option key={idx} value={estadoCivil}>{estadoCivil}</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<div className="d-grid gap-2 mb-3 mt-3 mt-md-0">
								<Button className="btn-next-accordion" variant="success" type="button" size="lg" onClick={decoratedOnClick}>
									Siguiente <FontAwesomeIcon icon={faArrowRight} />
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default AccordionDatosPersonales;
