const getLocalStorage = (key, initialValue) => {
  try {
    const value = localStorage.getItem(key);

    return value ? JSON.parse(value) : initialValue;
  } catch (error) {
    return initialValue;
  }
};

export default getLocalStorage;
