import React, { useEffect, useState } from "react";
import TotalAPagarListItem from "./items/TotalAPagarListItem";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";

const TotalAPagarList = ({ precios, codigoAplicado }) => {

	const [contado, setContado] = useState({});

	const [msi, setMsi] = useState({});

	const [credito, setCredito] = useState({});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (precios !== undefined) {
			setContado(precios.contado);

			setMsi(precios.msi);

			setCredito(precios.credito);

			setLoading(false);
		}
	}, [precios]);
	
	return (
		!loading ?

		<ul id="listPrecios ms-5">
			<TotalAPagarListItem codigoAplicado={codigoAplicado} content={<>De contado: $ {numberCommaSeparator(contado.monto)} MXN</>} isCredito={false} />

			{
				msi.planes.length > 0 ?
				<TotalAPagarListItem
					codigoAplicado={codigoAplicado}
					content={
						<>
							De&nbsp;
							<strong>{msi.planes[0]}</strong>&nbsp;a&nbsp;
							<strong>{msi.planes[msi.planes.length - 1]}</strong>&nbsp; Meses sin intereses: $ {numberCommaSeparator(msi.monto)} MXN
						</>
					}
					isCredito={false}
				/>
				: null
			}

			<TotalAPagarListItem
				codigoAplicado={codigoAplicado}
				content={{
					li: `A Crédito: $ ${numberCommaSeparator(credito.precioTotal)} MXN`,
					ul: (
						<ul id="listDetallePreciosCredito">
							<li className="orange-gc">
								<span className={codigoAplicado ? "strike" : ""}>
									<strong>{credito.totalPagosQuincenales}</strong>
									&nbsp;Pagos quincenales: $ {numberCommaSeparator(credito.pagoQuincenal)} MXN
								</span>
							</li>

							<li className="orange-gc">
								<span className={codigoAplicado ? "strike" : ""}>Enganche: $ {numberCommaSeparator(credito.pagoInicial)} MXN</span>
							</li>
						</ul>
					),
				}}
				isCredito={true}
			/>
		</ul>
		: null
	);
};

export default TotalAPagarList;
