import NavbarCertificado from "./components/NavbarCertificado";
import TemplateCertificado from "./components/TemplateCertificado";

const Certificado = () => {
  return (
    <>
      <NavbarCertificado />
      <TemplateCertificado />
    </>
  );
};

export default Certificado;
