import { Alert } from '@components/general/Alertas/Alertas';
import TitleDashboard from '@components/general/Titles/TitleDashboard';
import UserContext from '@context/User/UserContext';
import { confirmacionSolicitudEstadoCuenta, solicitudEstadoDeCuenta } from '@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers';
import { CheckCircleOutline } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Row, Spinner, Tab, Tabs } from "react-bootstrap";

const EdoCuenta = () => {

    const { contextValue, setContextValue } = useContext(UserContext);

    const {usuarioLogeado} = contextValue;

	const { servicioActivo, correo, folioContrato, nombreCompleto, apellidos, numeroTelefono } = usuarioLogeado;

	const [showLoader, setShowLoader] = useState(false);

	const [camposNecesarios, setCamposNecesarios] = useState(false);

    const solicitarEdoCuenta = async() => {
		try {
			setShowLoader(true);
			if(!camposNecesarios){
				const hacerSolicitud = await solicitudEstadoDeCuenta({
					nombreCompleto,
					apellidos,
					correo,
					folioContrato,
					numeroTelefono,
				});

				const hacerConfirmacion = await confirmacionSolicitudEstadoCuenta({
					nombreCompleto,
					apellidos,
					folioContrato,
					correo,
					numeroTelefono,
				});

				Alert("Estado de cuenta solicitado", "success", `Se ha enviado la solicitud de tu estado de cuenta, en un lapso de 48 horas hábiles estará llegando al correo ${correo}`)

				setShowLoader(false);
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if(nombreCompleto === "" || apellidos === "" || correo === "" || numeroTelefono === ""){
			setCamposNecesarios(true);
		}
	}, [])

  return (
    <Container fluid className='py-3'>
				<Row>
					<Col>
						<TitleDashboard title="Estado de cuenta" />
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<h2>Para solicitar tu estado de cuenta sigue los siguientes pasos:</h2>
					</Col>
					<Row>
						<Col>
							<ul>
								<li>Verifíca que tu información sea correcta antes de enviar tu solicitud.</li>
								<li>Tu estado de cuenta estará llegando en un lapso de 48 horas hábiles a la dirección de correo electrónico ingresado</li>
							</ul>
						</Col>
					</Row>

					<Row>
						<Col>
							<h3>Confirma tu información antes de enviar tu solicitud:</h3>

							<span style={{fontWeight: 'bold', fontSize: 18}}>Nombre completo:</span>
							<p style={{color: nombreCompleto === "" ? "red" : "#000"}}>{(nombreCompleto === "" || apellidos === "") ? "Es nesesario agregar nombre y apellidos" : `${nombreCompleto} ${apellidos}`}</p>
							<span style={{fontWeight: 'bold', fontSize: 18}}>Folio de contrato:</span>
							<p>{folioContrato}</p>
							<span style={{fontWeight: 'bold', fontSize: 18}}>Correo electrónico:</span>
							<p style={{color: correo === "" ? "red" : "#000"}}>{correo === "" ? "Es necesario adjuntar una dirección de correo electrónico a la solicitud" : correo}</p>
							<span style={{fontWeight: 'bold', fontSize: 18}}>Número de teléfono:</span>
							<p style={{color: numeroTelefono === "" ? "red" : "#000"}}>{numeroTelefono === "" ? "Es necesario registrar un número de telefono para poder continuar con la solicitud" : numeroTelefono}</p>

						</Col>
					</Row>

                    <p className='text-dark' style={{fontWeight: 'bold'}}>Ingresa a 'Configuración' desde el menú desplegable para modificar tus datos.</p>

                    

					<Row className="mt-3">
						<Col>
							<Button onClick={solicitarEdoCuenta} disabled={camposNecesarios || showLoader}>{showLoader ? "Enviando solicitud" : "Solicitar estado de cuenta"} {showLoader && <Spinner size="sm"/>}	</Button>
						</Col>
					</Row>
				</Row>
			</Container>
  )
}

export default EdoCuenta