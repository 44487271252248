import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//
// estos componentes son las alertas de sweeat alert en componente para reducir las lineas de codigo a utilizar las cuales reciben como parametro
//el texto e icono , se pueden agregar las notificaciones personalizadas con campos dinamicos
export const Notificacion = (tipo, mensaje) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    customClass: {
      popup: "notificacion",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon: tipo,
    title: mensaje,
  });
};

export const Alert = (titulo, icon, message) => {
  Swal.fire({
    title: titulo,
    icon,
    text: message,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Entendido",
  });
};

export const NotificacionExtendida = (tipo, mensaje) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: mensaje.length > 20 ? 3500 : 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: tipo,
    title: mensaje,
  });
};

/**
 *
 * @param {*} mensajeExito
 * @param {*} si ¿Qué es sí? Hay que ser más específicos
 * @param {*} no ¿Qué es no? Hay que ser más específicos
 */
export const NotificacionSeguridad = (mensajeExito, si, no) => {
  Swal.fire({
    title: "Editar perfil?",
    position: "top-end",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Editar",
  }).then((result) => {
    try {
      if (result.isConfirmed) {
        si();
      } else {
        no();
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const ModalSweetAlert = (options, allowEscapeKey = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      let rol = '';
      let allowOutsideClick = false;

      if (localStorage.getItem('userContext') !== null) {
        const jsonAux = JSON.parse(localStorage.getItem('userContext'));

        if (jsonAux.usuarioLogeado) {
          rol = jsonAux.usuarioLogeado.rol;
        } 
      }
      
      if (rol === 'administrador') {
        allowEscapeKey = true;
        allowOutsideClick = true;
      }

      const basicSweetAlertOptions = {
        allowOutsideClick,
        allowEnterKey: false,
        allowEscapeKey,
        width: 950,
      };

      const mySwal = withReactContent(Swal);

      const { isConfirmed, isDismissed } = await mySwal.fire({
        ...basicSweetAlertOptions,
        ...options,
      });

      if (isDismissed) {
        resolve(false);
      }

      if (isConfirmed) {
        resolve(true);
      }
    } catch (error) {
      console.log('error al lanzar el swal: ', error);

      resolve(false);
    }
  });
};

export const AlertSimple = (tipoAlerta, mensaje) => {
  Swal.fire({
    title: mensaje,
    icon: tipoAlerta,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Entendido",
  });
};
