import UserContext from "@context/User/UserContext";
import { getLinkContrato } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import Loader from "@helpers/loader/loader";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";
import React, { useContext, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";

const CompraFinalizada = () => {
	const [showLoader, setShowLoader] = useState(true);

    const { contextValue, setContextValue } = useContext(UserContext);

    const { compra: { folioContrato, correo } } = contextValue;

	const [linkContrato, setLinkContrato] = useState('');

    const consultarLinkContrato = async () => {
        try {
            const linkContrato = await getLinkContrato({folioContrato, correo});
			
			setLinkContrato(linkContrato);

            setShowLoader(false);
        } catch (error) {
            console.log("Error al obtener y enviar el link de contrato: ", error);
        }
    };

    useEffect(() => {
		if (linkContrato === '') {
            consultarLinkContrato();
        }
    }, []);

	return showLoader ? (
		<Loader />
	) : (
		<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
			<Navbar variant="light" bg="dark">
				<Navbar.Brand href="#home">
					<Image fluid width="100" src={require("@assets/images/click.png")} alt="Logo Global Click" />
				</Navbar.Brand>
			</Navbar>

			<Container fluid className="py-30 bg-main-container">
				<Row>
					<Col>
						<Container fluid className="bg-light-dark">
							<Row className="text-white">
								<Col>
									<Container fluid className="p-0 p-lg my-3">
										<Row>
											<Col className="text-center">
												<ThumbUpSvg />
											</Col>
										</Row>

										<Row>
											<Col className="text-center">
												<Container>
													<Row>
														<Col>
															<h3 className="text-success text-uppercase">¡Gracias por tu compra!</h3>

															<p className="text-center">Tu proceso de pre-registro finalizó.</p>

															<p className="text-center">
																Recibirás un correo electrónico de <b>notificaciones@globalclickmexico.com</b> para completar tu registro en un lapso máximo de{" "}
																<b className="text-uppercase">un día hábil</b>.
															</p>

															<p className="text-center">
																El enlace de descarga de tu <b>Contrato Digital</b> fue enviado a <b>{correo}</b> o puedes descargarlo en el siguiente enlace
															</p>

															<p className="text-center">
																Recuerda revisar tu bandeja de entrada y tu bandeja de Spam. <br />
																Ya puedes cerrar esta página.
															</p>

															<br />

															<a className="btn btn-success" id="linkContrato" target="_blank" rel="noreferrer" href={linkContrato}>
																Descargar contrato
															</a>
														</Col>
													</Row>
												</Container>
											</Col>
										</Row>
									</Container>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		</Animated>
	);
};

export default CompraFinalizada;
