import {
  Notificacion,
  NotificacionExtendida,
} from "@components/general/Alertas/Alertas";
import { faLightbulb, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPreguntasByCodigoLeccion } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col, Alert } from "react-bootstrap";

const EvaluacionLeccion = ({
  idx,
  key,
  codigoLeccion,
  setShowEvaluacionLeccion,
  setShowBtnEvaluacion,
}) => {
  // console.log(codigoLeccion);

  //*STATE PARA RECUPERAR LAS PREGUNTAS DE LA LECCION Y ALMACENAR LAS RESPUESTAS
  const [preguntasEvaluacion, setPreguntasEvaluacion] = useState([]);

  const [objetoRespuestas, setObjetoRespuestas] = useState(null);

  const [respuestas, setRespuesas] = useState([]);

  //*State para el examen ya contestado
  const [examenContestado, setExamenContestado] = useState(false);

  const [respuestasExamen, setRespuestasExamen] = useState(["", "", ""]);
  // let respuestasExamen = [];

  const handleRespuestas = ({ target }) => {
    const { name, value } = target; //*name = Indice del arreglo donde se guardara la respuesta, value = valor de la respuesta seleccionada
    respuestasExamen[parseInt(name)] = value;
  };

  const handleRevisarExamen = () => {
    setExamenContestado(true);
  };

  const handleFinalizarEvalacion = () => {
    setShowEvaluacionLeccion(false);
    setShowBtnEvaluacion(true);
  };

  useEffect(() => {
    const consultarPreguntas = async () => {
      try {
        const { data, message } = await getPreguntasByCodigoLeccion(
          codigoLeccion
        );
        if (data.length === 0) {
          return Notificacion("info", message);
        }

        if (Array.isArray(data)) {
          setPreguntasEvaluacion(data);
        } else {
          setPreguntasEvaluacion([data]);
        }
      } catch (error) {
        console.log("Error al consultar las preguntas", error);
        setPreguntasEvaluacion([]);
        NotificacionExtendida(
          "error",
          "Algo sucedió al consultar las preguntas de la lección, recarga la página"
        );
      }
    };

    consultarPreguntas();
  }, []);

  useEffect(() => {
    const handleRespuestas = () => {
      const arregloObjetos = [];

      // console.log(preguntasEvaluacion)

      preguntasEvaluacion.map((pregunta, idx) => {
        arregloObjetos.push(pregunta.opcionesRespuesta);
        respuestas.push(pregunta.respuestaCorrecta);
      });

      for (let i = 0; i < arregloObjetos.length; i++) {
        const obj = JSON.parse(arregloObjetos[i]);
        obj.id = i + 1;
        arregloObjetos[i] = JSON.stringify(Object.assign({}, obj));
      }

      const valores = arregloObjetos.map((objeto) => {
        const parsedObjeto = JSON.parse(objeto);
        return Object.values(parsedObjeto);
      });

      setObjetoRespuestas(valores);
    };

    handleRespuestas();
  }, [preguntasEvaluacion]);

  return (
    <>
      <Container
        className="shadow p-4 mt-4"
        style={{ height: "auto", borderRadius: "10px" }}
      >
        <Row className="px-2">
          <h5>
            Refuerza lo aprendido{" "}
            <FontAwesomeIcon icon={faLightbulb} color={"orange"} />
          </h5>
        </Row>
        {preguntasEvaluacion.length > 0 && (
          <Row className="justify-content-center">
            <Col className="col-auto">
              <lord-icon
                src="https://cdn.lordicon.com/ckatldkn.json"
                trigger="loop"
                delay="2000"
                style={{ width: "200px", height: "150px" }}
              ></lord-icon>
            </Col>
          </Row>
        )}
        <Row>
          {preguntasEvaluacion.length > 0 ? (
            <>
              <Col>
                  {preguntasEvaluacion.map((pregunta, idPregunta) => (
                    //*-------------------------Listado de preguntas y respuestas------------------------
                    <div key={idPregunta}>
                      <Form.Label className="mt-4" key={idPregunta}>
                        <span>
                          {pregunta.idTemporal + 1} . {pregunta.pregunta}
                        </span>
                      </Form.Label>

                      {pregunta.imagenPregunta !== null && (
                        <Row className="justify-content-center mt-3 mb-4">
                          <Col className="col-auto">
                            <img
                              src={pregunta.imagenPregunta}
                              alt="img pregunta"
                            />
                          </Col>
                        </Row>
                      )}
                       <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                      {!examenContestado ? (

                        objetoRespuestas.map((objeto, idObjeto) =>
                          objeto.length === 4
                            ? objeto[3] === idPregunta + 1 &&
                              objetoRespuestas[idObjeto].map(
                                (respuesta, idRespuesta) =>
                                  idRespuesta !== 3 && (
                                  

                                    <FormControlLabel  className="mb-3 pointer"
                                    key={idRespuesta}
                                    value={respuesta}
                                    control={<Radio />}  
                                    label={respuesta}
                                    name={`${idObjeto}`}
                                    onChange={handleRespuestas} />
                                    
                                  )
                              )
                            : objeto[2] === idPregunta + 1 &&
                              objetoRespuestas[idObjeto].map(
                                (respuesta, idRespuesta) =>
                                  idRespuesta !== 2 && (
                                    <FormControlLabel  className="mb-3 pointer"
                                    key={idRespuesta}
                                    value={respuesta}
                                    control={<Radio />}  
                                    label={respuesta}
                                    name={`${idObjeto}`}
                                    onChange={handleRespuestas} />
                                  )
                              )
                        )
                      ) : (
                        //*-----------------------Respuestas del usuario en la evaluacion de leccion----------------------
                        <>
                          <Alert
                            variant={
                              respuestasExamen[idPregunta] ===
                              respuestas[idPregunta]
                                ? "success"
                                : "danger"
                            }
                          >
                            <small>
                              Tu respuesta: {respuestasExamen[idPregunta]}
                            </small>
                          </Alert>
                          {/* <Alert variant="success"><small>Respuesta correcta: {respuestas[idPregunta]}</small></Alert> */}
                        </>
                      )}
                      </RadioGroup>
                    </div>
                  ))}
                  {!examenContestado ? (
                    <div className="d-grid gap-2">
                      <Button
                      onClick={handleRevisarExamen}
                      className="mt-4"
                      block
                      variant="primary"
                      type="submit"
                    >
                      Revisar respuestas
                    </Button>
                    </div>

                  ) : (
                   <div className="d-grid gap-2">
                     <Button
                      onClick={handleFinalizarEvalacion}
                      className="mt-4"
                      block
                      variant="primary"
                      type="submit"
                    >
                      Terminar evaluación
                    </Button>
                   </div>
                  )}
                <small>
                  <b>
                    Esta evaluación es unicamente retroalimentación de la
                    lección, no tiene impacto en tu calificación final.
                  </b>
                </small>
              </Col>
            </>
          ) : (
            <>
              <Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
                <FontAwesomeIcon icon={faList} className="mb-3" size="4x" />
                {/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
                <h4>Esta lección no cuenta con una evaluación</h4>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default EvaluacionLeccion;
