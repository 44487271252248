import React from "react";

const SubtitleForoAyuda = ({ title }) => {
  return (
    <>
      {title ? <h4>{title}</h4> : <h3>Sin titulo</h3>}
      <div
        style={{ width: "65px", height: "3px", backgroundColor: "#ffc107" }}
      ></div>
    </>
  );
};

export default SubtitleForoAyuda;
