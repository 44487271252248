export const cursosIntereses = {
  // Ofimatica: ["EA16", "EI16", "EX21", "OU20", "PP16", "WO16"],
  Ofimatica: ["EA16", "EX21", "OU20", "PP23", "WO16"],
  Comunicación: ["CL23", "PNLB"],
  "Informática y Software": ["INTE", "WI10", "ZOOM", "HANG", "MSTE"],
  "Inteligencia en ventas": ["PB21", "PBIA"],
  "Finanzas y contabilidad": ["EX21", "EI16", "EA16", "PB21", "PBIA"],
  Marketing: ["WP21"],
  Diseño: ["PH18"],
  "Desarrollo Personal": ["CL23", "PNLB", "CR23", "EP24", "PV24", "RC24"],
  Programación: ["SCRA"],
  Idiomas: ["ING1", "ING2", "ING3"],
};
