import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";

const columnsUsuariosSuspendidos = [
	{
		header: "Folio contrato",
		accessorKey: "folioContrato",
		sortable: true,
		center: true,
		compact: true,
		width: "200px",
	},
	{
		header: "Nombre completo",
		accessorKey: "nombreCompleto",
		sortable: true,
		center: true,
		compact: true,
		width: "40px",
	},
	{
		header: "Apellidos",
		accessorKey: "apellidos",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
	},
	{
		header: "Nombre de usuario",
		accessorKey: "nombreUsuario",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
	},
	{
		header: "Fecha de registro",
		accessorKey: "fechaRegistro",
		sortable: true,
		center: true,
		compact: true,
		width: "350px",
		Cell: ({ cell }) => (
			<div className="text-center">
				<small>
					<b>{getDateFromUnix(cell.row.original.fechaRegistro)}</b>
				</small>
			</div>
		),
	},
	{
		header: "Fecha de expiración",
		accessorKey: "fechaExpiracion",
		sortable: true,
		center: true,
		compact: true,
		width: "100px",
		Cell: ({ cell }) => (
			<div className="text-center">
				<small>
					<b>{getDateFromUnix(cell.row.original.fechaExpiracion)}</b>
				</small>
			</div>
		),
	},
];

export default columnsUsuariosSuspendidos;
