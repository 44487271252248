import EditarTema from "@components/administrador/DashboardForoAyuda/Modales/EditarTema/EditarTema";
import EliminarComentario from "@components/administrador/DashboardForoAyuda/Modales/EliminarComentario/EliminarComentario";
import EliminarTema from "@components/administrador/DashboardForoAyuda/Modales/EliminarTema/EliminarTema";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { getReportes } from "@helpers/httpRequests/httpRequestsReportes/httpRequestsReportes";
import {
  getTemaByIdCategoria,
  getTemasByUsuario,
  getTemasComentados,
} from "@helpers/httpRequests/httpRequestsTemas/httpRequestsTemas";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import ReportarTema from "../Modales/ReportarTema";
import PaginationSections from "./PaginationSections";
import TabPaneMisPublicaciones from "./TabContainersTemas/TabPaneMisPublicaciones";
import TabPaneReportes from "./TabContainersTemas/TabPaneReportes";
import TabPaneTemasPorCategoria from "./TabContainersTemas/TabPaneTemasPorCategoria";
import TabPanePublicacionesSeguidas from "./TabContainersTemas/TabPanePublicacionesSeguidas";

const TabContainerTemas = ({
  categorias,
  temas,
  nombreUsuarioLogeado,
  alias,
  respuestas,
  handleNuevoTema,
  numeroLikes,
  comentarTema,
  rol,
  setComentarioEliminado,
  aliasLogeado,
  idPerfil,
}) => {
  const [temasCategoria, setTemasCategoria] = useState([]);

  const [categoriaActual, setCategoriaActual] = useState();

  //Paginacion temas por categoria

  const [currentPage, setCurrentPage] = useState(1);

  const [sectionsPerPage, setSectionsPerPage] = useState(4);

  const indexOfLastSection = currentPage * sectionsPerPage;

  const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

  const currentPaginationSections = temasCategoria.slice(
    indexOfFirstSection,
    indexOfLastSection
  );

  const numberPages = Math.ceil(temasCategoria.length / sectionsPerPage);

  //End paginacion de temas por categoria

  //States para eliminar el tema desde administrador

  const [temaEliminado, setTemaEliminado] = useState(false);

  const [temaModificado, setTemaModificado] = useState(false);

  //State para el envio de los reportes de los temas
  const [reporteEnviado, setReporteEnviado] = useState(false);

  const [reporteAtendido, setReporteAtendido] = useState(false);

  const [reporteComentarioAtendido, setReporteComentarioAtendido] =
    useState(false);

  //*State para detectar que categoria es la que se afecta y refrescarla dependiendo si es de mis publicaciones o de alguna otra categoria
  const [categoriaModificada, setCategoriaModificada] = useState(null);

  //* MODALES
  const handleEliminarTema = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;

    const idCategoria = currentTarget.name;

    ModalSweetAlert({
      title: "Eliminar tema",
      html: (
        <EliminarTema
          categoria={idCategoria}
          setCategoriaModificada={setCategoriaModificada}
          setTemaEliminado={setTemaEliminado}
          tema={currentPaginationSections[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleEliminarComentario = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    // console.log(rowIndex);
    ModalSweetAlert({
      title: "Eliminar comentario",
      html: (
        <EliminarComentario
          setComentarioEliminado={setComentarioEliminado}
          comentario={rowIndex}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleEditarTema = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    const idCategoria = currentTarget.name;

    ModalSweetAlert({
      title: "Modificar tema",
      html: (
        <EditarTema
          setCategoriaModificada={setCategoriaModificada}
          setTemaModificado={setTemaModificado}
          categoria={idCategoria}
          tema={currentPaginationSections[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const reportarTema = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "¿Por qué quieres reportar este tema?",
      icon: "error",
      html: (
        <ReportarTema
          setReporteEnviado={setReporteEnviado}
          tema={currentPaginationSections[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  };

  const handleSetTemas = async (idCategoria) => {
    try {
      setCategoriaActual(idCategoria);

      let data = [];

      switch (idCategoria) {
        case -1:
          const temasComentados = await getTemasComentados(idPerfil);

          data = temasComentados;
          break;

        case 0:
          const { data: temasUsuario } = await getTemasByUsuario(alias);

          data = temasUsuario;
          break;

        default:
          const { data: temasCategoria } = await getTemaByIdCategoria(
            idCategoria
          );

          data = temasCategoria;
          break;
      }

      if (!Array.isArray(data)) {
        data = [data];
      }

      setTemasCategoria(data);

      setCurrentPage(1);
    } catch (error) {
      console.log(error);

      setTemasCategoria([]);
    }
  };

  const obtenerReportes = async () => {
    try {
      const { data } = await getReportes();
      console.log(data);

      if (Array.isArray(data)) {
        setTemasCategoria(data);
      } else {
        setTemasCategoria([data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const obtenerTemasUsuarioLogeado = async () => {
      try {
        const { data } = await getTemasByUsuario(alias);

        // console.log(data);
        if (Array.isArray(data)) {
          setTemasCategoria(data);
        } else {
          setTemasCategoria([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerTemasUsuarioLogeado();
  }, []);

  // *Cuando se agrega un tema nuevo hay un refresh como en los comentarios para refrescar la info de los temas del usuario Logeado
  useEffect(() => {
    const refrescarTemasUsuario = async () => {
      try {
        let { data } = await getTemasByUsuario(alias);

        if (Array.isArray(data)) {
          setTemasCategoria(data);
        } else {
          setTemasCategoria([data]);
        }
      } catch (error) {
        console.log(error);

        setTemasCategoria([]);
      }
    };
    refrescarTemasUsuario();
  }, [temas]);

  useEffect(() => {
    if (temaEliminado || temaModificado) {
      const refrescarTemasUsuario = async () => {
        try {
          if (categoriaModificada === null) {
            let { data } = await getTemasByUsuario(alias);

            if (Array.isArray(data)) {
              setTemasCategoria(data);
            } else {
              setTemasCategoria([data]);
            }
          } else {
            let { data } = await getTemaByIdCategoria(categoriaModificada);

            if (Array.isArray(data)) {
              setTemasCategoria(data);
            } else {
              setTemasCategoria([data]);
            }
          }
        } catch (error) {
          console.log(error);
          setTemasCategoria([]);
        }
      };
      refrescarTemasUsuario();

      setTemaEliminado(false);
      setTemaModificado(false);
    }
  }, [temaEliminado, temaModificado, categoriaModificada]);

  useEffect(() => {
    if (reporteEnviado) {
      const obtenerReportes = async () => {
        try {
          const { data } = await getReportes();
          // console.log(data);

          if (Array.isArray(data)) {
            setTemasCategoria(data);
          } else {
            setTemasCategoria([data]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      obtenerReportes();
    }
  }, [setReporteEnviado]);

  useEffect(() => {
    if (reporteAtendido || reporteComentarioAtendido) {
      // console.log("Reporte atendido");
      const obtenerReportes = async () => {
        try {
          const { data } = await getReportes();
          // console.log(data);

          if (Array.isArray(data)) {
            setTemasCategoria(data);
          } else {
            setTemasCategoria([data]);
          }
        } catch (error) {
          console.log(error);
          setTemasCategoria([]);
        }
      };

      obtenerReportes();
      setReporteAtendido(false);
    }
  }, [reporteAtendido, reporteComentarioAtendido]);

  return (
    <Tab.Container id="opc" defaultActiveKey="misPublicaciones">
      <Container fluid>
        <Row className="mt-4">
          {/* //*NAVEGACION PARA NAVEGAR ENTRE TABPANES */}
          <Col lg={3} className="categorias listado-foroAyuda">
            <h5>Categorías</h5>

            <Nav
              variant="pills"
              className="flex-column nav-categorias"
              style={{ borderRadius: "10px" }}
            >
              {rol === "administrador" && (
                <Nav.Item>
                  <Nav.Link
                    className="link-categoria"
                    onClick={obtenerReportes}
                    eventKey={"reportesTemas"}
                  >
                    <h6>Reportes</h6>
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item className="publicaciones-foroAyuda">
                <Nav.Link
                  className="link-categoria"
                  onClick={() => {
                    handleSetTemas(0);
                  }}
                  eventKey={"misPublicaciones"}
                >
                  <h6> Mis publicaciones</h6>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="publicaciones-foroAyuda">
                <Nav.Link
                  className="link-categoria"
                  onClick={() => {
                    handleSetTemas(-1);
                  }}
                  eventKey={"publicacionesSeguidas"}
                >
                  <h6>Publicaciones comentadas</h6>
                </Nav.Link>
              </Nav.Item>

              {categorias.length > 0 ? (
                categorias.map((categoria, key) => (
                  <Nav.Item key={key}>
                    <Nav.Link
                      className="link-categoria"
                      onClick={() => {
                        handleSetTemas(categoria.idCategoria);
                      }}
                      key={categoria.idCategoria}
                      eventKey={categoria.nombre}
                    >
                      <h6>{categoria.nombre}</h6>
                    </Nav.Link>
                  </Nav.Item>
                ))
              ) : (
                <Nav.Item>
                  <Nav.Link className="link-categoria">Sin categorias</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Col>

          {/* //* TABPANES DONDE SE MUESTRAN LOS REPORTES, LAS PUBLICACIONES HECHAS Y LOS TEMAS DE CADA CATEGORIA*/}
          <Col lg={9}>
            <Tab.Content>
              <TabPaneReportes
                currentPaginationSections={currentPaginationSections}
                rol={rol}
                reporteComentarioAtendido={reporteComentarioAtendido}
                handleEliminarTema={handleEliminarTema}
                handleEliminarComentario={handleEliminarComentario}
                comentarTema={comentarTema}
                nombreUsuarioLogeado={nombreUsuarioLogeado}
                setTemasCategoria={setTemasCategoria}
                setCurrentPage={setCurrentPage}
                tabPane={"reportes"}
                setReporteAtendido={setReporteAtendido}
                setReporteComentarioAtendido={setReporteComentarioAtendido}
              />

              <TabPaneMisPublicaciones
                alias={alias}
                temasCategoria={temasCategoria}
                currentPaginationSections={currentPaginationSections}
                handleEliminarTema={handleEliminarTema}
                comentarTema={comentarTema}
                nombreUsuarioLogeado={nombreUsuarioLogeado}
                handleNuevoTema={handleNuevoTema}
                handleEditarTema={handleEditarTema}
                respuestas={respuestas}
                numeroLikes={numeroLikes}
                tabPane={"mis-publicaciones"}
              />

              <TabPanePublicacionesSeguidas
                alias={alias}
                currentPaginationSections={currentPaginationSections}
                respuestas={respuestas}
                numeroLikes={numeroLikes}
                comentarTema={comentarTema}
              />

              <TabPaneTemasPorCategoria
                categorias={categorias}
                currentPaginationSections={currentPaginationSections}
                handleEliminarTema={handleEliminarTema}
                nombreUsuarioLogeado={nombreUsuarioLogeado}
                reportarTema={reportarTema}
                respuestas={respuestas}
                numeroLikes={numeroLikes}
                comentarTema={comentarTema}
                rol={rol}
                tabPane={"temas"}
                aliasLogeado={alias}
              />
            </Tab.Content>

            {/* //* PAGINACION PRINCIPAL*/}

            {currentPaginationSections.length > 0 && (
              <PaginationSections
                numberPages={numberPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Tab.Container>
  );
};

export default TabContainerTemas;
