import { Accordion, Button, Card, Form } from "react-bootstrap";
import React, { useState } from "react";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { updateNotificacion } from "@helpers/httpRequests/httpRequestsNotificaciones/httpRequestsNotificaciones";

export const EditarNotificacion = ({
  notificacion,
  setNotificacionEditada,
}) => {
  const [formValues, setFormValues] = useState(notificacion);

  const handleOnChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const notificacionActualizada = await updateNotificacion(formValues);

      Notificacion("success", "Notificación actualizada correctamente");

      setNotificacionEditada(true);
    } catch (error) {
      console.log(
        "Error al guardar los nuevos datos de la notificacion: ",
        error
      );
    }
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form className="text-start" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Nombre de la notificación</Form.Label>

                <Form.Control
                  name="titulo"
                  value={formValues.titulo}
                  onChange={handleOnChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Descripción</Form.Label>

                <Form.Control
                  as="textarea"
                  name="descripcion"
                  rows={3}
                  value={formValues.descripcion}
                  onChange={handleOnChange}
                />
              </Form.Group>

              <div className="d-grid gap-2 mt-4">
                <Button type="submit" variant="success" size="lg">
                  Guardar
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
