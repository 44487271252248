import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Container, Image, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap'
import TitleDashboard from '../Titles/TitleDashboard'
import DynamicBreadcrumb from '../DynamicBreadcrumb/DynamicBreadcrumb'
import ChatOpenAI from '../ChatOpenAI/ChatOpenAI'
import TextToImage from '../TextToImage/TextToImage'
import VideoGenerator from '../VideoGenerator/VideoGenerator'
import TextToVoice from '../TextToVoice/TextToVoice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import Joyride from 'react-joyride'
import spanishOptionsJoyRide from '@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide'
import { useRef } from 'react'
import { herramientas } from './HerramientasSuite'
import ChatVisualBasic from '../ChatVisualBasic/ChatVisualBasic'


const SuiteIA = ({ handleChangeComponent }) => {

    
    //*Tour del componente
    const joyrideRef2 = useRef(null);

    const [tourSteps2, setTourSteps] = useState([
        {
        target: ".suite-question",
        content: "¡Bienvenido a nuestra Suite de Inteligencia Artificial (IA)! Aquí, reunimos potentes herramientas diseñadas para potenciar tus proyectos con la última tecnología en IA. Esta suite ha sido creada con el objetivo de facilitar tu trabajo y llevar tus proyectos al siguiente nivel.",
        disableBeacon: true,
        },
        {
        target: ".suite-tools",
        content: "En esta sección, encontrarás un listado detallado de las herramientas disponibles. ",
        disableBeacon: true,
        },
        {
        target: ".suite-tools",
        content: "Cada herramienta ha sido cuidadosamente seleccionada para proporcionar funcionalidades únicas y eficientes en el ámbito de la inteligencia artificial. ",
        disableBeacon: true,
        },
        {
        target: ".suite-question",
        content: "Gracias por unirte a nosotros en este viaje hacia la excelencia impulsada por la inteligencia artificial. ¡Estamos emocionados de ser parte de tu proyecto y ayudarte a alcanzar tus metas de manera más eficiente!",
        disableBeacon: true,
        },


    ]);
    const [runTour, setRunTour] = useState(false);




    const [tool, setTool] = useState(<ChatOpenAI />)

    const [herramientaActual, setHerramientaActual] = useState("Generador de texto")


    const [esperarMinuto, setEsperarMinuto] = useState(false);   

    const handleTool = (herramienta, nombre) => {
        switch (herramienta) {
            case "chat-ai":
                setTool(<ChatOpenAI />)
                break;

            case "chat-ai-visual":
                setTool(<ChatVisualBasic />)
                break;

            case "images-generator":
                setTool(<TextToImage setEsperarMinuto={setEsperarMinuto}/>)
                break;

            case "video-generator":
                setTool(<VideoGenerator />)
                break;

            case "text-speech":
                setTool(<TextToVoice />)
                break;

            default:
                break;
        }

        setHerramientaActual(nombre);
    }

    const handleStartTour= () => {
        setRunTour(true);
    }

    useEffect(() => {
      
        if(esperarMinuto){
            console.log("Comienza a contar")
            setTimeout(() => {
                localStorage.setItem("tiempoEspera", false)

                setEsperarMinuto(false)
              }, 60000);
        }
    }, [esperarMinuto])
    

    return (
        <Container fluid className='mt-4 mb-4 suite-question'>

            <Joyride
                locale={spanishOptionsJoyRide}
                ref={joyrideRef2}
                steps={tourSteps2}
                run={runTour}
                hideCloseButton
                showSkipButton
                continuous={true}
                disableOverlayClose={false}
                disableCloseOnEsc={false}
                disableScrolling={false}
                scrollToFirstStep={false}
                scrollOffset={100}
                callback={(data) => {
                if (data.status === "finished" || data.status === "skipped") {
                    setRunTour(false);
                }

                }}
                styles={{
                options: {
                    primaryColor: "#ff9900",
                    textColor: "#00152A",
                    width: 900,
                    zIndex: 1000,
                },
                }}
            />

            <Row>
                <Col>
                    <DynamicBreadcrumb arrayBreadcrumbs={[{ title: "Suite IA", onclick: () => { } }, { title: herramientaActual, onClick: () => { } }]} />
                </Col>
            </Row>

            <Row className='justify-content-between'>
                <Col>
                    <TitleDashboard title={"Suite IA"} />
                </Col>

                {/* //TODO COMPLETAR EL TOUR PARA LA SUITE */}
                <Col className="col-auto text-center mx-3 ">
                    <OverlayTrigger
                        placement={"top"}
                        overlay={<Tooltip>Conoce la Suite IA</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                className=" pointer pomodoro-question"
                                onClick={handleStartTour}
                                style={{ fontSize: "2rem", color: "black" }}
                                icon={faQuestion}
                            />
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col className='suite-tools'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Herramientas disponibles</Accordion.Header>
                            <Accordion.Body>
                                <Row className="justify-content-center">
                                    {herramientas.map((herramienta, idx) => (
                                        <OverlayTrigger trigger="hover" placement="auto" overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Header as="h3">{herramienta.nombre}</Popover.Header>
                                                <Popover.Body>
                                                    {herramienta.descripcion}
                                                </Popover.Body>
                                            </Popover>
                                        }>
                                            <Col className='col-2 contenedor-cursos' style={{ height: "auto" }}>
                                                <div className='d-grid gap-2 mb-4'  >
                                                    <Image style={{ margin: "0 auto" }} onClick={() => { herramienta.id !== "video-generator" && handleTool(herramienta.id, herramienta.nombre) }} className={`rounded-3 ${herramienta.id !== "video-generator" && 'pointer'} shadow`} src={require(`@assets/images/${herramienta.fondo}`)} fluid width="75%" />
                                                </div>
                                            </Col>
                                        </OverlayTrigger>


                                    ))}

                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </Col>
            </Row>

            <Row className='mt-4' style={{height: "100vh"}}>


                <Col className='col-12'>
                    {tool}
                </Col>
            </Row>
        </Container>
    )
}

export default SuiteIA