import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

// export const reactivateEstudiante = (idPerfil) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const reactivatedStudent = await httpRequest({
// 				url: `${ endpointsPaths.usuarios}/${ httpRequestsPaths.usuarios.estudiantes.reactivateEstudiante }/${ idPerfil }`,
// 				method: "POST"
// 			});

// 			resolve(reactivatedStudent);
// 		} catch (error) {
// 			console.log("Error al reactivar el estudiante: ", error);

// 			reject(error);
// 		}
// 	});
// };

// export const deleteEstudianteById = (idPerfil) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const deletedStudent = await httpRequest({
// 				url: `${ endpointsPaths.usuarios}/${ httpRequestsPaths.usuarios.estudiantes.deleteEstudiante }/${ idPerfil }`,
// 				method: "POST"
// 			});

// 			resolve(deletedStudent);
// 		} catch (error) {
// 			console.log("Error al eliminar el estudiante: ", error);

// 			reject(error);
// 		}
// 	});
// };

export const getEstudiantes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: estudiantes } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.estudiantes.getEstudiantes}`,
        method: "POST",
      });

      resolve(estudiantes);
    } catch (error) {
      // console.log("Error al obtener a los estudiantes: ", error);

      reject(error);
    }
  });
};

export const getDatosSegundoUsuario = async (idPerfilSegundoUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const secondUserData = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.estudiantes.getSegundoPerfilById}/${idPerfilSegundoUsuario}`,
        method: "POST",
      });

      resolve(secondUserData);
    } catch (error) {
      reject(error);
    }
  });
};
