import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "@helpers/loader/loader";
import { Animated } from "react-animated-css";
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";

const CompraLinkPagoFinalizada = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false);
        }, 2000);
    }, []);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
					<Navbar variant="light" bg="dark">
						<Navbar.Brand href="#home">
							<Image fluid width="100" src={require("@assets/images/click.png")} alt="Logo Global Click" />
						</Navbar.Brand>
					</Navbar>

					<Container fluid className="py-30 bg-main-container">
						<Row>
							<Col>
								<Container fluid className="bg-light-dark">
									<Row className="text-white">
										<Col>
											<Container fluid className="p-0 p-lg my-3">
												<Row>
													<Col className="text-center">
														<ThumbUpSvg />
													</Col>
												</Row>

												<Row>
													<Col className="text-center">
														<Container>
															<Row>
																<Col>
																	<h3 className="text-success text-uppercase">¡Gracias por tu pago!</h3>

																	<p className="text-center">Tu pago ha sido recibido satisfactoriamente.</p>
																</Col>
															</Row>
														</Container>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Animated>
			)}
		</>
	);
};

export default CompraLinkPagoFinalizada;
