const columns = [
  {
    name: "Pregunta", //nombre de la columna
    selector: (row) => row.pregunta,
    sortable: true,
  },
  {
    name: "Respuesta",
    selector: (row) => row.respuestaCorrecta,
    sortable: true,
    center: true,
  },
  {
    name: "Nombre | Código de lección", //nombre de la columna
    selector: (row) => row.codigoLeccion, //identificador de la columna
    sortable: true,
    center: true,
  },
];

export default columns;
