import FormularioPago from "@components/usuario/RenovacionLicencia/FormularioPago";
import ValidacionFolio from "@components/usuario/RenovacionLicencia/ValidacionFolio";
import Loader from "@helpers/loader/loader";
import React, { useEffect, useState } from "react";

const RenovacionLicencia = () => {
	const [showLoader, setShowLoader] = useState(true);

	const [showCheckout, setShowCheckout] = useState(true);
	
	useEffect(() => {
		setTimeout(() => {
			setShowLoader(false);
		}, 1800);
	}, []);

	return (
		<>
			{showLoader && <Loader />}

			{!showLoader && (showCheckout ? <FormularioPago /> : <ValidacionFolio setShowCheckout={setShowCheckout} />)}
		</>
	);
};

export default RenovacionLicencia;
