import { faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUsuariosRanking } from "@helpers/httpRequests/httpRequestsRanking/httpRequestsRanking";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Panel } from "primereact/panel";
import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";

import ImageIcon from '@mui/icons-material/Image';

const RankingTopCurso = ({ codigoCurso }) => {
	const [usuariosRanking, setUsuariosRanking] = useState([]);

	//*FUNCION PARA EL COMPONENTE DE RANKING TOP
	const header = () => {
		return (
			<h5>
				<b>Ranking: Top 10 </b>
				<FontAwesomeIcon color="orange" icon={faStar} />
			</h5>
		);
	};

	useEffect(() => {
		const getRanking = async () => {
			try {
				const { data } = await getUsuariosRanking(codigoCurso);
				if (Array.isArray(data)) {
					setUsuariosRanking(data);
				} else {
					setUsuariosRanking([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};
		getRanking();
	}, []);

	useEffect(() => {
		const getRanking = async () => {
			try {
				const { data } = await getUsuariosRanking(codigoCurso);
				if (Array.isArray(data)) {
					setUsuariosRanking(data);
				} else {
					setUsuariosRanking([data]);
				}
			} catch (error) {
				console.log(error);
				setUsuariosRanking([]);
			}
		};
		getRanking();
	}, [codigoCurso]);

	return (
		<>
			<Row>
				<Col className="ranking-curso">
					<Card>
						<Card.Header>{header()}</Card.Header>

						<Card.Body>
							<div className="contenedor-ranking">
								{usuariosRanking.length > 0 ? (
									<List>
										{usuariosRanking.map(
											(usuarioRanking, idx) =>
												idx < 10 && (

                          <ListItem key={idx}>
                            <ListItemAvatar>
                              <Avatar />
                            </ListItemAvatar>

                            <ListItemText primary={usuarioRanking.nombreCompleto} secondary={`${ usuarioRanking.puntajeEvaluacion } puntos`} />
                          </ListItem>
												)
										)}
									</List>
								) : (
									// <Table striped borderless responsive size="sm">
									// 	<thead className="text-center">
									// 		<tr>
									// 			<th>#</th>
									// 			<th>Nombre</th>
									// 			<th>Puntaje</th>
									// 		</tr>
									// 	</thead>
									// 	<tbody className="text-center">
									// 		{usuariosRanking.map(
									// 			(usuarioRanking, idx) =>
									// 				idx < 10 && (
									// 					<tr key={idx}>
									// 						<td>{idx + 1} </td>
									// 						<td>{usuarioRanking.nombreCompleto}</td>
									// 						<td>{usuarioRanking.puntajeEvaluacion}</td>
									// 					</tr>
									// 				)
									// 		)}
									// 	</tbody>
									// </Table>
									<Row>
										<Col className="text-center mt-5" style={{ color: "grey" }}>
											<FontAwesomeIcon icon={faUser} className="mb-3" size="4x" />
											{/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
											<h6>No hay usuarios participando en el curso actual</h6>
										</Col>
									</Row>
								)}
							</div>
						</Card.Body>
					</Card>

					{/* <Panel className="shadow" header={header}>
						<div className="contenedor-ranking">
							{usuariosRanking.length > 0 ? (
								<Table striped borderless responsive size="sm">
									<thead className="text-center">
										<tr>
											<th>#</th>
											<th>Nombre</th>
											<th>Puntaje</th>
										</tr>
									</thead>
									<tbody className="text-center">
										{usuariosRanking.map(
											(usuarioRanking, idx) =>
												idx < 10 && (
													<tr key={idx}>
														<td>{idx + 1} </td>
														<td>{usuarioRanking.nombreCompleto}</td>
														<td>{usuarioRanking.puntajeEvaluacion}</td>
													</tr>
												)
										)}
									</tbody>
								</Table>
							) : (
								<Row>
									<Col className="text-center mt-5" style={{ color: "grey" }}>
										<FontAwesomeIcon icon={faUser} className="mb-3" size="4x" />
										<h6>No hay usuarios participando en el curso actual</h6>
									</Col>
								</Row>
							)}
						</div>
					</Panel> */}
				</Col>
			</Row>
		</>
	);
};

export default RankingTopCurso;
