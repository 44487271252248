import React, { forwardRef, useCallback, useContext, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from "@mui/material";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import { Animated } from "react-animated-css";
import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { getDatosFolio } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import UserContext from "@context/User/UserContext";

const particlesOptions = {
	fpsLimit: 60,
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
			},
		},
		color: {
			value: ["#ffcc00", "#757676", "#ff9900", "#00152A", "#f07f13"],
		},
		opacity: {
			value: { min: 0.1, max: 0.5 },
		},
		size: {
			value: { min: 1, max: 3 },
		},
		move: {
			enable: true,
			speed: 6,
			random: false,
		},
	},
	interactivity: {
		detectsOn: "window",
		events: {
			onClick: {
				enable: true,
				mode: "push",
			},
			resize: true,
		},
	},
	background: {
		image: "radial-gradient(#01488f, rgba(0, 21, 42, 1))",
	},
};

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ValidacionFolio = ({ setShowCheckout }) => {
    
    const { contextValue, setContextValue } = useContext(UserContext);

    const [open, setOpen] = useState(false);

    const [folioContrato, setFolioContrato] = useState("");

	const [propietarioFolio, setPropietarioFolio] = useState("");

	const particlesInit = useCallback(async (engine) => {
		await loadFull(engine);
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleShowCheckout = async () => {
		try {
            setShowCheckout(true);
		} catch (error) {
			console.log("error al mostrar checkout: ", error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (folioContrato === "") {
			return NotificationManager.error("Ingresa tu Folio de Contrato");
		}

		try {
			const datosFolio = await getDatosFolio(folioContrato);

			setPropietarioFolio(`${datosFolio.cliente.nombre} ${datosFolio.cliente.apellidoPaterno} ${datosFolio.cliente.apellidoMaterno}`);

            setContextValue({
                ...contextValue,
                renovacion: {
                    datosFolio
                }
            });

			handleClickOpen();
		} catch (error) {
			console.log("Error al consultar los datos del folio: ", error);
		}
	};

	return (
		<>
			<Particles options={particlesOptions} init={particlesInit} id="tsparticles" />

			<Animated animationIn="fadeIn" isVisible={true} className="vh-100">
				<div id="particles" className="h-100">
					<Container className="d-flex h-100 justify-content-center flex-column align-items-center">
						<Card className="w-50">
							<Card.Body className="h-100">
								<Row className="g-0">
									<Col className="d-flex flex-column align-items-center">
										<Image src={require("@assets/images/click.png")} className="w-50" />

										<Typography variant="overline">Bienvenido al módulo de Renovación</Typography>
										<br />
										<Typography variant="caption">Ingresa tu folio de contrato para iniciar</Typography>
									</Col>
								</Row>

								<hr />

								<Row>
									<Col xs={{ span: "6", offset: "3" }}>
										<Form onSubmit={handleSubmit}>
											<FloatingLabel label="Folio de contrato" className="mb-3">
												<Form.Control value={folioContrato} type="text" placeholder="Folio de contrato" name="folioContrato" onChange={({ target }) => setFolioContrato(target.value)} />
											</FloatingLabel>

											<div className="d-grid gap-2">
												<Button type="submit" variant="success">
													Renovar
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Container>
				</div>

				<Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
					<DialogTitle>Verifica la información</DialogTitle>

					<DialogContent>
						<DialogContentText>
							El folio ingresado está registrado a nombre de {propietarioFolio}.
							<br />
							¿Eres tú?
						</DialogContentText>
					</DialogContent>

					<DialogActions>
						<Button variant="secondary" onClick={handleClose}>
							No, cancelar
						</Button>

						<Button variant="success" onClick={handleShowCheckout}>
							Sí, continuar
						</Button>
					</DialogActions>
				</Dialog>
			</Animated>
		</>
	);
};

export default ValidacionFolio;
