import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "../Titles/TitleDashboard";
import { useEffect } from "react";
import {
  getPreguntasDiagnostico,
  getPreguntasDiagnosticoByCodigo,
} from "@helpers/httpRequests/httpRequestExamenDiagnostico/httpRequestsExamenDiagnostico";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUp,
  faChalkboardTeacher,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import RecomendacionesPrevias from "./componentes/RecomendacionesPrevias";
import TestDiagnostico from "./componentes/TestDiagnostico";
import VisorLecciones from "./componentes/ListaRetroalimentacion";
import { NotificacionExtendida } from "../Alertas/Alertas";
import { Animated } from "react-animated-css";
import { Link } from "react-scroll";
import SubtitleDashboard from "../Titles/SubtitleDashboard";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const ExamenDiagnostico = ({
  handleChangeComponent,
  handleScrollToTop,
  showScrollButton,
  handleScroll,
}) => {
  const videos = useRef(null);

  const [mostrarReproductor, setMostrarReproductor] = useState(false);

  const [currentLesson, setCurrentLesson] = useState({
    leccion: "",
    ultimaLeccion: false,
  });

  const [urlRutas, setUrlRutas] = useState({
    rutaCurso: "https://virtual.globalclickmexico.com/lecciones/sistemas",
    urlVideo: "",
    lecciones: [],
  });

  const iframeRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);

  function requestFullscreen() {
    iframeRef.current.requestFullscreen();
  }

  const [finalizarRuta, setFinalizarRuta] = useState(false);

  const [showBtnExamen, setShowBtnExamen] = useState(true);

  const handleLeccionActual = (leccion) => {
    // console.log(leccion);
    // console.log(urlRutas.lecciones);

    // if(leccion === urlRutas.lecciones[urlRutas.lecciones.length - 1].codigoLeccion){
    // 	// console.log("Es la ultima leccion");
    // 	setFinalizarRuta(true);
    // 	setCurrentLesson({
    // 		...currentLesson,
    // 		ultimaLeccion: true,
    // 	})
    // }else{
    // 	setFinalizarRuta(false);
    // }

    const codigoCurso = leccion.substring(0, 4);

    const urlVideo = urlRutas.rutaCurso + "/" + codigoCurso + "/" + leccion;

    setUrlRutas({
      ...urlRutas,
      urlVideo,
    });

    let indiceLeccionActual = "";

    urlRutas.lecciones.map((lesson, index) => {
      if (lesson.codigoLeccion === leccion) {
        indiceLeccionActual = index;
      }
    });

    setCurrentLesson({
      ...currentLesson,
      leccion,
      nombreLeccion: urlRutas.lecciones[indiceLeccionActual].nombre,
    });
  };

  const listarLecciones = ({ target }) => {
    setMostrarReproductor(true);

    handleScrollToTop();

    const { id } = target;

    //VALIDAR SI ESTA VACIO

    const lecciones = JSON.parse(id);

    if (lecciones.length > 0) {
      const codigoDeLeccion = lecciones[0].codigoLeccion;

      const codigoCurso = codigoDeLeccion.substring(0, 4);

      const urlPrimerVideo =
        urlRutas.rutaCurso + "/" + codigoCurso + "/" + codigoDeLeccion;

      setUrlRutas({
        ...urlRutas,
        lecciones,
        urlVideo: urlPrimerVideo,
      });
      let indicePrimeraLeccion = "";

      lecciones.map((lesson, index) => {
        if (lesson.codigoLeccion === lecciones[0].codigoLeccion) {
          indicePrimeraLeccion = index;
        }
      });

      if (lecciones.length === 1) {
        setFinalizarRuta(true);
      }

      setCurrentLesson({
        ...currentLesson,
        nombreLeccion: lecciones[indicePrimeraLeccion].nombre,
        leccion: codigoDeLeccion,
      });
    } else {
      NotificacionExtendida(
        "info",
        "Esta ruta aun no tiene lecciones para mostrar"
      );
    }
  };

  const handleFinalizarRuta = () => {
    handleScrollToTop();

    setUrlRutas({
      ...urlRutas,
      urlVideo: "",
      lecciones: [],
    });
    setFinalizarRuta(false);
    setMostrarReproductor(false);
  };

  const [preguntasDiagnostico, setPreguntasDiagnostico] = useState([]);

  const [respuestas, setRespuestas] = useState([]);

  const [objetoRespuestas, setObjetoRespuestas] = useState(null);

  const [respuestasExamen, setRespuestasExamen] = useState(
    new Array(preguntasDiagnostico.length).fill("undefined")
  );

  const [showTest, setShowTest] = useState(false);

  const [examenContestado, setExamenContestado] = useState(false);

  const [generarRetroalimentacion, setGenerarRetroalimentacion] =
    useState(false);

  const [showVisorLecciones, setShowVisorLecciones] = useState(false);

  //STATES PARA ALMACENAR LAS RESPUESTAS CORRECTAS DE LOS TEMAS

  const [funciones, setFunciones] = useState([]);

  const [analisisDatos, setAnalisisDatos] = useState([]);

  const [graficosExcel, setGraficosExcel] = useState([]);

  const [macros, setMacros] = useState([]);

  const [conocimientosGenerales, setConocimientosGenerales] = useState([]);

  const [graficos, setGraficos] = useState([]);

  const [transformarDatos, setTransformarDatos] = useState([]);

  const [relacionDatos, setRelacionDatos] = useState([]);

  const [funcionesDax, setFuncionesDax] = useState([]);

  const [codigoCursoSeleccionado, setCodfigoCursoSeleccionado] = useState(null);

  const handleShowTest = () => {
    setShowTest(true);
  };

  const handleRespuestas = ({ target }) => {
    const { name, value } = target;

    respuestasExamen[name] = value;
  };

  const handleRevisarTest = () => {
    handleScrollToTop();

    setExamenContestado(true);
  };

  const handleRetroalimentacion = () => {
    handleScrollToTop();

    //Se guardan en un arreglo vacio las diferencias entre el arreglo respuestas correctas y el de las respuestas del usuario
    let respuestasFinales = [];

    for (let i = 0; i < preguntasDiagnostico.length; i++) {
      if (respuestas[i] === respuestasExamen[i]) {
        respuestasFinales.push({
          tema: preguntasDiagnostico[i].tema,
          correcta: 1,
        });
      } else {
        respuestasFinales.push({
          tema: preguntasDiagnostico[i].tema,
          correcta: 0,
        });
      }
    }

    respuestasFinales.map((respuestaFinal) => {
      if (
        respuestaFinal.tema === "funciones" &&
        respuestaFinal.correcta === 1
      ) {
        funciones.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "análisis de datos" &&
        respuestaFinal.correcta === 1
      ) {
        analisisDatos.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "gráficos en excel" &&
        respuestaFinal.correcta === 1
      ) {
        graficosExcel.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "macros" &&
        respuestaFinal.correcta === 1
      ) {
        macros.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "conocimientos generales" &&
        respuestaFinal.correcta === 1
      ) {
        conocimientosGenerales.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "gráficos" &&
        respuestaFinal.correcta === 1
      ) {
        graficos.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "transformar datos" &&
        respuestaFinal.correcta === 1
      ) {
        transformarDatos.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "relación de datos" &&
        respuestaFinal.correcta === 1
      ) {
        relacionDatos.push(respuestaFinal.correcta);
      } else if (
        respuestaFinal.tema === "funciones dax" &&
        respuestaFinal.correcta === 1
      ) {
        funcionesDax.push(respuestaFinal.correcta);
      }
    });

    setGenerarRetroalimentacion(true);
  };

  const handleRegresar = () => {
    setShowTest(false);
    setShowBtnExamen(true);
  };

  const consultarPreguntas = ({ currentTarget }) => {
    const { id } = currentTarget;

    setCodfigoCursoSeleccionado(id);

    setShowBtnExamen(false);

    const preguntasExamen = async () => {
      try {
        const { data } = await getPreguntasDiagnosticoByCodigo(id);
        if (Array.isArray(data)) {
          setPreguntasDiagnostico(data);
        } else {
          setPreguntasDiagnostico([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    preguntasExamen();
  };

  useEffect(() => {}, []);

  useEffect(() => {
    const handleRespuestas = () => {
      const arregloObjetos = [];

      // console.log(preguntasEvaluacion)

      preguntasDiagnostico.map((pregunta, idx) => {
        arregloObjetos.push(pregunta.opcionesRespuesta);
        respuestas.push(pregunta.respuestaCorrecta);
      });

      for (let i = 0; i < arregloObjetos.length; i++) {
        const obj = JSON.parse(arregloObjetos[i]);
        obj.id = i + 1;
        arregloObjetos[i] = JSON.stringify(Object.assign({}, obj));
      }

      const valores = arregloObjetos.map((objeto) => {
        const parsedObjeto = JSON.parse(objeto);
        return Object.values(parsedObjeto);
      });

      setObjetoRespuestas(valores);
    };

    handleRespuestas();
  }, [preguntasDiagnostico]);

  useEffect(() => {
    const preguntasExamen = async () => {
      try {
        const { data } = await getPreguntasDiagnostico();
        if (Array.isArray(data)) {
          setPreguntasDiagnostico(data);
        } else {
          setPreguntasDiagnostico([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    preguntasExamen();
  }, [finalizarRuta, mostrarReproductor]);

  const breadcrumbItemsInitialState = [
    { title: "Examen Diagnóstico", onClick: () => {} },
  ];

  return (
    <Container fluid>
      {showScrollButton && (
        <Animated
          animationIn="fadeIn"
          isVisible={true}
          style={{ zIndex: "9999", position: "absolute" }}
        >
          <div className="scroll-top">
            <span
              className={`rounded-circle scrollTop text-center d-flex`}
              style={{
                height: "50px",
                width: "50px",
                fontSize: "2rem",
                backgroundColor: "#F59E0B",
                position: "fixed",
                bottom: "70px",
                right: "40px",
              }}
            >
              <Link
                className="pointer"
                activeClass="active"
                to="scrollTopElement"
                spy={true}
                smooth={true}
                duration={800}
                containerId="scrollTop"
                style={{ color: "black", width: "100%" }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Link>
            </span>
          </div>
        </Animated>
      )}
      <Row>
        <Col>
          <DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
        </Col>
      </Row>

      {showTest && !generarRetroalimentacion && (
        <Row className="mb-4">
          <Col className="pointer col-auto" onClick={handleRegresar}>
            <FontAwesomeIcon icon={faArrowLeft} /> Regresar
          </Col>
        </Row>
      )}

      {mostrarReproductor ? (
        <>
          {mostrarReproductor && (
            <Row className="mb-4">
              <Col
                className="pointer col-auto"
                onClick={() => setMostrarReproductor(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Regresar
              </Col>
            </Row>
          )}
          <Row className="mb-4 mt-4 justify-content-center visor" ref={videos}>
            <Col xl={8} className="mb-4 mb-md-0">
              {urlRutas.urlVideo !== "" && (
                <div className="encabezado-video">
                  <Row>
                    <Col>
                      <Row className="mb-3">
                        <Col className="text-capitalize">
                          <h4>{currentLesson.nombreLeccion}</h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div align={"end"} className="mb-3 overlay">
                    <OverlayTrigger
                      className="overlay-trigger"
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-bottom}`}>
                          Pantalla completa.
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-fullscreen"
                        onClick={requestFullscreen}
                        variant="secondary"
                      >
                        <FontAwesomeIcon icon={faExpand} />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  {/*//* IFRAME PARA EL VIDEO DE LA LECCION, CARDS DE LAS RUTAS DE APRENDIZAJE */}
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      loading="lazy"
                      ref={iframeRef}
                      title="Lección"
                      src={urlRutas.urlVideo}
                      width={"100%"}
                      height="500px"
                    ></iframe>
                  </div>
                </div>
              )}
            </Col>

            {urlRutas.lecciones.length > 0 && (
              <Col xl={4} className="lista-lecciones mt-5 mt-xl-0">
                {/* //*LISTA DE LECCIONES DE LA RUTA  */}

                <aside>
                  <Col>
                    <div>
                      <h5 className="mt-4">Lecciones de la ruta</h5>
                      <div
                        className={`mt-4 ${
                          urlRutas.lecciones.length > 0 &&
                          "rutas-aprendizaje contenedor-diagnostico"
                        }`}
                      >
                        {urlRutas.lecciones.length > 0 && (
                          <>
                            {urlRutas.lecciones.map((leccion, idx) => (
                              <Row key={idx}>
                                <Col className="d-flex align-items-center">
                                  <Button
                                    style={{
                                      background:
                                        leccion.codigoLeccion ===
                                        currentLesson.leccion
                                          ? "orange"
                                          : "none",
                                    }}
                                    onClick={() =>
                                      handleLeccionActual(leccion.codigoLeccion)
                                    }
                                    className="btn btn-light btn-block text-start p-3 mb-3"
                                  >
                                    <span
                                      style={{
                                        fontWeight:
                                          leccion.codigoLeccion ===
                                          currentLesson.leccion
                                            ? "bold"
                                            : "normal",
                                      }}
                                    >
                                      {leccion.nombre}
                                    </span>
                                  </Button>
                                  {/* <Checkbox id={idx+1} name={leccion.codigoLeccion } value={leccion.codigoLeccion} className="mx-3" onChange={handleLeccionVista} checked={(leccion.codigoLeccion in checkedCheckbox || leccionesVistas.some(leccionVista => leccionVista.codigoLeccion === leccion.codigoLeccion)) ? true : false}></Checkbox> */}
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </div>

                      <Button
                        variant={"success"}
                        onClick={handleFinalizarRuta}
                        className="finalizar-ruta mt-2 mb-2"
                        block
                      >
                        Finalizar retroalimentación
                      </Button>
                    </div>
                  </Col>
                  {/* 
									<div
										key={key}
										className={` ${
											currentLesson.leccion === leccion.codigoLeccion
												? `current ${leccion.codigoLeccion === urlRutas.lecciones[urlRutas.lecciones.length - 1].codigoLeccion && "done"}`
												: ""
										}`}
									>
										<b key={key} id={leccion.codigoLeccion} className="pointer leccion-lista" onClick={handleLeccionActual}>
											{leccion.nombre}
										</b>
									</div> */}
                </aside>
              </Col>
            )}
          </Row>
        </>
      ) : (
        <>
          {!showTest && (
            <Row>
              <Col>
                <TitleDashboard title="Examen Diagnóstico" />
              </Col>
            </Row>
          )}

          {showVisorLecciones ? (
            <VisorLecciones />
          ) : (
            <>
              {showTest ? (
                <TestDiagnostico
                  codigoCursoSeleccionado={codigoCursoSeleccionado}
                  handleScrollToTop={handleScrollToTop}
                  handleChangeComponent={handleChangeComponent}
                  funciones={funciones}
                  analisisDatos={analisisDatos}
                  graficosExcel={graficosExcel}
                  macros={macros}
                  conocimientosGenerales={conocimientosGenerales}
                  graficos={graficos}
                  transformarDatos={transformarDatos}
                  relacionDatos={relacionDatos}
                  funcionesDax={funcionesDax}
                  examenContestado={examenContestado}
                  objetoRespuestas={objetoRespuestas}
                  handleRespuestas={handleRespuestas}
                  generarRetroalimentacion={generarRetroalimentacion}
                  preguntasDiagnostico={preguntasDiagnostico}
                  respuestas={respuestas}
                  respuestasExamen={respuestasExamen}
                  handleRevisarTest={handleRevisarTest}
                  handleRetroalimentacion={handleRetroalimentacion}
                />
              ) : (
                <>
                  <Row className="mt-4">
                    <Col>
                      {/* <Alert variant="info">
												<p>
												Antes de comenzar es importante hacerte saber que esta evaluación es unicamente de diagnóstico y tu calificación no afecta en tu puntuación u avance futuro. <br/>Esta evaluación contiene temas de <b> Excel</b> y <b>Power BI.</b>
												</p>
											</Alert> */}

                      <Alert variant="info">
                        <p>
                          Por favor, selecciona el examen que desees realizar y
                          da click en "Comenzar examen"
                        </p>
                      </Alert>
                    </Col>

                    <Col className="col-12 mt-4 mb-4">
                      <SubtitleDashboard title={"Cursos disponibles:"} />
                      <Form>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            {/* <Form.Check
                              onClick={consultarPreguntas}
                              inline
                              label="Microsoft Excel"
                              name="group1"
                              type={type}
                              id="EX21"
                            /> */}

                            <FormControlLabel 
                              checked={codigoCursoSeleccionado === "EX21"}
                              onClick={consultarPreguntas}
                              label="Microsoft Excel"
                              name="group1"
                              id="EX21" control={<Radio />} />


                            {/* <Form.Check
                              onClick={consultarPreguntas}
                              inline
                              label="Power BI"
                              name="group1"
                              type={type}
                              id="PB21"
                            /> */}

                            <FormControlLabel 
                              checked={codigoCursoSeleccionado === "PB21"}
                              onClick={consultarPreguntas}
                              label="Power BI"
                              name="group1"
                              id="PB21" control={<Radio />} />
                          </div>
                        ))}
                        </RadioGroup>
                      </Form>
                    </Col>

                    <Col className="col-12 text-center">
                      <Button onClick={handleShowTest} disabled={showBtnExamen}>
                        Comenzar examen
                      </Button>
                    </Col>
                  </Row>

                  <RecomendacionesPrevias
                    listarLecciones={listarLecciones}
                    handleChangeComponent={handleChangeComponent}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ExamenDiagnostico;
