import React from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import _ from "lodash";
import { faBook, faComment, faGift, faGraduationCap, faLightbulb, faMicrochip, faRoute, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GridBeneficios = () => {
	const items = [
		{
			title: "Usuario Adicional",
			tooltipContent: "Recibirás 1 perfil de usuario adicional para que otra persona pueda utilizar la plataforma.",
			icon: faUserPlus,
		},
		{
			title: "Rutas de Aprendizaje",
			tooltipContent: "Personaliza tu lista de lecciones para que aprendas los temas de mayor peso curricular de tu interés.",
			icon: faRoute,
		},
		{
			title: "Consejos Rápidos",
			tooltipContent: "Accede a un listado de atajos para agilizar el uso de los programas y mejorar tus tiempos productivos.",
			icon: faLightbulb,
		},
		{
			title: "Foro de Ayuda",
			tooltipContent: "Resuelve dudas de forma colectiva. ¡Es como estar en un salón de clases!",
			icon: faComment,
		},
		{
			title: "Plantillas de Trabajo",
			tooltipContent: "Plantillas creadas para facilitar tu trabajo cotidiano.",
			icon: faBook,
		},
		{
			title: "Suite de Inteligencia Artificial",
			tooltipContent: "Complementa tu aprendizaje a través de las herramientas de última tecnología impulsadas con el uso de Inteligencia Artificial",
			icon: faMicrochip,
		},
		{
			title: "Examen de Certificación MOS",
			tooltipContent: "Obtén la oportunidad para respaldar tus conocimientos en Excel con la Certificación Microsoft Office Specialist.",
			icon: faGraduationCap,
		},
		{
			title: "Participa y Gana",
			tooltipContent: "Pon a prueba tus habilidades y obtén recompensas.",
			icon: faGift,
		},
	];

	const chunkSize = items.length > 1 ? Math.ceil(items.length / 2) : items.length;

	const chunks = _.chunk(items, chunkSize);

	return chunks.map((chunk, idxChunk) => (
		<Row className="py-2 " key={idxChunk}>
			{chunk.map(({ icon, title, tooltipContent }, idx) => (
				<OverlayTrigger
					key={idx}
					trigger={["hover", "focus"]}
					placement="top"
					overlay={
						<Popover>
							<Popover.Header as="h3" className="text-black">{title}</Popover.Header>

							<Popover.Body>{tooltipContent}</Popover.Body>
						</Popover>
					}>
					<Col>
						<h2 className="h1">
							<FontAwesomeIcon className="text-warning" icon={icon} />
						</h2>

						<h6 className="text-white">{title}</h6>
					</Col>
				</OverlayTrigger>
			))}
		</Row>
	));
};

export default GridBeneficios;
