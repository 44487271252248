import { Notificacion } from "@components/general/Alertas/Alertas";
import DatosFacturacion from "@components/usuario/MisPagos/components/facturacion/DatosFacturacion";
import UserContext from "@context/User/UserContext";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import { enviarCorreoFacturacion, retrievePaymentIntent } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import Loader from "@helpers/loader/loader";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";
import React, { useContext, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";

import "primereact/resources/themes/lara-light-blue/theme.css";
import { Alert, CssBaseline, ThemeProvider, Typography, createTheme } from "@mui/material";
import useWindowResize from "@customHooks/useWindowResize";

const CompraRealizada = ({ history }) => {
	const themeMui = createTheme({
		palette: {
			mode: "light",
		},
	});

	const { contextValue, setContextValue } = useContext(UserContext);

	const {
		compra: { folioContrato },
	} = contextValue;

	const [paymentIntent, setPaymentIntent] = useState({});

	const [paymentMethod, setPaymentMethod] = useState({});

	const [facturacionEnviada, setFacturacionEnviada] = useState(false);

	const [formValuesFacturacion, setFormValuesFacturacion] = useState({
		usoCfdi: "",
		claveCfdi: "",
		constanciaSituacionFiscalData: "",
		nombreCompleto: "",
		apellidos: "",
		correo: "",
	});

	const [showSpinner, setShowSpinner] = useState(false);

	const [showLoader, setShowLoader] = useState(true);

	const {
		size: { width },
	} = useWindowResize();

	const handleSolicitarFacturacion = async () => {
		setShowSpinner(true);

		let montoCobro = "",
			tipoTarjeta = "";

		const { amount } = paymentIntent;

		const {
			card: { funding },
		} = paymentMethod;

		montoCobro = amount / 100;

		tipoTarjeta = funding === "credit" ? "Tarjeta de crédito" : "Tarjeta de débito";

		await enviarCorreoFacturacion({
			pdfData: formValuesFacturacion.constanciaSituacionFiscalData,
			folioContrato: contextValue.compra.folioContrato,
			cfdi: {
				clave: formValuesFacturacion.claveCfdi,
				descripcion: formValuesFacturacion.usoCfdi,
			},
			metodoPago: tipoTarjeta,
			nombreCliente: `${formValuesFacturacion.nombreCompleto} ${formValuesFacturacion.apellidos}`,
			correoCliente: formValuesFacturacion.correo,
			montoCobro: numberCommaSeparator(montoCobro),
		});

		Notificacion("success", "Solicitud de facturación enviada con éxito");

		setFacturacionEnviada(true);

		setShowSpinner(false);
	};

	const handleGoToRegister = () => {
		history.replace(`${dashboardRoutesPaths.rawFormularioRegistroSai}/${folioContrato}`);
	};

	useEffect(() => {
		const validarExistenciaPago = async () => {
			try {
				const {
					compra: {
						stripeSession: { payment_intent },
					},
				} = contextValue;

				const { paymentIntent, paymentMethod } = await retrievePaymentIntent(payment_intent);

				setPaymentIntent(paymentIntent);

				setPaymentMethod(paymentMethod);

				// const { data } = await validarDatosPago(folioContrato);

				// const { pagosContrato } = data;

				// if (pagosContrato.find((pago) => pago.referenciaPago === paymentIntent.id) !== undefined) {
				// 	return history.replace(dashboardRoutesPaths.login);
				// }

				// setPagoRealizado(true);
			} catch (error) {
				console.log("Error al obtener el historial de pagos: ", error);
			}
		};

		validarExistenciaPago();
	}, []);

	useEffect(() => {
		// 	const registrarPagoSai = async () => {
		// 		try {
		// 			const { id, amount } = paymentIntent;

		// 			const { card: { last4, funding } } = paymentMethod;

		// 			const idMedioPago = funding === "credit" ? 7 : 9;

		// 			// await enviarComprobanteCompra({
		// 			// 	titular: `${nombreCompleto} ${apellidos}`,
		// 			// 	paymentIntentId: id,
		// 			// 	folioContrato,
		// 			// 	montoCobro: amount / 100,
		// 			// 	numeroTarjeta: `Tarjeta con terminación ${last4}`,
		// 			// 	description: `Abono a tu adeudo Click+ - Folio ${folioContrato}`,
		// 			// 	correo,
		// 			// });

		// 			setPagoRealizado(false);

		// 			setShowLoader(false);
		// 		} catch (error) {
		// 			console.log("error al validar el pago: ", error);
		// 		}
		// 	};

		// 	setPagoRealizado(false);
		// }

		setShowLoader(false);
	}, []);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<ThemeProvider theme={themeMui}>
					<CssBaseline />

					<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
						<div className="bg-main-container" style={{ overflow: "hidden", overflowY: "scroll" }}>
							<Navbar expand="lg" data-bs-theme="dark" bg="dark" className="bg-dark-custom">
								<Container fluid>
									<Navbar.Brand href="#">
										<div className="m-1">
											<img className="pointer" src={require(`@assets/images/logo-gc-horizontal.png`)} alt="Logo Global Click" style={{ width: `${width > 992 ? "10vw" : "20vw"}` }} />
											&nbsp;&nbsp;Compra Realizada - Plataforma Click+
										</div>
									</Navbar.Brand>
								</Container>
							</Navbar>

							<Container fluid className="py-30">
								<Row>
									<Col>
										<Container fluid className="bg-light-dark">
											<Row>
												<Col>
													<Container fluid className="p-0 p-lg my-3">
														<Row>
															<Col className="text-center">
																<ThumbUpSvg />
															</Col>
														</Row>

														<Row className="mt-2">
															<Col className="text-center">
																<Container>
																	<Row className="text-white">
																		<Col>
																			<h3 className="text-success text-uppercase">¡Gracias por tu compra!</h3>

																			<p className="text-center">Tu pago ha sido recibido satisfactoriamente.</p>

																			<p className="text-center">
																				Recibirás un correo electrónico de <b>notificaciones@globalclickmexico.com</b> con tu comprobante de pago.
																			</p>
																		</Col>
																	</Row>

																	{facturacionEnviada ?
																		<Row>
																			<Col xs={{ span: 6, offset: 3 }}>
																				<Alert severity="success">¡Tu solicitud de facturación ha sido enviada con éxito!</Alert>
																			</Col>
																		</Row>
																	: (
																		<>
																			<DatosFacturacion showExtraForm formValues={formValuesFacturacion} setFormValues={setFormValuesFacturacion} />

																			{formValuesFacturacion.facturacionSolicitada && (
																				<>
																					<div className="d-grid gap-2 my-4">
																						<Button variant="success" onClick={handleSolicitarFacturacion} disabled={facturacionEnviada} size="lg">
																							{ facturacionEnviada ? 'Solicitud enviada' : <>Solicitar facturación {showSpinner && <FontAwesomeIcon icon={faSpinner} spin />}</>}
																						</Button>
																					</div>

																					<hr />
																				</>
																			)}
																		</>
																	)}

																	<Row className='mt-3'>
																		<Col>
																			<Typography className='text-white' variant='h6'>Para iniciar tu registro en la plataforma, primero deberás llenar el siguiente formulario.</Typography>

																			<div className="d-grid gap-2 my-4">
																				<Button variant="primary" onClick={handleGoToRegister} className="mt-4">
																					Comenzar Registro
																				</Button>
																			</div>
																		</Col>
																	</Row>
																</Container>
															</Col>
														</Row>
													</Container>
												</Col>
											</Row>
										</Container>
									</Col>
								</Row>
							</Container>
						</div>
					</Animated>
				</ThemeProvider>
			)}
		</>
	);
};

export default CompraRealizada;
