import { Notificacion } from '@components/general/Alertas/Alertas';
import { SpinnerImage } from '@components/general/Spinners/SpinnerImage/SpinnerImage';
import { DropZone } from '@helpers/DropZone/DropZone';
import { addRecompensa } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import { Alert } from '@mui/material';
import Compressor from 'compressorjs';
import React, { useState } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { IoClose } from "react-icons/io5";

const AgregarRecompensa = ({ setTarjetaRegaloAgregada }) => {

    const [formValues, setFormValues] = useState({
        descripcion: "",
        valor: 0,
        imagenTarjeta: "",
    })

    const [faltaImagen, setFaltaImagen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { descripcion, valor, imagenTarjeta } = formValues;
       if(imagenTarjeta !== ""){
        try {
            console.log(formValues);
            //Guardar datos en la base de datos
            const { message } = await addRecompensa({
                descripcion,
                valor,
                imagenTarjeta
            })
            setTarjetaRegaloAgregada(true)
            Notificacion("success", "Recompensa agregada con éxito");
        } catch (error) {
            console.log(error)
        }
       }else{
        setFaltaImagen(true);
       }
    }

    const handleOnChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;

        setFormValues({
            ...formValues,
            [name]: value
        })
    }

    const getBase64Image = ({ target }) => {
        try {
            const { files } = target;

            const [file] = files;

            const fileReader = new FileReader();

            if (!file) {
                return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
            }

            //!codigo para comparacion de peso de imagen antes de ser comprimida
            fileReader.readAsDataURL(file);

            new Compressor(file, {
                quality: 0.2,
                success(result) {
                    fileReader.readAsDataURL(result);

                    fileReader.onload = async function () {
                        setFormValues({ ...formValues, imagenTarjeta: fileReader.result });

                    };
                },
                error(e) {
                    console.log(e);

                    Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
                },
            });
        } catch (e) {
            Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
        }
    };


    const limpiarImagen = () => {
        setFormValues({
            ...formValues,
            imagenTarjeta: ""
        })
    }


    return (
        <Container className='text-start'>
            <Row>
                <Col >
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className='mb-3'>
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control required onChange={handleOnChange} name='descripcion' value={formValues.descripcion} type='text' placeholder='Descripción de la recompensa'></Form.Control>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Valor de la recompensa</Form.Label>
                            <Form.Control required onChange={handleOnChange} name='valor' value={formValues.valor} type='number'></Form.Control>
                        </Form.Group>


                        {formValues.imagenTarjeta !== "" ? (
                            <Row className='text-center justify-content-center'>
                                <Col className='col-12'>
                                    <Image className='mb-3 rounded-4' width="240" src={formValues.imagenTarjeta} />
                                </Col>
                                <Col className='col-auto mb-3'>
                                    <h4><IoClose onClick={limpiarImagen} className='pointer' /></h4>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Imagen de la recompensa</Form.Label>
                                    <DropZone getImageInBase64={getBase64Image} />
                                </Form.Group>
                               {faltaImagen && (
                                 <Alert className='mb-4' severity='error'>Debes insertar una imagen para la tarjeta de regalo</Alert>
                               )}
                            </>
                        )}

                        <div className='d-grid gap-2'>
                            <Button type="submit">Agregar recompensa</Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default AgregarRecompensa