import { Button, FormControl, InputGroup } from "react-bootstrap";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import React, { useContext, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import getLocalStorage from "@helpers/localStorage/getLocalStorage";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import setLocalStorage from "@helpers/localStorage/setLocalStorage";
import UserContext from "@context/User/UserContext";

const filtrarData = (data, valorBuscado) => {
  const resultados = [];

  function buscarDentro(obj, valor) {
    if (obj === null) return false; // Validar si el objeto es null

    if (Array.isArray(obj)) {
      for (let item of obj) {
        if (buscarDentro(item, valor)) {
          return true;
        }
      }
    } else if (typeof obj === "object") {
      for (let clave in obj) {
        if (buscarDentro(obj[clave], valor)) {
          return true;
        }
      }
    } else {
      return obj.toString().includes(valor);
    }
    return false;
  }

  for (let item of data) {
    if (buscarDentro(item, valorBuscado)) {
      resultados.push(item);
    }
  }

  return resultados;
};

export const DataTablesRecipient = ({ data, columns }) => {

  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado: { modoOscuro } } = contextValue;

  const busquedaActiva = getLocalStorage(
    localStorageItemNames.busquedaUsuarioActiva,
    false
  );

  const dataBusquedaActiva = getLocalStorage(
    localStorageItemNames.busquedaUsuarioData,
    ""
  );

  const [search, setSearch] = useState(dataBusquedaActiva);

  const [filteredData, setFilteredData] = useState([]);

  const [dataRespaldo, setDataRespaldo] = useState([]);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const noRegisters = <h4>No se encontró ningún elemento</h4>;

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "larger",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  useEffect(() => {
    setFilteredData(data);

    setDataRespaldo(data);
  }, [data]);

  //*Se tiene error al usar el buscador ya que busca en una columna en especifico dentro del return, se debe cambiar para usarla en todas las tablas
  useEffect(() => {
    if (search === "") {
      setFilteredData(dataRespaldo);
    }

    handleSearch();
  }, [search]);

  const handleSearch = () => {
    setFilteredData(filtrarData(data, search));
  };

  useEffect(() => {
    if (busquedaActiva) {
      setSearch(dataBusquedaActiva);

      setLocalStorage(localStorageItemNames.busquedaUsuarioActiva, false);

      setLocalStorage(localStorageItemNames.busquedaUsuarioData, "");
    }
  }, []);

  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  });

  return (
    <DataTable
      theme={ modoOscuro ? "dark" : ""}
      customStyles={customStyles}
      columns={columns}
      data={filteredData}
      dense
      pagination
      paginationComponentOptions={paginationComponentOptions}
      fixedHeader
      fixedHeaderScrollHeight="600px"
      noDataComponent={noRegisters}
      subHeader
      subHeaderAlign="right"
      subHeaderComponent={
        <InputGroup className="w-50 mb-4">
          <FormControl
            className="input-form"
            placeholder="Buscar"
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            value={search}
          />

          <Button
            className="btn-responsive"
            variant="primary"
            onClick={handleSearch}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>
      }
    />
  );
};
