import { createCanvas } from "canvas";

const getPdfScreenshot = ({ filledPdfBytes }) => {
    return new Promise(async (resolve, reject) => {
        try {
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${window.pdfjsLib.version}/legacy/build/pdf.worker.min.js`;

            const pdfDocument = await window.pdfjsLib.getDocument({
				data: filledPdfBytes,
				cMapPacked: true,
				cMapUrl: 'pdfjs-dist/cmaps'
			}).promise;

            pdfDocument.getPage(1).then(async (page) => {
                const viewport = page.getViewport({ scale: 2.5 });

                const canvas = createCanvas(viewport.width, viewport.height);

                const context = canvas.getContext('2d');

                const renderTask = page.render({
                    canvasContext: context,
                    viewport
                }).promise;
                
                renderTask.then(() => resolve(canvas.toDataURL('image/png')));
            });
        } catch (error) {
            reject(error);
        }
    });
};

export default getPdfScreenshot;