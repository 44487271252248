import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TableAdministrador from "../../DataTables/Administrador/TableAdministrador";
import { AgregarAdministrador } from "../../Modales/Administrador/AgregarAdministrador/AgregarAdministrador";

const DashboardAdministradores = ({ isTabActive }) => {
  const handleAgregarAdministrador = async () => {
    try {
      await ModalSweetAlert({
        title: "Agregar administrador",
        html: <AgregarAdministrador setAdminAgregado={setAdminAgregado} />,
        showCloseButton: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("Error al agregar al empleado: ", error);
    }
  };

  const [adminAgregado, setAdminAgregado] = useState(false);

  return (
    isTabActive && (
      <Container fluid className="pt-4">
        <Row>
          <Col align="left">
            <Button variant="primary" onClick={handleAgregarAdministrador}>
              Agregar administrador
            </Button>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <TableAdministrador
              adminAgregado={adminAgregado}
              setAdminAgregado={setAdminAgregado}
            />
          </Col>
        </Row>
      </Container>
    )
  );
};

export default DashboardAdministradores;
