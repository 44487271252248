import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateConsejo } from "@helpers/httpRequests/httpRequestsConsejos/httpRequestsConsejos";

const EliminarConsejo = ({ setConsejoEliminado, consejo }) => {
  const { idConsejoRapido } = consejo;

  const cancelarEliminarConsejo = () => {
    Notificacion("info", "No se desactivó el consejo");
    setConsejoEliminado(true);
  };

  const eliminarConsejo = async (e) => {
    e.preventDefault();
    try {
      const { message } = await deactivateConsejo(idConsejoRapido);
      setConsejoEliminado(true);
      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al desactivar el consejo");
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de desactivar el consejo seleccionado?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={eliminarConsejo}>
            Sí, desactivar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarEliminarConsejo}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarConsejo;
