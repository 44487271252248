import UserContext from "@context/User/UserContext";
import { getCursoByCodigo } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { cursosIntereses } from "@helpers/intereses/cursosIntereses";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import TitleDashboard from "../Titles/TitleDashboard";

const RecomendacionesIntereses = ({ handleChangeComponent }) => {
	const [intereses, setIntereses] = useState([]);

	const [sugerencias, setSugerencias] = useState([]);

	const [sugerenciasRandom, setSugerenciasRandom] = useState([]);

	const { contextValue: userContextValue, setContextValue: setUserContextValue } = useContext(UserContext);

	const { usuarioLogeado } = userContextValue;

	let { intereses: interesesUsuarioLogeado } = usuarioLogeado;

	if (interesesUsuarioLogeado === null || interesesUsuarioLogeado === "") {
		interesesUsuarioLogeado = "Ofimatica";
	}

	const arregloIntereses = interesesUsuarioLogeado.split(",");

	const handleCursoSelection = (codigoCurso) => {
		const codigoSeccionSeleccionada = codigoCurso + "01";

		setUserContextValue({
			...userContextValue,
			codigoCursoSeleccionado: codigoCurso,
			ultimoCursoTomado: codigoCurso,
			codigoSeccionSeleccionada,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	useEffect(() => {
		const listarCursosIntereses = () => {
			const arregloAux = [];

			arregloIntereses.forEach((interes) => {
				if (cursosIntereses.hasOwnProperty(interes)) {
					arregloAux.push(...cursosIntereses[interes]);
				}
			});

			const arregloAux2 = new Set(arregloAux);

			let resultadoIntereses = [...arregloAux2];

			setIntereses(resultadoIntereses);
		};

		listarCursosIntereses();
	}, []);

	useEffect(() => {
		const obtenerCursosPorCodigo = async () => {
			try {
				let arregloAux = [];
				for (const interes in intereses) {
					if (Object.hasOwnProperty.call(intereses, interes)) {
						const element = intereses[interes];
						const { data } = await getCursoByCodigo(element);
						const { nombre, codigoCurso, portadaCurso, descripcion } = data;

						arregloAux.push({
							nombre,
							codigoCurso,
							portadaCurso,
							descripcion,
						});
					}
				}
				setSugerencias(arregloAux);
			} catch (error) {
				console.log(error);
			}
		};
		obtenerCursosPorCodigo();

		const generarNumerosAleatoriosSinRepetir = (cantidad) => {
			if (cantidad > 0 && cantidad <= intereses.length - 1) {
				// Cambia el 10 al valor máximo permitido de números únicos
				const numerosAleatorios = [];

				while (numerosAleatorios.length < cantidad) {
					const numeroAleatorio = Math.floor(Math.random() * intereses.length - 1) + 1; // Cambia el 10 al rango deseado de números aleatorios
					if (!numerosAleatorios.includes(numeroAleatorio)) {
						numerosAleatorios.push(numeroAleatorio);
					}
				}

				setSugerenciasRandom(numerosAleatorios);
			} else {
				return "La cantidad debe ser entre 1 y 10.";
			}
		};

		generarNumerosAleatoriosSinRepetir(4);
	}, [intereses]);

	return (
		<>
			<Row>
				<Col>
					<TitleDashboard title='Seleccionado para ti' />
				</Col>
			</Row>

			<Row className="mt-3 mb-5 justify-content-center">
				{sugerencias.length > 0 &&
					sugerenciasRandom.map((sugerencia, idx) => (
						<Col
							id={idx}
							key={idx}
							className="pointer col-9 col-sm-6 col-md-4 col-lg-4 col-xl-3"
							onClick={() => {
								handleCursoSelection(sugerencias[sugerencia].codigoCurso);
							}}>
							<Card className=" mb-4 card-predefinidas border-0">
								<picture width="100%" height="100%">
									<source srcSet={`${require(`@assets/images/portadas/${sugerencias[sugerencia].portadaCurso}`)}`} />
									<img
										loading="lazy"
										className="img-fluid"
										src={`${require(`@assets/images/portadas/${sugerencias[sugerencia].portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
										alt="Imagen de fondo"
									/>
								</picture>
							</Card>
						</Col>
					))}
			</Row>
		</>
	);
};

export default RecomendacionesIntereses;
