import "@assets/css/components/AulaVirtual/style.css";

import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Notificacion, NotificacionExtendida } from "../Alertas/Alertas";
import React, { useRef, useState } from "react";

import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import PaginationTransmisiones from "./components/PaginationTransmisiones";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import TransmisionAnterior from "./components/TransmisionAnterior";
import TransmisionEnVivo from "./components/TransmisionEnVivo";
import UserContext from "@context/User/UserContext";
import { getTransmissions } from "@helpers/httpRequests/httpRequestsAulaVirtual/httpRequestsAulaVirtual";
import { useContext } from "react";
import { useEffect } from "react";
import useWindowResize from "@customHooks/useWindowResize";
import { Animated } from "react-animated-css";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faChalkboardTeacher,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";

// import PaginationTransmition from "./components/PaginationTransmition";
// import EpiSearch from "./components/";

const AulaVirtual = ({ showScrollButton, handleScroll, handleScrollToTop }) => {
  const joyrideRef = useRef(null);

  const [tourActivo, setTourActivo] = useState(false);

  const [runTour, setRunTour] = useState(false);

  const [tourSteps, setTourSteps] = useState([
    // {
    // 	target: '.question-curso',
    // 	content: 'Esta es la sección de consejos rápidos, aquí puedes consultar los atajos más relevantes que tienen los cursos que se ofrecen en la plataforma.',
    // 	disableBeacon: true,
    // 	placement: 'left-start'
    //   },
    {
      target: ".question-aula",
      content:
        "Te presentamos el aula virtual, aqui encontrarás clases en vivo impartidas por nuestros profesionales.",
      disableBeacon: true,
      placement: "left-start",
    },
    {
      target: ".videos-anteriores",
      content:
        "Tienes acceso a todas las transmisiones en caso de no poder ver alguna en vivo.",
      disableBeacon: true,
      placement: "top-start",
    },
  ]);

  const [transmisiones, setTransmisiones] = useState([]);

  const [transmisionEnVivo, setTransmisionEnVivo] = useState({});

  const [transmisionAMostrar, setTransmisionAMostrar] = useState({});

  const [transmisionAnteriorSeleccionada, setTransmisionAnteriorSeleccionada] =
    useState(false);

  // PAGINACIÓN
  const [currentPage, setCurrentPage] = useState(1);

  const [transmissionsPerPage, setTransmissionsPerPage] = useState(4);

  const indexOfLastRecord = currentPage * transmissionsPerPage;

  const indexOfFirstRecord = indexOfLastRecord - transmissionsPerPage;

  const currentPaginationTransmissions = transmisiones.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const numberPages = Math.ceil(transmisiones.length / transmissionsPerPage);

  // END PAGINACIÓN

  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const {
    size: { width },
  } = useWindowResize();

  useEffect(() => {
    const consultarTransmisiones = async () => {
      try {
        const { data, message } = await getTransmissions();

        if (data.length === 0) {
          return Notificacion("info", message);
        }

        // console.log("Transmisiones: ", data);

        setTransmisiones(data.transmisionesAnteriores);

        setTransmisionAMostrar(data.transmisionEnVivo);

        setTransmisionEnVivo(data.transmisionEnVivo);
      } catch (error) {
        console.log("Error al consultar las transmisiones: ", error);

        NotificacionExtendida(
          "error",
          "Algo sucedió al consultar las transmisiones pasadas, recarga la página"
        );
      }
    };

    consultarTransmisiones();
  }, []);

  const handleBackOnline = () => {
    setTransmisionAMostrar(transmisionEnVivo);

    setTransmisionAnteriorSeleccionada(false);
  };

  const handleTransmissionSelection = (e) => {
    setTransmisionAMostrar(transmisiones[e.currentTarget.id - 1]);

    setTransmisionAnteriorSeleccionada(true);
  };

  const handleStartTour = () => {
    setRunTour(!runTour);
  };

  const breadcrumbItemsInitialState = [
    { title: "Aula Virtual", onClick: () => {} },
  ];

  return (
    <>
      {/* //*COMPONENTE ScrollToTop */}
      {showScrollButton && (
        <Animated
          animationIn="fadeIn"
          isVisible={true}
          style={{ zIndex: "9999", position: "absolute" }}
        >
          <div className="scroll-top">
            <span
              className={`rounded-circle scrollTop text-center d-flex`}
              style={{
                height: "50px",
                width: "50px",
                fontSize: "2rem",
                backgroundColor: "#F59E0B",
                position: "fixed",
                bottom: "40px",
                right: "40px",
              }}
            >
              <Link
                className="pointer"
                activeClass="active"
                to="scrollTopElement"
                spy={true}
                smooth={true}
                duration={800}
                containerId="scrollTop"
                style={{ color: "black", width: "100%" }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Link>
            </span>
          </div>
        </Animated>
      )}
      <Container fluid className="p-0 mb-3">
        <Joyride
          locale={spanishOptionsJoyRide}
          ref={joyrideRef}
          steps={tourSteps}
          run={runTour}
          continuous={true}
          showSkipButton
          hideCloseButton
          disableOverlayClose={true}
          disableCloseOnEsc={false}
          disableScrolling={false}
          scrollToFirstStep={true}
          scrollOffset={100}
          callback={(data) => {
            if (data.status === "finished") {
              setRunTour(false);
            } else if (data.action === "skip") {
              setRunTour(false);
            }
          }}
          styles={{
            options: {
              primaryColor: "#ff9900",
              textColor: "#00152A",
              width: 900,
              zIndex: 1000,
            },
          }}
        />

        <Row className="justify-content-between d-flex align-items-center">
          <Col className="col-auto">
            <DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
          </Col>

          {width > 768 && (
            <Col className="col-auto question-aula end-aula">
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>Conoce tu entorno</Tooltip>}
              >
                <span>
                  <FontAwesomeIcon
                    className="mx-5 pointer"
                    onClick={handleStartTour}
                    style={{ fontSize: "2rem" }}
                    icon={faQuestion}
                  />
                </span>
              </OverlayTrigger>
            </Col>
          )}
        </Row>

        <Row className="mb-3">
          <Col sm={12} md>
            <TitleDashboard title="Aula Virtual" />
          </Col>

          <Col sm={12} md align={width > 768 ? "right" : ""}>
            {transmisionAnteriorSeleccionada && (
              <Button variant="primary" onClick={handleBackOnline}>
                Volver a clase en vivo
              </Button>
            )}
          </Col>
        </Row>

        {Object.keys(transmisionAMostrar).length === 0 ? (
          <Row>
            <Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="mb-3 icon"
                size="4x"
              />
              {/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
              <h4>
                No hay ninguna clase en vivo, para ver una clase elige una
                anterior
              </h4>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <TransmisionEnVivo transmision={transmisionAMostrar} />
            </Col>
          </Row>
        )}

        <Row>
          <Col className="videos-anteriores">
            <h2 className="aula-virtual-subtitle mt-5">Videos anteriores</h2>

            {currentPaginationTransmissions.length > 0 ? (
              <Container fluid className="px-3">
                <Row className="justify-content-between">
                  {currentPaginationTransmissions.map((transmision, idx) => (
                    <TransmisionAnterior
                      titulo={transmision.titulo}
                      id={transmision.idTransmision}
                      handleTransmissionSelection={handleTransmissionSelection}
                      key={idx}
                    />
                  ))}
                </Row>
              </Container>
            ) : (
              <h2>No hay transmisiones disponibles</h2>
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <PaginationTransmisiones
              numberPages={numberPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AulaVirtual;
