import React from "react";

const TitleDashboard = ({ title }) => {
  return (
    <>
      {title ? (
        <h3 className="titulo-dashboard">{title}</h3>
      ) : (
        <h3>Sin titulo</h3>
      )}
      <div
        className="separador-titulo"
        style={{ width: "65px", height: "3px", backgroundColor: "#ffc107" }}
      ></div>
    </>
  );
};

export default TitleDashboard;
