import { Notificacion } from '@components/general/Alertas/Alertas'
import { abandonarProyecto } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

const DejarProyecto = ({idPerfil, idMision, setAbandonoProyecto}) => {
    
    const handleAbandonar = async() =>{
        try {
            await abandonarProyecto({
                idPerfil,
                idMision
            });
            setAbandonoProyecto(true);
            Notificacion("success", "Has abandonado la misión");
        } catch (error) {
            console.log(error)
            Notificacion("danger", "Ocurrió un error al dejar el proyecto")            
        }
    }

    const handleCancelar = () => {
        Notificacion("info", "No has abandonado el proyecto");
    }
  return (
    <Container>
        <Row>
            <Col>
                <h2>¿Estás seguro de abandonar el proyecto?</h2>
                <p>Perderás la oportunidad de obtener créditos Click+ y usarlos en las recompensas</p>

            </Col>
        </Row>

        <Row className='justify-content-center mt-4'>
            <Col className='col-auto'>
                <div>
                    <Button variant='success' onClick={handleAbandonar}>Si, quiero dejar el proyecto</Button>
                </div>

            </Col>
            <Col className='col-auto'>
                <div>
                    <Button variant='danger' onClick={handleCancelar}>Cancelar</Button>
                </div>

            </Col>
        </Row>
    </Container>
  )
}

export default DejarProyecto