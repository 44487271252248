const extensionesPermitidas = [
  ".csv",
  ".pptx",
  ".docx",
  ".accdb",
  ".mpp",
  ".pptx",
  ".PPTX",
  ".accdb",
  ".ACCDB",
  ".doc",
  ".DOC",
  ".pdf",
  ".PDF",
  ".xls",
  ".XLS",
  ".xlsx",
  ".XLSXs",
  ".xlsm",
  ".XLSM",
  ".xlm",
  ".XLM",
  ".xltx",
  ".XLTX",
  ".xltm",
  ".XLTM",
  ".accde",
  ".ACCDE",
  ".accdt",
  ".ACCDT",
  ".csv",
  ".CSV",
  ".docx",
  ".DOCXS",
  ".docm",
  ".DOCM",
];

export default extensionesPermitidas;
