import { PDFDocument, TextAlignment } from "pdf-lib";

//*Dentro del parametro "dataCodigoQR" se sustituirá por un parametro desestructurado para llenar el form con la info real.

const getFilledPdfBytes = (pdfTemplateBytes, infoPdf) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pdfDoc = await PDFDocument.load(pdfTemplateBytes);

      const pdfForm = pdfDoc.getForm();

      const {
        nombre,
        nombreCurso,
        tiempoCurso,
        fechaFinalizacion,
        codigoCurso,
      } = infoPdf;

      // console.log(infoPdf);

      pdfForm.getFields().forEach(async (field) => {
        const name = field.getName();

        switch (name) {
          case "nombre":
            pdfForm.getTextField(name).setText(nombre);
            pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;

          case "nombreCurso":
            pdfForm.getTextField(name).setText(nombreCurso);
            pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;

          case "fechaEmision":
            pdfForm
              .getTextField(name)
              .setText(`Emitido el día ${fechaFinalizacion}`);
            pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;

          case "duracionCurso":
            pdfForm
              .getTextField(name)
              .setText(`Con un duración de ${tiempoCurso} horas`);
            pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;

          default:
            break;
        }

        // pdfForm.getTextField(name).updateAppearances(helveticaFont);
      });

      resolve(await pdfDoc.save());
    } catch (error) {
      reject(error);
    }
  });
};

export default getFilledPdfBytes;
