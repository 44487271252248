import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import getFilledPdfBytes from "./getFilledPdfBytes/getFilledPdfBytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import {
  getFechaFinalizacionCurso,
  guardarCertificado,
} from "@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos";
import { NotificacionExtendida } from "../Alertas/Alertas";

import QRCode from "qrcode";
import getURLCodigoQR from "./getURLCodigoQR/getURLCodigoQR";

import { TfiReload } from "react-icons/tfi";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import confetti from "canvas-confetti";

const GenerarQR = ({
  comprobarCertificacion,
  codigoCursoSeleccionado,
  infoCurso,
  usuarioLogeado,
  certificadoDesbloqueado,
  puntajeCertificacion,
  rol,
  idUsuarioEmpresarial,
}) => {
  const {
    nombre: nombreCurso,
    isMsOffice,
    codigoCurso,
    tiempoCurso,
  } = infoCurso;

  const { nombreCompleto, apellidos, idPerfil, curp } = usuarioLogeado;

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const nombreUsuario = nombreCompleto + " " + apellidos;

  // const [pdfInfo, setPdfInfo] = useState([]);

  const [dataImage, setDataImage] = useState({
    dataImage: "",
  });

  const generarQR = async () => {
    var end = Date.now() + 15 * 250;

    // go Buckeyes!
    var colors = ["#00152A", "#ff9900"];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 35,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 35,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
    try {
      const infoCertificado = {
        rol: rol,
        idUsuarioEmpresarial: idUsuarioEmpresarial,
        idPerfil,
        codigoCurso: codigoCurso,
        nombre: nombreUsuario,
        curp,
        fecha: getUnixTimestamp(),
        nombreCurso,
      };

      const { data } = await guardarCertificado(infoCertificado);

      const { data: fechaFinalizacion } = await getFechaFinalizacionCurso({
        idPerfil,
        codigoCurso,
      });

      let payloadCodigoQR = {
        fechaFinalizacion: getFechaSinHora(fechaFinalizacion.fecha),
        fechaUnix: fechaFinalizacion.fecha,
        nombre: data.info.data.nombre,
        nombreCurso,
        tiempoCurso,
        idPerfil,
        codigoCurso,
        curso: isMsOffice,
      };

      //TODO Hacer debug para encontrar el porque no pasa correctamente la informacion para generar el qr

      const urlQR = getURLCodigoQR(payloadCodigoQR);

      const qrData = await QRCode.toDataURL(urlQR);

      payloadCodigoQR.dataQR = qrData;

      await createPdf(payloadCodigoQR);
    } catch (error) {
      console.log(error);
    }
  };

  //Dentro de este componente se deberá validar la categoría del curso para seleccionar el fetch correcto de la plantilla de certificado

  const createPdf = async (dataCodigoQR) => {
    try {
      let url = `${window.location.origin}/assets/templates/template_certificado.pdf`;

      if (isMsOffice) {
        url = `${window.location.origin}/assets/templates/template_certificado_microsoft_qr.pdf`;
      }

      const data = await fetch(url);

      const pdfTemplateBytes = await data.arrayBuffer();

      const filledPdfBytes = await getFilledPdfBytes(
        pdfTemplateBytes,
        dataCodigoQR
      );

      saveByteArray(`Certificado - ${nombreCurso}`, filledPdfBytes);
    } catch (error) {
      console.log(error);
    }
  };

  //Funcion para descargar el pdf sin la libreria
  const saveByteArray = (reportName, byte) => {
    var blob = new Blob([byte], { type: "application/pdf" });

    var link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);

    var fileName = reportName;

    setDataImage({
      ...dataImage,
      dataImage: link.href,
    });

    link.download = fileName;

    link.click();

    NotificacionExtendida("success", "Certificado generado con éxito");
  };

  useEffect(() => {
    const coordenadas = () => {
      // Obtener el elemento por su ID
      let elemento = document.getElementById("button-confetti");

      // Obtener las coordenadas X e Y del elemento
      let rect = elemento.getBoundingClientRect();

      var x = rect.left;
      var y = rect.top;

      setX(parseInt(x));
      setY(parseInt(y));
    };

    coordenadas();
  }, []);

  return (
    <div className="certificado-curso mt-4">
      <Row>
        <Col className="">
          <h3>Certificado del curso </h3>
        </Col>
      </Row>

      <Row className="ps-3 ">
        {/* <Alert variant='success'>Ya puedes descargar tu certificado</Alert> */}
        {certificadoDesbloqueado ? (
          // <Alert variant='success'>Tu certificado está listo para descargar</Alert>
          <>
            <Col className="col-12">
              <p className="text-success">
                Tu certificado está listo para descargar
              </p>
            </Col>
            <Col className="col-12">
              <p className="text-success">
                Obtuviste tu certificación con una calificación de:{" "}
                {puntajeCertificacion}%
              </p>
            </Col>
          </>
        ) : (
          //     <Alert variant="danger">
          // 	<b>No disponible a menos que logré un puntaje adecuado en la evaluación final</b>
          // </Alert>
          <p className="text-danger">
            {rol === typesTiposUsuario.invitado ? (
              <b>
                Exclusivo para usuarios Premium, adquiere tu licencia ahora y
                obtén acceso a todo el contenido Click+.
              </b>
            ) : (
              <b>
                No disponible a menos que logre un puntaje adecuado en la
                evaluación final
              </b>
            )}
          </p>
        )}
      </Row>

      <Row>
        <Col className=" mb-5 gap-3">
          {/* <h2>Generación certificado</h2> */}
          <Button
            id="button-confetti"
            disabled={certificadoDesbloqueado ? false : true}
            onClick={certificadoDesbloqueado ? generarQR : undefined}
            className="btn-sm "
            type="button"
          >
            Emitir certificado{" "}
            <FontAwesomeIcon className="ms-1" icon={faFileDownload} />
          </Button>

          {codigoCursoSeleccionado.startsWith("ING") &&
            !certificadoDesbloqueado && (
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>Actualizar puntaje</Tooltip>}
              >
                <Button
                  onClick={comprobarCertificacion}
                  className="btn btn-sm mx-3"
                >
                  {" "}
                  <TfiReload />
                </Button>
              </OverlayTrigger>
            )}
        </Col>
      </Row>
    </div>
  );
};

export default GenerarQR;
