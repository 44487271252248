import { useState } from 'react';

const useSignaturePad = () => {

    const [ showSignaturePad, setShowSignaturePad ] = useState(false);

    const [ signed, setSigned ] = useState(false);

    const handleInitSignaturePadButton = () => {
        setShowSignaturePad(true);
    };

    const resetSignaturePad = (signaturePad) => {
        signaturePad?.current?.clear();

        setSigned(false);
    };

    const getImageFromSignaturePad = (signaturePad) => {
        // if (!isEmpty(signaturePad)) {
        // }
        setSigned(true);
    };

    const getSignaturePadWidth = () => {
        const width = document.querySelector('#signatureCanvasParent').clientWidth;

        return width - ( width * 0.03 );
    };

    const isEmpty = (signaturePad) => {
        return signaturePad?.current?.isEmpty();
    };

    const blockSignaturePad = (signaturePad) => {
        signaturePad?.current?.off();
    };

    const unlockSignaturePad = (signaturePad) => {
        signaturePad?.current?.on();
    };
    
    return {
        showSignaturePad,
        signed,
        handleInitSignaturePadButton,
        getSignaturePadWidth,
        resetSignaturePad,
        getImageFromSignaturePad,
        isEmpty,
        blockSignaturePad,
        unlockSignaturePad
    };
}

export default useSignaturePad;