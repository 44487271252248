import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addCurso = async (infoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const newCurso = await httpRequest({
        url: `${endpointsPaths.cursoUsuario}/${httpRequestsPaths.cursoUsuario.addCurso}`,
        method: "POST",
        body: JSON.stringify(infoCurso),
      });

      resolve(newCurso);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};

export const updateCurso = async (infoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const newCurso = await httpRequest({
        url: `${endpointsPaths.cursoUsuario}/${httpRequestsPaths.cursoUsuario.updateCurso}`,
        method: "POST",
        body: JSON.stringify(infoCurso),
      });

      resolve(newCurso);
    } catch (error) {
      // console.log("Error al actualizar el puntaje del curso: ", error);

      reject(error);
    }
  });
};

export const getCalificacionPorCurso = async (infoPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getCalificacion } = await httpRequest({
        url: `${endpointsPaths.cursoUsuario}/${httpRequestsPaths.cursoUsuario.getCalificacionCurso}`,
        method: "POST",
        body: JSON.stringify(infoPerfil),
      });

      resolve(getCalificacion);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};

export const verificarCursoUsuario = async (infoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const newCurso = await httpRequest({
        url: `${endpointsPaths.cursoUsuario}/${httpRequestsPaths.cursoUsuario.verificarCursoUsuario}`,
        method: "POST",
        body: JSON.stringify(infoCurso),
      });

      resolve(newCurso);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};

export const updateCursosUsuario = (cursos, idLicenciaCredencial) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updated = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateCursosUsuario}`,
        method: "POST",
        body: JSON.stringify({
          cursos,
          idLicenciaCredencial,
        }),
      });

      console.log("Updated: ", updated);

      resolve(true);
    } catch (error) {
      // console.log("Error al actualizar los cursos del usuario: ", error);

      reject(error);
    }
  });
};
