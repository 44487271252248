import { Notificacion } from '@components/general/Alertas/Alertas';
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';
import endpointsPaths from '@helpers/httpRequests/endpointsPaths/endpointsPaths';
import httpFormDataRequest from '@helpers/httpRequests/httpFormDataRequest';
import { guardarArchivoProyecto } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import httpRequestsPaths from '@helpers/httpRequestsPaths/httpRequestsPaths';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io';

const SubirProyecto = ({idMision, idPerfil, infoParticipante, setProyectoSubido, misiones}) => {

    const [nombreMision, setNombreMision] = useState("");

    const [showLoader, setShowLoader] = useState(false);

    const [nombreOcupado, setNombreOcupado] = useState(false);


    console.log({idMision, idPerfil, infoParticipante, misiones})

    const {nombreCompleto, apellidos, correo} = infoParticipante;
    

    const [archivos, setArchivos] = useState([]);

    const [formValues, setFormValues] = useState({
        archivoAdjunto: ""
    })

    const handleOnChange = (event) =>{
        console.log(event.target.files[0]);

        const file = event.target.files[0];
        
        if(Array.isArray(file)){
            setArchivos(file);
        }else{
            setArchivos([file]);
        }

        setFormValues({
            ...formValues,
            archivoAdjunto: Array.isArray(file) ? file : [file]
        })
    }


    const handleSubmit= async(e) =>{
        e.preventDefault();
        try {
            setShowLoader(true);
            const {archivoAdjunto} = formValues;

            console.log(archivoAdjunto[0].name)


            const formData = new FormData();

            formData.append("archivoAdjunto", archivoAdjunto[0]);

            console.log(formData)
    
            const {permitted, message} = await httpFormDataRequest({
              url: `${endpointsPaths.misiones}/${httpRequestsPaths.misiones.sendProyectos}`,
              body: formData,
            });

            if(!permitted){
                Notificacion("info", message)
            }else{

                //Enviar los datos del proyecto a la base de datos y enviar correo de recibido
                if(formValues.archivoAdjunto !== ""){
                    
                await guardarArchivoProyecto({
                    nombreArchivo:archivoAdjunto[0].name,
                    idPerfil,
                    idMision: parseInt(idMision),
                    idParticipante: infoParticipante.idParticipante,
                    nombreCompleto,
                    apellidos,
                    correo,
                    nombreMision,
                    fechaRegistro: getUnixTimestamp()
                })
                setShowLoader(false)
                setProyectoSubido(true);
                Notificacion("success", message)
                }

            }


            
        } catch (error) {
            console.log(error)
            setProyectoSubido(false);
            setShowLoader(false);
            setNombreOcupado(true);
            setFormValues({
                ...formValues,
                archivoAdjunto : ""
            })
            setArchivos([])
        }
    }


    const limpiarArchivo = () =>{
        setFormValues({
            ...formValues,
            archivoAdjunto : ""
        })
        setArchivos([])
    }

    useEffect(() => {
        const obtenerNombreMision = () =>{
            const infoMision = misiones.find(mision => mision.idMision === idMision);

            setNombreMision(infoMision.titulo)

        }

        obtenerNombreMision()
    }, [])
    

  return (
    <Container>
        <Row className='mt-4'>
            <Col className='text-start'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-1'>
                        <Form.Label>Sube tus trabajos aquí</Form.Label>
                        <Form.Control required name="archivoAdjunto" onChange={handleOnChange} type='file'/>
                    </Form.Group>

                    <div className='text-start mt-4 mb-3'>
                        <Alert severity='info' className='mt-3'>Se debe enviar un solo archivo comprimido en formato .zip</Alert>
                    </div>

                    {archivos.length > 0 && (
                        <Row className='mb-3 mt-5 justify-content-center'>
                            <Col className='col-auto text-center'>
                                {archivos.map((archivo, idx) => (
                                   <>
                                        <Image className="mb-3" fluid width="100" height="100" src={require(`@assets/images/zip-icon.png`)} />
                                        <p>
                                            <b>{archivo.name}</b>
                                        </p>
                                        <p>
                                            <IoMdClose className='pointer' color='red' onClick={limpiarArchivo} />
                                        </p>
                                    </>
                                ))}
                            </Col>
                        </Row>
                    )}

                    {showLoader === true ? (
                       <div className='text-center'>
                        <div className='d-flex justify-content-center mt-4'>
                            <div className="spinner m-3"></div>
                            
                        </div>
                        <p>Enviando archivo...</p>
                       </div>

                    ): (
                        <div className='d-grid gap-20 mt-4'>
                            <Button type='submit' >Enviar</Button>

                            {nombreOcupado && (
                                <Alert className='mt-3' severity='error'>El nombre del archivo ya está en uso, intenta de nuevo</Alert>
                            )}
                        </div> 
                    )}

                   
                    
                </Form>
            </Col>
        </Row>
       
    </Container>
  )
}

export default SubirProyecto