import { Notificacion } from "@components/general/Alertas/Alertas";
import { updateEstadoReporte } from "@helpers/httpRequests/httpRequestsReportes/httpRequestsReportes";
import { deleteTema } from "@helpers/httpRequests/httpRequestsTemas/httpRequestsTemas";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarTema = ({
  tema,
  setTemaEliminado,
  categoria,
  setCategoriaModificada,
}) => {
  console.log(categoria);

  const { idTema } = tema;

  const cancelarEliminarTema = () => {
    Notificacion("info", "No se eliminó el tema");
  };

  const eliminarTema = async () => {
    try {
      const { message } = await deleteTema(idTema);

      await updateEstadoReporte(idTema);

      setTemaEliminado(true);

      setCategoriaModificada(parseInt(categoria));

      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al desactivar el consejo");
      console.log(error);
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de eliminar el tema seleccionado?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={eliminarTema}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarEliminarTema}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarTema;
