import SubtitleForoAyuda from "@components/general/Titles/SubtitleForoAyuda";
import {
  faCommentAlt,
  faEdit,
  faEllipsisV,
  faHeart,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Tab,
} from "react-bootstrap";

const TabPaneMisPublicaciones = ({
  tabPane,
  currentPaginationSections,
  handleEliminarTema,
  comentarTema,
  alias,
  handleNuevoTema,
  handleEditarTema,
  respuestas,
  numeroLikes,
  temasCategoria,
}) => {
  return (
    <Tab.Pane eventKey="misPublicaciones">
      {currentPaginationSections.some((tema) => tema.alias === alias) ? (
        <>
          <Row className="justify-content-start">
            <Col className="mb-3">
              <button
                onClick={handleNuevoTema}
                className="btn btn-success btn-sm btn-responsive"
              >
                Agregar un nuevo tema <FontAwesomeIcon icon={faEdit} />
              </button>
            </Col>
          </Row>

          {currentPaginationSections.map(
            (tema, key) =>
              tema.alias === alias && (
                <Row className="row-foro" key={key}>
                  <Col style={{ height: "150px" }} className="mt-3">
                    <Row>
                      <Col
                        className="pointer"
                        onClick={() => {
                          comentarTema(tema.idTema, tema.alias, tema.idPerfil);
                        }}
                      >
                        <SubtitleForoAyuda title={tema.titulo} />
                      </Col>
                      <Col className="col-2 text-center">
                        <DropdownButton
                          style={{ fontSize: "10px" }}
                          className="text-end menu-ruta"
                          id={`dropdown-button-drop-left`}
                          drop={"left"}
                          variant="transparent"
                          title={
                            <FontAwesomeIcon
                              className="pointer ellipsis"
                              icon={faEllipsisV}
                            />
                          }
                        >
                          <Dropdown.Item
                            onClick={handleEliminarTema}
                            name={0}
                            id={key}
                            eventKey="1"
                          >
                            Eliminar
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={handleEditarTema}
                            name={0}
                            id={key}
                            eventKey="1"
                          >
                            Modificar
                          </Dropdown.Item>
                        </DropdownButton>
                      </Col>
                    </Row>

                    <small>Creado por {tema.alias}</small>

                    <Row className="justify-content-end pt-4">
                      <Col className="d-flex align-items-center">
                        <span className="mx-2">
                          <h6>
                            <FontAwesomeIcon
                              className="svg-foro"
                              icon={faCommentAlt}
                            />{" "}
                            {
                              respuestas.filter(
                                (respuesta) => respuesta.idTema === tema.idTema
                              ).length
                            }
                          </h6>
                        </span>
                        <span className="mx-2">
                          <h6>
                            <FontAwesomeIcon
                              className="svg-foro"
                              icon={faHeart}
                            />{" "}
                            {numeroLikes(tema.idTema)}
                          </h6>
                        </span>
                      </Col>
                      <Col className="col-3 text-center">
                        <Button
                          name={tema.idTema}
                          value={tema.alias}
                          onClick={() => {
                            comentarTema(tema.idTema, tema.alias, tema.idPerfil);
                          }}
                          className="btn-sm btn-responsive"
                        >
                          Comentar <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )
          )}
        </>
      ) : (
        <Container className="temas">
          <Row>
            <Col className="text-center mt-5">
              <h4>No tienes temas publicados</h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-6 text-center">
              <button
                onClick={handleNuevoTema}
                className="btn btn-success btn-sm btn-responsive"
              >
                Agregar un nuevo tema <FontAwesomeIcon icon={faEdit} />
              </button>
            </Col>
          </Row>
        </Container>
      )}
    </Tab.Pane>
  );
};

export default TabPaneMisPublicaciones;
