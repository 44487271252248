import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { SlideMenu } from "primereact/slidemenu";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { addReporteComentario } from "@helpers/httpRequests/httpRequestsReportes/httpRequestsReportes";
import { Notificacion } from "@components/general/Alertas/Alertas";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";

const ReportarComentario = ({ setReporteEnviado, comentario }) => {
  // console.log(comentario);

  const { idTema, alias, respuesta, idRespuestaTema } = comentario;

  const [formValues, setFormValues] = useState({
    idRespuestaTema,
    otroMotivo: "",
    motivo: "",
    idTema,
    alias,
    respuesta,
  });

  const [otroMotivo, setOtroMotivo] = useState(false);

  const enviarReporte = async (razon) => {
    try {
      let { idRespuestaTema, idTema, alias, respuesta, motivo, otroMotivo } =
        formValues;

      if (motivo !== "OTRO") {
        otroMotivo = "No aplica";
      }

      const reporte = {
        idRespuestaTema,
        respuesta,
        idTema,
        motivo: motivo === "OTRO" ? "OTRO" : razon,
        otroMotivo: otroMotivo.replace(/'/g, "\\'").replace(/"/g, '\\"'),
        alias,
        fechaReporte: getUnixTimestamp(),
      };

      const { message } = await addReporteComentario(reporte);

      Notificacion("success", message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setFormValues({
      ...formValues,
      otroMotivo: value,
      motivo: "OTRO",
    });
  };

  const camposMenu = [
    {
      label: "Contenido del tema",
      icon: "pi pi-fw pi-file",

      items: [
        {
          label: "El tema no coincide con la categoría",
          icon: "pi pi-fw pi-thumbs-down",
          command: () => {
            enviarReporte("El tema no coindice con la categoría");
          },
        },
        {
          label: "El tema o el contenido es ofensivo",
          icon: "pi pi-fw pi-thumbs-down",
          command: () => {
            enviarReporte("El tema o el contenido es ofensivo");
          },
        },
      ],
    },
    {
      label: "Es spam",
      icon: "pi pi-fw pi-pencil",
      command: () => {
        enviarReporte("SPAM");
      },
    },
    {
      label: "Otro motivo",
      icon: "pi pi-fw pi-user",
      command: () => {
        setFormValues({
          ...formValues,
          motivo: "OTRO",
        });
        setOtroMotivo(true);
      },
    },

    {
      separator: true,
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <Form className="text-start">
            {!otroMotivo ? (
              <SlideMenu
                backLabel="Volver"
                className="slide-reportar"
                model={camposMenu}
                viewportHeight={180}
                menuWidth={600}
              ></SlideMenu>
            ) : (
              <>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    <i className="pi pi-comment"></i> Explicanos tu motivo
                  </Form.Label>
                  <Form.Control
                    placeholder="Explicanos tu motivo"
                    value={formValues.otroMotivo}
                    onChange={handleOnChange}
                    name="otroMotivo"
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                <Button
                  className="mt-3"
                  type="button"
                  onClick={() => {
                    enviarReporte(formValues.otroMotivo);
                  }}
                  block
                >
                  Enviar
                </Button>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ReportarComentario;
