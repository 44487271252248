import { Col, Container, Form, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

export const VerLicencia = ({ licencia }) => {
  const [cursos, setCursos] = useState([]);

  useEffect(() => {
    try {
      const auxCursos = JSON.parse(licencia.cursos);

      setCursos(auxCursos);
    } catch (error) {
      console.log("Error al parsear los cursos: ", error);
    }
  }, []);

  return (
    <Form>
      <Container fluid>
        <Row className="mb-3">
          <Form.Group as={Col} className="text-left">
            <h3>Cursos incluidos</h3>

            <Table striped bordered hover size="sm" className="text-center">
              <thead>
                <tr>
                  <th>Código de curso</th>
                  <th>Nombre</th>
                </tr>
              </thead>

              <tbody>
                {cursos.map((curso, idx) => (
                  <tr key={idx}>
                    <td>{curso.codigoCurso}</td>
                    <td>{curso.nombre}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Form.Group>
        </Row>
      </Container>
    </Form>
  );
};
