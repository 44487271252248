import React from 'react'

const TotalAPagarListItem = ({ codigoAplicado, content, isCredito }) => {
    return (
        <>
            {
                isCredito ? 

                <li className="orange-gc">
                    <span className={ codigoAplicado ? 'strike' : '' }>
                        { content.li }
                    </span>

                    { content.ul }
                </li>

                :

                <li className="orange-gc">
                    <span className={ codigoAplicado ? 'strike' : '' }>
                        { content }
                    </span>
                </li>
            }
        </>
    );
};

export default TotalAPagarListItem;