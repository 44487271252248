import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addRespuestaTema = async (respuesta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuestaAgregada = await httpRequest({
        url: `${endpointsPaths.respuestasTema}/${httpRequestsPaths.respuestasTema.addRespuestaTema}`,
        method: "POST",
        body: JSON.stringify(respuesta),
      });

      resolve(respuestaAgregada);
    } catch (error) {
      // console.log("Error al añadir una nueva respuesta: ", error);

      reject(error);
    }
  });
};

export const getRespuestaTemaById = (idRespuestaTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.respuestasTema}/${httpRequestsPaths.respuestasTema.getRespuestaTemaById}/${idRespuestaTema}`,
        method: "POST",
      });

      resolve(respuesta);
    } catch (error) {
      // console.log("Error al obtener la respuesta: ", error);

      reject(error);
    }
  });
};

export const getRespuestasTemaByIdTema = async (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuestaTema = await httpRequest({
        url: `${endpointsPaths.respuestasTema}/${httpRequestsPaths.respuestasTema.getRespuestaTemaByIdTema}/${idTema}`,
        method: "POST",
      });

      resolve(respuestaTema);
    } catch (error) {
      // console.log("Error a obtener las respuestas del tema: ", error);

      reject(error);
    }
  });
};

export const getRespuestasTema = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: respuestas } = await httpRequest({
        url: `${endpointsPaths.respuestasTema}/${httpRequestsPaths.respuestasTema.getRespuestasTema}`,
        method: "GET",
      });

      resolve(respuestas);
    } catch (error) {
      // console.log("Error al obtener las respuestas: ", error);

      reject(error);
    }
  });
};

export const deleteRespuestaTema = (idRespuestaTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuestaEliminada = await httpRequest({
        url: `${endpointsPaths.respuestasTema}/${httpRequestsPaths.respuestasTema.deleteRespuestaTema}/${idRespuestaTema}`,
        method: "POST",
      });

      resolve(respuestaEliminada);
    } catch (error) {
      // console.log("Error al eliminar la respuesta: ", error);

      reject(error);
    }
  });
};

export const updateRespuestaTema = (respuesta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuestaActualizada = await httpRequest({
        url: `${endpointsPaths.respuestasTema}/${httpRequestsPaths.respuestasTema.updateRespuestaTema}`,
        method: "POST",
        body: JSON.stringify(respuesta),
      });

      resolve(respuestaActualizada);
    } catch (error) {
      // console.log("Error al actualizar la respuesta: ", error);

      reject(error);
    }
  });
};
