import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import { updateReorderLecciones } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";

import React from "react";
import { useState } from "react";

const getDragnDropListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e3e3e3" : "#FFF",
  padding: 15,
  // width: 250
});

const getDragnDropItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 10,
  // margin: `0 0 ${8}px 0`,
  background: isDragging ? "#FFF" : "transparent",
  ...draggableStyle,
});

const ListadoLeccionesSeccion = ({
  lecciones,
  setLecciones,
  recoveryLecciones,
}) => {
  const [editarLecciones, setEditarLecciones] = useState(false);

  const handleEditLecciones = () => {
    setEditarLecciones(true);
  };

  const handleCancelEditSecciones = () => {
    setLecciones(recoveryLecciones);

    setEditarLecciones(false);
  };

  const reorderLecciones = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragDropEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const auxLecciones = Array.from(lecciones);

    const { source, destination } = result;

    const { index: sourceIndex } = source;

    const { index: destinationIndex } = destination;

    const reorderedLecciones = reorderLecciones(
      auxLecciones,
      sourceIndex,
      destinationIndex
    );

    reorderedLecciones.forEach((seccion, idx) => {
      const nuevoNivel = idx + 1;

      const nuevoNivelFormateado =
        nuevoNivel < 10 ? `0${nuevoNivel}` : nuevoNivel;

      reorderedLecciones[idx] = {
        ...seccion,
        nivel: nuevoNivelFormateado,
      };
    });

    setLecciones(reorderedLecciones);
  };

  const handleGuardarReorden = async () => {
    try {
      const isConfirmed = await ModalSweetAlert({
        title: "¿Estás seguro que deseas reordenar las lecciones?",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Sí, reordenar",
        cancelButtonText: "No, cancelar",
      });

      if (!isConfirmed) {
        return;
      }

      const leccionesReordenadas = await updateReorderLecciones(lecciones);

      Notificacion("success", "Las lecciones se reordenaron con éxito");

      setEditarLecciones(false);
    } catch (error) {
      console.log("Error al guardar el reorden: ", error);
      Notificacion("error", "No se pudieron reordenar las lecciones");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!editarLecciones && (
            <Button variant="primary" onClick={handleEditLecciones}>
              Cambiar orden de las lecciones
            </Button>
          )}

          {editarLecciones && (
            <>
              <Button
                className="me-2"
                variant="success"
                onClick={handleGuardarReorden}
              >
                Guardar
              </Button>

              <Button
                className="me-2"
                variant="danger"
                onClick={handleCancelEditSecciones}
              >
                Cancelar
              </Button>
            </>
          )}
        </Col>
      </Row>

      {editarLecciones && (
        <h5>Da clic y arrastra la lección al lugar donde deseas reordenarla</h5>
      )}

      <Row className="mt-3">
        <Col>
          {editarLecciones ? (
            <DragDropContext onDragEnd={handleDragDropEnd}>
              <Droppable droppableId="droppable-secciones">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getDragnDropListStyle(snapshot.isDraggingOver)}
                  >
                    {lecciones.map((leccion, idx) => (
                      <Draggable
                        key={idx + 1}
                        draggableId={idx.toString()}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getDragnDropItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ListGroup>
                              <ListGroup.Item key={idx}>
                                {idx + 1}. {leccion.nombre} -{" "}
                                {leccion.codigoLeccion}
                              </ListGroup.Item>
                            </ListGroup>
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <ListGroup>
              {lecciones.length > 0 ? (
                <>
                  {lecciones.map((leccion, idx) => (
                    <ListGroup.Item key={idx}>
                      {idx + 1}. {leccion.nombre} - {leccion.codigoLeccion}
                    </ListGroup.Item>
                  ))}
                </>
              ) : (
                <h3>No hay lecciones en esta sección</h3>
              )}
            </ListGroup>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ListadoLeccionesSeccion;
