import { Notificacion } from "@components/general/Alertas/Alertas";
import DatosFacturacion from "@components/usuario/MisPagos/components/facturacion/DatosFacturacion";
import UserContext from "@context/User/UserContext";
import useWindowResize from "@customHooks/useWindowResize";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import { sendPurchaseEvent } from "@helpers/httpRequests/httpRequestPixelFacebook/httpRequestPixelFacebook";
import { enviarCorreoFacturacion, getPriceLicenciaLecturaVeloz, retrieveCheckoutSessionByMetadata, retrievePaymentIntent } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import Loader from "@helpers/loader/loader";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";
import { CssBaseline, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Alert, Button, Col, Container, Image, Navbar, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const getFbCookie = () => {
    return new Promise(function(resolve, reject) {
        try {
            let cookiesInfo = document.cookie.split(';');

            console.log(cookiesInfo);

            let fbCookie = '';

            for (let i = 0; i < cookiesInfo.length; i++) {
                let cookie = cookiesInfo[i].split('=');
                let prefix = cookie[0].trim();

                if (prefix === '_fbp') {
                    fbCookie = decodeURIComponent(cookie[1])
                    break;
                }
            }

            console.log(fbCookie);

            resolve(fbCookie);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

const getCurrentTimeStamp = () => {
	return Math.floor(new Date() / 1000);
}


const CompraLecturaVelozRealizada = ({ history }) => {
	const urlParams = new URLSearchParams(useLocation().search);

	const themeMui = createTheme({
		palette: {
			mode: "light",
		},
	});

	const { contextValue, setContextValue } = useContext(UserContext);

	const [paymentIntent, setPaymentIntent] = useState({});

	const [paymentMethod, setPaymentMethod] = useState({});

	const [facturacionEnviada, setFacturacionEnviada] = useState(false);

	const [formValuesFacturacion, setFormValuesFacturacion] = useState({
		usoCfdi: "",
		claveCfdi: "",
		constanciaSituacionFiscalData: "",
		nombreCompleto: "",
		apellidos: "",
		correo: "",
	});

	const [showSpinner, setShowSpinner] = useState(false);

	const [fbEventSent, setFbEventSent] = useState(false);

	const [showLoader, setShowLoader] = useState(true);

	const [folioContrato, setFolioContrato] = useState();

	const handleSolicitarFacturacion = async () => {
		setShowSpinner(true);

		let montoCobro = "",
			tipoTarjeta = "";

		const { amount } = paymentIntent;

		const {
			card: { funding },
		} = paymentMethod;

		montoCobro = amount / 100;

		tipoTarjeta = funding === "credit" ? "Tarjeta de crédito" : "Tarjeta de débito";

		await enviarCorreoFacturacion({
			pdfData: formValuesFacturacion.constanciaSituacionFiscalData,
			folioContrato: folioContrato,
			cfdi: {
				clave: formValuesFacturacion.claveCfdi,
				descripcion: formValuesFacturacion.usoCfdi,
			},
			metodoPago: tipoTarjeta,
			nombreCliente: `${formValuesFacturacion.nombreCompleto} ${formValuesFacturacion.apellidos}`,
			correoCliente: formValuesFacturacion.correo,
			montoCobro: numberCommaSeparator(montoCobro),
		});

		Notificacion("success", "Solicitud de facturación enviada con éxito");

		setFacturacionEnviada(true);

		setShowSpinner(false);
	};

	const handleGoToRegister = () => {
		history.replace(`${dashboardRoutesPaths.rawFormularioRegistroSai}/${folioContrato}`);
	};

	const sendFbEvent = (correo) => {
		return new Promise(async(resolve, reject) => {
			try {

				console.log('correo del pixel: ', correo);

				const cookie = await getFbCookie();
	
				const eventID = 'gc-purchase-' + getCurrentTimeStamp();;
	
				// eslint-disable-next-line no-undef
				fbq("track", "Purchase", { 
					currency: "MXN", 
					value: 2990.0, 
					correo
				}, { eventID });

				await sendPurchaseEvent({
					cookie,
					correo,
					eventId: eventID
				});
		
				resolve();
			} catch (error) {
				console.log(error);
				
				reject(error);
			}
		});
	};

	useEffect(() => {
		const validarExistenciaPago = async () => {
			try {
				const folioContrato = urlParams.get("folioContrato");

				const id = urlParams.get("id");

				const stripeSession = await retrieveCheckoutSessionByMetadata(folioContrato, id);

				const { paymentIntent, paymentMethod } = await retrievePaymentIntent(stripeSession.payment_intent);

				setPaymentIntent(paymentIntent);

				setPaymentMethod(paymentMethod);

				const precios = await getPriceLicenciaLecturaVeloz();

				setFolioContrato(folioContrato);

				setContextValue({
					...contextValue,
					compra: {
						stripeSession,
						codigoAplicado: true,
						idCodigoDescuento: precios.idCodigo,
						preciosSeleccionados: precios,
						metodoPago: "contado",
						folioContrato,
					},
				});

				setShowLoader(false);

				if (!fbEventSent) {
					const correo = paymentIntent.customer_details !== undefined ? paymentIntent.customer_details.email : paymentMethod.billing_details.email;

					sendFbEvent(correo);

					setFbEventSent(true);
				}
			} catch (error) {
				console.log("Error al obtener el historial de pagos: ", error);
			}
		};

		validarExistenciaPago();
	}, []);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<ThemeProvider theme={themeMui}>
					<CssBaseline />

					<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
						<div className="bg-main-container" style={{ overflow: "hidden", overflowY: "scroll" }}>
							<Navbar expand="lg" data-bs-theme="light" bg="light" className="bg-dark-custom">
								<Container fluid>
									<Navbar.Brand href="#">
										<div className="m-1">
											<Image fluid width={250} src={require("@assets/images/skill_plus.png")} alt="Logo Skill Plus" />
											&nbsp;&nbsp;Compra Realizada - Plataforma Skill+
										</div>
									</Navbar.Brand>
								</Container>
							</Navbar>

							<Container fluid className="py-30">
								<Row>
									<Col>
										<Container fluid className="bg-light-dark">
											<Row>
												<Col>
													<Container fluid className="py-30">
														<Row>
															<Col>
																<Container fluid className="bg-light-dark">
																	<Row>
																		<Col>
																			<Container fluid className="p-0 p-lg my-3">
																				<Row>
																					<Col className="text-center">
																						<ThumbUpSvg />
																					</Col>
																				</Row>

																				<Row className="mt-2">
																					<Col className="text-center">
																						<Container>
																							<Row className="text-white">
																								<Col>
																									<h3 className="text-success text-uppercase">¡Gracias por tu compra!</h3>

																									<p className="text-center">Tu pago ha sido recibido satisfactoriamente.</p>

																									<p className="text-center">
																										Recibirás un correo electrónico de <b>notificaciones@globalclickmexico.com</b> con tu comprobante de pago.
																									</p>
																								</Col>
																							</Row>

																							{facturacionEnviada ? (
																								<Row>
																									<Col xs={{ span: 6, offset: 3 }}>
																										<Alert severity="success">¡Tu solicitud de facturación ha sido enviada con éxito!</Alert>
																									</Col>
																								</Row>
																							) : (
																								<>
																									<DatosFacturacion showExtraForm formValues={formValuesFacturacion} setFormValues={setFormValuesFacturacion} />

																									{formValuesFacturacion.facturacionSolicitada && (
																										<>
																											<div className="d-grid gap-2 my-4">
																												<Button variant="success" onClick={handleSolicitarFacturacion} disabled={facturacionEnviada} size="lg">
																													{facturacionEnviada ? "Solicitud enviada" : <>Solicitar facturación {showSpinner && <FontAwesomeIcon icon={faSpinner} spin />}</>}
																												</Button>
																											</div>

																											<hr />
																										</>
																									)}
																								</>
																							)}

																							<Row className="mt-3">
																								<Col>
																									<Typography className="text-white" variant="h6">
																										Para iniciar tu registro en la plataforma, primero deberás llenar el siguiente formulario.
																									</Typography>

																									<div className="d-grid gap-2 my-4">
																										<Button variant="primary" onClick={handleGoToRegister} className="mt-4">
																											Comenzar Registro
																										</Button>
																									</div>
																								</Col>
																							</Row>
																						</Container>
																					</Col>
																				</Row>
																			</Container>
																		</Col>
																	</Row>
																</Container>
															</Col>
														</Row>
													</Container>
												</Col>
											</Row>
										</Container>
									</Col>
								</Row>
							</Container>
						</div>
					</Animated>
				</ThemeProvider>
			)}
		</>
	);
};

export default CompraLecturaVelozRealizada;
