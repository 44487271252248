import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { faEye, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addNipPerfil, getDatosUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";

const AddNipSeguridad = ({ setNipActivo, fromUpdate = false }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idPerfil, nip } = usuarioLogeado;

	const [formValues, setFormValues] = useState({
		nip: "",
		confirmacionNip: "",
	});

	const [showNips, setShowNips] = useState({
		nip: false,
		confirmacionNip: false,
	});

	const [validacionNip, setValidacionNip] = useState({
		nip: false,
		confirmacionNip: false,
	});

	const handleAgregarNip = async (e) => {
		e.preventDefault();

		try {
			if (formValues.nip === "") {
				return NotificacionExtendida("info", "Ingresa un NIP para continuar");
			}

			await addNipPerfil({
				idPerfil,
				nip: formValues.nip,
			});

			const { data: datosUsuario } = await getDatosUsuario({
				tipoUsuario: usuarioLogeado.rol,
				idCredencial: usuarioLogeado.idCredencial,
				usuarioTitular: usuarioLogeado.usuarioTitular,
			});

			const [datosUsuarioActualizados] = datosUsuario;

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuarioActualizados,
			});

			Notificacion("success", "NIP agregado correctamente");

			setNipActivo(true);
		} catch (error) {
			console.log("Error al agregar un nuevo nip al perfil: ", error);

			NotificacionExtendida("error", "No se pudo agregar el NIP, por favor intenta más tarde");
		}
	};

	const handleOnChangeAgregarNip = ({ target }) => {
		const { name, value } = target;

		const cleanedValue = value.replace(/[^0-9]/g, "");

		setFormValues({
			...formValues,
			[name]: cleanedValue,
		});

		setValidacionNip({
			...validacionNip,
			nip: true,
		});

		if (name === "confirmacionNip") {
			let nipsCoincidentes = false;

			if (value === formValues.nip) {
				nipsCoincidentes = true;
			}

			setValidacionNip({
				...validacionNip,
				confirmacionNip: nipsCoincidentes,
			});
		}
	};

	const showNipNuevo = () => {
		setShowNips({
			...showNips,
			nip: !showNips.nip,
		});
	};

	const showConfirmacionNipNuevo = () => {
		setShowNips({
			...showNips,
			confirmacionNip: !showNips.confirmacionNip,
		});
	};

	return (
		<>
			{!fromUpdate && (
				<Row className="d-flex justify-content-center align-items-center text-center">
					<Col>
						<FontAwesomeIcon icon={faUserShield} className="mb-3" size="6x" />

						<h3>Establece un NIP de seguridad para tu perfil</h3>
					</Col>
				</Row>
			)}

			<Container>
				<Row className="mx-5 justify-content-center">
					<Col xs={12} md={4}>
						<Form onSubmit={handleAgregarNip}>
							<Form.Group>
								<Form.Label>NIP de seguridad</Form.Label>

								<InputGroup hasValidation>
                  <InputGroup.Text onClick={showNipNuevo} className="pointer">
                    <FontAwesomeIcon icon={faEye} />
                  </InputGroup.Text>

									<Form.Control
										type={showNips.nip ? "text" : "password"}
										autoComplete="off"
										value={formValues.nip}
										className={validacionNip.nip ? "is-valid " : ""}
										name="nip"
										placeholder="Ingresa tu NIP"
										onChange={handleOnChangeAgregarNip}
										maxLength={4}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group>
								<Form.Label>Confirmación</Form.Label>

								<InputGroup hasValidation>
                  <InputGroup.Text onClick={showConfirmacionNipNuevo} className="pointer">
                    <FontAwesomeIcon icon={faEye} />
                  </InputGroup.Text>

									<Form.Control
										onChange={handleOnChangeAgregarNip}
										maxLength={4}
										value={formValues.confirmacionNip}
										type={showNips.confirmacionNip ? "text" : "password"}
										autoComplete="off"
										name="confirmacionNip"
										placeholder="Confirma tu NIP"
										className={validacionNip.confirmacionNip ? "is-valid" : "is-invalid"}
									/>
								</InputGroup>
							</Form.Group>

							<Button block variant="warning" type="submit">
								Establecer NIP de seguridad
							</Button>
						</Form>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default AddNipSeguridad;
