import { Notificacion } from "@components/general/Alertas/Alertas";
import { deletePublicacion } from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const EliminarPublicacion = ({ setPublicacionEliminada, publicacion }) => {
  const { idPublicacion } = publicacion;
  // console.log(idPublicacion);

  const cancelarDeshabilitarPublicacion = () => {
    Notificacion("info", "No se deshabilitó la publicación");
  };

  const deshabilitarPublicacion = async () => {
    try {
      const { message, data } = await deletePublicacion(idPublicacion);
      // console.log(data);
      setPublicacionEliminada(true);
      Notificacion("success", message);
    } catch (error) {
      Notificacion("error", "Error al deshabilitar la publicación");
    }
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro de deshabilitar esta publicación?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={deshabilitarPublicacion}>
            Sí, deshabilitar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={cancelarDeshabilitarPublicacion}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EliminarPublicacion;
