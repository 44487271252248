
import typesPdfDomiciliacion from "@helpers/types/typesPdfDomiciliacion";
import { PDFDocument } from "pdf-lib";

const getFilledPdfBytes = ({ pdfTemplateBytes, replacements, fechaDomiciliacion }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const pdfDoc = await PDFDocument.load(pdfTemplateBytes);

            const pdfForm = pdfDoc.getForm();

            pdfForm.getFields().forEach(async (field) => {
				const name = field.getName();

				switch (name) {
					case typesPdfDomiciliacion.NOMBRE_SERVICIO:
						pdfForm.getTextField(name).setText('Licencia Click+ Virtual - 18 meses');
						break;

					case typesPdfDomiciliacion.FECHA_SOLICITUD.DIA:
						pdfForm.getTextField(name).setText(fechaDomiciliacion.dia);
						break;

					case typesPdfDomiciliacion.FECHA_SOLICITUD.MES:
						pdfForm.getTextField(name).setText(fechaDomiciliacion.mes);
						break;

					case typesPdfDomiciliacion.FECHA_SOLICITUD.ANIO:
						pdfForm.getTextField(name).setText(fechaDomiciliacion.anio);
						break;
					
					case typesPdfDomiciliacion.DIAS_PAGO:
						pdfForm.getTextField(name).setText(replacements.diasPago);
						break;

					case typesPdfDomiciliacion.NOMBRE_BANCO:
						pdfForm.getTextField(name).setText(replacements.nombreBanco);
						break;

					case typesPdfDomiciliacion.NUMERO_TARJETA:
						pdfForm.getTextField(name).setText(replacements.numeroTarjeta);
						break;

					case typesPdfDomiciliacion.MONTO_MAXIMO:
						pdfForm.getTextField(name).setText(replacements.montoMaximo);
						break;

					case typesPdfDomiciliacion.RADIO_BUTTON_GROUP:
						pdfForm.getRadioGroup(name).select(typesPdfDomiciliacion.RADIO_BUTTON_MONTO_FIJO)
						break;

					case typesPdfDomiciliacion.MONTO_FIJO:
						pdfForm.getTextField(name).setText(replacements.montoFijo);
						break;

					case typesPdfDomiciliacion.FECHA_VENCIMIENTO:
						pdfForm.getTextField(name).setText(replacements.fechaVencimiento);
						break;

					case typesPdfDomiciliacion.NOMBRE_TITULAR:
						pdfForm.getTextField(name).setText(replacements.nombreTitular);
						break;

					case typesPdfDomiciliacion.FIRMA_TITULAR:
						const imgFirma = await pdfDoc.embedPng(replacements.firmaTitular);

						pdfForm.getButton(name).setImage(imgFirma);
						break;
                    
                    default:
                        break;
				}

				// pdfForm.getTextField(name).updateAppearances(helveticaFont);
			});

			const filledPDF = await pdfDoc.save();

            resolve(filledPDF);
        } catch (error) {
            reject(error);
        }
    });
};

export default getFilledPdfBytes;