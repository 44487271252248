import React, { useEffect, useRef, useState } from "react";
import { faChalkboardTeacher, faComments, faDownload, faExclamation, faExpand, faPhone, faPlay, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, OverlayTrigger, Row, Table, Tooltip, Button as BootstrapButton, Button, Accordion } from "react-bootstrap";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { LeccionesDeApoyo } from "../CursoPropedeutico/LeccionesDeApoyo";
import { Proyectos } from "../CursoPropedeutico/Proyectos";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, ListItemAvatar } from "@mui/material";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const CursoPropedeuticoMos = ({ codigo, setCodigo, urlVideo, setUrlVideo, verLeccionDeApoyo, materialApoyo, verProyecto, tab, rol, usuarioTitular, derechoMos, width, handleStartTour }) => {
	const iframeRef = useRef();

	const [isFullscreen, setIsFullscreen] = useState(false);

	const requestFullscreen = () => {
		iframeRef.current.requestFullscreen();
	};

	useEffect(() => {
		if (tab === "propedeutico") {
			setUrlVideo(null);
			setCodigo(null);
		}
	}, [tab]);

	return (
		<>
			{derechoMos === 1 ? (
				<>
					<Row className="justify-content-end mt-4">
						{width > 768 && (
							<Col className="col-auto question-propedeutico end-propedeutico">
								<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
									<span>
										<FontAwesomeIcon className="mx-5 pointer " onClick={handleStartTour} style={{ fontSize: "2rem" }} icon={faQuestion} />
									</span>
								</OverlayTrigger>
							</Col>
						)}
					</Row>

					<Row className="justify-content-center mt-3 mb-5">
						<Col className="col-12 col-lg-6 mt-4 mt-lg-0 lecciones-mos">
							<Accordion defaultActiveKey="0">
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										<div className="flex align-items-center">
											<h5 className="vertical-align-middle">
												<HiOutlinePresentationChartBar /> Lecciones de apoyo
											</h5>
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<Col className="contenedor-lecciones pt-3">
											<Table striped borderless>
												<tbody>
													{LeccionesDeApoyo.map((Leccion, key) => (
														<tr
															style={{
																backgroundColor: `${codigo === Leccion.codigoLeccion && codigo !== null ? "#ffc107 " : ""}`,
															}}
															key={key}>
															<td
																style={{
																	fontWeight: `${codigo === Leccion.codigoLeccion ? "bold" : "normal"}`,
																}}>
																<h5>{`${key + 1}- ${Leccion.nombreLeccion}`}</h5>
															</td>
															<td></td>
															<td>
																<h6>
																	<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Reproducir</Tooltip>}>
																		<span>
																			<FontAwesomeIcon
																				className="pointer"
																				onClick={() => {
																					verLeccionDeApoyo(Leccion.nombreLeccion, Leccion.codigoLeccion);
																				}}
																				icon={faPlay}
																			/>
																		</span>
																	</OverlayTrigger>
																</h6>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</Col>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>
										<div className="flex align-items-center">
											<h5 className="vertical-align-middle">
												<HiOutlinePresentationChartBar /> Proyectos
											</h5>
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<Col className="contenedor-proyectos pt-3">
											<Col className="mb-3 p-0">
												<div className="d-grid gap-2 mx-3">
													<Button onClick={materialApoyo} size="lg">
														<FontAwesomeIcon icon={faDownload} /> Descargar material de apoyo
													</Button>
												</div>
											</Col>

											<Table striped borderless>
												<tbody>
													{Proyectos.map((Proyecto, key) => (
														<tr
															style={{
																backgroundColor: `${codigo === Proyecto.codigoProyecto && codigo !== null ? "#ffc107 " : ""}`,
															}}
															key={key}>
															<td></td>
															<td
																style={{
																	fontWeight: `${codigo === Proyecto.codigoProyecto ? "bold" : "normal"}`,
																}}>
																<h5>{`${key + 1}- ${Proyecto.nombreProyecto}`}</h5>
															</td>
															<td></td>
															<td>
																<h5>
																	<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Reproducir</Tooltip>}>
																		<span>
																			<FontAwesomeIcon
																				className="pointer"
																				onClick={() => {
																					verProyecto(Proyecto.nombreProyecto, Proyecto.codigoProyecto);
																				}}
																				icon={faPlay}
																			/>
																		</span>
																	</OverlayTrigger>
																</h5>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</Col>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
						<Col className="col-12 col-lg-6 mt-4 mt-lg-0 visor-mos">
							<Col className="col-12 mb-3">
								<Row className="justify-content-end mt-4">
									{urlVideo !== null && (
										<Col className="col-auto">
											<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Pantalla completa.</Tooltip>}>
												<BootstrapButton className="btn-fullscreen" onClick={requestFullscreen} variant="secondary">
													<FontAwesomeIcon icon={faExpand} />
												</BootstrapButton>
											</OverlayTrigger>
										</Col>
									)}
								</Row>
							</Col>
							{urlVideo === null ? (
								<Row>
									<Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
										<FontAwesomeIcon icon={faChalkboardTeacher} className="mb-3 icon" size="4x" />
										{/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
										<h4>Selecciona una lección o un proyecto para comenzar</h4>
									</Col>
								</Row>
							) : (
								<iframe
									className="ratio ratio-16x9"
									style={{ border: "none" }}
									ref={iframeRef}
									width="100%"
									height="100%"
									src={urlVideo}
									title="YouTube video player"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
							)}
						</Col>
					</Row>
				</>
			) : (
				<Row className="mt-3 mb-5">
					<Col className="text-center mt-5" style={{ color: "grey" }}>
						<FontAwesomeIcon icon={faExclamation} className="mb-4" size="4x" />

						<h4>¿Estas interesado en la Certificación MOS?</h4>

						<p>Ponte en contacto con nosotros para brindarte toda la información necesaria.</p>

						<List className="d-inline-block">
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<FontAwesomeIcon icon={faComments} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Chat En Línea" secondary="Ubicado en la esquina derecha inferior" />
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<FontAwesomeIcon icon={faPhone} />
									</Avatar>
								</ListItemAvatar>

								<ListItemText primary="Teléfono" secondary="(477) 390-83-01 ext. 101" />
							</ListItem>
						</List>
					</Col>
				</Row>
			)}
		</>
	);
};

export default CursoPropedeuticoMos;
