import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addRecomendaciones = async (infoRecomendacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: usuariosRanking } = await httpRequest({
        url: `${endpointsPaths.recomendacionesDiagnostico}/${httpRequestsPaths.recomendacionesDiagnostico.addRecomendacion}`,
        method: "POST",
        body: JSON.stringify(infoRecomendacion),
      });

      resolve(usuariosRanking);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};

export const getRecomendaciones = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccionesRecomendadas } = await httpRequest({
        url: `${endpointsPaths.recomendacionesDiagnostico}/${httpRequestsPaths.recomendacionesDiagnostico.getRecomendaciones}/${idPerfil}`,
        method: "POST",
      });

      resolve(leccionesRecomendadas);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};

export const getRecomendacionesByCodigo = async (infoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionesRecomendadas = await httpRequest({
        url: `${endpointsPaths.recomendacionesDiagnostico}/${httpRequestsPaths.recomendacionesDiagnostico.getRecomendacionesByCodigo}`,
        method: "POST",
        body: JSON.stringify(infoCurso),
      });

      resolve(leccionesRecomendadas);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};
