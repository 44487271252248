import React, { useContext, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "../Titles/TitleDashboard";
import "@assets/css/components/MaterialCapacitacion/style.css";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExpand } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionTab } from "primereact/accordion";
import UserContext from "@context/User/UserContext";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { addUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";

const MaterialVistoCapacitacion = ({ handleChangeComponent }) => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const { idPerfil } = usuarioLogeado;

  const iframeRef = useRef();

  const [urlLeccion, setUrlLeccion] = useState(null);

  const [urlDescarga, setUrlDescarga] = useState(null);

  const [verLeccion, setVerLeccion] = useState(false);

  const [showBtnDescarga, setShowBtnDescarga] = useState(false);

  const [showBtnEjercicio, setShowBtnEjercicio] = useState(false);

  const breadcrumbItemsInitialState = [
    { title: "Material visto en capacitación", onClick: () => {} },
  ];

  function requestFullscreen() {
    iframeRef.current.requestFullscreen();
  }

  const handleLeccion = ({ target }) => {
    const { name, id } = target;

    switch (id) {
      case "transposicion-datos":
        setShowBtnDescarga(true);
        setUrlDescarga(
          "https://virtual.globalclickmexico.com/lecciones/capacitacion/Transponer.xlsx"
        );
        break;

      case "ejercicio-complementario":
        setShowBtnDescarga(true);
        setShowBtnEjercicio(true);
        setUrlDescarga(
          "https://virtual.globalclickmexico.com/lecciones/capacitacion/Ejercicio_plantilla.xlsx"
        );
        break;

      case "tabla-pivote":
        setShowBtnDescarga(true);
        setUrlDescarga(
          "https://virtual.globalclickmexico.com/lecciones/capacitacion/Pivot_table.xlsm"
        );
        break;

      default:
        setShowBtnDescarga(false);
        setUrlDescarga(null);
        setShowBtnDescarga(false);
        setShowBtnEjercicio(false);
        break;
    }

    setUrlLeccion(name);
    setVerLeccion(true);
  };

  const handleRegresar = () => {
    setVerLeccion(false);
    setShowBtnDescarga(false);
    setShowBtnEjercicio(false);
    setUrlLeccion(null);
  };
  const handleSeccion = async (codigoSeccion, codigoCurso) => {
    const infoLeccion = {
      idPerfil: idPerfil,
      codigoLeccion: codigoSeccion + "01",
      fecha: getUnixTimestamp(),
    };

    const updateLeccion = await addUltimaLeccionTomada(infoLeccion);

    setContextValue({
      ...contextValue,
      codigoCursoSeleccionado: codigoCurso,
      codigoSeccionSeleccionada: codigoSeccion,
    });

    handleChangeComponent(multiViewComponentsPaths.curso);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
        </Col>
      </Row>

      <Row>
        <Col>
          <TitleDashboard title={"Material visto en capacitación"} />
        </Col>
      </Row>

      {!verLeccion ? (
        <>
          <Row className="mt-4">
            <Col>
              <Alert variant="success">
                <b>Nota importante:</b> en los vídeos presentados a continuación
                se pide presionar ciertas combinaciones de teclas para trabajar
                con atajos, sin embargo algunos pueden accionar funciones dentro
                del navegador, en estos casos se recomienda presionar las teclas
                que aparecen en la pantalla para continuar.
              </Alert>
            </Col>
          </Row>

          <Row>
            <Col>
              <Accordion activeIndex={0}>
                <AccordionTab header="Material de apoyo">
                  <Row className="text-center">
                    <Col>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://virtual.globalclickmexico.com/lecciones/capacitacion/Atajos%20Office%202010.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="pointer"
                      >
                        <b>Atajos Office 2010</b>
                      </a>
                    </Col>
                    <Col>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://virtual.globalclickmexico.com/lecciones/capacitacion/Atajos%20Office%202013.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="pointer"
                      >
                        <b>Atajos Office 2013</b>
                      </a>
                    </Col>
                    <Col>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://virtual.globalclickmexico.com/lecciones/capacitacion/Atajos%20Office%20365.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="pointer"
                      >
                        <b>Atajos Office 365</b>
                      </a>
                    </Col>
                  </Row>
                </AccordionTab>
              </Accordion>
            </Col>
          </Row>

          <Row className="mt-4 mb-4 justify-content-center ">
            .
            <Col className="text-center col-12 mb-5">
              <h3>
                <BsFillFileEarmarkExcelFill color="#3a87ad" /> Microsoft Word
              </h3>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="macros-word"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/macros_word/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid  img-capacitacion"
                  src={`${require(`@assets/images/portadas/macrosWord.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3 ">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="combinacion-correspondencia"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/comb_correspondencia/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion"
                  src={`${require(`@assets/images/portadas/combinacionCorrespondencia.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3 ">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="tablas-word"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/ex_en_word/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion"
                  src={`${require(`@assets/images/portadas/tablasWord.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
          </Row>

          <Row className="mt-4 mb-5 justify-content-center ">
            .
            <Col className="text-center col-12 mb-5">
              <h3>
                <BsFillFileEarmarkExcelFill color="#468847" /> Microsoft Excel
              </h3>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="transposicion-datos"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/transponer_datos/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion "
                  src={`${require(`@assets/images/portadas/transposicionDatos.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3 ">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="formato-condicional"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/formato_condicional/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion"
                  src={`${require(`@assets/images/portadas/formatoCondicional.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3 ">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="tablas-dinamicas"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/tablas_din/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion"
                  src={`${require(`@assets/images/portadas/tablasdinamicas.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="ejercicio-complementario"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/eje_complementario/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion "
                  src={`${require(`@assets/images/portadas/ejercicioComplementario.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3 ">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="tabla-pivote"
                  name="https://virtual.globalclickmexico.com/lecciones/capacitacion/pivot_table/index.html"
                  onClick={handleLeccion}
                  width="250"
                  className="img-fluid img-capacitacion"
                  src={`${require(`@assets/images/portadas/tablaPibote.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
            <Col className="col-9 col-sm-6 col-md-3 ">
              <Card className=" mb-4 material-capacitacion">
                <img
                  id="solver"
                  onClick={() => handleSeccion("EI1607", "EI16")}
                  width="250"
                  className="img-fluid img-capacitacion"
                  src={`${require(`@assets/images/portadas/solver.jpg`)}`}
                  alt="imagen leccion"
                />
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="mt-4 mb-5 justify-content-center">
          <Col className="mb-4">
            <span className="pointer" onClick={handleRegresar}>
              <FontAwesomeIcon icon={faArrowLeft} /> Regresar
            </span>
          </Col>

          <Col className="col-12 mb-4">
            <Row className="d-flex align-items-center justify-content-end">
              {showBtnDescarga && (
                <Col className="col-auto">
                  <a className="btn btn-primary" href={urlDescarga} download>
                    Descargar Plantilla
                  </a>
                </Col>
              )}
              {showBtnEjercicio && (
                <Col className="col-auto">
                  <a
                    className="btn btn-primary"
                    href="https://virtual.globalclickmexico.com/lecciones/capacitacion/Ejercicio_resuelto.xlsx"
                    download
                  >
                    Ejercicio Resuelto
                  </a>
                </Col>
              )}

              <Col className=" overlay col-auto">
                <OverlayTrigger
                  className="overlay-trigger"
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-bottom}`}>Pantalla completa.</Tooltip>
                  }
                >
                  <Button
                    className="btn-fullscreen"
                    onClick={requestFullscreen}
                    variant="secondary"
                  >
                    <FontAwesomeIcon icon={faExpand} />
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>

          <Col className="col-6">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                allow="autoplay; encrypted-media"
                loading="lazy"
                ref={iframeRef}
                title="Material visto en capacitación"
                src={urlLeccion}
                width={"100%"}
                height="500px"
              ></iframe>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default MaterialVistoCapacitacion;
