import { Notificacion } from "@components/general/Alertas/Alertas";
import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpFormDataRequest from "@helpers/httpRequests/httpFormDataRequest";
import {
  getGrupoById,
  getGrupos,
} from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { updateLeccionPersonalizada } from "@helpers/httpRequests/httpRequestsLeccionesPersonalizadas/httpRequestsLeccionesPersonalizadas";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

const EditarLeccion = ({ setLeccionEditada, infoLeccion }) => {
  const {
    nombre,
    archivoLeccion,
    nombrePlantilla,
    tipoArchivoPlantilla,
    idGrupo,
    idLeccion,
  } = infoLeccion;

  const [gruposEmpresariales, setGruposEmpresariales] = useState([]);

  //STATE PARA LOADER
  const [showLoader, setShowLoader] = useState(false);

  const [showFileLeccion, setShowFileLeccion] = useState(false);

  const [formValues, setFormValues] = useState({
    nombre,
    leccion: "",
    archivoLeccion,
    archivoLeccionAnterior:
      nombrePlantilla === null ? "" : nombrePlantilla.split(".")[0],
    tipoArchivo: tipoArchivoPlantilla,
    grupo: idGrupo,
    nombrePlantilla,
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleOnChangeFile = (event) => {
    const { name } = event.target;

    const { name: nombreArchivo, type } = event.target.files[0];

    if (name === "plantilla") {
      setFormValues({
        ...formValues,
        tipoArchivo: type,
        nombrePlantilla: nombreArchivo,
        archivoLeccion: nombreArchivo,
      });

      setShowFileLeccion(true);
    } else {
      setFormValues({
        ...formValues,
        [name]: event.target.files[0],
      });
    }
  };

  const agregarLeccion = async (e) => {
    e.preventDefault();

    try {
      setShowLoader(true);

      const {
        nombre,
        leccion,
        tipoArchivo,
        grupo,
        archivoLeccion,
        archivoLeccionAnterior,
        nombrePlantilla,
      } = formValues;

      const infoLeccion = {
        nombre,
        archivoLeccion: archivoLeccion.split(".")[0],
        nombrePlantilla,
        tipoArchivo: tipoArchivo,
        idGrupo: grupo,
        idLeccion,
        archivoLeccionAnterior,
      };

      const { message } = await updateLeccionPersonalizada(infoLeccion);

      if (leccion !== "") {
        const formData = new FormData();

        formData.append("leccion", leccion);

        await httpFormDataRequest({
          url: `${endpointsPaths.foroEmpresarial}/${
            httpRequestsPaths.foroEmpresarial.crudLecciones
              .updateArchivoLeccionEmpresarial
          }?archivoLeccionAnterior=${archivoLeccionAnterior}&nombreCarpeta=${
            archivoLeccion.split(".")[0]
          }&idGrupo=${grupo}`,
          body: formData,
        });
      }

      setLeccionEditada(true);

      Notificacion("success", message);
    } catch (error) {
      console.log(error);
      Notificacion(
        "error",
        "Ocurrió un error al modificar la lección, intentalo de nuevo"
      );
    }
  };

  useEffect(() => {
    const obtenerGruposEmpresariales = async () => {
      try {
        const { data } = await getGrupos();

        const { data: dataById } = await getGrupoById(idGrupo);
        // console.log(dataById);
        setFormValues({
          ...formValues,
          grupoActual: dataById.nombre,
          grupo: dataById.idGrupo,
        });

        // console.log(data);

        if (Array.isArray(data)) {
          setGruposEmpresariales(data);
        } else {
          setGruposEmpresariales([data]);
        }
      } catch (error) {
        console.log(error);
        setGruposEmpresariales([]);
      }
    };

    obtenerGruposEmpresariales();
  }, []);

  return (
    <Container>
      <Row>
        <Col className="text-start">
          <Form onSubmit={agregarLeccion}>
            <Form.Group className="mb-4">
              <Form.Label>Nombre de la lección</Form.Label>
              <Form.Control
                value={formValues.nombre}
                onChange={handleOnChange}
                name="nombre"
                type="text"
                placeholder="Nombre de la lección"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Plantilla de la lección</Form.Label>
              <p>
                {nombrePlantilla !== null ? (
                  { nombrePlantilla } - <b>Plantilla actual</b>
                ) : (
                  <b>No hay ninguna plantilla en esta lección</b>
                )}
              </p>
              {nombrePlantilla !== null && (
                <Form.Control
                  onChange={handleOnChangeFile}
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                  name="plantilla"
                  type="file"
                ></Form.Control>
              )}
            </Form.Group>

            {showFileLeccion && (
              <Form.Group className="mb-4">
                <Form.Label>Archivo comprimido de la lección</Form.Label>
                <Alert variant={"warning"}>
                  Se debe subir el archivo comprimido de la leccion que contenga
                  la nueva plantilla
                </Alert>
                <Form.Control
                  onChange={handleOnChangeFile}
                  accept="application/x-zip-compressed"
                  name="leccion"
                  type="file"
                ></Form.Control>
              </Form.Group>
            )}

            <Form.Group className="mb-4">
              <Form.Label>Descripción de la lección</Form.Label>
              <Form.Control onChange={handleOnChange} name="grupo" as="select">
                <option value={formValues.grupo}>
                  {formValues.grupoActual} - Grupo Actual
                </option>
                {gruposEmpresariales.length > 0 ? (
                  gruposEmpresariales.map((grupo, key) => (
                    <option key={key} value={grupo.idGrupo}>
                      {grupo.nombre}
                    </option>
                  ))
                ) : (
                  <option>No hay grupos empresariales</option>
                )}
              </Form.Control>
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button
                disabled={showLoader ? true : false}
                type="submit"
                size="lg"
              >
                Guardar{" "}
                {showLoader && (
                  <Spinner
                    animation="border"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      verticalAlign: "middle",
                    }}
                    className={`mx-3${!setShowLoader ? "d-none" : ""}`}
                  />
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditarLeccion;
