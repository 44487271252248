const bancosEmisores = [
    {
        key: 0,
        value: 'Elige un banco emisor',
    },
    {
        key: 1,
        value: 'Afirme'
    },
    {
        key: 2,
        value: 'American Express'
    },
    {
        key: 3,
        value: 'BanBajío'
    },
    {
        key: 4,
        value: 'Banca Mifel'
    },
    {
        key: 5,
        value: 'Banco Azteca'
    },
    {
        key: 6,
        value: 'Banco Famsa'
    },
    {
        key: 7,
        value: 'Banco Invex'
    },
    {
        key: 8,
        value: 'Banjercito'
    },
    {
        key: 9,
        value: 'Banorte'
    },
    {
        key: 10,
        value: 'BBVA'
    },
    {
        key: 11,
        value: 'Citibanamex'
    },
    {
        key: 12,
        value: 'HSBC'
    },
    {
        key: 13,
        value: 'Inbursa'
    },
    {
        key: 14,
        value: 'Liverpool'
    },
    {
        key: 15,
        value: 'Santander'
    },
    {
        key: 16,
        value: 'Scotiabank'
    }
];

export default bancosEmisores;