import { Notificacion } from "@components/general/Alertas/Alertas";
import { invitarEstudiante } from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import { getUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const InvitarUsuarioExistente = ({ infoGrupo, setUsuarioAgregado, idGrupo }) => {

  const [formValues, setFormValues] = useState({
    correo: "",
  });

  const handleOnChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleInvitacion = async (e) => {
    e.preventDefault();
    try {
      const enviarInvitacion = await invitarEstudiante({
        correo: formValues.correo,
        idGrupo,
      });

      setUsuarioAgregado(true);
      Notificacion("success", "Usuario agregado con éxito");
    } catch (error) {
      console.log(error);
      Notificacion("error", error.message);
    }
  };

  return (
    <Container style={{ textAlign: "start" }}>
      <Row>
        <Col>
          <Form onSubmit={handleInvitacion}>
            <Form.Group>
              <Form.Label>Correo electrónico:</Form.Label>
              <Form.Control
                value={formValues.correo}
                name="correo"
                onChange={handleOnChange}
                type="email"
                placeholder="ejemplo@ejemplo.com"
              ></Form.Control>
              <small>
                Escribe la dirección de correo ligada a la cuenta que deseas
                agregar.
              </small>
              <small>
                {" "}
                Al finalizar el nuevo usuario deberá:
                <ul>
                  <li>Cerrar su sesión e iniciar nuevamente</li>
                </ul>
              </small>
            </Form.Group>

            <Button type="submit" block>
              Agregar al grupo
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default InvitarUsuarioExistente;
