import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateLeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";

export const EliminarLeccion = ({ codigoLeccion, setLeccionEliminada }) => {
  const handleEliminarLeccion = async () => {
    try {
      await deactivateLeccion(codigoLeccion);

      setLeccionEliminada(true);

      Notificacion("success", "La lección fue eliminada con éxito");
    } catch (e) {
      console.log(e);

      Notificacion(
        "error",
        "Algo sucedió al eliminar la lección, vuelve a intentar"
      );
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "La lección no fue eliminada");
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro que deseas eliminar esta lección?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleEliminarLeccion}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
