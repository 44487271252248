import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getAsesores = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: asesores } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.asesor.getAsesores}`,
        method: "GET",
      });

      resolve(asesores);
    } catch (error) {
      // console.log("Error al consultar los asesores: ", error);

      reject(error);
    }
  });
};

export const getAsesorByGrupo = (idGrupo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: asesoresEmpresa } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.asesor.getAsesorByGrupo}/${idGrupo}`,
        method: "POST",
      });

      resolve(asesoresEmpresa);
    } catch (error) {
      // console.error(error);

      reject(error);
    }
  });
};

// export const reactivateAsesor = (idPerfil) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const asesorReactivado = await httpRequest({
// 				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.asesor.reactivateAsesor}/${idPerfil}`,
// 				method: "POST",
// 			});

// 			resolve(asesorReactivado);
// 		} catch (error) {
// console.log("Error al reactivar al asesor: ", error);

// 			reject(error);
// 		}
// 	});
// };

// export const deactivateAsesor = (idPerfil) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const deletedAsesor = await httpRequest({
// 				url: `${ endpointsPaths.usuarios }/${ httpRequestsPaths.usuarios.asesor.deleteAsesor }/${ idPerfil }`,
// 				method: "POST"
// 			});

// 			resolve(deletedAsesor);
// 		} catch (error) {
// console.log("Error al eliminar al asesor: ", error);

// 			reject(error);
// 		}
// 	});
// };
