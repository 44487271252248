import { Notificacion } from '@components/general/Alertas/Alertas';
import { SpinnerImage } from '@components/general/Spinners/SpinnerImage/SpinnerImage';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropZone } from '@helpers/DropZone/DropZone';
import getUnixFromDate from '@helpers/dates/getUnixFromDate/getUnixFromDate';
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';
import { addMision } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import Compressor from 'compressorjs';
import React from 'react'
import { useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap'

const AgregarMision = ({ setMisionAgregada, handleClose }) => {

    const [editable, setEditable] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const [spinnerImagen, setSpinnerImagen] = useState(false);

    const [formValues, setFormValues] = useState({
        titulo: "",
        descripcion: "",
        iconoProyecto: "",
        fechaExpiracion: 0
    })

    const handleOnChange = ({ currentTarget }) => {
        let { name, value } = currentTarget;
        console.log({ name, value })

        if (name === "fechaExpiracion") {
            const fechaFormateada = getUnixFromDate(value)
            console.log(fechaFormateada)
            value = fechaFormateada;
        }

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }


    const cleanPreview = () => {
        // setFormValues({
        // 	...formValues,
        // 	avatarPerfil: recoveryAvatarPerfil,
        // });

        setShowPreview(false);
    };

    const getBase64Image = ({ target }) => {
        try {
            const { files } = target;

            const [file] = files;

            const fileReader = new FileReader();

            if (!file) {
                return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
            }

            //!codigo para comparacion de peso de imagen antes de ser comprimida
            fileReader.readAsDataURL(file);

            new Compressor(file, {
                quality: 0.2,
                success(result) {
                    fileReader.readAsDataURL(result);

                    fileReader.onload = async function () {
                        setFormValues({ ...formValues, iconoProyecto: fileReader.result });

                        setShowPreview(true);
                    };
                },
                error(e) {
                    console.log(e);

                    Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
                },
            });
        } catch (e) {
            Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            await addMision({
                titulo: formValues.titulo,
                descripcion: JSON.stringify(formValues.descripcion),
                iconoProyecto: formValues.iconoProyecto,
                fechaExpiracion: formValues.fechaExpiracion,
                fechaRegistro: getUnixTimestamp()
            })
            setMisionAgregada(true);
            handleClose();
            Notificacion("success", "Misión agregada con éxito");
        } catch (error) {
            console.log(error)
            Notificacion("error", "Error al agregar la misión");
        }
    }


    return (
        <Container fluid style={{ textAlign: "start", width: "500px" }}>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <FloatingLabel className='mb-3' controlId="floatingTitle" label="Título">
                            <Form.Control required name='titulo' onChange={handleOnChange} value={formValues.titulo} type="text" placeholder="Título de la misión" />
                        </FloatingLabel>


                        <FloatingLabel className='mb-3' controlId="floatingDate" label="Fecha de expiración">
                            <Form.Control required name='fechaExpiracion' onChange={handleOnChange} type="date" placeholder="Fecha de expiración" />
                        </FloatingLabel>


                        <FloatingLabel className='mb-3' controlId="floatingTextarea2" label="Descripción de la misión">
                            <Form.Control
                                required
                                name='descripcion'
                                value={formValues.descripcion}
                                onChange={handleOnChange}
                                as="textarea"
                                placeholder="Agrega una descripción a la misión"
                                style={{ height: '100px' }}
                            />
                        </FloatingLabel>


                        <div className='text-center mb-3'>
                            {!editable && (
                                <Form.Group>
                                    {showPreview ? (
                                        <>
                                            <h6 className="mt-3 subtitulos">Vista previa</h6>

                                            <img width="250" height="250" className="mt-2 vista-previa img-fluid" src={formValues.iconoProyecto} alt="Imagen de perfil"></img>

                                            <Col className="mt-2 ">
                                                <h5>
                                                    <FontAwesomeIcon className='pointer' onClick={cleanPreview} icon={faTrash} />
                                                </h5>
                                            </Col>
                                        </>
                                    ) : (
                                        <>{spinnerImagen ? <SpinnerImage /> : <DropZone getImageInBase64={getBase64Image} />}</>
                                    )}
                                </Form.Group>
                            )}


                        </div>

                        <div className='d-grid gap-2'>
                            <Button type='submit'>Guardar misión</Button>
                        </div>


                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default AgregarMision