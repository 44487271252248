import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Compressor from "compressorjs";
import {
  Notificacion,
  NotificacionExtendida,
} from "@components/general/Alertas/Alertas";
import { addPregunta } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { DropZone } from "@helpers/DropZone/DropZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const AgregarPregunta = ({ setPreguntaAgregada }) => {
  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const [showPreviewImagenPregunta, setShowPreviewAvatarPerfil] =
    useState(false);

  const [tipoPregunta, setTipoPregunta] = useState({
    opcionMultiple: false,
    verdaderoFalso: false,
  });

  const [mostrarSelect, setMostrarSelect] = useState({
    selectCurso: true,
    selectSeccion: false,
    selectLeccion: false,
    error: false,
  });

  const [formValues, setFormValues] = useState({
    curso: "",
    seccion: "",
    leccion: "",
    pregunta: "",
    opcionRespuesta1: "",
    opcionRespuesta2: "",
    opcionRespuesta3: "",
    respuestaCorrecta: "",
    radioFalso: "FALSO",
    radioVerdadero: "VERDADERO",
    puntuacion: 0,
    imagenPregunta: "",
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    console.log(value);

    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === "curso" || name === "seccion" || name === "leccion") {
      handleMostrarSelect(name, value);
    }
  };

  const handleMostrarSelect = async (name, value) => {
    try {
      console.log(value);
      if (name === "curso") {
        //Lógica para consultar las secciones que correspondan al curso seleccionado

        const { data } = await getSeccionesByCodigoCurso(value);
        console.log(data);
        setSecciones(data);

        setMostrarSelect({
          ...mostrarSelect,
          selectSeccion: true,
          error: false,
        });
      }
      if (name === "seccion") {
        //Lógica para consultar las lecciones correspondientes a la seccion seleccionada

        const { data } = await getLeccionesByCodigoSeccion(value);
        console.log(data);
        setLecciones(data);

        setMostrarSelect({
          ...mostrarSelect,
          selectLeccion: true,
          error: false,
        });
      }
    } catch (error) {
      console.log(error);
      setSecciones([]);
      setLecciones([]);
      setMostrarSelect({
        ...mostrarSelect,
        error: true,
      });
    }
  };

  const handleTipoPregunta = ({ target }) => {
    const { value } = target;

    if (value === "Opcion multiple") {
      setTipoPregunta({
        ...tipoPregunta,
        opcionMultiple: true,
        verdaderoFalso: false,
      });
    } else if (value === "Verdadero/Falso") {
      setTipoPregunta({
        ...tipoPregunta,
        verdaderoFalso: true,
        opcionMultiple: false,
      });
    }
  };

  const getBase64Image = ({ target }) => {
    try {
      const { files } = target;

      const [file] = files;

      console.log(file);

      const fileReader = new FileReader();

      if (!file) {
        return Notificacion(
          "warning",
          "Ups! Algo salió mal, vuelve a cargar tu foto"
        );
      }

      //!codigo para comparacion de peso de imagen antes de ser comprimida
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.2,
        success(result) {
          fileReader.readAsDataURL(result);

          fileReader.onload = async function () {
            setFormValues({ ...formValues, imagenPregunta: fileReader.result });

            setShowPreviewAvatarPerfil(true);
          };
        },
        error(e) {
          console.log(e);

          Notificacion(
            "warning",
            "Ups!, algo salió mal, vuelve a cargar tu foto"
          );
        },
      });
    } catch (e) {
      Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
    }
  };

  const cleanImagenPregunta = () => {
    setFormValues({
      ...formValues,
      imagenPregunta: "",
    });

    setShowPreviewAvatarPerfil(false);
  };

  const guardarPregunta = async (e) => {
    e.preventDefault();
    //Lógica para guardar la pregunta nueva
    try {
      let informacionPregunta = {
        codigoCurso: formValues.curso,
        codigoSeccion: formValues.seccion,
        codigoLeccion: formValues.leccion,
        pregunta: formValues.pregunta,
        respuestaCorrecta: formValues.respuestaCorrecta,
        puntuacion: formValues.puntuacion,
        imagenPregunta: formValues.imagenPregunta,
      };

      if (tipoPregunta.opcionMultiple) {
        informacionPregunta.opcionesRespuesta = {
          1: formValues.opcionRespuesta1,
          2: formValues.opcionRespuesta2,
          3: formValues.opcionRespuesta3,
        };
        informacionPregunta.tipoPregunta = "Opcion multiple";
      }

      if (tipoPregunta.verdaderoFalso) {
        informacionPregunta.opcionesRespuesta = {
          1: formValues.radioFalso,
          2: formValues.radioVerdadero,
        };

        informacionPregunta.tipoPregunta = "Verdadero/Falso";
      }

      const { message } = await addPregunta(informacionPregunta);

      NotificacionExtendida("success", message);

      setPreguntaAgregada(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //Logica para traer todos los cursos al iniciar el componente

    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();
        setCursos(data);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursos();
  }, []);

  return (
    <>
      <Form onSubmit={guardarPregunta} className="text-start m-0 m-sm-4">
        <Container className="texto-izquierda">
          <Row>
            <Col md={6}>
              {/* Alerta para en caso de no encontrar datos de un curso o lección */}
              {mostrarSelect.error && (
                <Alert variant="danger">
                  No se encontrarón secciones o lecciones relacionadas,
                  selecciona otro curso.
                </Alert>
              )}

              {mostrarSelect.selectCurso && (
                <Form.Group>
                  <Form.Label>
                    Curso <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="mb-4"
                    onChange={handleOnChange}
                    as={"select"}
                    required
                    name="curso"
                  >
                    <option selected disabled value="0">
                      Selecciona un curso
                    </option>
                    {cursos.map((curso, idx) => (
                      <option key={idx} value={curso.codigoCurso}>
                        {curso.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
            <Col md={6} className="align-self-center">
              <p>
                <span className="text-danger">(* Campo requerido )</span>
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              {(mostrarSelect.selectSeccion, secciones.length > 1) && (
                <Form.Group>
                  <Form.Label>
                    Sección <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="mb-4"
                    onChange={handleOnChange}
                    as={"select"}
                    required
                    name="seccion"
                  >
                    <option selected disabled value="0">
                      Selecciona una sección
                    </option>
                    {secciones.map((seccion, idx) => (
                      <option key={idx} value={seccion.codigoSeccion}>
                        {seccion.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
            <Col>
              {(mostrarSelect.selectLeccion, lecciones.length > 1) && (
                <Form.Group>
                  <Form.Label>
                    Lección <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="mb-4"
                    onChange={handleOnChange}
                    as={"select"}
                    required
                    name="leccion"
                  >
                    <option selected disabled value="0">
                      Selecciona una lección
                    </option>
                    {lecciones.map((leccion, idx) => (
                      <option key={idx} value={leccion.codigoLeccion}>
                        {leccion.nombre}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md={9}>
              <Form.Group>
                <Form.Label>
                  Pregunta <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  type="text"
                  required
                  name="pregunta"
                  value={formValues.pregunta}
                  placeholder="Haz tu pregunta"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>
                  Puntuación <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="mb-4"
                  onChange={handleOnChange}
                  type="number"
                  required
                  name="puntuacion"
                  placeholder="Puntuación de la respuesta"
                ></Form.Control>
              </Form.Group>
            </Col>

            <Col>
              <Form.Label>
                Tipo de pregunta <span className="text-danger">*</span>
              </Form.Label>
              <Form.Group className="mb-4">
                <Form.Check
                  required
                  onChange={handleTipoPregunta}
                  inline
                  label="Opción Multiple"
                  value="Opcion multiple"
                  name="group1"
                  type={"radio"}
                  id={`inline-${"radio"}-1`}
                />

                <Form.Check
                  required
                  onChange={handleTipoPregunta}
                  inline
                  label="Verdadero/Falso"
                  value="Verdadero/Falso"
                  name="group1"
                  type={"radio"}
                  id={`inline-${"radio"}-2`}
                />
              </Form.Group>

              {tipoPregunta.opcionMultiple && (
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Opción #1 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        onChange={handleOnChange}
                        value={formValues.opcionRespuesta1}
                        type="text"
                        required
                        name="opcionRespuesta1"
                        placeholder="Primera opción"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Opción #2 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        onChange={handleOnChange}
                        value={formValues.opcionRespuesta2}
                        type="text"
                        required
                        name="opcionRespuesta2"
                        placeholder="Segunda opción"
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Opción #3 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        onChange={handleOnChange}
                        value={formValues.opcionRespuesta3}
                        type="text"
                        required
                        name="opcionRespuesta3"
                        placeholder="Tercera opción"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Respuesta Correcta{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        required
                        onChange={handleOnChange}
                        value={formValues.respuestaCorrecta}
                        as={"select"}
                        name="respuestaCorrecta"
                      >
                        <option value="0">Selecciona una respuesta</option>
                        {(formValues.opcionRespuesta1 !== "",
                        formValues.opcionRespuesta2 !== "",
                        formValues.opcionRespuesta3 !== "") && (
                          <>
                            <option value={formValues.opcionRespuesta1}>
                              {formValues.opcionRespuesta1}
                            </option>
                            <option value={formValues.opcionRespuesta2}>
                              {formValues.opcionRespuesta2}
                            </option>
                            <option value={formValues.opcionRespuesta3}>
                              {formValues.opcionRespuesta3}
                            </option>
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {tipoPregunta.verdaderoFalso && (
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Opción #1 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        disabled
                        type="text"
                        required
                        name="radioFalso"
                        placeholder="FALSO"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Opción #2 <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="mb-4"
                        disabled
                        type="text"
                        required
                        name="radioVerdadero"
                        placeholder="VERDADERO"
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Label>
                      Respuesta correcta <span className="text-danger">*</span>
                    </Form.Label>

                    <Form.Group className="mb-4">
                      <Form.Check
                        required
                        onChange={handleOnChange}
                        value="VERDADERO"
                        label="VERDADERO"
                        name="respuestaCorrecta"
                        type={"radio"}
                        id={`inline-${"radio"}-3`}
                      />
                      <Form.Check
                        required
                        onChange={handleOnChange}
                        value="FALSO"
                        label="FALSO"
                        name="respuestaCorrecta"
                        type={"radio"}
                        id={`inline-${"radio"}-4`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {showPreviewImagenPregunta ? (
                <Container className="mt-4 mb-4">
                  <Row>
                    <Col className="text-center">
                      <img
                        src={formValues.imagenPregunta}
                        alt="Imagen de pregunta"
                        width={200}
                        className="img-fluid"
                      ></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="col-6 text-center mt-2">
                      <Button variant="danger" onClick={cleanImagenPregunta}>
                        <FontAwesomeIcon className="pointer" icon={faTrash} />
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Form.Group className="mb-4">
                  <Form.Label>
                    Imagen de pregunta <span className="text-danger">*</span>
                  </Form.Label>
                  <DropZone getImageInBase64={getBase64Image} />
                </Form.Group>
              )}
            </Col>

            <div className="d-grid gap-2 mt-4">
              <Button type="submit" className="mt-4" size="lg">
                Agregar pregunta
              </Button>
            </div>
          </Row>
        </Container>
      </Form>
    </>
  );
};
