import { getConsejos, getConsejosByCodigo } from "@helpers/httpRequests/httpRequestsConsejos/httpRequestsConsejos";
import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";

import React, { useContext, useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Image, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import "@assets/css/components/ConsejosRapidos/style.css";
import TitleDashboard from "../Titles/TitleDashboard";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import { useRef } from "react";
import useWindowResize from "@customHooks/useWindowResize";
import { nombres } from "@helpers/cursos/cursos";
import { FaArrowRight, FaEye } from "react-icons/fa";
import { ModalSweetAlert } from "../Alertas/Alertas";
import VerDetalles from "./dataTables/Modales/VerDetalles";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { addUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { shorts } from "./shorts/shorts";
import VerReel from "@components/general/ConsejosRapidos/modales/VerReel";

const ConsejosRapidos = ({ handleChangeComponent }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idPerfil } = usuarioLogeado;

	const joyrideRef = useRef(null);

	const [tourActivo, setTourActivo] = useState(false);

	const [runTour, setRunTour] = useState(false);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".question-consejos",
			content: "Esta es la sección de consejos rápidos, aquí puedes consultar los atajos más relevantes que tienen los cursos que se ofrecen en la plataforma.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".lista-cursos",
			content: "Selecciona cualquiera de los cursos y se desplegarán los atajos disponibles, se te indicará una breve descripción con cada atajo y las versiones en la que se encuentra disponible.",
			disableBeacon: true,
			placement: "left-start",
		},
	]);

	const {
		size: { width },
	} = useWindowResize();

	const [consejos, setConsejos] = useState([]);

	const [dataTableColumns, setDataTableColumns] = useState([]);

	const [infoCursos, setInfoCursos] = useState([]);

	const [cursoActual, setCursoActual] = useState({
		codigoCurso: "EX21",
		nombreCurso: "Seleccione una categoría",
	});

	const [estadoSwitch, setEstadoSwitch] = useState(true);

	const verDetalles = (infoConsejo) => {
		ModalSweetAlert({
			title: "Información del consejo",
			html: <VerDetalles consejo={infoConsejo} />,
			showConfirmButton: false,
			showCloseButton: true,
		});
	};

	const [key, setKey] = useState("consejos");

	//STATE PARA ACTIVAR EL "ACTIVE" EN LOS CURSOS
	const [activeItem, setActiveItem] = useState({});

	const handleStartTour = () => {
		setRunTour(!runTour);
	};

	const handleOnChange = ({ target }) => {
		const { value, name } = target;
		setCursoActual({
			...cursoActual,
			codigoCurso: value,
			nombreCurso: name,
		});

		setActiveItem(name);
	};

	const handleSwitch = () => {
		setEstadoSwitch(!estadoSwitch);
	};

	const handleLeccion = async (codigoLeccion, codigoCurso) => {
		//FALTA PASAR EL CURSO Y EL CODIGO DE LA SECCION

		const codigoSeccion = codigoLeccion.substring(0, 6);

		const infoLeccion = {
			idPerfil: idPerfil,
			codigoLeccion,
			fecha: getUnixTimestamp(),
		};

		await addUltimaLeccionTomada(infoLeccion);

		setContextValue({
			...contextValue,
			codigoCursoSeleccionado: codigoCurso,
			codigoSeccionSeleccionada: codigoSeccion,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	const handleReel = (short) => {
		ModalSweetAlert({
			html: <VerReel infoShort={short} shorts={shorts} handleChangeComponent={handleChangeComponent} handleLeccion={handleLeccion} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "40%",
		});
	};

	useEffect(() => {
		const obtenerCursosConConsejos = async () => {
			let arregloAux = [];
			for (const nombre in nombres) {
				if (Object.hasOwnProperty.call(nombres, nombre)) {
					const element = nombres[nombre];
					try {
						const res = await getConsejosByCodigo(element.codigo);
						arregloAux.push({
							nombre: element.nombre,
							codigoCurso: element.codigo,
						});
					} catch (error) {
						// console.log(error)
					}
				}
			}

			setInfoCursos(arregloAux);
		};

		obtenerCursosConConsejos();
	}, []);
	

	useEffect(() => {
		if (cursoActual.codigoCurso !== "" && cursoActual.nombreCurso !== "Selecciona una categoría") {
			const obtenerConsejos = async () => {
				try {
					const { data: dataConsejos } = await getConsejosByCodigo(cursoActual.codigoCurso);
					setConsejos(Array.isArray(dataConsejos) ? dataConsejos : [dataConsejos]);
				} catch (error) {
					console.log(error);
					setConsejos([]);
				}
			};
			obtenerConsejos();

			setDataTableColumns([
				{
					name: "",
					cell: (row, id) => (
						<OverlayTrigger placement={"top"} overlay={<Tooltip>Más información</Tooltip>}>
							<div
								onClick={() => {
									verDetalles({
										titulo: row.titulo,
										descripcion: row.descripcion,
										versiones: row.versiones,
									});
								}}
								className="pointer">
								<FaEye color="grey" style={{ fontSize: "1.3rem" }} />
							</div>
						</OverlayTrigger>
					),
					ignoreRowClick: true,
					allowOverflow: true,
					center: true,
					width: "150px",
				},
				...columns,
				{
					name: "Para",
					cell: (row) => JSON.parse(row.versiones).toString().replace(/,/g, ", "),
					ignoreRowClick: true,
					allowOverflow: true,
					width: "500px",
				},
			]);
		}
	}, [cursoActual]);

	const breadcrumbItemsInitialState = [{ title: "Consejos Rápidos", onClick: () => {} }];

	const columns = useMemo(
		() => [
			{
				accessorKey: "titulo", //access nested data with dot notation
				header: "Título",
			},
			{
				accessorKey: "descripcion",
				header: "Descripción",
			},
			{
				accessorKey: "versiones",
				header: "Para",
				Cell: ({ cell, row }) => <p>{JSON.parse(row.original.versiones).toString().replace(/,/g, ", ")}</p>,
			},
		],
		[]
	);

	return (
		<Container fluid style={{ margin: "0" }} className="mt-2 p-0">
			<Joyride
				locale={spanishOptionsJoyRide}
				ref={joyrideRef}
				steps={tourSteps}
				run={runTour}
				continuous={true}
				showSkipButton
				hideCloseButton
				disableOverlayClose={true}
				disableCloseOnEsc={false}
				disableScrolling={false}
				scrollToFirstStep={true}
				scrollOffset={100}
				callback={(data) => {
					if (data.status === "finished") {
						setRunTour(false);
					} else if (data.action === "skip") {
						setRunTour(false);
					}
				}}
				styles={{
					options: {
						primaryColor: "#ff9900",
						textColor: "#00152A",
						width: 900,
						zIndex: 1000,
					},
				}}
			/>

			<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />

			<Row className=" justify-content-between">
				<Col className="col-auto mb-5">
					<TitleDashboard title={`${key === "consejos" ? "Consejos Rápidos" : "Global Click Shorts"}`} />
				</Col>

				{estadoSwitch && width > 768 && (
					<Col className="col-auto question-consejos">
						<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
							<FontAwesomeIcon className="mx-5 pointer " onClick={handleStartTour} style={{ fontSize: "2rem" }} icon={faQuestion} />
						</OverlayTrigger>
					</Col>
				)}
			</Row>

			{/* <Row className="justify-content-center mb-4" style={{height: "85px"}}>
					<Col className="col-3 shadow" style={{backgroundColor: "#f2f2f2", borderRadius: "10px"}} >
						<Row className={`${estadoSwitch ? 'justify-content-start' : 'justify-content-end'}`} style={{height: "100%"}}>
							<Col onClick={handleSwitch} className={`col-6 m-2 d-flex flex-column justify-content-center text-center shadow pointer  ${estadoSwitch ? 'consejos' : 'reels'}`} style={{backgroundColor: "#ff9900", borderRadius: "10px",}}>
								<span>{estadoSwitch ? <b>Shorts</b> : <b>Consejos Rápidos</b>}</span>
							</Col>
						</Row>
					</Col>
				</Row> */}

			<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} defaultActiveKey="consejos" className="test">
				<Tab eventKey="consejos" title="Consejos Rápidos">
					<>
						<h4 className="subtitulos d-none d-md-block mt-4">
							Categorías | <b>{cursoActual.nombreCurso}</b>
						</h4>

						<Row className="my-3 mt-4 justify-content-between" style={{ paddingBottom: "5rem" }}>
							<Col className="contenedor-cursos col-3 d-none d-lg-block lista-cursos">
								<div className="d-grid gap-2">
									{infoCursos.map((curso, key) => (
										// <Form.Check onChange={handleOnChange} key={key} label={curso.nombre} value={curso.codigoCurso} name="group1" type="radio" id={`radio-1`} />
										<Button
											key={key}
											variant={(activeItem, curso.nombre === cursoActual.nombreCurso) && "warning"}
											onClick={handleOnChange}
											size="lg"
											className="mt-3 mb-2 btn-responsive"
											name={curso.nombre}
											value={curso.codigoCurso}>
											{curso.nombre}
										</Button>
									))}
								</div>
							</Col>

							{/* //*SE DESPLIEGA CUANDO LA RESOOLUCION ES MUY PEQUEÑA */}
							<Col className="col-12 d-block d-lg-none">
								<Form.Group>
									<Form.Label>Curso del consejo</Form.Label>
									<Form.Control as={"select"} required onChange={handleOnChange}>
										<option value="0">Selecciona una opción</option>
										{infoCursos.map((curso, key) => (
											// <Form.Check onChange={handleOnChange} key={key} label={curso.nombre} value={curso.codigoCurso} name="group1" type="radio" id={`radio-1`} />
											<option key={key} text={curso.nombre} value={curso.codigoCurso}>
												{curso.nombre}
											</option>
										))}
									</Form.Control>

									<Form.Control.Feedback type="invalid">Selecciona un curso</Form.Control.Feedback>
								</Form.Group>
							</Col>

							<Col className="col-12 mb-md-0 pb-md-0 me-5" lg={8}>
								{/* <DataTablesRecipient columns={dataTableColumns} style={{ fontSize: "3rem" }} data={consejos} /> */}
								<MaterialReactTable
									columns={columns}
									data={consejos}
									localization={MRT_Localization_ES}
									muiTableContainerProps={{ sx: { maxHeight: "40vh" } }}
									enableFullScreenToggle={false}
									enableRowActions
									renderRowActions={({ row }) => (
										<Box
											sx={{
												display: "flex",
												flexWrap: "nowrap",
												gap: "8px",
												justifyContent: "center",
											}}>
											<IconButton color="primary" onClick={() => verDetalles(row.original)}>
												<FaEye color="grey" />
											</IconButton>
										</Box>
									)}
								/>
							</Col>
						</Row>
					</>
				</Tab>
				<Tab eventKey="shorts" title="Global Click Shorts">
					<>
						<Row style={{ marginBottom: "100px" }}>
							<Col style={{ position: "relative" }}>
								<h5>
									<b>Excel</b> | Desliza para ver más <FaArrowRight />
								</h5>
								<Swiper
									slidesPerView={4}
									spaceBetween={5}
									centeredSlides={true}
									pagination={{
										clickable: true,
									}}
									modules={[Pagination]}
									className="mySwiper">
									{shorts.map(
										(short, idx) =>
											short.curso === "EX21" && (
												<SwiperSlide>
													<Image onClick={() => handleReel(short)} fluid className="pointer" src={require(`@assets/images/portadasReels/${short.portada}`)} />
												</SwiperSlide>
											)
									)}
								</Swiper>
							</Col>
						</Row>

						<Row style={{ marginBottom: "100px" }} className="mt-4">
							<Col style={{ position: "relative" }}>
								<h5>
									<b>Excel</b> | Desliza para ver más <FaArrowRight />
								</h5>
								<Swiper
									slidesPerView={4}
									spaceBetween={5}
									centeredSlides={true}
									pagination={{
										clickable: true,
									}}
									modules={[Pagination]}
									className="mySwiper">
									{shorts.map(
										(short, idx) =>
											short.curso === "EX21" && (
												<SwiperSlide>
													<Image onClick={() => handleReel(short)} fluid className="pointer" src={require(`@assets/images/portadasReels/${short.portada}`)} />
												</SwiperSlide>
											)
									)}
								</Swiper>
							</Col>
						</Row>

						<Row style={{ marginBottom: "100px" }}>
							<Col style={{ position: "relative" }}>
								<h5>
									<b>Power Point</b> | Desliza para ver más <FaArrowRight />
								</h5>
								<Swiper
									slidesPerView={4}
									spaceBetween={5}
									centeredSlides={true}
									pagination={{
										clickable: true,
									}}
									modules={[Pagination]}
									className="mySwiper">
									{shorts.map(
										(short, idx) =>
											short.curso === "PP16" && (
												<SwiperSlide>
													<Image onClick={() => handleReel(short)} fluid className="pointer" src={require(`@assets/images/portadasReels/${short.portada}`)} />
												</SwiperSlide>
											)
									)}
								</Swiper>
							</Col>
						</Row>

						<Row style={{ marginBottom: "100px" }}>
							<Col style={{ position: "relative" }}>
								<h5>
									<b>Power BI</b> | Desliza para ver más <FaArrowRight />
								</h5>
								<Swiper
									slidesPerView={4}
									spaceBetween={5}
									centeredSlides={true}
									pagination={{
										clickable: true,
									}}
									modules={[Pagination]}
									className="mySwiper">
									{shorts.map(
										(short, idx) =>
											short.curso === "PB21" && (
												<SwiperSlide>
													<Image onClick={() => handleReel(short)} fluid className="pointer" src={require(`@assets/images/portadasReels/${short.portada}`)} />
												</SwiperSlide>
											)
									)}
								</Swiper>
							</Col>
						</Row>

						<Row style={{ marginBottom: "100px" }}>
							<Col style={{ position: "relative" }}>
								<h5>
									<b>Windows</b> | Desliza para ver más <FaArrowRight />
								</h5>
								<Swiper
									slidesPerView={4}
									spaceBetween={5}
									centeredSlides={true}
									pagination={{
										clickable: true,
									}}
									modules={[Pagination]}
									className="mySwiper">
									{shorts.map(
										(short, idx) =>
											short.curso === "WI10" && (
												<SwiperSlide>
													<Image onClick={() => handleReel(short)} fluid className="pointer" src={require(`@assets/images/portadasReels/${short.portada}`)} />
												</SwiperSlide>
											)
									)}
								</Swiper>
							</Col>
						</Row>
					</>
				</Tab>
			</Tabs>
		</Container>
	);
};

export default ConsejosRapidos;
