import { Button, Card, Col, Image } from "react-bootstrap";

import React from "react";

const PanelAdministracionItem = ({ id, title, icon, onClick }) => {
	return (
		<Col onClick={onClick} id={id} xs md={3} className="justify-content-center pointer">
			<Card className="mb-4 p-4 admin-panel-item align border-0">
				<Image style={{ margin: "0 auto" }} fluid src={require(`@assets/images/panelAdministracionIcons/${icon}`)} className="admin-panel-item-icon" />
				<Card.Body className="text-center">
					<Card.Title className="">
						<h6>{title}</h6>
					</Card.Title>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default PanelAdministracionItem;
