import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useState } from "react";

import { Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { updateLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import { useEffect } from "react";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const EditarLicencia = ({ licencia, setLicenciaEditada }) => {
	const [nombreLicencia, setNombreLicencia] = useState(licencia.nombre);

	const [cursos, setCursos] = useState([]);

	const [cursosLicencia, setCursosLicencia] = useState([]);

	const [nuevosCursos, setNuevoCursos] = useState([]);

	const [usuarioExtra, setUsuarioExtra] = useState(licencia.usuarioExtra);

	const [licenciaEmpresarial, setLicenciaEmpresarial] = useState(licencia.licenciaEmpresarial);

	const [licenciaInvitado, setLicenciaInvitado] = useState(licencia.licenciaInvitado);

	const [licenciaRenovacion, setLicenciaRenovacion] = useState(licencia.licenciaRenovacion);

  const [ loading, setLoading ] = useState(false);

	const handleUpdateLicencia = async (e) => {
		e.preventDefault();

		try {
      setLoading(true);
      
			await updateLicencia({
				idLicencia: licencia.idLicencia,
				nombre: nombreLicencia,
				usuarioExtra,
				licenciaEmpresarial,
				licenciaInvitado,
        licenciaRenovacion,
				cursos: JSON.stringify(nuevosCursos),
				...(nuevosCursos.length < cursosLicencia.length && {
					cursoEliminado: true,
					nuevosCursos: cursosLicencia.filter((cursoLicencia) => !nuevosCursos.some((cursoNuevo) => cursoNuevo.codigoCurso === cursoLicencia.codigoCurso)),
				}),
				...(nuevosCursos.length > cursosLicencia.length && {
					nuevoCurso: true,
					nuevosCursos: nuevosCursos.filter((curso) => !cursosLicencia.some((cursoLicencia) => cursoLicencia.codigoCurso === curso.codigoCurso)),
				}),
			});

			Notificacion("success", "Se actualizaron los cursos de la licencia correctamente");

      setLoading(false);

			setLicenciaEditada(true);
		} catch (error) {
			console.log("Error al agregar los cursos a la licencia seleccionada: ", error);

			NotificacionExtendida(error.message.includes("Se actualizaron los cursos de la licencia,") ? "info" : "error", error.message);
		}
	};

	const handleCheckboxChange = ({ target }) => {
		const { value, checked } = target;

		try {
			if (checked) {
				const curso = nuevosCursos.find((c) => c.codigoCurso === value);

				if (curso === undefined) {
					setNuevoCursos([...nuevosCursos, ...cursos.filter((curso) => curso.codigoCurso === value)]);
				}
			} else {
				setNuevoCursos((nuevosCursos) => nuevosCursos.filter((nuevoCurso) => nuevoCurso.codigoCurso !== value));
			}
		} catch (error) {
			console.log("Error al agregar el curso al listado de cursos de la licencia: ", error);
		}
	};

	const handleCheckboxUsuarioAdicional = ({ target }) => {
		try {
			setUsuarioExtra(target.checked);
		} catch (error) {
			console.log("Error al establecer un usuario adicional: ", error);
		}
	};

	const handleCheckboxLicenciaEmpresarial = ({ target }) => {
		try {
			setLicenciaEmpresarial(target.checked);
		} catch (error) {
			console.log("Error al establecer un usuario adicional: ", error);
		}
	};

	const handleCheckboxLicenciaInvitado = ({ target }) => {
		try {
			setLicenciaInvitado(target.checked);
		} catch (error) {
			console.log("Error al establecer un usuario adicional: ", error);
		}
	};
	
  const handleCheckboxLicenciaRenovacion = ({ target }) => {
		try {
			setLicenciaRenovacion(target.checked);
		} catch (error) {
			console.log("Error al establecer un usuario adicional: ", error);
		}
	};

	const handleOnChange = ({ target }) => {
		try {
			setNombreLicencia(target.value);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		try {
			const { cursos } = licencia;

			const parsedCursos = JSON.parse(cursos);

			setCursosLicencia(parsedCursos);

			setNuevoCursos(parsedCursos);
		} catch (error) {
			console.log("Error al obtener los cursos de la licencia: ", error);
		}

		const consultarCursos = async () => {
			try {
				const { data } = await getCursos();

				let cursosAux = [];

				data.forEach((curso) => {
					cursosAux.push({
						nombre: curso.nombre,
						codigoCurso: curso.codigoCurso,
					});
				});

				setCursos(cursosAux);
			} catch (error) {
				console.log("Error al consultar los cursos disponibles: ", error);

				Notificacion("error", "Error al consultar los cursos disponibles, vuelve a intentar");
			}
		};

		consultarCursos();
	}, []);

	return (
		<Form onSubmit={handleUpdateLicencia} className="text-start">
			<Container fluid>
				<Row className="mb-3">
					<Form.Group as={Col} controlId="formHorizontalPassword">
						<Form.Label>Nombre</Form.Label>

						<Form.Control type="text" name="nombre" onChange={handleOnChange} value={nombreLicencia} />
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<h5>Usuario adicional</h5>

						<Form.Check
							// className='checkbox-curso-disponible'
							defaultChecked={usuarioExtra}
							type="switch"
							id="usuarioExtra"
							label={usuarioExtra ? "Con usuario adicional" : "Sin usuario adicional"}
							onChange={handleCheckboxUsuarioAdicional}
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<h5>Licencia empresarial</h5>

						<Form.Check
							// className='checkbox-curso-disponible'
							defaultChecked={licenciaEmpresarial}
							type="switch"
							id="licenciaEmpresarial"
							label={licenciaEmpresarial ? "Es licencia empresarial" : "No es licencia empresarial"}
							onChange={handleCheckboxLicenciaEmpresarial}
						/>
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col}>
						<h5>Licencia de invitado</h5>

						<Form.Check
							// className='checkbox-curso-disponible'
							defaultChecked={licenciaInvitado}
							type="switch"
							id="licenciaInvitado"
							label={licenciaInvitado ? "Es licencia de invitado" : "No es licencia de invitado"}
							onChange={handleCheckboxLicenciaInvitado}
						/>
					</Form.Group>

          <Form.Group as={Col}>
            <h5>Licencia de Renovación</h5>

            <Form.Check
              // className='checkbox-curso-disponible'
              type="switch"
              id="licenciaRenovacion"
              label={
                licenciaRenovacion
                  ? "Es licencia de renovación"
                  : "No es licencia de renovación"
              }
              onChange={handleCheckboxLicenciaRenovacion}
            />
          </Form.Group>
				</Row>

				<Row className="mt-3">
					<Form.Group className="lista-cursos-disponibles" as={Col}>
						<h5>Cursos disponibles</h5>

						{cursos.map((curso, idx) => (
							<Form.Check
								className="checkbox-curso-disponible"
								defaultChecked={cursosLicencia.find((c) => c.codigoCurso === curso.codigoCurso) !== undefined}
								id={curso.codigoCurso}
								type="switch"
								label={`${curso.nombre} - ${curso.codigoCurso} - ${
									codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === curso.codigoCurso) !== undefined ? "Usuarios Invitados" : ""
								}`}
								key={idx}
								value={curso.codigoCurso}
								onChange={handleCheckboxChange}
							/>
						))}
					</Form.Group>

					<Col className="cursos-agregados-licencia">
						<h5>Cursos de la licencia</h5>

						{nuevosCursos.map((cursoSeleccionado, idx) => (
							<p key={idx}>
								{cursoSeleccionado.nombre} - {cursoSeleccionado.codigoCurso}
							</p>
						))}
					</Col>
				</Row>

				<Row className="mt-3">
					<Col>
						<div className="d-grid gap-2 mt-4">
							<Button type="submit" variant="success" size="lg">
                {
                  loading ?
                  <>
                    Actualizando... <FontAwesomeIcon icon={faSpinner} spin />
                  </>
                  :
								  'Guardar'
                }
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</Form>
	);
};
