import getURLCodigoQR from '@components/general/GenerarQR/getURLCodigoQR/getURLCodigoQR';
import getFechaSinHora from '@helpers/dates/getDateFinalizacionCurso/getFechaSinHora';
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';
import { getLeccionesVistasBI } from '@helpers/httpRequests/httpRequestLeccionesVistas/httpRequestsLeccionesVistas';
import { getFechaFinalizacionCurso, guardarCertificado } from '@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos';
import { getLeccionesByCodigoCurso } from '@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones';
import confetti from 'canvas-confetti';
import React, { useEffect, useState } from 'react'
import QRCode from "qrcode";
import { Button, Col, Row } from 'react-bootstrap'
import { NotificacionExtendida } from '@components/general/Alertas/Alertas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import getFilledPdfBytes from '@components/general/GenerarQR/getFilledPdfBytes/getFilledPdfBytes';
import { ProgressBar } from 'primereact/progressbar';

const CertificadosPowerBI = ({idPerfil, leccionActual, usuarioLogeado, infoCurso}) => {

    const [totalLecciones, setTotalLecciones] = useState(0);

    const [numLeccionesVistas, setNumLeccionesVistas]= useState(0);

    const [cursoTerminado, setCursoTerminado] = useState(false);

    const [porcentajeAvance, setPorcentajeAvance] = useState(0  );

    const calcularAvanceCurso = async(totalLecciones) =>{

        try {
            const results  = await getLeccionesVistasBI(idPerfil);

            const leccionesCompletadas = Array.isArray(results.data) ? results.data.length: [results.data].length;

            setNumLeccionesVistas(leccionesCompletadas);

            //Se obtienen la cantidad de lecciones vistas del curso y se compara con el total
            setPorcentajeAvance(Math.round((100 * leccionesCompletadas) / totalLecciones));

            if(totalLecciones === leccionesCompletadas){
                
                setCursoTerminado(true);
            }else{
                setCursoTerminado(false)

            }


        } catch (error) {
            console.log('Error al calcular el avance del curso')
        }
    }


    const {
        nombre: nombreCurso,
        isMsOffice,
        codigoCurso,
        tiempoCurso
      } = infoCurso;
    
      const { nombreCompleto, apellidos, curp, rol, idUsuarioEmpresarial } = usuarioLogeado;
    
      const [x, setX] = useState(0);
      const [y, setY] = useState(0);
    
      const nombreUsuario = nombreCompleto + " " + apellidos;
    
      // const [pdfInfo, setPdfInfo] = useState([]);
    
      const [dataImage, setDataImage] = useState({
        dataImage: "",
      });
    
      const generarQR = async () => {
        var end = Date.now() + 15 * 250;
    
        // go Buckeyes!
        var colors = ["#00152A", "#ff9900"];
    
        (function frame() {
          confetti({
            particleCount: 2,
            angle: 60,
            spread: 35,
            origin: { x: 0 },
            colors: colors,
          });
          confetti({
            particleCount: 2,
            angle: 120,
            spread: 35,
            origin: { x: 1 },
            colors: colors,
          });
    
          if (Date.now() < end) {
            requestAnimationFrame(frame);
          }
        })();
        try {
          const infoCertificado = {
            rol: rol,
            idUsuarioEmpresarial: idUsuarioEmpresarial,
            idPerfil,
            codigoCurso: codigoCurso,
            nombre: nombreUsuario,
            curp,
            fecha: getUnixTimestamp(),
            nombreCurso,
          };
    
          const { data } = await guardarCertificado(infoCertificado);
    
          const { data: fechaFinalizacion } = await getFechaFinalizacionCurso({
            idPerfil,
            codigoCurso,
          });
    
          let payloadCodigoQR = {
            fechaFinalizacion: getFechaSinHora(fechaFinalizacion.fecha),
            fechaUnix: fechaFinalizacion.fecha,
            nombre: nombreUsuario,
            nombreCurso: 'Power BI Avanzado',
            tiempoCurso: 20,
            idPerfil,
            codigoCurso: 'PBIA',
            curso: isMsOffice,
          };

    
          //TODO Hacer debug para encontrar el porque no pasa correctamente la informacion para generar el qr
    
          const urlQR = getURLCodigoQR(payloadCodigoQR);
    
          const qrData = await QRCode.toDataURL(urlQR);    
          payloadCodigoQR.dataQR = qrData;
    
          await createPdf(payloadCodigoQR);
        } catch (error) {
          console.log(error);
        }
      };
    
      //Dentro de este componente se deberá validar la categoría del curso para seleccionar el fetch correcto de la plantilla de certificado
    
      const createPdf = async (dataCodigoQR) => {

        try {
          let url = `${window.location.origin}/assets/templates/template_certificado.pdf`;
    
          if (isMsOffice) {
            url = `${window.location.origin}/assets/templates/template_certificado_microsoft_qr.pdf`;
          }
    
          const data = await fetch(url);
    
          const pdfTemplateBytes = await data.arrayBuffer();
    
          const filledPdfBytes = await getFilledPdfBytes(
            pdfTemplateBytes,
            dataCodigoQR
          );
    
          saveByteArray(`Certificado - ${nombreCurso}`, filledPdfBytes);
        } catch (error) {
          console.log(error);
        }
      };
    
      //Funcion para descargar el pdf sin la libreria
      const saveByteArray = (reportName, byte) => {
        var blob = new Blob([byte], { type: "application/pdf" });
    
        var link = document.createElement("a");
    
        link.href = window.URL.createObjectURL(blob);
    
        var fileName = reportName;
    
        setDataImage({
          ...dataImage,
          dataImage: link.href,
        });
    
        link.download = fileName;
    
        link.click();
    
        NotificacionExtendida("success", "Certificado generado con éxito");
      };
    
      useEffect(() => {
        const coordenadas = () => {
          // Obtener el elemento por su ID
          let elemento = document.getElementById("button-confetti");
    
          // Obtener las coordenadas X e Y del elemento
          let rect = elemento.getBoundingClientRect ();
    
          var x = rect.left;
          var y = rect.top;
    
          setX(parseInt(x));
          setY(parseInt(y));
        };
    
        coordenadas();

        const obtenerLeccionesBI = async() => {
          try {
              const lecciones = await getLeccionesByCodigoCurso('PBIA'); 
              setTotalLecciones(lecciones.data.length); 
              console.log(lecciones);

              calcularAvanceCurso(lecciones.data.length);
          } catch (error) {
              console.log('Error al recuperar las lecciones del curso')
          }
      }

      obtenerLeccionesBI();
      }, []);


    useEffect(() => {
        const obtenerLeccionesBI = async() => {
            try {
                const lecciones = await getLeccionesByCodigoCurso('PBIA'); 
                setTotalLecciones(lecciones.data.length); 
                console.log(lecciones);

                calcularAvanceCurso(lecciones.data.length);
            } catch (error) {
                console.log('Error al recuperar las lecciones del curso')
            }
        }
        obtenerLeccionesBI();

    }, [leccionActual])
    
    


  return (
    <Row>
        <Row className="mt-4 mb-3 justify-content-center">
              <Col className="col-10 text-center">
                <ProgressBar showValue={false} className="progressbar-evaluacion" color="#FF9900" value={porcentajeAvance}></ProgressBar>
              </Col>
					</Row>
        <Col>
            <h3>Tu progreso del curso: {porcentajeAvance}%</h3>
            <p>{cursoTerminado ? <span className='text-success'>Descarga tu certificado</span> : <span className='text-danger'>Completa todas las lecciones para desbloquear tu certificado</span>}</p>
            <Button
            id="button-confetti"
            disabled={cursoTerminado ? false : true}
            onClick={cursoTerminado ? generarQR : undefined}
            className="btn-sm "
            type="button"
          >
            Emitir certificado{" "}
            <FontAwesomeIcon className="ms-1" icon={faFileDownload} />
          </Button>
        </Col>
    </Row>
  )
}

export default CertificadosPowerBI