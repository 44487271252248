import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const VerDetalles = ({ consejo }) => {
  console.log(consejo);

  const { titulo, descripcion, versiones } = consejo;

  const versionesFormateadas = JSON.parse(versiones);

  console.log(versionesFormateadas);

  return (
    <Container style={{ textAlign: "start" }}>
      <Row>
        <Col className="col-12 mb-4">
          <span>
            <b>Titulo: </b> {titulo}
          </span>
        </Col>
        <Col className="col-12 mb-4">
          <span>
            <b>Descripción: </b> {descripcion}
          </span>
        </Col>
        <Col className="col-12 mb-4">
          <p>
            <b>Versiones disponibles:</b>
          </p>
          {versionesFormateadas.map((versionFormateada, idx) => (
            <p key={idx}>- {versionFormateada}</p>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default VerDetalles;
