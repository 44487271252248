import { Alert, AlertSimple, Notificacion } from '@components/general/Alertas/Alertas';
import { canjearRecompensa } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import React, { useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask';

const SolicitarRecompensa = ({tarjetaRegalo, infoUsuario, creditosDisponibles, setRecompensaCanjeada}) => {


    const {idPerfil, nombreCompleto, apellidos, correo }= infoUsuario;

    const {descripcion, valor } = tarjetaRegalo;

    const [showForm, setShowForm] = useState(false);

    const [showLoader, setShowLoader] = useState(false);

    const [formValues, setFormValues] = useState({
        telefonoCliente: ""
    })

    const handleOnChange = ({currentTarget}) =>{
        const {name, value} = currentTarget;

        setFormValues({
            ...formValues,
            [name]: value
        })
    }

    const solicitarRecompensa = async() =>{
        try {
            if(creditosDisponibles >= valor){

                setShowForm(true);
            }else{
                Notificacion("info", "Créditos insuficientes")
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    const cancelar = () => {
        Notificacion("info","No se ha canjeado la recompensa")
    }


    const handleSubmit = async(e) =>{
        try {
            e.preventDefault();
            setShowLoader(true);
            console.log({
                descripcionRecompensa: descripcion,
                nombreCliente: nombreCompleto + "" + apellidos,
                correoCliente: correo,
                telefonoCliente: formValues.telefonoCliente,
                costoRecompensa: valor,
                idPerfil
            })
            await canjearRecompensa({
                descripcionRecompensa: descripcion,
                nombreCliente: nombreCompleto + " " + apellidos,
                correoCliente: correo,
                telefonoCliente: formValues.telefonoCliente,
                costoRecompensa: valor,
                idPerfil
            })
            setShowLoader(false);

            setRecompensaCanjeada(true);
            Alert("Solicitud enviada", "success", "Se ha recibido tu solicitud, nos estaremos comunicando contigo para concluir con el proceso.")
        } catch (error) {
            console.log(error);
            setShowLoader(false);
            Notificacion("error", error.message);
        }
    }

  return (
    <Container>
       

        {showForm ? (
             <>
                <Row>
                    <Col>
                        <span><b>Por favor, proporciona un teléfono de contacto para poder comunicarnos contigo y hacerte llegar tu recompensa</b></span>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit} className='text-start mt-4'>

                    <Form.Group>
                        <Form.Label>Número de contacto</Form.Label>
                        <ReactInputMask
                        className="input-form form-control"
                        mask="(999) 999-9999"
                        maskChar=" "
                        required
                        onChange={handleOnChange}
                        value={formValues.telefono}
                        name="telefonoCliente"
                        type="tel"
                        placeholder="(999) 999-9999"
                    />
                    </Form.Group>

                    <div className='mt-3 d-grid gap-2'>
                        <Button disabled={showLoader} type='submit'>Solicitar recompensa {showLoader && <Spinner size='sm' animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>}</Button>
                    </div>

                </Form>
            </>
        ): (
            <>
             <Row>
                <Col>
                    <h2>{descripcion}</h2>
                    <span><b>¿Canjear recompensa?</b></span>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='d-flex gap-2 justify-content-center'>
                    <Button variant='success' onClick={solicitarRecompensa}>Si, canjear</Button>
                    <Button variant='danger' onClick={cancelar}>Cancelar</Button>
                </Col>
            </Row>
            </>
        )}
    </Container>
  )
}

export default SolicitarRecompensa