import SubtitleDashboard from "@components/general/Titles/SubtitleDashboard";
import UserContext from "@context/User/UserContext";
import { faLink, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getRecomendaciones,
  getRecomendacionesByCodigo,
} from "@helpers/httpRequests/httpRequestsRecomendaciones/httpRequestsRecomendaciones";
import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const RecomendacionesPrevias = ({ handleChangeComponent, listarLecciones }) => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const { idPerfil } = usuarioLogeado;

  const [recomendaciones, setRecomendaciones] = useState([]);

  const [arregloLecciones, setArregloLecciones] = useState([]);

  const [temas, setTemas] = useState([]);

  const [recomendacionesExcel, setRecomendacionesExcel] = useState(false);

  const [recomendacionesPower, setRecomendacionesPower] = useState(false);

  useEffect(() => {
    const obtenerRecomendaciones = async () => {
      try {
        const { data } = await getRecomendaciones(idPerfil);

        if (Array.isArray(data)) {
          setRecomendaciones(data);
        } else {
          setRecomendaciones([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerRecomendaciones();

    const validarRecomendacionesExcel = async () => {
      try {
        const { code: dataExcel } = await getRecomendacionesByCodigo({
          idPerfil,
          codigoCurso: "EX21",
        });
        console.log(dataExcel);
        if (dataExcel === 200) {
          setRecomendacionesExcel(true);
        }
      } catch (error) {
        console.log("Sin recomendaciones en un curso");
      }
    };

    validarRecomendacionesExcel();

    const validarRecomendacionesPower = async () => {
      try {
        const { code: dataPower } = await getRecomendacionesByCodigo({
          idPerfil,
          codigoCurso: "PB21",
        });
        console.log(dataPower);
        if (dataPower === 200) {
          setRecomendacionesPower(true);
        }
      } catch (error) {
        console.log("Sin recomendaciones en un curso");
      }
    };

    validarRecomendacionesPower();
  }, []);

  useEffect(() => {
    recomendaciones.map((dato, idDato) => {
      arregloLecciones.push(JSON.parse(dato.leccionesRecomendadas));
    });

    recomendaciones.map((dato, idDato) => {
      temas.push(dato.tema);
    });
  }, [recomendaciones]);

  return (
    <>
      <Row className="mt-4 mb-3 justify-content-center">
        <Col className="col-12">
          <h4>Retroalimentación: </h4>
        </Col>
      </Row>

      {recomendaciones.length > 0 ? (
        <Row className="mt-3 mb-5">
          {recomendacionesExcel && (
            <Col className="col-12 mb-3">
              <SubtitleDashboard title={"Excel"} />

              <div className="card">
                <Accordion activeIndex={false}>
                  {recomendaciones.map(
                    (recomendacion, idRecomendacion) =>
                      recomendacion.codigoCurso === "EX21" && (
                        <AccordionTab header={recomendacion.tema}>
                          <ul>
                            {arregloLecciones.map(
                              (lecciones, idLecciones) =>
                                recomendacion.tema === temas[idLecciones] &&
                                lecciones.map((leccion, idLeccion) => (
                                  <li>{leccion.nombre}</li>
                                ))
                            )}
                          </ul>
                          <span
                            id={recomendacion.leccionesRecomendadas}
                            onClick={listarLecciones}
                            className="pointer"
                            style={{ color: "#007bff" }}
                          >
                            <FontAwesomeIcon className="mx-1" icon={faLink} />{" "}
                            Ver contenido recomendado
                          </span>
                        </AccordionTab>
                      )
                  )}
                </Accordion>
              </div>
            </Col>
          )}

          {recomendacionesPower && (
            <Col className="col-12 mb-3">
              <SubtitleDashboard title={"Power BI"} />
              <div className="card">
                <Accordion activeIndex={false}>
                  {recomendaciones.map(
                    (recomendacion, idRecomendacion) =>
                      recomendacion.codigoCurso === "PB21" && (
                        <AccordionTab header={recomendacion.tema}>
                          <ul>
                            {arregloLecciones.map(
                              (lecciones, idLecciones) =>
                                recomendacion.tema === temas[idLecciones] &&
                                lecciones.map((leccion, idLeccion) => (
                                  <li>{leccion.nombre}</li>
                                ))
                            )}
                          </ul>
                          <span
                            id={recomendacion.leccionesRecomendadas}
                            onClick={listarLecciones}
                            className="pointer"
                            style={{ color: "#007bff" }}
                          >
                            <FontAwesomeIcon className="mx-1" icon={faLink} />{" "}
                            Ver contenido recomendado
                          </span>
                        </AccordionTab>
                      )
                  )}
                </Accordion>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
            <FontAwesomeIcon icon={faList} className="mb-3 icon" size="4x" />
            {/* <img src={require('@assets/images/ca1mara-de-video.png')} className="img-fluid" width={80} alt='Trabajo en equipo'></img> */}
            <h4>Aún no cuentas con ninguna retroalimentación generada</h4>
            <p>
              Realiza el examen de diagnóstico para obtener una
              retroalimentación en base a tus resultados.
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default RecomendacionesPrevias;
