import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getUsuariosRanking = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: usuariosRanking } = await httpRequest({
        url: `${endpointsPaths.usuariosRankings}/${httpRequestsPaths.usuariosRanking.getRanking}/${codigoCurso}`,
        method: "GET",
      });

      resolve(usuariosRanking);
    } catch (error) {
      // console.log("Error al agregar el curso: ", error);

      reject(error);
    }
  });
};
