import regExpStrings from "@helpers/regExpStrings/regExpStrings";

export const handlePassword = (password) => {
  /**
     * ? Contraseña
     * ?Restricciones:
     *               1-No estar vacio
     *               2-almenos 8 caracteres
     *               3-almenos una mayuscula
     *               4-almenos un numero
     *! EN CASO DE REQUERIR CAMBIAR LAS RESTRICCIONES DE LA CONTASEÑA SE ANEXAN LOS DIFERENTES REGEX :D
     * 
     * ?Mínimo ocho caracteres, al menos una letra y un número:
      * ?     "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
          Mínimo ocho caracteres, al menos una letra, un número y un carácter especial:

        * ?   "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
          Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula y un número:

       * ?    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
          Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial:

       * ?    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          Mínimo ocho y máximo 10 caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial:

        * ?   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"
     */

  try {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    let validacionPass = regex.test(password);
    // console.log(validacionPass);

    if (validacionPass) {
      return { validacion: true, descripcion: "Contraseña Valida" };
    } else {
      let regexCaracteresEspeciales =
        /^(?=.*[@$!%*?&])[A-Za-z\d@$!%_"#*?&]{8,}$/;
      if (password.length > 7) {
        let validacionEspecial = regexCaracteresEspeciales.test(password);
        if (validacionEspecial) {
          return {
            validacion: false,
            descripcion: "No se admiten caracteres especiales en la contraseña",
          };
        } else {
          return {
            validacion: false,
            descripcion:
              "Revisa que la contraseña tenga todas las caracteristicas requeridas",
          };
        }
      }
    }
  } catch (e) {
    return {
      validacion: false,
      descripcion: `error ${e.message}`,
    };
  }
};

export const handleCurpAPI = async (curp) => {
  try {
    let response = {
      validacion: false,
      descripcion: "",
      data: null,
    };

    if (curp.length > 18) {
      return {
        ...response,
        descripcion: "El CURP no debe tener más de 18 caracteres",
      };
    } else if (curp.length < 18) {
      return {
        ...response,
        descripcion: "El CURP debe tener sólo 18 caracteres",
      };
    }

    if (
      regExpStrings.regexCaracteresEspeciales(curp) &&
      regExpStrings.regexCurp(curp)
    ) {
      return {
        ...response,
        descripcion: "El CURP está mal escrito, revisa tu información",
      };
    }

    // const { message, data } = await validateCurp(curp);

    return {
      validacion: true,
      descripcion: "CURP válido",
      // data,
    };
    // switch (json.code) {
    // 	case 200:
    // 		return {
    // 			validacion: true,
    // 			descripcion: json.message,
    // 			data: json.data
    // 		};

    // 	case 406:
    // 		return {
    // 			validacion: false,
    // 			descripcion: "Esta curp ya esta siendo utilizada!!!",
    // 			code: 409
    // 		};
    // 	case 409:
    // 		return {
    // 			validacion: false,
    // 			code: 406,
    // 			descripcion: "Curps no coincidentes , revisa  la api del curp pruebas",
    // 			data: json.data
    // 		};

    // 	default:
    // 		break;
    // }
  } catch (error) {
    return {
      validacion: false,
      descripcion: `No se pudo recuperar la información del CURP`,
    };
  }
};

export const handleEncrypt = (password) => {
  try {
    let mensajeEncriptado = window.btoa(password);
    return mensajeEncriptado;
  } catch (e) {
    console.error("Error con el metodo de encriptaion de contraseñas");
  }
};

export const handleDecrypt = (password) => {
  try {
    let mensajeDesencriptado = atob(password);
    return mensajeDesencriptado;
  } catch (e) {
    console.error("Error con el metodo de desencryptacion de contraseñas");
  }
};

export const handleName = (nombreCompleto) => {
  if (nombreCompleto) {
    if (nombreCompleto.length > 2) {
      return {
        validacion: true,
        descripcion: "nombre aceptado",
      };
    } else {
      return {
        validacion: false,
        descripcion: "nombre menor a lo permitido",
      };
    }
  } else {
    return {
      validacion: false,
      descripcion: "no se recibio ningun parametro",
    };
  }
};

export const handlePuntuation = (puntaje) => {
  try {
    if (puntaje) {
      if (puntaje.length > 0) {
        return {
          validacion: true,
          descripcion: "puntaje aceptado",
        };
      } else {
        return {
          validacion: false,
          descripcion: "puntaje menor a lo permitido",
        };
      }
    } else {
      return {
        validacion: false,
        descripcion: "no se recibio ningun parametro",
      };
    }
  } catch (e) {
    console.log("error en handlePuntuation");
    console.log(e);
  }
};

export const handleDescription = (description) => {
  if (description) {
    if (description.length >= 5) {
      return {
        validacion: true,
        descripcion: "descripcion aceptada ",
      };
    } else {
      return {
        validacion: false,
        descripcion: "descripcion  menor a lo permitido de 5 caracteres",
      };
    }
  } else {
    return {
      validacion: false,
      descripcion: "no se recibio ningun parametro",
    };
  }
};

export const handleLastName = (apellidos) => {
  if (apellidos) {
    if (apellidos.length >= 1) {
      return {
        validacion: true,
        descripcion: "apellidos aceptados",
      };
    } else {
      return {
        validacion: false,
        descripcion: "apellidos menor a lo permitido",
      };
    }
  } else {
    return {
      validacion: false,
      descripcion: "no se recibio ningun parametro",
    };
  }
};

export const handleCorreo = async (preCorreo) => {
  // console.log("precorreo");
  // console.log(preCorreo);
  if (preCorreo.length > 10) {
    console.log("mayor a 10 caracteres");

    let results = preCorreo.split("@");

    console.log(results);

    if (results.length < 2) {
      return {
        validacion: false,
        descripcion: "correo incompleto",
        code: 404,
      };
    } else {
      console.log("tiene el @ ");

      let results2 = preCorreo.split(".com");

      if (results2.length < 2) {
        return {
          validacion: false,
          descripcion: "correo incompleto",
          code: 404,
        };
      } else {
        console.log("tiene el .com ");

        let results3 = preCorreo.substring(
          preCorreo.length,
          preCorreo.length - 4
        );

        if (results3 !== ".com") {
          return {
            validacion: false,
            descripcion: "correo invalido",
            code: 404,
          };
        }
        console.log("substring despues del .com");

        console.log(results3);

        return {
          validacion: true,
          descripcion: "correo valido",
          code: 200,
        };
      }
    }
  } else {
    return {
      validacion: false,
      descripcion: "correo incompleto",
      code: 404,
    };
  }
};

export const handleProfesion = (profesion) => {
  if (profesion.length > 2) {
    return {
      validacion: true,
      descripcion: "Profesion aceptada",
    };
  } else {
    return {
      validacion: false,
      descripcion: "Profesion menor a lo aceptado",
    };
  }
};

export const handleFormatTime = (timeStamp) => {
  // console.log("handleGetDate");
  // console.log(timeStamp);
  try {
    var date = new Date(parseInt(timeStamp) * 1000);
    let months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    let dateFormated =
      date.getDate() +
      "/" +
      months[date.getMonth()] +
      "/" +
      date.getFullYear() +
      " " +
      date.toLocaleTimeString("en-US");
    // ":" +
    // date.getMinutes();
    return `${dateFormated}`;
  } catch (e) {
    console.error("handleGetDate");
    console.error(e.message);
  }
};

export const handleFormatDate = (timeStamp) => {
  const unixTimestamp = timeStamp;

  const milliseconds = unixTimestamp * 1000; //

  const dateObject = new Date(milliseconds);

  // const humanDateFormat = dateObject.toLocaleString() //2019-12-9 10:30:15

  // let diaString=dateObject.toLocaleString("en-US", {weekday: "long"}) // Monday
  // let mesString=dateObject.toLocaleString("en-US", {month: "long"}) // December
  // let horaAMPM=dateObject.toLocaleString("en-US", {hour: "numeric"}) // 10 AM
  // let minutosNumber=dateObject.toLocaleString("en-US", {minute: "numeric"}) // 30
  // let segundoNumber=dateObject.toLocaleString("en-US", {second: "numeric"}) // 15
  // let fechaYHora=dateObject.toLocaleString("en-US", {timeZoneName: "short"}) // 12/9/2019, 10:30:15 AM CST
  let diaNumber = dateObject.toLocaleString("en-US", { day: "numeric" }); // 9
  let anioNumber = dateObject.toLocaleString("en-US", { year: "numeric" }); // 2019
  let mesNumber = dateObject.toLocaleString("en-US", { month: "numeric" }); // 2019

  if (diaNumber < 10) {
    let aux = `0${diaNumber}`;
    diaNumber = aux;
  }

  if (mesNumber < 10) {
    let aux = `0${mesNumber}`;
    mesNumber = aux;
  }

  return `${anioNumber}-${mesNumber}-${diaNumber}`;
};

export const getDateNow = () => {
  const date = new Date();

  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];
  let dia = "";
  let mes = "";
  let anio = year;

  if (day < 10) {
    let aux = `0${day}`;
    dia = aux;
  }
  if (month < 10) {
    let aux = `0${month}`;
    mes = aux;
  }
  console.log(dia + "/" + mes + "/" + anio);

  return `${dia}/${mes}/${anio}`;
};

export const phoneNumberValidator = (phoneNumber) => {
    const phoneNumberRegExp = /\(?([0-9]{2,3})\)?([ .-]?)([0-9]{3})-([0-9]{2}-?([0-9]{2}))/;
  
    return phoneNumberRegExp.test(phoneNumber);
};


export const emailValidator = (email) => {
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegEx.test(email);
};