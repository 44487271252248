import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const validarCreditoUsuario = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: validarCredito } = await httpRequest({
        url: `${endpointsPaths.salaEntretenimiento}/${httpRequestsPaths.salaEntretenimiento.validarCreditoUsuario}/${idPerfil}`,
        method: "POST",
      });

      resolve(validarCredito);
    } catch (error) {
      // console.log("Error al obtener las secciones: ", error);

      reject(error);
    }
  });
};

export const addUsuarioSinFicha = async (infoUsuario) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: validarCredito } = await httpRequest({
          url: `${endpointsPaths.salaEntretenimiento}/${httpRequestsPaths.salaEntretenimiento.addUsuarioSinFicha}`,
          method: "POST",
          body: JSON.stringify(infoUsuario)
        });
  
        resolve(validarCredito);
      } catch (error) {
        // console.log("Error al obtener las secciones: ", error);
  
        reject(error);
      }
    });
  };

  
export const liberarFicha = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: validarCredito } = await httpRequest({
        url: `${endpointsPaths.salaEntretenimiento}/${httpRequestsPaths.salaEntretenimiento.liberarFicha}/${idPerfil}`,
        method: "POST",
      });

      resolve(validarCredito);
    } catch (error) {
      // console.log("Error al obtener las secciones: ", error);

      reject(error);
    }
  });
};

  
export const registrarVisitaSalaEntretenimiento = async (infoUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: validarCredito } = await httpRequest({
        url: `${endpointsPaths.salaEntretenimiento}/${httpRequestsPaths.salaEntretenimiento.registrarVisita}`,
        method: "POST",
        body: JSON.stringify(infoUsuario)
      });

      resolve(validarCredito);
    } catch (error) {
      // console.log("Error al obtener las secciones: ", error);

      reject(error);
    }
  });
};


export const registrarUsoJuegoSalaEntretenimiento = async (infoJuegos) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: registrarUso } = await httpRequest({
        url: `${endpointsPaths.salaEntretenimiento}/${httpRequestsPaths.salaEntretenimiento.registrarUsoJuegos}`,
        method: "POST",
        body: JSON.stringify(infoJuegos)
      });

      resolve(registrarUso);
    } catch (error) {
      // console.log("Error al obtener las secciones: ", error);

      reject(error);
    }
  });
};