import React from "react";
import { Spinner } from "react-bootstrap";

import "@assets/css/components/Spinners/SpinnerRing/style.css";

const SpinnerRing = () => {
  return (
    <>
      <Spinner
        variant="dark"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />

      <span className="sr-only">Cargando...</span>
    </>
  );
};

export default SpinnerRing;
