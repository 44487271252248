import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getInvitados = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.invitados.getInvitados}`,
        method: "GET",
      });

      resolve(data);
    } catch (error) {
      // console.log("Error al consultar a los usuarios invitados: ", error);

      reject(error);
    }
  });
};

export const getCodigosInvitado = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.invitados.getCodigosInvitado}`,
        method: "GET",
      });

      resolve(data);
    } catch (error) {
      // console.log("Error al obtener los códigos de invitado: ", error);

      reject(error);
    }
  });
};

export const validateCodigoInvitado = (codigoInvitado) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.invitados.validateCodigoInvitado}`,
        method: "POST",
        body: JSON.stringify({ codigoInvitado }),
      });

      resolve(data);
    } catch (error) {
      // console.log("Error al validar el código de invitado: ", error);

      reject(error);
    }
  });
};

export const generarCodigosInvitado = (cantidadCodigos) => {
  return new Promise(async (resolve, reject) => {
    try {
      const codigosGenerados = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.invitados.generarCodigosInvitado}`,
        method: "POST",
        body: JSON.stringify({ cantidadCodigos }),
      });

      resolve(codigosGenerados);
    } catch (error) {
      // console.log("Error al generar los códigos de invitado solicitados: ", error);

      reject(error);
    }
  });
};
