import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";


export const consultarIntentos = (consulta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.consultarIntentos}`,
        method: "POST",
        body: JSON.stringify(consulta),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      console.log(error.status);
      reject(error);
    }
  });
};

export const updateConsultas = (consulta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.updateConsultas}`,
        method: "POST",
        body: JSON.stringify(consulta),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      console.log(error.status);
      reject(error);
    }
  });
};

export const sinConsultas = (consulta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.sinConsultas}`,
        method: "POST",
        body: JSON.stringify(consulta),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      console.log(error.status);
      reject(error);
    }
  });
};

export const nuevasConsultas = (consulta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.nuevasConsultas}`,
        method: "POST",
        body: JSON.stringify(consulta),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      console.log(error.status);
      reject(error);
    }
  });
};

export const sendGPTRequest = (consulta) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: respuesta } = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.sendGPTRequest}`,
        method: "POST",
        body: JSON.stringify(consulta),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      console.log(error.status);
      reject(error);
    }
  });
};

export const textToImageRequest = (consulta ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.sendTextToImageRequest}`,
        method: "POST",
        body: JSON.stringify(consulta),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      console.log(error.status);
      reject(error);
    }
  });
};


export const textToVoice = ( consulta ) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(consulta)
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.sendTextToVoiceRequest}`,
        method: "POST",
        body: JSON.stringify(
          consulta,
        ),
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};


export const getHerramientasSuite = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const respuesta = await httpRequest({
        url: `${endpointsPaths.openAI}/${httpRequestsPaths.openAI.getHerramientasSuite}`,
        method: "GET",
      });

      resolve(respuesta);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};