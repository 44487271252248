import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const validarVigenciaUsuario = (idCredencial) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: validacionVigencia } = await httpRequest({
        url: `${endpointsPaths.verificarVigencia}/${httpRequestsPaths.usuarios.general.validarVigenciaUsuario}/${idCredencial}`,
        method: "POST",
      });

      resolve(validacionVigencia);
    } catch (error) {
      // console.log("Error validando la vigencia de la credencial");

      reject(error);
    }
  });
};
