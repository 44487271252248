import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";

export const getPlantillasTrabajo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: plantillasTrabajo } = await httpRequest({
        url: `${endpointsPaths.plantillasTrabajo}/${httpRequestsPaths.plantillasTrabajo.getPlantillasTrabajo}`,
        method: "GET",
      });

      resolve(plantillasTrabajo);
    } catch (error) {
      reject(error);
    }
  });
};

export const addPlantillaTrabajo = (plantilla) => {
  return new Promise(async (resolve, reject) => {
    try {
      const plantillaAgregada = await httpRequest({
        url: `${endpointsPaths.plantillasTrabajo}/${httpRequestsPaths.plantillasTrabajo.addPlantillaTrabajo}`,
        method: "POST",
        body: JSON.stringify(plantilla),
      });

      resolve(plantillaAgregada);
    } catch (error) {
      reject(error);
    }
  });
};

export const deletePlantillaTrabajo = (idPlantillaTrabajo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const plantillaTrabajoEliminada = await httpRequest({
        url: `${endpointsPaths.plantillasTrabajo}/${httpRequestsPaths.plantillasTrabajo.deletePlantillaTrabajo}/${idPlantillaTrabajo}`,
        method: "POST",
      });

      resolve(plantillaTrabajoEliminada);
    } catch (error) {
      reject(error);
    }
  });
};

export const updatePlantillaTrabajo = (plantilla) => {
  return new Promise(async (resolve, reject) => {
    try {
      const plantillaTrabajoActualizada = await httpRequest({
        url: `${endpointsPaths.plantillasTrabajo}/${httpRequestsPaths.plantillasTrabajo.updatePlantillaTrabajo}`,
        method: "POST",
        body: JSON.stringify(plantilla),
      });

      resolve(plantillaTrabajoActualizada);
    } catch (error) {
      reject(error);
    }
  });
};
