import React from "react";
import { Typography } from "@mui/material";
import GestionCertificacionMosTable from "./GestionCertificacionMosTable";

const GestionCertificacionMos = ({ isUsuarioExterno = false, certificacion }) => {

	return (
		<>
			<hr />

			<Typography variant="h6" gutterBottom>
				Usuario{isUsuarioExterno ? " Externo" : ""}: {`${certificacion.nombreCompleto} ${certificacion.apellidos}`}
			</Typography>

			<GestionCertificacionMosTable certificacion={certificacion} intentos={certificacion.intentos} />
		</>
	);
};

export default GestionCertificacionMos;
