import { Notificacion } from "@components/general/Alertas/Alertas";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { addExtensionTiempoVigencia } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const ExtensionVigencia = ({ usuario, setRefetchUsuarios }) => {
  const [fechaExpiracionExtendida, setFechaExpiracionExtendida] = useState(0);

  const [cantidadMesesExtra, setCantidadMesesExtra] = useState(0);

  const [motivoExtension, setMotivoExtension] = useState("");

  const {
    idPerfil,
    idCredencial,
    nombreCompleto,
    apellidos,
    correo,
    fechaExpiracion,
    cantidadExtensionesAplicadas,
    motivoUltimaExtension,
  } = usuario;

  const usuarioSeleccionado = `${nombreCompleto} ${apellidos}`.trim();

  const handleSeleccionTiempoVigencia = ({ target }) => {
    const cantidadMesesExtra = target.value;

    if (cantidadMesesExtra === "") {
      return setFechaExpiracionExtendida(0);
    }

    const fechaExpiracionMoment = moment
      .unix(fechaExpiracion)
      .utcOffset("-06:00");

    const nuevaFechaExpiracionMoment = fechaExpiracionMoment.add(
      cantidadMesesExtra,
      "months"
    );

    setFechaExpiracionExtendida(nuevaFechaExpiracionMoment.unix());

    setCantidadMesesExtra(cantidadMesesExtra);
  };

  const handleOnChange = ({ target }) => {
    setMotivoExtension(target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (fechaExpiracionExtendida === 0) {
        return NotificationManager.error(
          "Elige un plan de extensión de vigencia para continuar"
        );
      }

      if (motivoExtension === "") {
        return NotificationManager.error(
          "Ingresa un motivo de extensión para continuar"
        );
      }

      await addExtensionTiempoVigencia({
        idPerfil,
        idCredencial,
        nombre: nombreCompleto,
        correo,
        tiempoExtension: parseInt(cantidadMesesExtra),
        cantidadExtensionesAplicadas: cantidadExtensionesAplicadas + 1,
        motivoUltimaExtension: motivoExtension,
        fechaExpiracionExtendida,
      });

      Notificacion("success", "Tiempo de vigencia extendido correctamente");

      setRefetchUsuarios(true);
    } catch (error) {
      console.log("Error al agregar meses de extensión de vigencia: ", error);

      NotificationManager.error(
        "Algo ocurrió al agregar tiempo de vigencia extra, vuelve a intentar"
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid className="text-start">
        <Row>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label>Usuario seleccionado</Form.Label>

              <Form.Control readOnly type="text" value={usuarioSeleccionado} />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Tiempo a extender</Form.Label>

              <Form.Control
                as="select"
                onChange={handleSeleccionTiempoVigencia}
              >
                <option value="">--Elige una opción--</option>

                <option value="1">Agregar 1 mes de vigencia extra</option>

                <option value="2">Agregar 2 meses de vigencia extra</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label>Motivo de la extensión: </Form.Label>

              <Form.Control
                as="textarea"
                name="motivoUltimaExtension"
                onChange={handleOnChange}
                rows={4}
                value={motivoExtension}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <ul className="list-unstyled lista-datos-membresia">
              <li>
                <span>Cantidad de extensiones efectuadas: </span>
                {cantidadExtensionesAplicadas === 0
                  ? "Sin extensiones efectuadas"
                  : cantidadExtensionesAplicadas}
              </li>

              {cantidadExtensionesAplicadas > 0 && (
                <li className="mt-2">
                  <span>Motivo última extensión: </span>

                  <p>{motivoUltimaExtension}</p>
                </li>
              )}
            </ul>
          </Col>

          <Col>
            <ul className="list-unstyled lista-datos-membresia">
              <li>
                <span>Fecha de expiración actual: </span>{" "}
                {getDateFromUnix(fechaExpiracion)}
              </li>

              <li className="mt-2">
                <span>Fecha de expiración extendida: </span>{" "}
                {fechaExpiracionExtendida !== 0
                  ? getDateFromUnix(fechaExpiracionExtendida)
                  : "Elige un plazo de extensión de tiempo"}
              </li>
            </ul>
          </Col>
        </Row>

        {cantidadExtensionesAplicadas === 2 && (
          <Row>
            <Col>
              <Alert variant="danger">
                El usuario ya alcanzó el límite de extensiones permitidas, no se
                puede aplicar una nueva extensión de vigencia.
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button
                variant="primary"
                type="submit"
                disabled={cantidadExtensionesAplicadas === 2}
                size="lg"
              >
                Extender vigencia
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default ExtensionVigencia;
