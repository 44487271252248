const columnas = [
  {
    name: "Código de lección", //nombre de la columna
    selector: (row) => row.codigoLeccion, //identificador de la columna
    sortable: true,
    center: true,
    width: "200px",
  },
  {
    name: "Código de sección",
    selector: (row) => row.codigoSeccion,
    sortable: true,
    center: true,
    width: "200px",
  },
  {
    name: "Nombre", //nombre de la columna
    selector: (row) => row.nombre,
    sortable: true,
  },
  {
    name: "Plantilla", //nombre de la columna
    selector: (row) => row.urlPlantilla, //identificador de la columna
    sortable: true,
  },
  {
    name: "Nivel",
    selector: (row) => row.nivel,
    sortable: true,
    center: true,
    width: "100px",
  },
];

export default columnas;
