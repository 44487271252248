import { Button, Col, Container, Row } from "react-bootstrap";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import React, { useState } from "react";

import { AgregarCurso } from "./Modales/AgregarCurso/AgregarCurso";
import { TableCursos } from "./DataTables/TableCursos";

const DashboardCursos = () => {
  const handleAgregarCurso = async (e) => {
    try {
      await ModalSweetAlert({
        title: "Agregar nuevo curso",
        html: <AgregarCurso setCursoAgregado={setCursoAgregado} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Algo ocurrió al agregar un nuevo curso: ", error);

      Notificacion("error", "Ocurrió un error al agregar un nuevo curso");
    }
  };

  const [cursoAgregado, setCursoAgregado] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col align="right">
          <Button variant="primary" onClick={handleAgregarCurso}>
            Agregar curso
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TableCursos
            setCursoAgregado={setCursoAgregado}
            cursoAgregado={cursoAgregado}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardCursos;
