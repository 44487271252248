import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { createCheckoutSession, getPreciosCompraLinkUnico } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import Loader from "@helpers/loader/loader";
import stripe from "@helpers/stripe/stripe";
import React, { useEffect, useState } from "react";

const CompraLinkPago = () => {
	const [precio, setPrecio] = useState(null);

	const consultarPrecioCompraRapida = async (msi) => {
		try {
			const precio = await getPreciosCompraLinkUnico(msi);

			setPrecio(precio);
		} catch (error) {
			console.log("error al consultar el precio de la compra rapida: ", error);
		}
	};

	const redirectToCheckout = async () => {
		try {
			const checkoutSession = await createCheckoutSession({
				success_url: `${window.location.origin}${dashboardRoutesPaths.pagos.compra.compraLinkFinalizada}`,
				cancel_url: window.location.href,
				line_items: [{ price: precio, quantity: 1 }],
				mode: "payment",
				currency: "mxn",
                payment_method_options: {
                    card: {
                        installments: {
                            enabled: window.location.href.includes("msi")
                        }
                    }
                }
			});

			await (await stripe).redirectToCheckout({ sessionId: checkoutSession.id });
		} catch (error) {
			console.log("error al redirigir al checkout: ", error);
		}
	};

	useEffect(() => {
		if (precio === null) {
			consultarPrecioCompraRapida(window.location.href.includes("msi"));
		}
	}, []);

	useEffect(() => {
		if (precio !== null) {
			redirectToCheckout();
		}
	}, [precio]);

	return <Loader />;
};

export default CompraLinkPago;
