import React, { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { imagenesMuestra } from './imagenesMuestra'

const OpcionesImagenes = ({setLgShow, lgShow, setEstiloSeleccionado, estiloSeleccionado}) => {


    const [opciones, setOpciones] = useState(imagenesMuestra);

    const seleccionarEstilo = (nombreEstilo, pathEstilo) => {
      setEstiloSeleccionado({
        ...estiloSeleccionado,
        nombreEstilo,
        pathEstilo
      });
      setLgShow(false);
      // console.log(estilo);
    };

  return (
    <>      
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Galería de estilos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col className='col-10 col-md-6 col-lg-4 col-xl-4 pointer' onClick={() => seleccionarEstilo('default', '/text2img')}>
                        <Card className=" mb-4 card-images border-0" style={{backgroundColor: "#212529"}}>
                           <picture width="100%" height="100%">
                              <source
                                srcSet={require('@assets/images/plus.webp')}
                              />
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={require('@assets/images/plus.png')}
                                alt="imagen leccion"
                              />
                            </picture>
                            <Card.Body className="info-images">
                                <h3>Sin estilo</h3>
                            </Card.Body>
                        </Card>
                    </Col>
                {imagenesMuestra.map((imagenMuestra, index) => (
                    <Col className='col-10 col-md-6 col-lg-4 col-xl-4 pointer' onClick={() => seleccionarEstilo(imagenMuestra.nombre, imagenMuestra.nombrePath)}>
                        <Card className=" mb-4 card-images border-0">
                           <picture width="100%" height="100%">
                              <source
                                srcSet={imagenMuestra.imagen}
                              />
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={imagenMuestra.imagenAlt}
                                alt="imagen leccion"
                              />
                            </picture>
                            <Card.Body className="info-images">
                                <h3>{imagenMuestra.nombre}</h3>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OpcionesImagenes