const columnsEmpleados = [
  {
    name: "#",
    selector: (row, id) => id + 1,
    sortable: true,
    center: true,
    width: "25%",
  },
  {
    name: "Nombre",
    selector: (row) => row.nombreCompleto,
    sortable: true,
    center: true,
    width: "25%",
  },
  {
    name: "Apellidos",
    selector: (row) => row.apellidos,
    sortable: true,
    center: true,
    width: "25%",
  },
];

export default columnsEmpleados;
