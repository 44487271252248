import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";

import "@assets/css/components/Home/style.css";
import "@assets/css/components/RutaAprendizaje/style.css";
import rutaDeAprendizaje1 from "@assets/images/portadas/rutaDeAprendizaje1.png";

import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import { ModalSweetAlert, NotificacionExtendida } from "../Alertas/Alertas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import {
  getCantidadRutasPorCurso,
  getRutasActivas,
} from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import { getRutasAprendizajePersonalizadas } from "@helpers/httpRequests/httpRequestsRutasAprendizajePersonalizadas/httpRequestsRutasPersonalizadas";

import ModificarRuta from "./Modales/ModificarRuta";
import EliminarRuta from "./Modales/EliminarRuta";
import AgregarRuta from "./Modales/AgregarRuta";
import { nombres } from "@helpers/cursos/cursos";
import { Element, Link } from "react-scroll";
import { Animated } from "react-animated-css";
import VisorDeLecciones from "./Componentes/VisorDeLecciones";
import RutasPredefinidas from "./Componentes/RutasPredefinidas";
import RutasPersonalizadas from "./Componentes/RutasPersonalizadas";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import { getLeccionByCodigo } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";

const RutasDeAprendizajes = ({
  handleScroll,
  handleScrollToTop,
  showScrollButton,
}) => {
  const joyrideRef = useRef(null);

  const [tourActivo, setTourActivo] = useState(false);

  const [runTour, setRunTour] = useState(false);

  const [tourSteps, setTourSteps] = useState([
    {
      target: ".question-rutas",
      content:
        "Esta es la sección de rutas de aprendizaje, dentro de ella podrás visualizar rutas de aprendizaje que han sido creadas en base a un curso en especifico.",
      disableBeacon: true,
      placement: "left-start",
    },
    {
      target: ".predefinidas",
      content: "Aquí se listan las rutas que han sido creadas por nosotros.",
      disableBeacon: true,
      placement: "top-start",
    },
    {
      target: ".personalizadas",
      content:
        "También tienes la posibilidad de crear tus propias rutas, con las lecciones de tu elección, del curso de tu preferencia.",
      disableBeacon: true,
      placement: "top-start",
    },
    {
      target: ".visor",
      content:
        "Al seleccionar una ruta de aprendizaje se desplegará un reproductor donde se visualizará la ruta que escogiste.",
      disableBeacon: true,
      placement: "top-start",
    },
    {
      target: ".end-rutas",
      content: "Eso sería todo, puedes comenzar con tu aprendizaje.",
      disableBeacon: true,
      placement: "left-start",
    },
  ]);

  const [cursos, setCursos] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [rutasPorCurso, setRutasPorCurso] = useState([]);

  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const { idUsuario } = usuarioLogeado;

  const videos = useRef(null);

  //-------------------------------------------------STATES PARA LOS MODALES DE LAS RUTAS PERSONALIZADAS-----------------------------------

  const [rutaPersonalizadaAgregada, setRutaPersonalizadaAgregada] =
    useState(false);

  const [rutaPersonalizadaModificada, setRutaPersonalizadaModificada] =
    useState(false);

  const [rutaPersonalizadaEliminada, setRutaPersonalizadaEliminada] =
    useState(false);

  const [rutas, setRutas] = useState([]);

  //--------------------------------STATES PARA CUANDO LAS LECCIONES NO SEAN DE TIPO CAPTIVATE---------------

  const [urlPlantilla, setUrlPlantilla] = useState(null);

  const [nombreLeccion, setNombreLeccion] = useState(null);

  //-------------------------------------------------STATES PARA LA SECCION DEL VIDEO Y LAS LECCIONES LISTADAS-----------------------------------

  const [urlRutas, setUrlRutas] = useState({
    //todo Hacer la validacion para el curso de Exel Avanzado EA16
    rutaCurso: "https://virtual.globalclickmexico.com/lecciones/sistemas",
    urlVideo: "",
    lecciones: [],
  });

  const [currentLesson, setCurrentLesson] = useState({
    leccion: "",
    ultimaLeccion: false,
  });

  const [finalizarRuta, setFinalizarRuta] = useState(false);

  const [rutasAprendizaje, setRutasAprendizaje] = useState([]);

  const iframeRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);

  //---------------------------------------------------------------FUNCIONES--------------------------------------------------------------------

  const handleStartTour = () => {
    setRunTour(!runTour);
  };

  function requestFullscreen() {
    iframeRef.current.requestFullscreen();
  }

  function handleFullscreenChange() {
    setIsFullscreen(document.fullscreenElement === iframeRef.current);
  }

  const urlPlantillaDocumento = async (codigoLeccion) => {
    try {
      const { data } = await getLeccionByCodigo(codigoLeccion);
      setUrlPlantilla(data.urlPlantilla);
      setNombreLeccion(data.nombre);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLeccionActual = ({ target }) => {
    const { id: leccion } = target;

    //VALIDACION PARA LECCIONES QUE NO SON DE TIPO CAPTIVATE
    if (leccion.startsWith("PNLB") || leccion.startsWith("CL23")) {
      urlPlantillaDocumento(leccion);
    }

    if (
      leccion ===
      urlRutas.lecciones[urlRutas.lecciones.length - 1].codigoLeccion
    ) {
      setFinalizarRuta(true);
      setCurrentLesson({
        ...currentLesson,
        ultimaLeccion: true,
      });
    } else {
      setFinalizarRuta(false);
    }

    const codigoCurso = leccion.substring(0, 4);

    const urlVideo = leccion.startsWith("EA16")
      ? "https://virtual.globalclickmexico.com/lecciones/sistemas/EA16/EA16/" +
        leccion
      : urlRutas.rutaCurso + "/" + codigoCurso + "/" + leccion;

    setUrlRutas({
      ...urlRutas,
      urlVideo,
    });

    let indiceLeccionActual = "";

    urlRutas.lecciones.map((lesson, index) => {
      if (lesson.codigoLeccion === leccion) {
        indiceLeccionActual = index;
      }
    });

    setCurrentLesson({
      ...currentLesson,
      leccion,
      nombreLeccion: urlRutas.lecciones[indiceLeccionActual].nombre,
    });
  };

  const listarLecciones = ({ target }) => {
    handleScrollToTop();

    const { name } = target;

    //VALIDAR SI ESTA VACIO

    const lecciones = JSON.parse(name);

    if (lecciones.length > 0) {
      const codigoDeLeccion = lecciones[0].codigoLeccion;

      const codigoCurso = codigoDeLeccion.substring(0, 4);

      const urlPrimerVideo = codigoDeLeccion.startsWith("EA16")
        ? "https://virtual.globalclickmexico.com/lecciones/sistemas/EA16/EA16/" +
          codigoDeLeccion
        : urlRutas.rutaCurso + "/" + codigoCurso + "/" + codigoDeLeccion;

      setUrlRutas({
        ...urlRutas,
        lecciones,
        urlVideo: urlPrimerVideo,
      });
      let indicePrimeraLeccion = "";

      lecciones.map((lesson, index) => {
        if (lesson.codigoLeccion === lecciones[0].codigoLeccion) {
          indicePrimeraLeccion = index;
        }
      });

      if (lecciones.length === 1) {
        setFinalizarRuta(true);
      }

      setCurrentLesson({
        ...currentLesson,
        nombreLeccion: lecciones[indicePrimeraLeccion].nombre,
        leccion: codigoDeLeccion,
      });
    } else {
      NotificacionExtendida(
        "info",
        "Esta ruta aun no tiene lecciones para mostrar"
      );
    }
  };

  const handleFinalizarRuta = () => {
    setUrlRutas({
      ...urlRutas,
      urlVideo: "",
      lecciones: [],
    });
    setFinalizarRuta(false);
  };

  //*----------------------------------------------MODALES PARA LAS LECCIONES PERSONALIZADAS-----------------------------------------------
  const handleAgregarRutaPersonalizada = () => {
    ModalSweetAlert({
      title: "Nueva ruta personalizada",
      html: (
        <AgregarRuta
          setRutaPersonalizadaAgregada={setRutaPersonalizadaAgregada}
          idUsuario={idUsuario}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const modificarRutaPersonalizada = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "Modificar Ruta",
      html: (
        <ModificarRuta
          setRutaPersonalizadaModificada={setRutaPersonalizadaModificada}
          idUsuario={idUsuario}
          ruta={rutas[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const eliminarRutaPersonalizada = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;
    ModalSweetAlert({
      title: "Eliminar Ruta",
      width: "800px",
      html: (
        <EliminarRuta
          setRutaPersonalizadaEliminada={setRutaPersonalizadaEliminada}
          idUsuario={idUsuario}
          ruta={rutas[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const breadcrumbItemsInitialState = [
    { title: "Rutas de aprendizaje", onClick: () => {} },
  ];

  useEffect(() => {
    handleScrollToTop();

    const obtenerCategorias = async () => {
      try {
        const { data } = await getCategoriasCursos();
        // console.log(data);

        if (Array.isArray(data)) {
          setCategorias(data);
        } else {
          setCategorias([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCategorias();

    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();
        if (Array.isArray(data)) {
          setCursos(data);
        } else {
          setCursos([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursos();

    const getRutas = async () => {
      try {
        const { data: rutas } = await getRutasActivas();
        // console.log(rutas)
        if (Array.isArray(rutas)) {
          setRutasAprendizaje(rutas);
        } else {
          setRutasAprendizaje([rutas]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getRutas();

    const cantidadRutasPorCurso = async () => {
      try {
        const { data } = await getCantidadRutasPorCurso();

        // console.log(data)

        if (Array.isArray(data)) {
          setRutasPorCurso(data);
        } else {
          setRutasPorCurso([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    cantidadRutasPorCurso();

    //*RUTAS APRENDIZAJE PERSONALIZADAS

    const getRutasPersonalizadas = async () => {
      try {
        const { data: datosRutas } = await getRutasAprendizajePersonalizadas(
          idUsuario
        );

        if (Array.isArray(datosRutas)) {
          setRutas(datosRutas);
        } else {
          setRutas([datosRutas]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getRutasPersonalizadas();

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (
      rutaPersonalizadaAgregada ||
      rutaPersonalizadaEliminada ||
      rutaPersonalizadaModificada
    ) {
      //REFRESH DE LAS RUTAS
      const getRutasActualizadas = async () => {
        try {
          const { data: datosRuta } = await getRutasAprendizajePersonalizadas(
            idUsuario
          );

          if (Array.isArray(datosRuta)) {
            setRutas(datosRuta);
          } else {
            setRutas([datosRuta]);
          }
        } catch (error) {
          console.log(error);
          setRutas([]);
        }
      };

      getRutasActualizadas();
      setRutaPersonalizadaAgregada(false);
      setRutaPersonalizadaEliminada(false);
      setRutaPersonalizadaModificada(false);
    }
  }, [
    rutaPersonalizadaAgregada,
    rutaPersonalizadaEliminada,
    rutaPersonalizadaModificada,
  ]);

  return (
    <>
      {/* //*COMPONENTE ScrollToTop */}
      {showScrollButton && (
        <Animated
          animationIn="fadeIn"
          isVisible={true}
          style={{ zIndex: "9999", position: "absolute" }}
        >
          <div className="scroll-top">
            <span
              className={`rounded-circle scrollTop text-center d-flex`}
              style={{
                height: "50px",
                width: "50px",
                fontSize: "2rem",
                backgroundColor: "#F59E0B",
                position: "fixed",
                bottom: "70px",
                right: "40px",
              }}
            >
              <Link
                className="pointer"
                activeClass="active"
                to="scrollTopElement"
                spy={true}
                smooth={true}
                duration={800}
                containerId="scrollTop"
                style={{ color: "black", width: "100%" }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Link>
            </span>
          </div>
        </Animated>
      )}
      <Animated
        animationIn="fadeIn"
        animationInDuration={250}
        animationOut="fadeOut"
        isVisible={true}
      >
        <Container fluid>
          <Joyride
            locale={spanishOptionsJoyRide}
            ref={joyrideRef}
            steps={tourSteps}
            run={runTour}
            continuous={true}
            hideCloseButton
            showSkipButton
            disableOverlayClose={true}
            disableCloseOnEsc={false}
            disableScrolling={false}
            scrollToFirstStep={true}
            scrollOffset={100}
            callback={(data) => {
              if (data.status === "finished") {
                setRunTour(false);
              } else if (data.action === "skip") {
                setRunTour(false);
              }
            }}
            styles={{
              options: {
                primaryColor: "#ff9900",
                textColor: "#00152A",
                width: 900,
                zIndex: 1000,
              },
            }}
          />

          <Element name="iframeElement"></Element>

          <DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />

          {/* //*----------------------------- Visor de lecciones--------------------------------- */}
          <VisorDeLecciones
            videos={videos}
            currentLesson={currentLesson}
            requestFullscreen={requestFullscreen}
            urlRutas={urlRutas}
            iframeRef={iframeRef}
            handleLeccionActual={handleLeccionActual}
            finalizarRuta={finalizarRuta}
            handleFinalizarRuta={handleFinalizarRuta}
            handleStartTour={handleStartTour}
            urlPlantilla={urlPlantilla}
            nombreLeccion={nombreLeccion}
          />

          {/* //*--------------------------------------Rutas predefinidas------------------------------- */}
          {/* HACER QUE SI HAY MAS DE UNA RUTA SE ITERE A LA PAR */}

          <RutasPredefinidas
            rutasPorCurso={rutasPorCurso}
            nombres={nombres}
            rutasAprendizaje={rutasAprendizaje}
            rutaDeAprendizaje1={rutaDeAprendizaje1}
            listarLecciones={listarLecciones}
            categorias={categorias}
            cursos={cursos}
          />

          {/* //* ---------------------------RUTAS PERSONALIZADAS--------------------------------- */}

          <RutasPersonalizadas
            handleAgregarRutaPersonalizada={handleAgregarRutaPersonalizada}
            rutas={rutas}
            rutaDeAprendizaje1={rutaDeAprendizaje1}
            eliminarRutaPersonalizada={eliminarRutaPersonalizada}
            modificarRutaPersonalizada={modificarRutaPersonalizada}
            listarLecciones={listarLecciones}
          />
        </Container>
      </Animated>
    </>
  );
};

export default RutasDeAprendizajes;
