import { Notificacion } from "@components/general/Alertas/Alertas";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { updateCursosUsuario } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const AgregarCurso = ({ usuario }) => {
	const [cursos, setCursos] = useState([]);

	const [cursosUsuario, setCursosUsuario] = useState(JSON.parse(usuario.cursos));

	const [cursosFaltantes, setCursosFaltantes] = useState([]);

	const [cursosNuevos, setCursosNuevos] = useState([]);

	const handleCheckboxChange = ({ target }) => {
		const { value, checked } = target;

		try {
			if (checked) {
				const curso = cursosNuevos.find((c) => c.codigoCurso === value);

				if (curso === undefined) {
					setCursosNuevos([...cursosNuevos, ...cursosFaltantes.filter((curso) => curso.codigoCurso === value)]);
				}
			} else {
				setCursosNuevos((nuevosCursos) => nuevosCursos.filter((nuevoCurso) => nuevoCurso.codigoCurso !== value));
			}
		} catch (error) {
			console.log("Error al agregar el curso al listado de cursos de la licencia: ", error);
		}
	};

	const handleAgregarCursos = async () => {
		try {
			const nuevosCursos = [...cursosUsuario, ...cursosNuevos];

			await updateCursosUsuario(nuevosCursos, usuario.idLicenciaCredencial);

			Notificacion("success", "Se agregaron los cursos seleccionados al usuario elegido");
		} catch (error) {
			console.log("Error al actualizar los crusos del usuario: ", error);

			NotificationManager.error("Error al agregar los cursos al usuario, revisa la consola para mayor información");
		}
	};

	const consultarCursos = async () => {
		try {
			const { data } = await getCursos();

			setCursos(data);

			setCursosFaltantes(data.filter((curso) => !cursosUsuario.some((asignado) => asignado.codigoCurso === curso.codigoCurso)));
		} catch (error) {
			console.log("Error al consultar los cursos: ", error);
		}
	};

	useEffect(() => {
		consultarCursos();
	}, []);

	return (
		<Container fluid>
			<h3>Agregar cursos al usuario</h3>

			<Row className="text-start mt-2">
				<Col>
					<h5>Licencia adquirida: {usuario.nombreLicencia}</h5>

					<h6>{cursosFaltantes.length} cursos faltantes en la licencia del usuario</h6>
				</Col>
			</Row>

			<Row>
				<Form.Group className="lista-cursos-disponibles text-start" as={Col}>
					{cursosFaltantes.map((curso, idx) => (
						<Form.Check
							className="checkbox-curso-disponible"
							id={curso.codigoCurso}
							type="switch"
							label={`${curso.nombre} - ${curso.codigoCurso} ${
								codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === curso.codigoCurso) !== undefined ? "- Usuarios Invitados" : ""
							}`}
							key={idx}
							value={curso.codigoCurso}
							onChange={handleCheckboxChange}
						/>
					))}
				</Form.Group>

				<Col className="cursos-agregados-licencia">
					<h5>Cursos a agregar</h5>

					{cursosNuevos.map((cursoSeleccionado, idx) => (
						<p key={idx}>
							{cursoSeleccionado.nombre} - {cursoSeleccionado.codigoCurso}{" "}
							{codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === cursoSeleccionado.codigoCurso) !== undefined ? "- Usuarios Invitados" : ""}
						</p>
					))}
				</Col>
			</Row>

			<Row className="mt-3">
				<Col>
					<div className="d-grid gap-2 mt-4">
						<Button onClick={handleAgregarCursos} type="submit" variant="success" size="lg">
							Guardar
						</Button>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default AgregarCurso;
