import { Accordion, Card, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import ListadoLeccionesSeccion from "./ListadoLeccionesSeccion";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";

const SeccionCurso = ({
  editarSecciones = false,
  codigoSeccion,
  nombre,
  idx,
}) => {
  console.log("rendeing seccion curso: ", codigoSeccion);

  const [lecciones, setLecciones] = useState([]);

  const [recoveryLecciones, setRecoveryLecciones] = useState([]);

  const handleSeleccionSeccion = async () => {
    try {
      let { data } = await getLeccionesByCodigoSeccion(codigoSeccion);

      if (!Array.isArray(data)) {
        data = [data];
      }

      setLecciones(data);

      setRecoveryLecciones(data);
    } catch (error) {
      console.log("Error seleccionando la sección: ", error);
    }
  };

  return (
    <Accordion className="mb-2">
      <Accordion.Item
        className={`text-black hovered-accordion-toggle ${
          editarSecciones ? "" : "pointer"
        }`}
        id={codigoSeccion}
        as={Card.Header}
        eventKey={idx + 1}
        onClick={handleSeleccionSeccion}
      >
        <Accordion.Header>
          {idx + 1}. {nombre} | {codigoSeccion}
        </Accordion.Header>

        <Accordion.Body eventKey={idx + 1}>
          {/* <Card.Body className="seccion-accordion"> */}
          <Container fluid>
            <Row>
              <Col>
                <ListadoLeccionesSeccion
                  setLecciones={setLecciones}
                  lecciones={lecciones}
                  recoveryLecciones={recoveryLecciones}
                  setRecoveryLecciones={setRecoveryLecciones}
                />
              </Col>
            </Row>
          </Container>
          {/* </Card.Body> */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SeccionCurso;
