import { getCursoByCodigo } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { logRoles } from "@testing-library/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const VerInfoRuta = ({ ruta }) => {
  const { nombre, descripcion, lecciones, estatus, codigoCurso } = ruta;

  const [nombreCurso, setNombreCurso] = useState({
    nombre: "",
  });

  const listaLecciones = JSON.parse(lecciones);

  useEffect(() => {
    const getNombreCurso = async () => {
      try {
        const { data } = await getCursoByCodigo(codigoCurso);

        setNombreCurso({
          nombre: data.nombre,
        });
      } catch (error) {}
    };

    getNombreCurso();
  }, []);

  return (
    <Container>
      <Row>
        <Col className="text-start">
          <p style={{ fontSize: "20px" }}>
            <b>Estatus:</b>{" "}
            <span className={estatus === 1 ? "text-success" : "text-danger"}>
              {estatus === 1 ? "Activo" : "Inactivo"}
            </span>
          </p>
          <p>
            <b>Nombre de la ruta: </b>
            {nombre}
          </p>
          <p>
            <b>Descripción de la ruta: </b>
            {descripcion}
          </p>
          <p>
            <b>Curso al que pertenece:</b> {nombreCurso.nombre}
          </p>
          <p>
            <b>Lecciones de la ruta:</b>
          </p>
          <div className="contenedor-rutas">
            <ul>
              {listaLecciones.map((leccion) => (
                <li>{leccion.nombre}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VerInfoRuta;
