import React from "react";
import { Row } from "react-bootstrap";
import GridPaquetesItem from "./GridPaquetesItem";

const GridPaquetes = () => {

    const items = [
        {
            title: 'Paquete Microsoft Office',
            imageSrc: 'pckg_office.jpg',
        },
        {
            title: 'Paquete Cursos Premium',
            imageSrc: 'pckg_premium.jpg'
        },
        {
            title: 'Paquete Home Office',
            imageSrc: 'pckg_self_improvement.jpg'
        },
        {
            title: 'Asesorías Personalizadas',
            imageSrc: 'pckg_asesoria.jpg'
        }
    ];

	return (
		<Row className="pt-4">
            {
                items.map(({title, imageSrc}, idx) => 
                    <GridPaquetesItem key={idx} title={title} imageSrc={imageSrc} imageAlt={title} />
                )
            }
		</Row>
	);
};

export default GridPaquetes;
