import React from "react";

const SubtitleDashboard = ({ title }) => {
	// return <>{title ? <h4>{title}</h4> : <h4>Sin titulo</h4>}</>;
	return (
		<>
			{title ? <h4 className="titulo-dashboard">{title}</h4> : <h4>Sin titulo</h4>}
			<div style={{ width: "65px", height: "3px", backgroundColor: "#ffc107" }}></div>
		</>
	);
};

export default SubtitleDashboard;
