import { Notificacion } from "@components/general/Alertas/Alertas";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { getLeccionesByCodigoSeccion } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { addRutaAprendizaje } from "@helpers/httpRequests/httpRequestsRutasAprendizaje/httpRequestsRutasAprendizaje";
import { getSeccionesByCodigoCurso } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";

const AgregarRuta = ({ setRutaAprendizajeAgregada, idUsuario }) => {
  const [sinRecursos, setSinRecursos] = useState(false);

  const [listaLecciones, setListaLecciones] = useState([]);

  //STATES PARA ALMACENAR LOS DATOS DE LOS CURSOS, SECCIONES Y LECCIONES DE LOS SELECTS

  const [cursos, setCursos] = useState([]);

  const [secciones, setSecciones] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  //STATES PARA LOS SELECTS DE LAS SECCIONES Y LAS LECCIONES

  const [selectSeccionesDeshabilitado, setSelectSeccionesDeshabilitado] =
    useState(true);

  const [selectLeccionesDeshabilitado, setSelectLeccionesDeshabilitado] =
    useState(true);

  const [leccionesRepetidas, setLeccionesRepetidas] = useState({
    status: false,
    razon: "",
  });

  const [botonAgregarDeshabilitado, setBotonAgregarDeshabilitado] =
    useState(true);

  const [formValues, setFormValues] = useState({
    nombreRuta: "",
    nombreCurso: "",
    codigoCurso: "",
    nombreSeccion: "",
    codigoSeccion: "",
    nombreLeccion: "",
    codigoLeccion: "",
    descripcionRuta: "",
    imagenPortada: "pexels-martin-péchy-1147377.jpg",
  });

  const [showPreviewPortada, setShowPreviewPortada] = useState(false);

  const guardarNuevaRuta = async (e) => {
    e.preventDefault();
    try {
      let obj = listaLecciones.map((leccion) => ({
        nombre: leccion.nombre,
        codigoLeccion: leccion.codigoLeccion,
      }));

      //* Esta información será la que se esté mandando a la bd para su registro.
      const infoRuta = {
        idUsuario: idUsuario,
        lecciones: JSON.stringify(obj),
        nombreRuta: formValues.nombreRuta,
        descripcionRuta: formValues.descripcionRuta,
        codigoCurso: formValues.codigoCurso,
        portadaRuta: null,
      };

      const { data, message } = await addRutaAprendizaje(infoRuta);

      setRutaAprendizajeAgregada(true);

      Notificacion("success", message);
    } catch (error) {
      console.log(error);
    }
  };

  const obtenerLecciones = async (codigoLeccion) => {
    try {
      setSinRecursos(false);
      const { data } = await getLeccionesByCodigoSeccion(codigoLeccion);

      if (Array.isArray(data)) {
        setLecciones(data);
        setSelectLeccionesDeshabilitado(false);
      } else {
        setLecciones([data]);
        setSelectLeccionesDeshabilitado(false);
      }
    } catch (error) {
      setSinRecursos(true);
      console.log(error);
    }
  };

  const agregarLeccion = () => {
    const duplicados = listaLecciones.find((object, index) => {
      return listaLecciones
        .slice(index)
        .some(
          (otherObject) => object.codigoLeccion === formValues.codigoLeccion
        );
    });

    if (duplicados === undefined) {
      setLeccionesRepetidas({
        status: false,
        razon: "",
      });
      return setListaLecciones([
        ...listaLecciones,
        ...lecciones.filter(
          (leccion) => leccion.codigoLeccion === formValues.codigoLeccion
        ),
      ]);
    }

    setLeccionesRepetidas({
      status: true,
      razon: "Las lecciones no se pueden repetir, elige otra.",
    });

    setFormValues({
      ...formValues,
      nombreCurso: "",
      codigoCurso: "",
      nombreSeccion: "",
      codigoSeccion: "",
      nombreLeccion: "",
      codigoLeccion: "",
    });
  };

  const eliminarLeccion = ({ target }) => {
    const { name, id } = target;

    const indice = parseInt(id);

    let nuevasLecciones = [...listaLecciones];

    nuevasLecciones.splice(indice, 1);

    setListaLecciones(nuevasLecciones);
  };

  const obtenerSecciones = async (codigoCurso) => {
    try {
      setSinRecursos(false);
      const { data } = await getSeccionesByCodigoCurso(codigoCurso);

      setSecciones(data);
      setSelectSeccionesDeshabilitado(false);
    } catch (error) {
      setSinRecursos(true);
      console.log(error);
    }
  };

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSelectOnChange = ({ target }) => {
    const { name, value } = target;

    const arregloValue = value.split("/"); //arregloValue[0] = valor del codigoCurso / arregloValue[1] = valor del nombreCurso

    const arregloName = name.split("/"); //arregloName[0] = 'codigoCurso' / arregloName[1] = 'nombreCurso'

    if (arregloName[0] === "codigoCurso") {
      obtenerSecciones(arregloValue[0]);
    }
    if (arregloName[0] === "codigoSeccion") {
      obtenerLecciones(arregloValue[0]);
    }
    if (arregloName[0] === "codigoLeccion") {
      setBotonAgregarDeshabilitado(false);
    }

    setFormValues({
      ...formValues,
      [arregloName[1]]: arregloValue[1],
      [arregloName[0]]: arregloValue[0],
    });
  };

  const handleListarTodo = () => {
    const arregloLeccionesAListar = [];

    const arregloLeccionesListadas = [];

    listaLecciones.map((leccionListada) => {
      arregloLeccionesListadas.push(leccionListada.codigoLeccion);
    });

    lecciones.map((leccionAListar) => {
      arregloLeccionesAListar.push(leccionAListar.codigoLeccion);
    });

    const validacionRepetidas = arregloLeccionesAListar.map(
      (leccionAListar) => {
        const repetidas = arregloLeccionesListadas.includes(leccionAListar);
        if (repetidas) {
          return true;
        }
      }
    );

    if (validacionRepetidas.includes(true)) {
      setLeccionesRepetidas({
        status: true,
        razon:
          "Parece que ya tienes listada o más leccion, asegurate de que no se repitan",
      });

      setFormValues({
        ...formValues,
        nombreCurso: "",
        codigoCurso: "",
        nombreSeccion: "",
        codigoSeccion: "",
        nombreLeccion: "",
        codigoLeccion: "",
      });
    } else {
      setListaLecciones([...listaLecciones, ...lecciones]);

      setLeccionesRepetidas({
        status: false,
        razon: "",
      });
    }
  };

  useEffect(() => {
    const obtenerCursos = async () => {
      try {
        const { data } = await getCursos();
        setCursos(data);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerCursos();
  }, []);

  return (
    <Form onSubmit={guardarNuevaRuta}>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-4">
              <Form.Control
                required
                type="text"
                name="nombreRuta"
                value={formValues.nombreRuta}
                onChange={handleOnChange}
                placeholder="Nombre de la ruta"
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Form.Group className="mb-4">
              <Form.Control
                name="codigoCurso/nombreCurso"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione un curso
                </option>
                {cursos.map((curso, key) => (
                  <option
                    key={key}
                    value={`${curso.codigoCurso}/${curso.nombre}`}
                  >
                    {curso.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Control
                disabled={selectSeccionesDeshabilitado}
                name="codigoSeccion/nombreSeccion"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione una sección
                </option>
                {secciones.map((seccion, key) => (
                  <option
                    key={key}
                    value={`${seccion.codigoSeccion}/${seccion.nombre}`}
                  >
                    {seccion.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Control
                disabled={selectLeccionesDeshabilitado}
                name="codigoLeccion/nombreLeccion"
                onChange={handleSelectOnChange}
                as="select"
                placeholder=""
              >
                <option value={0} selected disabled>
                  Seleccione una lección
                </option>
                {lecciones.map((leccion, key) => (
                  <option
                    key={key}
                    value={`${leccion.codigoLeccion}/${leccion.nombre}`}
                  >
                    {leccion.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Row className="justify-content-center">
              <Col className="col-11 p-0 mb-3">
                <div className="d-grid gap-2 mt-4">
                  <Button
                    className="btn-form-rutas"
                    style={{ fontSize: "12px" }}
                    disabled={botonAgregarDeshabilitado}
                    onClick={agregarLeccion}
                    variant="success"
                    size="lg"
                  >
                    Agregar
                  </Button>
                </div>
              </Col>

              <Col className="col-11 p-0 mb-3">
                <div className="d-grid gap-2 mt-4">
                  <Button
                    className="btn-form-rutas"
                    style={{ fontSize: "12px" }}
                    onClick={handleListarTodo}
                    disabled={selectLeccionesDeshabilitado ? "disabled" : ""}
                    size="lg"
                  >
                    Agregar todas las lecciones
                  </Button>
                </div>
              </Col>

              {leccionesRepetidas && (
                <Col className="col-12">
                  <small className="text-danger alert-rutas">
                    {leccionesRepetidas.razon}
                  </small>
                </Col>
              )}

              {sinRecursos && (
                <Col className="col-12">
                  <small className="text-danger alert-rutas">
                    El curso selecionado no contiene secciones o lecciones
                    disponible, eliga otro.
                  </small>
                </Col>
              )}
            </Row>
          </Col>

          <Col md={7} className="mt-4 mt-md-0  ">
            <div className="p-3 text-start contenedor-rutas ">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <b>Lecciones agregadas</b>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {listaLecciones.length > 0 ? (
                    <>
                      {listaLecciones.map((leccion, key) => (
                        <tr key={leccion.codigoLeccion}>
                          <td>{leccion.nombre}</td>
                          <td className="text-center">
                            <Button
                              name={leccion.nombre}
                              id={key}
                              onClick={eliminarLeccion}
                              variant="danger"
                              className="btn-sm m-1"
                            >
                              <FontAwesomeIcon
                                style={{ fontSize: "12px" }}
                                icon={faTrash}
                              />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>Aún no tienes lecciones agregadas</td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>

          <Col className="text-start mt-3">
            <Form.Group className="mb-4">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                required
                name="descripcionRuta"
                value={formValues.descripcionRuta}
                onChange={handleOnChange}
                as="textarea"
                rows={3}
                placeholder="Agrega una descripción a la ruta"
              />
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button type="submit" size="lg">
                Guardar ruta
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AgregarRuta;
