const horarios = [
	{ hora: '-- Selecciona un horario --' },
	{ hora: '12:00 a.m.' },
	{ hora: '12:15 a.m.' },
	{ hora: '12:30 a.m.' },
	{ hora: '12:45 a.m.' },
	{ hora: '01:00 a.m.' },
	{ hora: '01:15 a.m.' },
	{ hora: '01:30 a.m.' },
	{ hora: '01:45 a.m.' },
	{ hora: '02:00 a.m.' },
	{ hora: '02:15 a.m.' },
	{ hora: '02:30 a.m.' },
	{ hora: '02:45 a.m.' },
	{ hora: '03:00 a.m.' },
	{ hora: '03:15 a.m.' },
	{ hora: '03:30 a.m.' },
	{ hora: '03:45 a.m.' },
	{ hora: '04:00 a.m.' },
	{ hora: '04:15 a.m.' },
	{ hora: '04:30 a.m.' },
	{ hora: '04:45 a.m.' },
	{ hora: '05:00 a.m.' },
	{ hora: '05:15 a.m.' },
	{ hora: '05:30 a.m.' },
	{ hora: '05:45 a.m.' },
	{ hora: '06:00 a.m.' },
	{ hora: '06:15 a.m.' },
	{ hora: '06:30 a.m.' },
	{ hora: '06:45 a.m.' },
	{ hora: '07:00 a.m.' },
	{ hora: '07:15 a.m.' },
	{ hora: '07:30 a.m.' },
	{ hora: '07:45 a.m.' },
	{ hora: '08:00 a.m.' },
	{ hora: '08:15 a.m.' },
	{ hora: '08:30 a.m.' },
	{ hora: '08:45 a.m.' },
	{ hora: '09:00 a.m.' },
	{ hora: '09:15 a.m.' },
	{ hora: '09:30 a.m.' },
	{ hora: '09:45 a.m.' },
	{ hora: '10:00 a.m.' },
	{ hora: '10:15 a.m.' },
	{ hora: '10:30 a.m.' },
	{ hora: '10:45 a.m.' },
	{ hora: '11:00 a.m.' },
	{ hora: '11:15 a.m.' },
	{ hora: '11:30 a.m.' },
	{ hora: '11:45 a.m.' },
	{ hora: '12:00 p.m.' },
	{ hora: '12:15 p.m.' },
	{ hora: '12:30 p.m.' },
	{ hora: '12:45 p.m.' },
	{ hora: '01:00 p.m.' },
	{ hora: '01:15 p.m.' },
	{ hora: '01:30 p.m.' },
	{ hora: '01:45 p.m.' },
	{ hora: '02:00 p.m.' },
	{ hora: '02:15 p.m.' },
	{ hora: '02:30 p.m.' },
	{ hora: '02:45 p.m.' },
	{ hora: '03:00 p.m.' },
	{ hora: '03:15 p.m.' },
	{ hora: '03:30 p.m.' },
	{ hora: '03:45 p.m.' },
	{ hora: '04:00 p.m.' },
	{ hora: '04:15 p.m.' },
	{ hora: '04:30 p.m.' },
	{ hora: '04:45 p.m.' },
	{ hora: '05:00 p.m.' },
	{ hora: '05:15 p.m.' },
	{ hora: '05:30 p.m.' },
	{ hora: '05:45 p.m.' },
	{ hora: '06:00 p.m.' },
	{ hora: '06:15 p.m.' },
	{ hora: '06:30 p.m.' },
	{ hora: '06:45 p.m.' },
	{ hora: '07:00 p.m.' },
	{ hora: '07:15 p.m.' },
	{ hora: '07:30 p.m.' },
	{ hora: '07:45 p.m.' },
	{ hora: '08:00 p.m.' },
	{ hora: '08:15 p.m.' },
	{ hora: '08:30 p.m.' },
	{ hora: '08:45 p.m.' },
	{ hora: '09:00 p.m.' },
	{ hora: '09:15 p.m.' },
	{ hora: '09:30 p.m.' },
	{ hora: '09:45 p.m.' },
	{ hora: '10:00 p.m.' },
	{ hora: '10:15 p.m.' },
	{ hora: '10:30 p.m.' },
	{ hora: '10:45 p.m.' },
	{ hora: '11:00 p.m.' },
	{ hora: '11:15 p.m.' },
	{ hora: '11:30 p.m.' },
	{ hora: '11:45 p.m.' },
];

export default horarios;
