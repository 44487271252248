import React, { Component } from 'react';
import './Header.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';

export default class Header extends Component {
  render() {
    return (
      <header>
        <Container>
          <Row className="justify-content-evenly d-flex align-items-center">
            <Col className='col-6'>
              <div> 
                <button className="btn btn-primary" onClick={this.props.resetearPartida}>
                  <FaRedo /> Reiniciar
                </button>
              </div>
            </Col>
            <Col className='col-6'>
              <div className="titulo">
                <b>Movimientos: <span>{this.props.numeroDeIntentos}</span></b>
              </div>
            </Col>
          </Row>
        </Container>


      </header>
    );
  }
};