import "@assets/css/components/DashboardLicencias/style.css";
import "react-notifications/lib/notifications.css";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { addLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { NotificationManager } from "react-notifications";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

export const AgregarLicencia = ({ setLicenciaAgregada }) => {
  const [formValues, setFormValues] = useState({
    nombre: "",
    usuarioExtra: false,
    licenciaEmpresarial: false,
    licenciaInvitado: false,
    licenciaRenovacion: false,
    tiempoVigencia: 0,
  });

  const [cursos, setCursos] = useState([]);

  const [cursosSeleccionados, setCursosSeleccionados] = useState([]);

  const handleAgregarLicencia = async (e) => {
    e.preventDefault();

    try {
      if (cursosSeleccionados.length === 0) {
        return NotificationManager.error(
          "Agrega al menos un curso para la licencia"
        );
      }

      let cursosLicencia = [];

      cursosSeleccionados.forEach((cursoSeleccionado) => {
        cursosLicencia.push({
          nombre: cursoSeleccionado.nombre,
          codigoCurso: cursoSeleccionado.codigoCurso,
        });
      });

      await addLicencia({
        ...formValues,
        cursos: JSON.stringify(cursosLicencia),
      });

      Notificacion("success", "La licencia se agregó correctamente");

      setLicenciaAgregada(true);
    } catch (error) {
      console.log(
        "Error al agregar los cursos a la licencia seleccionada: ",
        error
      );

      Notificacion(
        "error",
        "Algo ocurrió al actualizar el listado de cursos de la licencia seleccionada"
      );
    }
  };

  const handleCheckboxChange = ({ target }) => {
    const { value, checked } = target;

    try {
      if (value === "todos") {
        const handleCheckboxesGroupSelection = (checked) => {
          const checkboxes = document.querySelectorAll(
            ".checkbox-curso-disponible input"
          );

          for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = checked;
          }
        };

        if (checked) {
          setCursosSeleccionados(cursos);

          handleCheckboxesGroupSelection(true);
        } else {
          setCursosSeleccionados([]);

          handleCheckboxesGroupSelection(false);
        }
      } else {
        if (checked) {
          if (!cursosSeleccionados.includes(value)) {
            setCursosSeleccionados([
              ...cursosSeleccionados,
              ...cursos.filter((curso) => curso.codigoCurso === value),
            ]);
          }
        } else {
          setCursosSeleccionados((cursosSeleccionados) =>
            cursosSeleccionados.filter(
              (cursoSeleccionado) => cursoSeleccionado.codigoCurso !== value
            )
          );
        }
      }
    } catch (error) {
      console.log(
        "Error al agregar el curso al listado de cursos de la licencia: ",
        error
      );
    }
  };

  const handleCheckboxUsuarioAdicional = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        usuarioExtra: target.checked,
      });
    } catch (error) {
      console.log("Error al establecer un usuario adicional: ", error);
    }
  };

  const handleCheckboxLicenciaEmpresarial = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        licenciaEmpresarial: target.checked,
      });
    } catch (error) {
      console.log("Error al establecer un usuario adicional: ", error);
    }
  };

  const handleCheckboxLicenciaInvitado = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        licenciaInvitado: target.checked,
      });
    } catch (error) {
      console.log("Error al establecer un usuario adicional: ", error);
    }
  };

  const handleCheckboxLicenciaRenovacion = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        licenciaRenovacion: target.checked,
      });
    } catch (error) {
      console.log("Error al establecer un usuario adicional: ", error);
    }
  };

  const handleOnChange = ({ target }) => {
    try {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      });
    } catch (error) {
      console.log(": ", error);
    }
  };

  useEffect(() => {
    const consultarCursos = async () => {
      try {
        const { data: cursos } = await getCursos();

        setCursos(cursos);
      } catch (error) {
        console.log("Error al consultar los cursos: ", error);

        Notificacion(
          "error",
          "Error al consultar los cursos, recarga la página y vuelve a intentar"
        );
      }
    };

    consultarCursos();
  }, []);

  return (
    <Form onSubmit={handleAgregarLicencia} className="text-start">
      <Container fluid>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formHorizontalPassword">
            <Form.Label>Nombre</Form.Label>

            <InputText
              className="w-100"
              name="nombre"
              value={capitalizeString(formValues.nombre)}
              onChange={handleOnChange}
            />

            {/* <Form.Control type='text' name='nombre' onChange={handleOnChange} value={capitalizeString(formValues.nombre)} /> */}
          </Form.Group>

          <Form.Group as={Col} controlId="formHorizontalPassword">
            <Form.Label>Tiempo de vigencia (meses)</Form.Label>

            <InputNumber
              className="w-100"
              name="tiempoVigencia"
              value={formValues.tiempoVigencia}
              onValueChange={handleOnChange}
              useGrouping={false}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col}>
            <h5>Usuario adicional</h5>

            <Form.Check
              // className='checkbox-curso-disponible'
              type="switch"
              id="usuarioExtra"
              label={
                formValues.usuarioExtra
                  ? "Con usuario adicional"
                  : "Sin usuario adicional"
              }
              onChange={handleCheckboxUsuarioAdicional}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <h5>Licencia empresarial</h5>

            <Form.Check
              // className='checkbox-curso-disponible'
              type="switch"
              id="licenciaEmpresarial"
              label={
                formValues.licenciaEmpresarial
                  ? "Es licencia empresarial"
                  : "No es licencia empresarial"
              }
              onChange={handleCheckboxLicenciaEmpresarial}
            />
          </Form.Group>
        </Row>

        <Row className="mt-3">
          <Form.Group as={Col}>
            <h5>Licencia de invitado</h5>

            <Form.Check
              // className='checkbox-curso-disponible'
              type="switch"
              id="licenciaInvitado"
              label={
                formValues.licenciaInvitado
                  ? "Es licencia de invitado"
                  : "No es licencia de invitado"
              }
              onChange={handleCheckboxLicenciaInvitado}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <h5>Licencia de Renovación</h5>

            <Form.Check
              // className='checkbox-curso-disponible'
              type="switch"
              id="licenciaRenovacion"
              label={
                formValues.licenciaRenovacion
                  ? "Es licencia de renovación"
                  : "No es licencia de renovación"
              }
              onChange={handleCheckboxLicenciaRenovacion}
            />
          </Form.Group>
        </Row>

        <Row className="mt-3">
          <Form.Group as={Col} className="lista-cursos-disponibles">
            <h5>Asigna cursos a esta licencia</h5>

            <Form.Check
              type="switch"
              id="todosCursos"
              label={<strong>Todos los cursos</strong>}
              value="todos"
              onChange={handleCheckboxChange}
            />

            {cursos.map((curso, idx) => (
              <Form.Check
                className="checkbox-curso-disponible"
                type="switch"
                id={curso.codigoCurso}
                label={`${curso.nombre}${
                  codigoCursosUsuarioInvitado.find(
                    (invitado) => invitado.codigoInvitado === curso.codigoCurso
                  ) !== undefined
                    ? "- Usuarios Invitados"
                    : ""
                }`}
                key={idx}
                value={curso.codigoCurso}
                onChange={handleCheckboxChange}
              />
            ))}
          </Form.Group>

          <Col className="cursos-agregados-licencia">
            <h5>Cursos asignados</h5>

            {cursosSeleccionados.map((cursoSeleccionado, idx) => (
              <p key={idx}>
                {cursoSeleccionado.nombre} - {cursoSeleccionado.codigoCurso}
              </p>
            ))}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button type="submit" variant="success" size="lg">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
