import React from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useState } from "react";
import TabRegistro from "./TabContent/TabRegistro";
import TabConsulta from "./TabContent/TabConsulta";
import TabCodigosInvitados from "./TabContent/TabCodigosInvitados";

const DashboardRegistroUsuarioInvitado = () => {
  const [tabState, setTabState] = useState("registro");

  const handleChangeTab = (tab) => {
    try {
      setTabState(tab);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabs
            id="tabsInvitados"
            className="mb-3"
            onSelect={handleChangeTab}
            activeKey={tabState.select}
          >
            <Tab eventKey="registro" title="Registro">
              <TabRegistro />
            </Tab>

            <Tab eventKey="existentes" title="Consulta">
              <TabConsulta />
            </Tab>

            <Tab eventKey="codigosInvitados" title="Códigos de Invitados">
              <TabCodigosInvitados />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardRegistroUsuarioInvitado;
