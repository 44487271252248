// // // import '@assets/css/components/SeleccionPerfiles/style.css';

import { Col, Container, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";

import UserContext from "@context/User/UserContext";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { getDatosSegundoUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/estudiantes/httpRequestsEstudiantes";
import useWindowResize from "customHooks/useWindowResize";
import "@assets/css/components/SeleccionPerfiles/style.css";
import Loader from "@helpers/loader/loader";
import { Animated } from "react-animated-css";
import { updateFechaUltimoAcceso } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import ValidacionNip from "./ValidacionNip";

const SeleccionPerfiles = ({ history }) => {
  // dentro de perfiles debemos obtener la informacion de los perfiles del la cuenta estudiante premium para cargarla en los botones
  const { contextValue, setContextValue } = useContext(UserContext);

  const [showSecondUser, setShowSecondUser] = useState(false);

  const [showLoader, setShowLoader] = useState(true);

  const [nipValidado, setNipValidado] = useState(false);

  const [dataSecondUser, setDataSecondUser] = useState({
    nombre: "",
    avatarPerfil: "",
    informacion: {},
  });

  const { usuarioLogeado } = contextValue;

  const {
    avatarPerfil,
    nombreCompleto,
    segundoPerfil,
    usuarioTitular,
    idPersona,
    nip: nipTitular,
  } = usuarioLogeado;

  const {
    size: { width },
  } = useWindowResize();

  const handleSeleccionPerfil = async ({ currentTarget }) => {
    const ids = currentTarget.id.split("-");

    const [tipoPerfil, idPersona] = ids;

    let nipActivo = false;

    let idPerfil = 0;

    let nombre = "";

    let correo = "";

    if (tipoPerfil === "titular") {
      if (nipTitular !== "") {
        nipActivo = true;

        nombre = `${usuarioLogeado.nombreCompleto} ${usuarioLogeado.apellidos}`;

        correo = usuarioLogeado.correo;
      }

      idPerfil = usuarioLogeado.idPerfil;
    }

    if (tipoPerfil === "secundario") {
      if (dataSecondUser.nip !== "") {
        nipActivo = true;

        nombre = `${dataSecondUser.informacion.nombreCompleto} ${dataSecondUser.informacion.apellidos}`;

        correo = dataSecondUser.informacion.correo;
      }

      idPerfil = dataSecondUser.informacion.idPerfil;
    }

    const darAcceso = async () => {
      try {
        await updateFechaUltimoAcceso({
          idPersona,
          fecha: getUnixTimestamp(),
        });

        if (tipoPerfil === "secundario") {
          setContextValue({
            ...contextValue,
            // ...usuarioLogeado,
            usuarioLogeado: dataSecondUser.informacion,
          });
        }

        history.replace(dashboardRoutesPaths.dashboard);
      } catch (error) {
        console.log("algo pasó al actualizar la ultima fecha de acceso");
      }
    };

    if (nipActivo) {
      try {
        let closedByUser = false;

        await ModalSweetAlert({
          title: "Ingresa tu NIP de seguridad",
          icon: "info",
          html: (
            <ValidacionNip
              idPerfil={idPerfil}
              nombre={nombre}
              correo={correo}
              darAcceso={darAcceso}
            />
          ),
          showConfirmButton: false,
          showCloseButton: true,
          willClose: () => {
            closedByUser = true;
          },
        });

        console.log(closedByUser);

        if (closedByUser) {
          return;
        }
      } catch (error) {
        console.log("error validando el nip: ", error);
      }
    }

    darAcceso();
  };

  useEffect(() => {
    if (segundoPerfil !== null) {
      //Función para recuperar el segundo perfil
      const idPerfilSegundoUsuario = segundoPerfil;

      const obtenerSegundoUsuario = async () => {
        try {
          const { message, data } = await getDatosSegundoUsuario(
            idPerfilSegundoUsuario
          );

          const { data: informacion } = data;

          const {
            nombreCompleto,
            avatarPerfil,
            usuarioTitular,
            idPersona,
            nip,
          } = informacion;

          informacion.fechaUltimoAcceso = getUnixTimestamp();

          setDataSecondUser({
            nombre: nombreCompleto,
            avatarPerfil,
            usuarioTitular,
            idPersona,
            informacion,
            nip,
          });

          setShowSecondUser(!showSecondUser);
        } catch (error) {}
      };

      obtenerSegundoUsuario(idPerfilSegundoUsuario);
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 1200);
  }, []);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <Animated
          animationIn="fadeIn"
          animationInDuration={2000}
          animationOut="fadeOut"
          isVisible={true}
        >
          <Container
            fluid
            className="seleccion-perfiles main-content-perfiles vh-100"
            style={{ overflowY: "scroll" }}
          >
            <Row className="align-items-center vh-100">
              <Col>
                <Row className="text-center justify-content-center p-4">
                  <h2 className="text-white title-perfiles">
                    ¿Quién aprende hoy?
                  </h2>
                </Row>

                <Row className="text-center justify-content-center ">
                  <Col className="col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 mt-2 mb-2">
                    <div
                      id={`titular-${idPersona}`}
                      className="avatar-perfiles"
                      onClick={handleSeleccionPerfil}
                    >
                      <p className="text-white">
                        {usuarioTitular
                          ? "Usuario Titular"
                          : "Usuario Secundario"}
                      </p>
                      <img
                        height="150px"
                        width="150px"
                        className={
                          avatarPerfil !== null
                            ? "avatar-seleccion-perfiles"
                            : "avatar-seleccion-perfiles__vacio"
                        }
                        src={
                          avatarPerfil !== null
                            ? avatarPerfil
                            : require(`@assets/images/avatar.png`)
                        }
                        alt="Avatar perfil"
                      />

                      <p className="nombre-perfiles mt-3">{nombreCompleto}</p>
                    </div>
                  </Col>
                  {showSecondUser && (
                    <Col className="col-11 col-sm-10 col-md-5 col-lg-4 col-xl-3 mt-2 mb-2">
                      <div
                        id={`secundario-${dataSecondUser.informacion.idPersona}`}
                        className="avatar-perfiles"
                        onClick={handleSeleccionPerfil}
                      >
                        <p className="text-white">
                          {dataSecondUser.usuarioTitular
                            ? "Usuario Titular"
                            : "Usuario Secundario"}
                        </p>
                        <img
                          height="150px"
                          width="150px"
                          className={
                            dataSecondUser.avatarPerfil !== null
                              ? "avatar-seleccion-perfiles"
                              : "avatar-seleccion-perfiles__vacio"
                          }
                          src={
                            dataSecondUser.avatarPerfil !== null
                              ? dataSecondUser.avatarPerfil
                              : require(`@assets/images/avatar.png`)
                          }
                          alt="Avatar perfil"
                        />

                        <p className="nombre-perfiles mt-3">
                          {dataSecondUser.nombre}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </Animated>
      )}
    </>
  );
};

export default SeleccionPerfiles;
