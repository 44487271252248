import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const columnsDetallesMos = [
	{
		header: "Folio contrato",
		accessorKey: "folioContrato",
		sortable: true,
		center: true,
		compact: true,
		width: "200px",
	},
	{
		header: "Nombre completo",
		accessorKey: "nombreCompleto",
		sortable: true,
		center: true,
		compact: true,
		width: "40px",
	},
	{
		header: "Apellidos",
		accessorKey: "apellidos",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
	},
	{
		header: "Examen realizado",
		accessorKey: "examenRealizado",
		sortable: true,
		center: true,
		compact: true,
		width: "350px",
		Cell: ({ cell }) => (
			<div className="text-center">
				<span>{cell.row.original.examenRealizado ? <FontAwesomeIcon icon={faCheck} color="#28a745" /> : <FontAwesomeIcon icon={faTimes} color="#dc3545" />}</span>
			</div>
		),
	},
	{
		header: "Aprobado",
		accessorKey: "aprobado",
		sortable: true,
		center: true,
		compact: true,
		width: "100px",
		Cell: ({ cell }) => (
			<div className="text-center">
				<span>{cell.row.original.aprobado ? <FontAwesomeIcon icon={faCheck} color="#28a745" /> : <FontAwesomeIcon icon={faTimes} color="#dc3545" />}</span>
			</div>
		),
	},
];

export default columnsDetallesMos;
