import React, {Component} from 'react';
import './Carta.css';
import ReactFlipCard from 'reactjs-flip-card';
import { Image } from 'react-bootstrap';
export default class Carta extends Component {
  render() {
    return (
      <div className="carta pointer" onClick={this.props.seleccionarCarta}>
       <ReactFlipCard
            flipByProp={this.props.estaSiendoComparada || this.props.fueAdivinada}
            flipTrigger='disabled'
            frontComponent={
            <div className="portada d-flex justify-content-center align-items-center">
              <div className='p-3'>
                <Image fluid src={require(`@assets/images/logoGCvertical.png`)} />
              </div>
            </div>
          }
            backComponent={<div className="contenido">
            <h2>{this.props.icono}</h2>
          </div>}
        />
      </div>
    )
  }  
};