import React from "react";
import { Col, Card } from "react-bootstrap";
import logoGCvertical from "@assets/images/logoGCvertical.png";
import "@assets/css/components/AulaVirtual/style.css";

const TransmisionAnterior = ({ id, titulo, handleTransmissionSelection }) => {
  return (
    // <div>
    <Col
      className="m-2 text-center pointer"
      onClick={handleTransmissionSelection}
      id={id}
    >
      <Card style={{ width: "19rem" }}>
        <Card.Img
          src={logoGCvertical}
          style={{ width: "auto", height: "auto" }}
        />

        <Card.Footer>
          <Card.Title className="card-title-aula-virtual">
            Clase {id}: {titulo}
          </Card.Title>
        </Card.Footer>
      </Card>
    </Col>
    // </div>

    // <Col>
    // 	<div
    // 		className="text-center pointer border rounded m-2"
    // 		onClick={handleTransmissionSelection}
    // 		id={id}
    // 	>
    // 		<Card className="text-black">

    // 			<Card.Img
    // 				fluid
    // 				src={logoGCvertical}
    // 				style={{ width: "250px", height: "250px" }}
    // 			/>

    // 			<div>
    // 				<Card.Title>
    // 					Clase {id}: {titulo}
    // 				</Card.Title>
    // 			</div>
    // 		</Card>
    // 	</div>
    // </Col>
  );
};

export default TransmisionAnterior;
