const codigoCursosUsuarioInvitado = [
  {
    codigoInvitado: "EAUI",
    codigoCurso: "EA16",
  },
  {
    codigoInvitado: "EIUI",
    codigoCurso: "EI16",
  },
  {
    codigoInvitado: "EXUI",
    codigoCurso: "EX21",
  },
  {
    codigoInvitado: "PBUI",
    codigoCurso: "PB21",
  },
  {
    codigoInvitado: "PBUA",
    codigoCurso: "PBIA",
  },
];

export default codigoCursosUsuarioInvitado;
