import { Col, Container, Row } from "react-bootstrap";

import React from "react";
import { TableGrupos } from "../../DataTables/Grupo/TableGrupo";

const DashboardGrupos = ({ isTabActive }) => {
	return (
		isTabActive && (
			<Container fluid className="pt-3">
				{/* <Row>
                    <Col align='right'>
                        <Button variant="primary" onClick={handleAgregarEstudiante}>Agregar empleado</Button>
                    </Col>
                </Row> */}

				<Row className="mt-3">
					<Col>
						<TableGrupos />
					</Col>
				</Row>
			</Container>
		)
	);
};

export default DashboardGrupos;
