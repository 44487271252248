import ListadoCursos from "@components/general/Cursos/ListadoCursos";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Animated } from "react-animated-css";
import { Alert, Button, Card, Col, Container, Image, ResponsiveEmbed, Row } from "react-bootstrap";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

const HomeInvitado = ({ showScrollButton, handleChangeComponent, handleScrollToTop }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { nombreCompleto, rol } = usuarioLogeado;

	const [cursos, setCursos] = useState([]);

	const slides = [
		{
			image: (
				<div className="image-wrapper m-auto">
					<picture>
						<source srcSet={`${require("@assets/images/recursos_mos/logo_excel.png")}`} />
						<Image className="imageMos mt-3" src={`${require("@assets/images/recursos_mos/logo_excel.png")}`} alt="Imagen de slide" />
					</picture>
				</div>
			),
			title: <h2 className="my-2">Certificacación MOS</h2>,
			subtitle: (
				<h5 className="mx-5 px-5">
					Prepárate para tu Certificado Microsoft Office Specialist, te ayudará a mejorar tu análisis de datos, la toma de decisiones y optimización de procesos, además de destacar para mejores
					puestos y oportunidades laborales.
				</h5>
			),
			control: (
				<Button
					onClick={() => {
						handleChangeComponent(multiViewComponentsPaths.solicitudLlamadaVenta);
					}}
					className="mt-2"
					// block
				>
					Haz clic aquí para tener acceso a la Certificación MOS
				</Button>
			),
		},
		{
			image: (
				<picture>
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos.webp")}`} />
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos.png")}`} />
					<Image width="100%" height="50%" fluid loading="lazy" className="comentario-mos" src={`${require("@assets/images/recursos_mos/reseña_mos.webp")}`} alt="Imagen de slide" />
				</picture>
			),
		},
		{
			image: (
				<picture>
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos2.webp")}`} />
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos2.png")}`} />
					<Image width="100%" height="50%" fluid loading="lazy" className="comentario-mos" src={`${require("@assets/images/recursos_mos/reseña_mos2.webp")}`} alt="Imagen de slide" />
				</picture>
			),
		},
		{
			image: (
				<picture>
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos3.webp")}`} />
					<source srcSet={`${require("@assets/images/recursos_mos/reseña_mos3.png")}`} />
					<Image width="100%" height="50%" fluid loading="lazy" className="comentario-mos" src={`${require("@assets/images/recursos_mos/reseña_mos3.webp")}`} alt="Imagen de slide" />
				</picture>
			),
		},
	];

	const handleMaterialCapacitacion = () => {
		const { materialCapacitacion } = multiViewComponentsPaths;

		handleChangeComponent(materialCapacitacion);
	};

	useEffect(() => {
		const consultarCursos = async () => {
			try {
				let { data: cursos } = await getCursos();

				if (!Array.isArray(cursos)) {
					cursos = [cursos];
				}

				setCursos(cursos);
			} catch (error) {
				console.log("Error al consultar los cursos: ", error);
			}
		};

		consultarCursos();
	}, []);

	return (
		<Animated animationIn="fadeIn" animationInDuration={250} animationOut="fadeOut" isVisible={true}>
			<Container fluid className="p-0">
				<Row className="my-4">
					<Col>
						<TitleDashboard
							title={
								<span style={{ fontSize: "1.5rem" }}>
									¡Hola {nombreCompleto}! Te damos la bienvenida a tu demostración de la plataforma &nbsp;
									<b style={{ fontSize: "2rem" }}>
										Click <span style={{ color: "rgb(245, 158, 11)" }}>+</span>
									</b>
								</span>
							}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<Alert variant="primary">
							{rol === typesTiposUsuario.invitado ? (
								<Alert.Heading>Agradecemos tu presencia en nuestra capacitación impartida en tu empresa</Alert.Heading>
							) : (
								<Alert.Heading>¡Gracias por tu opinión!</Alert.Heading>
							)}
							<hr />
							<h6>
								Es por ello que te obsequiamos esta prueba gratuita de {rol === typesTiposUsuario.invitado ? <b>1 mes</b> : <b>3 meses</b>} de nuestra nueva plataforma para que disfrutes de un listado
								de lecciones especialmente elegidas para ti y puedas conocer más sobre cómo funciona{" "}
								<b style={{ fontSize: "1.3rem" }}>
									Click <span style={{ color: "rgb(245, 158, 11)" }}>+</span>
								</b>
								.
								<br />
								<br />
								{rol === typesTiposUsuario.invitado
									? "Seleccionamos algunos cursos para que conozcas nuestra plataforma."
									: "Elegimos el curso de Resolución de Conflictos para que conozcas más sobre Click+"}
								<br />
								<br />
								Para acceder a nuestro catálogo completo, adquiere aquí tu Licencia Premium y ve un listado de beneficios incluidos.
							</h6>

							<Button
								className="mb-4"
								variant="primary"
								onClick={() => {
									handleChangeComponent(multiViewComponentsPaths.solicitudLlamadaVenta);
								}}>
								Adquirir Licencia Premium
							</Button>

							<hr />

							<p className="mb-0">Recuerda que puedes utilizar esta plataforma las 24 horas del día, los 7 días de la semana hasta que tu tiempo de vigencia finalice.</p>
						</Alert>
					</Col>

					<Col>
						<div className="contenedor-mos invitado">
							<div className="contenedor-carrusel">
								<ReactCarousel interval={3500} showArrows={false} showStatus={false} showThumbs={false} swipeable={true} emulateTouch={true} infiniteLoop={true} autoPlay={false}>
									{slides.map((slide, key) => (
										<div className="contenido-mos" key={key}>
											{slide.image}

											{slide.title}

											{slide.subtitle}

											{slide.control}
										</div>
									))}
								</ReactCarousel>
							</div>
						</div>
					</Col>
				</Row>

        <Row className="mt-5 pt-4">
					<Col className="columna-capacitacion">
            <TitleDashboard title="Material Visto en Capacitación" />

						<div className="parallax contenedor-material-capacitacion mt-3">
							<div className="text-container">
								<div className="parallax-text">
									<h2>Material visto en capacitación</h2>

									<Button onClick={handleMaterialCapacitacion} className="btn-responsive" variant="warning">
										Ver material
									</Button>
								</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="mt-3">
					<Col>
						<Container fluid className="mt-5 pt-4">
							<TitleDashboard title="Obtén acceso ilimitado a este catálogo de cursos" />

							<h4 className="mt-4">
								¡Descubre el mundo de posibilidades que se abre ante ti con nuestra Licencia Premium! <br /> Accede al catálogo exclusivo de cursos que te llevarán al siguiente nivel. ¿Estás listo
								para potenciar tu aprendizaje?
							</h4>

							<Row className="mt-5">
								{cursos.map(
									(curso, idx) =>
										curso.estatus === 1 && (
											<Col id={idx} className="col-9 col-sm-6 col-md-4 col-lg-4 col-xl-3">
												<Card className="mb-4 card-predefinidas invitado">
													<picture width="100%" height="100%">
														<source srcSet={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} />

														<img
															loading="lazy"
															className="img-fluid"
															src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
															alt="Imagen de fondo"
														/>
													</picture>
												</Card>
											</Col>
										)
								)}
							</Row>
						</Container>
					</Col>
				</Row>

				<Row className="mt-3">
					<Col>
						<ListadoCursos handleChangeComponent={handleChangeComponent} showScrollButton={showScrollButton} />
					</Col>
				</Row>

				{/* <Row>
					<Col>
						<TitleDashboard title="Conoce la Plataforma Click+ con este vídeo demostración" />

						<div className="embed-responsive embed-responsive-16by9 mt-5 pt-1">
							<iframe title="Video Demostración" className="embed-responsive-item" src="https://globalclickmexico.com/img/video_demo/click_plus.mp4" allowFullScreen></iframe>
						</div>
					</Col>
				</Row> */}
			</Container>
		</Animated>
	);
};

export default HomeInvitado;
