import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getTemas = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temas } = await httpRequest({
        url: `${endpointsPaths.aparienciaPlataforma}/${httpRequestsPaths.aparienciaPlataforma.getTemas}`,
        method: "GET",
      });

      resolve(temas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};

export const selectTema = async (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temas } = await httpRequest({
        url: `${endpointsPaths.aparienciaPlataforma}/${httpRequestsPaths.aparienciaPlataforma.selectTema}/${idTema}`,
        method: "POST",
      });

      resolve(temas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};

export const subirImagenTemaPersonalizado = async (infoTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: temas } = await httpRequest({
        url: `${endpointsPaths.aparienciaPlataforma}/${httpRequestsPaths.aparienciaPlataforma.subirImagenTemaPersonalizado}`,
        method: "POST",
        body: JSON.stringify(infoTema)
      });

      resolve(temas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};

