import React, { useEffect, useState } from "react";

import getLocalStorage from "@helpers/localStorage/getLocalStorage";
import setLocalStorage from "@helpers/localStorage/setLocalStorage";

import UserContext from "./UserContext";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";

const UserProvider = ({ children }) => {
  const initialState = {};

  const [contextValue, setContextValue] = useState(
    getLocalStorage(localStorageItemNames.userContext, initialState)
  );

  useEffect(() => {
    setLocalStorage(localStorageItemNames.userContext, contextValue);
  }, [contextValue]);

  const clearContext = () => {
    setContextValue(initialState);
  };

  return (
    <UserContext.Provider
      value={{ contextValue, setContextValue, clearContext }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
