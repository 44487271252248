import { Notificacion } from "@components/general/Alertas/Alertas";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";
import { updateCursosUsuario } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const EliminarCurso = ({ usuario }) => {
	const [cursosUsuario, setCursosUsuario] = useState(JSON.parse(usuario.cursos));

	const [cursosEliminados, setCursosEliminados] = useState([]);

	const handleCheckboxChange = ({ target }) => {
		const { value, checked } = target;

		try {
			if (!checked) {
				const curso = cursosEliminados.find((c) => c.codigoCurso === value);

				if (curso === undefined) {
					setCursosEliminados([...cursosEliminados, ...cursosUsuario.filter((curso) => curso.codigoCurso === value)]);
				}
			} else {
				setCursosEliminados((nuevosCursos) => nuevosCursos.filter((nuevoCurso) => nuevoCurso.codigoCurso !== value));
			}
		} catch (error) {
			console.log("Error al eliminar el curso al listado de cursos del usuario: ", error);
		}
	};

	const handleEliminarCursos = async () => {
		try {
			const nuevosCursos = cursosUsuario.filter((curso) => !cursosEliminados.some((elim) => elim.codigoCurso === curso.codigoCurso));

			await updateCursosUsuario(nuevosCursos, usuario.idLicenciaCredencial);

			Notificacion("success", "Se eliminaron los cursos seleccionados para el usuario elegido");
		} catch (error) {
			console.log("Error al actualizar los cursos del usuario: ", error);

			NotificationManager.error("Error al eliminar los cursos al usuario, revisa la consola para mayor información");
		}
	};

	return (
		<Container fluid>
			<h3>Eliminar cursos al usuario</h3>

			<Row className="text-start mt-2">
				<Col>
					<h4>Licencia adquirida: {usuario.nombreLicencia}</h4>

					<h5>{cursosUsuario.length} cursos actualmente asignados al usuario</h5>
				</Col>
			</Row>

			<Row>
				<Form.Group className="lista-cursos-disponibles text-start" as={Col}>
					{cursosUsuario.map((curso, idx) => (
						<Form.Check
							className="checkbox-curso-disponible"
							defaultChecked={true}
							id={curso.codigoCurso}
							type="switch"
							label={`${curso.nombre} - ${curso.codigoCurso} ${
								codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === curso.codigoCurso) !== undefined ? "- Usuarios Invitados" : ""
							}`}
							key={idx}
							value={curso.codigoCurso}
							onChange={handleCheckboxChange}
						/>
					))}
				</Form.Group>

				<Col className="cursos-agregados-licencia">
					<h5>Cursos a eliminar</h5>

					{cursosEliminados.map((cursoSeleccionado, idx) => (
						<p key={idx}>
							{cursoSeleccionado.nombre} - {cursoSeleccionado.codigoCurso}{" "}
							{codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === cursoSeleccionado.codigoCurso) !== undefined ? "- Usuarios Invitados" : ""}
						</p>
					))}
				</Col>
			</Row>

			<Row className="mt-3">
				<Col>
					<div className="d-grid gap-2 mt-4">
						<Button onClick={handleEliminarCursos} type="submit" variant="success" size="lg">
							Guardar
						</Button>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default EliminarCurso;
