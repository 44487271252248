import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import React, { useState } from "react";

import DashboardAsesores from "./Dashboards/Asesor/DashboardAsesores";
import DashboardBuscadorUsuarios from "./Dashboards/BuscadorUsuarios/DashboardBuscadorUsuarios";
import { DashboardEmpleados } from "./Dashboards/Empleado/DashboardEmpleados";
import DashboardEstudiantes from "./Dashboards/Estudiante/DashboardEstudiantes";
import DashboardGrupos from "./Dashboards/Grupo/DashboardGrupos";
import DashboardAdministradores from "./Dashboards/Administrador/DashboardAdministradores";
import DashboardCursosUsuario from "./Dashboards/CursosUsuario/DashboardCursosUsuario";
import DashboardInvitado from "./Dashboards/Invitado/DashboardInvitado";
import DashboardLecturaVeloz from "./Dashboards/LecturaVeloz/DashboardLecturaVeloz";
import DashboardCambiosLicencia from "./Dashboards/CambiosLicencia/DashboardCambiosLicencia";

// import './dashboardUsuarios.css'

export const DashboardUsuarios = ({ handleChangeComponent }) => {
	const [activeTab, setActiveTab] = useState("buscador");

	const [tabs, setTabs] = useState([
		{
			eventKey: "buscador",
			title: "Buscador de Usuarios",
			getComponent: (props) => <DashboardBuscadorUsuarios {...props} handleChangeTab={handleChangeTab} handleChangeComponent={handleChangeComponent} />,
		},
		// {
		// 	eventKey: "asesores",
		// 	title: "Asesores",
		// 	getComponent: (props) => <DashboardAsesores {...props} />,
		// },
		// {
		// 	eventKey: "empleados",
		// 	title: "Empleados",
		// 	getComponent: (props) => <DashboardEmpleados {...props} />,
		// },
		// {
		// 	eventKey: "estudiantes",
		// 	title: "Estudiantes",
		// 	getComponent: (props) => <DashboardEstudiantes {...props} />,
		// },
		// {
		// 	eventKey: "invitados",
		// 	title: "Invitados",
		// 	getComponent: (props) => <DashboardInvitado {...props} />,
		// },
		// {
		// 	eventKey: "lecturaVeloz",
		// 	title: "Usuarios Lectura Veloz",
		// 	getComponent: (props) => <DashboardLecturaVeloz {...props} />,
		// },
		{
			eventKey: "grupos",
			title: "Grupos",
			getComponent: (props) => <DashboardGrupos {...props} />,
		},
		{
			eventKey: "admin",
			title: "Administradores",
			getComponent: (props) => <DashboardAdministradores {...props} />,
		},
		// {
		// 	eventKey: "cursosUsuario",
		// 	title: "Cursos de Usuario",
		// 	getComponent: (props) => <DashboardCursosUsuario {...props} />,
		// },
		// {
		// 	eventKey: "cambioLicencia",
		// 	title: "Cambios de Licencia",
		// 	getComponent: (props) => <DashboardCambiosLicencia {...props} />,
		// },
	]);

	const defaultActiveTab = tabs[0].eventKey;

	const handleChangeTab = (tab) => {
		try {
			setActiveTab(tab);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Container fluid>
			<Row className="mb-5">
				<Col>
					<Tabs id="tabsUsuarios" defaultActiveKey={defaultActiveTab} onSelect={handleChangeTab} activeKey={activeTab}>
						{tabs.map((tab, idx) => (
							<Tab key={idx} eventKey={tab.eventKey} title={tab.title}>
								{tab.getComponent({
									isTabActive: tab.eventKey === activeTab,
								})}
							</Tab>
						))}
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};
