import React from "react";

const TitleForoAyuda = ({ title }) => {
  return (
    <>
      {title ? <h2 className="titulo-foro">{title}</h2> : <h3>Sin titulo</h3>}
      <div
        className="separador-foro"
        style={{ width: "65px", height: "3px", backgroundColor: "#ffc107" }}
      ></div>
    </>
  );
};

export default TitleForoAyuda;
