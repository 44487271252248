import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PaquetesIncluidosListItem = ({ title, classNames = undefined }) => {
	return (
		<li className={classNames !== undefined ? classNames : ""}>
			<span className="fa-li text-warning">
				<FontAwesomeIcon icon={faCheck} />
			</span>
			{title}
		</li>
	);
};

export default PaquetesIncluidosListItem;
