import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getNotificaciones = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: notificaciones } = await httpRequest({
        url: `${endpointsPaths.notificaciones}/${httpRequestsPaths.notificaciones.getNotificaciones}`,
        method: "GET",
      });

      resolve(notificaciones);
    } catch (error) {
      // console.log("Error al obtener las notificaciones");

      reject(error);
    }
  });
};

export const addNotificacion = (notificacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notificacionAgregada = await httpRequest({
        url: `${endpointsPaths.notificaciones}/${httpRequestsPaths.notificaciones.addNotificacion}`,
        method: "POST",
        body: JSON.stringify(notificacion),
      });

      resolve(notificacionAgregada);
    } catch (error) {
      // console.log("error al agregar la notificacion: ", error);

      reject(error);
    }
  });
};

export const deactivateNotificacion = (idNotificacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notificacionDesactivada = await httpRequest({
        url: `${endpointsPaths.notificaciones}/${httpRequestsPaths.notificaciones.deactivateNotificacion}/${idNotificacion}`,
        method: "POST",
      });

      resolve(notificacionDesactivada);
    } catch (error) {
      // console.log("Error al desactivar la notificacion: ", error);

      reject(error);
    }
  });
};

export const reactivateNotificacion = (idNotificacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notificacionReactivada = await httpRequest({
        url: `${endpointsPaths.notificaciones}/${httpRequestsPaths.notificaciones.reactivateNotificacion}/${idNotificacion}`,
        method: "POST",
      });

      resolve(notificacionReactivada);
    } catch (error) {
      // console.log("Error al reactivar la notificacion: ", error);

      reject(error);
    }
  });
};

export const getNotificacionById = (idNotificacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notificacion = await httpRequest({
        url: `${endpointsPaths.notificaciones}${httpRequestsPaths.notificaciones.getNotificacionById}/${idNotificacion}`,
        method: "POST",
      });

      resolve(notificacion);
    } catch (error) {
      // console.log("Error al obtener la notificacion: ", error);

      reject(error);
    }
  });
};

export const updateNotificacion = (notificacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notificacionActualizada = await httpRequest({
        url: `${endpointsPaths.notificaciones}/${httpRequestsPaths.notificaciones.updateNotificacion}`,
        method: "POST",
        body: JSON.stringify(notificacion),
      });

      resolve(notificacionActualizada);
    } catch (error) {
      // console.log("Error al actualizar la notificacion: ", error);

      reject(error);
    }
  });
};
