import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Row, Container, Button, Card, Form, FloatingLabel, Image } from "react-bootstrap";

import { getUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

import "@assets/css/views/Login/style.css";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { enviarFormularioRecuperacionContrasenia } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import { Typography, Alert as MUIAlert } from "@mui/material";

export const RecuperacionCredenciales = (history) => {
	const formValuesInitialState = {
		codigoVerificacion: "",
		contrasenia: "",
		confirmacionContrasenia: "",
		correo: "",
	};

	const validacionContraseniaInitialState = {
		caracteresContrasenia: false,
		mayusculaContrasenia: false,
		numeroContrasenia: false,
		codigoVerificacion: false,
		contrasenia: false,
		confirmacionContrasenia: false,
		correo: false,
	};

	const [formValues, setFormValues] = useState(formValuesInitialState);

	const [validacionContrasenia, setValidacionContrasenia] = useState(validacionContraseniaInitialState);

	const [correoIngresado, setCorreoIngresado] = useState(false);

	const [correoErrorMessage, setCorreoErrorMessage] = useState("Ingresa tu correo electrónico");

	const [showLoader, setShowLoader] = useState(false);

	const handleOnChange = ({ target }) => {
		setFormValues({
			...formValues,
			[target.name]: target.value,
		});

		if (target.name === "correo") {
			if (regExpStrings.regexEmail(target.value)) {
				setValidacionContrasenia({
					...validacionContrasenia,
					correo: true,
				});
			}
		}
	};

	const handleEnviarFormularioCambioContrasenia = async () => {
		try {
			setShowLoader(true);

			const datosUsuario = await getUsuario(formValues.correo);

			console.log('datos usuario: ', datosUsuario);

			if (datosUsuario.message && datosUsuario.message === "No existe ningún usuario con este correo") {
				setValidacionContrasenia({
					...validacionContrasenia,
					correo: false,
				});

				setShowLoader(false);

				return setCorreoErrorMessage("El correo ingresado no existe, verifica tus datos");
			}

			if (!datosUsuario.usuarioTitular) {
				setValidacionContrasenia({
					...validacionContrasenia,
					correo: false,
				});

				setShowLoader(false);

				return setCorreoErrorMessage("El correo ingresado corresponde al usuario secundario, para recuperar tu contraseña contacta a tu titular.");
			}

			const urlFormulario = `${window.location.origin}${dashboardRoutesPaths.formularioRecuperacionContrasenia}`;

			await enviarFormularioRecuperacionContrasenia({
				correo: formValues.correo,
				nombre: datosUsuario.nombreCompleto,
				urlFormulario,
				idCredencial: datosUsuario.idCredencial
			});

			setCorreoIngresado(true);

			setShowLoader(false);

			ModalSweetAlert({
				icon: "success",
				title: "Enviamos un enlace para restablecer tu contraseña",
				html: (
					<>
						El enlace fue enviado a la dirección{" "}
						<Typography variant="overline">
							<b className="text-lowercase">{formValues.correo}</b>
						</Typography>
						<br />
						Ingresa a tu correo para cambiar tu contraseña desde el enlace que te enviamos.
					</>
				),
			});
		} catch (error) {
			console.log("Error al solicitar el código de verificación", error);

			Notificacion("error", "Algo sucedió al solicitar el restablecimiento de contraseña. Por favor, inténtalo de nuevo.");

			setShowLoader(false);
		}
	};

	return (
		<Container fluid className="formRegistro vh-100" style={{ overflowY: "hidden" }}>
			<Row className="justify-content-center align-items-center vh-100">
				<Col md={8} lg={7}>
					<Card
						body
						className="p-2"
						style={{
							borderRadius: "1.25rem",
						}}>
						<a style={{ textDecoration: "none" }} href={dashboardRoutesPaths.login} className="text-secondary pointer">
							<FontAwesomeIcon icon={faArrowLeft} /> Iniciar sesión
						</a>

						<Typography gutterBottom variant="h5" className="mt-3 text-center">
							Recuperación de Contraseña
						</Typography>

						<Row className="justify-content-center">
							<div className="imgKey m-4"></div>
						</Row>

						<Container fluid>
							{!correoIngresado ? (
								<>
									<h5 className="text-center mt-3">
										Para cambiar la contraseña es necesario ingresar el correo del titular de la licencia.
										<br />
										<br />
										Da clic en "Restablecer contraseña" para modificar tu contraseña, te enviaremos un enlace por correo electrónico.
									</h5>

									<Row className="my-4 mb-3 pb-5 justify-content-center">
										<Col>
											<FloatingLabel label="Correo electrónico" className="mb-3">
												<Form.Control type="text" placeholder="Ingresa el correo registrado en tu cuenta" onChange={handleOnChange} value={formValues.correo} name="correo" autoComplete="off" />
											</FloatingLabel>

											<MUIAlert severity="error">{correoErrorMessage}</MUIAlert>

											<div className="d-grid gap-2 mt-3">
												<Button onClick={handleEnviarFormularioCambioContrasenia} variant="primary" size="lg">
													{showLoader ? (
														<>
															Enviando correo... <FontAwesomeIcon icon={faSpinner} spin />
														</>
													) : (
														<>Restablecer contraseña</>
													)}
												</Button>
											</div>
										</Col>
									</Row>
								</>
							) : (
								<>
									<h5 className="text-center mt-3">
										Hemos enviado un enlace de restablecimiento al correo ingresado.
										<br />
										<br />
										Accede a tu correo para ingresar al formulario de cambio de contraseña.
									</h5>

									<Image className='d-flex align' src={require('@assets/images/check.png')} width={150} />
								</>
							)}
						</Container>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
