import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import DashboardRoutes from "@routes/DashboardRoutes/DashboardRoutes";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";

const AppRoutes = () => {
  const { root: rootPath } = dashboardRoutesPaths;

  return (
    <Router>
      <Switch>
        <Route path={rootPath} component={DashboardRoutes} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
