import { Notificacion } from '@components/general/Alertas/Alertas';
import { SpinnerImage } from '@components/general/Spinners/SpinnerImage/SpinnerImage';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropZone } from '@helpers/DropZone/DropZone';
import getDateFromUnix from '@helpers/dates/getDateFromUnix/getDateFromUnix';
import getUnixFromDate from '@helpers/dates/getUnixFromDate/getUnixFromDate';
import { updateMision } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import Compressor from 'compressorjs';
import React, { useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

const EditarMision = ({ setMisionEditada, infoMision}) => {
    console.log(infoMision);

    const {fechaRegistro, fechaExpiracion, descripcion, titulo, iconoProyecto, estatus, idMision} = infoMision;

    const [editable, setEditable] = useState(false);

	const [showPreview, setShowPreview] = useState(false);

	const [spinnerImagen, setSpinnerImagen] = useState(false);

    const [formValues, setFormValues] = useState({
        titulo,
        descripcion: descripcion.replace(/^"|"$/g, ''), 
        iconoProyecto,
        fechaExpiracion
    })

    const handleOnChange = ({currentTarget}) =>{
        let {name, value} = currentTarget;
        console.log({name, value})
        
        if(name === "fechaExpiracion"){
            const fechaFormateada = getUnixFromDate(value)
            console.log(fechaFormateada)
            value = fechaFormateada;
        }

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = async(e)=> {
        e.preventDefault();
        
        try {

            console.log(formValues);

            await updateMision({
                titulo: formValues.titulo,
                descripcion: JSON.stringify(formValues.descripcion),
                fechaExpiracion: formValues.fechaExpiracion,
                iconoProyecto: formValues.iconoProyecto,
                idMision
            })

            setMisionEditada(true);
            Notificacion("success", "Misión modificada con éxito");

        } catch (error) {
            console.log(error)
            Notificacion("error", "Error al modificar la misión");
        }
    }


    const cleanPreview = () => {
		// setFormValues({
		// 	...formValues,
		// 	avatarPerfil: recoveryAvatarPerfil,
		// });

		setShowPreview(false);
	};

    const handleSetEditable = () => {
        setEditable(!editable);
    }

    const getBase64Image = ({ target }) => {
		try {
			const { files } = target;

			const [file] = files;

			const fileReader = new FileReader();

			if (!file) {
				return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
			}

			//!codigo para comparacion de peso de imagen antes de ser comprimida
			fileReader.readAsDataURL(file);

			new Compressor(file, {
				quality: 0.2,
				success(result) {
					fileReader.readAsDataURL(result);

					fileReader.onload = async function () {
						setFormValues({ ...formValues, iconoProyecto: fileReader.result });

						setShowPreview(true);
					};
				},
				error(e) {
					console.log(e);

					Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
				},
			});
		} catch (e) {
			Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
		}
	};

    const [showDate, setShowDate] = useState(false);

    const handleEditarFecha = () =>{
        setShowDate(!showDate)
    }

  return (
    <Container fluid style={{textAlign: "start"}}>
        <Row>
            <Col>
                <Form onSubmit={handleSubmit}>
                    <FloatingLabel className='mb-3' controlId="floatingTitle" label="Título">
                        <Form.Control required name='titulo' onChange={handleOnChange} value={formValues.titulo} type="text" placeholder="Título de la misión" />
                    </FloatingLabel>

                    <Form.Check
                    onChange={handleEditarFecha}
                    className='mb-3'
                    type="switch"
                    id="custom-switch"
                    label="Editar fecha de expiración"
                />

                        {showDate &&(
                            <FloatingLabel className='mb-3' controlId="floatingDate" label="Fecha de expiración">
                                <Form.Control required name='fechaExpiracion' onChange={handleOnChange}  type="date" placeholder="Fecha de expiración" />
                            </FloatingLabel>
                        )}
                   

                    <FloatingLabel className='mb-3' controlId="floatingTextarea2" label="Descripción de la misión">
                        <Form.Control
                        required
                        name='descripcion'
                        value={formValues.descripcion.replace(/^"|"$/g, '')}
                        onChange={handleOnChange}
                        as="textarea"
                        placeholder="Agrega una descripción a la misión"
                        style={{ height: '100px' }}
                        />
                    </FloatingLabel>


                      <div className='text-center mb-3'>
                          {editable && (
                              <Form.Group>
                                  {showPreview ? (
                                      <>
                                          <h6 className="mt-3 subtitulos">Vista previa</h6>

                                          <img width="250" height="250" className="mt-2 vista-previa borderCircularImg" src={formValues.iconoProyecto} alt="Imagen de perfil"></img>

                                          <Col className="mt-2 ">
                                              <h5>
                                                  <FontAwesomeIcon className='pointer' onClick={cleanPreview} icon={faTrash} />
                                              </h5>
                                          </Col>
                                      </>
                                  ) : (
                                      <>
                                      {spinnerImagen ? <SpinnerImage /> : <DropZone getImageInBase64={getBase64Image} />}
                                      <h4><IoMdClose className='pointer m-4' onClick={handleSetEditable} color='red' /></h4>
                                      </>
                                  )}
                              </Form.Group>
                          )}

                          {!editable && (
                              <div className='d-flex flex-column text-center align-items-center'>
                              <h4><FaEdit onClick={handleSetEditable} color='grey' className='pointer mb-4 mt-4' /></h4>
                              <Image
                                  width="300"
                                  height="300"
                                  className="shadow  mb-3"
                                  src={iconoProyecto}
                                  alt={"Foto de perfil"}
                              />
                              
                              </div>
                          )}


                      </div>

                    <div className='d-grid gap-2'>
                        <Button type='submit'>Guardar cambios</Button>
                    </div>
                                    
                        
                </Form>
            </Col>
        </Row>
    </Container>
  )
}

export default EditarMision