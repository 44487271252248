import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Image, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash, FaTrophy } from "react-icons/fa";
import AgregarMision from "../modales/AgregarMision";
import { getMisiones, getParticipaciones, getProyectosByPerfil } from "@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones";
import "@assets/css/components/Misiones/style.css";
import EditarMision from "../modales/EditarMision";
import EliminarMision from "../modales/EliminarMision";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import ParticiparMision from "../modales/ParticiparMision";
import DejarProyecto from "../modales/DejarProyecto";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import SubirProyecto from "../modales/SubirProyecto";
import ListarParticipantes from "../modales/ListarParticipantes";
import Alert from "@mui/material/Alert";
import Terminos from "../modales/Terminos";
import Metricas from "../modales/Metricas";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";

const MisionesComponent = ({ tab }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { nombreCompleto, apellidos, idPerfil, correo, avatarPerfil, rol } = usuarioLogeado;

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	const [misiones, setMisiones] = useState([]);

	const [misionAgregada, setMisionAgregada] = useState(false);

	const [misionEditada, setMisionEditada] = useState(false);

	const [misionEliminada, setMisionEliminada] = useState(false);

	const [inscrito, setInscrito] = useState(false);

	const [participaciones, setParticipaciones] = useState([]);

	const [abandonoProyecto, setAbandonoProyecto] = useState(false);

	const [proyectos, setProyectos] = useState([]);

	const [proyectoSubido, setProyectoSubido] = useState(false);

	const [validando, setValidando] = useState(true);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	function SimpleDialog(props) {
		const { onClose, selectedValue, open } = props;

		const handleClose = () => {
			onClose(selectedValue);
		};

		const handleListItemClick = (value) => {
			onClose(value);
		};

		return (
			<React.Fragment>
				<div className="d-grid gap-2 d-lg-block gap-lg-0">
					<Button variant="outline-success" onClick={handleClickOpen}>
						Agregar misión <FaPlus />
					</Button>
				</div>
				<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
					<DialogActions>
						<Button className="btn-light" autoFocus onClick={handleClose}>
							<AiOutlineClose />
						</Button>
					</DialogActions>
					<DialogTitle id="responsive-dialog-title">{"Agregar una nueva misión"}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<AgregarMision setMisionAgregada={setMisionAgregada} handleClose={handleClose} />
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		);
	}

	//Objeto para proporcionar a la bd para participar en un proyecto
	const infoPerfil = {
		nombreCompleto,
		apellidos,
		idPerfil,
		correo,
		avatarPerfil,
	};

	const handleEditarMision = ({ currentTarget }) => {
		const index = currentTarget.id;
		console.log(index);
		ModalSweetAlert({
			title: "Editar misión",
			html: <EditarMision setMisionEditada={setMisionEditada} infoMision={misiones[index]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleEliminarMision = ({ currentTarget }) => {
		const index = currentTarget.id;
		ModalSweetAlert({
			title: "Eliminar misión",
			html: <EliminarMision infoMision={misiones[index]} setMisionEliminada={setMisionEliminada} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleParticipacion = ({ currentTarget }) => {
		const index = currentTarget.id;

		ModalSweetAlert({
			title: "Participar en el proyecto",
			html: <ParticiparMision setInscrito={setInscrito} infoPerfil={infoPerfil} infoMision={misiones[index]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "850px",
		});
	};

	const handleDejarProyecto = ({ currentTarget }) => {
		const index = currentTarget.id;
		
		ModalSweetAlert({
			title: "Dejar de participar",
			html: <DejarProyecto setAbandonoProyecto={setAbandonoProyecto} idMision={index} idPerfil={idPerfil} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "850px",
		});
	};

	const handleSubirProyecto = ({ currentTarget }) => {
		const index = currentTarget.id;
		const participante = participaciones.find((participacion) => participacion.idMision === parseInt(index));

		ModalSweetAlert({
			title: "Envíanos tu proyecto terminado",
			html: <SubirProyecto setProyectoSubido={setProyectoSubido} idPerfil={idPerfil} idMision={parseInt(index)} infoParticipante={participante} misiones={misiones} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "850px",
		});
	};

	const handleRegresarParticipantes = (idMision) => {
		handleSeleccionarGanador(idMision);
	};

	const handleSeleccionarGanador = (idMision) => {
		// const index = currentTarget.id;

		const infoMision = misiones.find((mision) => mision.idMision === idMision);

		ModalSweetAlert({
			title: "Seleccionar un ganador",
			html: <ListarParticipantes handleRegresarParticipantes={handleRegresarParticipantes} idMision={idMision} infoMision={infoMision} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "850px",
		});
	};

	const validarInscripcion = (idMision) => {
		const existe = participaciones.some((participacion) => participacion.idMision === idMision);

		return existe;
	};

	const validarProyectoSubido = (idMision) => {
		const existe = proyectos.some((proyecto) => proyecto.idMision === idMision);
		return existe;
	};

	const verTerminosYcondiciones = () => {
		ModalSweetAlert({
			title: "Términos y condiciones",
			html: <Terminos />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const metricasEvaluacion = () => {
		ModalSweetAlert({
			title: "Métricas de evaluación",
			html: <Metricas />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	useEffect(() => {
		const obtenerMisiones = async () => {
			try {
				const { data } = await getMisiones();
				if (Array.isArray(data)) {
					setMisiones(data);
				} else {
					setMisiones([data]);
				}
			} catch (error) {
				console.log(error);
				setMisiones([]);
			}
		};
		obtenerMisiones();
		const obtenerParticipaciones = async () => {
			try {
				setValidando(true);
				const { data } = await getParticipaciones(idPerfil);
				if (Array.isArray(data)) {
					setParticipaciones(data);
				} else {
					setParticipaciones([data]);
				}
				setInscrito(false);
				setValidando(false);
			} catch (error) {
				console.log(error);
				setValidando(false);
			}
		};
		obtenerParticipaciones();

		// const compararFechas = () => {
		//     const date1 = 1702576018;
		//     const date2 = 1702576018; //14/12/23

		//     const date3 = 1702748818; //16/12/23

		//     const fechaBD = new Date(date1 * 1000);

		//     const date4 = getUnixTimestamp();

		//     if(date1 === date4){
		//         console.log("Las fechas son iguales");
		//     }
		//     else if(date1 <= date3) {
		//         console.log("La fecha 3 es mayor o igual a la fecha 1")
		//     }

		// }

		// compararFechas()

		const obtenerProyectosPorPerfil = async () => {
			try {
				const { data } = await getProyectosByPerfil(idPerfil);
				if (Array.isArray(data)) {
					setProyectos(data);
				} else {
					setProyectos([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};
		obtenerProyectosPorPerfil();
		setValidando(false);
	}, []);

	useEffect(() => {
		if (misionAgregada || misionEditada || misionEliminada) {
			const obtenerMisiones = async () => {
				try {
					const { data } = await getMisiones();
					if (Array.isArray(data)) {
						setMisiones(data);
					} else {
						setMisiones([data]);
					}
				} catch (error) {
					console.log(error);
					setMisiones([]);
				}
			};
			obtenerMisiones();

			setMisionAgregada(false);
			setMisionEditada(false);
			setMisionEliminada(false);
		}
	}, [misionAgregada, misionEditada, misionEliminada]);

	useEffect(() => {
		if (inscrito || abandonoProyecto) {
			setValidando(true);
			const obtenerParticipaciones = async () => {
				try {
					const { data } = await getParticipaciones(idPerfil);
					if (Array.isArray(data)) {
						setParticipaciones(data);
					} else {
						setParticipaciones([data]);
					}

					setInscrito(false);
					setAbandonoProyecto(false);
					setValidando(false);
				} catch (error) {
					console.log(error);
					if (error.data.data === null) {
						setParticipaciones([]);
						setValidando(false);
						setAbandonoProyecto(false);
					}
				}
			};
			obtenerParticipaciones();
		}
	}, [inscrito, abandonoProyecto]);

	const [fechaActual, setFechaActual] = useState(getUnixTimestamp());

	useEffect(() => {
		if (proyectoSubido) {
			setValidando(true);

			const obtenerProyectosPorPerfil = async () => {
				try {
					const { data } = await getProyectosByPerfil(idPerfil);
					if (Array.isArray(data)) {
						setProyectos(data);
					} else {
						setProyectos([data]);
					}

					setProyectoSubido(false);
					setValidando(false);
				} catch (error) {
					console.log(error);
					setProyectoSubido(false);

					setValidando(false);
				}
			};
			obtenerProyectosPorPerfil();
		}
	}, [proyectoSubido]);

	useEffect(() => {
		const obtenerMisiones = async () => {
			try {
				const { data } = await getMisiones();
				if (Array.isArray(data)) {
					setMisiones(data);
				} else {
					setMisiones([data]);
				}
			} catch (error) {
				console.log(error);
				setMisiones([]);
			}
		};

		obtenerMisiones();

		const obtenerParticipaciones = async () => {
			try {
				setValidando(true);
				const { data } = await getParticipaciones(idPerfil);
				if (Array.isArray(data)) {
					setParticipaciones(data);
				} else {
					setParticipaciones([data]);
				}
				setInscrito(false);
				setValidando(false);
			} catch (error) {
				console.log(error);
				setValidando(false);
			}
		};
        
		obtenerParticipaciones();

		const obtenerProyectosPorPerfil = async () => {
			try {
				const { data } = await getProyectosByPerfil(idPerfil);
				if (Array.isArray(data)) {
					setProyectos(data);
				} else {
					setProyectos([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};
		obtenerProyectosPorPerfil();
	}, [tab]);

	return (
		<Container className="mt-4 mb-5 justify-content-end" fluid>
			<Row className="mb-4">
				<Col className=" text-end">
					<Button size="sm" className="mx-2 terminos" onClick={verTerminosYcondiciones}>
						Terminos y condiciones
					</Button>
					<Button size="sm" className="mx-2 metricas" onClick={metricasEvaluacion}>
						Métricas de evaluación
					</Button>
				</Col>
			</Row>
			<Row className="justify-content-between">
				<Col className="col-auto">
					<h3>
						<b>Proyectos disponibles</b>
					</h3>
				</Col>

				<Col className="col-12 col-lg-4 mt-4 mt-lg-0 text-lg-center">
					{rol === "administrador" && (
						<>
							<SimpleDialog open={open} onClose={handleClose} />
						</>
					)}
				</Col>
			</Row>
			<Row className="justify-content-center mt-4 ">
				<Col className="contenedor-misiones col-10">
					{misiones.length > 0 ? (
						misiones.map((mision, idx) => (
							<>
								<Row className="separacion-misiones d-flex justify-content-center align-content-center">
									<Col className="d-none d-lg-flex col-3 text-center">
										<Image className="mt-4 mb-4 rounded-3 img-mision" fluid src={mision.iconoProyecto}></Image>
									</Col>
									<Col className="col-10 col-lg-9 mt-4 mb-4 " style={{ height: "100px" }}>
										<h4>
											{mision.titulo}{" "}
											{rol === "administrador" && (
												<Col className="mt-3" lg={4}>
													<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Editar misión</Tooltip>}>
														<span>
															{" "}
															<FaEdit color="gray" id={idx} onClick={handleEditarMision} className="mx-1 pointer mb-2" />
														</span>
													</OverlayTrigger>

													<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Eliminar misión</Tooltip>}>
														<span>
															{" "}
															<FaTrash color="gray" id={idx} onClick={handleEliminarMision} className="mx-1 pointer mb-2" />
														</span>
													</OverlayTrigger>

													<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Mostrar participaciones</Tooltip>}>
														<span>
															{" "}
															<FaTrophy color="gray" id={mision.idMision} onClick={() => handleSeleccionarGanador(mision.idMision)} className="mx-1 pointer mb-2" />
														</span>
													</OverlayTrigger>
												</Col>
											)}
										</h4>

										<pre style={{ whiteSpace: "pre-line" }}>
											<b>
												<p style={{ height: "auto", maxHeight: "100px", overflowY: "scroll" }}>{mision.descripcion.replace(/^"|"$/g, "")}</p>
											</b>
										</pre>

										<div style={{ position: "relative" }}>
											{validando === true && (
												<div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "white", zIndex: 2 }}>
													<Spinner />
												</div>
											)}
											{validarProyectoSubido(mision.idMision) ? (
												<Alert severity="success">Proyecto enviado</Alert>
											) : fechaActual < mision.fechaExpiracion ? (
												validarInscripcion(mision.idMision) ? (
													<div className="mb-4 d-grid gap-2 d-lg-block gap-lg-0">
														{/* Subir el proyecto y almacenarlo en el sistema */}
														<Button className="mx-1" onClick={handleSubirProyecto} id={mision.idMision}>
															Subir proyecto
														</Button>
														<Button className="mx-1" id={mision.idMision} variant="danger" onClick={handleDejarProyecto}>
															Dejar de participar
														</Button>
													</div>
												) : (
													<div className="mb-4">
														<Button id={idx} variant="warning" onClick={handleParticipacion}>
															Participar
														</Button>
													</div>
												)
											) : (
												<Alert severity="info" key={idx}>
													Este proyecto ya expiró
												</Alert>
											)}
										</div>

										{!validarProyectoSubido(mision.idMision) && (
											<b>
												<small>Expira el {getDateFromUnix(mision.fechaExpiracion).toLocaleString()}</small>
											</b>
										)}
									</Col>
								</Row>
							</>
						))
					) : (
						<Row>
							<Col className="text-center mt-4">
								<h2>No hay misiones disponibles por el momento</h2>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default MisionesComponent;
