import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addReporteTema = (reporte) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addReporte = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.addReporteTema}`,
        method: "POST",
        body: JSON.stringify(reporte),
      });

      resolve(addReporte);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const addReporteComentario = (reporte) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addReporte = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.addReporteComentario}`,
        method: "POST",
        body: JSON.stringify(reporte),
      });

      resolve(addReporte);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const getReportes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getReportes } = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.getReportes}`,
        method: "GET",
      });

      resolve(getReportes);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const getReportesComentarios = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getReportes } = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.getReportesComentarios}`,
        method: "GET",
      });

      resolve(getReportes);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const getReportesByIdTema = (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getReportesByIdTema } = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.getReportesByIdTema}/${idTema}`,
        method: "POST",
      });

      resolve(getReportesByIdTema);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const getReporteComentarioByIdRespuesta = (idRespuestaTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getReportesByIdTema } = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.getReporteComentarioByIdRespuesta}/${idRespuestaTema}`,
        method: "POST",
      });

      resolve(getReportesByIdTema);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const getReportesComentariosByIdTema = (idRespuestaTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getReportesByIdTema } = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.getReportesComentariosByIdTema}/${idRespuestaTema}`,
        method: "POST",
      });

      resolve(getReportesByIdTema);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const updateEstadoReporte = (idTema) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateReporte = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.updateEstadoReporteTema}/${idTema}`,
        method: "POST",
      });

      resolve(updateReporte);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};

export const updateEstadoReporteComentario = (idReporte) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updateReporte = await httpRequest({
        url: `${endpointsPaths.reportes}/${httpRequestsPaths.reportes.updateEstadoReporteComentario}/${idReporte}`,
        method: "POST",
      });

      resolve(updateReporte);
    } catch (error) {
      // console.log("Error al enviar el reporte");

      reject(error);
    }
  });
};
