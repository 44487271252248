import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { faEdit, faEye, faPowerOff, faTrash, faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAsesorByGrupo } from "@helpers/httpRequests/httpRequestsUsuarios/asesores/httpRequestsAsesores";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import DesactivarUsuario from "../Modales/colaboradores/DesactivarUsuario";
import ModificarUsuario from "../Modales/colaboradores/ModificarUsuario";
import NuevoUsuario from "../Modales/colaboradores/NuevoUsuario";
import ReactivarUsuario from "../Modales/colaboradores/ReactivarUsuario";
import VerInfoUsuarioEmpresaial from "../Modales/colaboradores/VerInfoUsuarioEmpresarial";
import ListarAvanceEmpleado from "@components/general/MisAvances/components/ListarAvanceEmpleado";
import InvitarUsuarioExistente from "../Modales/colaboradores/InvitarUsuarioExistente";
import { getUsuariosByIdGrupo, getUsuariosInvitados } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import VerInfoInvitados from "../Modales/colaboradores/VerInfoInvitados";
import { deleteUsusarioDelGrupo } from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import { getTiempoPlataforma } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import moment from "moment";

const Colaboradores = ({ isTabActive, tab, idGrupo, rol, usuarioLogeado, infoGrupo, folioGrupo }) => {
	const { nombreCompleto, apellidos, avatarPerfil, idPersona, estudiantesRestantes } = usuarioLogeado;

	const [colaboradores, setColaboradores] = useState([]);

	const [asesores, setAsesores] = useState([]);

	const [empleadoRegistrado, setEmpleadoRegistrado] = useState(false);

	const [empleadoDesactivado, setEmpleadoDesactivado] = useState(false);

	const [empleadoModificado, setEmpleadoModificado] = useState(false);

	const [empleadoReactivado, setEmpleadoReactivado] = useState(false);

	const [usuarioAgregado, setUsuarioAgregado] = useState(false);

	const [usuarioEliminado, setUsuarioEliminado] = useState(false);

	const [mostrarGraficaEmpleado, setMostrarGraficaEmpleado] = useState(false);

	const [mostrarGraficaInvitado, setMostrarGraficaInvitado] = useState(false);

	const [idPerfilEmpleado, setIdPerfilEmpleado] = useState(0);

	const [usuariosInvitados, setUsuariosInvitados] = useState([]);

	const [nombreEmpleadoSeleccionado, setNombreEmpleadoSeleccionado] = useState("");

	const [yearsPlataforma, setYearsPlataforma] = useState(0);

	const [mesesPlataforma, setMesesPlataforma] = useState(0);

	const [diasPlataforma, setDiasPlataforma] = useState(0);

	const [horasPlataforma, setHorasPlataforma] = useState(0);

	const [minutosPlataforma, setMinutosPlataforma] = useState(0);

	/**MODALES PARA:
	 * -AVANCE GENERAL.
	 * -NUEVO USUARIO-
	 * ACCIONES DEL USUARIO EMPRESARIAL (AVANCE, VER INFORMACION, MODIFICAR PERFIL).
	 */

	const handleVerInformacion = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;

		ModalSweetAlert({
			title: "Información del colaborador",
			html: <VerInfoUsuarioEmpresaial colaborador={colaboradores[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleVerInfoInvitado = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;

		ModalSweetAlert({
			title: "Información del invitado",
			html: <VerInfoInvitados infoUsuario={usuariosInvitados[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleNuevoColaborador = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;

		ModalSweetAlert({
			title: "Nuevo usuario empresarial",
			html: <NuevoUsuario setEmpleadoRegistrado={setEmpleadoRegistrado} folioGrupo={folioGrupo} colaborador={colaboradores[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleModificarUsuario = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;

		ModalSweetAlert({
			title: "Editar información",
			html: <ModificarUsuario setEmpleadoModificado={setEmpleadoModificado} colaborador={colaboradores[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "1000px",
		});
	};

	const handleDesactivarUsuario = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;

		ModalSweetAlert({
			title: "Deshabilitar colaborador",
			html: <DesactivarUsuario setEmpleadoDesactivado={setEmpleadoDesactivado} colaborador={colaboradores[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleReactivarUsuario = ({ currentTarget }) => {
		const rowIndex = currentTarget.id;

		ModalSweetAlert({
			title: "Reactivar colaborador",
			html: <ReactivarUsuario setEmpleadoReactivado={setEmpleadoReactivado} colaborador={colaboradores[rowIndex]} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const eliminarUsuarioDelGrupo = async ({ currentTarget }) => {
		const { id } = currentTarget;

		ModalSweetAlert({
			title: `¿Seguro que deseas eliminar a esta persona de tu grupo empresarial?`,
			cancelButtonText: "Cancelar",
			cancelButtonColor: "#dc3545",
			showCancelButton: true,
			confirmButtonText: "Si, eliminar",
			confirmButtonColor: "#28a745",
			reverseButtons: true,
		}).then(async (isConfirmed) => {
			if (isConfirmed) {
				try {
					await deleteUsusarioDelGrupo({
						correo: id,
					});

					setUsuarioEliminado(true);

					Notificacion("success", "Invitado eliminado del grupo");
				} catch (error) {
					console.log(error);
				}
			}
		});
	};

	const handleInvitarUsuarioExistente = ({ currentTarget }) => {
		ModalSweetAlert({
			title: "Agregar invitado al grupo empresarial",
			html: <InvitarUsuarioExistente idGrupo={idGrupo} infoGrupo={infoGrupo} setUsuarioAgregado={setUsuarioAgregado} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	const handleAvancesEmpleado = async (nombre, tiempoPlataforma, id, idPersona) => {
		try {
			setIdPerfilEmpleado(id);

			setNombreEmpleadoSeleccionado(nombre);

			const { data } = await getTiempoPlataforma(idPersona);
			// console.log(data);
			const { tiempoEnPlataforma } = data;

			const duracion = moment.duration(tiempoEnPlataforma, "minutes");

			const { days, hours, minutes, months, years } = duracion._data;

			setDiasPlataforma(days);
			setHorasPlataforma(hours);
			setMinutosPlataforma(minutes);
			setMesesPlataforma(months);
			setYearsPlataforma(years);

			setMostrarGraficaEmpleado(true);
		} catch (error) {
			console.log(error);
		}
	};

	const handleAvancesInvitado = async (infoUsuario) => {
		try {
			//consultar el tiempo en plataforma
			const { nombreCompleto, apellidos, idPerfil, idPersona } = infoUsuario;

			const nombre = nombreCompleto + " " + apellidos;

			const { data } = await getTiempoPlataforma(idPersona);
			const { tiempoEnPlataforma } = data;

			const duracion = moment.duration(tiempoEnPlataforma, "minutes");

			const { days, hours, minutes, months, years } = duracion._data;

			setDiasPlataforma(days);
			setHorasPlataforma(hours);
			setMinutosPlataforma(minutes);
			setMesesPlataforma(months);
			setYearsPlataforma(years);

			setIdPerfilEmpleado(idPerfil);

			setNombreEmpleadoSeleccionado(nombre);

			setMostrarGraficaInvitado(true);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const obtenerUsuariosEmpresariales = async () => {
			try {
				console.log("idGrupo: ", idGrupo);

				const { data } = await getUsuariosByIdGrupo(idGrupo);

				console.log("usuarios empresariales: ", data);

				if (Array.isArray(data)) {
					setColaboradores(data);
				} else {
					setColaboradores([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};

		const obtenerUsuariosInvitados = async () => {
			try {
				const { data } = await getUsuariosInvitados(idGrupo);
				if (Array.isArray(data)) {
					setUsuariosInvitados(data.data);
				} else {
					setUsuariosInvitados([data.data]);
				}
			} catch (error) {
				console.log(error);
				setUsuariosInvitados([]);
			}
		};

		const obtenerAsesor = async () => {
			try {
				const { data } = await getAsesorByGrupo(idGrupo);

				if (Array.isArray(data)) {
					setAsesores(data);
				} else {
					setAsesores([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerUsuariosInvitados();

		obtenerUsuariosEmpresariales();

		obtenerAsesor();
	}, []);

	useEffect(() => {
		if (empleadoRegistrado || empleadoDesactivado || empleadoReactivado || empleadoModificado || usuarioAgregado || usuarioEliminado) {
			const obtenerUsuariosEmpresariales = async () => {
				try {
					const { data } = await getUsuariosByIdGrupo(idGrupo);
					if (Array.isArray(data)) {
						setColaboradores(data);
					} else {
						setColaboradores([data]);
					}
				} catch (error) {
					console.log(error);
				}
			};

			obtenerUsuariosEmpresariales();

			const obtenerUsuariosInvitados = async () => {
				try {
					const { data } = await getUsuariosInvitados(idGrupo);
					if (Array.isArray(data)) {
						setUsuariosInvitados(data.data);
					} else {
						setUsuariosInvitados([data.data]);
					}
				} catch (error) {
					setUsuariosInvitados([]);
				}
			};

			obtenerUsuariosInvitados();

			setEmpleadoRegistrado(false);

			setEmpleadoDesactivado(false);

			setEmpleadoReactivado(false);

			setEmpleadoModificado(false);

			setUsuarioAgregado(false);

			setUsuarioEliminado(false);
		}
	}, [empleadoRegistrado, empleadoDesactivado, empleadoReactivado, empleadoModificado, usuarioAgregado, usuarioEliminado]);

	return (
		isTabActive && (
			<>
				{rol === "asesor" && (
					<Container>
						<Row className="mt-5  mb-5 justify-content-start">
							<Col className="col-auto p-0">
								<Col className="col-auto p-0">
									{/* //TODO Este enlace de avance general nos generará un reporte general del grupo */}
									{/* <Button className='mx-1'><FontAwesomeIcon icon={faChartBar} /> Avance</Button> */}

									{estudiantesRestantes > 0 ? (
										<>
											<Button onClick={handleNuevoColaborador} className="mx-1 " variant="success">
												<FontAwesomeIcon icon={faUserPlus} /> Nuevo colaborador
											</Button>

											<Button onClick={handleInvitarUsuarioExistente} className="mx-1 " variant="info">
												<FontAwesomeIcon icon={faUserPlus} /> Invitar a un usuario existente
											</Button>
										</>
									) : null}
								</Col>
							</Col>
						</Row>
					</Container>
				)}
				<Container className="pb-5 mb-" style={{ width: "75%" }}>
					{!mostrarGraficaInvitado && !mostrarGraficaEmpleado && (
						<Row className="mt-4 justify-content-between">
							<Col className="col-auto">
								<TitleDashboard title={"Asesores"} />
							</Col>
							<Col className="d-flex align-items-center col-auto">
								<small>
									<b> {`(${asesores.length} ${asesores.length > 1 ? "asesores" : asesores.length === 0 ? "asesores" : "asesor"})`}</b>
								</small>
							</Col>
						</Row>
					)}
					{/* //*SI EL ASESOR ES QUIEN ESTA LOGEADO, MOSTRAR DIRECTAMENTE LA INFORMACION DEL USUARIO LOGEADO */}

					{/* //*HACER UN MAPEO DE LOS ASESORES  */}
					{!mostrarGraficaInvitado &&
						!mostrarGraficaEmpleado &&
						(asesores.length > 0 ? (
							asesores.map((asesor, key) => (
								<Row key={key} className="mt-3">
									<Col className="col-12 col-md-auto text-center">
										<img
											className="rounded-circle"
											src={`${
												rol !== "asesor"
													? asesor.avatarPerfil === "" || asesor.avatarPerfil === null
														? require("@assets/images/avatar.png")
														: asesor.avatarPerfil
													: asesor.avatarPerfil === "" || asesor.avatarPerfil === null
													? require("@assets/images/avatar.png")
													: avatarPerfil
											}`}
											width="50"
											height="50"
											alt="asesor"></img>
										<span className="mx-4">{rol !== "asesor" ? `${asesor.nombreCompleto} ${asesor.apellidos}` : `${nombreCompleto} ${apellidos}`}</span>
									</Col>
								</Row>
							))
						) : (
							<Row className="mt-3">
								<Col className="text-center mt-5" style={{ color: "grey" }}>
									<FontAwesomeIcon icon={faUser} className="mb-3" size="4x" />
									<h4>El grupo no tiene asesores asignados</h4>
								</Col>
							</Row>
						))}

					{!mostrarGraficaInvitado &&
						(colaboradores.length > 0 ? (
							!mostrarGraficaEmpleado ? (
								<>
									<Row className="mt-4 justify-content-between">
										<Col className="col-auto">
											<TitleDashboard title={`Colaboradores `} />
										</Col>
										<Col className="d-flex align-items-center col-auto">
											<small>
												<b> {`(${colaboradores.length} ${colaboradores.length > 1 ? "colaboradores" : colaboradores.length === 0 ? "colaboradores" : "colaborador"})`}</b>
											</small>
										</Col>
									</Row>

									{colaboradores.map((colaborador, key) => (
										<Row key={key} className="mt-3 mb-3 justify-content-between">
											<Col className="col-12 col-md-auto text-center">
												<img
													className="rounded-circle"
													src={colaborador.avatarPerfil === null || colaborador.avatarPerfil === "" ? require("@assets/images/avatar.png") : colaborador.avatarPerfil}
													width="50"
													height="50"
													alt="asesor"></img>
												<span className="mx-4">{`${colaborador.nombreCompleto} ${colaborador.apellidos}`} </span>
											</Col>
											<Col className="col-12 col-md-auto d-flex align-items-center">
												{/* //TODO Enlazar a los avances del usuario */}
												{(colaborador.idPersona === idPersona || rol === "asesor") && (
													<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>{`${rol === "asesor" ? "Ver avances" : "Ver mis avances"} `}</Tooltip>}>
														{/* <FontAwesomeIcon id={colaborador.idPerfil} onClick={handleAvancesEmpleado} className="ms-3 pointer" style={{ color: "grey" }} icon={faChartBar} /> */}
														<Image
															className="pointer"
															fluid
															onClick={() => handleAvancesEmpleado(colaborador.nombreCompleto + " " + colaborador.apellidos, colaborador.tiempoPlataforma, colaborador.idPerfil, colaborador.idPersona)}
															src={require("@assets/images/grafica.png")}
															width="24"
															alt="Mis avances"></Image>
													</OverlayTrigger>
												)}

												{rol === "asesor" && (
													<>
														<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Ver información.</Tooltip>}>
															<div id={key} onClick={handleVerInformacion}>
																<FontAwesomeIcon className="ms-3 pointer" style={{ color: "grey" }} icon={faEye} />
															</div>
														</OverlayTrigger>

														<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Modificar información.</Tooltip>}>
															<div id={key} onClick={handleModificarUsuario}>
																<FontAwesomeIcon className="ms-3 pointer" style={{ color: "grey" }} icon={faEdit} />
															</div>
														</OverlayTrigger>

														{colaborador.estatusCredencial === 1 ? (
															<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Desactivar colaborador.</Tooltip>}>
																<div id={key} onClick={handleDesactivarUsuario}>
																	<FontAwesomeIcon className="ms-3 pointer" style={{ color: "grey" }} icon={faTrash} />
																</div>
															</OverlayTrigger>
														) : (
															<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Reactivar colaborador.</Tooltip>}>
																<div onClick={handleReactivarUsuario} id={key}>
																	<FontAwesomeIcon className="ms-3 pointer" style={{ color: "grey" }} icon={faPowerOff} />
																</div>
															</OverlayTrigger>
														)}
													</>
												)}
											</Col>
										</Row>
									))}
								</>
							) : (
								<ListarAvanceEmpleado
									minutosPlataformaEmpleado={minutosPlataforma}
									horasPlataformaEmpleado={horasPlataforma}
									mesesPlataformaEmpleado={mesesPlataforma}
									diasPlataformaEmpleado={diasPlataforma}
									yearsPlataformaEmpleado={yearsPlataforma}
									idPerfilEmpleado={idPerfilEmpleado}
									setMostrarGraficaInvitado={setMostrarGraficaInvitado}
									setMostrarGraficaEmpleado={setMostrarGraficaEmpleado}
									nombreEmpleadoSeleccionado={nombreEmpleadoSeleccionado}
									mostrarGraficaEmpleado={mostrarGraficaEmpleado}
									mostrarGraficaInvitado={mostrarGraficaInvitado}
								/>
							)
						) : (
							<Row className="mt-3">
								<Col className="text-center mt-5" style={{ color: "grey" }}>
									<FontAwesomeIcon icon={faUser} className="mb-3" size="4x" />
									<h4>Sin colaboradores por el momento</h4>
								</Col>
							</Row>
						))}

					{!mostrarGraficaEmpleado &&
						(!mostrarGraficaInvitado ? (
							<>
								<Row className="mt-4 justify-content-between">
									<Col className="col-auto">
										<TitleDashboard title={`Usuarios Invitados`} />
									</Col>
									<Col className="d-flex align-items-center col-auto">
										<small>
											<b> {`(${usuariosInvitados.length} ${usuariosInvitados.length > 1 ? "estudiantes invitados" : usuariosInvitados.length === 0 ? "estudiantes invitados" : "estudiante"})`}</b>
										</small>
									</Col>
								</Row>

								{usuariosInvitados.length > 0 ? (
									usuariosInvitados.map((estudiante, key) => (
										<Row key={key} className="mt-3 justify-content-between">
											<Col className="col-12 col-md-auto text-center">
												<img
													className="rounded-circle"
													src={estudiante.avatarPerfil === null || estudiante.avatarPerfil === "" ? require("@assets/images/avatar.png") : estudiante.avatarPerfil}
													width="50"
													height="50"
													alt="asesor"></img>
												<span className="mx-4">{estudiante.nombreCompleto + " " + estudiante.apellidos}</span>
											</Col>

											<Col className="col-12 col-md-auto d-flex align-items-center">
												{/* //TODO Enlazar a los avances del usuario */}
												{(estudiante.idPersona === idPersona || rol === "asesor") && (
													<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>{`${rol === "asesor" ? "Ver avances" : "Ver mis avances"} `}</Tooltip>}>
														{/* <FontAwesomeIcon id={estudiante.idPerfil} onClick={handleAvancesEmpleado} className="ms-3 pointer" style={{ color: "grey" }} icon={faChartBar} /> */}
														<Image
															className="pointer"
															fluid
															onClick={() => handleAvancesInvitado(usuariosInvitados[key])}
															src={require("@assets/images/grafica.png")}
															width="24"
															alt="Mis avances"></Image>
													</OverlayTrigger>
												)}

												{rol === "asesor" && (
													<>
														<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Ver información.</Tooltip>}>
															<div id={key} onClick={handleVerInfoInvitado}>
																<FontAwesomeIcon className="ms-3 pointer" style={{ color: "grey" }} icon={faEye} />
															</div>
														</OverlayTrigger>

														{estudiante.usuarioTitular ? (
															<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Eliminar del grupo empresarial</Tooltip>}>
																<div id={estudiante.correo} onClick={eliminarUsuarioDelGrupo}>
																	<FontAwesomeIcon className="ms-3 pointer" style={{ color: "grey" }} icon={faTrash} />
																</div>
															</OverlayTrigger>
														) : (
															<span></span>
														)}
													</>
												)}
											</Col>
										</Row>
									))
								) : (
									<Row className="mt-3">
										<Col className="text-center mt-5" style={{ color: "grey" }}>
											<FontAwesomeIcon icon={faUser} className="mb-3" size="4x" />
											<h4>No has invitado a nadie al grupo</h4>
										</Col>
									</Row>
								)}
							</>
						) : (
							<ListarAvanceEmpleado
								idPerfilEmpleado={idPerfilEmpleado}
								setMostrarGraficaInvitado={setMostrarGraficaInvitado}
								setMostrarGraficaEmpleado={setMostrarGraficaEmpleado}
								nombreEmpleadoSeleccionado={nombreEmpleadoSeleccionado}
								minutosPlataformaEmpleado={minutosPlataforma}
								horasPlataformaEmpleado={horasPlataforma}
								mesesPlataformaEmpleado={mesesPlataforma}
								diasPlataformaEmpleado={diasPlataforma}
								yearsPlataformaEmpleado={yearsPlataforma}
								mostrarGraficaEmpleado={mostrarGraficaEmpleado}
								mostrarGraficaInvitado={mostrarGraficaInvitado}
							/>
						))}
				</Container>
			</>
		)
	);
};

export default Colaboradores;
