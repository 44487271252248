import UserContext from "@context/User/UserContext";
import {
  enviarCorreoFacturacion,
  retrievePaymentIntent,
} from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import {
  registrarNuevoPago,
  validarDatosPago,
} from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";
import moment from "moment";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Image, Navbar, Row } from "react-bootstrap";
import DatosFacturacion from "../facturacion/DatosFacturacion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "@assets/css/components/Pagos/style.css";
import { Animated } from "react-animated-css";
import { Notificacion } from "@components/general/Alertas/Alertas";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import Loader from "@helpers/loader/loader";
import { enviarComprobanteAbono } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";

const AbonoRealizadoLoggedIn = ({ history }) => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  let { folioContrato, correo, nombreCompleto, apellidos, rol, licenciaRenovacion, folioRenovacion } = usuarioLogeado;

  if (rol === typesTiposUsuario.administrador) {
    folioContrato = "754";
  }

  const [paymentIntent, setPaymentIntent] = useState({});

  const [paymentMethod, setPaymentMethod] = useState({});

  const [facturacionEnviada, setFacturacionEnviada] = useState(false);

  const [formValuesFacturacion, setFormValuesFacturacion] = useState({
    usoCfdi: "",
    claveCfdi: "",
    constanciaSituacionFiscalData: "",
  });

  const [pagoRealizado, setPagoRealizado] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const [showLoader, setShowLoader] = useState(true);

  const handleButtonClick = async () => {
    let montoCobro = "",
      tipoTarjeta = "";

    const { amount } = paymentIntent;

    const {
      card: { funding },
    } = paymentMethod;

    montoCobro = amount / 100;

    tipoTarjeta =
      funding === "credit" ? "Tarjeta de crédito" : "Tarjeta de débito";

    await enviarCorreoFacturacion({
      pdfData: formValuesFacturacion.constanciaSituacionFiscalData,
      folioContrato: contextValue.folioContrato,
      cfdi: {
        clave: formValuesFacturacion.claveCfdi,
        descripcion: formValuesFacturacion.usoCfdi,
      },
      metodoPago: tipoTarjeta,
      nombreCliente: `${nombreCompleto} ${apellidos}`,
      correoCliente: correo,
      montoCobro: numberCommaSeparator(montoCobro),
    });

    Notificacion("success", "Solicitud de facturación enviada con éxito");

    setFacturacionEnviada(true);
  };

  const handleBackToDashboard = () => {
    history.replace(dashboardRoutesPaths.dashboard);
  };

  useEffect(() => {
    const validarExistenciaPago = async () => {
      try {
        const {
          stripeSession: { payment_intent },
        } = contextValue;

        const { paymentIntent, paymentMethod } = await retrievePaymentIntent(payment_intent);

        setPaymentIntent(paymentIntent);

        setPaymentMethod(paymentMethod);

        const { data } = await validarDatosPago(folioContrato);

        const { pagosContrato } = data;

        const pago = pagosContrato.find((pago) => pago.referenciaPago === paymentIntent.id);

        if (pago !== undefined) {
          return history.replace(dashboardRoutesPaths.dashboard);
        }

        setPagoRealizado(true);
      } catch (error) {
        console.log("Error al obtener el historial de pagos: ", error);
      }
    };

    validarExistenciaPago();
  }, []);

  useEffect(() => {
    if (pagoRealizado) {
      const registrarPagoSai = async () => {
        try {
          const { id, amount } = paymentIntent;

          const {
            card: { last4, funding },
          } = paymentMethod;

          const idMedioPago = funding === "credit" ? 7 : 9;

          const fechaActual = moment().format("yyyy-MM-DD|HH:mm");

          const [fechaPago, horaPago] = fechaActual.split("|");

          console.log('registrando pago en sai');

          let folioContratoAux = licenciaRenovacion ? `${folioContrato}-${folioRenovacion}` : folioContrato;

          await registrarNuevoPago(
            {
              fechaPago,
              horaPago,
              montoPago: amount / 100,
              referenciaPago: id,
              comentarioPago: "Abono desde Plataforma",
              idMedioPago,
            },
            folioContratoAux
          );

          console.log('enviando correo');

          await enviarComprobanteAbono({
            titular: `${nombreCompleto} ${apellidos}`,
            paymentIntentId: id,
            folioContrato,
            montoCobro: amount / 100,
            numeroTarjeta: `Tarjeta con terminación ${last4}`,
            description: `Abono a tu adeudo Click+ - Folio ${folioContrato}`,
            correo,
          });

          setPagoRealizado(false);

          setShowLoader(false);
        } catch (error) {
          console.log("error al validar el pago: ", error);
        }
      };

      registrarPagoSai();
    }
  }, [pagoRealizado]);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <Animated
          animationIn="fadeIn"
          animationInDuration={2000}
          animationOut="fadeOut"
          isVisible={true}
        >
          <Navbar variant="light" bg="dark">
            <Navbar.Brand href="#home">
              <Image
                fluid
                width="100"
                src={require("@assets/images/click.png")}
                alt="Logo Global Click"
              />
            </Navbar.Brand>
          </Navbar>

          <Container fluid className="py-30 bg-main-container">
            <Row>
              <Col>
                <Container fluid className="bg-light-dark">
                  <Row className="text-white">
                    <Col>
                      <Container fluid className="p-0 p-lg my-3">
                        <Row>
                          <Col className="text-center">
                            <ThumbUpSvg />
                          </Col>
                        </Row>

                        <Row>
                          <Col className="text-center">
                            <Container>
                              <Row>
                                <Col>
                                  <h3 className="text-success text-uppercase">
                                    ¡Gracias por tu pago!
                                  </h3>

                                  <p className="text-center">
                                    Tu pago ha sido recibido satisfactoriamente.
                                  </p>

                                  <p className="text-center">
                                    Recibirás un correo electrónico de{" "}
                                    <b>notificaciones@globalclickmexico.com</b>{" "}
                                    con tu comprobante de pago.
                                  </p>
                                </Col>
                              </Row>

                              {!facturacionEnviada && (
                                <>
                                  <DatosFacturacion
                                    formValues={formValuesFacturacion}
                                    setFormValues={setFormValuesFacturacion}
                                  />

                                  {formValuesFacturacion.facturacionSolicitada && (
                                    <Button
                                      variant="success"
                                      onClick={handleButtonClick}
                                      disabled={facturacionEnviada}
                                    >
                                      Solicitar facturación{" "}
                                      {showSpinner && (
                                        <FontAwesomeIcon
                                          icon={faSpinner}
                                          spin
                                        />
                                      )}
                                    </Button>
                                  )}
                                </>
                              )}

                              <Row>
                                <Col>
                                  <Button
                                    variant="primary"
                                    onClick={handleBackToDashboard}
                                  >
                                    Regresar a la plataforma
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Animated>
      )}
    </>
  );
};

export default AbonoRealizadoLoggedIn;
