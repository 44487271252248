import { Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";

import { Notificacion } from "@components/general/Alertas/Alertas";
import { addConsejo } from "@helpers/httpRequests/httpRequestsConsejos/httpRequestsConsejos";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";

const AgregarConsejo = ({ setConsejoAgregado }) => {
  const [cursos, setCursos] = useState([]);

  const [versiones, setVersiones] = useState([]);

  const [validacionForm, setValidacionForm] = useState({
    tituloConsejo: false,
    descripcionConsejo: false,
    cursoConsejo: false,
  });

  const [formValues, setFormValues] = useState({
    tituloConsejo: "",
    descripcionConsejo: "",
    cursoConsejo: "0",
    showVersiones: false,
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    let categoria = parseInt(value.substring(5, 6));

    setFormValues({
      ...formValues,
      [name]: value,
      showVersiones: categoria === 3 ? true : false,
    });
    if (value === "" || value === "0") {
      setValidacionForm({
        ...validacionForm,
        [name]: !validacionForm.name,
      });
    } else {
      setValidacionForm({
        ...validacionForm,
        [name]: false,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { tituloConsejo, descripcionConsejo, cursoConsejo } = formValues;

      let codigoCurso = cursoConsejo.substring(4, 0);

      if (tituloConsejo === "") {
        setValidacionForm({
          ...validacionForm,
          tituloConsejo: true,
        });
      } else if (descripcionConsejo === "") {
        setValidacionForm({
          ...validacionForm,
          descripcionConsejo: true,
        });
      } else if (cursoConsejo === "0") {
        setValidacionForm({
          ...validacionForm,
          cursoConsejo: true,
        });
      }

      if (
        tituloConsejo !== "" &&
        descripcionConsejo !== "" &&
        cursoConsejo !== "0"
      ) {
        const nuevoConsejo = {
          tituloConsejo,
          descripcionConsejo,
          codigoCurso,
          versiones: JSON.stringify(versiones),
        };
        const { message: insertarNuevoConsejo } = await addConsejo(
          nuevoConsejo
        );

        Notificacion("success", insertarNuevoConsejo);

        setConsejoAgregado(true);
      }
    } catch (error) {
      Notificacion("error", "Error al guardar nuevo consejo");
    }
  };

  const handleVersion = ({ target }) => {
    const { id } = target;

    const versionYaSeleccionada = versiones.indexOf(id);

    if (versionYaSeleccionada === -1) {
      setVersiones([...versiones, id]);
    } else {
      versiones.splice(versionYaSeleccionada, 1);
    }
  };

  useEffect(() => {
    const obtenerCursos = async () => {
      try {
        const { data: dataCursos } = await getCursos();
        setCursos(dataCursos);
      } catch (error) {}
    };
    obtenerCursos();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit} className="text-start">
        <Form.Group className="mb-4">
          <Form.Label>Título del consejo</Form.Label>
          <Form.Control
            className={validacionForm.tituloConsejo ? "is-invalid" : ""}
            onChange={handleOnChange}
            name="tituloConsejo"
            type="text"
            placeholder="Título del consejo"
          ></Form.Control>

          <Form.Control.Feedback type="invalid">
            Este campo no puede ir vacío
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Descripción del consejo</Form.Label>
          <Form.Control
            className={validacionForm.descripcionConsejo ? "is-invalid" : ""}
            onChange={handleOnChange}
            name="descripcionConsejo"
            type="text"
            placeholder="Titulo del consejo"
          ></Form.Control>

          <Form.Control.Feedback type="invalid">
            Este campo no puede ir vacío
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>Curso del consejo</Form.Label>
          <Form.Control
            className={validacionForm.cursoConsejo ? "is-invalid" : ""}
            as={"select"}
            required
            onChange={handleOnChange}
            name="cursoConsejo"
          >
            <option value="0">Selecciona una opción</option>
            {cursos.map((curso, idx) => (
              <option
                key={idx}
                value={`${curso.codigoCurso}/${curso.idCategoria}`}
              >
                {curso.nombre}
              </option>
            ))}
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            Selecciona un curso
          </Form.Control.Feedback>
        </Form.Group>

        {formValues.showVersiones && (
          <Form.Group>
            <Form.Label>
              Selecciona las versiones en las que se puede usar el consejo
            </Form.Label>
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 365"
              label={`Microsoft 365`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2021"
              label={`Microsoft Office 2021`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2019"
              label={`Microsoft Office 2019`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2016"
              label={`Microsoft Office 2016`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2013"
              label={`Microsoft Office 2013`}
            />
            <Form.Check
              onClick={handleVersion}
              type={"checkbox"}
              id="Office 2010"
              label={`Microsoft Office 2010`}
            />
          </Form.Group>
        )}

        <div className="d-grid gap-2 mt-4">
          <Button type="submit" variant="primary" size="lg">
            Agregar consejo
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AgregarConsejo;
