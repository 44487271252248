import "@assets/css/components/DashboardSecciones/DataTables/style.css";

import { Col, Container, Row } from "react-bootstrap";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import React, { useEffect, useState } from "react";
import {
  faEdit,
  faEye,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  getSecciones,
  reactivateSeccionByCodigo,
} from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import { EditarSeccion } from "../Modales/EditarSeccion/EditarSeccion";
import { EliminarSeccion } from "../Modales/EliminarSeccion/EliminarSeccion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import columnas from "./columns";

const TableSecciones = ({ seccionAgregada, setSeccionAgregada }) => {
  const [secciones, setSecciones] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [seccionEditada, setSeccionEditada] = useState(false);

  const [seccionEliminada, setSeccionEliminada] = useState(false);

  const [seccionReactivada, setSeccionReactivada] = useState(false);

  /**
   * ENCARGADO DE FILTRAR CADA QUE SE DETECTE CAMBIO EN EL BUSCADOR
   */
  // const filtrarElementos = (e) => {
  // 	try {
  // 		let busqueda = e.target.value.toLowerCase();
  // 		let search = data.filter((registro) => {
  // 			if (busqueda.length > 0) {
  // 				if (
  // 					registro.nombre.toLowerCase().toString().includes(busqueda) ||
  // 					registro.codigoCurso.toLowerCase().toString().includes(busqueda) ||
  // 					registro.codigoSeccion.toLowerCase().toString().includes(busqueda)
  // 				) {
  // 					return registro;
  // 				}
  // 			}
  // 			return false;
  // 		});

  // 		setState({ ...state, seccionesFiltradas: search });
  // 	} catch (e) {
  // 		console.log(e);
  // 	}
  // };

  useEffect(() => {
    const consultarSecciones = async () => {
      try {
        let { data: secciones } = await getSecciones();

        if (!Array.isArray(secciones)) {
          secciones = [secciones];
        }

        setSecciones(secciones);
      } catch (error) {
        console.log("error al consultar las secciones: ", error);

        Notificacion("error", "Algo ocurrió al consultar las secciones");
      }
    };

    consultarSecciones();
  }, []);

  useEffect(() => {
    if (secciones.length > 0) {
      const handleEditSeccion = async ({ currentTarget }) => {
        try {
          const codigoSeccion = currentTarget.id.split("-")[1];

          const seccion = secciones.filter(
            (seccion) => seccion.codigoSeccion === codigoSeccion
          )[0];

          await ModalSweetAlert({
            title: "Editar sección",
            html: <EditarSeccion seccion={seccion} />,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log("Error al editar la sección");
        }
      };

      const handleDeleteSeccion = async ({ currentTarget }) => {
        try {
          const codigoSeccion = currentTarget.id.split("-")[1];

          const seccion = secciones.filter(
            (seccion) => seccion.codigoSeccion === codigoSeccion
          )[0];

          await ModalSweetAlert({
            title: "Eliminar sección",
            html: (
              <EliminarSeccion
                seccion={seccion}
                setSeccionEliminada={setSeccionEliminada}
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log("Error al eliminar la sección: ", error);

          Notificacion(
            "error",
            "Algo sucedió al eliminar la sección, vuelve a intentar"
          );
        }
      };

      const handleReactivateSeccion = async ({ currentTarget }) => {
        try {
          const codigoSeccion = currentTarget.id.split("-")[1];

          await reactivateSeccionByCodigo(codigoSeccion);

          setSeccionReactivada(true);

          Notificacion("success", "Sección reactivada correctamente");
        } catch (error) {
          console.log("Error al reactivar la sección: ", error);

          Notificacion(
            "error",
            "No se pudo reactivar la sección, vuelve a intentar"
          );
        }
      };

      setDataTableColumns([
        ...columnas,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`edit-${row.codigoSeccion}`}
                className="me-1 pointer"
                onClick={handleEditSeccion}
              >
                <FontAwesomeIcon icon={faEdit} className="text-info" />
              </span>

              {row.estatus ? (
                <span
                  id={`delete-${row.codigoSeccion}`}
                  className="ms-1 pointer"
                  onClick={handleDeleteSeccion}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              ) : (
                <span
                  id={`reactivate-${row.codigoSeccion}`}
                  className="ms-1 pointer"
                  onClick={handleReactivateSeccion}
                >
                  <FontAwesomeIcon icon={faPowerOff} className="text-success" />
                </span>
              )}
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]);
    }
  }, [secciones]);

  useEffect(() => {
    if (
      seccionAgregada ||
      seccionEditada ||
      seccionEliminada ||
      seccionReactivada
    ) {
      const consultarSecciones = async () => {
        try {
          const { data: secciones } = await getSecciones();

          setSecciones(secciones);
        } catch (error) {
          console.log("error al consultar las secciones: ", error);

          Notificacion("error", "Algo ocurrió al consultar las secciones");
        }
      };

      consultarSecciones();

      setSeccionAgregada(false);

      setSeccionEditada(false);

      setSeccionEliminada(false);

      setSeccionReactivada(false);
    }
  }, [seccionAgregada, seccionEditada, seccionEliminada, seccionReactivada]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <DataTablesRecipient columns={dataTableColumns} data={secciones} />
        </Col>
      </Row>
    </Container>
  );
};

export default TableSecciones;
