import React, { useRef, useEffect } from 'react';

const AudioPlayer = ({ audioBuffer }) => {
    console.log(audioBuffer)
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioBuffer) {
      // Convierte el Buffer en un Blob
      const blob = new Blob([new Uint8Array(audioBuffer)], { type: 'audio/mp3' });

      // Crea una URL para el Blob
      const audioUrl = URL.createObjectURL(blob);

      // Actualiza la fuente del elemento de audio
      audioRef.current.src = audioUrl;

      return () => {
        // Libera la URL cuando el componente se desmonta
        URL.revokeObjectURL(audioUrl);
      };
    }
  }, [audioBuffer]);

  return <audio className='mt-5 mb-5' ref={audioRef} controls />;
};

export default AudioPlayer;