import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React from "react";
import { Accordion, Button, Col, Container, FloatingLabel, Form, Row, useAccordionButton } from "react-bootstrap";
import horarios from "./horarios";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { formatPhoneNumber } from "@helpers/strings/formatPhoneNumber/formatPhoneNumber";
import useWindowResize from "@customHooks/useWindowResize";

const AccordionDatosCredito = ({ formValues, setFormValues }) => {
	const nextEventKey = "4";

	const decoratedOnClick = useAccordionButton(nextEventKey);

	const {
		size: { width },
	} = useWindowResize();

	const {
		nombreReferencia1,
		parentescoReferencia1,
		numeroTelefonoReferencia1,
		nombreReferencia2,
		parentescoReferencia2,
		numeroTelefonoReferencia2,
		nombreEmpresa,
		numeroTelefonoEmpresa,
		puesto,
		antiguedad,
	} = formValues;

	const handleOnChange = ({ target }) => {
		let auxValues = {};

		switch (target.name) {
			case "numeroTelefonoReferencia1":
			case "numeroTelefonoReferencia2":
			case "numeroTelefonoEmpresa":
				auxValues = {
					[target.name]: formatPhoneNumber(target.value),
				};
				break;

			case "nombreReferencia1":
			case "parentescoReferencia1":
			case "nombreReferencia2":
			case "parentescoReferencia2":
			case "nombreEmpresa":
			case "antiguedad":
			case "puesto":
				auxValues = {
					[target.name]: capitalizeString(target.value),
				};
				break;

			default:
				auxValues = {
					[target.name]: target.value,
				};
				break;
		}

		setFormValues({
			...formValues,
			...auxValues,
		});
	};

	return (
		<Accordion.Item eventKey="3">
			<Accordion.Header>
				<Typography variant="button">Datos para Crédito</Typography>
			</Accordion.Header>

			<Accordion.Body>
				<Typography variant="overline">Referencias Personales</Typography>
				<br />
				<Typography variant="caption">Ingresa los datos de 2 referencias personales</Typography>
				<hr />

				<Container fluid>
					<Row>
						<Col xs={12} md={4}>
							<FloatingLabel label="Nombre #1" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									type="text"
									placeholder="Nombre"
									name="nombreReferencia1"
									onChange={handleOnChange}
									value={nombreReferencia1}
								/>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={4}>
							<FloatingLabel label="Parentesco #1" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}"
									type="text"
									placeholder="Parentesco"
									name="parentescoReferencia1"
									onChange={handleOnChange}
									value={parentescoReferencia1}
								/>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={4}>
							<FloatingLabel label={width > 992 && width < 1200 ? "Teléfono #1" : "Número de teléfono #1"} className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									maxLength={10}
									type="text"
									placeholder="Número de teléfono"
									name="numeroTelefonoReferencia1"
									onChange={handleOnChange}
									value={numeroTelefonoReferencia1}
								/>
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={4}>
							<FloatingLabel label="Nombre #2" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									type="text"
									placeholder="Nombre"
									name="nombreReferencia2"
									onChange={handleOnChange}
									value={nombreReferencia2}
								/>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={4}>
							<FloatingLabel label="Parentesco #2" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}"
									type="text"
									placeholder="Parentesco"
									name="parentescoReferencia2"
									onChange={handleOnChange}
									value={parentescoReferencia2}
								/>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={4}>
							<FloatingLabel label={width > 992 && width < 1200 ? "Teléfono #2" : "Número de teléfono #2"} className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									maxLength="10"
									type="text"
									placeholder="Número de teléfono"
									name="numeroTelefonoReferencia2"
									onChange={handleOnChange}
									value={numeroTelefonoReferencia2}
								/>
							</FloatingLabel>
						</Col>
					</Row>
				</Container>

				<Typography variant="overline">Datos de Empleo</Typography>
				<br />
				<Typography variant="caption">Ingresa los datos de tu empleo</Typography>
				<hr />

				<Container fluid>
					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label="Nombre de la empresa" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									type="text"
									placeholder="Nombre de la empresa"
									name="nombreEmpresa"
									onChange={handleOnChange}
									value={nombreEmpresa}
								/>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label="Número de teléfono" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									maxLength={10}
									type="text"
									placeholder="Número de teléfono"
									name="numeroTelefonoEmpresa"
									onChange={handleOnChange}
									value={numeroTelefonoEmpresa}
								/>
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label="Puesto en la empresa" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									type="text"
									placeholder="Puesto en la empresa"
									name="puesto"
									onChange={handleOnChange}
									value={puesto}
								/>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label="Antigüedad" className="mb-3">
								<Form.Control
									// className={numeroTelefonoValido ? "text-success" : "text-danger"}
									type="text"
									placeholder="Antigüedad"
									name="antiguedad"
									onChange={handleOnChange}
									value={antiguedad}
								/>
							</FloatingLabel>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={6}>
							<FloatingLabel label="Inicio horario laboral" className="mb-3">
								<Form.Select aria-label="Inicio horario laboral" onChange={handleOnChange} name="inicioHorarioLaboral">
									{horarios.map((horario, idx) => (
										<option key={idx} value={horario.hora}>
											{horario.hora}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Col>

						<Col xs={12} md={6}>
							<FloatingLabel label="Fin horario laboral" className="mb-3">
								<Form.Select aria-label="Fin horario laboral" onChange={handleOnChange} name="finHorarioLaboral">
									{horarios.map((horario, idx) => (
										<option key={idx} value={horario.hora}>
											{horario.hora}
										</option>
									))}
								</Form.Select>
							</FloatingLabel>
						</Col>
					</Row>

					<Row className="mt-3">
						<Col xs={12} md={{ span: 6, offset: 6 }}>
							<div className="d-grid gap-2 mb-3">
								<Button className="btn-next-accordion" variant="success" type="button" size="lg" onClick={decoratedOnClick}>
									Siguiente <FontAwesomeIcon icon={faArrowRight} />
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default AccordionDatosCredito;
