import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import _ from "lodash";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { getDatosSegundoUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/estudiantes/httpRequestsEstudiantes";
import { getGrupoById } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { getLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";

const VerUsuario = ({ usuario }) => {

	const [datosLicencia, setDatosLicencia] = useState([]);

	const [cursosLicencia, setCursosLicencia] = useState(JSON.parse(usuario.cursos));

	const [tiempoVigencia, setTiempoVigencia] = useState({
		diasVigencia: "",
		mesesVigencia: "",
	});

	const [datosUsuarioSecundario, setDatosUsuarioSecundario] = useState({});

	const [grupoEmpresarial, setGrupoEmpresarial] = useState([]);

	const { diasVigencia, mesesVigencia } = tiempoVigencia;

	const calcularTiempoVigencia = () => {
		const diferenciaEnMilisegundos = usuario.fechaExpiracion - getUnixTimestamp();

		const diferenciaEnDias = diferenciaEnMilisegundos / 86400;

		// if (diferenciaEnDias < 30) {
		// 	console.log(`Faltan ${Math.round(diferenciaEnDias)} días`);
		// } else {
		// 	console.log(`Faltan ${Math.floor(diferenciaEnDias / 30)} meses`);
		// }

		setTiempoVigencia({
			diasVigencia: Math.round(diferenciaEnDias),
			mesesVigencia: Math.floor(diferenciaEnDias / 30),
		});
	};

	const consultarUsuarioSecundario = async () => {
		try {
			const { data: datosSegundoUsuario } = await getDatosSegundoUsuario(usuario.segundoPerfil);

			const { data } = datosSegundoUsuario;

			setDatosUsuarioSecundario(data);
		} catch (error) {
			console.log(error);
		}
	};

	const consultarGrupoEmpresarial = async () => {
		try {
			const { data: grupoEmpleado } = await getGrupoById(usuario.idGrupo);

			setGrupoEmpresarial(grupoEmpleado);
		} catch (error) {
			console.log("Error al consultar el grupo del empleado seleccionado: ", error);
		}
	};

    const consultarDatosLicencia = async () => {
        try {
          const { data: licencia } = await getLicencia(usuario.idLicencia);
  
          setDatosLicencia(licencia);
  
          setCursosLicencia(JSON.parse(licencia.cursos));
  
          calcularTiempoVigencia();
        } catch (error) {
          console.log("Error al consultar los datos de la licencia: ", error);
        }
      };

	useEffect(() => {
        consultarDatosLicencia();

		if (usuario.rol === typesTiposUsuario.estudiante) {
			if (usuario.usuarioTitular) {
                if (usuario.segundoPerfilRegistrado) {
                    consultarUsuarioSecundario();
                }
			}
		}

		if (usuario.rol === typesTiposUsuario.asesor || usuario.rol === typesTiposUsuario.empleado) {
			consultarGrupoEmpresarial();
		}
	}, []);

	return (
		<Container fluid className="text-start">
			<Row>
				<Col>
					<h3>Datos personales</h3>
					<hr />

					<Container fluid>
						<Row>
							<Col>
								<p>
									<b>Nombre:</b> {usuario.nombreCompleto} {usuario.apellidos}
								</p>

								<p>
									<b>Correo electrónico:</b> {usuario.correo}
								</p>

								<p>
									<b>CURP:</b> {usuario.curp}
								</p>

								<p>
									<b>{usuario.usuarioTitular ? <span className="text-success">Usuario titular</span> : <span className="text-success">Usuario Secundario</span>}</b>
								</p>

								{usuario.rol === typesTiposUsuario.estudiante ? (
									<p>
										{usuario.usuarioTitular ? (
											<span>
												<b>Usuario secundario:</b> {datosUsuarioSecundario.nombreCompleto + " " + datosUsuarioSecundario.apellidos}
											</span>
										) : (
											<span>
												<b>Usuario titular:</b> {usuario.nombreCompleto + " " + usuario.apellidos}
											</span>
										)}
									</p>
								) : null}
							</Col>

							<Col>
								<p>
									<b>Folio de contrato:</b> {usuario.folioContrato}
								</p>

								<p>
									<b>Nombre de usuario:</b> {usuario.nombreUsuario}
								</p>

								<p>
									<b>Profesión:</b> {usuario.profesion !== "" ? usuario.profesion : "No especificado"}
								</p>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>

			<Row>
				<Col>
					<h3>Datos de la Licencia</h3>
					<hr />

					<Container fluid>
						<Row>
							<Col>
								<p>
									<b>Fecha de registro: </b> {getDateFromUnix(usuario.fechaRegistro)}
								</p>

								<p>
									<b>Fecha último acceso: </b> {usuario.fechaUltimoAcceso !== 0 && usuario.fechaUltimoAcceso !== null ? getDateFromUnix(usuario.fechaUltimoAcceso) : "Sin ingresos registrados"}
								</p>

								<p>
									<b>Tiempo restante: </b> {usuario.fechaUltimoAcceso !== 0 ? (mesesVigencia > 0 ? `${mesesVigencia} meses` : `${diasVigencia} días`) : `${datosLicencia.tiempoVigencia} meses`}
								</p>
							</Col>

							<Col>
								<p>
									<b>Licencia: </b> {usuario.nombreLicencia}
								</p>

								<p>
									<b>Tiempo de vigencia: </b> {usuario.tiempoVigencia} meses
								</p>

								<p>
									<b>Usuario adicional: </b>
									{usuario.usuarioExtra ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faTimes} className="text-danger" />}
								</p>
							</Col>
						</Row>

						<Row>
							<Col>
								<b>Cursos de la licencia</b>

								<Container fluid className="p-0">
									<Row>
										{(() => {
											const chunkSize = cursosLicencia.length > 1 ? Math.ceil(cursosLicencia.length / 2) : cursosLicencia.length;

											const chunks = _.chunk(cursosLicencia, chunkSize);

											return chunks.map((chunk, idxChunk) => (
												<Col key={idxChunk} className="p-0">
													<ul>
														{chunk.map((curso, idxCurso) => (
															<li key={idxCurso}>
																<h6>{curso.nombre}</h6>
															</li>
														))}
													</ul>
												</Col>
											));
										})()}
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>

			{usuario.rol === typesTiposUsuario.asesor || usuario.rol === typesTiposUsuario.empleado ? (
				<Row>
					<Col>
						<h3>Datos de grupo empresarial</h3>
						<hr />

						<Container fluid>
							<Row>
								<Col>
									<p>
										<b>Nombre de grupo empresarial: </b> {grupoEmpresarial.nombre !== "" ? grupoEmpresarial.nombre : "Sin nombre asignado"}
									</p>

									<p>
										<b>Nombre de la empresa: </b> {grupoEmpresarial.nombreEmpresa !== "" ? grupoEmpresarial.nombreEmpresa : "Sin nombre asignado"}
									</p>
								</Col>

								<Col>
									<p>
										<b>Folio del grupo: </b> {grupoEmpresarial.folioGrupo}
									</p>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			) : null}
		</Container>
	);
};

export default VerUsuario;
