import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getAdministradores = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: administradores } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.administrador.getAdministradores}`,
        method: "GET",
      });

      resolve(administradores);
    } catch (error) {
      // console.log("Error al consultar los administradores: ", error);

      reject(error);
    }
  });
};
