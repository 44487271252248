import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addEmpleadoMasivo = (empleados) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: empleadosAgregados } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addEmpleadoMasivo}`,
				method: "POST",
				body: JSON.stringify({ empleados }),
			});

			resolve(empleadosAgregados);
		} catch (error) {
			// console.log("Error al agregar a los empleados de forma masiva: ", error);

			reject(error);
		}
	});
};

export const addSegundoPerfil = (datosSegundoPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const segundoPerfilRegistrado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.empleados.addSegundoPerfil}`,
				method: "POST",
				body: JSON.stringify(datosSegundoPerfil),
			});

			resolve(segundoPerfilRegistrado);
		} catch (error) {
			// console.log("Error actualizar el segundo perfil: ", error);

			reject(error);
		}
	});
};

export const getEmpleados = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: empleados } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.empleados.getEmpleados}`,
				method: "GET",
			});

			resolve(empleados);
		} catch (error) {
			// console.log("Error a consultar los empleados por empresa: ", error);

			reject(error);
		}
	});
};

export const updateUsuarioEmpresarial = (usuarioEmpresarial) => {
	return new Promise(async (resolve, reject) => {
		try {
			const usuarioEmpresarialActualizado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.empleados.updateEmpleado}`,
				method: "POST",
				body: JSON.stringify(usuarioEmpresarial),
			});

			resolve(usuarioEmpresarialActualizado);
		} catch (error) {
			// console.log("Error al actualizar al usuario empresarial: ", error);

			reject(error);
		}
	});
};

export const updateColaborador = (usuarioEmpresarial) => {
	return new Promise(async (resolve, reject) => {
		try {
			const usuarioEmpresarialActualizado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.empleados.updateColaborador}`,
				method: "POST",
				body: JSON.stringify(usuarioEmpresarial),
			});

			resolve(usuarioEmpresarialActualizado);
		} catch (error) {
			// console.log("Error al actualizar al usuario empresarial: ", error);

			reject(error);
		}
	});
};

export const getEmpleadosByGrupo = async (idGrupo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: empleadosEmpresa } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.empleados.getEmpleadosByGrupo}/${idGrupo}`,
				method: "POST",
			});

			resolve(empleadosEmpresa);
		} catch (error) {
			// console.log("Error a consultar los empleados por grupo: ", error);

			reject(error);
		}
	});
};

// export const deleteEmpleadoById = (idUsuarioEmpresarial) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const usuarioEmpresarialActualizado = await httpRequest({
// 				url: `${endpointsPaths.usuarios}/${ httpRequestsPaths.usuarios.empleados.deleteEmpleado }/${ idUsuarioEmpresarial }`,
// 				method: 'POST'
// 			});

// 			resolve(usuarioEmpresarialActualizado);
// 		} catch (error) {
// 			console.log("Error al eliminar el empleado con id: " + idUsuarioEmpresarial, error);

// 			reject(error);
// 		}
// 	});
// };

// export const reactivateEmpleado = (idUsuarioEmpresarial) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const empleadoReactivado = await httpRequest({
// 				url: `${endpointsPaths.usuarios}/${ httpRequestsPaths.usuarios.empleados.reactivateEmpleado }/${ idUsuarioEmpresarial }`,
// 				method: "POST"
// 			});

// 			resolve(empleadoReactivado);
// 		} catch (error) {
// console.log("Error al reactivar al empleado: ", error);

// 			reject(error);
// 		}
// 	});
// };
