import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";

const columns = [
  {
    name: "Nombre", //nombre de la columna
    selector: (row) => row.nombre, //identificador de la columna
    sortable: true,
  },
  {
    name: "Codigo de curso", //nombre de la columna
    selector: (row) =>
      codigoCursosUsuarioInvitado.find(
        (invitado) => invitado.codigoInvitado === row.codigoCurso
      ) !== undefined
        ? `${row.codigoCurso} - Usuarios Invitados`
        : row.codigoCurso,
    sortable: true,
  },
  {
    name: "Cantidad de lecciones",
    selector: (row) => row.cantidadLecciones, //identificador de la columna
    sortable: true,
  },
];

export default columns;
