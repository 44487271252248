import { AUTH_TOKEN, getApiUrl } from "@config/config";
import validateLocalStorageItemExistence from "@helpers/localStorage/validateLocalStorageItemExistence";

const httpFormDataRequest = ({ url, body = undefined }) => {
	return new Promise(async (resolve, reject) => {
		try {
			let BASE_API_URL = "";

			if (validateLocalStorageItemExistence("BASE_API_URL")) {
				BASE_API_URL = localStorage.getItem("BASE_API_URL");
			} else {
				BASE_API_URL = await getApiUrl();
			}

			const options = {
				method: "POST",
				body,
				headers: {
					Authorization: window.atob(AUTH_TOKEN),
				},
			};

			const request = await fetch(`${BASE_API_URL}/${url}`, options);

			const response = await request.json();

			response.code === 200 ? resolve(response) : reject(response);
		} catch (error) {
			reject(error);
		}
	});
};

export default httpFormDataRequest;
