import React from "react";
import { Col } from "react-bootstrap";

const AvisoProductoDigital = () => {
	const ImagenesCertificacionMos = () => {
		return (
			<div className="mt-4 d-flex justify-content-center align-items-center">
				<picture className="pe-5">
					<source srcSet={require("@assets/images/compra/mos/mos-certification-logo.png")} type="image/png" />

					<img alt="Tarjetas aceptadas" className="d-block" loading="lazy" src={require("@assets/images/compra/mos/mos-certification-logo.webp")} width="150" />
				</picture>

				<picture>
					<source srcSet={require("@assets/images/compra/mos/logo-certiport.png")} type="image/png" />

					<img alt="Tarjetas aceptadas" className="d-block" loading="lazy" src={require("@assets/images/compra/mos/logo-certiport.webp")} width="250" />
				</picture>
			</div>
		);
	};
	return (
		<>
			<Col sm={12} lg className="d-none d-md-block">
				<h5>Este es un producto digital. Recibirás los datos para acceder a él en el correo electrónico ingresado durante la compra.</h5>

				<h5>Ninguno de nuestros productos genera IVA.</h5>

                <ImagenesCertificacionMos />
			</Col>

			<Col sm={12} lg className="d-md-none">
				<p>Este es un producto digital. Recibirás los datos para acceder a él en el correo electrónico ingresado durante la compra.</p>

				<p>Ninguno de nuestros productos genera IVA.</p>
                
                <ImagenesCertificacionMos />
			</Col>
		</>
	);
};

export default AvisoProductoDigital;
