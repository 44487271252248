import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

import httpRequest from "../httpRequest";

export const addLike = (infoLike) => {
  return new Promise(async (resolve, reject) => {
    try {
      const addLike = await httpRequest({
        url: `${endpointsPaths.likes}/${httpRequestsPaths.likes.addLike}`,
        method: "POST",
        body: JSON.stringify(infoLike),
      });
      resolve(addLike);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteLike = (infoLike) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deleteLike = await httpRequest({
        url: `${endpointsPaths.likes}/${httpRequestsPaths.likes.deleteLike}`,
        method: "POST",
        body: JSON.stringify(infoLike),
      });
      resolve(deleteLike);
    } catch (error) {
      reject(error);
    }
  });
};

export const getLikes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getLike } = await httpRequest({
        url: `${endpointsPaths.likes}/${httpRequestsPaths.likes.getLikes}`,
        method: "GET",
      });
      resolve(getLike);
    } catch (error) {
      reject(error);
    }
  });
};

export const getLikesPorTema = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: getLikeTema } = await httpRequest({
        url: `${endpointsPaths.likes}/${httpRequestsPaths.likes.getLikesPorTema}`,
        method: "GET",
      });
      resolve(getLikeTema);
    } catch (error) {
      reject(error);
    }
  });
};
