import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { getAgendasAsesorias, updateAgendaAsesoriaResuelta, updatePrivacidadAgendaAsesoria } from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";
import { Box, IconButton, Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookBookmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import SubtitleDashboard from "@components/general/Titles/SubtitleDashboard";
import CustomMultiStateCheckbox from "@components/administrador/DashboardAgendasAsesorias/CustomMultiStateCheckbox";

const DashboardAgendasAsesorias = () => {
	const columnas = useMemo(() => [
		{
			id: "idAgendaAsesoria",
			header: "# ID",
			accessorFn: (row) => row.idAgendaAsesoria,
		},
		{
			id: "fechaSolicitud",
			header: "Fecha de Solicitud",
			accessorFn: (row) => getDateFromUnix(row.fechaSolicitud),
		},
		{
			id: "fechaAgenda",
			header: "Fecha de la Asesoría",
			accessorFn: (row) => getDateFromUnix(row.fechaAgenda),
		},
		{
			id: "horarioAgenda",
			header: "Horario elegido",
			accessorFn: (row) => row.horarioAgenda,
		},
		{
			id: "temaElegido",
			header: "Tema de la Asesoría",
			accessorFn: (row) => row.temaElegido,
		},
		{
			id: "estatus",
			header: "Estatus",
			accessorFn: (row) => row.estatus,
		},
	]);

	const [agendasAsesorias, setAgendasAsesorias] = useState([]);

	const [refetch, setRefetch] = useState(false);

	const [loading, setLoading] = useState(false);

	const consultarAgendas = async () => {
		try {
			const agendasAsesorias = await getAgendasAsesorias();

			console.log('agendasAsesorias: ', agendasAsesorias);

			setAgendasAsesorias(agendasAsesorias);

			if (refetch) {
				setRefetch(false);
			}
		} catch (error) {
			console.log("error al obtener las agendas de asesorías: ", error);

			// NotificationManager.error("No se pudieron consultar las fechas no disponibles, recarga la página.");
		}
	};

	const handleResolverAsesoria = async (idAgendaAsesoria) => {
		try {
			setLoading(true);
			
			await updateAgendaAsesoriaResuelta(idAgendaAsesoria);
			
			NotificationManager.success("Agenda marcada como resuelta");

			setLoading(false);
			
			setRefetch(true);
		} catch (error) {
			console.log("error al resolver la asesoria: ", error);
		}
	};

	const handleChangePrivacidadAsesoria = async ({ idAgendaAsesoria, privacidad }) => {
		try {
			const publica = privacidad === 'public';
			
			await updatePrivacidadAgendaAsesoria({
				idAgendaAsesoria,
				publica
			});

			NotificationManager.success("Privacidad cambiada");
		} catch (error) {
			console.log('algo sucedió al actualizar la privacidad de la asesoria: ', error);
			
			NotificationManager.error("No se pudo actualizar, intenta de nuevo");
		}
	};

	const table = useMaterialReactTable({
		localization: MRT_Localization_ES,
		columns: columnas,
		data: agendasAsesorias,
		muiTableBodyCellProps: {
			//no useTheme hook needed, just use the `sx` prop with the theme callback
			sx: (theme) => ({
				backgroundColor: "rgba(248, 249, 250)",
			}),
		},
		renderDetailPanel: ({ row }) => {
			return (
				<Container fluid>
					<Row>
						<Col>
							<SubtitleDashboard title="Problemática presentada" />

							<br />

							<Typography gutterBottom variant="body1">
								{row.original.problematica}
							</Typography>
						</Col>
					</Row>

					{row.original.estatus !== "Agendada" ? (
						<Row className="mt-3">
							<Col>
								<SubtitleDashboard title="Feedback" />

								<br />

								<Typography gutterBottom variant="body1">
									{
										row.original.feedback !== null ? 
										row.original.feedback
										:
										'El usuario no ha brindado su retroalimentación.'
									}
								</Typography>
							</Col>
						</Row>
					) : null}
				</Container>
			);
		},
		enableRowActions: true,
		positionActionsColumn: "last",
		renderRowActions: ({ row, table }) => (
			<Box className="text-center d-inline-block">
				{row.original.estatus === "Agendada" ? (
					<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Resolver Asesoría</Tooltip>}>
						<IconButton className="d-inline-block me-3" color="secondary" onClick={() => handleResolverAsesoria(row.original.idAgendaAsesoria)} id={row.original.idAgendaAsesoria}>
							{
								loading ? 
								<FontAwesomeIcon icon={faSpinner} spin />
								:
								<FontAwesomeIcon icon={faBookBookmark} />
							}
						</IconButton>
					</OverlayTrigger>
				) : null}

				<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{row.original.publica ? "Ocultar al público" : "Mostrar al público"}</Tooltip>}>
					<div className="d-inline-block">
						<CustomMultiStateCheckbox idAgendaAsesoria={row.original.idAgendaAsesoria} publica={row.original.publica} handleChangePrivacy={handleChangePrivacidadAsesoria} />
					</div>
				</OverlayTrigger>
			</Box>
		),
	});

	useEffect(() => {
		if (agendasAsesorias.length === 0) {
			consultarAgendas();
		}
	}, []);

	useEffect(() => {
		if (refetch) {
			consultarAgendas();
		}
	}, [refetch]);

	return (
		<Container fluid>
			<Row className="mt-5">
				<Typography variant="h5" gutterBottom>
					Elige cuáles retroalimentaciones mostrar a los usuarios
				</Typography>

				<Col className="mt-3">
					<Row>
						<Col>
							<MaterialReactTable table={table} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default DashboardAgendasAsesorias;
