import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AvisoPrivacidad = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="privacy-policy-content">
            <h5 className="title-aviso-privacidad">
              <b>1. “Responsable” del tratamiento de sus datos personales.</b>
            </h5>
            <p className="description-aviso-privacidad">
              <b>Orange Click S. de R.L. de C.V.</b>, es la persona moral, que
              de acuerdo con la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares, (LFPDPPP) se entiende como (el{" "}
              <b>“Responsable”</b>) ya que, decide sobre el tratamiento de los
              datos personales que recaba de usted, como “Titular” de los
              mismos, entendiéndose como “Titular” la persona física a quien
              corresponden los datos personales, y por éstos últimos, cualquier
              información concerniente a una persona física identificada o
              identificable. Para el “Responsable”, el tratar sus datos de
              manera legítima y en apego a la Ley Federal de la Materia, resulta
              un tema prioritario. Este Aviso de Privacidad complementa
              cualesquiera otros avisos de privacidad simplificados o cortos que
              el “Responsable” haya puesto a su disposición por ser el titular
              de sus datos personales y resulta supletorio en todo aquello que
              expresamente no refieran tales avisos.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>2. Domicilio del “Responsable”.</b>
            </h5>
            <p className="description-aviso-privacidad">
              Calle Mixtecas # 104, colonia Bugambilias, en la ciudad y/o
              municipio León, Guanajuato. México C.P. 37270.
              <br />
              Dicho domicilio es el establecido por el “Responsable” para oír y
              recibir notificaciones
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                3. Finalidades del tratamiento de los datos personales de los
                CLIENTES del “Responsable”.
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              El “Responsable” en este acto recaba sus datos para las
              siguientes&nbsp;
              <b>finalidades</b>:
              <span>
                <ul>
                  <li>
                    A) Prestarle los servicios profesionales que usted requiera,
                    de conformidad con la propuesta que de los mismos el
                    Responsable le presente. Los servicios profesionales que el
                    “Responsable” puede prestarle son en los ramos de sistemas
                    operacionales, tales como seguridad, control y gestión de
                    tecnología, asesorías personalizadas en paquetería Office,
                    mejora de procesos, manejo de transacciones y manejo de las
                    tecnologías de Información, entre otros; y todos aquellos
                    relacionados con los anteriores para asesorar a personas
                    físicas nacionales y extranjeras.
                    <br />
                    <br />
                  </li>

                  <li>
                    B) Dar cumplimiento a las obligaciones contraídas con usted
                    de acuerdo a los servicios profesionales que se le presten,
                    así como informarle cualquier situación o cambio sobre
                    dichos servicios.
                    <br />
                    <br />
                  </li>

                  <li>
                    C) Evaluar la calidad de los servicios profesionales que
                    presta el “Responsable” y llevar a cabo encuestas de
                    satisfacción de clientes.
                    <br />
                    <br />
                  </li>

                  <li>
                    D) Mantener contacto con usted, con objeto de invitarlo a
                    conferencias, cursos o eventos, en el que se tratan temas de
                    actualidad que se relacionen con los servicios profesionales
                    que el “Responsable” le presta.
                    <br />
                    <br />
                  </li>
                </ul>
              </span>
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                4. Datos personales que recaba el “Responsable” de sus clientes.
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              Para llevar a cabo las finalidades descritas en el presente aviso
              de privacidad el "Responsable” utilizará datos personales de
              identificación, contacto, referencias personales, datos laborales
              y para facturación.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>5. Datos personales sensibles.</b>
            </h5>
            <p className="description-aviso-privacidad">
              El “Responsable” le informa que, para cumplir con las finalidades
              previstas en este aviso de privacidad, <b>NO</b> requiere recabar
              de usted datos personales sensibles.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                6. Opciones y medios para limitar el uso o divulgación de sus
                datos personales.
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              Para limitar el uso o divulgación de sus datos personales, a fin
              de que no sean tratados para fines mercadotécnicos o publicitarios
              por parte del “Responsable”, usted puede contactar directamente al
              Departamento de Datos Personales del “Responsable”, ubicado en
              Calle Mixtecas # 104, colonia Bugambilias, en la ciudad y/o
              municipio León, Guanajuato. México C.P. 37270., al teléfono{" "}
              <b>(477) 390-83-01</b>, extensión 103 con la Lic. Nancy Briseño, o a
              través de nuestros canales de atención al cliente en nuestra
              pagina
              <b>
                <a
                  style={{ color: "#ff9900" }}
                  href="https://globalclickmexico.com/"
                  target="_blank"
                >
                  www.globalclickmexico.com
                </a>
              </b>{" "}
              y a través del siguiente correo electrónico:
              <b>
                <a
                  style={{ color: "#ff9900" }}
                  href="mailto:servicio@globalclickmexico.com"
                  target="_top"
                >
                  servicio@globalclickmexico.com
                </a>
              </b>
              , a fin de que se le indiquen la forma y los medios por los que
              usted pueda limitar el uso y divulgación de sus datos personales.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                7. Medios para ejercer los Derechos de Acceso, Rectificación,
                Cancelación y Oposición (Derechos ARCO).
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              Usted tiene el derecho de acceder a sus datos personales que posea
              el “Responsable” y a los detalles del tratamiento de los mismos,
              así como a rectificarlos en caso de ser inexactos o incompletos;
              cancelarlos cuando considere que no se requieren para alguna de
              las finalidades señaladas en el presente aviso de privacidad,
              estén siendo utilizados para finalidades no consentidas o bien
              oponerse al tratamiento de los mismos para fines específicos.
              <br />
              <br />
              El Departamento de Datos Personales del “Responsable”, le brindará
              toda la información necesaria, para que usted proceda al ejercicio
              de sus derechos de Acceso, Rectificación, Cancelación y Oposición
              respecto de sus datos personales.
              <br />
              <br />
              El procedimiento que se ha implementado para el ejercicio de
              dichos derechos, inicia con la presentación de la solicitud
              respectiva, cuyo formato e información respecto de los plazos en
              los que será atendida, los podrá obtener en el Departamento de
              Datos Personales del “Responsable”, mismo que usted puede
              contactar como se indica en el punto 7 del presente aviso de
              privacidad.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                8. Procedimiento para que el titular (cliente), en su caso,
                revoque su consentimiento para el tratamiento de sus datos
                personales
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              En cualquier momento, usted puede revocar el consentimiento que,
              en su caso, haya otorgado al “Responsable” para el tratamiento de
              sus datos personales. Sin embargo, es importante que tenga en
              cuenta que no en todos los casos se podrá atender su solicitud o
              concluir el uso de forma inmediata, ya que, es posible que por
              alguna obligación legal se requiera seguir tratando sus datos
              personales. Asimismo, usted deberá considerar que para ciertos
              fines, la revocación de su consentimiento implicará que no se le
              pueda seguir prestando el servicio que solicitó, o la conclusión
              de su relación con el “Responsable”.
              <br />
              <br />
              Para revocar su consentimiento es necesario que contacte al
              Departamento de Protección de Datos Personales del “Responsable”,
              a fin de conocer el procedimiento para la revocación del
              consentimiento.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                9. Transferencias de datos personales dentro de México y al
                extranjero.{" "}
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              Sus datos personales pueden ser transferidos y tratados dentro y
              fuera del país, si fuere necesario para las finalidades
              mencionadas en los incisos A) y B) del punto 3 del presente aviso
              de privacidad. En este caso, se compartirían sus datos personales
              fuera del país, con la o las sociedades que se requiriera,
              pertenecientes a la Red global de Aulas Amigas. Por lo anterior,
              es importante que usted esté enterado de que sus datos se
              encuentran protegidos en virtud de que dichas sociedades, de ser
              necesario que se remitan sus datos, operarán bajo la misma
              Política de Protección de Datos Personales y cuentan además con
              una Política de Protección de Datos y Privacidad.
              <br />
              <br />
              El “Responsable” se compromete a no transferir sus datos
              personales a otros terceros, salvo que por los servicios
              profesionales que se le presten, debieran transferirse para dar
              cumplimiento a obligaciones legales ante las autoridades
              competentes, tales como autoridades tributarias o autoridades
              judiciales, federales o locales. Igualmente se podrá llevar a cabo
              transferencia de sus datos sin su consentimiento en los supuestos
              previstos en el artículo 37 de la Ley Federal de Protección de
              Datos Personales en Posesión de los Particulares, y en los casos
              mencionados en el párrafo que antecede y que están dentro de los
              supuestos del citado artículo 37, en el entendido de que las
              transferencias se realizarían en los términos que fija la citada
              Ley.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>10. Medidas de seguridad implementadas.</b>
            </h5>
            <p className="description-aviso-privacidad">
              Para la protección de sus datos personales hemos instrumentado
              medidas de seguridad de carácter administrativo, físico y técnico
              con el objeto de evitar pérdidas, mal uso o alteración de su
              información.
            </p>

            <h5 className="title-aviso-privacidad">
              <b>11. Modificaciones al aviso de privacidad.</b>
            </h5>
            <p className="description-aviso-privacidad">
              El presente aviso de privacidad puede sufrir modificaciones,
              cambios o actualizaciones derivadas de nuevos requerimientos
              legales, de las propias necesidades del “Responsable” por los
              servicios que ofrece, de las prácticas de privacidad o por otras
              causas, por lo que el “Responsable” se reserva el derecho de
              efectuar en cualquier momento las modificaciones o actualizaciones
              que sean necesarias al presente aviso de privacidad.
              <br />
              <br />
              El “Responsable” pondrá a disposición del cliente, la versión
              actualizada del aviso de privacidad, en la parte inferior de la
              página de internet que a continuación se indica:
              <b>
                <a
                  style={{ color: "#ff9900" }}
                  href="https://www.globalclickmexico.com"
                >
                  https://www.globalclickmexico.com
                </a>
              </b>
            </p>

            <h5 className="title-aviso-privacidad">
              <b>
                12. Derecho de promover los procedimientos de protección de
                derechos y de verificación que sustancia el Instituto.
              </b>
            </h5>
            <p className="description-aviso-privacidad">
              Cualquier queja o información adicional respecto al tratamiento de
              sus datos personales o duda en relación con la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares o
              con su Reglamento, podrá dirigirla al Instituto Nacional de
              Transparencia, Acceso a la Información y Protección de Datos
              Personales (INAI).
            </p>

            <br />
            <p className="text-center">
              <b> Fecha de última actualización: </b> 01 de Julio de 2023.
            </p>

            <br />

            <p className="text-center">
              <b>Atentamente: </b> <br />
              Dirección General de Global Click México
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AvisoPrivacidad;
