import { Notificacion } from '@components/general/Alertas/Alertas'
import { eliminarMision } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

const EliminarMision = ({setMisionEliminada, infoMision}) => {


    const {idMision} = infoMision;

    const handleEliminarMision = async() => {
        try {
            await eliminarMision(idMision);

            setMisionEliminada(true)
        Notificacion("success", "Misión eliminada con éxito.");

        } catch (error) {
            console.log(error);
        }
    }



    const handleCancelar = () => {
        Notificacion("info", "No se ha eliminado la misión");
    }

  return (
    <Container>
        <Row>
            <Col>
                <h2>¿Estás seguro de eliminar la misión?</h2>
            </Col>
        </Row>

        <Row className='justify-content-center mt-4'>
            <Col className='col-auto'>
                <div>
                    <Button variant='success' onClick={handleEliminarMision}>Eliminar</Button>
                </div>

            </Col>
            <Col className='col-auto'>
                <div>
                    <Button variant='danger' onClick={handleCancelar}>Cancelar</Button>
                </div>

            </Col>
        </Row>
    </Container>
  )
}

export default EliminarMision