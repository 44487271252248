const typesCodigosNotificacionUsuario = {
  certificacionMos: "mos_certification",
  nuevoCurso: "new_curso",
  nuevaClaseVirtual: "new_live_class",
  nuevoPostForoAyuda: "new_post_foro_ayuda",
  nuevoPostForoEmpresarial: "new_post_foro_empresarial",
  nuevaRespuestaTemaForoAyudaAutor: "new_post_response_autor",
  nuevaRespuestaTemaForoAyudaSeguidores: "new_post_response_followers",
  claseEnVivo: "class_now_live",
  registroSegundoUsuario: "second_user_registered",
};

export default typesCodigosNotificacionUsuario;
