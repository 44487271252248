import { Breadcrumb } from "react-bootstrap";
import { FaHouseUser } from "react-icons/fa";
import React from "react";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import { useEffect } from "react";
import { useState } from "react";

const DynamicBreadcrumb = ({ arrayBreadcrumbs }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(arrayBreadcrumbs);

  useEffect(() => {
    setBreadcrumbs(arrayBreadcrumbs);
  }, [arrayBreadcrumbs]);

  const backToHome = () => {
    sessionStorage.setItem("lastRoute", multiViewComponentsPaths.home);
  };

  return (
    <Breadcrumb className="mt-3">
      <Breadcrumb.Item
        href={`${dashboardRoutesPaths.dashboard}`}
        onClick={backToHome}
      >
        {/* <Breadcrumb.Item href='#' onClick={backToHome}> */}
        <FaHouseUser />
      </Breadcrumb.Item>

      {breadcrumbs.map((breadcrumb, idx) => (
        <Breadcrumb.Item
          onClick={breadcrumb.onClick !== null && breadcrumb.onClick}
          key={idx}
          active={idx === breadcrumbs.length - 1 && true}
        >
          {breadcrumb.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default DynamicBreadcrumb;
