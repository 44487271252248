const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const getURLCodigoQR = ({
  fechaFinalizacion,
  fechaUnix,
  nombre,
  nombreCurso,
  tiempoCurso,
  idPerfil,
  codigoCurso,
  curso,
}) => {
  const urlVistaPublica = `${window.location.origin}/certificado`;

  const fechaFinalizacionAux = new Date(fechaUnix * 1000);

  const anio = fechaFinalizacionAux.getFullYear();

  const mes = months[fechaFinalizacionAux.getMonth()];

  const dia = fechaFinalizacionAux.getDate();

  const fechaFinalizacionFormateada = dia + " de " + mes + " del " + anio;

  const jsonInfo = [
    nombre,
    nombreCurso,
    tiempoCurso,
    fechaFinalizacionFormateada,
    idPerfil,
    codigoCurso,
    curso,
  ];

  const urlQR = `${urlVistaPublica}?data=${window.btoa(
    JSON.stringify(jsonInfo)
  )}`;

  return urlQR;
};

export default getURLCodigoQR;
