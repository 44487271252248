import SubtitleForoAyuda from "@components/general/Titles/SubtitleForoAyuda";
import {
  faCommentAlt,
  faHeart,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Row, Tab } from "react-bootstrap";

const TabPanePublicacionesSeguidas = ({
  currentPaginationSections,
  respuestas,
  numeroLikes,
  comentarTema,
  alias,
}) => {
  return (
    <Tab.Pane eventKey="publicacionesSeguidas">
      {currentPaginationSections.length > 0 ? (
        <Container fluid>
          {currentPaginationSections.map((tema, key) => (
            <Row className="row-foro" key={key}>
              <Col style={{ height: "150px" }} className="mt-3">
                <Row>
                  <Col
                    className="pointer"
                    onClick={() => {
                      comentarTema(tema.idTema, tema.alias, tema.idPerfil );
                    }}
                  >
                    <SubtitleForoAyuda title={tema.titulo} />
                  </Col>
                </Row>

                <small>Creado por {tema.alias}</small>

                <Row className="justify-content-end pt-4">
                  <Col className="d-flex align-items-center">
                    <span className="mx-2">
                      <h6>
                        <FontAwesomeIcon
                          className="svg-foro"
                          icon={faCommentAlt}
                        />{" "}
                        {
                          respuestas.filter(
                            (respuesta) => respuesta.idTema === tema.idTema
                          ).length
                        }
                      </h6>
                    </span>
                    <span className="mx-2">
                      <h6>
                        <FontAwesomeIcon className="svg-foro" icon={faHeart} />{" "}
                        {numeroLikes(tema.idTema)}
                      </h6>
                    </span>
                  </Col>
                  <Col className="col-3 text-center">
                    <Button
                      name={tema.idTema}
                      value={tema.alias}
                      onClick={() => {
                        comentarTema(tema.idTema, tema.alias, tema.idPerfil);
                      }}
                      className="btn-sm btn-responsive"
                    >
                      Comentar <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Container>
      ) : (
        <Container className="temas">
          <Row>
            <Col className="text-center mt-5">
              <h4>No has comentado ningún tema</h4>
            </Col>
          </Row>
        </Container>
      )}
    </Tab.Pane>
  );
};

export default TabPanePublicacionesSeguidas;
