import React, { useContext, useRef, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "../Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import MisionesComponent from "./components/MisionesComponent";
import SalonFama from "./components/SalonFama";
import Recompensas from "./components/Recompensas";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const Misiones = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idPerfil } = usuarioLogeado;

	const [key, setKey] = useState("misiones");

	const breadcrumbItemsInitialState = [{ title: "Misiones Click+", onClick: () => {} }];

	const [runTour, setRunTour] = useState(false);

	const handleStartTour = async () => {
		try {
			setRunTour(!runTour);
		} catch (error) {
			console.log(error);
		}
	};

	const joyrideRef = useRef(null);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".contenedor-tabs",
			content:
				"Te damos la bienvenida a las misiones Click+, aquí tienes la oportunidad de poner en práctica todo lo que has aprendido con nosotros, completa las misiones para obtener créditos Click+ y asi canjearlos por recompensas.",
			disableBeacon: true,
		},
		{
			target: ".tabs",
			content:
				"Dentro de MISIONES encontrarás los proyectos del mes, donde puedes participar en 2 misiones cada 15 días, selecciona la que mejor se acople a tus habilidades y conocimientos y participa para obtener el primer lugar.",
			disableBeacon: true,
		},
		{
			target: ".tabs",
			content: "En el SALÓN DE LA FAMA encuentra a los ganadores de los proyectos del mes, ¡consigue el primer lugar y aparece dentro del salón de la fama!.",
			disableBeacon: true,
		},

		{
			target: ".tabs",
			content: "Encuentra grandiosas recompensas en el apartado de RECOMPENSAS para hacer uso de tus créditos Click+ los cuales ganarás participando o ganando en alguna de las misiones.",
			disableBeacon: true,
		},

		{
			target: ".terminos",
			content: "Conoce los términos y condiciones para participar en la dinámica.",
			disableBeacon: true,
		},

		{
			target: ".metricas",
			content: "Por último conoce las métricas de evaluación para saber de que manera se evaluan los trabajos que envíes.",
			disableBeacon: true,
		},

		{
			target: ".contenedor-tabs",
			content: "¡Práctica y mejora tus habilidades mientras participas para ganar premios increíbles.!",
			disableBeacon: true,
		},
	]);

	return (
		<Container fluid className="mt-5 contenedor-tabs">
			<Joyride
				locale={spanishOptionsJoyRide}
				ref={joyrideRef}
				steps={tourSteps}
				run={runTour}
				hideCloseButton
				showSkipButton
				continuous={true}
				disableOverlayClose={false}
				disableCloseOnEsc={false}
				disableScrolling={false}
				scrollToFirstStep={true}
				scrollOffset={100}
				callback={(data) => {
					if (data.status === "finished" || data.status === "skipped") {
						setRunTour(false);
					}
				}}
				styles={{
					options: {
						primaryColor: "#ff9900",
						textColor: "#00152A",
						width: 900,
						zIndex: 1000,
					},
				}}
			/>

			<Row className="d-flex align-items-center justify-content-between">
				<Col className="col-auto col-lg-6">
					<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
				</Col>
			</Row>

			<Row className="mt-3 justify-content-between">
				<Col lg={"6"}>
					<TitleDashboard title={"Misiones Click+"} />
				</Col>
				<Col className="col-md-2 mb-4 " style={{ height: "0px" }}>
					<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
						<span>
							<FontAwesomeIcon className="mx-5 pointer question" onClick={handleStartTour} style={{ fontSize: "1.5rem" }} icon={faQuestion} />
						</span>
					</OverlayTrigger>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col>
					<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 tabs">
						<Tab eventKey="misiones" title="Misiones">
							<MisionesComponent tab={key} />
						</Tab>
						<Tab eventKey="salonFama" title="Salón de la fama">
							<SalonFama tab={key} />
						</Tab>
						<Tab eventKey="recompensas" title="Recompensas">
							<Recompensas tab={key} infoUsuario={usuarioLogeado} />
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};

export default Misiones;
