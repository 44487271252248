import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getPreguntasDiagnostico = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: preguntas } = await httpRequest({
        url: `${endpointsPaths.examenDiagnostico}/${httpRequestsPaths.bancoPreguntasDiagnostico.getPreguntas}`,
        method: "GET",
      });

      resolve(preguntas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};

export const getPreguntasDiagnosticoByCodigo = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: preguntas } = await httpRequest({
        url: `${endpointsPaths.examenDiagnostico}/${httpRequestsPaths.bancoPreguntasDiagnostico.getPreguntasByCodigo}/${codigoCurso}`,
        method: "POST",
      });

      resolve(preguntas);
    } catch (error) {
      // console.log("Error al obtener las preguntas diagnóstico: ", error);

      reject(error);
    }
  });
};
