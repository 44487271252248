import React from "react";
import { Alert, Col } from "react-bootstrap";

const ListaRetroalimentacion = ({
  graficos,
  transformarDatos,
  relacionDatos,
  funcionesDax,
  funciones,
  analisisDatos,
  graficosExcel,
  macros,
  conocimientosGenerales,
  codigoCursoSeleccionado,
}) => {
  return (
    <div>
      {codigoCursoSeleccionado === "EX21" && (
        <>
          <h4 className="mt-3">Funciones: </h4>
          <span>Obtuviste {funciones.length} de 4 preguntas correctas</span>

          <Col className="col-12 mt-3">
            <ul>
              {funciones.length >= 0 && funciones.length <= 1 && (
                <>
                  <Alert variant="info">
                    Recomendamos que practiques formulas Básicas (sumar, Restar,
                    multiplicar, Etc…) para que comiences a familiarizarte con
                    el uso de Funciones
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>¿Qué son las funciones?</li>
                  <li>Insertar funciones</li>
                  <li>Tipos de funciones</li>
                  <li>Asignar nombre y rastreo</li>
                  <li>Operadores y valores</li>
                </>
              )}

              {funciones.length >= 2 && funciones.length <= 3 && (
                <>
                  <Alert variant="info">
                    Muy bien, conoces las funciones básicas, te recomendamos las
                    siguientes lecciones para perfeccionar tus conocimientos
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>Paréntesis de celda</li>
                  <li>Referencias de celda</li>
                  <li>Funciones matemáticas</li>
                </>
              )}

              {funciones.length === 4 && (
                <>
                  <Alert variant="info">
                    Excelente conocimiento y manejo de fórmulas, sigue mejorando
                    con nuestras avanzadas que encontrarás en el módulo "Excel
                    Aplicado"
                  </Alert>
                  <span>
                    Curso: <b>Excel Aplicado</b>
                  </span>
                  <li>Buscar V</li>
                  <li>Condicionar buscar V</li>
                  <li>Registro duplicado buscar V</li>
                  <li>Si y anidados</li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Análisis de datos: </h4>
          <span>Obtuviste {analisisDatos.length} de 4 preguntas correctas</span>

          <Col className="col-12 mt-3">
            <ul>
              {analisisDatos.length === 0 && (
                <>
                  <Alert variant="info">
                    Puedes comenzar a conocer el manejo de Tablas y bases de
                    Datos, en el curso De Excel Básico
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>Rango a tablas</li>
                  <li>Opciones de estilos de tabla</li>
                  <li>Nombre de tabla</li>
                </>
              )}

              {analisisDatos.length >= 1 && analisisDatos.length <= 2 && (
                <>
                  <Alert variant="info">
                    Muy bien, tienes la noción de manejo, intepretación y
                    limpeza de datos, perfecciona tus conocimientos con las
                    siguientes lecciones recomendadas
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>Referencias estructuradas</li>
                  <li>Botones de segmentación</li>
                  <li>Selección multiple, borrar filtro, agregar columna</li>
                  <li>Tamaño y posición</li>
                  <li>Insertar gráficos</li>
                </>
              )}

              {analisisDatos.length >= 3 && analisisDatos.length <= 4 && (
                <>
                  <Alert variant="info">
                    Excelente conocimiento e interpretación de Datos, estás
                    listo para comenzar a crear DashBoards con las isguientes
                    lecciones recomendadas
                  </Alert>
                  <span>
                    Curso: <b>Excel Intermedio</b>
                  </span>
                  <li>Dashboards preparación de datos 1</li>
                  <li>Dashboards preparación de datos 2</li>
                  <li>Top 5 vendedores</li>
                  <li>Gráficos en celda</li>
                  <li>Top 5 productos</li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Gráficos en Excel: </h4>
          <span>Obtuviste {graficosExcel.length} de 4 preguntas correctas</span>

          <Col className="col-12 mt-3">
            <ul>
              {graficosExcel.length >= 0 && graficosExcel <= 1 && (
                <>
                  <Alert variant="info">
                    Comienza a utilizar gráficos en el módulo "Excel Básico" con
                    las siguientes lecciones recomendadas
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>Insertar gráficos</li>
                  <li>Gráficos recomendados</li>
                  <li>Tamaño y posición</li>
                  <li>Cambiar fila/columna</li>
                </>
              )}

              {graficosExcel.length >= 2 && graficosExcel.length <= 3 && (
                <>
                  <Alert variant="info">
                    Muy bien, ya sabes crear gráficos, aprende a mejorar su
                    utilizad y aspecto con las siguientes lecciones recomendadas
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>Cambiar tipo de gráfico</li>
                  <li>Estilos y colores prediseñados</li>
                  <li>Personalizar gráficos</li>
                  <li>Colores de barra</li>
                  <li>Efectos de barra</li>
                </>
              )}

              {graficosExcel.length === 4 && (
                <>
                  <Alert variant="info">
                    Excelente Manejo e interpretación de gráficos, aprende a
                    darles un uso profesional creando DashBoards con las
                    Siguientes lecciones
                  </Alert>
                  <span>
                    Curso: <b>Excel Intermedio</b>
                  </span>
                  <li>Primer gráfico dinámico</li>
                  <li>Formato gráfico dinámico</li>
                  <li>Segundo gráfico dinámico</li>
                  <li>Insertar botones de segmentación</li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Macros: </h4>
          <span>Obtuviste {macros.length} de 4 preguntas correctas</span>

          <Col className="col-12 mt-3">
            <ul>
              {macros.length >= 0 && macros <= 1 && (
                <>
                  <Alert variant="info">
                    Comienza a conocer el uso y funciones de las Macros, dentro
                    del módulo "Excel Aplicado" con las siguientes lecciones
                  </Alert>
                  <span>
                    Curso: <b>Excel Aplicado</b>
                  </span>
                  <li>Las macros en Excel</li>
                  <li>La interfaz de Excel en BVA</li>
                  <li>Cómo se crea el código BVA</li>
                </>
              )}

              {macros.length >= 2 && macros.length <= 3 && (
                <>
                  <Alert variant="info">
                    Muy bien, no te quedes atorado solo "grabando macros",
                    Aprende a programar dentro de VBA con las lecciones que te
                    recomendamos
                  </Alert>
                  <span>
                    Curso: <b>Excel Aplicado</b>
                  </span>
                  <li>Inserte botones y controles</li>
                  <li>Aplicación de botones y controles 1</li>
                  <li>Aplicación de botones y controles 2</li>
                  <li>Aplicación de botones y controles 3</li>
                  <li>Aplicación de una macro</li>
                  <li>Diseñe una macro</li>
                </>
              )}

              {macros.length === 4 && (
                <>
                  <Alert variant="info">
                    Perfecto, conoces mucho sobre Macros, ve mas alla de tus
                    conocimientos, con las prácticas que tenemos para ti dentro
                    de "Excel Aplicado"
                  </Alert>
                  <span>
                    Curso: <b>Excel Aplicado</b>
                  </span>
                  <li>Proyecto 2: Formato credenciales</li>
                  <li>Proyecto 3: Macro para imprimir</li>
                  <li>
                    4. Señala la celda activa de forma automática: Fórmula y
                    formato
                  </li>
                  <li>
                    7. Crea hojas a partir de una lista: Programando la macro
                  </li>
                  <li>
                    9. Rellena celdas en blanco como todo un experto: Igual a...
                  </li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Conocimientos Generales: </h4>
          <span>
            Obtuviste {conocimientosGenerales.length} de 4 preguntas correctas
          </span>

          <Col className="col-12 mt-3">
            <ul>
              {conocimientosGenerales.length >= 0 &&
                conocimientosGenerales <= 1 && (
                  <>
                    <Alert variant="info">
                      Comienza a familiarizarte con excel, iniciando el curso
                      "Excel Básico"
                    </Alert>
                    <span>
                      Curso: <b>Excel Básico</b>
                    </span>
                    <li>Primero contacto con Excel</li>
                    <li>Conociendo la "Cinta de opciones"</li>
                  </>
                )}

              {conocimientosGenerales.length >= 2 &&
                conocimientosGenerales.length <= 3 && (
                  <>
                    <Alert variant="info">
                      Muy bien, conoces algunas herramientas básicas de excel,
                      explora nuevas herramientas vistando el curso "Excel
                      básico"
                    </Alert>
                    <span>
                      Curso: <b>Excel Básico</b>
                    </span>
                    <li>Plantillas</li>
                    <li>Fornato de fuente</li>
                    <li>Estilos</li>
                    <li>Bordes, relleno y color de fuente</li>
                    <li>Alineación y orientación</li>
                    <li>Combinar, centrar y ajustar texto</li>
                  </>
                )}

              {conocimientosGenerales.length === 4 && (
                <>
                  <Alert variant="info">
                    Estás listo para comenzar el módulo de "excel Intermedio",
                    recomendamos dar un vistaso rápido al curso "Excel básico"
                    para repasar tus conocimientos
                  </Alert>
                  <span>
                    Curso: <b>Excel Básico</b>
                  </span>
                  <li>Click derecho</li>
                  <li>Orden personalizado</li>
                  <li>Orden por niveles</li>
                  <li>Ordenar niveles y listas personalizadas</li>
                  <span>
                    Curso: <b>Excel Intermedio</b>
                  </span>
                  <li>Partes de una tabla dinámica</li>
                  <li>Dar formato a una tabla dinámica</li>
                  <li>Filtrar una tabla dinámica</li>
                </>
              )}
            </ul>
          </Col>
        </>
      )}

      {codigoCursoSeleccionado === "PB21" && (
        <>
          <h4 className="mt-3">Gráficos: </h4>
          <span>Obtuviste {graficos.length} de 5 preguntas correctas</span>

          <Col className="col-12 mt-3">
            <ul>
              {graficos.length >= 0 && graficos.length <= 2 && (
                <>
                  <Alert variant="info">
                    Comienza a ordenar tus datos y empieza a crear gráfcos con
                    el curso "Power BI Básico" Con las siguientes lecciones
                    recomendadas
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Creando gráficos</li>
                  <li>Creando gráficos 2</li>
                </>
              )}

              {graficos.length >= 3 && graficos.length <= 4 && (
                <>
                  <Alert variant="info">
                    Bien, ya conoces algunas de las visualizaciones de Power BI,
                    explora mas visualizaciones dentro del módulo "Power BI
                    básico"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Controles de gráficos</li>
                  <li>Segmentación de datos</li>
                </>
              )}

              {graficos.length === 5 && (
                <>
                  <Alert variant="info">
                    Muy bien, tienes un muy buen manejo de datos y gráficos,
                    aprende a crear un DashBoard con las siguientes lecciones
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Formato de gráfico</li>
                  <li>Formato de gráfico 2</li>
                  <li>Formato de datos</li>
                  <li>Efecto 3D</li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Transformar datos: </h4>
          <span>
            Obtuviste {transformarDatos.length} de 5 respuestas correctas
          </span>

          <Col className="col-12 mt-3">
            <ul>
              {transformarDatos.length >= 0 && transformarDatos.length <= 2 && (
                <>
                  <Alert variant="info">
                    Puedes iniciar a conocer los conceptos básicos sobre
                    transformación y limpieza da datos dentro del módulo "Power
                    BI Básico"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Importar datos</li>
                  <li>Editos de consultas</li>
                </>
              )}

              {transformarDatos.length >= 3 && transformarDatos.length <= 4 && (
                <>
                  <Alert variant="info">
                    Muy bien, ya conoces algo sobre la transformación y limpieza
                    da datos, perfecciona tus conocimientos dentro del módulo
                    "Power BI Básico"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Columnas calculadas</li>
                  <li>Editar nombre de la columna</li>
                  <li>Campos calculados</li>
                </>
              )}

              {transformarDatos.length === 5 && (
                <>
                  <Alert variant="info">
                    Perfecto, sabes como tener una base de datos limpia y que se
                    pueda trabajar, recomendamos dar el siguiente paso a
                    funciones DAX dentro de "Power BI Avanzado"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Avanzado</b>
                  </span>
                  <li>Creando medidas con expresiones Dax</li>
                  <li>Creando medidas con atajos del teclado</li>
                  <li>Expresión simple diferencia</li>
                  <li>Tabla medidas Dax</li>
                  <li>Organizar por carpetas</li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Relación de datos: </h4>
          <span>
            Obtuviste {relacionDatos.length} de 4 respuestas correctas
          </span>

          <Col className="col-12 mt-3">
            <ul>
              {relacionDatos.length >= 0 && relacionDatos.length <= 1 && (
                <>
                  <Alert variant="info">
                    Aprende a generar Relaciones de Datos desde cero en el
                    módulo "Power BI Básico"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Bloques de creación básicos</li>
                  <li>Importar datos</li>
                </>
              )}

              {relacionDatos.length >= 2 && relacionDatos.length <= 3 && (
                <>
                  <Alert variant="info">
                    Recomendamos afinar tus conocimientos en cuanto a Relación
                    de Datos de Power Bi, dentro del módulo "Power BI Básico"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Básico</b>
                  </span>
                  <li>Relación de datos</li>
                  <li>Editar relación</li>
                  <li>Administrar relaciones</li>
                  <li>Errores al relacionar</li>
                </>
              )}

              {relacionDatos.length === 4 && (
                <>
                  <Alert variant="info">
                    Tienes una excelente forma de relacionar tus datos en Power
                    BI, Aprende mas al respecto y funciones DAX dentro del
                    módulo "Power BI Avanzado"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Avanzado</b>
                  </span>
                  <li>Relación en modelos de datos</li>
                  <li>Modelado tipo embudo</li>
                  <li>Ocultando conceptos repetidos</li>
                  <li>Medidas implicitas</li>
                </>
              )}
            </ul>
          </Col>

          <h4 className="mt-3">Funciones DAX: </h4>
          <span>Obtuviste {funcionesDax.length} de 5 respuestas correctas</span>

          <Col className="col-12 mt-3">
            <ul>
              {funcionesDax.length >= 0 && funcionesDax.length <= 2 && (
                <>
                  <Alert variant="info">
                    Comienza a Trabajar YA con funciones DAX dentro del módulo
                    "Power BI Avanzado"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Avanzado</b>
                  </span>
                  <li>Creando medidas con expresiones Dax</li>
                  <li>Creando medidas con atajos del teclado</li>
                  <li>Expresión simple diferencia</li>
                </>
              )}

              {funcionesDax.length >= 3 && funcionesDax.length <= 4 && (
                <>
                  <Alert variant="info">
                    Muy Bien, tienes un conocimiento basico de Funciones DAX,
                    continua aprendiendo mas Funciones dentro del módulo "Power
                    BI Avanzado"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Avanzado</b>
                  </span>
                  <li>Conociendo IF</li>
                  <li>Condiciones múltiples</li>
                  <li>Función OR</li>
                  <li>Implementando IN</li>
                  <li>Implementando NOT</li>
                </>
              )}

              {funcionesDax.length === 5 && (
                <>
                  <Alert variant="info">
                    Perfecto, ya tienes conocimiento de algunas de las funciones
                    DAX que Power BI ofrece, aprende a utilizarlas de forma
                    profesional dentro del módulo "Power BI avanzado"
                  </Alert>
                  <span>
                    Curso: <b>Power Bi Avanzado</b>
                  </span>
                  <li>Creando resúmenes con Dax</li>
                  <li>Gráfico de líneas</li>
                  <li>Porcentaje de devoluciones</li>
                  <li>Promedio</li>
                </>
              )}
            </ul>
          </Col>
        </>
      )}
    </div>
  );
};

export default ListaRetroalimentacion;
