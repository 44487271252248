import { DataTablesRecipient } from "@components/general/DataTablesRecipient/DataTablesRecipient";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import columnasRegistroInvitado from "./columnasRegistroInvitado";
import { validateExistenciaCorreo } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import AgregarInvitado from "../../Modales/AgregarInvitado/AgregarInvitado";

const TableRegistroInvitados = ({
  setInvitadoAgregado,
  asistentesCapacitacion,
}) => {
  const [dataTableColumns, setDataTableColumns] = useState([]);

  useEffect(() => {
    if (asistentesCapacitacion.length > 0) {
      const handleRegistrarInvitado = async ({ currentTarget }) => {
        try {
          const correo = currentTarget.id.split("-")[1];

          const datosAsistente = asistentesCapacitacion.filter(
            (asistente) =>
              asistente.correoEmpresa === correo ||
              asistente.correoPersonal === correo
          )[0];

          try {
            await validateExistenciaCorreo(correo);
          } catch (error) {
            // correo usado
            return Notificacion(
              "error",
              "El usuario/correo ya se encuentra registrado"
            );
          }

          await ModalSweetAlert({
            title: "Agregar usuario invitado",
            icon: "info",
            html: (
              <AgregarInvitado
                datosAsistente={datosAsistente}
                setInvitadoAgregado={setInvitadoAgregado}
              />
            ),
            showConfirmButton: false,
            showCloseButton: true,
          });
        } catch (error) {
          console.log(
            "Error al ver los datos del estudiante seleccionado: ",
            error
          );
        }
      };

      setDataTableColumns([
        ...columnasRegistroInvitado,
        {
          name: "Acciones",
          cell: (row) => (
            <>
              <span
                id={`add-${row.correoEmpresa || row.correoPersonal}`}
                className="me-1 pointer"
                onClick={handleRegistrarInvitado}
              >
                <FontAwesomeIcon icon={faUserPlus} className="text-success" />
              </span>
            </>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]);
    }
  }, [asistentesCapacitacion]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <DataTablesRecipient
            columns={dataTableColumns}
            data={asistentesCapacitacion}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TableRegistroInvitados;
