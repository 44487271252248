import { Button, Col, Container, Row } from "react-bootstrap";

import React from "react";
import { deactivatePregunta } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import { Notificacion } from "@components/general/Alertas/Alertas";

export const EliminarPregunta = ({ setPreguntaEliminada, infoPregunta }) => {
  const { idPregunta } = infoPregunta;

  const handleDeletePregunta = async (e) => {
    try {
      const { message } = await deactivatePregunta(idPregunta);
      Notificacion("success", message);
      setPreguntaEliminada(true);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelarDeletePregunta = () => {
    Notificacion("info", "No se desactivó el consejo");
  };

  return (
    <>
      <Container fluid className="text-center">
        <h3>¿Estás seguro de desactivar la pregunta seleccionada?</h3>

        <Row className="pt-3">
          <Col>
            <Button variant="success" onClick={handleDeletePregunta}>
              Sí, desactivar
            </Button>
          </Col>

          <Col>
            <Button variant="danger" onClick={cancelarDeletePregunta}>
              No, cancelar
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
