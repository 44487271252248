import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const PanelAdministracionDashboardTitle = ({
  title,
  showBackToPanel,
  resetPanel,
}) => {
  return (
    <>
      {title ? (
        <h3>
          {showBackToPanel && (
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="pointer back-to-admin-panel me-3"
              onClick={resetPanel}
            />
          )}

          {title}
        </h3>
      ) : (
        <h3>Sin titulo</h3>
      )}

      <div
        style={{ width: "65px", height: "3px", backgroundColor: "#ffc107" }}
      ></div>
    </>
  );
};

export default PanelAdministracionDashboardTitle;
