import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";

export const sendPurchaseEvent = (eventData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await httpRequest({
                url: `${ endpointsPaths.pixelFacebook }/${ httpRequestsPaths.pixelFacebook.sendPurchaseEvent }`,
                method: 'POST',
                body: JSON.stringify(eventData)
            });

            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};