import { PDFDocument, TextAlignment } from "pdf-lib";

//*Dentro del parametro "dataCodigoQR" se sustituirá por un parametro desestructurado para llenar el form con la info real.

const getFilledPdfBytes = (pdfTemplateBytes, dataCodigoQR) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pdfDoc = await PDFDocument.load(pdfTemplateBytes);

      const pdfForm = pdfDoc.getForm();

      const { nombre, nombreCurso, tiempoCurso, fechaFinalizacion, dataQR } =
        dataCodigoQR;

      pdfForm.getFields().forEach(async (field) => {
        const name = field.getName();

        switch (name) {
          case "nombre":
          case "nombreCurso":
          case "fechaEmision":
          case "duracionCurso":
            pdfForm.getTextField(name).setAlignment(TextAlignment.Center);

            break;

          default:
            break;
        }

        switch (name) {
          case "nombre":
            pdfForm.getTextField(name).setText(nombre);
            // pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;

          case "nombreCurso":
            pdfForm.getTextField(name).setText(nombreCurso);
            // pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;

          case "fechaEmision":
            pdfForm
              .getTextField(name)
              .setText(`Emitido el día ${fechaFinalizacion}`);

            break;

          case "duracionCurso":
            pdfForm
              .getTextField(name)
              .setText(`Con una duración de ${tiempoCurso} ${nombreCurso === "Lectura Veloz" ? "semanas" : "horas"}`);
            // pdfForm.getTextField(name).setAlignment(TextAlignment.Center);
            break;
          case "codigoQR":
            const codigoQr = await pdfDoc.embedPng(dataQR);

            pdfForm.getButton(name).setImage(codigoQr);
            break;

          default:
            break;
        }

        // pdfForm.getTextField(name).updateAppearances(helveticaFont);
      });

      // pdfForm.flatten();

      resolve(await pdfDoc.save());
    } catch (error) {
      reject(error);
    }
  });
};

export default getFilledPdfBytes;
