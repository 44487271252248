import { NotificacionExtendida } from '@components/general/Alertas/Alertas'
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp'
import { otorgarParticipaciones, setGanador } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones'
import regExpStrings from '@helpers/regExpStrings/regExpStrings'
import React from 'react'
import { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

const ConfirmarGanador = ({handleRegresarParticipantes, idMision, infoUsuario, setGanadorSeleccionado, infoMision, participaciones}) => {

    console.log(participaciones)

    const {titulo, descripcion, fechaExpiracion, fechaRegistro, iconoProyecto} = infoMision;

    const {nombreCompleto, apellidos, correo, idPerfil, avatarPerfil} = infoUsuario;
    
    const [estado, setEstado] = useState("");

    //Funcion para guardar la informacion del usuario, guardarla en base de datos y enviar el correo de que fue seleccionado como ganador.


    const seleccionarGanador = async(idMision) =>{
        try {

          console.log(infoUsuario)

            const validacionEmail = regExpStrings.regexEmail(correo);

          if(validacionEmail) {
            const ganadorSeleccionado = await setGanador({
                nombreGanador: nombreCompleto + " " + apellidos,
                correo,
                avatarPerfil,
                tituloMision: titulo,
                descripcionMision: descripcion,
                fechaExpiracion,
                fechaRegistro: getUnixTimestamp(),
                iconoProyecto,
                idPerfil,
                idMision,
            })

            console.log(ganadorSeleccionado)

            const participantes = participaciones.filter(participacion => participacion.idPerfil !== idPerfil);

            console.log(participantes)

            participantes.map(async(participacion) => {
              await otorgarParticipaciones(participacion.idPerfil);
            })
            setGanadorSeleccionado(true);
            handleRegresarParticipantes(idMision)
          }else{
            NotificacionExtendida("info", "Correo electrónico inválido, revisa tu información");
          }


        } catch (error) {
            console.log(error)
        }
    }

  return (
    <Container>
        <Row className='mt-4'>
            <Col>
                <h3>¿Estás seguro de elegir a <b>{nombreCompleto + " " + apellidos}</b> como ganador de la competencia?</h3>
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col>
                <Button variant='success' className='mx-2' onClick={()=> seleccionarGanador(idMision)}>Si, quiero hacerlo ganador</Button>
                <Button id={idMision} onClick={() => handleRegresarParticipantes(idMision)} variant='danger' className='mx-2'>Cancelar</Button>
            </Col>
        </Row>
    </Container>
  )
}

export default ConfirmarGanador