import { Button, Col, Container, Row } from "react-bootstrap";

import { Notificacion } from "@components/general/Alertas/Alertas";
import React from "react";
import { deactivateCurso } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";

export const EliminarCurso = ({ curso, setCursoEliminado }) => {
  const handleEliminarCurso = async () => {
    try {
      await deactivateCurso(curso.codigoCurso);

      setCursoEliminado(true);

      Notificacion("success", "El curso se eliminó con éxito");
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelar = () => {
    Notificacion("info", "El curso no fue eliminado");

    // setTransmisionEliminada(true);
  };

  return (
    <Container fluid className="text-center">
      <h3>¿Estás seguro que deseas eliminar este curso?</h3>

      <Row className="pt-3">
        <Col>
          <Button variant="success" onClick={handleEliminarCurso}>
            Sí, eliminar
          </Button>
        </Col>

        <Col>
          <Button variant="danger" onClick={handleCancelar}>
            No, cancelar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
