import { ModalSweetAlert } from '@components/general/Alertas/Alertas';
import { getCreditos, getParticipacionesUsuario, getRecompensas, obtenerVictoriasParticipaciones } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Container, Image, Row } from 'react-bootstrap'
import { FaEdit, FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import AgregarRecompensa from '../modales/AgregarRecompensa';
import numberCommaSeparator from '@helpers/formateoPrecios/numberCommaSeparator';
import EditarRecompensa from '../modales/EditarRecompensa';
import EliminarRecompensa from '../modales/EliminarRecompensa';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import getDateFromUnix from '@helpers/dates/getDateFromUnix/getDateFromUnix';
import SolicitarRecompensa from '../modales/SolicitarRecompensa';
import { useTheme } from '@emotion/react';
import { AiOutlineClose } from "react-icons/ai";


const Recompensas = ({infoUsuario, tab}) => {

  const {avatarPerfil, rol, idPerfil} = infoUsuario;

  const [tarjetasRegalo, setTarjetasRegalo] = useState([]);

  const [tarjetaRegaloAgregada, setTarjetaRegaloAgregada] = useState(false);

  const [tarjetaRegaloEditada, setTarjetaRegaloEditada] = useState(false);

  const [tarjetaRegaloEliminada, setTarjetaRegaloEliminada] = useState(false);

  const [participacion, setParticipaciones] = useState(0);

  const [victorias, setVictorias] = useState(0);

  const [proyectos, setProyectos] = useState([]);

  const [creditos, setCreditos] = useState(0);

  const [recompensaCanjeada, setRecompensaCanjeada] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
};

const handleClose = (value) => {
    setOpen(false);
};


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <React.Fragment>
            <div className='d-grid gap-2 d-lg-block gap-lg-0'>
                <Button variant='outline-success' onClick={handleClickOpen}>Agregar recompensa <FaPlus /></Button>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogActions>
                    <Button className='btn-light' autoFocus onClick={handleClose}>
                        <AiOutlineClose />
                    </Button>
                </DialogActions>
                <DialogTitle id="responsive-dialog-title">
                    {"Agregar una nueva misión"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      <AgregarRecompensa setTarjetaRegaloAgregada={setTarjetaRegaloAgregada} />
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}

  //Modales
  // const agregarRecompensa = () => {
  //   ModalSweetAlert({
  //     title: "",
  //     html: (
  //         <AgregarRecompensa setTarjetaRegaloAgregada={setTarjetaRegaloAgregada} />
  //     ),
  //     showConfirmButton: false,
  //     showCloseButton: true,
  //     width: "700px",
  //   });
  // }

  const editarRecompensa = ({currentTarget}) => {
    const index = currentTarget.id;
    ModalSweetAlert({
      title: "",
      html: (
          <EditarRecompensa setTarjetaRegaloEditada={setTarjetaRegaloEditada} infoRecompensa ={tarjetasRegalo[index]} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  }

  const eliminarRecompensa = ({currentTarget}) => {
    const index = currentTarget.id;
    ModalSweetAlert({
      title: "",
      html: (
          <EliminarRecompensa setTarjetaRegaloEliminada={setTarjetaRegaloEliminada} idRecompensa={tarjetasRegalo[index].idTarjeta}/>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  }

  const solicitarRecompensa = ({currentTarget}) => {
    const index = currentTarget.id;
    ModalSweetAlert({
      title: "",
      html: (
          <SolicitarRecompensa setRecompensaCanjeada={setRecompensaCanjeada} creditosDisponibles={creditos} infoUsuario={infoUsuario} tarjetaRegalo={tarjetasRegalo[index]}/>
        ),
      showConfirmButton: false,
      showCloseButton: true,
      width: "700px",
    });
  }


  useEffect(() => {
    const obtenerRecompensas = async() => {
      try {
        const {data} = await getRecompensas();
        if(Array.isArray(data)){
          setTarjetasRegalo(data)
        }else{
          setTarjetasRegalo([data])
        }
      } catch (error) {
        console.log(error)
        setTarjetasRegalo([])

      }
    }
    obtenerRecompensas()

    const participacionesVictorias = async() =>{
      try {
        const {data} = await obtenerVictoriasParticipaciones(idPerfil);
        setParticipaciones(data.numero_participaciones);
        setVictorias(data.numero_victorias);
      } catch (error) {
        console.log(error)
      }
    }
    participacionesVictorias();

    const participacionesDelUsuario = async() =>{
      try {
        const {data}= await getParticipacionesUsuario(idPerfil);
        if(Array.isArray(data)){
          setProyectos(data);
        }else{
          setProyectos([data]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    participacionesDelUsuario();

    const obtenerCreditos = async() =>{
      try {
          const {data} = await getCreditos(idPerfil);
          setCreditos(data.cantidadCreditos);
      } catch (error) {
          console.log(error);
      }
  }

  obtenerCreditos()
  }, [])


  useEffect(() => {
    if(tarjetaRegaloAgregada || tarjetaRegaloEditada || tarjetaRegaloEliminada){
      const obtenerRecompensas = async() => {
        try {
          const {data} = await getRecompensas();
          if(Array.isArray(data)){
            setTarjetasRegalo(data)
          }else{
            setTarjetasRegalo([data])
          }

          setTarjetaRegaloAgregada(false);
          setTarjetaRegaloEditada(false);
          setTarjetaRegaloEliminada(false);
        } catch (error) {
          console.log(error)
        setTarjetasRegalo([])

        }
      }
      obtenerRecompensas()
    }
  }, [tarjetaRegaloAgregada, tarjetaRegaloEditada, tarjetaRegaloEliminada])

  useEffect(() => {
    if(recompensaCanjeada){
      const obtenerCreditos = async() =>{
        try {
            const {data} = await getCreditos(idPerfil);
            setCreditos(data.cantidadCreditos);
            setRecompensaCanjeada(false)
        } catch (error) {
            console.log(error);
        }
    }

    obtenerCreditos()
    }
  }, [recompensaCanjeada])
  
  useEffect(() => {
    const obtenerRecompensas = async() => {
      try {
        const {data} = await getRecompensas();
        if(Array.isArray(data)){
          setTarjetasRegalo(data)
        }else{
          setTarjetasRegalo([data])
        }
      } catch (error) {
        console.log(error)
        setTarjetasRegalo([])
      }
    }
    obtenerRecompensas()

    const participacionesVictorias = async() =>{
      try {
        const {data} = await obtenerVictoriasParticipaciones(idPerfil);
        setParticipaciones(data.numero_participaciones);
        setVictorias(data.numero_victorias);
      } catch (error) {
        console.log(error)
      }
    }
    participacionesVictorias();

    const participacionesDelUsuario = async() =>{
      try {
        const {data}= await getParticipacionesUsuario(idPerfil);
        if(Array.isArray(data)){
          setProyectos(data);
        }else{
          setProyectos([data]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    participacionesDelUsuario();

    const obtenerCreditos = async() =>{
      try {
          const {data} = await getCreditos(idPerfil);
          setCreditos(data.cantidadCreditos);
      } catch (error) {
          console.log(error);
      }
  }

  obtenerCreditos()
  }, [tab])
  
  

  const columnas =  [
    {
      header: "#",
      size: 50,
      Cell: ({ cell }) => (
        <div className="text-center">
          <span>{cell.row.index + 1}</span>
        </div>
      ),
    },
    {
      accessorKey: "titulo",
      header: "Título",
    },
    {
      header: "Fecha de publicación",
      Cell: ({ cell }) => (
        <div>
          <span>{getDateFromUnix(cell.row.original.fechaRegistro)}</span>
        </div>
      ),
    },
  
  ];
  

  return (
    <Container fluid className='text-start mb-5 tab-recompensas'>
        <Row className='mt-5'>
            <Col className='col-md-4 text-center'>
                <h3>Mi perfil</h3>

                <Image width="240" height="240" className='rounded-circle mt-3 avatar-misiones' src={(avatarPerfil === null || avatarPerfil === "") ? require(`@assets/images/avatar.png`): avatarPerfil} />

                <Row className='mt-5 justify-content-center'>
                  <Col className='col-auto'>
                    <span> <Image width="50" src={require(`@assets/images/medalla-ganador.png`)} /> <b>{victorias}</b></span> 
                  </Col>
                  <Col className='col-auto'>
                    <span> <Image width="50" src={require(`@assets/images/medalla-participante.png`)} /> <b>{participacion}</b></span>
                  </Col>
                  <Col lg={"12"} className='text-center mt-4'>
            <p><Image className='mx-2' fluid width="50" src={require(`@assets/images/creditos.png`)} /> <b>Créditos Click+:</b> <span className='creditos'><b>${numberCommaSeparator(creditos)}</b></span></p>
          </Col>
                </Row>
            </Col>
            <Col className='col-12 col-md-8'>
                <Row className='justify-content-between'>
                  <Col className='col-auto'>
                    <h3>Premios disponibles</h3>
                  </Col>
                  {rol === "administrador" &&(
                      <Col className='col-12 col-lg-auto'>
                         <>

                            <SimpleDialog
                                open={open}
                                onClose={handleClose}
                            /></>
                      </Col>
                  )}
                </Row>

          <Row className='contenedor-recompensas justify-content-center justify-content-lg-start'>
            {tarjetasRegalo.length > 0 ? (
              tarjetasRegalo.map((tarjetaRegalo, idx) => (
                <Col key={idx} className='col-12 col-sm-8 col-md-6 col-lg-4 mt-4 text-center'>
                  <Image className='rounded-4 mb-3' src={tarjetaRegalo.imagenTarjeta} fluid />
                  <p className="descripcion-recompensas">
                    <b>{tarjetaRegalo.descripcion}</b>
                  </p>
                  <p className='d-flex align-items-center justify-content-center'><Image className='mx-1' src={require(`@assets/images/creditos.png`)} width="25" /><b>${numberCommaSeparator(tarjetaRegalo.valor)}</b></p>
                  <div className='d-grid gap-2'>
                    <Button id={idx} onClick={solicitarRecompensa}>Canjear premio</Button>
                  </div>

                  <Row className='justify-content-center mt-3'>
                    <Col className='col-auto text-center'>
                      <FaEdit id={idx} onClick={editarRecompensa} className='pointer'/>
                    </Col>

                    <Col className='col-auto text-center'>
                      <FaTrash id={idx} onClick={eliminarRecompensa} className='pointer'/>
                    </Col>
                  </Row>
                </Col>
              ))
            ) : (
              <Col className='mt-4 text-center'>
                <h2>No hay recompensas disponibles por el momento</h2>
              </Col>
                  )}
          </Row>
        </Col>
      </Row>  

      <Row className='mt-5'>
        <Col>
        <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><b>Misiones en las que participaste</b></Accordion.Header>
                  <Accordion.Body>
                    <MaterialReactTable
                      localization={MRT_Localization_ES}
                      columns={columnas}
                      data={proyectos}            
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default Recompensas