import "@assets/css/components/Perfil/style.css";

import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TabMiPerfil from "./TabsItems/TabMiPerfil";
import TabNotificaciones from "./TabsItems/TabNotificaciones";
import TitleDashboard from "../Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import TabNipSeguridad from "./TabsItems/TabNipSeguridad/TabNipSeguridad";

export const Perfil = () => {
	const [activeTab, setActiveTab] = useState("profile");

	const [editable, setEditable] = useState(false);

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { usuarioTitular } = usuarioLogeado;

	const breadcrumbItemsInitialState = [{ title: "Configuración", onClick: () => {} }];

	const handleChangeTab = (tab) => {
		try {
			setActiveTab(tab);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		// console.log(usuarioTitular);

		const verNotificaciones = sessionStorage.getItem("verNotificaciones");

		if (verNotificaciones) {
			setActiveTab("notifications");

			sessionStorage.removeItem("verNotificaciones");
		}
	}, []);

	return (
		<Container fluid className="p-0 mb-3">
			<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />

			<Row className="mb-3">
				<Col sm={12} md>
					<TitleDashboard title="Configuración" />
				</Col>
			</Row>

			{/* ------------- Tabs -------------------------- */}
			<Row>
				<Col>
					{/* <Container className="margen"> */}
					<Container fluid>
						<Row>
							<Col>
								{usuarioTitular ? (
									<Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 test" onSelect={handleChangeTab} activeKey={activeTab}>
										<Tab eventKey="profile" title="Mi perfil" className="">
											<TabMiPerfil editable={editable} setEditable={setEditable} />
										</Tab>

										{/* ------------------------------- Cambio nombre usuario -------------------------*/}
										{/* {
											<Tab eventKey="nombreUsuario" title="Cambio de nombre de usuario">
												<TabCambioNombreUsuario />
											</Tab>
										} */}

										{/* ------------------------------- Cambio contraseña ----------------------------- */}
										{/* {
											<Tab eventKey="password" title="Cambio de contraseña">
												<TabCambioContrasenia />
											</Tab>
										} */}

										{/* ------------------------------- NIP seguridad ----------------------------- */}
										{usuarioLogeado.rol === typesTiposUsuario.estudiante && (
											<Tab eventKey="nip" title="NIP de Seguridad">
												<TabNipSeguridad />
											</Tab>
										)}
									</Tabs>
								) : (
									<Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 test" onSelect={handleChangeTab} activeKey={activeTab}>
										<Tab eventKey="profile" title="Mi perfil" className="">
											<TabMiPerfil editable={editable} setEditable={setEditable} />
										</Tab>

										{/* ------------------------------- Notificaciones ----------------------------- */}
										{usuarioLogeado.rol !== typesTiposUsuario.invitado && usuarioLogeado.rol !== typesTiposUsuario.lecturaVeloz && (
											<Tab eventKey="notifications" title="Mis notificaciones">
												<TabNotificaciones />
											</Tab>
										)}
									</Tabs>
								)}
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
		</Container>
	);
};
