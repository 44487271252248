import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import { getGrupoById } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { getLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import moment from "moment";
import { useEffect } from "react";

export const VerAdministrador = ({ administrador }) => {
  const [grupoAsesor, setGrupoAsesor] = useState([]);

  const [datosLicencia, setDatosLicencia] = useState([]);

  // const [cursosLicencia, setCursosLicencia] = useState(JSON.parse(administrador.cursos));

  const [tiempoVigencia, setTiempoVigencia] = useState({
    diasVigencia: "",
    mesesVigencia: "",
  });

  // const { diasVigencia, mesesVigencia } = tiempoVigencia;

  useEffect(() => {
    const consultarGrupoAsesor = async () => {
      try {
        const { data: grupoAsesor } = await getGrupoById(administrador.idGrupo);

        setGrupoAsesor(grupoAsesor);
      } catch (error) {
        console.log(
          "Error al consultar el grupo del asesor seleccionado: ",
          error
        );
      }
    };

    const consultarDatosLicencia = async () => {
      try {
        const { data: licencia } = await getLicencia(administrador.idLicencia);

        setDatosLicencia(licencia);
      } catch (error) {
        console.log("Error al consultar los datos de la licencia: ", error);
      }
    };

    const calcularTiempoVigencia = () => {
      const registrationDateMoment = moment
        .unix(administrador.fechaRegistro)
        .utcOffset("-06:00");

      const expirationDateMoment = registrationDateMoment.add(
        datosLicencia.tiempoVigencia,
        "months"
      );

      const currentMoment = moment();

      const monthsLeft = expirationDateMoment.diff(currentMoment, "months");

      const daysLeft = expirationDateMoment.diff(currentMoment, "days");

      setTiempoVigencia({
        diasVigencia: daysLeft,
        mesesVigencia: monthsLeft,
      });
    };

    calcularTiempoVigencia();

    consultarGrupoAsesor();

    consultarDatosLicencia();
  }, []);

  return (
    <Container fluid className="text-start">
      <Row>
        <Col>
          <h3>Datos personales</h3>
          <hr />

          <Container fluid>
            <Row>
              <Col>
                <p>
                  <b>Nombre:</b> {administrador.nombreCompleto}{" "}
                  {administrador.apellidos}
                </p>

                <p>
                  <b>Correo electrónico:</b> {administrador.correo}
                </p>

                <p>
                  <b>CURP:</b> {administrador.curp}
                </p>
              </Col>

              <Col>
                <p>
                  <b>Folio de contrato:</b> {administrador.folioContrato}
                </p>

                <p>
                  <b>Nombre de usuario:</b> {administrador.nombreUsuario}
                </p>

                <p>
                  <b>Profesión:</b>{" "}
                  {administrador.profesion !== ""
                    ? administrador.profesion
                    : "No especificado"}
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      {/* <Row>
				<Col>
					<h3>Datos de la Licencia</h3>
					<hr />

					<Container fluid>
						<Row>
							<Col>
								<p>
									<b>Fecha de registro: </b> {getDateFromUnix(administrador.fechaRegistro)}
								</p>

								<p>
									<b>Fecha último acceso: </b> { administrador.fechaUltimoAcceso !== 0 ? getDateFromUnix(administrador.fechaUltimoAcceso) : 'Sin ingresos registrados' }
								</p>

								<p>
									<b>Tiempo restante: </b> { administrador.fechaUltimoAcceso !== 0 ? mesesVigencia > 0 ? `${mesesVigencia} meses` : `${diasVigencia} días` : `${ datosLicencia.tiempoVigencia } meses` }
								</p>
							</Col>
							
							<Col>
								<p>
									<b>Licencia: </b> {datosLicencia.nombre}
								</p>

								<p>
									<b>Tiempo de vigencia: </b> {datosLicencia.tiempoVigencia} meses
								</p>

								<p>
									<b>Usuario adicional: </b>
									{datosLicencia.usuarioExtra ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faTimes} className="text-danger" />}
								</p>
							</Col>
						</Row>

						<Row>
							<Col>
								<b>Cursos de la licencia</b>

								<Container fluid className="p-0">
									<Row>
										{
											(() => {
												const chunks = _.chunk(cursosLicencia, cursosLicencia.length / 2);

												return chunks.map((chunk, idxChunk) => 
													<Col key={ idxChunk } className='p-0'>
														<ul>
															{
																chunk.map((curso, idxCurso) => 
																	<li key={idxCurso}>
																		<h6>{curso.nombre}</h6>
																	</li>
																)
															}
														</ul>
													</Col>
												)
											})()
										}
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>

			<Row>
				<Col>
					<h3>Datos de grupo empresarial</h3>
					<hr />

					<Container fluid>
						<Row>
							<Col>
								<p>
									<b>Nombre de grupo empresarial: </b> { grupoAsesor.nombre !== '' ? grupoAsesor.nombre : 'Sin nombre asignado' }
								</p>

								<p>
									<b>Nombre de la empresa: </b> { grupoAsesor.nombreEmpresa !== '' ? grupoAsesor.nombreEmpresa : 'Sin nombre asignado' }
								</p>
							</Col>

							<Col>
								<p>
									<b>Folio del grupo: </b> { grupoAsesor.folioGrupo }
								</p>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row> */}
    </Container>
  );
};
