import {
  ModalSweetAlert,
  Notificacion,
} from "@components/general/Alertas/Alertas";
import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import { enviarSolicitudLlamadaVenta } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { Fieldset } from "primereact/fieldset";

import { BsCurrencyDollar, BsLightbulb } from "react-icons/bs";
import {
  FaBriefcase,
  FaChalkboardTeacher,
  FaChartLine,
  FaRoute,
} from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { IoMdHelpBuoy } from "react-icons/io";
import { MdLaptop } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import { GiArchiveResearch } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import NumberFormat from "react-number-format";

const SolicitudLlamadaVenta = () => {
  const { contextValue, setContextValue } = useContext(UserContext);

  const { usuarioLogeado } = contextValue;

  const nombre = `${usuarioLogeado.nombreCompleto} ${usuarioLogeado.apellidos}`;

  const breadcrumbItemsInitialState = [
    { title: "Adquiere tu Licencia Premium", onClick: () => {} },
  ];

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formValues.numeroTelefono === "") {
        return Notificacion("error", "Ingresa un número de teléfono");
      }

      setShowLoader(true);

      await enviarSolicitudLlamadaVenta(formValues);

      setShowLoader(false);

      await ModalSweetAlert({
        title: "¡Solicitud enviada con éxito!",
        icon: "success",
        text: "Recibirás una llamada de nuestro Agente de Ventas lo más pronto posible",
      });
    } catch (error) {
      console.log("Error al enviar la solicitud de llamada de venta: ", error);
    }
  };

  const [formValues, setFormValues] = useState({
    numeroTelefono: "",
    nombre,
    correo: usuarioLogeado.correo,
  });

  const [showLoader, setShowLoader] = useState(false);

  const [cursos, setCursos] = useState([]);

  const endOfContentRef = useRef(null);

  const beneficios = [
    {
      id: "propedeutico-mos",
      title: (
        <span>
          Curso Propedéutico Microsoft Office Specialist{" "}
          <Badge variant="warning">NUEVO</Badge>
        </span>
      ),
      description: (
        <>
          <h5>
            Como parte de los beneficios que obtienes al adquirir tu Licencia
            Premium, obtienes acceso a un curso propedéutico que te preparará
            para tomar tu Examen de Certificación Microsoft Office Specialist
            <br />
            <br />
            Podrás encontrar lecciones y actividades de apoyo que te servirán
            para practicar y obtener una mejor calificación en tu examen MOS.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/propedeutico-mos.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/propedeutico-mos.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <MdLaptop size="1.5rem" />,
    },
    {
      id: "certificacion-mos",
      title: (
        <span>
          Certificación Microsoft Office Specialist{" "}
          <Badge variant="warning">NUEVO</Badge>
        </span>
      ),
      description: (
        <>
          <Alert variant="warning">
            Esta Certificación es para una sola persona que sólo el titular de
            la Licencia puede designar.
          </Alert>

          <h5>
            En este apartado podrás ver todos los requisitos necesarios para que
            puedas presentar tu examen oficial MOS.
            <br />
            <br />
            Si cumples con los requisitos, podrás agendar tu examen oficial.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/certificacion-mos.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/certificacion-mos.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <MdLaptop size="1.5rem" />,
    },
    {
      id: "rutasAprendizaje",
      title: "Ruta de Aprendizaje",
      description: (
        <>
          <h5>
            La Plataforma Click+ Premium te permitirá tomar tus lecciones
            preferidas acorde a como tú las ordenes, o puedes optar por las
            rutas de aprendizaje predefinidas que tenemos para ti.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/ruta-aprendizaje.png`)}`}
            />
            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/ruta-aprendizaje.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <FaRoute size="1.5rem" />,
    },
    {
      id: "consejosRapidos",
      title: "Consejos Rápidos",
      description: (
        <>
          <h5>
            En este módulo podrás encontrar un amplio listado de atajos/comandos
            de distintos programas que te servirán para ser cada vez más ágil en
            el manejo de las aplicaciones ofimáticas.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/consejos-rapidos.png`)}`}
            />
            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/consejos-rapidos.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <BsLightbulb size="1.5rem" />,
    },
    {
      id: "foroAyuda",
      title: "Foro de Ayuda",
      description: (
        <>
          <h5>
            Tendrás acceso a un Foro de Ayuda en donde podrás consultar o
            compartir dudas con toda la comunidad Click+.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/foro-ayuda.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/foro-ayuda.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <TiMessages size="1.5rem" />,
    },
    {
      id: "cursos",
      title: "Catálogo de Cursos",
      description: (
        <Container fluid className="mt-2">
          <TitleDashboard title="Obten acceso ilimitado a este catálogo de cursos" />

          <Row className="mt-4">
            {cursos.map(
              (curso, idx) =>
                curso.estatus === 1 && (
                  <Col
                    id={idx}
                    className="col-9 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                  >
                    <Card className="mb-4 card-predefinidas invitado">
                      <picture width="100%" height="100%">
                        <source
                          srcSet={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`}
                        />

                        <img
                          loading="lazy"
                          className="img-fluid"
                          src={`${require(`@assets/images/portadas/${curso.portadaCurso}`)}`} // Aquí va la URL de la imagen de fondo
                          alt="Imagen de fondo"
                        />
                      </picture>
                    </Card>
                  </Col>
                )
            )}
          </Row>
        </Container>
      ),
      icon: <ImBooks size="1.5rem" />,
    },
    {
      id: "foroEmpresarial",
      title: "Foro Empresarial",
      description: (
        <>
          <Alert variant="warning">
            Este módulo sólo está disponible para Grupos Empresariales. El
            encargado de gestionar la capacitación en tu empresa debe de hacer
            la contratación.
          </Alert>

          <hr />

          <h5>
            Este Foro Empresarial puede ayudarte a ti y a tu equipo de trabajo
            empresarial a interactuar dentro de la plataforma sin necesidad de
            estar frente a frente.
            <br />
            Es un espacio exclusivo para un grupo empresarial, en el que todos
            los integrantes de un grupo visualizan el contenido personalizado,
            creado con base a las necesidades y solicitudes de la empresa, y en
            el caso del Asesor (Titular de la cuenta) puede ver el avance
            individual de cada integrante.
            <br />
            <br />
            Podrás ver:
            <br />
          </h5>

          <Container>
            <Row>
              <Col>
                <Fieldset legend="Publicaciones">
                  <p className="m-0">
                    Una publicación es una nota breve que puede realizar el
                    asesor, consta de una descripción y una plantilla auxiliar
                    que los colaboradores de un grupo pueden ver y descargar. Es
                    útil para fines informativos internos de la empresa.
                  </p>
                </Fieldset>
              </Col>

              <Col>
                <Fieldset legend="Lecciones">
                  <p className="m-0">
                    A diferencia de las Publicaciones, una lección interactiva
                    es totalmente personalizada, dirigida a tu grupo de
                    colaboradores, que sirve como temario de capacitación para
                    tu empresa.
                  </p>
                </Fieldset>
              </Col>

              <Col>
                <Fieldset legend="Colaboradores">
                  <p className="m-0">
                    En esta sección, el Asesor del Grupo podrá ver un listado de
                    todos los empleados que están registrados en la Plataforma
                    Click+. También se puede dar de alta un nuevo empleado en
                    esta sección.
                  </p>
                </Fieldset>
              </Col>
            </Row>
          </Container>

          <hr />

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/foro-empresarial.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/foro-empresarial.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <FaBriefcase size="1.5rem" />,
    },
    {
      id: "aulaVirtual",
      title: "Aula Virtual",
      description: (
        <>
          <h5>
            En este apartado, podrás disfrutar de clases en línea 100% en vivo
            que brindan nuestros capacitadores, así como las clases que otros
            capacitadores han impartido.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/aula-virtual.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/aula-virtual.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <FaChalkboardTeacher size="1.5rem" />,
    },
    {
      id: "historialAvances",
      title: "Historial de Avances",
      description: (
        <>
          <h5>
            Como parte de la Licencia Premium, podrás llevar un historial de
            avances para cada uno de los cursos que se encuentran en el catálogo
            Click+.
            <br />
            <br />
            Así como el tiempo que has pasado dentro de la plataforma y tu
            historial de lecciones para que retomes el curso de tu agrado en el
            punto dónde lo dejaste.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/historial-avances.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/historial-avances.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <FaChartLine size="1.5rem" />,
    },
    {
      id: "faq",
      title: "Centro de Ayuda",
      description: (
        <>
          <h5>
            Aquí podrás ver un listado de Preguntas Frecuentes para que puedas
            resolver dudas sobre la plataforma de forma rápida y eficaz.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/faq.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/faq.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <IoMdHelpBuoy size="1.5rem" />,
    },
    {
      id: "pagos",
      title: "Historial de Pagos",
      description: (
        <>
          <h5>
            También podrás tener un control de tus pagos, listando los pagos
            realizados a tu cuenta Click+.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/historial-pagos.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/historial-pagos.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <BsCurrencyDollar size="1.5rem" />,
    },
    {
      id: "barraBusqueda",
      title: "Barra de búsqueda",
      description: (
        <>
          <h5>
            Aquí podrás buscar cualquier recurso que se encuentre disponile en
            la Plataforma Click+ y acceder al módulo o recurso que selecciones.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/barra-busqueda.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/barra-busqueda.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <GiArchiveResearch size="1.5rem" />,
    },
    {
      id: "certificados",
      title: "Certificados",
      description: (
        <>
          <h5>
            Cada uno de los cursos cuentan con un Certificado que consta que
            tomaste el curso y lo completaste con éxito.
          </h5>

          <Container>
            <Row className="align">
              <Col>
                <picture width="100%" height="100%">
                  <source
                    srcSet={`${require(`@assets/images/previewModulos/certificados.png`)}`}
                  />

                  <Image
                    loading="lazy"
                    fluid
                    src={`${require(`@assets/images/previewModulos/certificados.png`)}`}
                    alt="Imagen de fondo"
                  />
                </picture>
              </Col>
            </Row>
          </Container>
        </>
      ),
      icon: <TbCertificate size="1.5rem" />,
    },
    {
      id: "usuarioAdicional",
      title: "Usuario adicional",
      description: (
        <>
          <Alert variant="warning">
            El usuario adicional sólo está disponible para compras individuales.
            No aplica si es una compra empresarial.
          </Alert>

          <hr />

          <h5>
            Este no es un módulo pero sí un beneficio que reciben las personas
            que realizan su compra de forma personal, al adquirir tu Licencia
            puedes asignar a una persona de tu agrado para que ingrese a la
            plataforma y pueda certificarse.
            <br />
            <br />
            Ten en cuenta que al asignar a un usuario adicional, deberás
            compartir tus credenciales de acceso para que pueda ingresar a su
            perfil, en el cuál cada uno tendrá sus propios avances de cada
            curso.
          </h5>

          <picture width="100%" height="100%">
            <source
              srcSet={`${require(`@assets/images/previewModulos/usuario-adicional.png`)}`}
            />

            <Image
              loading="lazy"
              fluid
              src={`${require(`@assets/images/previewModulos/usuario-adicional.png`)}`}
              alt="Imagen de fondo"
            />
          </picture>
        </>
      ),
      icon: <AiOutlineUsergroupAdd size="1.5rem" />,
    },
  ];

  const handleComprarLicencia = () => {
    endOfContentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const consultarCursos = async () => {
      try {
        let { data: cursos } = await getCursos();

        if (!Array.isArray(cursos)) {
          cursos = [cursos];
        }

        setCursos(cursos);
      } catch (error) {
        console.log("Error al consultar los cursos: ", error);
      }
    };

    consultarCursos();
  }, []);

  return (
    <Container fluid className="mb-5 pb-3">
      <Row>
        <Col className="col-auto">
          <DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
        </Col>
      </Row>

      <Row>
        <Col>
          <TitleDashboard title="Conoce los beneficios de Click+ Premium" />

          <Container fluid className="mt-4">
            <Row>
              <Col align="right">
                <Button variant="success" onClick={handleComprarLicencia}>
                  Adquirir Licencia Premium
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <h5 className="mb-4">
                  Al contratar tu Licencia Premium, tendrás acceso a todos estos
                  beneficios:{" "}
                </h5>

                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={beneficios[0].id}
                >
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        {beneficios.map(({ icon, title, id }) => (
                          <Nav.Item>
                            <Nav.Link eventKey={id}>
                              {icon}&nbsp;{title}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>

                    <Col sm={9}>
                      <Tab.Content>
                        {beneficios.map(({ description, id }) => (
                          <Tab.Pane eventKey={id}>{description}</Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <hr />

      <Row className="mt-5">
        <Col>
          <TitleDashboard title="Solicita una llamada de Ventas" />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <h5>
            Llena el siguiente formulario para que un Agente de Ventas se ponga
            en contacto para iniciar tu proceso de registro en Click+.
          </h5>

          <Form onSubmit={handleOnSubmit} ref={endOfContentRef}>
            <Form.Group className='mb-3'>
              <Form.Label>Nombre</Form.Label>

              <Form.Control
                type="text"
                value={formValues.nombre}
                name="nombre"
                readOnly
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Correo electrónico</Form.Label>

              <Form.Control
                type="text"
                value={formValues.correo}
                name="correo"
                readOnly
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Número de teléfono</Form.Label>

              <NumberFormat
                // className={`form-control ${numeroTelefonoValido ? "is-valid" : "is-invalid"}`}
                className="form-control"
                format="(###) ###-##-##"
                mask="_"
                name="numeroTelefono"
                onValueChange={(values) => {
                  const { formattedValue } = values;

                  setFormValues({
                    ...formValues,
                    numeroTelefono: formattedValue,
                  });
                }}
                value={formValues.numeroTelefono}
              />
            </Form.Group>
              <Row>
                <Col xs={3}>
                    <div className="d-grid gap-2">
                      <Button variant="success" type="submit">
                        {showLoader ? "Enviando solicitud..." : "Solicitar llamada"}
                      </Button>
                    </div>
                </Col>
              </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SolicitudLlamadaVenta;
