import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";

const columnsAgendasAsesorias = [

	{
		header: "Fecha solicitud",
		accessorKey: "fechaSolicitud",
		sortable: true,
		center: true,
		compact: true,
		width: "40px",
        Cell: ({ cell }) => (
			<div className="text-center">
				<small>
					<b>{getDateFromUnix(cell.row.original.fechaSolicitud)}</b>
				</small>
			</div>
		),
	},
	{
		header: "Fecha agenda",
		accessorKey: "fechaAgenda",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
        Cell: ({ cell }) => (
			<div className="text-center">
				<small>
					<b>{getDateFromUnix(cell.row.original.fechaAgenda)}</b>
				</small>
			</div>
		),
	},
    {
		header: "Horario",
		accessorKey: "horarioAgenda",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
	},
    {
		header: "Tema elegido",
		accessorKey: "temaElegido",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
	},
    {
		header: "Problemática",
		accessorKey: "problematica",
		sortable: true,
		center: true,
		compact: false,
		width: "720px",
	},
    {
		header: "Estatus",
		accessorKey: "estatus",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
	},
    {
		header: "Feedback",
		accessorKey: "feedback",
		sortable: true,
		center: true,
		compact: true,
		width: "360px",
        Cell: ({ cell }) => (
			<div className="text-center">
				<small>
					<b>{cell.row.original.feedback === null ? 'Sin feedback' :  cell.row.original.feedback}</b>
				</small>
			</div>
		),
	},
	
];

export default columnsAgendasAsesorias;
