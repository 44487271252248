import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getLecciones = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const lecciones = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.getLecciones}`,
        method: "GET",
      });

      resolve(lecciones);
    } catch (error) {
      // console.log("Error al obtener las lecciones: ", error);

      reject(error);
    }
  });
};

export const getLeccionesPapelera = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const lecciones = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.getLeccionesPapelera}`,
        method: "GET",
      });

      resolve(lecciones);
    } catch (error) {
      // console.log("Error al obtener las lecciones: ", error);

      reject(error);
    }
  });
};

export const getLeccionByCodigo = async (codigoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccion } = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.getLeccionByCodigo}/${codigoLeccion}`,
        method: "POST",
      });

      resolve(leccion);
    } catch (error) {
      // console.log("Error al obtener la lección: ", error);

      reject(error);
    }
  });
};

export const getLeccionesByCodigoSeccion = async (codigoSeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccion } = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.getLeccionesByCodigoSeccion}/${codigoSeccion}`,
        method: "POST",
      });

      resolve(leccion);
    } catch (error) {
      // console.log("Error al obtener las lecciones de la sección: ", error);

      reject(error);
    }
  });
};

export const getLeccionesByCodigoCurso = async (codigoCurso) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: leccion } = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.getLeccionByCodigoCurso}/${codigoCurso}`,
        method: "POST",
      });

      resolve(leccion);
    } catch (error) {
      // console.log("Error al obtener las lecciones de la sección: ", error);

      reject(error);
    }
  });
};

export const addLeccion = async (leccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionAgregada = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.addLeccion}`,
        method: "POST",
        body: JSON.stringify(leccion),
      });

      resolve(leccionAgregada);
    } catch (error) {
      // console.log("Error al añadir la lección: ", error);

      reject(error);
    }
  });
};

export const updateLeccion = async (leccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionActualizada = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.updateLeccion}`,
        method: "POST",
        body: JSON.stringify(leccion),
      });

      resolve(leccionActualizada);
    } catch (error) {
      // console.log("Error al actualizar la lección: ", error);

      reject(error);
    }
  });
};

export const updateReorderLecciones = async (lecciones) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionActualizada = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.updateReorderLecciones}`,
        method: "POST",
        body: JSON.stringify({ lecciones }),
      });

      resolve(leccionActualizada);
    } catch (error) {
      // console.log("Error al reordenar las lecciones: ", error);

      reject(error);
    }
  });
};

export const reactivateLeccion = async (codigoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionReactivada = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.reactivateLeccion}/${codigoLeccion}`,
        method: "POST",
      });

      resolve(leccionReactivada);
    } catch (error) {
      // console.log("Error al reactivar la lección: ", error);

      reject(error);
    }
  });
};

export const deactivateLeccion = async (codigoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionDesactivada = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.deactivateLecccion}/${codigoLeccion}`,
        method: "POST",
      });

      resolve(leccionDesactivada);
    } catch (error) {
      // console.log("Error al desactivar la lección: ", error);

      reject(error);
    }
  });
};

export const deleteLeccion = async (codigoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const leccionDesactivada = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.deleteLeccion}/${codigoLeccion}`,
        method: "POST",
      });

      resolve(leccionDesactivada);
    } catch (error) {
      // console.log("Error al desactivar la lección: ", error);

      reject(error);
    }
  });
};

//*Peticion para subir los documentos al server y obtener la url
// export const getUrlTemplate = async (archivos) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const templateUrl = await httpRequest({
// 				url: `${ endpointsPaths.lecciones }/${  httpRequestsPaths.lecciones.getUrlPlantilla }`,
// 				method: "POST",
// 				body: JSON.stringify({ archivos }),
// 			});

// 			resolve(templateUrl);
// 		} catch (error) {
// 			// console.log("Error al obtener el URL de la plantilla: ", error);

// 			reject(error);
// 		}
// 	});
// };

//*---------------------- FUNCIONES PARA LA ULTIMA LECCION TOMADA----------------------------------
export const addUltimaLeccionTomada = async (infoLeccion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const ultimaLeccion = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.addUltimaLeccionTomada}`,
        method: "POST",
        body: JSON.stringify(infoLeccion),
      });

      resolve(ultimaLeccion);
    } catch (error) {
      // console.log("Error al inserar la lección: ", error);

      reject(error);
    }
  });
};

export const getUltimaLeccionTomada = async (idPerfil) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: ultimaLeccion } = await httpRequest({
        url: `${endpointsPaths.lecciones}/${httpRequestsPaths.lecciones.getUltimaLeccionTomada}/${idPerfil}`,
        method: "POST",
      });

      resolve(ultimaLeccion);
    } catch (error) {
      // console.log("Error al recuperar la lección: ", error);

      reject(error);
    }
  });
};
