import "@assets/css/components/DashboardLecciones/style.css";

import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AgregarLeccion } from "./Modales/AgregarLeccion/AgregarLeccion";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { TableLecciones } from "@components/administrador/DashboardLecciones/DataTables/TableLecciones";

export const DashboardLecciones = () => {
  const handleAgregarLeccion = async () => {
    try {
      await ModalSweetAlert({
        title: "Agregar nueva lección",
        html: <AgregarLeccion setLeccionAgregada={setLeccionAgregada} />,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } catch (error) {
      console.log("Error al agregar una nueva lección: ", error);
    }
  };

  const [leccionAgregada, setLeccionAgregada] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col align="right">
          <Button variant="primary" onClick={handleAgregarLeccion}>
            Agregar lección
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <TableLecciones
            leccionAgregada={leccionAgregada}
            setLeccionAgregada={setLeccionAgregada}
          />
        </Col>
      </Row>
    </Container>
  );
};
