import "@assets/css/views/Login/style.css";
import "@assets/css/views/Login/responsive/responsive.css";

import { useEffect } from "react";
import Loader from "@helpers/loader/loader";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import { validateTokenExpiry } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { IniciarSesion } from "@components/general/Login/IniciarSesion/IniciarSesion";

import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Animated } from "react-animated-css";
import React, { useCallback, useState } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const particlesOptions = {
	fpsLimit: 60,
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
			},
		},
		color: {
			value: ["#ffcc00", "#757676", "#ff9900", "#00152A", "#f07f13"],
		},
		opacity: {
			value: { min: 0.1, max: 0.5 },
		},
		size: {
			value: { min: 1, max: 3 },
		},
		move: {
			enable: true,
			speed: 6,
			random: false,
		},
	},
	interactivity: {
		detectsOn: "window",
		events: {
			onClick: {
				enable: true,
				mode: "push",
			},
			resize: true,
		},
	},
	background: {
		image: "radial-gradient(#01488f, rgba(0, 21, 42, 1))",
	},
};

const Login = ({ history, location }) => {
	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		const validarExpiracionToken = async () => {
			try {
				const token = localStorage.getItem(localStorageItemNames.token);

				await validateTokenExpiry(token);

				history.replace("/dashboard");
			} catch (error) {
				console.log("Error validando la fecha de expiración del token: ", error);

				setTimeout(() => {
					setShowLoader(false);
				}, 1800);
			}
		};

		validarExpiracionToken();

		// ModalSweetAlert({
		// 	icon: 'info',
		// 	title: '¡Disculpa los inconvenientes!',
		// 	html: <span>Estamos realizando una actualización importante. Nuestro equipo de Soporte Técnico se encuentra trabajando en estos momentos. La plataforma estará lista en máximo 1 día hábil. Agradecemos profundamente tu comprensión. Para cualquier duda o aclaración comunícate al (477) 314 59 75 o al (477) 663 03 93. <br /> Si cuentas con un usuario de nuestra plataforma anterior, puedes ingresar a ella en el siguiente enlace: <a href="https://virtual.globalclickmexico.com">virtual.globalclickmexico.com</a></span>
		// });
	}, []);

	const particlesInit = useCallback(async (engine) => {
		await loadFull(engine);
	}, []);

	return (
		<>
			{showLoader && <Loader />}

			{!showLoader && (
				<>
					<Particles options={particlesOptions} init={particlesInit} id="tsparticles" />

					<Animated animationIn="fadeIn" isVisible={true} className="vh-100">
						<div id="particles" className="h-100">
							<Container className="d-flex h-100 justify-content-center flex-column align-items-center">
								<Card className="w-75">
									<Row className="g-0">
										<Col md="6" className="d-none d-md-block">
											<Image src={require("@assets/images/bg-formulario-login.jpeg")} className="rounded-start w-100 img-login" height={575} />
										</Col>

										<Col xs={12} md="6">
											<Card.Body className="d-flex flex-column h-100">
												<IniciarSesion />
											</Card.Body>
										</Col>
									</Row>
								</Card>
							</Container>
						</div>
					</Animated>
				</>
			)}
		</>
	);
};

export default Login;
