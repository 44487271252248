import React, { useEffect } from 'react'
import { lanzarConfetti } from './festejoClick';
import { Col, Container, Row } from 'react-bootstrap';

const ModalFestejo = () => {
    
    useEffect(() => {
        
        lanzarConfetti();
    }, [])
    
  return (
    <Container>
        <Row className='d-flex align-items-center justify-content-between'>
            <Col className='col-4'>
                <lord-icon
                    src="https://cdn.lordicon.com/fkmafinl.json"
                    trigger="loop"
                    delay="1200"
                    style={{width: "250px", height: "250px"}}>
                </lord-icon>
            </Col>
            <Col className='col-7'>
                <h4><b>Feliz cumpleaños <span style={{color: "#cc9900"}}>#8</span> Global Click México</b></h4>
                <span>Queremos expresar nuestro más sincero agradecimiento a cada uno de ustedes: nuestro equipo, clientes y socios.
                    <p>
                    Su apoyo ha sido la chispa que ha encendido nuestro camino hacia el éxito. ¡Sigamos celebrando juntos y construyendo un futuro aún más brillante para todos nosotros!
                    </p>
                </span>
            </Col>
        </Row>

    </Container>
  )
}

export default ModalFestejo