import { Notificacion } from '@components/general/Alertas/Alertas';
import { updateRespuestaTema } from '@helpers/httpRequests/httpRequestsRespuestasTema/httpRequestsRespuestasTema';
import { AlignHorizontalLeftSharp } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { FaImage, FaTrash } from 'react-icons/fa';

const EditarComentario = ({setComentarioEditado, comentario}) => {

    const {idRespuestaTema, imagenComentario, respuesta, alias} = comentario;

    const fileInputRef = useRef(null);

    const [imagenNuevaComentario, setImagenNuevaComentario] = useState(imagenComentario);

    const [showImagen, setShowImagen ] = useState(imagenComentario !== '' ? true : false);

    const eliminarImagen = () => {
        setImagenNuevaComentario(null);
        setShowImagen(false);
    }

    const handleImagenClick = () => {
        fileInputRef.current.click();
      };

      const [formValues, setFormValues] = useState({
        respuesta
      });

      const [caracteresRestantes, setCaracteresRestantes] = useState(500 - respuesta.length);

      const validarKeyDown = (event) => {
        const { key } = event;


        if (key === 'Backspace') {
          if (caracteresRestantes < 500) {
            setCaracteresRestantes(caracteresRestantes + 1);
          }
        } else {
          if (caracteresRestantes > 0) {
            setCaracteresRestantes(caracteresRestantes - 1);
          }
        }
      }


      const handleOnChange = ({target}) => {
        const {name, value} = target;
        console.log(value);
        setFormValues({
            ...formValues,
            respuesta: value
        })
      }


    const handleFileInputChange = (event) => {
        // Maneja la lógica para la selección de archivo aquí
        const archivo = event.target.files[0];
        if (!archivo) {
          return;
        }
    
        const reader = new FileReader();
    
        reader.onloadend = () => {
          // La cadena Base64 se encuentra en reader.result
          setImagenNuevaComentario(reader.result);
          setShowImagen(true)
       };
    
        reader.onerror = () => {
          console.error('Hubo un error al leer el archivo');
        };
    
        reader.readAsDataURL(archivo);
      };


    const guardarCambios = async() => {
      try {

        const infoComentarioEditado = {
          imagenComentario: imagenNuevaComentario,
          respuesta: formValues.respuesta,
          idRespuestaTema,
        };

        await updateRespuestaTema(infoComentarioEditado);
        
        setComentarioEditado(true);
        Notificacion("success", "Comentario modificado con éxito");

      } catch (error) {
        console.log(error); 
        Notificacion("error", "Error modificando el comentario");
      }
    }
      
    useEffect(() => {
      if(formValues.respuesta === '') {
        setCaracteresRestantes(500);
      }
    }, [formValues.respuesta])
    

  return (
    <Container className='text-start'>
    {(showImagen) && (
        <Row className='justify-content-center'>
            <Col className='text-center'>
                <Image fluid src={imagenNuevaComentario} alt='Imagen' />
            </Col>
            <Col className='col-12 text-center mt-4'>
                <FaTrash className='pointer' size={25} onClick={eliminarImagen} color='red' />
            </Col>
        </Row>
    )}
    <Row>
        <Col>
            {/* <Col className="col"></Col> */}
            <Col className="col-12 mt-4">                    
                <Form className='mt-3'>
              
                <Form.Group>
                    <Form.Control
                    className="input-form"
                    name="respuesta"
                    value={formValues.respuesta}
                    onChange={handleOnChange}
                    onKeyDown={validarKeyDown}
                    as="textarea"
                    rows={15}
                    placeholder="Escribe un comentario"
                    ></Form.Control>
                </Form.Group>

                <Row className='mt-2'>
                  <Col className='col-auto'>
                    <small>{caracteresRestantes}/500</small>
                  </Col>
                </Row>

                {/* <Editor name="respuesta" value={formValues.respuesta}  onTextChange={handleEditorChange} style={{ height: '320px' }} /> */}

                <div className="d-flex justify-content-between align-items-start mt-3">
                
                    {imagenNuevaComentario === '' && (
                        <>
                            <Form.Control accept="image/*" onChange={handleFileInputChange} type='file' id='fileInput' ref={fileInputRef} style={{ display: "none" }} />
                            <FaImage onClick={handleImagenClick} fontSize={25} color='gray' className='pointer mt-3 mb-3' />
                        </>
                    )}

                    <Button
                    className="btn-responsive"
                    onClick={guardarCambios}
                    >
                    Guardar cambios
                    </Button>
                </div>


                </Form>
            </Col>
        </Col>
    </Row>
</Container>
  )
}

export default EditarComentario