import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { enviarRestablecimientoNip } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { validateNipPerfil } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import React, { useContext } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const ValidacionNip = ({ idPerfil, nombre, correo, darAcceso }) => {
	const [nip, setNip] = useState("");

	const [showNip, setShowNip] = useState(false);

	const [enviandoCorreo, setEnviandoCorreo] = useState(false);

	const [nipValido, setNipValido] = useState(false);

	const handleValidarNip = async (e) => {
		e.preventDefault();

		try {
			const nipValido = await validateNipPerfil({
				idPerfil,
				nip,
			});

			setNipValido(nipValido);

			if (!nipValido) {
				return NotificationManager.error("El NIP es inválido");
			}

			Notificacion("success", "NIP válido");

			darAcceso();
		} catch (error) {
			console.log("error al validar el nip: ", error);
		}
	};

	const showNipSeguridad = () => {
		setShowNip(!showNip);
	};

	const handleOnChangeValidacionNip = ({ target }) => {
		setNip(target.value);
	};

	const handleRecuperarNip = async () => {
		try {
			setEnviandoCorreo(true);

			await enviarRestablecimientoNip({
				nombre,
				correo,
				idPerfil,
			});

			await ModalSweetAlert({
				title: `Tu nuevo NIP ha sido enviado al correo electrónico: ${correo}`,
				icon: "success",
			});

			setEnviandoCorreo(false);
		} catch (error) {
			console.log("error al recuperar el NIP: ", error);

			await ModalSweetAlert({
				title: `Algo ocurrió al restablecer tu NIP, vuelve a intentar o ponte en contacto con Soporte Técnico.`,
				icon: "error",
			});

			setEnviandoCorreo(false);
		}
	};

	return (
		<Container>
			<Row className="justify-content-center">
				<Col xs={12} md={4}>
					<Form onSubmit={handleValidarNip}>
						<Form.Group>
							<InputGroup hasValidation>
								<InputGroup.Text onClick={showNipSeguridad} className="pointer">
									<FontAwesomeIcon icon={faEye} />
								</InputGroup.Text>

								<Form.Control
									type={showNip ? "text" : "password"}
									autoComplete="off"
									value={nip}
									name="nip"
									placeholder="Ingresa tu NIP"
									onChange={handleOnChangeValidacionNip}
									className={nipValido ? "is-valid" : "is-invalid"}
									maxLength={4}
								/>
							</InputGroup>

							{enviandoCorreo ? (
								<legend className="text-primary forgotten-nip pointer">Enviando NIP...</legend>
							) : (
								<legend onClick={handleRecuperarNip} className="text-primary forgotten-nip pointer">
									Olvid&eacute; mi NIP
								</legend>
							)}
						</Form.Group>

            <div className="dgrid gap-2 mt-4">
              <Button variant="primary" type="submit" size="lg">
                Validar
              </Button>
            </div>

					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default ValidacionNip;
