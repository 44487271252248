const columnas = [
  {
    name: "Nombre", //nombre de la columna
    selector: (row) => row.nombre, //identificador de la columna
    center: true,
    // width: '350px'
  },
  {
    name: "Código",
    selector: (row) => {
      if (row.codigoCurso && !row.codigoSeccion && !row.codigoLeccion) {
        return row.codigoCurso;
      } else if (row.codigoSeccion && !row.codigoLeccion) {
        return row.codigoSeccion;
      } else if (row.codigoLeccion && !row.codigoCurso) {
        return row.codigoLeccion;
      }
    },
    sortable: true,
    center: true,
    // width: '250px'
  },
];

export default columnas;
