import { Notificacion } from "@components/general/Alertas/Alertas";
import { SpinnerImage } from "@components/general/Spinners/SpinnerImage/SpinnerImage";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropZone } from "@helpers/DropZone/DropZone";
import {
  getTemas,
  selectTema,
  subirImagenTemaPersonalizado,
} from "@helpers/httpRequests/httpRequestApariencia/httpRequestApariencia";
import Compressor from "compressorjs";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";

const DashboardApariencia = ({ setTemaSeleccionado }) => {
  const [temas, setTemas] = useState([]);

  /**
   * PARA SUBIR LAS IMAGENES PERSONALIZADAS:
   * Se necesitan 2 dropzones, 1 para el header y otro para el banner de home
   * Se necesita un endpoint para proporcionar y subir los cambios a la base de datos
   */

  // const categories = [
  //     { name: 'Mes patrio', key: 'A' },
  //     { name: 'Halloween', key: 'M' },
  //     { name: 'Día de muertos', key: 'P' },
  //     { name: 'Navidad', key: 'R' }
  // ];
  const [selectedCategory, setSelectedCategory] = useState();

  const [nombreTemaSeleccionado, setNombreTemaSeleccionado] = useState("");

  const [vistaPrevia, setVistaPrevia] = useState("");

  const [temaModificado, setTemaModificado] = useState(false);

  const [showPreviewHeader, setShowPreviewHeader] = useState(false);

  const [showPreviewLogo, setShowPreviewLogo] = useState(false);

  const [spinnerImagen, setSpinnerImagen] = useState(false);

  const [formValues, setFormValues] = useState({
    imagenHeader: "",
    imagenLogo: "",
  })



  const cleanPreviewHeader = () => {

    setFormValues({
      ...formValues,
      imagenHeader: ""
    })
    setShowPreviewHeader(false);
  };

  const cleanPreviewLogo = () => {

    setFormValues({
      ...formValues,
      imagenLogo: ""
    })
    setShowPreviewLogo(false);
  };


  const getBase64ImageHeader = ({ target }) => {
    try {
      const { files } = target;

      const [file] = files;

      const fileReader = new FileReader();

      if (!file) {
        return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
      }

      //!codigo para comparacion de peso de imagen antes de ser comprimida
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.2,
        success(result) {
          fileReader.readAsDataURL(result);

          fileReader.onload = async function () {
            setFormValues({ ...formValues, imagenHeader: fileReader.result });

            setShowPreviewHeader(true);
          };
        },
        error(e) {
          console.log(e);

          Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
        },
      });
    } catch (e) {
      Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
    }
  };


  const getBase64ImageLogo = ({ target }) => {
    try {
      const { files } = target;

      const [file] = files;

      const fileReader = new FileReader();

      if (!file) {
        return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
      }

      //!codigo para comparacion de peso de imagen antes de ser comprimida
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.2,
        success(result) {
          fileReader.readAsDataURL(result);

          fileReader.onload = async function () {
            setFormValues({ ...formValues, imagenLogo: fileReader.result });

            setShowPreviewLogo(true);
          };
        },
        error(e) {
          console.log(e);

          Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
        },
      });
    } catch (e) {
      Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
    }
  };


  const handleApariencia = (tema) => {
    setVistaPrevia(tema.vistaPrevia);
  };

  const seleccionarTema = async ({ currentTarget }) => {
    try {
      const { id, name } = currentTarget;
      if(nombreTemaSeleccionado === "Personalizado"){
        await subirImagenTemaPersonalizado({
          idTema: id,
          imagenLogo: formValues.imagenLogo,
          imagenHeader: formValues.imagenHeader,
        })
      }
      await selectTema(id);
      setTemaModificado(true);
      setTemaSeleccionado(true);
      Notificacion(
        "success",
        "Apariencia de la plataforma modificada exitosamente"
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const obtenerTemasApariencia = async () => {
      try {
        const { data } = await getTemas();
        data.map((tema) => {
          if (tema.estatus) {
            setSelectedCategory(tema.idApariencia);
            setVistaPrevia(tema.vistaPrevia);
            setNombreTemaSeleccionado(tema.nombreTema)
          }
        });
        setTemas(data);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerTemasApariencia();
  }, []);

  useEffect(() => {
    if (temaModificado) {
      const obtenerTemasApariencia = async () => {
        try {
          const { data } = await getTemas();
          setTemas(data);
        } catch (error) {
          console.log(error);
        }
      };

      obtenerTemasApariencia();
      setTemaModificado(false);
    }
  }, [temaModificado]);

  return (
    <Container className="mt-3 pb-4" fluid>
      <Row>
        <Col className="col-4">
          <h5>Selecciona un estilo:</h5>
        </Col>
        <Col className="col-8">
          <h5>Vista previa:</h5>
        </Col>
      </Row>

      <Row>
        <Col className="col-4">
          <div className="card flex justify-content-center mt-4">
            <div className="flex flex-column gap-3">
              {temas.length > 0 &&
                temas.map((tema, key) => {
                  return (
                    <div
                      key={tema.idApariencia}
                      className="flex align-items-center mb-3"
                      onClick={() => handleApariencia(tema)}
                    >
                      <RadioButton
                        inputId={tema.idApariencia}
                        name={tema.nombreTema}
                        value={tema.idApariencia}
                        onChange={(e) => {
                          setSelectedCategory(e.value)
                          setNombreTemaSeleccionado(tema.nombreTema)
                        }}
                        checked={selectedCategory === tema.idApariencia}
                      />
                      <label
                        htmlFor={tema.idApariencia}
                        className="mx-2 p-0 mb-0"
                      >
                        {tema.nombreTema}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="d-grid gap-2 mt-3">
            <Button disabled={nombreTemaSeleccionado === "Personalizado" && (formValues.imagenHeader === "" || formValues.imagenLogo === "")} id={selectedCategory} block onClick={seleccionarTema}>
              Seleccionar
            </Button>
          </div>
        </Col>
        {nombreTemaSeleccionado === "Personalizado" ? (
          <Col className="col-8 pt-4">
            
            <h4>Decoración para home</h4>
            <small>Decoración que se muestra en la vista home al iniciar sesión | <b>Dimensiones recomendadas: 2000 x 100 px</b></small>
            <Form.Group className="mt-3">
              {showPreviewHeader ? (
                <>
                  <img className="mt-2 vista-previa img-fluid" src={formValues.imagenHeader} alt="Imagen de perfil"></img>

                  <Col className="mt-4 text-center">
                    <h5>
                      <FontAwesomeIcon className='pointer' onClick={cleanPreviewHeader} icon={faTrash} />
                    </h5>
                  </Col>
                </>
              ) : (
                <>{spinnerImagen ? <SpinnerImage /> : <DropZone getImageInBase64={getBase64ImageHeader} />}</>
              )}
            </Form.Group>

            <h4>Logo Click+ personalizado</h4>
            <small>Logo de la barra superior personalizado | <b>Dimensiones recomendadas: 600 x 600 px</b></small>
            <Form.Group className="mt-3">
              {showPreviewLogo ? (
                <div className="text-center">
                  <img width="250" height="250" className="mt-2 vista-previa img-fluid" src={formValues.imagenLogo} alt="Imagen de perfil"></img>

                  <Col className="mt-4 text-center">
                    <h5>
                      <FontAwesomeIcon className='pointer' onClick={cleanPreviewLogo} icon={faTrash} />
                    </h5>
                  </Col>
                </div>
              ) : (
                <>{spinnerImagen ? <SpinnerImage /> : <DropZone getImageInBase64={getBase64ImageLogo} />}</>
              )}
            </Form.Group>
          </Col>
        ) : (
          <Col className="col-8">
            {vistaPrevia !== "" && (
              <img
                src={require(`@assets/images/${vistaPrevia}`)}
                className="img-fluid"
                alt="Vista Previa"
              />
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default DashboardApariencia;
