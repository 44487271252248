import { Notificacion } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import useForm from "@customHooks/useForm";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { createCheckoutSession, getFolioContratoCompra, validateCodigoDescuento } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import stripe from "@helpers/stripe/stripe";
import { typesOpcionesPagoCompra } from "@helpers/types/typesOpcionesPagoCompra";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { FormControl as MUIFormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Swal from "sweetalert2";

const setPrecio = (codigoDescuento, precios, setPreciosXCodigo) => {
	switch (codigoDescuento) {
		case precios.clickPlus2024.codigo:
			setPreciosXCodigo(precios.clickPlus2024);
			break;

		case precios.clickPlusPremium.codigo:
			setPreciosXCodigo(precios.clickPlusPremium);
			break;

		case precios.gcPlus.codigo:
			setPreciosXCodigo(precios.gcPlus);
			break;

		default:
			console.log("No hay ningún código que coincida con el ingresado o no se ingresó ningún dato");
			break;
	}
};

function esValido(json) {
	return Object.keys(json).length > 0;
}

const FormCodigoDescuento = (props) => {
	const {
		precios,
		states: { 
			stateCodigoAplicado, 
			stateCodigoDescuento, 
			// statePreciosXCodigo 
		},
	} = props;

	// const history = useHistory();

	const { contextValue, setContextValue } = useContext(UserContext);

	let { folioContrato, rol } = contextValue.usuarioLogeado;

	if (rol === typesTiposUsuario.administrador) {
		folioContrato = '753';
	}

	const { codigoAplicado, setCodigoAplicado } = stateCodigoAplicado;

	const { codigoDescuento, setCodigoDescuento } = stateCodigoDescuento;

	// const { preciosXCodigo, setPreciosXCodigo } = statePreciosXCodigo;

	// const [idCodigoDescuento, setIdCodigoDescuento] = useState(0);

	// const [folioContrato, setFolioContrato] = useState("");

	const [loading, setLoading] = useState(false);

	const [opcionesPago, setOpcionesPago] = useState([]);

	const [valueRadioButton, setValueRadioButton] = useState("");

	const [inputValue, handleInputChange] = useForm("");

	//  

	const handlePagarAhora = async () => {
		try {
			if (valueRadioButton === "") {
				return NotificationManager.error("Elige una opción de pago");
			}

			setLoading(true);

			let stripeIdPrice = "";

			switch (valueRadioButton) {
				case typesOpcionesPagoCompra.contado:
					stripeIdPrice = precios.contado.stripeIdPrice;
					break;

				case typesOpcionesPagoCompra.msi:
					stripeIdPrice = precios.msi.stripeIdPrice;
					break;

				case typesOpcionesPagoCompra.credito:
					stripeIdPrice = precios.credito.stripeIdPrice;
					break;

				default:
					break;
			}

			const sessionData = {
				success_url: `${window.location.origin}${dashboardRoutesPaths.dashboard}`,
				cancel_url: `${window.location.origin}${dashboardRoutesPaths.dashboard}`,
				line_items: [{ price: stripeIdPrice, quantity: 1 }],
				mode: "payment",
				currency: "mxn",
				metadata: {
					folioContrato,
					metodoPago: valueRadioButton
				},
			};

			const checkoutSession = await createCheckoutSession(sessionData);

			const { isConfirmed } = await Swal.fire({
				title: "Saliendo de Click+",
				text: "Estás a punto de ser redirigido al sitio de pago",
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Entendido",
				cancelButtonText: "Cancelar",
				confirmButtonColor: "#0d6efd",
				allowOutsideClick: false,
			});

			if (!isConfirmed) {
				setLoading(false);

				return Notificacion("info", "El pago fue cancelado");
			}

			setContextValue({
				...contextValue,
				renovacion: {
					...contextValue.renovacion,
					pagoIniciado: true,
					stripeSession: checkoutSession,
					metodoPago: valueRadioButton,
					precios
				},
			});	

			setLoading(false);

			await (await stripe).redirectToCheckout({ sessionId: checkoutSession.id });
		} catch (error) {
			console.log("Error al obtener la información para generar el checkout: ", error);
		}
	};

	const handleChangeOpcionPago = ({ target }) => {
		setValueRadioButton(target.value);
	};

	// const consultarFolioCompra = async () => {
	// 	try {
	// 		const { folioContrato } = await getFolioContratoCompra();

	// 		setFolioContrato(folioContrato);

	// 		setContextValue({
	// 			...contextValue,
	// 			renovacion: {
	// 				...contextValue.renovacion,
	// 				folioContrato,
	// 			},
	// 		});
	// 	} catch (error) {
	// 		console.log("error al obtener el folio de contrato: ", error);
	// 	}
	// };

	// useEffect(() => {
	// 	if (folioContrato === "") {
	// 		consultarFolioCompra();
	// 	}
	// }, []);

	useEffect(() => {
		const { contado, msi, credito } = precios;

		const auxOpcionesPago = [
			contado !== undefined && { value: "contado", label: "De Contado" },
			msi !== undefined && { value: "msi", label: "Meses sin intereses" },
			credito !== undefined && { value: "credito", label: "A Crédito" },
		].filter(esValido);

		setOpcionesPago(auxOpcionesPago);
	}, [precios]);

	return (
		<Col sm={12} lg className="">
			<MUIFormControl>
				<FormLabel style={{color: '#fff'}} id="opciones-pago">Elige tu modalidad de pago</FormLabel>

				<RadioGroup aria-labelledby="opciones-pago" name="opcionPago" row value={valueRadioButton} onChange={handleChangeOpcionPago}>
					{opcionesPago.map(({ value, label }, idx) => (
						<FormControlLabel key={idx} value={value} control={<Radio style={{color: "#ff9900"}} />} label={label} />
					))}
				</RadioGroup>

				{valueRadioButton === "" ? (
					<Typography variant="caption" gutterBottom className="text-danger">
						Elige una opción de pago
					</Typography>
				) : null}
			</MUIFormControl>

			<div className="d-grid gap-2 mt-3">
				<Button onClick={handlePagarAhora} size="large" variant="orange-gc">
					Pagar ahora {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
				</Button>
			</div>	

			{/* <Form onSubmit={handleSubmitValidacionCodigo}>
				<Typography variant="overline" gutterBottom>
					Ingresa aquí tu código de descuento
				</Typography>

				<InputGroup className="mb-3">
					<FormControl className="mb-2 txtCodigoDescuento" name="codigoDescuento" onChange={handleInputChange} placeholder="Código de descuento" value={inputValue} disabled={codigoAplicado} />

					<Button className="mb-2" type="submit" variant="warning">
						Aplicar código
					</Button>
				</InputGroup>

				<div className="d-grid gap-2">
					<Button onClick={handlePagarAhora} size="large" variant="orange-gc">
						Pagar ahora {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
					</Button>
				</div>
			</Form> */}
		</Col>
	);
};

export default FormCodigoDescuento;
