import { Button, Col, Container, Row } from "react-bootstrap";

import AgregarTransmision from "./Modales/AgregarTransmision/AgregarTransmision";
import EditarTransmision from "./Modales/EditarTransmision/EditarTransmision";
import EliminarTransmision from "./Modales/EliminarTransmision/EliminarTransmision";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import React, { useMemo } from "react";
import { getTransmissions } from "@helpers/httpRequests/httpRequestsAulaVirtual/httpRequestsAulaVirtual";
import { useEffect } from "react";
import { useState } from "react";
import {MaterialReactTable} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, IconButton } from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";

const DashboardAulaVirtual = () => {
  const [transmisiones, setTransmisiones] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([]);

  const [transmisionAgregada, setTransmisionAgregada] = useState(false);

  const [transmisionModificada, setTransmisionModificada] = useState(false);

  const [transmisionEliminada, setTransmisionEliminada] = useState(false);

  const handleAgregarTransmision = (e) => {
    ModalSweetAlert({
      title: "Agregar nueva transmisión",
      icon: "info",
      html: (
        <AgregarTransmision setTransmisionAgregada={setTransmisionAgregada} />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleEditTransmision = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;

    ModalSweetAlert({
      title: "Actualizar transmisión",
      icon: "info",
      html: (
        <EditarTransmision
          setTransmisionModificada={setTransmisionModificada}
          transmision={transmisiones[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const handleDeleteTransmision = ({ currentTarget }) => {
    const rowIndex = currentTarget.id;

    ModalSweetAlert({
      title: "Eliminar transmisión",
      icon: "warning",
      html: (
        <EliminarTransmision
          setTransmisionEliminada={setTransmisionEliminada}
          transmision={transmisiones[rowIndex]}
        />
      ),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  useEffect(() => {
    const getTransmisiones = async () => {
      try {
        const { data: transmisiones } = await getTransmissions();

        const isAnyTransmisinEnVivoAvailable =
          Object.keys(transmisiones.transmisionEnVivo).length === 0;

        const transmisionesAux = [
          ...transmisiones.transmisionesAnteriores,
          isAnyTransmisinEnVivoAvailable
            ? null
            : transmisiones.transmisionEnVivo,
        ];

        setTransmisiones(
          transmisionesAux.filter((transmision) => transmision !== null)
        );
      } catch (error) {
        console.log("Ocurrió un error al obtener las transmisiones: ", error);
      }
    };

    getTransmisiones();
  }, []);

  useEffect(() => {
    if (transmisionAgregada || transmisionModificada || transmisionEliminada) {
      console.log("refetching data");

      const refetchTransmisiones = async () => {
        const { data: transmisiones } = await getTransmissions();

        const isAnyTransmisinEnVivoAvailable =
          Object.keys(transmisiones.transmisionEnVivo).length === 0;

        const transmisionesAux = [
          ...transmisiones.transmisionesAnteriores,
          isAnyTransmisinEnVivoAvailable
            ? null
            : transmisiones.transmisionEnVivo,
        ];

        setTransmisiones(
          transmisionesAux.filter((transmision) => transmision !== null)
        );
      };

      refetchTransmisiones();

      setTransmisionAgregada(false);

      setTransmisionModificada(false);

      setTransmisionEliminada(false);
    }
  }, [transmisionAgregada, transmisionModificada, transmisionEliminada]);

  // useEffect(() => {
  // 	if (transmisiones.length > 0) {

  // 		setDataTableColumns([
  // 			...columns,
  // 			{
  // 				name: "Acciones",
  // 				cell: (row) => (
  // 					<>
  // 						<span id={`edit-${row.idTransmision - 1}`} className="me-1 pointer" onClick={handleEditTransmision}>
  // 							<FontAwesomeIcon icon={faEdit} className='text-info' />
  // 						</span>

  // 						<span id={`delete-${row.idTransmision - 1}`} className="ms-1 pointer" onClick={handleDeleteTransmision}>
  // 							<FontAwesomeIcon icon={faTrashAlt} className='text-danger' />
  // 						</span>
  // 					</>
  // 				),
  // 				ignoreRowClick: true,
  // 				allowOverflow: true,
  // 				button: true,
  // 			},
  // 		]);
  // 	}
  // }, [ transmisiones ]);

  const columnas = useMemo(() => [
    {
      accessorKey: "idTransmision",
      header: "#",
      size: 50,
    },
    {
      accessorKey: "titulo",
      header: "Título",
    },
    {
      accessorKey: "descripcion",
      header: "Descripción",
    },
    {
      accessorKey: "fecha",
      header: "Fecha",
      Cell: ({ cell }) => (
        <div>
          <span>{getFechaSinHora(cell.row.original.fecha)}</span>
        </div>
      ),
    },
    {
      accessorKey: "nombreCapacitador",
      header: "Capacitador",
    },
  ]);

  return (
    <Container fluid>
      <Row>
        <Col align="right">
          <Button variant="primary" onClick={handleAgregarTransmision}>
            Agregar transmisión
          </Button>
        </Col>
      </Row>

      <Row className="my-3 mb-5">
        <Col>
          {/* <DataTablesRecipient columns={dataTableColumns} data={transmisiones} /> */}

          <MaterialReactTable
            localization={MRT_Localization_ES}
            columns={columnas}
            data={transmisiones}
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                <IconButton
                  color="secondary"
                  id={row.original.idTransmision - 1}
                  onClick={handleEditTransmision}
                >
                  <FaEdit className="text-primary" />
                </IconButton>

                <IconButton
                  color="secondary"
                  id={`${row.original.idTransmision - 1}`}
                  onClick={handleDeleteTransmision}
                >
                  <FaTrash color="red" />
                </IconButton>
              </Box>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardAulaVirtual;
